import { push } from "connected-react-router";
import { toast } from "react-toastify";

import { axios, currentAPI } from "../../../../../config";
import { formatCreationDate } from "../../../../../utils/date/formatCreationDate";
import { referredCommissions } from "../../../../../utils/filtering/commissions";
import {
  getUserById,
  getAllCommissions,
  getUserLastActiveState,
  saveUserKycVerificationState,
} from "../../../user/actions";
import { getUserAvatar } from "../../../user/actions/avatar";
import {
  LOADING_AGENTS_REFERRALS_DATA,
  LOADING_AGENTS_REFERRALS_COMPLETED,
  LOADING_AGENTS_DATA,
  LOADING_AGENTS_DATA_SUCCESS,
  LOADING_AGENTS_DATA_ERROR,
  SAVE_AGENTS_REFERRAL,
  SAVE_TEAM_DATA,
  SAVE_TEAM_MEMBERS,
  SAVE_AGENTS_COMMISSIONS,
  AGENT_LAST_SEEN,
  UPDATE_TEAM,
  DELETE_TEAM,
  AGENT_ACTIVATION_STATUS_SUCCESS,
  SAVE_REFERRED_AGENTS_COUNT,
  // AGENT_ACTIVATION_STATUS_FAILURE
} from "../constants";

export const saveAgentReferral = (referral) => (dispatch) => {
  dispatch({ type: SAVE_AGENTS_REFERRAL, payload: referral });
};

export const agentLastSeen = (id, lastSeen) => (dispatch) => {
  dispatch({ type: AGENT_LAST_SEEN, payload: { id, lastSeen } });
};

export const createTeam = (teamData) => async (dispatch) => {
  if (!navigator.onLine) {
    toast.error("You can not perform this action offline...");
    return;
  }

  dispatch({ type: LOADING_AGENTS_DATA });

  try {
    const { name, description } = teamData;

    const sendTeamResponse = await axios.post(
      `${currentAPI}/api/teams/create?name=${name}&description=${description}`,
      null
    );

    if (sendTeamResponse.status === 200) {
      const { id, owner } = sendTeamResponse.data;
      dispatch({ type: LOADING_AGENTS_DATA_SUCCESS });
      toast.success(`Team ${teamData.name} was successfully created`);
      dispatch({ type: SAVE_TEAM_DATA, payload: { id, owner, teamData } });
      return id;
    }
  } catch (error) {
    dispatch({
      type: LOADING_AGENTS_DATA_ERROR,
      errorMsg: error.message,
    });

    toast.error(`Failed to create ${teamData.name}`);
  }
};

export const addTeamMembers =
  (teamId, selectedMembers, redirect = null) =>
  async (dispatch, getState) => {
    if (!navigator.onLine) {
      toast.error("You can not perform this action offline...");
      return;
    }

    dispatch({ type: LOADING_AGENTS_DATA });

    try {
      const referrals = getState().applications.agents.referrals;
      const members = selectedMembers.map(
        (agentIndex) => referrals[agentIndex]
      );
      const membersIds = members.map((member) => member.id);

      const addTeamMembersResponse = await axios.put(
        `${currentAPI}/api/teams/editMembers?members=${membersIds}&teamId=${teamId}`,
        null
      );

      if (addTeamMembersResponse.status === 200) {
        dispatch({ type: LOADING_AGENTS_DATA_SUCCESS });
        toast.success("Team members were successfully saved");
        dispatch({ type: SAVE_TEAM_MEMBERS, payload: { teamId, members } });
        if (redirect) dispatch(push(redirect));
      }
    } catch (error) {
      dispatch({
        type: LOADING_AGENTS_DATA_ERROR,
        errorMsg: error.message,
      });
      if (error.response && error.response.status === 400) {
        const { data } = (error && error.response) || {};
        data.message && toast.error(error.response.data.message);
      }
    }
  };

export const getReferralCommision = (id) => async () => {
  if (!navigator.onLine) return;

  try {
    const referralCommisionsResponse = await axios.get(
      `${currentAPI}/api/transactions/forUserByUserId/${id}?page=0&resultsPerPage=100&types=COMMISSION`
    );

    return referralCommisionsResponse && referralCommisionsResponse.data;
  } catch (error) {
    console.error(error);
  }
};

export const getAgentTeams = () => async (dispatch, getState) => {
  if (!navigator.onLine) return;

  try {
    const getAgentTeamsResponse = await axios.get(
      `${currentAPI}/api/teams/find`
    );

    if (getAgentTeamsResponse.status === 200) {
      const teams = getAgentTeamsResponse.data;
      const storedTeams = getState().applications.agents.teams;

      if (storedTeams.length < teams.length) {
        dispatch({ type: LOADING_AGENTS_DATA });

        teams.forEach((team) => {
          const { id, owner, members } = team;

          const teamData = {
            name: team.name,
            description: team.description,
          };

          dispatch({
            type: SAVE_TEAM_DATA,
            payload: { id, owner, teamData },
          });

          const membersData = members.map((member) => {
            const referrals = getState().applications.agents.referrals;
            const referral = referrals.filter(
              (referral) => referral.id === member.id
            )[0];

            return {
              id: member.id,
              msisdn: member.msisdn,
              createdOn: member.createdOn,
              commission: referral.commission,
              firstName: member.data.firstName,
              lastName: member.data.lastName,
              lastSeen: referral.lastSeen,
              avatar: referral.avatar || "",
            };
          });

          dispatch({ type: LOADING_AGENTS_DATA_SUCCESS });
          dispatch({
            type: SAVE_TEAM_MEMBERS,
            payload: { teamId: id, members: membersData },
          });
        });
      }
    }
  } catch (error) {
    dispatch({
      type: LOADING_AGENTS_DATA_ERROR,
      errorMsg: error.message,
    });
  }
};

export const getAvatarById = (id) => async (dispatch) => {
  const { msisdn } = await dispatch(getUserById(id));
  const avatar = await dispatch(getUserAvatar(msisdn));
  return avatar;
};

export const updateTeam = (teamId, teamData) => async (dispatch) => {
  if (!navigator.onLine) {
    toast.error("You can not perform this action offline...");
    return;
  }

  dispatch({ type: LOADING_AGENTS_DATA });

  try {
    const deleteTeamResponse = await axios.patch(
      `${currentAPI}/api/teams/editTeam?newDescription=${teamData.description}&newName=${teamData.name}&teamId=${teamId}`,
      null
    );

    if (deleteTeamResponse.status === 200) {
      dispatch({ type: LOADING_AGENTS_DATA_SUCCESS });
      dispatch({ type: UPDATE_TEAM, payload: { teamId, teamData } });
      dispatch(push("/actions/agents"));
    }
  } catch (error) {
    dispatch({
      type: LOADING_AGENTS_DATA_ERROR,
      errorMsg: error.message,
    });

    toast.error("Failed to update team");
  }
};

export const deleteTeam = (teamId) => async (dispatch) => {
  if (!navigator.onLine) {
    toast.error("You can not perform this action offline...");
    return;
  }

  dispatch({ type: LOADING_AGENTS_DATA });

  try {
    const deleteTeamResponse = await axios.delete(
      `${currentAPI}/api/teams/delete?teamId=${teamId}`
    );

    if (deleteTeamResponse.status === 200) {
      dispatch({ type: LOADING_AGENTS_DATA_SUCCESS });
      dispatch({ type: DELETE_TEAM, payload: teamId });
      dispatch(push("/actions/agents"));
    }
  } catch (error) {
    dispatch({
      type: LOADING_AGENTS_DATA_ERROR,
      errorMsg: error.message,
    });

    toast.error("Failed to delete the team");
  }
};

export const getAgentReferrals = () => async (dispatch, getState) => {
  if (!navigator.onLine) return;

  dispatch({ type: LOADING_AGENTS_REFERRALS_DATA });

  try {
    const getAgentReferralsResponse = await axios.get(
      `${currentAPI}/api/users/getReferrals`
    );

    let referralsData =
      getAgentReferralsResponse &&
      getAgentReferralsResponse.data.filter(
        (referral) => referral.role.name === "ROLE_AGENT"
      );

    if (referralsData.length) {
      referralsData = referralsData.map(async (referral) => {
        const lastSeen = await dispatch(getUserLastActiveState(referral.id));
        const rejectionReason =
          referral.data.agentState === "INCOMPLETE"
            ? await dispatch(getRejectedReasons(referral.id))
            : "";

        return {
          id: referral.id,
          firstName: referral.data.firstName,
          lastName: referral.data.lastName,
          avatar: referral.data.image || "",
          lastSeen: formatCreationDate(lastSeen),
          msisdn: referral.data.msisdn,
          role: referral.role.name,
          createdAt: referral.createdAt,
          status:
            referral.data.agentState === "INCOMPLETE"
              ? "rejected"
              : referral.data.agentState.toLowerCase(),
          // rejectionReason: rejectionReason.map( item => item.details )
          rejectionReason,
        };
      });

      const allData = await Promise.all(referralsData);
      dispatch({
        type: SAVE_AGENTS_REFERRAL,
        payload: [...allData].sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        ),
      });
    }
    dispatch({ type: LOADING_AGENTS_REFERRALS_COMPLETED });
  } catch (error) {
    dispatch({ type: LOADING_AGENTS_REFERRALS_COMPLETED });
  }
};

export const getAgentReferralsV2 =
  (page = 1, size = 1500) =>
  async (dispatch, getState) => {
    if (!navigator.onLine) return;

    dispatch({ type: LOADING_AGENTS_REFERRALS_DATA });

    try {
      const getAgentListResponse = await axios.get(
        `${currentAPI}/api/users/getAgentWithActivity/v2?page=${page}&size=${size}`
      );

      if (getAgentListResponse && getAgentListResponse.data) {
        const {
          activityData,
          //pageNumber,
          //pageSize,
          total,
        } = getAgentListResponse.data || {};

        dispatch({
          type: SAVE_REFERRED_AGENTS_COUNT,
          payload: total,
        });

        if (activityData && activityData.length > 0) {
          let referralsData = activityData.map((referral) => {
            const status = referral.userData.agentState
              ? referral.userData.agentState === "INCOMPLETE"
                ? "rejected"
                : referral.userData.agentState.toLowerCase()
              : "pending";

            return {
              id: referral.id,
              createdAt: referral.createdAt,
              lastSeen: formatCreationDate(referral.lastActiveDate),
              firstName: referral.userData.firstName,
              lastName: referral.userData.lastName,
              state: referral.userData.state,
              avatar: referral.userData.avatar || "",
              msisdn: referral.userData.msisdn,
              //role:    referral.role.name,
              status: status,
              //rejectionReason: []
            };
          });
          dispatch({
            type: SAVE_AGENTS_REFERRAL,
            payload: [...referralsData].sort(
              (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
            ),
          });
        }
      }

      dispatch({ type: LOADING_AGENTS_REFERRALS_COMPLETED });
    } catch (error) {
      dispatch({ type: LOADING_AGENTS_REFERRALS_COMPLETED });
    }
  };

export const updateAgentCommissions = () => async (dispatch, getState) => {
  const allCommissions = await dispatch(getAllCommissions());
  const agents = getState().applications.agents.referrals;

  agents.forEach((agent) => {
    const id = agent.id;

    const foundCommissions = allCommissions.transactions.filter(
      (transaction) => {
        return transaction.commissionDetails.generatedByUserID === id;
      }
    );

    const savedMerchantCommissions =
      agent.commissionsList && agent.commissionsList.length > 0
        ? agent.commissionsList.filter((transaction) => {
            return transaction.commissionDetails.generatedByUserID === id;
          })
        : [];

    if (foundCommissions.length > savedMerchantCommissions.length) {
      const totalCommission = referredCommissions(
        allCommissions,
        agent
      ).totalAmount;
      dispatch({
        type: SAVE_AGENTS_COMMISSIONS,
        payload: { id, foundCommissions, totalCommission },
      });
    }
  });
};

export const getAgentActivationStatus = () => async (dispatch) => {
  if (!navigator.onLine) return;

  try {
    const activationStatusResponse = await axios.get(
      `${currentAPI}/api/users/userData`
    );

    if (activationStatusResponse.status === 200) {
      const { agentState } = activationStatusResponse.data;
      agentState && dispatch(saveUserKycVerificationState(agentState));

      dispatch({
        type: AGENT_ACTIVATION_STATUS_SUCCESS,
        payload: activationStatusResponse.data,
      });
    }
  } catch (error) {
    //
  }
};

export const getRejectedReasons = (id, cb) => async () => {
  if (!navigator.onLine) return [];

  try {
    const reasonsResponse = await axios.get(
      `
            ${currentAPI}/api/rejectedReasons/byAgentId`,
      {
        params: {
          agentId: id,
        },
      }
    );

    if (reasonsResponse.status === 200) {
      if (cb) {
        cb(reasonsResponse.data);
      } else return reasonsResponse.data;
    }
    return [];
  } catch (error) {
    return [];
  }
};
