import React, { Fragment, useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import {
  InfiniteScrollList,
  TopHeader,
  DeliveryLocationPopup,
} from "../../../../components";
import { SearchInputWithCancel } from "../../../../components/forms/input/search-input/SearchInput";
import { merchbuyActions } from "../../../../redux/ducks/applications/merchbuy/actions";
import DesktopBackgroundLayout from "../../../DesktopBackgroundLayout";
import { ListShops } from "../components/";
import {
  Main,
  AddFlexBox,
  CenterText,
  Space,
  DetailsContainer,
} from "../styles";

export const ShopsContainer = styled.div`
  margin: 20px;
`;

const MerchbuyShops = () => {
  const history = useHistory();
  const [shopsToDisplay, setShopsToDisplay] = useState([]);
  const totalElements = useSelector(
    (state) => state.applications.merchbuy.totalElements
  );
  const perPage = 50;
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);

  const dispatch = useDispatch();
  const shops = useSelector(
    (state) => state.applications.merchbuy.merchbuyShops
  );

  const [searchValue, setSearchValue] = useState("");
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const [deliveryLocation, setDeliveryLocation] = useState(
    JSON.parse(localStorage.getItem("deliveryLocation"))
  );
  const [openDeliverLocationPopup, setOpenDeliverLocationPopup] = useState(
    deliveryLocation === null
  );

  const getLocation = (data) => {
    if (data.state) {
      setDeliveryLocation(data);
      setOpenDeliverLocationPopup(!openDeliverLocationPopup);
    }
  };

  const addMoreDataToDisplay = async () => {
    if (shopsToDisplay.length < totalElements) {
      const { state } = deliveryLocation || {};
      const data = await dispatch(
        merchbuyActions.paginatedShopHistory(state, page, perPage, searchValue)
      );
      setShopsToDisplay([...shopsToDisplay, ...data]);
      setPage(page + 1);
    } else {
      setHasMore(false);
    }
  };

  useEffect(() => {
    const { state } = deliveryLocation || {};
    dispatch(merchbuyActions.getAllShops(state, perPage));
  }, [deliveryLocation, dispatch, openDeliverLocationPopup]);

  useEffect(() => {
    const InitiateSearch = setTimeout(() => {
      const { state } = deliveryLocation || {};
      dispatch(merchbuyActions.getAllShops(state, perPage, searchValue)).then(
        (res) => {
          setIsDataAvailable(true);
        }
      );
    }, 1500);
    return () => clearTimeout(InitiateSearch);
  }, [deliveryLocation, dispatch, searchValue]);

  useEffect(() => {
    shops && setShopsToDisplay(shops.slice(0, perPage));
    if (shops && shops.length < totalElements) {
      setPage(2);
      setHasMore(true);
    }
  }, [shops, totalElements]);

  return (
    <Fragment>
      <DesktopBackgroundLayout>
        <TopHeader title="Shops" size="14px" />
        <Main>
          <ShopsContainer>
            <SearchInputWithCancel
              placeholder="Search for a stores"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                !searchValue && setIsDataAvailable(false);
              }}
              onCancel={() => {
                setSearchValue("");
                history.goBack();
              }}
              showCancelOnInteract
              onCancelInputValue={() => setSearchValue("")}
            />
          </ShopsContainer>
          <DetailsContainer>
            {searchValue && isDataAvailable ? (
              <CenterText>
                {" "}
                Showing{" "}
                <span style={{ color: "black" }}>
                  {shopsToDisplay && shopsToDisplay.length}
                </span>{" "}
                result{" "}
              </CenterText>
            ) : (
              <CenterText> Showing all </CenterText>
            )}
            <InfiniteScrollList
              data={shopsToDisplay}
              fetchMore={addMoreDataToDisplay}
              hasMore={hasMore}
              endMessage=""
            >
              <AddFlexBox>
                <ListShops
                  data={shopsToDisplay}
                  size={{ width: "100%", height: "64px" }}
                />
              </AddFlexBox>
            </InfiniteScrollList>
          </DetailsContainer>
          <Space height="50px" />
        </Main>
        {openDeliverLocationPopup && (
          <DeliveryLocationPopup
            open={openDeliverLocationPopup}
            getLocation={getLocation}
            cancel={() => {
              setOpenDeliverLocationPopup(!openDeliverLocationPopup);
            }}
          />
        )}
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default MerchbuyShops;
