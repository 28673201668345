import React, { Fragment } from "react";

import styled from "styled-components";

import { RippleLink } from "../../../../../components";
import { MERCHBUY_INTERACTION } from "../../../../../utils/mix-panel/constants";
import { mixPanel } from "../../../../../utils/mix-panel/mixPanel";
import { ReactComponent as PopularProducts } from "../../assets/popularProducts.svg";
import { ReactComponent as ProductCategories } from "../../assets/productCategories.svg";
import { ReactComponent as RecommendedProducts } from "../../assets/recommendedProducts.svg";
import { ReactComponent as ShopsSvg } from "../../assets/ShopsIcon.svg";

const IconStyles = `
    width: 32px;
    height: 32px;
    border-radius: 8px;
`;

const ProductCategoriesIcon = styled(ProductCategories)`
  ${IconStyles}
`;

const PopularProductsIcon = styled(PopularProducts)`
  ${IconStyles}
`;

const RecommendedProductsIcon = styled(RecommendedProducts)`
  ${IconStyles}
`;

const ShopsIcon = styled(ShopsSvg)`
  ${IconStyles}
`;

const list = [
  {
    icons: <ProductCategoriesIcon />,
    title: "Categories",
    link: "/actions/merchbuy/product-categories",
  },
  {
    icons: <PopularProductsIcon />,
    title: "Promotions",
    link: "/actions/merchbuy/popular-products",
  },
  {
    icons: <RecommendedProductsIcon />,
    title: "Recommended",
    link: "/actions/merchbuy/recommended-products",
  },
  {
    icons: <ShopsIcon />,
    title: "Shops",
    link: "/actions/merchbuy/shops",
  },
];

const IconsMenuSection = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  margin-bottom: 32px;
`;

const IconsMenuItem = styled.div`
  flex: 1;
`;
const IconBox = styled.div`
  width: 100%;
  display: inline-flex;
  vertical-align: top;
  justify-content: center;
  align-items: center;
`;
const Title = styled.p`
  color: #29394f;
  font-size: 10px;
  word-spacing: 100vw;
  line-height: 1.5;
  text-align: center;
`;

export const TopNavIconsMenu = () => {
  return (
    <Fragment>
      <IconsMenuSection>
        {list.map((item, index) => (
          <IconsMenuItem
            key={index}
            onClick={() => {
              mixPanel.track(MERCHBUY_INTERACTION, {
                "Icon Name": item.title,
                Time: new Date().toLocaleString(),
              });
            }}
          >
            <RippleLink to={item.link}>
              <IconBox>{item.icons}</IconBox>
              <Title>{item.title}</Title>
            </RippleLink>
          </IconsMenuItem>
        ))}
      </IconsMenuSection>
    </Fragment>
  );
};
