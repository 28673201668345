import { toast } from "react-toastify";

import { currentAPI, axios } from "../../../../../config";
import { LOADING } from "../constants";

const getLinkedDevices = (phoneNumber) => async (dispatch) => {
  dispatch({ type: LOADING, payload: true });

  try {
    const response = await axios.get(
      `${currentAPI}/api/shs/customer/linkedDevices/${phoneNumber}`
    );

    if (response.status === 200) {
      dispatch({ type: LOADING, payload: false });
      return response.data;
    }
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    if (
      error.response &&
      (error.response.status === 400 || error.response.status === 404)
    ) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.error);
    }
  }
};

const billPayment = (payload) => async (dispatch) => {
  dispatch({ type: LOADING, payload: true });

  try {
    const response = await axios.post(
      `${currentAPI}/api/shs/process/shsBillPayment`,
      payload
    );
    if (response.status === 200) {
      dispatch({ type: LOADING, payload: false });
      return response.data;
    }
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
  }
};

const getShsRate = (shsTime) => async (dispatch) => {
  dispatch({ type: LOADING, payload: true });

  try {
    const response = await axios.get(`${currentAPI}/api/shs/rate/${shsTime}`);
    if (response.status === 200) {
      const { rate } = response.data;
      dispatch({ type: LOADING, payload: false });
      return rate;
    }
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
  }
};

const verifyPin = (pin) => async (dispatch) => {
  dispatch({ type: LOADING, payload: true });

  try {
    const response = await axios.post(
      `${currentAPI}/api/users/verifyTransactionPin`,
      { transactionPin: pin }
    );
    if (response.status === 200) {
      const { verifiedTransactionPin } = response.data;
      dispatch({ type: LOADING, payload: false });
      return verifiedTransactionPin;
    }
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    if (error.response && error.response.status === 404) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.error);
    }
  }
};

const updateTransactionPin = (oldPin, newPin) => async (dispatch) => {
  dispatch({ type: LOADING, payload: true });

  try {
    const response = await axios.put(
      `${currentAPI}/api/users/updateTransactionPin`,
      {
        newTransactionPin: newPin,
        oldTransactionPin: oldPin,
      }
    );
    if (response.status === 200) {
      dispatch({ type: LOADING, payload: false });
      return response.data.status;
    }
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    if (error.response && error.response.status === 409) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.error);
    }
  }
};

export const shsActions = {
  getLinkedDevices,
  billPayment,
  verifyPin,
  getShsRate,
  updateTransactionPin,
};
