import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { TopHeader } from "../../../../components";
import { List } from "../../../../containers/ListContainer";
import { ScreenContainer } from "../../../../containers/ScreenContainer";
import { getPaymentLinkTransactions } from "../../../../redux/ducks/account/transactions/actions";
import { colors } from "../../../../styles";
import DesktopBackgroundLayout from "../../../DesktopBackgroundLayout";
import TransactionCard from "../../../home/transactions/transaction-card";
import CreatePaymentButton from "../create-link/create-payment-botton";

const LinkDetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: url(${require("../assets/rectangle.png")});
  padding: 0 58px;
  background-blend-mode: soft-lighlat;
  border-radius: 5px;
  width: 346px;
  height: 100px;
  background-repeat: round;
  margin-top: 13px;
`;

const LinkDetailsInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TotalText = styled.p`
  font-size: 20px;
  font-weight: 700;
  color: #071827;
  margin: 0;
`;

const LinkText = styled.p`
  color: #0413e7;
  font-size: 12px;
  font-weight: 400;
  margin: 0;
`;

const ViewAllContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 32px 0 0;
`;
const CreatedLinkText = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #5c6f7f;
`;

const ViewAllText = styled.p`
  font-size: 12px;
  font-weight: 500;
  color: #227eef;
`;

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PaymentLinks = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getPaymentLinkTransactions());
  }, [dispatch]);

  const [pendingPayment, setPendingPayment] = useState(0);
  const [closedPayment, setClosedPayment] = useState(0);

  const transactions = useSelector(
    (state) => state.account.transactions.paymentTransactionsList
  );

  useEffect(() => {
    if (transactions?.length > 0) {
      setPendingPayment(
        transactions.filter((x) => x?.status === "PENDING")?.length
      );
      setClosedPayment(
        transactions.filter((x) => x?.status !== "PENDING")?.length
      );
    }
  }, [transactions]);

  return (
    <DesktopBackgroundLayout>
      <TopHeader
        backLink="/my-shop"
        title="Payment Link"
        noBorderBottom
      ></TopHeader>
      <ScreenContainer color={colors.notificationBg}>
        <LinkContainer>
          <LinkDetailsContainer>
            <LinkDetailsInnerWrapper>
              <TotalText>{pendingPayment}</TotalText>
              <LinkText>Pending Links</LinkText>
            </LinkDetailsInnerWrapper>
            <LinkDetailsInnerWrapper>
              <TotalText>{closedPayment}</TotalText>
              <LinkText>Closed Links</LinkText>
            </LinkDetailsInnerWrapper>
          </LinkDetailsContainer>
        </LinkContainer>
        <ViewAllContainer>
          <CreatedLinkText>Your Created Links</CreatedLinkText>
          <ViewAllText
            onClick={() => history.push("/actions/payment-links/transactions")}
          >
            VIEW ALL
          </ViewAllText>
        </ViewAllContainer>
        <List noBorderBottom fullScreen>
          {transactions &&
            transactions
              .sort(
                (a, b) =>
                  new Date(b.createdAt).getTime() -
                  new Date(a.createdAt).getTime()
              )
              .slice(0, 6)
              .map((transaction, index) => (
                <TransactionCard
                  transaction={transaction}
                  key={index}
                  pathname={"/actions/payment-links/details"}
                  background={colors.white}
                  containerWidth={"90%"}
                />
              ))}
        </List>
        <CreatePaymentButton />
      </ScreenContainer>
    </DesktopBackgroundLayout>
  );
};

export default PaymentLinks;
