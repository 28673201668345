import React, { Fragment, useState, useEffect } from "react";

import { Form, Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  TopHeader,
  AmountInputWithLabel,
  TextInputWithLabel,
  SelectBox,
  RippleButton,
  FileInput3,
  SuccessPage,
} from "../../../../components";
import { BlurBackground } from "../../../../containers/BlurBackground";
import { ScreenContainer } from "../../../../containers/ScreenContainer";
import { remittanceActions } from "../../../../redux/ducks/applications/remittance/actions";
import { colors } from "../../../../styles";
import { formatPrice } from "../../../../utils/currency/formatPriceWithComma";
import { formatCurrency } from "../../../../utils/currency/parseBalance";
import {
  REMITTANCE_MULTIPLE_ORDER_START,
  REMITTANCE_MULTIPLE_ORDER_FINISH,
} from "../../../../utils/mix-panel/constants";
import { mixPanel } from "../../../../utils/mix-panel/mixPanel";
import DesktopBackgroundLayout from "../../../DesktopBackgroundLayout";
import { ReactComponent as Down } from "../assets/arrow_bottom_right.svg";
import { ReactComponent as Up } from "../assets/arrow_top_right.svg";
import {
  OrderDetailCards,
  FlexContainer,
  Text,
  PageText,
  PageSubText,
  InputBlock,
  StyledAddOrderIcon,
  Backdrop,
  ModalContainer,
  EmptyStateText,
  OrderNumber,
  ContainerDiv,
  RemittanceAmountStatus,
  AmountText,
} from "../styles";

import { MultipleRemitValidationSchema } from "./MultipleRemitValidationSchema";
import { OrdersList } from "./ordersList";

export const RemittingMultipleOrder = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const isLoading = useSelector(
    (state) => state.applications.remittance.isLoading
  );
  const [showSuccessPage, setShowSucessPage] = useState(false);
  const [remittanceStatus, setRemittanceStatus] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [openOrderList, setOpenOrderList] = useState(false);
  const [openModalPopUp, setOpenModalPopUp] = useState(false);
  const [clickedOrder, setClickedOrder] = useState({});
  const [total, setTotal] = useState(0);
  const [values, setValues] = useState({});
  const [amountDiff, setAmountDiff] = useState(0);

  const banks = [
    { value: "zenith", label: "Zenith - 1214403902" },
    { value: "gtb", label: "GTB - 0627779713" },
  ];

  useEffect(() => {
    selectedOrders &&
      setTotal(
        selectedOrders.reduce(
          (accum, current) => Number(accum) + Number(current.amount),
          0
        )
      );

    setAmountDiff(total - (values.amount || 0));
  }, [selectedOrders, values, total]);

  const onSubmit = async (values, { setErrors }) => {
    if (Number(values.amount) > Number(convertToPositive(total))) {
      return setErrors({
        amount: "Paid amount is greater than remitted amount",
      });
    }

    const orders = selectedOrders.reduce(
      (accum, current) => [
        ...accum,
        {
          orderId: current.orderId,
          orderNumber: current.orderNumber,
          paidAmount: current.amount,
          collectionAmounts: [
            {
              collectionMedium: 2,
              amount: current.amount,
            },
          ],
        },
      ],
      []
    );

    const payload = {
      outstandingOrders: orders,
      partnerReference: values.bankAccount,
      receiptBase64String: values.receiptBase64String,
      totalPaidAmount: values.amount,
      transactionReference: values.transactionReference,
    };
    const res = await dispatch(remittanceActions.logRemittance(payload));
    res && setShowSucessPage(true);
    res && setRemittanceStatus(true);
    res && mixPanel.track(REMITTANCE_MULTIPLE_ORDER_FINISH, {});
  };

  const onSubmitModal = (values, { setErrors }) => {
    if (
      Number(values.amount) >
      Number(convertToPositive(clickedOrder.outstandingAmount))
    ) {
      return setErrors({
        amount: "Paid amount is greater than outstanding amount",
      });
    }
    const filteredOrders = selectedOrders.filter(
      (x) => x.orderId !== clickedOrder.orderId
    );
    setSelectedOrders([
      ...filteredOrders,
      { ...clickedOrder, amount: values.amount },
    ]);
    setOpenModalPopUp(false);
  };

  const convertToPositive = (amount) => {
    if (amount < 0) {
      return amount * -1;
    }
    return amount;
  };

  useEffect(() => {
    mixPanel.track(REMITTANCE_MULTIPLE_ORDER_START, {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DesktopBackgroundLayout>
      <Fragment>
        {showSuccessPage ? (
          <SuccessPage
            title={`${
              remittanceStatus ? "Remittance Successful" : "Remittance Failed!"
            }`}
            subtitle={
              remittanceStatus
                ? "Your payment has been logged successfully. You will be notified once it’s been reviewed."
                : "We couldn't complete your transaction. Kindy try again"
            }
            successfull={remittanceStatus}
            doneText={remittanceStatus ? "Go to Home" : "Try Again"}
            detailText="View remittance"
            onDoneClick={() => {
              if (remittanceStatus) {
                history.push("/actions/remittance/dashboard");
              } else {
                setShowSucessPage(false);
              }
            }}
            onDetailClick={async () => {
              history.push({
                pathname: "/actions/remittance/remittance-details/",
                state: null,
              });
            }}
          />
        ) : (
          <Fragment>
            {!openOrderList && (
              <>
                {!openModalPopUp && (
                  <TopHeader title="Remit Order" size="14px" noBorderBottom />
                )}

                {openModalPopUp && (
                  <BlurBackground position="fixed">
                    <Backdrop onClick={() => setOpenModalPopUp(false)} />
                    <ModalContainer>
                      <EmptyStateText
                        align="start"
                        size="14px"
                        color={"#353645"}
                      >
                        How much are you paying for this order?
                      </EmptyStateText>
                      <OrderNumber margin="19px 0 4px 0">{`Order No. ${
                        clickedOrder.orderNumber
                      } - ${clickedOrder.customer?.name || ""}`}</OrderNumber>
                      <ContainerDiv bottom="24px">
                        <Text right="4px">Balance left</Text>
                        <Text color={colors.darkRed}>
                          {formatPrice(
                            convertToPositive(clickedOrder.outstandingAmount) ||
                              0
                          )}
                        </Text>
                      </ContainerDiv>

                      <Formik
                        initialValues={{
                          amount: clickedOrder.amount || "",
                        }}
                        onSubmit={onSubmitModal}
                      >
                        {({ initialValues }) => {
                          return (
                            <Form>
                              <AmountInputWithLabel
                                label="Amount"
                                placeholder={"Amount"}
                                name="amount"
                                autocomplete={"off"}
                                allowDecimal
                                initialValues={initialValues}
                              />

                              <RippleButton
                                width="100%"
                                top="32px"
                                type="submit"
                                bottom="32px"
                              >
                                Add Order
                              </RippleButton>
                            </Form>
                          );
                        }}
                      </Formik>
                    </ModalContainer>
                  </BlurBackground>
                )}

                <ScreenContainer top="65px">
                  <Formik
                    initialValues={{
                      amount: values.amount || "",
                      receiptBase64String: values.receiptBase64String || "",
                      bankAccount: values.bankAccount || "",
                      transactionReference: values.transactionReference || "",
                    }}
                    validationSchema={MultipleRemitValidationSchema}
                    onSubmit={onSubmit}
                  >
                    {({ errors, touched, setFieldValue, values }) => {
                      setValues(values);

                      return (
                        <Form>
                          <InputBlock>
                            <PageText> How much do you want to remit?</PageText>
                            <PageSubText>
                              Make sure the amount matches your provided proof
                              of payment
                            </PageSubText>
                            <AmountInputWithLabel
                              label="Amount"
                              placeholder={"Amount"}
                              name="amount"
                              autocomplete={"off"}
                              allowDecimal
                            />

                            <PageText top="0">Orders</PageText>
                            <PageSubText bottom="0">
                              Select which of the orders you are paying for
                            </PageSubText>
                            {selectedOrders &&
                              selectedOrders.map((item, index) => (
                                <OrderDetailCards key={index}>
                                  <FlexContainer>
                                    <Text
                                      color={colors.themeTxtColor10}
                                    >{`Order ${item.orderId}`}</Text>
                                    <Text
                                      weight="500"
                                      color={colors.deepBlue}
                                      size="10px"
                                      lineHeight="16px"
                                      onClick={() => {
                                        setClickedOrder(item);
                                        setOpenModalPopUp(true);
                                      }}
                                    >
                                      Edit
                                    </Text>
                                  </FlexContainer>
                                  <FlexContainer>
                                    <Text opacity={0.8}>
                                      {item.customer?.name || ""}
                                    </Text>
                                    <Text
                                      weight="500"
                                      color={colors.themeTxtColor10}
                                    >
                                      {formatPrice(item.amount || 0)}
                                    </Text>
                                  </FlexContainer>
                                </OrderDetailCards>
                              ))}

                            <StyledAddOrderIcon
                              onClick={() => setOpenOrderList(true)}
                            />

                            {selectedOrders?.length > 0 && (
                              <>
                                <FlexContainer padding="40px 0 16px">
                                  <Text
                                    size="14px"
                                    lineHeight="21px"
                                    weight="700"
                                    color={colors.boldDark}
                                  >
                                    Total Order
                                  </Text>
                                  <Text
                                    size="14px"
                                    lineHeight="21px"
                                    weight="700"
                                    color={colors.boldDark}
                                  >
                                    {formatPrice(total || 0)}
                                  </Text>
                                </FlexContainer>

                                {amountDiff !== 0 && (
                                  <RemittanceAmountStatus>
                                    {amountDiff > 0 && <Up />}
                                    {amountDiff < 0 && <Down />}
                                    <div>
                                      <AmountText>
                                        <Text
                                          diplay="flex"
                                          left="8px"
                                          size="14px"
                                          lineHeight="21px"
                                        >
                                          Your order is
                                          <Text
                                            diplay="inline"
                                            left="2px"
                                            right="2px"
                                            weight="700"
                                            color={
                                              amountDiff > 0
                                                ? colors.textGreen
                                                : colors.red
                                            }
                                            size="14px"
                                            lineHeight="21px"
                                          >
                                            {formatCurrency(
                                              convertToPositive(amountDiff)
                                            )}
                                          </Text>
                                          {amountDiff > 0 ? "above" : "below"}{" "}
                                          remitted amount
                                        </Text>
                                      </AmountText>
                                      <Text left="8px" top="4px">
                                        click
                                        <Text
                                          left="2px"
                                          right="2px"
                                          weight="700"
                                        >
                                          edit
                                        </Text>
                                        to correct amount
                                      </Text>
                                    </div>
                                  </RemittanceAmountStatus>
                                )}
                              </>
                            )}

                            <PageText>Enter transaction reference</PageText>
                            <PageSubText>
                              Kindly provide the transaction reference on your
                              deposit receipt
                            </PageSubText>
                            <TextInputWithLabel
                              label="Reference number"
                              placeholder="Reference number"
                              name="transactionReference"
                            />

                            <PageText top="0">
                              Upload transaction receipt
                            </PageText>
                            <PageSubText>
                              Upload a proof of payment (bank teller or transfer
                              receipt) so we can start remit your payment
                            </PageSubText>
                            <FileInput3
                              mimeType={"image/*"}
                              name="receiptBase64String"
                              compressFile
                              base64String
                              placeholder={"Click to Upload Document here"}
                              value={values.receiptBase64String}
                              label="Proof of payment"
                              handleChange={(value) => {
                                if (value) {
                                  var base64result = value.substr(
                                    value.indexOf(",") + 1
                                  );
                                  setFieldValue(
                                    "receiptBase64String",
                                    base64result
                                  );
                                }
                              }}
                            />

                            <PageText> What account did you remit to?</PageText>
                            <PageSubText>
                              {" "}
                              Kindly provide the bank details that you made
                              payment to
                            </PageSubText>
                            <SelectBox
                              top="0px"
                              placeholder={"Bank Name"}
                              value={values.bankAccount}
                              title="Select Account"
                              options={banks}
                              padding={"20px 0 10px"}
                              mbottom={"0px"}
                              checkable
                              showSearchBar={false}
                              handleChange={(selected) => {
                                setFieldValue("bankAccount", selected.label);
                              }}
                              valid={`${
                                !touched.bankAccount && !errors.bankAccount
                              }`}
                              error={
                                touched &&
                                touched.bankAccount &&
                                errors &&
                                errors.bankAccount
                              }
                            />
                          </InputBlock>

                          <RippleButton
                            disabled={
                              !values.amount ||
                              !values.receiptBase64String ||
                              !values.bankAccount ||
                              !values.transactionReference ||
                              !selectedOrders?.length ||
                              amountDiff !== 0 ||
                              isLoading
                            }
                            type="submit"
                          >
                            Submit
                          </RippleButton>
                        </Form>
                      );
                    }}
                  </Formik>
                </ScreenContainer>
              </>
            )}

            {openOrderList && (
              <OrdersList
                open={openOrderList}
                setOpen={setOpenOrderList}
                setSelectedOrders={setSelectedOrders}
                selectedOrders={selectedOrders}
              />
            )}
          </Fragment>
        )}
      </Fragment>
    </DesktopBackgroundLayout>
  );
};
