import React, { Fragment, useState, useEffect } from "react";

import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";

import {
  TopHeader,
  AmountInputWithLabel,
  TextInputWithLabel,
  UploadPicture,
  Loader,
  SwitchTrigger2,
  PageLogo,
} from "../../../../../../../components";
import {
  ImageCropper,
  toDataURL,
} from "../../../../../../../components/popup/image-cropper";
import { InputBlock } from "../../../../../../../containers/InputContainer";
import {
  List,
  ListHeading,
  ListItem,
} from "../../../../../../../containers/ListContainer";
import {
  SubTitle,
  Message,
  SecondaryText,
} from "../../../../../../../containers/MessageContainer";
import {
  ScreenContainer,
  FlexContainer,
} from "../../../../../../../containers/ScreenContainer";
import * as actions from "../../../../../../../redux/ducks/applications/my-shop/actions/shop";
import { colors } from "../../../../../../../styles";
import { formatPrice } from "../../../../../../../utils/currency/formatPriceWithComma";
import { toTitleCase } from "../../../../../../../utils/toTitleCase";
import DesktopBackgroundLayout from "../../../../../../DesktopBackgroundLayout";
import DefaultIcon from "../../assets/default_product.svg";
import { ReactComponent as CloseIcon } from "../../assets/icon-close.svg";
import { ReactComponent as InfoIcon } from "../../assets/new-info-arrow.svg";

import { AddProductValidationSchema } from "./AddProductValidationSchema";

const AddProductContainer = styled.div`
  margin-top: 15px;
`;

const SaveText = styled(SecondaryText)`
  text-transform: uppercase;
  position: absolute;
  right: 16px;
  font-size: 14px;
  background-color: transparent;
  border: none;
`;

const Title = styled.p`
  font-size: ${({ size }) => size || "16px"};
  font-weight: ${({ weight }) => weight || "700"};
  line-height: ${({ lineHeight }) => lineHeight || "24px"};
  color: ${({ color }) => color || colors.boldDark};
`;

const PicturesContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
  transition: "all 1s linear";

  & > *:not(:last-child) {
    margin-right: 8px;
  }
`;

const ProfitWrapper = styled.div`
  margin-bottom: 16px;
`;

const MasterListContainer = styled.div`
  padding: 15px 16px;
  margin: 12px 0;
  border: 1px solid ${colors.lightGray};
  border-radius: 8px;
`;

const SpacedDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AddSingleProductStep1 = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const isLoading = useSelector((state) => state.applications.myShop.isLoading);
  const shops = useSelector((state) => state.applications.myShop.shops);
  const currentShop = shops[0];
  const [openMasterList, setOpenMasterList] = useState(false);
  const [showCropper, setShowCropper] = useState(false);
  const [picture, setPicture] = useState();
  const [formName, setFormName] = useState();
  const [pImages, setPImages] = useState({});
  const [quantityVal, setQuantityVal] = useState(1);
  const [productName, setProductName] = useState(
    location?.state?.productName || ""
  );
  const [costPrice, setCostPrice] = useState();
  const [sellingPrice, setSellingPrice] = useState();
  const [masterListImageUrl, setMasterListImageUrl] = useState();
  const [showUploadImage, setShowUploadImage] = useState(false);
  const productsMasterList = useSelector(
    (state) => state.applications.myShop.productsMasterList
  );
  const isOffline = useSelector((state) => state.offline.isOffline);
  const [searchTerm, setSearchTerm] = useState("");

  const setSelectedProduct = (itemName, itemImage) => {
    itemName && setProductName(itemName);
    itemImage && setPImages({ ...pImages, upload0: itemImage });
    itemImage && setMasterListImageUrl(itemImage);
  };

  const pAction = (formName, image) => {
    setPicture(image);
    setFormName(formName);
    setShowCropper(true);
  };

  const onCropperCancel = () => {
    setShowCropper(false);
  };

  const onCropperSave = async (url) => {
    if (url) {
      const image = await toDataURL(url);
      setPImages({ ...pImages, [formName]: image });
      setShowCropper(false);
    } else {
      toast.error("Image is still processing, wait for a sec...");
    }
  };

  const onImageRemove = (formName) => {
    const images = { ...pImages };
    delete images[formName];
    if (formName === "upload0") {
      setMasterListImageUrl("");
    }

    const entries = Object.keys(images).flatMap((key) => [images[key]]);
    var newEntry = {};
    for (let i = 0; i < entries.length; i++) {
      newEntry = { ...newEntry, [`upload${i}`]: entries[i] };
    }
    setPImages(newEntry);
  };

  const calculateProfit = () => {
    if (!costPrice || !sellingPrice || !quantityVal) return;
    const profit =
      (Number(sellingPrice) - Number(costPrice)) * Number(quantityVal);
    return profit;
  };

  useEffect(() => {
    dispatch(actions.getProductCategories());
  }, [dispatch]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      searchTerm &&
        dispatch(
          actions.searchProductsOnMasterList(currentShop.branchId, searchTerm)
        );
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchTerm]);

  useEffect(() => {
    setProductName(location?.state?.productName || "");
  }, [location]);

  return isLoading ? (
    <Loader />
  ) : (
    <Fragment>
      <DesktopBackgroundLayout>
        <TopHeader
          size="14px"
          color={colors.boldDark}
          weight="400"
          title={"New Product"}
          noBorderBottom
        >
          <SaveText
            as="button"
            form="form"
            type="submit"
            color={colors.deepBlue}
            top="14px"
            weight="700"
            lineHeight="21px"
            disabled={
              !sellingPrice ||
              !productName ||
              !quantityVal ||
              quantityVal < 1 ||
              isLoading ||
              (costPrice && costPrice > sellingPrice)
            }
          >
            Save
          </SaveText>
        </TopHeader>

        <ScreenContainer paddingBottom={"100px"}>
          <Formik
            initialValues={{
              productName: productName || "",
              productDescription: "",
              productCategory: "",
              productUnit: "",
              costPrice: costPrice || "",
              quantity: quantityVal || "",
              localCreatedDate: Date.now(),
              retailUnitPrice: sellingPrice || "",
              availableAtRetailPrice: false,
              availableAtWholesalePrice: false,
              bulkPrices: [{ price: "", moq: "" }],
              base64ProductImageString: "",
              otherImagesBase64String: [],
              images: {
                baseImageUrl: "",
                others: [],
              },
              masterListImageUrl: masterListImageUrl || "",
              localSettings: {
                hasInventory: true,
              },
            }}
            enableReinitialize
            validationSchema={AddProductValidationSchema}
            onSubmit={async (values, { setErrors }) => {
              let images = Object.entries(pImages).map(
                (entries) => entries && entries[1]
              );
              values = {
                ...values,
                quantity: values.quantity > 1 ? values.quantity : 1,
                productName: toTitleCase(values.productName),
                base64ProductImageString: masterListImageUrl
                  ? ""
                  : (images && images[0]) || "",
                otherImagesBase64String: [
                  (images && images[1]) || "",
                  (images && images[2]) || "",
                ],
                images: {
                  baseImageUrl: masterListImageUrl
                    ? ""
                    : (images && images[0]) || "",
                  others: [
                    (images && images[1]) || "",
                    (images && images[2]) || "",
                  ],
                },
              };

              const res = await dispatch(
                actions.addProductV2(currentShop.id, values, setErrors)
              );
              res &&
                res.status &&
                history.replace({
                  pathname: "/actions/shop_single_product_success",
                  state: { product: res.productData, source: "Created" },
                });
            }}
          >
            {({ errors, touched, values, setFieldValue, initialValues }) => {
              return (
                <AddProductContainer>
                  {showCropper && (
                    <ImageCropper
                      avatar={picture}
                      onCancel={onCropperCancel}
                      onSave={onCropperSave}
                    />
                  )}
                  <Form id="form">
                    <Title>Add New Product</Title>
                    <InputBlock>
                      <TextInputWithLabel
                        label={"Product Name"}
                        type={"text"}
                        placeholder={"Product Name"}
                        autoComplete={"true"}
                        name="productName"
                        valid={`${!touched.productName && !errors.productName}`}
                        errors={
                          touched &&
                          touched.productName &&
                          errors &&
                          errors.productName
                        }
                        onChange={(e) => setProductName(e.target.value)}
                        onFocus={(e) => {
                          if (isOffline === false) {
                            setSearchTerm(e.target.value);
                            setOpenMasterList(true);
                          }
                        }}
                        onKeyUp={(e) => {
                          if (isOffline === false) {
                            setSearchTerm(e.target.value);

                            const masterListProduct =
                              productsMasterList &&
                              productsMasterList.find(
                                (product) =>
                                  product.itemName === values.productName
                              );

                            if (
                              masterListProduct &&
                              masterListProduct.masterListImageUrl
                            ) {
                              setFieldValue(
                                "productName",
                                masterListProduct.itemName
                              );
                              setFieldValue(
                                "masterListImageUrl",
                                masterListProduct.masterListImageUrl
                              );
                            }
                          }
                        }}
                        initialValues={initialValues}
                        description="E.g Nestle Milo"
                      />
                      {openMasterList &&
                        productsMasterList &&
                        productsMasterList.length > 0 && (
                          <MasterListContainer>
                            <SpacedDiv>
                              <Title
                                color={colors.lightDark}
                                weight="500"
                                size="14px"
                                lineHeight="21px"
                              >
                                Suggestions
                              </Title>
                              <CloseIcon
                                onClick={() => setOpenMasterList(false)}
                              />
                            </SpacedDiv>
                            <List style={{ border: "none" }} fullScreen>
                              {productsMasterList &&
                                productsMasterList.map((item, index) => (
                                  <ListItem
                                    key={index}
                                    bottom={"8px"}
                                    noBorderBottom
                                    align="center"
                                  >
                                    <PageLogo
                                      width={"32px"}
                                      height={"32px"}
                                      iconWidth={"16px"}
                                      iconHeight={"20px"}
                                      Icon={
                                        item.masterListImageUrl ||
                                        DefaultIcon ||
                                        ""
                                      }
                                      background={colors.gray4}
                                      borderRadius="4px"
                                      objectFit="cover"
                                    />
                                    <ListHeading
                                      style={{ margin: "0 0 0 8px" }}
                                      onClick={() => {
                                        setSelectedProduct(
                                          item.itemName,
                                          item.masterListImageUrl
                                        );
                                        setShowUploadImage(true);
                                        dispatch(
                                          actions.clearProductsOnMasterList()
                                        );
                                      }}
                                    >
                                      {item.itemName}
                                    </ListHeading>
                                  </ListItem>
                                ))}
                            </List>
                          </MasterListContainer>
                        )}

                      <FlexContainer bottom="11px">
                        <SubTitle
                          color={colors.themeColor2}
                          font="12px"
                          lineHeight="18px"
                        >
                          {" "}
                          Add Product Image
                        </SubTitle>
                        <SwitchTrigger2
                          checkStatus={showUploadImage}
                          switchStatus={() => {
                            if (
                              showUploadImage &&
                              Object.keys(pImages).length > 0
                            ) {
                              setPImages({});
                              setMasterListImageUrl("");
                            }
                            setShowUploadImage(!showUploadImage);
                          }}
                        />
                      </FlexContainer>
                      {showUploadImage && (
                        <PicturesContainer>
                          <UploadPicture
                            text={"Upload"}
                            pictureAction={pAction}
                            formName={"upload0"}
                            version2={true}
                            removeImage={onImageRemove}
                            picture={pImages && pImages["upload0"]}
                          />
                          <UploadPicture
                            text={"Upload"}
                            pictureAction={pAction}
                            formName={"upload1"}
                            version2={true}
                            removeImage={onImageRemove}
                            picture={pImages && pImages["upload1"]}
                            disabled={pImages && !pImages["upload0"]}
                          />
                          <UploadPicture
                            text={"Upload"}
                            pictureAction={pAction}
                            formName={"upload2"}
                            version2={true}
                            removeImage={onImageRemove}
                            picture={pImages && pImages["upload2"]}
                            disabled={
                              pImages &&
                              (!pImages["upload0"] || !pImages["upload1"])
                            }
                          />
                        </PicturesContainer>
                      )}

                      <AmountInputWithLabel
                        label={"Cost Price"}
                        placeholder={"Cost Price"}
                        name="costPrice"
                        valid={`${!touched.costPrice && !errors.costPrice}`}
                        errors={
                          touched &&
                          touched.costPrice &&
                          errors &&
                          errors.costPrice
                        }
                        onChange={(e) => {
                          const {
                            target: { value: inputValue = 0 },
                          } = e;
                          const value = parseInt(
                            inputValue.replace(/\D/g, "") || 0,
                            10
                          );
                          setCostPrice(value);
                        }}
                        description="How much you bought the product"
                        initialValues={initialValues}
                      />

                      <AmountInputWithLabel
                        label={"Selling Price"}
                        placeholder={"Selling Price"}
                        name="retailUnitPrice"
                        valid={`${
                          !touched.retailUnitPrice && !errors.retailUnitPrice
                        }`}
                        errors={
                          touched &&
                          touched.retailUnitPrice &&
                          errors &&
                          errors.retailUnitPrice
                        }
                        onChange={(e) => {
                          const {
                            target: { value: inputValue = 0 },
                          } = e;
                          const value = parseInt(
                            inputValue.replace(/\D/g, "") || 0,
                            10
                          );
                          setSellingPrice(value);
                        }}
                        initialValues={initialValues}
                        borderColor={colors.lightGray}
                        color={colors.deepBlue}
                        description={
                          costPrice && costPrice > sellingPrice
                            ? "Your selling price should be higher than your cost price"
                            : "How much you want to sell the product"
                        }
                      />

                      <ProfitWrapper>
                        <FlexContainer justifyContent="intial">
                          <InfoIcon />
                          <Message
                            left="13.64px"
                            weight="400"
                            color={colors.themeTextColor5}
                            top={"0"}
                            lineHeight="21px"
                          >
                            You will earn{" "}
                            <span
                              style={{
                                color: colors.textGreen,
                                fontWeight: 700,
                              }}
                            >
                              {formatPrice(calculateProfit())}
                            </span>{" "}
                            in profit
                          </Message>
                        </FlexContainer>
                        {!costPrice && (
                          <SubTitle
                            font="12px"
                            weight="400"
                            lineHeight="18px"
                            color={colors.lightDark}
                            left="36px"
                          >
                            Add cost price to generate profit
                          </SubTitle>
                        )}
                      </ProfitWrapper>

                      <TextInputWithLabel
                        label={"Quantity in stock"}
                        placeholder={"Quantity in stock"}
                        name="quantity"
                        value={values.quantity}
                        valid={`${!touched.quantity && !errors.quantity}`}
                        errors={
                          touched &&
                          touched.quantity &&
                          errors &&
                          errors.quantity
                        }
                        onChange={(e) => {
                          const {
                            target: { value },
                          } = e;
                          if (value.length > 7) return;
                          setQuantityVal(value);
                        }}
                        initialValues={initialValues}
                        description="How much do you have"
                      />
                    </InputBlock>
                  </Form>
                </AddProductContainer>
              );
            }}
          </Formik>
        </ScreenContainer>
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default AddSingleProductStep1;
