// import { toast } from 'react-toastify';
import { push } from "connected-react-router";

import { axios, currentAPI } from "../../../../../../config";
import {
  FETCH_DEBTOR,
  FETCH_LIST_OF_DEBTORS,
  LOADING_DEBTORS,
  FETCH_TOTAL_DEBT_AMOUNT,
  FETCH_DEBT_REMINDER,
  SET_REMINDER_SUCCESS,
} from "../../constants";

export const getDebtors = () => async (dispatch) => {
  if (!navigator.onLine) return;

  dispatch({ type: LOADING_DEBTORS, payload: true });

  try {
    const getDebtorsResponse = await axios.get(
      `${currentAPI}/api/merchantAppIntegration/debtor`
    );
    if (getDebtorsResponse.status === 200) {
      dispatch({
        type: FETCH_LIST_OF_DEBTORS,
        payload: getDebtorsResponse.data.data || [],
      });
      dispatch({ type: LOADING_DEBTORS, payload: false });
    }
  } catch (error) {
    dispatch({ type: LOADING_DEBTORS, payload: false });
    //
  }
};

export const getTotalDebtAmount = () => async (dispatch) => {
  if (!navigator.onLine) return;

  try {
    const getDebtorsResponse = await axios.get(
      `${currentAPI}/api/merchantAppIntegration/debtor/total`
    );
    if (getDebtorsResponse.status === 200) {
      dispatch({
        type: FETCH_TOTAL_DEBT_AMOUNT,
        payload: getDebtorsResponse.data.data.totalDebtorsAmount,
      });
    }
  } catch (error) {
    //
  }
};

export const getDebtor = (debtorId) => async (dispatch) => {
  if (!navigator.onLine) return;

  dispatch({ type: LOADING_DEBTORS, payload: true });

  try {
    const getDebtorResponse = await axios.get(
      `${currentAPI}/api/merchantAppIntegration/debtor/${debtorId}`
    );
    if (getDebtorResponse.status === 200) {
      dispatch({
        type: FETCH_DEBTOR,
        payload: getDebtorResponse.data.data || {},
      });
      dispatch({ type: LOADING_DEBTORS, payload: false });
      dispatch({
        type: SET_REMINDER_SUCCESS,
        payload: false,
      });
    }
  } catch (error) {
    dispatch({ type: LOADING_DEBTORS, payload: false });
    //
  }
};

export const getDebtReminderStatus = (debtorId) => async (dispatch) => {
  if (!navigator.onLine) return;

  try {
    const getResponse = await axios.get(
      `${currentAPI}/api/merchantAppIntegration/debtor/${debtorId}/reminder`
    );
    if (getResponse.status === 200) {
      dispatch({
        type: FETCH_DEBT_REMINDER,
        payload: getResponse.data.data || { active: false },
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_DEBT_REMINDER,
      payload: { active: false },
    });
  }
};

export const setReminder = (debtorId, body) => async (dispatch) => {
  if (!navigator.onLine) return;
  dispatch({ type: LOADING_DEBTORS, payload: true });
  try {
    const getResponse = await axios.post(
      `${currentAPI}/api/merchantAppIntegration/debtor/${debtorId}/reminder`,
      JSON.stringify(body)
    );
    if (getResponse.status === 201 || getResponse.status === 200) {
      dispatch({
        type: SET_REMINDER_SUCCESS,
        payload: getResponse?.data?.data?.active,
      });
      dispatch({ type: LOADING_DEBTORS, payload: false });
      dispatch({
        type: FETCH_DEBT_REMINDER,
        payload: getResponse.data.data,
      });
      dispatch(push("/actions/payment_reminder_success"));
    }
  } catch (error) {
    dispatch({
      type: SET_REMINDER_SUCCESS,
      payload: false,
    });
    dispatch({ type: LOADING_DEBTORS, payload: false });
  }
};

export const updateReminder = (debtorId, body) => async (dispatch) => {
  if (!navigator.onLine) return;
  dispatch({ type: LOADING_DEBTORS, payload: true });
  try {
    const getResponse = await axios.put(
      `${currentAPI}/api/merchantAppIntegration/debtor/${debtorId}/reminder`,
      JSON.stringify(body)
    );
    if (getResponse.status === 200) {
      dispatch({
        type: SET_REMINDER_SUCCESS,
        payload: getResponse?.data?.data?.active,
      });
      dispatch({ type: LOADING_DEBTORS, payload: false });
      dispatch({
        type: FETCH_DEBT_REMINDER,
        payload: getResponse.data.data,
      });
      if (getResponse?.data?.data?.active)
        dispatch(push("/actions/payment_reminder_success"));
    }
  } catch (error) {
    dispatch({
      type: SET_REMINDER_SUCCESS,
      payload: false,
    });
    dispatch({ type: LOADING_DEBTORS, payload: false });
  }
};
