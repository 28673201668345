import { axios, currentAPI } from "../../../../../../config";
import { getWalletTransactions } from "../../../transactions/actions";
import {
  BILL_PAYMENT_RESPONSE,
  FETCH_ALL_BILLERS_PER_CATEGORY,
  FETCH_ALL_BILL_CATEGORIES,
  USER_WALLET_FAILED,
} from "../../constants";

export const fetchAllBillCategories = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${currentAPI}/api/billPayments/billCategories`
    );

    if (response.status === 200) {
      dispatch({
        type: FETCH_ALL_BILL_CATEGORIES,
        payload: response.data?.data?.content,
      });
    }
  } catch (error) {
    return "";
  }
};

export const fetchAllBillersPerCategory = (categoryId) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${currentAPI}/api/billPayments/billCategories/${categoryId}`
    );

    if (response.status === 200) {
      dispatch({
        type: FETCH_ALL_BILLERS_PER_CATEGORY,
        payload: response.data?.data,
      });
    }
  } catch (error) {
    return "";
  }
};

export const submitAirtimePurchaseRequest =
  (airtimePurchaseRequest) => async (dispatch) => {
    try {
      const response = await axios.post(
        `${currentAPI}/api/billPayments/airtime`,
        JSON.stringify(airtimePurchaseRequest)
      );

      if (response.status === 201) {
        dispatch({
          type: BILL_PAYMENT_RESPONSE,
          payload: response.data?.data,
        });
        dispatch(getWalletTransactions());
        return true;
      }
    } catch (error) {
      dispatch({
        type: USER_WALLET_FAILED,
        payload: error?.response?.data?.message,
      });
      return false;
    }
  };
