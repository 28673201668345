import React from "react";

import { string, func } from "prop-types";
import styled from "styled-components";

import { colors } from "../../../../styles";
// import { unparseBalance } from '../../../../utils/currency/parseBalance';
// import { formatPrice } from '../../../../utils/currency/formatPriceWithComma';

const Amount = styled.input`
  font-size: 20px;
  font-weight: 500;
  width: inherit;
  background: transparent;
  border: none;
  text-align: center;
  color: ${colors.blue};
  outline: none;
`;

function toCurrency(number) {
  const formatter = new Intl.NumberFormat("en-SA", {
    style: "currency",
    currency: "ZAR",
  });

  return formatter.format(number).replace(/\D00(?=\D*$)/, "");
}

export const MoneyInput = ({ country, amount, setAmount, balance }) => {
  return (
    <Amount
      inputMode={"number"}
      value={amount && toCurrency(amount)}
      placeholder={"KES0.00"}
      onChange={(event) => {
        setAmount(event.target.value.replace(/[,KESa-zA-Z]/g, ""));
      }}
    />
  );
};

MoneyInput.propTypes = {
  amount: string,
  setAmount: func,
};
