import React, { Fragment, useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import useRouter from "use-react-router";

import { SuccessfulOrder } from "../..";
import { TopHeader, RippleButton, Loader } from "../../../../../components";
import { Button } from "../../../../../components/button";
import { List } from "../../../../../containers/ListContainer";
import { ScreenContainer } from "../../../../../containers/ScreenContainer";
import { orderStatus } from "../../../../../enums";
import { redirectedFromMerchbuy } from "../../../../../redux/ducks/account/wallet/actions";
import { merchbuyActions } from "../../../../../redux/ducks/applications/merchbuy/actions";
import { fetchDeliveryDetails } from "../../../../../redux/ducks/applications/my-shop/actions/customers";
import { colors } from "../../../../../styles";
import { formatPrice } from "../../../../../utils/currency/formatPriceWithComma";
import { START_CHECKOUT } from "../../../../../utils/mix-panel/constants";
import { mixPanel } from "../../../../../utils/mix-panel/mixPanel";
import DesktopBackgroundLayout from "../../../../DesktopBackgroundLayout";
import { ShippingCompany, EditDeliveryAddressDetails } from "../../components";
import { DeliveryAddressList } from "../../components/delivery-address/delivery-address-list";
import {
  Space,
  Container,
  PageSubSectionsTop,
  PageSubSectionsTopLeftTitle,
} from "../../styles";
import { CheckoutProgress } from "../checkout-progress";
import { getProductInfoForMixPanel } from "../helper";
import Payment from "../payment";
import { PaymentInfo } from "../payment-info";
import { CheckoutWithWalletPin } from "../pin";
import Summary from "../summary";
import { FeatureUndergoingFixes } from "../../../../../components/popup/feature-undergoing-fixes";

import Products from "./products";

const ModifiedContainer = styled(Container)`
  width: 100%;
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const NameWrapper = styled.div`
  height: 24px;
  width: 24px;
  background: #ede6fe;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

const Text = styled.div`
  margin: ${({ margin }) => margin || "0"};
  font-weight: ${({ fontWeight }) => fontWeight || "normal"};
  font-size: ${({ font }) => font || "12px"};
  color: ${({ color }) => color || null};
  line-height: 15px;
  align-self: center;
`;

const ShippingFee = styled.span`
  color: ${colors.deepBlue};
  line-height: 15px;
`;

const ChangeDeliveryText = styled.h4`
  font-size: 13px;
  color: ${colors.deepBlue};
  font-weight: 600;
`;

const DeliveryDetailsWrapper = styled.div`
  display: grid;
  gap: 7px;
  padding-left: 23px;
  background: ${colors.white};
  padding: 1em;
`;

const DetailsSection = styled.div`
  background: ${colors.white};
  height: 97px;
  display: grid;
`;

const DetailWrapper = styled.div`
  padding-left: 16px;
`;

const FlexContent = styled.div`
  display: flex;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonOuterWrapper = styled.div`
  background: ${colors.white};
  height: 96px;
  display: flex;
  align-items: center;
`;

const ProductWrapper = styled.div`
  padding: 1px 1em 1em 1em;
  margin-bottom: 100px;
`;

const FooterWrapper = styled.div`
  background: ${colors.white};
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1em;
`;

const Checkout = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { location } = useRouter();

  const isAgent = useSelector((state) => state?.user?.role === "ROLE_AGENT");
  const userDeliveryDetails = useSelector(
    (state) => state.applications.myShop.deliveryDetails
  );
  const { address, lga, phoneNumber, state, city } = userDeliveryDetails || {};
  const walletStatus = useSelector((state) => state.account.wallet.status);
  const isLoading = useSelector(
    (state) => state.applications.merchbuy.processingOrder
  );
  const lastSavedDelivery = useSelector(
    (state) => state.applications.merchbuy.lastSavedDeliveryAddress
  );
  const cartItems = useSelector(
    (state) => state.applications.merchbuy.shopCart
  );
  const redirected = useSelector(
    (state) => state.account.wallet.redirectedFromMerchbuy
  );
  const [deliveryDetails, setDeliveryDetails] = useState(null);
  const [shippingDetails, setShippingDetails] = useState();
  const [paymentMethod, setPaymentMethod] = useState();
  const [orderId, setOrderId] = useState();
  const [statusEnum, setStatusEnum] = useState(0);

  const [showContinueButton, setShowContinueButton] = useState(true);
  const [showShippingPopup, setShowShippingPopup] = useState(false);
  const [showAddressPopup, setShowAddressPopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [shouldPlaceOrder, setShouldPlaceOrder] = useState(false);
  const [step, setStep] = useState(orderStatus.delivery);

  const [subTotal] = useState(
    !location?.state?.fromBuyNow
      ? cartItems.reduce(
          (accum, current) =>
            Number(accum) + Number(current.maxPrice) * Number(current.quantity),
          0
        )
      : Number(location?.state?.items?.maxPrice) *
          Number(location?.state?.items?.quantity)
  );

  const [totalQuantity] = useState(
    cartItems.reduce(
      (accum, current) => Number(accum) + Number(current.quantity),
      0
    )
  );
  const { deliveryFee } = shippingDetails || {};
  const [totalPrice, setTotalPrice] = useState(0);
  const [vat, setVat] = useState(0);
  const [openFeatureFixes, setOpenFeatureFixes] = useState(false);

  useEffect(() => window.scrollTo({ top: 0, left: 0, behavior: "smooth" }));

  useEffect(() => {
    setVat(subTotal * 0);
  }, [subTotal]);

  useEffect(() => {
    dispatch(fetchDeliveryDetails());
    dispatch(merchbuyActions.getDeliveryAddressEditLimit());
  }, [dispatch]);

  useEffect(() => {
    setTotalPrice(subTotal + vat + (deliveryFee || 0));
  }, [subTotal, setTotalPrice, deliveryFee, vat]);

  useEffect(() => {
    setShowContinueButton(deliveryDetails ? false : true);
  }, [deliveryDetails]);

  const priceInfo = { subTotal, deliveryFee, totalPrice, vat };

  useEffect(() => {
    if (!isAgent && address && lga && phoneNumber && state && city) {
      setDeliveryDetails({ ...userDeliveryDetails, contactType: "SELF" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAgent, userDeliveryDetails]);

  useEffect(() => {
    if (redirected) {
      setDeliveryDetails(lastSavedDelivery);
      // setShippingDetails(null) //TODO when shipping gets added
      setStep(orderStatus.payment);
      dispatch(redirectedFromMerchbuy(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, redirected]);

  const avatarName = (name) => {
    return name
      ? name
          .split(" ")
          .map((n) => n[0])
          .join("")
      : "";
  };

  const backAction = () => {
    if (step === orderStatus.delivery) {
      history.push("/actions/merchbuy/cart");
    }
    if (step === orderStatus.payment) {
      setStep(orderStatus.delivery);
    }
    if (step === orderStatus.summary) {
      setStep(orderStatus.payment);
    }
  };

  if (isLoading) return <Loader />;
  return (
    <Fragment>
      <DesktopBackgroundLayout>
        {showShippingPopup && (
          <ShippingCompany
            carts={cartItems || []}
            getShippingDetails={(values) => {
              setShowShippingPopup(false);
              setShippingDetails(values);
            }}
            deliveryDetails={deliveryDetails}
            totalQuantity={totalQuantity}
            close={() => {
              setShowShippingPopup(false);
            }}
          />
        )}
        {openFeatureFixes && (
          <FeatureUndergoingFixes cancel={() => setOpenFeatureFixes(false)} />
        )}
        {showAddressPopup && (
          <DeliveryAddressList
            merchbuyActions={merchbuyActions}
            getDeliveryDetails={(values) => setDeliveryDetails(values)}
            close={() => setShowAddressPopup(false)}
          />
        )}
        {showEditPopup && (
          <EditDeliveryAddressDetails
            open={showEditPopup}
            cancel={() => setShowEditPopup(false)}
            close={() => setShowAddressPopup(false)}
            deliveryLocation={
              deliveryDetails
                ? { ...deliveryDetails, contactType: "SELF" }
                : { ...userDeliveryDetails, contactType: "SELF" }
            }
            getDeliveryDetails={(values) => {
              dispatch(merchbuyActions.updateDeliveryLocation(values));
              setDeliveryDetails(values);
            }}
            setSelectedDeliveryInformation={(values) => {
              setDeliveryDetails(values);
            }}
          />
        )}

        {showPin && (
          <CheckoutWithWalletPin
            amount={totalPrice}
            setShowPin={setShowPin}
            setShouldPlaceOrder={setShouldPlaceOrder}
          />
        )}

        {!showShippingPopup && !showAddressPopup && !showEditPopup && !showPin && (
          <Fragment>
            <TopHeader
              backLink="#"
              backAction={backAction}
              title="Checkout"
              noArrow={step === orderStatus.placed ? true : false}
              withSpacesHeader
            />
            <ScreenContainer color={colors.lightestGray} padding={"0"}>
              <ModifiedContainer>
                <Space color={colors.white} height={"13px"} />
                <CheckoutProgress step={step} />
                {step === orderStatus.payment && (
                  <Payment
                    setPaymentMethod={setPaymentMethod}
                    priceInfo={priceInfo}
                    setStep={setStep}
                    deliveryDetails={deliveryDetails}
                    setOpenFeatureFixes={setOpenFeatureFixes}
                  />
                )}
                {step === orderStatus.placed && (
                  <SuccessfulOrder
                    orderNumber={orderId}
                    paymentMethod={paymentMethod}
                    priceInfo={priceInfo}
                    statusEnum={statusEnum}
                    setStep={setStep}
                  />
                )}
                {step === orderStatus.summary && (
                  <Summary
                    paymentMethod={paymentMethod}
                    priceInfo={priceInfo}
                    setStep={setStep}
                    setOrderId={setOrderId}
                    shippingDetails={{
                      ...shippingDetails,
                      shippingPrice: deliveryFee,
                    }}
                    deliveryDetails={deliveryDetails}
                    setShowAddressPopup={setShowAddressPopup}
                    setShowShippingPopup={setShowShippingPopup}
                    setShowPin={setShowPin}
                    shouldPlaceOrder={shouldPlaceOrder}
                    setShouldPlaceOrder={setShouldPlaceOrder}
                    setStatusEnum={setStatusEnum}
                    fromBuyNow={location?.state?.fromBuyNow}
                    buyNowItems={[location?.state?.items]}
                  />
                )}
                {step === orderStatus.delivery && (
                  <Fragment>
                    <List noBorderBottom={false}>
                      <PageSubSectionsTop padding={"16px 16px 8px"}>
                        <PageSubSectionsTopLeftTitle left="0" font="12px">
                          DELIVERY ADDRESS
                        </PageSubSectionsTopLeftTitle>
                        {deliveryDetails && (
                          <ChangeDeliveryText
                            onClick={() => {
                              if (!isAgent) {
                                setShowEditPopup(true);
                              } else {
                                setShowAddressPopup(true);
                              }
                            }}
                          >
                            CHANGE
                          </ChangeDeliveryText>
                        )}
                      </PageSubSectionsTop>

                      {deliveryDetails && (
                        <DeliveryDetailsWrapper>
                          <TextWrapper>
                            <NameWrapper>
                              <Text
                                font="10px"
                                color="#7E42F5"
                                fontWeight="500"
                              >
                                {avatarName(
                                  deliveryDetails?.receiverName ||
                                    deliveryDetails?.fullName ||
                                    `${deliveryDetails?.firstName || ""} ${
                                      deliveryDetails?.lastName || ""
                                    }`
                                )}
                              </Text>
                            </NameWrapper>
                            <Text font="14px" color="#29394f">
                              {deliveryDetails?.receiverName ||
                                deliveryDetails?.fullName ||
                                `${deliveryDetails?.firstName || ""} ${
                                  deliveryDetails?.lastName || ""
                                }`}
                            </Text>
                          </TextWrapper>
                          <Text color={colors.lightDark}>
                            {`${
                              deliveryDetails.shopNumber
                                ? deliveryDetails.shopNumber + ", "
                                : ""
                            } 
                                                        ${
                                                          deliveryDetails.shopAddress ||
                                                          deliveryDetails.streetAddress ||
                                                          deliveryDetails.address ||
                                                          ""
                                                        }`}
                          </Text>
                          <Text color={colors.lightDark}>
                            {`${
                              deliveryDetails.lga || deliveryDetails.localGovt
                            } Municipalities`}
                          </Text>
                          <Text color={colors.lightDark}>
                            {`${deliveryDetails.state} Province`}
                          </Text>
                        </DeliveryDetailsWrapper>
                      )}

                      {!deliveryDetails && (
                        <ButtonOuterWrapper>
                          <Button
                            top="0"
                            margin="1em 1em 0 1em"
                            backgroundColor={colors.white}
                            size="16px"
                            color={colors.deepBlue}
                            border={`1px solid ${colors.deepBlue}`}
                            onClick={() => {
                              if (!isAgent) {
                                setShowEditPopup(true);
                              } else {
                                setShowAddressPopup(true);
                              }
                            }}
                          >
                            Select a delivery address
                          </Button>
                        </ButtonOuterWrapper>
                      )}
                    </List>
                    <List noBorderBottom={false}>
                      <PageSubSectionsTop padding={"24px 16px 8px"}>
                        <PageSubSectionsTopLeftTitle left="0" font="12px">
                          DELIVERY METHOD
                        </PageSubSectionsTopLeftTitle>
                        {shippingDetails && (
                          <ChangeDeliveryText
                            onClick={() => {
                              setShowShippingPopup(true);
                            }}
                          >
                            CHANGE
                          </ChangeDeliveryText>
                        )}
                      </PageSubSectionsTop>
                      <DetailsSection>
                        <ContentWrapper>
                          <FlexContent>
                            <DetailWrapper>
                              <Text
                                margin="16px 0 8px 0"
                                font="14px"
                                color="#071827"
                              >
                                Vumele | Free Shipping
                              </Text>
                              <Text margin="0 0 2px 0" color={colors.lightDark}>
                                Estimated Delivery: 2-4 days
                              </Text>
                              <Text color={colors.lightDark}>
                                Shipping Fee:{" "}
                                <ShippingFee>
                                  {formatPrice(0)} (FREE)
                                </ShippingFee>
                              </Text>
                            </DetailWrapper>
                          </FlexContent>
                        </ContentWrapper>
                      </DetailsSection>
                      {/* {!shippingDetails && (
                                                <Fragment>
                                                    <ButtonOuterWrapper>
                                                        <Button
                                                            top="0"
                                                            margin="1em 1em 0 1em"
                                                            backgroundColor={colors.white}
                                                            size="16px"
                                                            color={colors.deepBlue}
                                                            border={`1px solid ${colors.deepBlue}`}
                                                            onClick={() => {
                                                                setShowShippingPopup(true);
                                                            }}
                                                        >
                                                            Choose a delivery method
                                                        </Button>
                                                    </ButtonOuterWrapper>
                                                </Fragment>
                                            )} */}
                    </List>
                    <List noBorderBottom={false}>
                      <PageSubSectionsTop padding={"16px 16px 6px"}>
                        <PageSubSectionsTopLeftTitle left="0" font="12px">
                          ORDER SUMMARY
                        </PageSubSectionsTopLeftTitle>
                      </PageSubSectionsTop>
                      <ProductWrapper>
                        <Products carts={cartItems} />
                      </ProductWrapper>
                    </List>
                    <FooterWrapper>
                      <PaymentInfo priceInfo={priceInfo} />
                      <RippleButton
                        top="12px"
                        size="16px"
                        onClick={() => {
                          setStep(orderStatus.payment);
                          const data = getProductInfoForMixPanel(cartItems);
                          mixPanel.track(START_CHECKOUT, {
                            "Total cart value": subTotal,
                            "Total item count": data.totalItemCount,
                            "Total unique item": cartItems.length,
                            "Total shops": data.sortedItems.length,
                            "Item ids": data.productIds,
                            "Item names": data.productNames,
                            "Item shopNames": data.shopNames,
                            "Wallet Status": walletStatus,
                            Time: new Date().toLocaleString(),
                          });
                        }}
                        disabled={showContinueButton}
                      >
                        Proceed to payment
                      </RippleButton>
                    </FooterWrapper>
                  </Fragment>
                )}
              </ModifiedContainer>
            </ScreenContainer>
          </Fragment>
        )}
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default Checkout;
