import React from "react";

import { number } from "prop-types";
import styled from "styled-components";

import { colors } from "../../../styles";

const ProgressBarWrapper = styled.section`
  display: flex;
  position: sticky;
  top: 64px;
  width: 100%;
  height: 4px;
  margin-top: 2px;
  z-index: 1;
  background-color: ${colors.aliceBlue};
`;

const ProgressBarSpan = styled.span`
  width: ${(props) => parseFloat((props.step / props.amount) * 100)}%;
  height: 4px;
  background-color: ${colors.deepBlue};
`;

export const FlatProgressBar = ({ step, amount }) => {
  return (
    <ProgressBarWrapper>
      <ProgressBarSpan step={step} amount={amount}></ProgressBarSpan>
    </ProgressBarWrapper>
  );
};

FlatProgressBar.propTypes = {
  step: number,
  amount: number,
};
