import React, { Fragment, useState } from "react";

import styled from "styled-components";

import { colors } from "../../../../../styles";
import { ReactComponent as HappyIcon } from "../../assets/happy.svg";
import { ReactComponent as HappyColouredIcon } from "../../assets/happyColor.svg";
import { ReactComponent as SadIcon } from "../../assets/sad.svg";
import { ReactComponent as SadColouredIcon } from "../../assets/sadColor.svg";
import { ReactComponent as ThinkingIcon } from "../../assets/thinking1.svg";
import { ReactComponent as ThinkingColouredIcon } from "../../assets/thinking1Color.svg";
import { ReactComponent as UglyIcon } from "../../assets/ugly1.svg";
import { ReactComponent as UglyColouredIcon } from "../../assets/ugly1Color.svg";
import { ReactComponent as Ugly2Icon } from "../../assets/ugly2.svg";
import { ReactComponent as Ugly2ColouredIcon } from "../../assets/ugly2Color.svg";
import { getEmojiValue } from "../utils";

import { RateParagraph, StarDiv, StarRatingCover } from "./StarRating";

export const CircleDiv = styled.div`
  background: ${({ background }) => background || colors.white};
  width: 51px;
  height: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

const EmojiRating = ({ rating, setRating, showValue, setShowValue }) => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const IconObject = [
    { bw: <SadIcon />, color: <SadColouredIcon /> },
    { bw: <UglyIcon />, color: <UglyColouredIcon /> },
    { bw: <ThinkingIcon />, color: <ThinkingColouredIcon /> },
    { bw: <Ugly2Icon />, color: <Ugly2ColouredIcon /> },
    { bw: <HappyIcon />, color: <HappyColouredIcon /> },
  ];
  return (
    <Fragment>
      <StarRatingCover>
        {IconObject.map((icon, index) => {
          const ratingValue = index + 1;
          return (
            <label key={index}>
              <input
                type="radio"
                name="rating"
                value={getEmojiValue(ratingValue)}
                onClick={() => {
                  setRating(ratingValue);
                  setSelectedIndex(index);
                }}
                style={{ display: "none" }}
              />
              {selectedIndex === index ? (
                <StarDiv
                  onClick={() => setShowValue(getEmojiValue(ratingValue))}
                >
                  <CircleDiv background={colors.gray4}>{icon.color}</CircleDiv>
                  <RateParagraph weight={"500"} color={colors.boldDark}>
                    {getEmojiValue(ratingValue)}
                  </RateParagraph>
                </StarDiv>
              ) : (
                <StarDiv
                  onClick={() => setShowValue(getEmojiValue(ratingValue))}
                >
                  <CircleDiv>{icon.bw}</CircleDiv>
                  {!showValue && (
                    <RateParagraph>{getEmojiValue(ratingValue)}</RateParagraph>
                  )}
                </StarDiv>
              )}
            </label>
          );
        })}
      </StarRatingCover>
    </Fragment>
  );
};

export default EmojiRating;
