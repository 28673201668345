import React, { Fragment, useState, useEffect } from "react";

import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";

import {
  TopHeader,
  PageLogo,
  RippleButton,
  SelectBox,
  TextInputWithLabel,
} from "../../../../../components";
import {
  PageTitleWrapper,
  PageTitle,
  PageSubTitle,
} from "../../../../../containers/MessageContainer";
import {
  ScreenContainer,
  PageProgressLine,
  PageProgressWrap,
  PageProgressStep,
} from "../../../../../containers/ScreenContainer";
import { countriesMap } from "../../../../../data/countries";
import { localAreas } from "../../../../../data/countries/nigeria/localAreas";
import { states } from "../../../../../data/countries/nigeria/states";
import { getCategories } from "../../../../../redux/ducks/applications/my-shop/actions/shop";
import {
  checkExistingEmail,
  checkReferralCode,
} from "../../../../../redux/ducks/auth/check/actions";
import { getReferral } from "../../../../../redux/ducks/user/actions";
import { findMapKeyByValues } from "../../../../../utils/inputs/findMapKeyByValues";
import { selectAreasByState } from "../../../../../utils/inputs/selectAreasByState";
import DesktopBackgroundLayout from "../../../../DesktopBackgroundLayout";
import { colors } from "../../../../../styles";
import { AgentSignUpValidationSchema } from "./AgentSignUpValidationSchema";
import { ReactComponent as Spinner } from "./assets/loading-spinner.svg";
import UserIcon from "./assets/user.svg";

const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const InputGroupWrapper = styled.div`
  position: relative;
  margin-top: 16px;
`;

const InputLabelName = styled.div`
  position: absolute;
  top: 64px;
  color: ${colors.themeTxtColor10};
  font-size: 11px;
  letter-spacing: 0.01em;
  margin: 0 15px;
`;

const LoaderSpin = styled(Spinner)`
  position: absolute;
  display: flex;
  top: 14px;
  right: 14px;
  width: 20px;
  height: 20px;
  transform: rotate(360deg);
  animation: ld-spinner 1.8s linear infinite;
  @keyframes ld-spinner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0.3;
    }
  }
`;

const AgentProfileCreation = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const phoneNumber = useSelector((state) => state.user.msisdn);
  const { isLoading: loadingAccountReferral } = useSelector(
    (state) => state.user.accountReferral
  );
  const [referralLinkCode] = useState(
    localStorage.getItem("referralCode") || ""
  );
  let userData = localStorage.getItem("userData");
  const {
    referralCode = "",
    firstName,
    lastName,
    email,
    country,
    state,
    lga,
  } = (userData && JSON.parse(userData)) || {};
  const [userReferralNumber, setUserReferralNumber] = useState("");
  const [referralName, setReferralName] = useState("");

  useEffect(() => {
    if (userReferralNumber && userReferralNumber.length === 10) {
      dispatch(getReferral(userReferralNumber)).then((res) => {
        if (res) {
          setReferralName(`${res?.firstName || ""}  ${res?.lastName || ""}`);
        } else {
          setReferralName("");
        }
      });
    } else {
      setReferralName("");
    }
  }, [dispatch, userReferralNumber]);

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  const onSubmit = (values, { setErrors }) => {
    setTimeout(async () => {
      if (!values.email) values.email = `${phoneNumber}@spaceso2o.com`;

      const hasExistingEmail = await dispatch(checkExistingEmail(values.email));
      if (hasExistingEmail === undefined) return;
      if (hasExistingEmail) return setErrors({ email: "Email already exists" });

      if (values.referralCode) {
        const refCode = values.referralCode.replace(/\s/g, "");
        if (refCode && refCode.length < 10) {
          toast.error("Invalid referral code");
          return;
        }

        const refNumber = `0${refCode.substr(refCode.length - 9)}`;
        var isSameUser = phoneNumber.localeCompare(refNumber);
        if (isSameUser === 0) {
          toast.error("You can not use your referral code here");
          return;
        }

        const isValidReferral = await dispatch(checkReferralCode(refNumber));
        if (!isValidReferral) {
          return;
        }
      }

      const payload = {
        ...values,
        country: findMapKeyByValues(countriesMap, values.country),
      };

      userData = JSON.parse(userData);
      userData = { ...userData, ...payload };
      localStorage.setItem("userData", JSON.stringify(userData));

      history.push("/user/create_agent_identity");
    }, 200);
  };

  return (
    <Fragment>
      <DesktopBackgroundLayout>
        <TopHeader title={"Agent Account"}>
          <PageProgressWrap>
            <PageProgressStep>1</PageProgressStep>/4
          </PageProgressWrap>
          <PageProgressLine lineWidth={"25%"} />
        </TopHeader>

        <ScreenContainer>
          <PageLogo
            top={"26px"}
            margin={"auto"}
            borderRadius={"0"}
            Icon={UserIcon}
            iconWidth="72px"
            iconHeight="72px"
          />
          <PageTitleWrapper mtop={"10px"} mbottom={"35px"}>
            <PageTitle size={"16px"} top={"16px"} bottom={"4px"}>
              Personal information
            </PageTitle>
            <PageSubTitle margin={"0"}>
              Let's get to know more about you
            </PageSubTitle>
          </PageTitleWrapper>

          <Formik
            initialValues={{
              firstName: firstName || "",
              lastName: lastName || "",
              email: email || "",
              country: country || "",
              state: state || "",
              lga: lga || "",
              referralCode: referralCode
                ? referralCode.trim()
                : referralLinkCode
                ? referralLinkCode.trim()
                : "",
            }}
            enableReinitialize={true}
            validationSchema={AgentSignUpValidationSchema}
            onSubmit={onSubmit}
          >
            {({
              errors,
              touched,
              setFieldValue,
              handleChange,
              values,
              initialValues,
            }) => {
              if (values.referralCode) {
                setUserReferralNumber(values.referralCode);
              }

              return (
                <Form>
                  <InputBlock>
                    <TextInputWithLabel
                      type="text"
                      label="First name"
                      name="firstName"
                      placeholder="First name"
                    />

                    <TextInputWithLabel
                      type="text"
                      label="Last name"
                      name="lastName"
                      placeholder="Last name"
                    />

                    <TextInputWithLabel
                      type="email"
                      label="Email"
                      name="email"
                      placeholder="Email"
                    />

                    <SelectBox
                      top="0px"
                      name={"country"}
                      placeholder={"Select country"}
                      value={values && values.country}
                      options={[{ value: "SA", label: "South Africa" }]}
                      handleChange={handleChange}
                      valid={`${!touched.state && !errors.state}`}
                      error={touched && touched.state && errors && errors.state}
                    />

                    <SelectBox
                      placeholder={"Province"}
                      value={values && values.state}
                      options={states}
                      height="90%"
                      handleChange={(selected) => {
                        if (values.state !== selected.label) {
                          setFieldValue("lga", "");
                        }
                        setFieldValue("state", selected.label);
                      }}
                      valid={`${!touched.state && !errors.state}`}
                      error={touched && touched.state && errors && errors.state}
                    />

                    <SelectBox
                      name={"lga"}
                      placeholder={"Municipalities"}
                      value={values && values.lga}
                      height="90%"
                      options={
                        values.state
                          ? selectAreasByState(values.state, localAreas)
                          : []
                      }
                      handleChange={handleChange}
                      valid={`${!touched.lga && !errors.lga}`}
                      error={touched && touched.lga && errors && errors.lga}
                    />

                    <InputGroupWrapper>
                      <TextInputWithLabel
                        type="text"
                        label="Referral Number"
                        name="referralCode"
                        placeholder="Referral Number"
                      />

                      {loadingAccountReferral === "LOADING" && <LoaderSpin />}
                      {loadingAccountReferral === "SUCCESSFUL" && (
                        <InputLabelName>{referralName}</InputLabelName>
                      )}
                    </InputGroupWrapper>

                    <RippleButton
                      type="submit"
                      disabled={
                        values.firstName === "" ||
                        values.lastName === "" ||
                        (values.referralCode && !referralName)
                      }
                    >
                      Continue
                    </RippleButton>
                  </InputBlock>
                </Form>
              );
            }}
          </Formik>
        </ScreenContainer>
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default AgentProfileCreation;
