import * as Yup from "yup";

import { phoneRegExp } from "../../../../../../utils/regexp/phoneRegExp";

export const UpdateDeliveryDetailsValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .nullable()
    .required("Please, enter the receiver's First name")
    .test(
      "check receiver names",
      "Please enter First name only",
      function (value) {
        if (/\s/.test(value)) {
          return false;
        }
        return true;
      }
    ),
  lastName: Yup.string()
    .trim()
    .nullable()
    .required("Please, enter the receiver's last name")
    .test(
      "check receiver names",
      "Please enter Last name only",
      function (value) {
        if (/\s/.test(value)) {
          return false;
        }
        return true;
      }
    ),
  shopAddress: Yup.string()
    .nullable()
    .required("Please, enter the street address"),
  state: Yup.string().nullable().required("Please, select one of the options"),
  lga: Yup.string().nullable().required("Please, select one of the options"),
  // city: Yup.string().nullable().required('Please, select one of the options'),
  msisdn: Yup.string()
    .min(10, "Must be minimum 10 characters")
    .max(11, "Must be maximum 11 characters")
    .matches(phoneRegExp, "Phone Number is not valid")
    .nullable()
    .required("Please, enter a phone number"),
});
