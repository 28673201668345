import React, { useState, Fragment } from "react";

import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { OptionsPopupDialog, NavigationElement } from "../../../components";
import { FixedBottom } from "../../actions/merchbuy/styles";

import ActiveAgentIcon from "./assets/active_agent.svg";
import ActiveBuyIcon from "./assets/active_buy.svg";
import ActiveHomeIcon from "./assets/active_home.svg";
import ActiveMoreIcon from "./assets/active_more.svg";
// import ActiveWalletIcon from './assets/active_wallet.svg';
import AgentIcon from "./assets/agent.svg";
import BuyIcon from "./assets/buy.svg";
import HomeIcon from "./assets/home.svg";
import MoreIcon from "./assets/more.svg";
import { ReactComponent as SettingsIcon } from "./assets/settings.svg";
// import WalletIcon from './assets/wallet.svg';

const NavigationWrapper = styled(FixedBottom)``;

const NavigationBar = styled.nav`
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  width: 100%;
  max-height: 56px;
`;

const Navigation = () => {
  const [open, setOpen] = useState(false);
  const route = useLocation();
  const role = useSelector((state) => state.user.role);
  const isB2BAgent = useSelector((state) => state.user.isB2BAgent);

  const popupOptions =
    role === "ROLE_AGENT"
      ? [
          {
            Icon: SettingsIcon,
            title: "Settings",
            link: "/user/account_settings",
          },
        ]
      : [
          {
            Icon: SettingsIcon,
            title: "Settings",
            link: "/user/account_settings",
          },
        ];

  return (
    <Fragment>
      <NavigationWrapper>
        <NavigationBar>
          {isB2BAgent && (
            <NavigationElement
              link={"/actions/remittance/dashboard"}
              currentLink={route.pathname}
              activeIcon={ActiveAgentIcon}
              nonActiveIcon={AgentIcon}
              text={"Agent"}
            />
          )}
          <NavigationElement
            link={"/my-shop"}
            currentLink={route.pathname}
            activeIcon={ActiveHomeIcon}
            nonActiveIcon={HomeIcon}
            text={"Store"}
          />
          <NavigationElement
            link={"/actions/merchbuy"}
            currentLink={route.pathname}
            activeIcon={ActiveBuyIcon}
            nonActiveIcon={BuyIcon}
            text={"Buy"}
          />
          {/* <NavigationElement
                        link={'/user/wallet_index'}
                        currentLink={route.pathname}
                        activeIcon={ActiveWalletIcon}
                        nonActiveIcon={WalletIcon}
                        text={'Wallet'}
                    /> */}
          <NavigationElement
            link={"/more"}
            currentLink={route.pathname}
            activeIcon={ActiveMoreIcon}
            nonActiveIcon={MoreIcon}
            text={"More"}
          />
        </NavigationBar>
      </NavigationWrapper>
      <OptionsPopupDialog
        open={open}
        arrows={true}
        title={"Options"}
        cancel={() => setOpen(!open)}
        items={popupOptions}
      />
    </Fragment>
  );
};

export default Navigation;
