import React, { Fragment, useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import {
  PageLogo,
  RippleButton,
  SearchHeader,
  SwitchTrigger,
} from "../../../../../components";
import { List, ListSubHeading } from "../../../../../containers/ListContainer";
import { Message } from "../../../../../containers/MessageContainer";
import { updateProduct } from "../../../../../redux/ducks/applications/my-shop/actions/shop";
import { colors } from "../../../../../styles";
import { formatPrice } from "../../../../../utils/currency/formatPriceWithComma";
import DesktopBackgroundLayout from "../../../../DesktopBackgroundLayout";
import { ReactComponent as FlatIcon } from "../../assets/flat.svg";
import { Space } from "../../styles";

import EditProduct from "./edit-product";

const ProductWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 16px;
`;

const ProductContainer = styled.div`
  display: flex;
  position: relative;
  margin: 7.5px;
`;

const MessageWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 4px;
`;

const EditProductContainer = styled.div`
  border-radius: 8px;
  background-color: ${colors.themeColor3};
  height: 32px;
  margin: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  width: 80px;
  height: 24px;
  margin: 5px;
`;

const MerchBuyProducts = () => {
  const dispatch = useDispatch();

  const shops = useSelector((state) => state.applications.myShop.shops) || [];
  const shop = shops && shops[0];
  const { id: shopId, inventory: products } = shop || {};

  const [inventory, setInventory] = useState([]);
  const [searchedOptions, setSearchedOptions] = useState(inventory);
  const [selectedProduct, setSelectedProduct] = useState();
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    setInventory(products);
    if (inventory && inventory.length > 0) {
      let searchResults = inventory;
      if (searchValue) {
        searchResults = inventory.filter((x) =>
          String(x.productName)
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        );
      }
      searchResults.sort((a, b) =>
        String(a.productName)
          .toLowerCase()
          .localeCompare(String(b.productName).toLowerCase())
      );
      setSearchedOptions(searchResults);
    }
  }, [searchValue, shops, shopId, inventory, products]);

  const update = async (product) => {
    if (
      !product.availableAtWholesalePrice &&
      (!product.productCategory ||
        !product.productDescription ||
        product.bulkPrices.length < 1 ||
        !product.base64ProductImageString)
    ) {
      setShowEditPopup(true);
      setSelectedProduct(product);
    } else {
      const newInventory = [...inventory];
      const products = [...inventory];
      const index = inventory.findIndex((x) => x.id === product.id);
      const availableAtWholesalePrice = !product.availableAtWholesalePrice;
      newInventory[index].listingOptions.availableAtWholesalePrice =
        availableAtWholesalePrice;
      const payload = { ...product, availableAtWholesalePrice };
      setInventory(newInventory);
      const response = await dispatch(
        updateProduct(shopId, product.id, payload, false, false, true)
      );
      if (!response) {
        products[index].availableAtWholesalePrice = !availableAtWholesalePrice;
        setInventory(products);
      }
    }
  };

  if (showEditPopup) {
    return (
      <EditProduct
        setShowEditPopup={() => {
          setShowEditPopup(false);
        }}
        shopId={shopId}
        product={selectedProduct}
      />
    );
  } else
    return (
      <Fragment>
        <DesktopBackgroundLayout>
          <SearchHeader
            backLink="/sell"
            handleSearch={setSearchValue}
            placeholder="Search products"
            title="Products"
            noArrow={false}
          />
          <Space height="80px" />
          {searchedOptions &&
            searchedOptions.length > 0 &&
            searchedOptions.map((product) => (
              <List key={product.id}>
                <ProductContainer>
                  <ProductWrapper>
                    <PageLogo
                      Icon={
                        (product.images && product.images.baseImageUrl) ||
                        product.base64ProductImageString ||
                        ""
                      }
                      width={"32px"}
                      height={"32px"}
                      iconHeight={"32px"}
                      iconWidth={"32px"}
                    />
                    <ListSubHeading
                      maxWidth="260px"
                      fontWeight="500"
                      fontSize="14px"
                      padding="0 16px"
                      top="0"
                      color={colors.themeTextColor1}
                    >
                      {product.productName}
                    </ListSubHeading>
                  </ProductWrapper>
                  <SwitchTrigger
                    checkStatus={product.availableAtWholesalePrice}
                    switchStatus={() => {
                      update(product);
                    }}
                    top="5px"
                    right="16px"
                  />
                </ProductContainer>
                {product.availableAtWholesalePrice && (
                  <EditProductContainer>
                    <MessageWrapper>
                      <FlatIcon />
                      <Message left="8px" top="0" weight="500" size="10px">
                        {formatPrice(product.retailUnitPrice || 0)}
                      </Message>
                    </MessageWrapper>
                    <ButtonWrapper>
                      <RippleButton
                        onClick={() => {
                          setShowEditPopup(true);
                          setSelectedProduct(product);
                        }}
                        size="10px"
                        height="24px"
                        top="0"
                        background={colors.link.active}
                      >
                        Edit product
                      </RippleButton>
                    </ButtonWrapper>
                  </EditProductContainer>
                )}
              </List>
            ))}
        </DesktopBackgroundLayout>
      </Fragment>
    );
};

export default MerchBuyProducts;
