import React, { Fragment, useState, useEffect } from "react";

import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import {
  TopHeader,
  UserAvatar,
  RippleButton,
  PageLogo,
  Loader,
  InputWithLabel,
} from "../../../../components";
import { InputBlock } from "../../../../containers/InputContainer";
import { ListHeading } from "../../../../containers/ListContainer";
import {
  ScreenContainer,
  FlexCenteredBlock,
} from "../../../../containers/ScreenContainer";
import { sendAgentReferral } from "../../../../redux/ducks/applications/merchant-and-agent/actions";
import { getReferral } from "../../../../redux/ducks/user/actions";
import { colors } from "../../../../styles";
import DesktopBackgroundLayout from "../../../DesktopBackgroundLayout";

import { ReactComponent as Spinner } from "./assets/loading-spinner.svg";
import PhoneIcon from "./assets/phone.svg";
import SMSIcon from "./assets/sms.svg";
import WhatsAppIcon from "./assets/whatsapp.svg";

const ReferralName = styled(ListHeading)`
  font-size: 16px;
  font-weight: bold;
  color: #071827;
  line-height: 24px;
  align-items: center;
  text-align: center;
  margin-top: 16px;
  margin-bottom: ${({ bottom }) => bottom || null};
`;

const ReferralPhoneNumber = styled(ListHeading)`
  font-size: 14px;
  font-weight: 400;
  color: #718596;
  line-height: 21px;
  text-align: center;
`;

const AddButton = styled(RippleButton)`
  margin-top: auto;
`;

const ContactAgentBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  margin-top: 16px;
  padding: 8px 15px;
  & > div {
    margin-right: 16px;
    &:last-of-type {
      margin-right: 0;
    }
    &:first-of-type {
      margin-bottom: 12px;
    }
  }
`;
const ContactOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;
const ContactText = styled.span`
  font-size: 10px;
  font-weight: 500;
  line-height: 13px;
  color: ${colors.themeColor6};
`;
const ContactLink = styled.a`
  text-align: center;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > span {
    max-width: 67px;
    max-height: 26px;
    text-align: center;
    margin-top: 8px;
  }
`;
const PageTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #071827;
  width: 100%;
  line-height: 24px;
  text-align: left;
  align-items: left;
  margin-top: 16px;
`;

const PageSubTitle = styled.p`
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  color: #718596;
  line-height: 21px;
  text-align: left;
`;
const InputGroupWrapper = styled.div`
  position: relative;
`;
const InputBlockContainer = styled(InputBlock)`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 55vh;
`;

const LoaderSpin = styled(Spinner)`
  position: absolute;
  display: flex;
  top: 14px;
  right: 14px;
  width: 20px;
  height: 20px;
  transform: rotate(360deg);
  animation: ld-spinner 1.8s linear infinite;
  @keyframes ld-spinner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0.3;
    }
  }
`;

const InputLabelName = styled.div`
  position: absolute;
  top: 52px;
  color: #071827;
  font-size: 11px;
  letter-spacing: 0.01em;
  margin: 0 15px;
`;

const MerchantAndAgentReferrerDetail = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector(
    (state) => state.applications.merchantAndAgent.isLoading
  );
  // const user = useSelector(state => state.user);
  // const {msisdn: phoneNumber} = user && user;
  const { isLoading: loadingAccountReferral, referral = {} } =
    useSelector((state) => state.user.accountReferral) || {};
  const [referralLinkCode] = useState(
    localStorage.getItem("referralCode") || ""
  );
  let userData = localStorage.getItem("userData");
  const { referralCode, referralNumber } =
    (userData && JSON.parse(userData)) || {};
  const [userReferralNumber, setUserReferralNumber] = useState();

  const agent = useSelector((state) => state.user.agentData) || {};

  useEffect(() => {
    userReferralNumber && dispatch(getReferral(userReferralNumber));
  }, [dispatch, userReferralNumber]);

  const { firstName: refFirstName, lastName: refLastName } = referral;
  const referralName =
    (refFirstName || refLastName) && `${refFirstName}  ${refLastName}`;
  const { firstName, lastName, msisdn: refPhoneNumber, avatar } = agent || {};

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <DesktopBackgroundLayout>
        <TopHeader title={"Referring user"} />

        {firstName &&
        firstName.length > 0 &&
        agent &&
        refPhoneNumber &&
        refPhoneNumber.length > 9 ? (
          <Fragment>
            <ScreenContainer top={"64px"}>
              <FlexCenteredBlock top={"20px"}>
                <UserAvatar width={"80px"} height={"80px"} avatar={avatar} />
                <ReferralName>
                  {firstName} {lastName}
                </ReferralName>
                <ReferralPhoneNumber>{refPhoneNumber}</ReferralPhoneNumber>
                <ContactAgentBlock>
                  <ContactOption>
                    <ContactLink href={`tel:${refPhoneNumber}`}>
                      <PageLogo
                        background={"#E9F2FF"}
                        width={"40px"}
                        height={"40px"}
                        iconHeight={"20px"}
                        iconWidth={"20px"}
                        Icon={PhoneIcon}
                      />
                      <ContactText>Call</ContactText>
                    </ContactLink>
                  </ContactOption>
                  <ContactOption>
                    <ContactLink href={`sms:${refPhoneNumber}`}>
                      <PageLogo
                        background={"#FDEDE7"}
                        width={"40px"}
                        height={"40px"}
                        iconHeight={"20px"}
                        iconWidth={"20px"}
                        Icon={SMSIcon}
                      />
                      <ContactText>Send SMS</ContactText>
                    </ContactLink>
                  </ContactOption>
                  <ContactOption>
                    <ContactLink
                      href={`https://api.whatsapp.com/send?phone=${refPhoneNumber}&text=Hello`}
                    >
                      <PageLogo
                        background={"#D3FDE9"}
                        width={"40px"}
                        height={"40px"}
                        iconHeight={"20px"}
                        iconWidth={"20px"}
                        Icon={WhatsAppIcon}
                      />
                      <ContactText>Whatsapp</ContactText>
                    </ContactLink>
                  </ContactOption>
                </ContactAgentBlock>
              </FlexCenteredBlock>
            </ScreenContainer>
          </Fragment>
        ) : (
          <Fragment>
            <ScreenContainer top={"64px"} paddingBottom={"40px"}>
              <PageTitle size={"16px"} top={"16px"} bottom={"6px"}>
                Who referred you?
              </PageTitle>
              <PageSubTitle margin={"0"}>
                Enter phone number of your referral
              </PageSubTitle>
              <Formik
                initialValues={{
                  referralNumber:
                    referralNumber !== "Not Set" ? referralNumber : "",
                  referralCode: referralCode
                    ? referralCode.trim()
                    : referralLinkCode
                    ? referralLinkCode.trim()
                    : "",
                }}
                onSubmit={(values) => {
                  setTimeout(() => {
                    dispatch(
                      sendAgentReferral({ referralCode: values.referralNumber })
                    );
                  }, 400);
                }}
              >
                {({
                  errors,
                  touched,
                  setFieldValue,
                  values,
                  initialValues,
                }) => {
                  if (
                    values.referralNumber &&
                    values.referralNumber.length === 10
                  ) {
                    setUserReferralNumber(values.referralNumber);
                  }

                  return (
                    <Form>
                      <InputBlockContainer>
                        <InputGroupWrapper>
                          <InputWithLabel
                            label={"Referral Number"}
                            type={"text"}
                            value={values.referralNumber}
                            placeholder={"Referral Number"}
                            name="referralNumber"
                            valid={`${
                              !touched.referralNumber && !errors.referralNumber
                            }`}
                            errors={
                              touched &&
                              touched.referralNumber &&
                              errors &&
                              errors.referralNumber
                            }
                            setFieldValue={setFieldValue}
                            initialValues={initialValues}
                          />
                          {loadingAccountReferral === "LOADING" && (
                            <LoaderSpin />
                          )}
                          {loadingAccountReferral === "SUCCESSFUL" && (
                            <InputLabelName>{referralName}</InputLabelName>
                          )}
                        </InputGroupWrapper>
                        <AddButton
                          type="submit"
                          top={"50px"}
                          disabled={
                            !(
                              loadingAccountReferral === "SUCCESSFUL" &&
                              referralName
                            )
                          }
                        >
                          Add
                        </AddButton>
                      </InputBlockContainer>
                    </Form>
                  );
                }}
              </Formik>
            </ScreenContainer>
          </Fragment>
        )}
      </DesktopBackgroundLayout>
    );
  }
};

export default MerchantAndAgentReferrerDetail;
