import React, { useState } from "react";

import { Form, Formik } from "formik";
import { number, func, any } from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { RippleButton } from "../../../../../../../components";
import { NewTextInputWithLabel } from "../../../../../../../components/forms/input/new-text-input";
import { NewPhoneNumberInput } from "../../../../../../../components/forms/input/phone-number-input";
import { ScreenContainer } from "../../../../../../../containers/ScreenContainer";
import { colors } from "../../../../../../../styles";
import { SelectCustomer } from "../../../../customers/select-customer";
import { SalesModal } from "../../../components/modal";

import { AddCustomerValidationSchema } from "./validation";

const SubmitButton = styled(RippleButton)`
  background: ${colors.deepBlue};
  position: relative;
  width: 100%;
`;

const RadioButtonBox = styled.div`
  display: flex;
  cursor: pointer;
  width: 259px;
  height: 40px;
  align-items: center;
  border-radius: 12px;
  background: ${colors.themeColor3};
  margin: auto;
  padding: 0 5px;
`;

const NewCustomers = styled.input.attrs({ type: "radio" })`
  display: inline-block;
  display: none;

  & + label {
    flex: 1;
    color: ${colors.themeTxtColor10};
    background: ${colors.themeColor3};
    font-size: 12px;
    text-align: center;
    width: 112px;
    line-height: 18px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:checked + label {
    background: ${colors.white};
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.05);
    color: #212c3d;
    font-weight: 500;
    padding: 14px 0;
    border-radius: 8px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const ExistingCustomers = styled.input.attrs({ type: "radio" })`
  display: inline-block;
  display: none;
  & + label {
    flex: 1;
    color: ${colors.themeTxtColor10};
    background: ${colors.themeColor3};
    font-size: 12px;
    text-align: center;
    width: 112px;
    line-height: 18px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:checked + label {
    background: ${colors.white};
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.05);
    color: #212c3d;
    font-weight: 500;
    padding: 14px 0;
    border-radius: 8px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const ButtonWrapper = styled.div`
  position: fixed;
  padding: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 0;
  right: 0;
  bottom: 0px;
  width: 100%;
  max-width: 375px;
  margin: auto;
  @media (max-width: 576px) {
    max-width: 100%;
  }
`;

const InputFlexContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const CustomersPopUp = ({
  open,
  cancel,
  selectedCustomer,
  setSelectedCustomer,
  showFirstAndLastName,
  height,
}) => {
  const msisdn = useSelector((state) => state.user.msisdn);
  const [viewType, setViewType] = useState(true);
  const customers = useSelector((state) => state.applications.myShop.customers);
  const [customerName] = useState("");
  const [customerPhoneNumber] = useState("");
  const [firstName] = useState("");
  const [lastName] = useState("");

  return (
    <SalesModal
      open={open}
      title="Add Customer"
      cancel={cancel}
      height={height}
    >
      <RadioButtonBox>
        <NewCustomers
          name="paymentInFull"
          id="new"
          checked={viewType}
          onChange={() => setViewType((prev) => !prev)}
        />
        <label htmlFor="new">New Customer</label>
        <ExistingCustomers
          name="paymentInFull"
          id="existing"
          checked={!viewType}
          onChange={() => setViewType((prev) => !prev)}
        />
        <label htmlFor="existing">Existing Customer(s)</label>
      </RadioButtonBox>

      <ScreenContainer padding="1em 0" top="0">
        {viewType ? (
          <div>
            <Formik
              initialValues={{
                customerName,
                customerPhoneNumber,
                firstName,
                lastName,
              }}
              validationSchema={
                showFirstAndLastName ? null : AddCustomerValidationSchema
              }
              onSubmit={(values, { setErrors }) => {
                if (msisdn === values.customerPhoneNumber) {
                  setErrors({
                    customerPhoneNumber:
                      "Sorry, you cannot use your number for a customer",
                  });
                } else {
                  setSelectedCustomer({
                    customerName: values.customerName,
                    customerPhoneNumber: values.customerPhoneNumber,
                    firstName: values.firstName,
                    lastName: values.lastName,
                  });

                  cancel();
                }
              }}
            >
              {({ values }) => (
                <Form>
                  {showFirstAndLastName ? (
                    <InputFlexContainer>
                      <NewTextInputWithLabel
                        label={"First Name"}
                        placeholder={"First Name"}
                        name="firstName"
                      />
                      <NewTextInputWithLabel
                        label={"Last Name"}
                        placeholder={"Last Name"}
                        name="lastName"
                      />
                    </InputFlexContainer>
                  ) : (
                    <NewTextInputWithLabel
                      label={"Customer Name"}
                      placeholder={"Customer Name"}
                      name="customerName"
                    />
                  )}
                  <NewPhoneNumberInput
                    label={"Phone Number"}
                    name="customerPhoneNumber"
                    placeholder={"Enter Phone"}
                  />
                  <ButtonWrapper>
                    <SubmitButton
                      type="submit"
                      disabled={
                        showFirstAndLastName
                          ? !values.firstName ||
                            !values.lastName ||
                            values.customerPhoneNumber?.toString().length < 11
                          : false
                      }
                      top={"36px"}
                    >
                      Add Customer
                    </SubmitButton>
                  </ButtonWrapper>
                </Form>
              )}
            </Formik>
          </div>
        ) : (
          <SelectCustomer
            open={!viewType}
            setOpen={cancel}
            selectedCustomer={selectedCustomer}
            setSelectedCustomer={setSelectedCustomer}
            customers={customers}
          />
        )}
      </ScreenContainer>
    </SalesModal>
  );
};

CustomersPopUp.propTypes = {
  id: number,
  data: any,
  handleChange: func,
  remove: func,
};
