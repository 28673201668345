import axios from "axios";

import { trivialGameAPI } from "../../config";

import { authHeader } from "./authHeader";

export const utils = {
  postRequest: async (url, payload) => {
    const fullUrl = `${trivialGameAPI}${url}`;
    let config = {
      headers: {
        ...authHeader(),
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(fullUrl, payload, config);
      return res;
    } catch (error) {
      return error;
    }
  },

  getRequest: async (url) => {
    const fullUrl = `${trivialGameAPI}${url}`;
    let config = {
      headers: {
        ...authHeader(),
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.get(fullUrl, config);
      if (res.status === 401) {
        window.location.assign("/");
      } else {
        return res;
      }
    } catch (error) {
      return error;
    }
  },

  padNumWithZero: (num) => {
    var s = "0" + num;
    return s.substr(s.length - 2);
  },

  formatDate: (date) => {
    let m = new Date(date);
    return `${m.getUTCMonth() + 1}/${m.getUTCDate()}/${m.getUTCFullYear()}`;
  },

  iscurrentTimeGreaterThanStartTime: () => {
    let curTime = new Date();
    // let day = curTime.getDay();
    curTime = parseInt(
      curTime.getHours() +
        "" +
        ("0" + curTime.getMinutes()).substr(-2) +
        "" +
        ("0" + curTime.getSeconds()).substr(-2)
    );

    if (curTime > 170000 && curTime < 210000) return true;
    else return false;
  },

  iscurrentTimeGreaterThanEndTime: () => {
    let curTime = new Date();
    // let day = curTime.getDay();
    curTime = parseInt(
      curTime.getHours() +
        "" +
        ("0" + curTime.getMinutes()).substr(-2) +
        "" +
        ("0" + curTime.getSeconds()).substr(-2)
    );

    if (curTime > 210000) return true;
    else return false;
  },

  shuffleArray(arr) {
    if (arr && arr.length > 0) {
      let ctr = arr?.length;
      let temp;
      let index;
      // While there are elements in the array
      while (ctr > 0) {
        // Pick a random index
        index = Math.floor(Math.random() * ctr);
        // Decrease ctr by 1
        ctr--;
        // And swap the last element with it
        temp = arr[ctr];
        arr[ctr] = arr[index];
        arr[index] = temp;
      }
      return arr;
    } else {
      return [];
    }
  },

  shuffleQuestionOptions(questions) {
    return questions.map((item) => {
      return {
        answer: item.answer,
        options: this.shuffleArray(item.options),
        questionLevel: item.questionLevel,
        questionLifeLineCostQuantity: item.questionLifeLineCostQuantity,
        questionMultiPlayerPoints: item.questionMultiPlayerPoints,
        questionText: item.questionText,
      };
    });
  },

  formatNumberWithCommas: (x) => {
    try {
      var parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    } catch (error) {
      //
    }
  },
};
