import styled, { css } from "styled-components";

import WhatsappIcon from "../assets/whatsapp.svg";
import { colors } from "../styles";

export const Info = styled.div`
  background-color: ${({ color }) => color || "#E9F5FF"};
  padding: ${({ padding }) => padding || null};
  margin-top: ${({ top }) => top || null};
  margin-bottom: ${({ bottom }) => bottom || null};
  margin-left: ${({ left }) => left || null};
  border-radius: ${({ borderRadius }) => borderRadius || "8px"};
`;

export const Message = styled.h3`
  text-align: ${({ align }) => align || null};
  padding: ${({ padding }) => padding || null};
  font-size: ${({ size }) => size || "14px"};
  font-weight: ${({ weight }) => weight || 100};
  color: ${({ color }) => color || colors.themeTextColor3};
  line-height: ${({ lineHeight }) => lineHeight || "18px"};
  margin-top: ${({ top }) => top || "24px"};
  margin-bottom: ${({ bottom }) => bottom || null};
  margin-left: ${({ left }) => left || null};
  opacity: ${({ opacity }) => opacity || null};
  max-width: ${({ maxWidth }) => maxWidth || null};
  cursor: ${({ cursor }) => cursor || null};

  @media (max-width: 370px) {
    font-size: ${({ font }) => font || null};
  }
`;

export const PageCaption = styled.h2`
  font-size: ${({ size }) => size || "20px"};
  font-weight: ${({ weight }) => weight || "bold"};
  line-height: ${({ lineHeight }) => lineHeight || "32px"};
  color: ${({ color }) => color || colors.boldDark};
`;

export const PageTitleWrapper = styled.div`
  position: relative;
  margin-top: ${({ mtop }) => mtop || null};
  margin-bottom: ${({ mbottom }) => mbottom || null};
`;

export const PageTitle = styled.h3`
  font-size: ${({ size }) => size || "20px"};
  font-weight: ${({ weight }) => weight || "bold"};
  color: ${({ color }) => color || "#071827"};
  line-height: 24px;
  align-items: center;
  text-align: ${({ align }) => align || "center"};
  margin-top: ${({ top }) => top || null};
  margin-bottom: ${({ bottom }) => bottom || null};
`;

export const PageSubTitle = styled.p`
  font-size: ${({ size }) => size || "14px"};
  font-weight: ${({ weight }) => weight || "400"};
  color: ${({ color }) => color || "#718596"};
  line-height: 21px;
  text-align: ${({ align }) => align || "center"};
  margin: ${({ margin }) => margin || null};
`;

export const Strong = styled.strong`
  font-size: ${({ size }) => size || "14px"};
  font-weight: ${({ weight }) => weight || 400};
  color: ${({ color }) => color || colors.deepBlue};
`;

export const Label = styled.p`
  margin-top: ${({ top }) => top || "1.5em"};
  margin-bottom: ${({ bottom }) => bottom || "1em"};
  height: fit-content;
  width: fit-content;
`;

export const ResendCode = styled.p`
  margin-top: 39px;
  text-align: center;
  color: ${({ color }) => (color === "primary" ? colors.deepBlue : "blue")};
  font-size: 0.85em;
  font-weight: 500;
  padding: 16px 20px;
  cursor: pointer;
  background: none;
  outline: none;
  display: flex;
  margin-bottom: 30px;
  align-items: center;
  justify-content: center;
  border: 1px solid #227eff;
  border-radius: 4px;
`;

export const SendViaWhatsApp = styled.p`
  position: relative;
  display: flex;
  margin-top: 30px;
  text-align: center;
  color: ${({ color }) => color || colors.deepBlue};
  font-size: 0.85em;
  font-weight: 500;
  padding: 4px;
  cursor: pointer;
  outline: none;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
  gap: 4;
  &:before {
    content: url(${WhatsappIcon});
    position: relative;
    top: 2px;
    right: 4px;
  }
`;

export const ResendLabel = styled.p`
  margin-top: 40px;
  text-align: center;
  color: ${colors.themeTextColor0};
`;

export const ResendTimer = styled.span`
  color: ${colors.themeTextColor1};
`;

export const Title = styled.h2`
  font-size: ${({ size }) => size || "18px"};
  line-height: ${({ lineHeight }) => lineHeight || "18px"};
  font-weight: ${({ weight }) => weight || "500"};
  color: ${({ color }) => color || colors.themeTextColor1};
  margin-bottom: ${({ bottom }) => bottom || "8px"};
  margin-top: ${({ top }) => top || null};
  margin-left: ${({ left }) => left || null};
  text-align: ${({ textAlign }) => textAlign || "center"};
  max-width: ${({ maxWidth }) => maxWidth || null};
`;

export const MessageContainer = styled.div`
  background: #edf2f7;
  padding: 40px 20px 20px 20px;
  margin: 0 auto;
  margin-bottom: 8px;
  font-weight: ${({ weight }) => weight || "500"};
  text-align: ${({ textAlign }) => textAlign || "center"};
  color: ${({ color }) => color || colors.themeTextColor1};
  margin-top: ${({ top }) => top || null};
  margin-left: ${({ left }) => left || null};
  border-radius: 8px;
`;

export const SubTitle = styled.h5`
  font-size: ${({ font }) => font || "14px"};
  line-height: ${({ lineHeight }) => lineHeight};
  font-weight: ${({ weight }) => weight || "700"};
  text-align: ${({ textAlign }) => textAlign || null};
  color: ${({ color }) => color || colors.themeTextColor1};
  margin-top: ${({ top }) => top || null};
  margin-left: ${({ left }) => left || null};
  margin-bottom: ${({ bottom }) => bottom || null};
  text-transform: ${({ textTransform }) => textTransform};
  overflow-wrap: ${({ wrap }) => wrap || null};
  @media (max-width: 576px) {
    margin-top: ${({ mTop }) => mTop || null};
  }
`;

export const SecondaryText = styled(Label)`
  font-size: 12px;
  font-weight: ${({ weight }) => weight || "100"};
  color: ${({ color }) => color || colors.themeTextColor3};
  line-height: ${({ lineHeight }) => lineHeight || "15px"};
  margin: ${({ margin }) => margin || null};
  margin-top: ${({ top }) => top || null};
`;

export const SecondaryStrongText = styled.strong`
  color: ${colors.lightBoldBlue};
`;

export const SmallLightText = styled.p`
  font-weight: 100;
  font-size: 10px;
`;

export const Amount = styled.span`
  font-size: 12px;
  margin: 0px;
  font-weight: 500;
  line-height: 18px;
  color: #718596;
  ${({ txnClass, type }) =>
    txnClass === "CREDIT" &&
    type === "SUCCESSFUL" &&
    css`
      color: ${colors.transactions.credit};
    `};
  ${({ txnClass, type }) =>
    txnClass === "PAYMENT_LINK" &&
    type === "SUCCESSFUL" &&
    css`
      color: ${colors.transactions.credit};
    `};
  ${({ txnClass, type }) =>
    (type === "OPENED" || type === "PENDING") &&
    css`
      color: ${colors.transactions.credit};
    `};
  ${({ txnClass, type }) =>
    txnClass === "DEBIT" &&
    type === "SUCCESSFUL" &&
    css`
      color: ${colors.transactions.debit};
    `};
`;

export const TransactionAmount = styled.span`
  font-size: 18px;
  font-weight: 500;
  margin: 16px 0 24px 0;
  ${({ type }) =>
    type === "PENDING" &&
    css`
      color: ${colors.transactions.pending};
    `};
  ${({ type }) =>
    type === "PAID" &&
    css`
      color: ${colors.transactions.paid};
    `};
  ${({ type }) =>
    type === "WITHDRAWAL" &&
    css`
      color: ${colors.transactions.withDrawal};
    `};
  ${({ type }) =>
    type === "CREDIT" &&
    css`
      color: ${colors.transactions.credit};
    `};
  ${({ type }) =>
    type === "COMMISSION" &&
    css`
      color: ${colors.transactions.credit};
    `};
  ${({ type }) =>
    type === "DEBIT" &&
    css`
      color: ${colors.transactions.debit};
    `};
`;

export const TransactionStatus = styled.span`
  font-size: 14px;
  font-weight: 500;
  ${({ type }) =>
    type === "PENDING" &&
    css`
      color: ${colors.transactions.pending};
    `};
  ${({ type }) =>
    type === "PAID" &&
    css`
      color: ${colors.transactions.paid};
    `};
  ${({ type }) =>
    type === "WITHDRAWAL" &&
    css`
      color: ${colors.transactions.withDrawal};
    `};
`;

export const StatusText = styled.p`
  margin: 0;
  font: 14px;
  font-weight: 500;
  text-transform: capitalize;
  ${({ type }) =>
    type === "PENDING" &&
    css`
      color: ${colors.transactions.pending};
    `};
  ${({ type }) =>
    type === "FAILED" &&
    css`
      color: ${colors.transactions.failed};
    `};
  ${({ type }) =>
    type === "SUCCESSFUL" &&
    css`
      color: ${colors.transactions.successful};
    `};
  ${({ type }) =>
    type === "CLOSED" &&
    css`
      color: ${colors.transactions.successful};
    `};
  ${({ type }) =>
    type === "REFUNDED" &&
    css`
      color: ${colors.transactions.successful};
    `};
`;

export const CategoryLabel = styled.span`
  position: relative;
  padding: ${({ padding }) => padding || "4px 8px"};
  min-height: ${({ height }) => height || "24px"};
  min-width: ${({ width }) => width || "74px"};
  border-radius: 8px;
  background-color: ${colors.blueish};
  font-size: 12px;
  line-height: 16px;
  color: ${colors.blue};
`;

export const Error = styled.span`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || "flex-start"};
  color: ${colors.red};
  margin-top: ${({ top }) => top || "16px"};
  margin-left: ${({ left }) => left || null};
  margin-bottom: ${({ bottom }) => bottom || null};
  ${SmallLightText};
`;

export const PageCount = styled.span`
  font-size: ${({ fontSize }) => fontSize || "10px"};
  font-weight: ${({ weight }) => weight || "500"};
  line-height: 13px;
  color: ${({ color }) => color || colors.themeColor6};
  margin-bottom: ${({ bottom }) => bottom || null};
`;

export const CategoryRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: ${({ top }) => top || "24px"} 0 ${({ bottom }) => bottom || "8px"} 0;
`;

export const CategoryTitle = styled.h4`
  font-weight: 700;
  text-transform: ${({ noTransform }) => (noTransform ? "none" : "uppercase")};
  font-size: 12px;
  margin-left: 8px;
  color: #718596;
  line-height: 18px;
`;

export const BoldText = styled.span`
  font-weight: bold;
`;

export const UnderlinedText = styled.span`
  text-decoration: underline;
`;

export const PageSeeAll = styled.p`
  margin: 0;
  font-size: ${({ size }) => size || "12px"};
  font-weight: 500;
  color: ${({ color }) => color || colors.blue};
`;

export const ShowOption = styled.p`
  margin: 24px 0;
  font-size: 12px;
  font-weight: 100;
  color: ${colors.themeTextColor1};
`;
