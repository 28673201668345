import React, { useState, useEffect, Fragment } from "react";

import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { RippleButton, TopHeader } from "../../../../../components";
import { Close } from "../../../../../containers/HeaderContainer";
import { List } from "../../../../../containers/ListContainer";
import { ScreenContainer } from "../../../../../containers/ScreenContainer";
import { merchbuyActions } from "../../../../../redux/ducks/applications/merchbuy/actions";
import { colors } from "../../../../../styles";
import { formatPrice } from "../../../../../utils/currency/formatPriceWithComma";
import { ReactComponent as Check } from "../../assets/radio.svg";
import { ReactComponent as UnCheck } from "../../assets/radio_uncheck.svg";
import { Space, Container } from "../../styles";

const ModifiedContainer = styled(Container)`
  width: 100%;
`;

const ButtonWrapper = styled.div`
  padding: 0 1em 26px 1em;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

const DetailsSection = styled.div`
  padding: ${({ padding }) => padding || null};
  margin: 8px 1em 0 1em;
  border-radius: 4px;
  border: 1px solid ${({ borderColor }) => borderColor || colors.gray3};
  height: ${({ minHeight }) => minHeight || "97px"};
  background-color: ${({ background }) => background || null};
`;

const FlexContent = styled.div`
  display: flex;
`;

const ContentWrapper = styled(FlexContent)`
  display: flex;
  padding: 15px 16.4px;
  justify-content: space-between;
`;

const Text = styled.p`
  margin: 0;
  font-weight: ${({ fontWeight }) => fontWeight || "normal"};
  font-size: ${({ font }) => font || "12px"};
  color: ${({ color }) => color || null};
`;

const FeeText = styled.span`
  color: ${colors.deepBlue};
`;

const DetailWrapper = styled.div`
  display: grid;
  gap: 6px;
`;

export const ShippingCompany = ({
  close,
  getShippingDetails,
  deliveryDetails,
  totalQuantity,
  carts,
}) => {
  const dispatch = useDispatch();
  const shippingOptions = useSelector(
    (state) => state.applications.merchbuy.shippingOptions || []
  );
  const isFreeShipping = useSelector(
    (state) => state.applications.merchbuy?.shippingConfiguration?.value || {}
  );
  const user = useSelector((state) => state.user);
  const [selectedShippingOption, setSelectedShippingOption] = useState(null);
  const [isSelectedOption, setIsSelectedOption] = useState("");
  const onSelect = (value) => setSelectedShippingOption(value);

  useEffect(() => {
    async function fetchData() {
      let from = [];
      let to = [];
      await carts.forEach((cart) => {
        const cartData = {
          country: "Nigeria",
          state: cart?.shopData?.countryState,
          city: cart?.shopData?.city,
          address: cart?.shopData?.streetAddress,
        };
        from.push(cartData);
      });
      to.push({
        country: "Nigeria",
        state: deliveryDetails?.state || user?.regionData?.state,
        city: deliveryDetails?.city,
        address:
          deliveryDetails?.streetAddress ||
          deliveryDetails?.address ||
          user?.houseAddress,
      });

      dispatch(
        merchbuyActions.getWareNextShippingOptions({
          locationFrom: from,
          locationTo: to,
          details: {
            vehicleQuantity: 1,
            vehicleType: "motorbike",
            truckType: "",
            truckWeight: "",
          },
        })
      );
    }
    fetchData();
    dispatch(merchbuyActions.getShippingConfiguration());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <TopHeader
        noBorderBottom={true}
        title="Change Delivery Method"
        noArrow={true}
        withSpacesHeader
      >
        <Close left={"true"} onClick={close} />
      </TopHeader>
      <ScreenContainer
        height="100%"
        color={colors.lightestGray}
        padding={"0px"}
      >
        <ModifiedContainer>
          <Space height={"27px"} />
          <List noBorderBottom={true}>
            {shippingOptions.map((data, index) => (
              <DetailsSection
                key={data.partnerId}
                background={colors.white}
                borderColor={
                  isSelectedOption === data.partnerId ? colors.deepBlue : null
                }
                onClick={() => {
                  setIsSelectedOption(data.partnerId);
                  onSelect({
                    option: 2,
                    totalAmount: 0,
                    deliveryProvider: data.partnerName,
                    deliveryDate: "Estimated Delivery: 2 days",
                    pickupDate: "Processing Time: Today",
                    deliveryFee:
                      isFreeShipping === "true" ? 0 : data?.price?.price,
                  });
                }}
                minHeight="97px"
              >
                <ContentWrapper>
                  <FlexContent>
                    <DetailWrapper>
                      <Text
                        color={colors.boldDark}
                        fontWeight="500"
                        font="14px"
                      >
                        {data.partnerName}
                      </Text>
                      <Text color={colors.subGrey} font="12px">
                        Estimated Delivery: 2 days
                      </Text>
                      <Text color={colors.subGrey} font="12px">
                        Shipping Fee:{" "}
                        <FeeText>
                          {isFreeShipping === "true"
                            ? `${formatPrice(0)} (FREE)`
                            : formatPrice(data?.price?.price)}
                        </FeeText>
                      </Text>
                    </DetailWrapper>
                  </FlexContent>
                  {isSelectedOption === data.partnerId ? (
                    <Check />
                  ) : (
                    <UnCheck />
                  )}
                </ContentWrapper>
              </DetailsSection>
            ))}
          </List>
          <ButtonWrapper>
            <RippleButton
              disabled={selectedShippingOption ? false : true}
              onClick={() => getShippingDetails(selectedShippingOption)}
            >
              Select
            </RippleButton>
          </ButtonWrapper>
        </ModifiedContainer>
      </ScreenContainer>
    </Fragment>
  );
};
