import styled from "styled-components";

import { colors } from "../styles";

export const ScreenContainer = styled.main`
  display: ${({ flex }) => flex || null};
  flex-direction: ${({ direction }) => direction || null};
  padding: ${({ padding }) => padding || "1em"};
  padding-bottom: ${({ paddingBottom }) => paddingBottom || "65px"};
  margin-top: ${({ top }) => top || "45px"};
  background-color: ${({ color }) => color || null};
  height: ${({ height }) => height || null};
  min-height: ${({ minHeight }) => minHeight || null};
  width: 100%;

  @media (max-width: 576px) {
    top: ${({ withSpacesHeader }) => withSpacesHeader || "0"};
    margin-top: ${({ mtop }) => mtop || null};
  }
`;

export const Container = styled.div`
  padding: 0 1em;
  margin-top: ${({ top }) => top || null};
`;

export const SectionWrapper = styled.div`
  background-color: ${({ bgcolor }) => bgcolor || null};
  padding: ${({ padding }) => padding || "0 1em"};
  width: ${({ width }) => width || null};
  margin: ${({ margin }) => margin || null};
  min-height: ${({ minHeight }) => minHeight || null};
  height: ${({ height }) => height || null};
  border-radius: ${({ radius }) => radius || null};
`;

export const CenteredBlock = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  padding: 0 1em;
  margin-top: ${({ top }) => top || 0};
`;

export const FlexCenteredBlock = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: ${({ top }) => top || 0};
  padding: ${({ padding }) => padding};
  background-color: ${({ color }) => color};
  border-radius: ${({ radius }) => radius};
  margin-bottom: ${({ bottom }) => bottom};
  @media (max-width: 576px) {
    margin-top: ${({ mtop }) => mtop || 0};
    margin-bottom: ${({ mbottom }) => mbottom || null};
  }
`;

export const FlexSpaceBetweenBlock = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ top }) => top || 0};
  height: calc(100vh - 100px);
  width: 100%;
`;

export const FlexStartBlock = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: ${({ top }) => top || 0};
`;

export const FlexContainer = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent || "space-between"};
  margin-top: ${({ top }) => top || 0};
  margin-bottom: ${({ bottom }) => bottom || 0};
`;

export const FlexDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: ${({ align }) => align || "flex-end"};
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: -4px 0 24px 0;
  padding: 16px;
  border-radius: 10px;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.12);

  background-color: ${colors.white};
`;

export const FilterAndSortSection = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent || "space-between"};
  margin: ${({ top }) => top || "24px"} ${({ right }) => right || "0"}
    ${({ bottom }) => bottom || "0"} ${({ left }) => left || "0"};
  border-top: ${({ borderTop }) => borderTop || null};
  border-bottom: ${({ borderBottom }) => borderBottom || null};
  padding: ${({ padding }) => padding || null};
  background-color: ${({ background }) => background};
`;

export const SortFilterCell = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: ${({ color }) => color || colors.blue};
  font-weight: ${({ weight }) => weight || "100"};
  cursor: pointer;
  justify-content: ${({ justifyContent }) => justifyContent || null};
  border: ${({ border }) => border || null};
  width: ${({ width }) => width || null};
  border-right: ${({ borderRight }) => borderRight || null};
  padding: ${({ padding }) => padding || "10px 0 10px 0"};
  height: ${({ height }) => height || "24px"};

  & > svg {
    width: 24px;
    margin-right: 8px;
    margin-left: 8px;
  }
`;

export const TotalContainer = styled.div`
  width: ${({ width }) => width || null};
  height: ${({ height }) => height || null};
  margin: 0 auto;
  padding-left: 24px;
  padding-top: 10px;
  line-height: 10px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  border: 4px solid #ffffff;
  box-shadow: 0px 4px 36px rgba(0, 0, 0, 0.05);
  background: linear-gradient(
    109.36deg,
    rgba(253, 237, 231, 0.5) 9.97%,
    rgba(239, 221, 246, 0.5) 94.46%
  );
`;

export const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ top }) => top || "64px"};
  max-height: ${({ maxHeight }) => maxHeight};
  overflow-y: ${({ overflowY }) => overflowY || null};
`;

export const EmptyListContainer = styled.div`
  margin: 0 -16px;
  text-align: center;
  border-top: 1px solid ${colors.border.top};
  font-size: 12px;
  color: ${colors.themeTextColor3};
  line-height: calc(50vh + 8.5px);
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: ${({ top }) => top || "60vh"};
`;

export const PageProgressLine = styled.div`
  position: absolute;
  width: ${({ width }) => width || "100%"};
  height: 4px;
  left: 0;
  bottom: -2px;
  background-color: ${({ bgColor }) => bgColor || "#E9F2FF"};

  &::after {
    content: "";
    position: absolute;
    width: ${({ lineWidth }) => lineWidth || null};
    height: 4px;
    left: 0;
    bottom: 0;
    background-color: ${({ bgColor }) => bgColor || "#227EFF"};
  }
`;

export const PageProgressWrap = styled.div`
  position: absolute;
  display: flex;
  right: 15px;
  color: ${colors.themeTextColor0};
  z-index: 100;
`;

export const PageProgressStep = styled.span`
  font-size: 14px;
  color: ${colors.themeTextColor1};
`;

export const FloatingBottomSection = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0px;
  padding: 0px 16px 20px 16px;
  background-color: ${colors.white};
`;
