import React, { Fragment, useState } from "react";

import styled, { css } from "styled-components";

import { ReactComponent as ArrowIcon } from "../../../../../assets/arrow.svg";
import {
  TopHeader,
  PageLogo,
  RippleButton,
  IntroductionPopup,
} from "../../../../../components";
import { OptionList, Item } from "../../../../../components/popup/common";
import {
  Message,
  PageTitleWrapper,
  PageTitle,
  PageSubTitle,
} from "../../../../../containers/MessageContainer";
import {
  ScreenContainer,
  PageProgressWrap,
  PageProgressLine,
  PageProgressStep,
  FloatingBottomSection,
} from "../../../../../containers/ScreenContainer";
import History from "../../../../../utils/History";
import DesktopBackgroundLayout from "../../../../DesktopBackgroundLayout";

import AgentGroupIcon from "./assets/agent_network.svg";
import Check from "./assets/check.svg";
import { ReactComponent as Info } from "./assets/info.svg";
import SpaceForceIcon from "./assets/space_force.svg";
import { ReactComponent as SpacesSVG } from "./assets/spaces.svg";
import TermsInfoOverlay from "./termsInfoOverlay";

const InfoIcon = styled(Info)`
  position: absolute;
  margin-left: 7px;
`;

const Tip = styled(Message)`
  font-weight: 400;
  line-height: 21px;
  color: #718596;
  margin-bottom: 8px;
  text-align: left;
`;

const Title = styled(Tip)`
  font-size: 12px;
  line-height: 18px;
`;

const ArrowForward = styled(ArrowIcon)`
  position: absolute;
  right: 0;
`;

const SpacesIcon = styled(SpacesSVG)`
  height: 24px;
  weight: 24px;
`;

const PadBlock = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  background-color: #e9f2ff;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 8px;

  ${({ selected }) =>
    selected &&
    css`
      background-color: #227eff;
    `}
`;

const PadBlockIcon = styled.img``;

const BlockTitleWrapper = styled.div`
  margin: 8px;
`;

const PadBlockTitle = styled.h4`
  font-size: 16px;
  font-weight: 700;
  color: ${({ toggle }) => (toggle ? "#FFFFFF" : "#071827")};
  line-height: 24px;
`;

const PadBlockDetail = styled.h4`
  font-size: 12px;
  font-weight: 400;
  color: ${({ toggle }) => (toggle ? "#FFFFFFCC" : "#071827")};
  line-height: 18px;
  margin-top: 4px;
`;

const RadioWrapper = styled.div`
  position: relative;
  display: flex;
  height: 20px;
`;

const CheckButton = styled.span`
  display: inline-block;
  background: #ffffff;
  min-width: 20px;
  height: 20px;
  border-radius: 100%;
  vertical-align: middle;
  &::after {
    content: "";
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all 110ms;
    background-image: url(${Check});
    background-repeat: no-repeat;
    background-position: center;
  }
`;

const PadCheckBoxButton = styled.input`
  width: 20px;
  height: 20px;
  opacity: 0;
  &:checked + ${CheckButton} {
    opacity: 1;
    &::after {
      width: 12px;
      height: 12px;
      opacity: 1;
    }
  }
`;

const Divider = styled.div`
  border-bottom: 1px solid #edf2f7;
`;

const AgentGroup = () => {
  const [primaryNetworkPopup, setPrimaryNetworkPopup] = useState(false);
  const [termsPopup, setTermsPopup] = useState(false);
  const [openOverlay, setOpenOverlay] = useState(false);
  const [value, setValue] = useState("");

  return (
    <Fragment>
      <DesktopBackgroundLayout>
        <TopHeader title={"Agent Account"}>
          <PageProgressWrap>
            <PageProgressStep>5</PageProgressStep>/6
          </PageProgressWrap>
          <PageProgressLine lineWidth={"80%"} />
        </TopHeader>

        <ScreenContainer>
          <PageLogo
            top={"26px"}
            margin={"auto"}
            borderRadius={"0"}
            Icon={AgentGroupIcon}
            iconWidth="72px"
            iconHeight="72px"
          />
          <PageTitleWrapper mtop={"10px"} mbottom={"35px"}>
            <PageTitle size={"16px"} top={"16px"} bottom={"4px"}>
              Agent Network Selection
            </PageTitle>
            <PageSubTitle margin={"0"}>
              Please choose your agent network
            </PageSubTitle>
          </PageTitleWrapper>

          <Tip>
            Primary network
            <InfoIcon
              onClick={() => setPrimaryNetworkPopup(!primaryNetworkPopup)}
            />
          </Tip>

          <PadBlock
            selected={value === "Space Force"}
            onClick={() => setValue("Space Force")}
          >
            <PadBlockIcon src={SpaceForceIcon}></PadBlockIcon>
            <BlockTitleWrapper>
              <PadBlockTitle toggle={value === "Space Force"}>
                Seller
              </PadBlockTitle>
              <PadBlockDetail toggle={value === "Space Force"}>
                As a seller and get access to tools that help you manage your
                shop and your products.
              </PadBlockDetail>
            </BlockTitleWrapper>
            <RadioWrapper>
              <PadCheckBoxButton
                type="checkbox"
                name="networkType"
                value="Space Force"
                readOnly
                checked={value === "Space Force"}
              />
              <CheckButton />
            </RadioWrapper>
          </PadBlock>

          <Tip>Other Networks</Tip>

          <FloatingBottomSection>
            <RippleButton
              type="submit"
              disabled={!value}
              onClick={() => setTermsPopup(true)}
            >
              Continue
            </RippleButton>
          </FloatingBottomSection>
        </ScreenContainer>

        <IntroductionPopup
          open={primaryNetworkPopup}
          cancel={() => setPrimaryNetworkPopup(!primaryNetworkPopup)}
          title={"Primary Network"}
          textAlign={"left"}
          message={
            "This is the primary network you belong to. Your Primary network is the network you signed up under."
          }
        />

        <IntroductionPopup
          open={termsPopup}
          cancel={() => setTermsPopup(!termsPopup)}
          title={"Terms & Conditions"}
          textAlign={"left"}
          withConfirmation
          confirmText={"I Accept"}
          confirm={() => {
            History.push("/user/create_agent_pin");
          }}
          message={
            "Continuing means you agree to the terms & conditions of the selected agent network(s)."
          }
        >
          <Title>PRIMARY NETWORK</Title>
          <Divider />
          <OptionList>
            <Item style={{ padding: "17px 0px" }}>
              <RippleButton type={"button"} onClick={setOpenOverlay}>
                <SpacesIcon />
                {"Space Force"}
                <ArrowForward />
              </RippleButton>
            </Item>
          </OptionList>
          <Divider />
        </IntroductionPopup>

        <TermsInfoOverlay setOpen={setOpenOverlay} open={openOverlay} />
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default AgentGroup;
