import React, { Fragment, useEffect, useState } from "react";

import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { matchPath } from "react-router-dom";
import styled from "styled-components";
import useRouter from "use-react-router";

import { RippleButton, TopHeader } from "../../../../../components";
import { NewAmountInputWithLabel } from "../../../../../components/forms/input/new-amount-input";
import { updateSale } from "../../../../../redux/ducks/applications/my-shop/actions/sales";
import { colors } from "../../../../../styles";
import { formatPrice } from "../../../../../utils/currency/formatPriceWithComma";
import History from "../../../../../utils/History";
import DesktopBackgroundLayout from "../../../../DesktopBackgroundLayout";

import Congratulations from "./congratulations";

const Wrapper = styled.div`
  padding: 16px;
  margin-top: 75px;
`;

const Text = styled.div`
  font-size: ${({ size }) => size || "14px"};
  color: ${({ color }) => color || colors.black};
  margin: ${({ margin }) => margin || null};
  line-height: ${({ lineHeight }) => lineHeight || null};
  font-weight: ${({ weight }) => weight || "400"};
  text-align: ${({ align }) => align || null};
  opacity: ${({ opacity }) => opacity || null};
  position: relative;
`;

const Span = styled.span`
  color: ${colors.red};
`;

const UpdatePayment = () => {
  const dispatch = useDispatch();
  const debtorInfo = useSelector(
    (state) => state.applications.myShop.debtor?.debtorInfo || {}
  );
  const [balance, setBalance] = useState(0);
  const [salesInfo, setSalesInfo] = useState({});
  const [clicked, setClicked] = useState(false);
  const [openCompletePayment, setOpenCompletePayment] = useState(false);
  const [amount, setAmount] = useState(0);
  const [disableButton, setDisableButton] = useState(false);

  const { location } = useRouter();
  const { pathname } = location;
  const pattern = `(.*)?${"/actions/debtors/:id/update"}`;
  const match = matchPath(pathname, { path: pattern }) || {};

  useEffect(() => {
    const sale = debtorInfo?.debtHistory?.find(
      (debt) => debt.id === Number(match?.params?.id)
    );
    setSalesInfo(sale);
    setBalance(sale?.amountOutstanding);
  }, [debtorInfo, match.params.id]);

  const submit = async (amount) => {
    setDisableButton(true);
    await dispatch(
      updateSale(
        { ...salesInfo, customer: { id: debtorInfo.customerId } },
        amount
      )
    );
    setDisableButton(false);
    setBalance(balance - amount);
    setAmount(amount);
    setOpenCompletePayment(true);
  };

  const handleSubmit = (amount, setErrors) => {
    if (!amount) {
      setErrors({
        amount: "Please, enter an amount",
      });
      return;
    }
    if (amount > balance) {
      setErrors({
        amount: `Amount can't be greater than balance`,
      });
      return;
    } else {
      submit(amount);
    }
  };

  const close = () => {
    setOpenCompletePayment(false);
    History.goBack();
  };
  return (
    <Fragment>
      {openCompletePayment ? (
        <Congratulations
          name={debtorInfo?.name}
          amount={amount}
          balance={balance}
          close={close}
        />
      ) : (
        <DesktopBackgroundLayout>
          <TopHeader title="Repayment" noBorderBottom={true} noArrow={false} />
          <Wrapper>
            <Text
              margin="0 0 16px 0"
              weight="bold"
              size="16px"
              lineHeight="24px"
              color={colors.boldDark}
            >
              How much is {debtorInfo?.name?.split(" ")[0]} repaying?
            </Text>
            <Formik
              initialValues={{
                amount: "",
              }}
              onSubmit={(values, { setErrors }) => {
                handleSubmit(values.amount, setErrors);
              }}
            >
              {({ errors }) => {
                return (
                  <Form>
                    <NewAmountInputWithLabel
                      label="Enter Amount"
                      placeholder="Enter Amount"
                      name="amount"
                      bottom="4px"
                    />
                    <Text
                      margin={`${
                        errors.amount && clicked ? "0" : "-15px"
                      } 0 0 16px`}
                      size="10px"
                      lineHeight="16px"
                      color={colors.lightDark}
                    >
                      Balance: <Span>{formatPrice(balance)}</Span>
                    </Text>
                    <RippleButton
                      onClick={() => setClicked(true)}
                      height="56px"
                      top="31px"
                      type="submit"
                      disabled={disableButton}
                    >
                      Add Payment
                    </RippleButton>
                  </Form>
                );
              }}
            </Formik>
          </Wrapper>
        </DesktopBackgroundLayout>
      )}
    </Fragment>
  );
};

export default UpdatePayment;
