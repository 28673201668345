import React from "react";

import { any } from "prop-types";

import { AddFlexBox } from "../../styles";
import { ShopItem } from "../shopItem";

export const ListShops = ({ data, size, showProductCategory }) => {
  return (
    <AddFlexBox justifyContent={"center"}>
      {data &&
        data.map((item, index) => (
          <ShopItem
            showProductCategory={showProductCategory}
            key={index}
            data={item}
            size={size}
          ></ShopItem>
        ))}
    </AddFlexBox>
  );
};

ListShops.propTypes = {
  data: any,
  size: any,
};
