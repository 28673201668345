import React, { useState } from "react";

import { arrayOf, string, bool, shape, func, object } from "prop-types";
import styled from "styled-components";

import { Close } from "../../../containers/HeaderContainer";
import { ListCheckedRow } from "../../../containers/ListContainer";
import { SubTitle } from "../../../containers/MessageContainer";
import { SlidingOverlay } from "../../../containers/OverlayContainer";
import { colors } from "../../../styles";
import { FloatingButton, FloatingButtonWrapper } from "../../button";
import { SearchHeader } from "../../header/search-header";

const AlphabetList = styled.ul`
  display: flex;
  flex-direction: column;

  @media (min-width: 576px) {
    position: relative;
    padding: ${({ padding }) => padding || 0};
  }
`;

const AlphabetItems = styled.li`
  flex-direction: column;
  margin: 0;
  padding: 0;
`;

const SortedItems = styled(ListCheckedRow)`
  padding: 16px;
  margin-left: 0;
  border-top: 1px solid ${colors.border.top};
  border-bottom: 1px solid ${colors.border.bottom};
  position: relative;
  font-size: 14px;
`;

const AlphabetLetter = styled(SubTitle)`
  font-weight: 100;
  margin: 24px 16px;
`;

export const SortedSelectOverlay = ({
  name,
  open,
  title,
  setValue,
  selectedLabels,
  sortedList,
  setOpen,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const searchedSortedList = sortedList.filter((data) =>
    data.letter.toLowerCase().startsWith(searchValue.charAt(0).toLowerCase())
  );

  return (
    open && (
      <SlidingOverlay
        style={{ margin: "0 auto" }}
        top={"64px"}
        zIndex={"1000"}
        minHeight={"inherit"}
      >
        <SearchHeader
          noArrow={true}
          placeholder={"Select a business category..."}
          title={title}
          handleSearch={setSearchValue}
        >
          <Close left={"true"} onClick={setOpen} />
        </SearchHeader>
        <AlphabetList padding={"64px 0 0;"}>
          {searchedSortedList.map(({ letter, items }, index) => (
            <AlphabetItems borderTop={"none"} key={index + 10}>
              <AlphabetLetter>{letter}</AlphabetLetter>
              {items &&
                items
                  .toLocaleString()
                  .split(",")
                  .filter((item) => item.includes(searchValue.toLowerCase()))
                  .map((item, index) => (
                    <SortedItems
                      key={index}
                      onClick={(event) => {
                        if (event.target.classList.contains("active")) {
                          const newTypes = selectedLabels.current.filter(
                            (type) => type !== item
                          );
                          selectedLabels.current = newTypes;
                          setValue(name, newTypes);
                          event.target.classList.remove("active");
                        } else {
                          event.target.classList.add("active");

                          if (!selectedLabels.current.includes(item)) {
                            const newLabels =
                              selectedLabels.current.concat(item);
                            selectedLabels.current = newLabels;
                            setValue(name, newLabels);
                          }
                        }
                      }}
                    >
                      {item}
                    </SortedItems>
                  ))}
            </AlphabetItems>
          ))}
        </AlphabetList>
        <FloatingButtonWrapper>
          <FloatingButton type={"button"} onClick={() => setOpen(!open)}>
            Done
          </FloatingButton>
        </FloatingButtonWrapper>
      </SlidingOverlay>
    )
  );
};

SortedSelectOverlay.propTypes = {
  open: bool,
  title: string,
  setOpen: func,
  setValue: func,
  selectedLabels: object,
  sortedList: arrayOf(
    shape({
      letter: string,
      items: arrayOf(string),
    })
  ),
};
