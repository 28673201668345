import React, { lazy, Suspense } from "react";

import ProductCategorySkeleton from "../components/listProductCategories/productCategorySkeleton";

const MerchbuyProductCategories = lazy(() => {
  return new Promise((resolve) => setTimeout(resolve, 1000)).then(() =>
    import("./index")
  );
});

const MerchbuyList = () => {
  return (
    <Suspense fallback={<ProductCategorySkeleton />}>
      <MerchbuyProductCategories />
    </Suspense>
  );
};

export default MerchbuyList;
