import React, { Fragment, useState } from "react";

import { useSelector } from "react-redux";

import { DeliveryLocationPopup, RippleButton } from "../../../../../components";
import { Message, Title } from "../../../../../containers/MessageContainer";
import {
  FlexCenteredBlock,
  ScreenContainer,
} from "../../../../../containers/ScreenContainer";
import History from "../../../../../utils/History";
import DesktopBackgroundLayout from "../../../../DesktopBackgroundLayout";
import { ReactComponent as AddedToCartIcon } from "../../assets/added-to-cart.svg";
import { Space } from "../../styles";

const AddedToCartView = () => {
  const productName = useSelector((state) =>
    state.router.location.params ? state.router.location.params.name : null
  );
  const [deliveryLocation, setDeliveryLocation] = useState(
    JSON.parse(localStorage.getItem("deliveryLocation"))
  );

  const [openDeliverLocationPopup, setOpenDeliverLocationPopup] = useState(
    deliveryLocation === null
  );

  const getLocation = (data) => {
    if (data.state /*&& data.lga*/) {
      setDeliveryLocation(data);
      setOpenDeliverLocationPopup(!openDeliverLocationPopup);
    }
  };
  return (
    <Fragment>
      <DesktopBackgroundLayout>
        <ScreenContainer>
          <FlexCenteredBlock top={"60px"}>
            <AddedToCartIcon />
            <Space height={"32px"} />

            <Title>Added to cart!</Title>
            <Message
              bottom={"24px"}
              top={"8px"}
              align={"center"}
              padding={"0 1em"}
            >
              {productName || "Item"} was added to your cart.
            </Message>
          </FlexCenteredBlock>

          <RippleButton
            top={"45px"}
            onClick={() => History.push("/actions/merchbuy/cart")}
          >
            Checkout
          </RippleButton>
          <RippleButton
            top={"20px"}
            style={{
              backgroundColor: `rgba(87,159,215,.4)`,
              color: `rgb(59, 120, 220)`,
              paddingLeft: 20,
              paddingRight: 20,
              opacity: `40%`,
            }}
            onClick={() => History.push("/actions/merchbuy")}
          >
            Continue shopping
          </RippleButton>
        </ScreenContainer>
        {openDeliverLocationPopup && (
          <DeliveryLocationPopup
            open={openDeliverLocationPopup}
            getLocation={getLocation}
            cancel={() => {
              setOpenDeliverLocationPopup(!openDeliverLocationPopup);
            }}
          />
        )}
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default AddedToCartView;
