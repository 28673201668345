import { toast } from "react-toastify";

import { axios, currentAPI } from "../../../../../config";
import {
  LOADING_COMPLAINTS,
  COMPLAINTS_LOADED,
  COMPLAINTS_LOAD_ERROR,
} from "../constants";

export const logComplaint = (params, formData) => async (dispatch) => {
  dispatch({ type: LOADING_COMPLAINTS });

  try {
    const formDataToSend = formData ? formData : new FormData();
    formDataToSend.append("ticket", JSON.stringify(params));

    const res = await axios.post(
      encodeURI(`${currentAPI}/api/complaints/v2/tickets/createTicket`),
      formDataToSend,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (res?.status === 200) {
      dispatch({ type: COMPLAINTS_LOADED });
      return true;
    }
  } catch (error) {
    dispatch({ type: COMPLAINTS_LOAD_ERROR });
    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
  }
};

export const getComplaintDetails = (reference) => async (dispatch) => {
  dispatch({ type: LOADING_COMPLAINTS });

  try {
    const res = await axios.get(
      `${currentAPI}/api/complaints/tickets/reference/${reference}`
    );

    if (res.status === 200) {
      dispatch({ type: COMPLAINTS_LOADED });
      return res?.data?.data;
    }
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return {};
    }
    if (error.response && error.response.status === 400) {
      dispatch({ type: COMPLAINTS_LOAD_ERROR });
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
  }
};

export const getComplaintFiles = (ticketId) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${currentAPI}/api/complaints/tickets/uploads/${ticketId}`
    );

    if (res.status === 200) {
      const {
        data: { data },
      } = res?.data;
      return data;
    }
  } catch (error) {
    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
  }
};

export const getComplaintComments =
  (reference, pageNumber = 1, pageSize = 100) =>
  async (dispatch) => {
    dispatch({ type: LOADING_COMPLAINTS });
    try {
      const res = await axios.get(
        `${currentAPI}/api/complaints/tickets/v2/${reference}/comments?pageNumber=${pageNumber}&pageSize=${pageSize}`
      );
      dispatch({ type: COMPLAINTS_LOADED });
      if (res.status === 200) {
        return res?.data?.data;
      } else return [];
    } catch (error) {
      dispatch({ type: COMPLAINTS_LOAD_ERROR });
      if (error.response && error.response.status === 404) {
        return [];
      }
      if (error.response && error.response.status === 400) {
        const { data } = (error && error.response) || {};
        data.message && toast.error(error.response.data.message);
      }
    }
  };

export const addCommentToComplaint =
  (ticketId, formData) => async (dispatch) => {
    dispatch({ type: LOADING_COMPLAINTS });

    try {
      const res = await axios.post(
        `${currentAPI}/api/complaints/tickets/v2/replyAticket/${ticketId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.status === 200) {
        dispatch({ type: COMPLAINTS_LOADED });
        return true;
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const { data } = (error && error.response) || {};
        data.message && toast.error(error.response.data.message);
      }
      dispatch({ type: COMPLAINTS_LOAD_ERROR });
    }
  };

export const uploadTicketFile = (ticketId, formData) => async (dispatch) => {
  dispatch({ type: LOADING_COMPLAINTS });

  try {
    const res = await axios.post(
      `${currentAPI}/api/complaints/tickets/uploads/${ticketId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (res.status === 200) {
      dispatch({ type: COMPLAINTS_LOADED });
      return true;
    }
  } catch (error) {
    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
    dispatch({ type: COMPLAINTS_LOAD_ERROR });
  }
};
