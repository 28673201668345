import React from "react";

import { array, number, string, func, any } from "prop-types";
import { toast } from "react-toastify";
import styled from "styled-components";

import { colors } from "../../../../../styles";
import { ReactComponent as SubstractIcon } from "../../assets/Minus.svg";
import { ReactComponent as AddIcon } from "../../assets/Plus.svg";
import { QuantityCount } from "../../styles";
import { ReactComponent as SubstractBlueIcon } from "../assets/MinusBlue.svg";
import { ReactComponent as AddBlueIcon } from "../assets/PlusBlue.svg";

const QuantityControls = styled.div`
  display: flex;
  position: ${({ position }) => position || "absolute"};
  right: ${({ right }) => right || "25px"};
  width: ${({ width }) => width || null};
  top: ${({ top }) => top || "15px"};
`;

const Add = styled(AddIcon)`
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-left: 8px !important;
`;
const AddBlue = styled(AddBlueIcon)`
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-left: 8px !important;
`;
const Substract = styled(SubstractIcon)`
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-right: 8px !important;
`;

const SubstractBlue = styled(SubstractBlueIcon)`
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-right: 8px !important;
`;

const Input = styled.input`
  font-size: 12px;
  font-weight: ${({ fontWeight }) => fontWeight || "500"};
  max-width: 35px;
  border: none;
  text-align: center;
  background-color: ${colors.themeColor3};
  height: 27px;
  border-radius: 2px;
`;

const changeQuantity = (updateCart, productId, carts, action, e) => {
  const updatedcartsItems = carts.map((product) => {
    const { quantity } = product;
    if (product.id === productId) {
      if (action === "add") {
        const newQty = parseInt(quantity) + 1;
        product.quantity = newQty;
        product.cost = parseInt(product.cost) + parseInt(product.minPrice);
      } else if (action === "input") {
        const newQty = Number(e.target.value.replace(/\D/g, ""));
        product.quantity = newQty;
        product.cost = parseInt(product.cost) + parseInt(product.minPrice);
      } else {
        product.quantity =
          parseInt(quantity) - (quantity >= product.moq ? 1 : 0);
        product.cost =
          quantity === 1
            ? parseInt(product.cost)
            : parseInt(product.cost) - parseInt(product.minPrice);
      }
    }
    return product;
  });
  carts = [...updatedcartsItems];
  updateCart(carts);
};

export const SetAvailableQuantity = ({
  updateCart,
  productQuantity,
  productId,
  carts,
  moq,
  position,
  right,
  width,
  qtyHeight,
  top,
  isEditable,
}) => {
  const handleEdit = (e, action) => {
    if (e.target.value && e.target.value < 1) {
      return toast.info("You can't have less than 1 quantity in cart");
    } else {
      changeQuantity(updateCart, productId, carts, action, e);
    }
  };
  return (
    <QuantityControls top={top} position={position} right={right} width={width}>
      {isEditable && productQuantity > 0 && !(productQuantity <= moq) ? (
        <SubstractBlue
          onClick={(e) => handleEdit(e, "substract")}
          style={{ marginRight: "8px" }}
        ></SubstractBlue>
      ) : (
        <Substract
          onClick={(e) => null}
          style={{ marginRight: "8px" }}
        ></Substract>
      )}
      <QuantityCount radius="0" padding="0" qtyHeight="27px">
        <Input
          qtyHeight={qtyHeight}
          onChange={(e) => (isEditable ? handleEdit(e, "input") : null)}
          value={productQuantity}
          type="text"
          inputMode={"numeric"}
        />
      </QuantityCount>
      {isEditable ? (
        <AddBlue
          onClick={(e) => handleEdit(e, "add")}
          style={{ marginLeft: "8px" }}
        ></AddBlue>
      ) : (
        <Add onClick={() => null} style={{ marginLeft: "8px" }}></Add>
      )}
    </QuantityControls>
  );
};

SetAvailableQuantity.propTypes = {
  selectedProducts: array,
  productId: string,
  productQuantity: number,
  carts: any,
  updateCart: func,
  moq: any,
};
