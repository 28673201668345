import React, { Fragment, useState } from "react";

import { useSelector } from "react-redux";

import { SuccessPage } from "../../../../components";
import { SlidingOverlay } from "../../../../containers/OverlayContainer";
import DesktopBackgroundLayout from "../../../DesktopBackgroundLayout";
import ComplaintDetails from "../complaint-details";
const ComplaintSuccess = ({ open, cancel }) => {
  const [openComplaintDetails, setOpenComplaintDetails] = useState(false);
  const complaints = useSelector((state) => state.help.complaints);

  function handleClick() {
    setOpenComplaintDetails(!openComplaintDetails);
  }

  return (
    open && (
      <Fragment>
        {!openComplaintDetails && (
          <SlidingOverlay>
            <DesktopBackgroundLayout>
              <SuccessPage
                title={"Complaint Created"}
                subtitle={"Your complaint has been succesfully logged."}
                doneText={"View Complaint"}
                onDoneClick={handleClick}
                mposition={"fixed"}
                position={"absolute"}
              />
            </DesktopBackgroundLayout>
          </SlidingOverlay>
        )}
        {openComplaintDetails && (
          <ComplaintDetails
            complaint={complaints[0]}
            open={openComplaintDetails}
            setOpen={setOpenComplaintDetails}
            cancel={cancel}
            colorIndex={0}
          />
        )}
      </Fragment>
    )
  );
};

export default ComplaintSuccess;
