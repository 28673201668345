import React from "react";

import {
  ComplaintsForm,
  ComplaintsViewComment,
} from "../../views/actions/complaints";

export const complaintsRoutes = [
  {
    path: "/actions/complaints/log_complaint",
    exact: true,
    main: () => <ComplaintsForm />,
    public: false,
  },
  {
    path: "/actions/view_comment",
    exact: true,
    main: () => <ComplaintsViewComment />,
    public: false,
  },
];
