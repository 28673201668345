import React, { Fragment, useRef, useState } from "react";

import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import {
  TopHeader,
  InputWithLabel,
  SelectBox,
  // MultipleLabelsSelect,
  RippleButton,
  NoConnection,
  PasswordGroup,
  Loader,
} from "../../../../../components";
import { Message } from "../../../../../containers/MessageContainer";
import { ScreenContainer } from "../../../../../containers/ScreenContainer";
import { localAreas } from "../../../../../data/countries/nigeria/localAreas";
import { states } from "../../../../../data/countries/nigeria/states";
import * as actions from "../../../../../redux/ducks/applications/my-shop/actions/shop";
import { getInputValues } from "../../../../../utils/inputs/getInputValues";
import { selectAreasByState } from "../../../../../utils/inputs/selectAreasByState";
import DesktopBackgroundLayout from "../../../../DesktopBackgroundLayout";

import {
  ShopSetupValidationSchema,
  MerchantShopSetupValidationSchema,
} from "./ShopSetupValidationSchema";

const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const SetupShop = () => {
  const dispatch = useDispatch();

  // const selectedLabels = useRef([]);
  const setFormikErrors = useRef(undefined);
  const [networkErrorOpen, setNetworkErrorOpen] = useState(false);
  const businessPhoneNumber = useSelector((state) => state.user.msisdn);
  const isLoading = useSelector((state) => state.applications.myShop.isLoading);
  const email = useSelector((state) => state.user.email);
  const role = useSelector((state) => state.user.role);

  const submitShop = async (values, setErrors, setNetworkErrorOpen) => {
    if (role === "ROLE_AGENT") {
      const data = {
        adress: values.streetAddress,
        localGovt: values.lga,
        name: values.shopName,
        state: values.countryState,
      };
      dispatch(actions.createAgentShop(data, setErrors, setNetworkErrorOpen));
    } else {
      dispatch(actions.createShop(values, setErrors, setNetworkErrorOpen));
    }
  };

  return isLoading ? (
    <Loader />
  ) : (
    <Fragment>
      <DesktopBackgroundLayout>
        {!networkErrorOpen && (
          <TopHeader title={"Shop setup"} withSpacesHeader />
        )}
        <ScreenContainer>
          <Formik
            initialValues={{
              shopName: "",
              streetAddress: "",
              countryState: "",
              businessPhoneNumber,
              email,
              lga: "",
              password: "",
            }}
            validationSchema={
              role === "ROLE_AGENT"
                ? ShopSetupValidationSchema
                : MerchantShopSetupValidationSchema
            }
            onSubmit={(values, { setErrors }) => {
              setFormikErrors.current = setErrors;
              submitShop(values, setErrors, setNetworkErrorOpen);
            }}
          >
            {({
              errors,
              handleChange,
              values,
              touched,
              setFieldValue,
              initialValues,
            }) => (
              <Form style={{ marginTop: "24px" }}>
                <InputBlock>
                  <InputWithLabel
                    label="Shop name"
                    placeholder="Shop name"
                    name="shopName"
                    type="text"
                    errors={
                      touched && touched.shopName && errors && errors.shopName
                    }
                    valid={`${touched.shopName && !errors.shopName}`}
                    setFieldValue={setFieldValue}
                    initialValues={initialValues}
                    bottom={"16px"}
                  />
                  <InputWithLabel
                    label="Street address"
                    placeholder="Street address"
                    name="streetAddress"
                    type="text"
                    errors={
                      touched &&
                      touched.streetAddress &&
                      errors &&
                      errors.streetAddress
                    }
                    valid={`${touched.streetAddress && !errors.streetAddress}`}
                    setFieldValue={setFieldValue}
                    initialValues={initialValues}
                    bottom={"0px"}
                  />
                  <SelectBox
                    name={"countryState"}
                    placeholder={"Province"}
                    value={values.countryState}
                    options={states}
                    bottom={"15px"}
                    handleChange={handleChange}
                    valid={`${!touched.countryState && !errors.countryState}`}
                    error={
                      touched &&
                      touched.countryState &&
                      errors &&
                      errors.countryState
                    }
                  />
                  <SelectBox
                    name={"lga"}
                    placeholder={"Municipalities"}
                    value={values.lga}
                    options={
                      values.countryState
                        ? selectAreasByState(values.countryState, localAreas)
                        : []
                    }
                    handleChange={handleChange}
                    valid={`${!touched.lga && !errors.lga}`}
                    error={touched && touched.lga && errors && errors.lga}
                    bottom={"15px"}
                  />
                  {/* <MultipleLabelsSelect
                                        title={'Business categories'}
                                        selectedLabels={selectedLabels}
                                        setValue={setFieldValue}
                                        name={'businessCategories'}
                                        sortedList={categories.map((category) => category.name)}
                                    /> */}
                  {role !== "ROLE_AGENT" && (
                    <Fragment>
                      <Message top={"24px"} bottom={"16px"}>
                        Password
                      </Message>
                      <PasswordGroup
                        count={6}
                        startIndex={1}
                        type={"password"}
                        valid={`${touched.password && !errors.password}`}
                        errors={errors}
                        name={"password"}
                        touched={touched}
                        align={"center"}
                        enteredValue={values.password}
                        handleChange={() => {
                          setFieldValue("password", getInputValues("password"));
                        }}
                      />
                    </Fragment>
                  )}
                  {networkErrorOpen && (
                    <NoConnection
                      open={networkErrorOpen}
                      setOpen={setNetworkErrorOpen}
                      tryAgain={() => {
                        setNetworkErrorOpen(!networkErrorOpen);
                        actions.createShop(
                          values,
                          setFormikErrors.current,
                          setNetworkErrorOpen
                        );
                      }}
                    />
                  )}
                  {/* <ErrorMessage name={'businessCategories'} component="div" className="form-error" /> */}
                </InputBlock>
                <RippleButton
                  type="submit"
                  top={"24px"}
                  disabled={Object.values(values).some(
                    (value) => value && value.length === 0
                  )}
                >
                  Save
                </RippleButton>
              </Form>
            )}
          </Formik>
        </ScreenContainer>
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default SetupShop;
