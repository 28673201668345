export const SAVE_USER_TELEPHONE = "user/save_user_phone";
export const SAVE_USER_DATA = "user/save_user_data";
export const SAVE_USER_AGENT_DATA = "user/save_user_agent_data";
export const SAVE_USER_CREATION_DATE = "user/save_user_creation_date";
export const SAVE_USER_ROLE = "user/save_user_role";
export const SAVE_USER_AVATAR = "user/save_user_avatar";
export const SAVE_USER_NAME = "user/save_user_name";
export const SAVE_PHONE_NUMBER = "user/save_phone_number";
export const SAVE_USER_COUNTRY = "user/save_user_country";
export const SAVE_USER_ID = "user/save_user_id";
export const SAVE_USER_DOCUMENT = "user/save_user_document";
export const SAVE_USER_DOCUMENT_DATA = "user/save_user_document_data";
export const SAVE_USER_PASSPORT = "user/save_user_passport";
export const SAVE_USER_PASSPORT_DATA = "user/save_user_passport_data";
export const SAVE_USER_REGION = "user/save_user_region";
export const SAVE_USER_ADDRESS = "user/save_user_address";
export const SAVE_USER_VERIFICATION_STATUS =
  "user/save_user_verification_status";
export const SAVE_USER_EMAIL = "user/save_user_email";
export const SAVE_USER_REFERRAL_CODE = "user/save_user_referral_code";
export const SAVE_AGENT_SHARE_CODE = "user/save_agent_share_code";
export const SET_USER_MERCHAPP_PRESENT = "user/set_user_merchapp_present";
export const SAVE_REJECTED_REASONS = "user/get_user_rejected_reasons";
export const GET_USER_DOCUMENTS = "user/get_user_documents";
export const GET_DEFAULT_BANK_DATA = "user/get_default_bank_data";
export const GET_USER_KYC_VERIFICATION_STATE =
  "user/get_user_kyc_verification_state";
export const SAVE_USER_DOCUMENT_STATE = "user/save_user_document_state";
export const SAVE_USER_PASSPORT_STATE = "user/save_user_passport_state";
export const GET_USER_TIER_LEVEL = "user/get_user_tier_level";
export const SAVE_USER_EDUCATION = "user/save/save_user_education";

export const ACTIVATE_USER_ACCOUNT = "user/activate_user_account";
export const SET_USER_LOGIN_STATUS = "user/set_user_login_status";
export const USER_LOGOUT = "user/user_logout";
export const SAVE_USER_BVN = "user/save_user_bvn";
export const SAVE_USER_REALM = "user/save_user_realm";
export const SAVE_ACCOUNT_REFERRAL = "user/save_account_referral";
export const FETCH_ACCOUNT_REFERRAL = "user/fetch_account_referral";
export const UPDATE_USER_NPS_DETAILS = "user/update_user_nps_details";
export const GET_USER_NPS_DETAILS = "user/get_user_nps_details";
export const SAVE_TRANSACTIONS_PIN_STATUS = "user/save_transactions_pin_status";
export const SAVE_AGENT_NETWORK_AND_TEAMS = "user/save_agent_network_and_teams";
export const GET_SURVEY_TYPES = "user/get_survey_types";
export const GET_USER_LAST_SURVEY = "user/get_user_last_survey";
export const CHECK_ORDER_SURVEY = "user/check_order_survey";
export const GET_WINDOW = "user/get_window";
export const GET_FEATURES = "user/get_features";
