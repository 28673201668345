import React, { Fragment, useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  OptionsPopupDialog,
  TopHeader,
  Loader,
  DateRangePopup,
  SpeficDatePopup,
} from "../../../../components";
import { FilterAndSortSection } from "../../../../containers/ScreenContainer";
import { reportActions } from "../../../../redux/ducks/applications/my-shop/actions/reports";
import {
  INVENTORY_REPORT,
  PROFIT_REPORT,
  SALES_REPORT,
  MY_SHOP_HOME_INTERACTION,
} from "../../../../utils/mix-panel/constants";
import { mixPanel } from "../../../../utils/mix-panel/mixPanel";
import DesktopBackgroundLayout from "../../../DesktopBackgroundLayout";

import { ReactComponent as CalenderIcon } from "./assets/calender.svg";
import { ReactComponent as DropdownArrow } from "./assets/dropdownArrow.svg";
import { BubbleChartComponent } from "./components/BubbleChart";
import { DropdownList } from "./components/Dropdown";
import { HorizontalBarChart } from "./components/HorizontalBarChart";
import { NoData } from "./components/NoData";
import { SummaryText, SortDropdownBox, Container } from "./reports.styles";
import { initialData } from "./utils/initialData";
import {
  formatBarChartData,
  formatYearlyBarChartData,
} from "./utils/processData";

const Reports = () => {
  const dispatch = useDispatch();
  const [sortOptionsOpen, setSortOptionsOpen] = useState(false);
  const [sortType, setSortType] = useState({
    key: "WEEKLY",
    value: "This week",
    type: "week",
    height: 300,
  });
  const [isLoading, setIsloading] = useState(false);
  const loadingSalesReport = useSelector(
    (state) => state.applications.myShop.loadingSalesReport
  );
  const loadingProductsReport = useSelector(
    (state) => state.applications.myShop.loadingProductsReport
  );

  const weeklySalesReport = useSelector(
    (state) => state.applications.myShop.weeklySalesReport || []
  );

  const salesReportExtra = useSelector(
    (state) => state.applications.myShop.salesReportExtra
  );
  const productsReport = useSelector(
    (state) => state.applications.myShop.productsReport || []
  )
    .sort((b, a) => a.count - b.count)
    .slice(0, 6);
  const productsReportExtra = useSelector(
    (state) => state.applications.myShop.productsReportExtra
  );

  const [salesReport, setSalesReport] = useState(
    formatBarChartData([...initialData.initWeeklyData], [...weeklySalesReport])
  );

  const [openDateRange, setOpenDateRange] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [openSpeficDate, setOpenSpecificDate] = useState(false);
  const [specificDate, setSpecificDate] = useState("");

  useEffect(() => {
    mixPanel.track(MY_SHOP_HOME_INTERACTION, {
      "Icon Name": "Reports",
      Time: new Date().toLocaleString(),
    });
  }, []);

  useEffect(() => {
    if (sortType.key === "SpecificDay") {
      dispatch(
        reportActions.getSalesReportBySpecificDay(
          specificDate,
          setSalesReport,
          formatBarChartData,
          initialData
        )
      );
      dispatch(reportActions.getProductReportBySpecificDay(specificDate));
    } else if (sortType.key === "DateRange") {
      dispatch(
        reportActions.getSalesReportByDateRange(
          startDate,
          endDate,
          setSalesReport
        )
      );
      dispatch(reportActions.getProductReportByDateRange(startDate, endDate));
    } else {
      dispatch(
        reportActions.getSalesReport(
          sortType.key,
          setSalesReport,
          formatYearlyBarChartData,
          formatBarChartData,
          initialData
        )
      );
      dispatch(reportActions.getProductReport(sortType.key));
    }
  }, [dispatch, sortType, startDate, endDate, specificDate]);

  useEffect(() => {
    if (loadingSalesReport && loadingProductsReport) {
      setIsloading(true);
    } else {
      setIsloading(false);
    }
  }, [loadingProductsReport, loadingSalesReport]);

  if (isLoading) {
    return <Loader />;
  } else
    return (
      <Fragment>
        <DesktopBackgroundLayout>
          <Fragment>
            <TopHeader title={"Reports"} backLink={"/"} />
            <Container>
              <FilterAndSortSection top={"91px"}>
                <SummaryText>Summary</SummaryText>
                <SortDropdownBox
                  onClick={() =>
                    setSortOptionsOpen((currentValue) => !currentValue)
                  }
                >
                  <span>{sortType.value}</span>
                  <DropdownArrow />
                </SortDropdownBox>
              </FilterAndSortSection>
            </Container>

            <DropdownList
              title={"Sales"}
              simple={true}
              salesReportExtra={salesReportExtra}
              sortType={sortType}
              clickAction={() => {
                mixPanel.track(SALES_REPORT, {
                  Source: "Report page",
                  Time: new Date().toLocaleString(),
                });
              }}
            >
              {salesReport.map((x) => x.totalAmount).every((x) => x === 0) ? (
                <NoData />
              ) : (
                <HorizontalBarChart
                  data={salesReport}
                  type={"Sales"}
                  height={sortType.height}
                />
              )}
            </DropdownList>

            <DropdownList
              title={"Profit"}
              simple={true}
              salesReportExtra={salesReportExtra}
              sortType={sortType}
              clickAction={() => {
                mixPanel.track(PROFIT_REPORT, {
                  Source: "Report page",
                  Time: new Date().toLocaleString(),
                });
              }}
            >
              {salesReport.map((x) => x.profit).every((x) => x === 0) ? (
                <NoData />
              ) : (
                <HorizontalBarChart
                  data={salesReport}
                  height={sortType.height}
                />
              )}
            </DropdownList>
            <DropdownList
              title={"Top selling products"}
              productsReport={productsReport}
              salesReportExtra={productsReportExtra}
              clickAction={() => {
                mixPanel.track(INVENTORY_REPORT, {
                  Source: "Report page",
                  Time: new Date().toLocaleString(),
                });
              }}
            >
              {productsReport.length ? (
                <BubbleChartComponent
                  productsReport={productsReport}
                  open={true}
                />
              ) : (
                <NoData />
              )}
            </DropdownList>
          </Fragment>

          {sortOptionsOpen && (
            <OptionsPopupDialog
              open={sortOptionsOpen}
              title={"Time Period"}
              cancel={() => {
                setSortOptionsOpen(!sortOptionsOpen);
              }}
              items={[
                {
                  Icon: CalenderIcon,
                  title: "Today",
                  click: () => {
                    setSortOptionsOpen(!sortOptionsOpen);
                    if (sortType.value !== "Today") {
                      setSortType({
                        key: "DAILY",
                        value: "Today",
                        type: "day",
                        height: 250,
                      });
                    }
                  },
                },
                {
                  Icon: CalenderIcon,
                  title: "This Week",
                  click: () => {
                    setSortOptionsOpen(!sortOptionsOpen);
                    if (sortType.value !== "This Week") {
                      setSortType({
                        key: "WEEKLY",
                        value: "This Week",
                        type: "week",
                        height: 300,
                      });
                    }
                  },
                },
                {
                  Icon: CalenderIcon,
                  title: "This Month",
                  click: () => {
                    setSortOptionsOpen(!sortOptionsOpen);
                    if (sortType.value !== "This Month") {
                      setSortType({
                        key: "MONTHLY",
                        value: "This Month",
                        type: "month",
                        height: 250,
                      });
                    }
                  },
                },
                {
                  Icon: CalenderIcon,
                  title: "This Year",
                  click: () => {
                    setSortOptionsOpen(!sortOptionsOpen);
                    if (sortType.value !== "This Year") {
                      setSortType({
                        key: "YEARLY",
                        value: "This Year",
                        type: "year",
                        height: 400,
                      });
                    }
                  },
                },
                {
                  Icon: CalenderIcon,
                  title: "Specific Day",
                  click: () => {
                    setSortOptionsOpen(!sortOptionsOpen);
                    setOpenSpecificDate(!openSpeficDate);
                    // if(sortType.value !== "Specific Day"){
                    //     setSortType({key: 'SpecificDay', value: 'SpecificDay',  type: 'Specific Day', height: 250});
                    // }
                  },
                },
                {
                  Icon: CalenderIcon,
                  title: "Date Range",
                  click: () => {
                    setSortOptionsOpen(!sortOptionsOpen);
                    setOpenDateRange(!openDateRange);
                    // if(sortType.value !== "Date Range"){
                    //     setSortType({key: 'DateRange', value: 'Date Range',  type: 'Date Range', height: 400});
                    // }
                  },
                },
              ]}
            />
          )}
          {openDateRange && (
            <DateRangePopup
              open={openDateRange}
              setOpen={setOpenDateRange}
              startDate={startDate}
              setSelectedFilter={setSortType}
              filterParam={{
                key: "DateRange",
                value: "Date Range",
                type: "Date Range",
                height: "auto",
              }}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
          )}
          {openSpeficDate && (
            <SpeficDatePopup
              open={openSpeficDate}
              setOpen={setOpenSpecificDate}
              startDate={specificDate}
              setSelectedFilter={setSortType}
              filterParam={{
                key: "SpecificDay",
                value: "Specific Day",
                type: "Specific Day",
                height: 250,
              }}
              setSelectedDate={setSpecificDate}
            />
          )}
        </DesktopBackgroundLayout>
      </Fragment>
    );
};

export default withRouter(Reports);
