import React, { Fragment } from "react";

import styled from "styled-components";

import Icon404 from "../../assets/broken_link.svg";
import { RippleButton, PageLogo } from "../../components";
import { Message } from "../../containers/MessageContainer";
import {
  FlexCenteredBlock,
  ScreenContainer,
} from "../../containers/ScreenContainer";
import History from "../../utils/History";
import DesktopBackgroundLayout from "../DesktopBackgroundLayout";

const Space = styled.div`
  height: ${({ val }) => val || "10px"};
`;

const NotFoundPage = () => {
  return (
    <Fragment>
      <DesktopBackgroundLayout>
        <ScreenContainer>
          <FlexCenteredBlock top={"120px"}>
            <PageLogo
              Icon={Icon404}
              width={"184px"}
              height={"184px"}
              iconHeight={"auto"}
              iconWidth={"auto"}
              margin={"24px auto"}
            />
            <Space height={"32px"} />

            <Message
              bottom={"24px"}
              top={"8px"}
              align={"center"}
              padding={"0 1em"}
              color={"#212c3d"}
              weight={"500"}
            >
              The page you're looking for does not exist or may have changed.
            </Message>
          </FlexCenteredBlock>

          <RippleButton top={"45px"} onClick={() => History.push("/")}>
            Take me home
          </RippleButton>
        </ScreenContainer>
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default NotFoundPage;
