import moment from "moment";

export const formatCreationDate = (creationDate) => {
  const hours = moment(new Date(creationDate)).format("hh");
  const minutes = moment(new Date(creationDate)).format("mm");
  const interv = moment(new Date(creationDate)).format("A");

  let day;

  if (moment(new Date(creationDate)).isSame(new Date(), "day")) {
    day = "Today";
  } else if (
    moment(new Date(creationDate)).isSame(moment().subtract(1, "day"), "day")
  ) {
    day = "Yesterday";
  } else {
    day = moment(new Date(creationDate)).format("D MMMM");
  }

  return `
        ${day}, 
        ${hours}:${minutes} ${interv}
    `;
};

export const relativeDateTime = (creationDate) => {
  const hours = moment(new Date(creationDate)).format("hh");
  const minutes = moment(new Date(creationDate)).format("mm");
  const interv = moment(new Date(creationDate)).format("A");
  const diff = Math.abs(
    moment(new Date(creationDate)).diff(new Date(), "minute")
  );
  if (diff < 1) {
    //less than 60 seconds
    return "now";
  } else if (moment(new Date(creationDate)).isSame(new Date(), "day")) {
    //same day
    return hours + ":" + minutes + " " + interv;
  } else if (diff < 7 * 24 * 60) {
    //less than 7 days
    return moment(new Date(creationDate)).fromNow();
  } else {
    const day = moment(new Date(creationDate)).format("D/MM/YY");
    return day;
  }
};
