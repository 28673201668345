export const SortComplaint = (type, complaints, setComplaint) => {
  const newComplaintsArray = [...complaints];
  if (type === "Most Recent") {
    setComplaint(
      newComplaintsArray.sort((a, b) =>
        b.createdTime.localeCompare(a.createdTime)
      )
    );
  } else if (type === "Oldest") {
    setComplaint(
      newComplaintsArray.sort((a, b) =>
        a.createdTime.localeCompare(b.createdTime)
      )
    );
  }
};
