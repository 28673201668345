import { push } from "connected-react-router";
import { toast } from "react-toastify";

import { axios, currentAPI, walletAPI } from "../../../../../config";
import { formatPrice } from "../../../../../utils/currency/formatPriceWithComma";
import {
  SAVE_CUSTOMER,
  UPDATE_CUSTOMER_ID,
} from "../../../applications/my-shop/constants";
import { getWalletTransactions } from "../../transactions/actions";
import { addPendingRequest } from "../../../offline/actions";
import {
  SENDING_WALLET_DATA,
  USER_WALLET_SUCCESS,
  USER_WALLET_FAILED,
  SAVE_USER_DEBIT_CARD,
  DELETE_USER_DEBIT_CARD,
  SAVE_WALLET_FUND_AMOUNT,
  SAVE_WALLET_USAGE_MODE,
  UPDATE_WALLET_BALANCE,
  UPDATE_WALLET_STATUS,
  SAVE_USER_BANK_ACCOUNT,
  HIDE_WALLET_BALANCE,
  SAVE_WALLET_TRANSFER_AMOUNT,
  SAVE_WALLET_TRANSFER_DATA,
  SAVE_WALLET_MONEY_RECIPIENTS,
  TEMP_SAVE_BVN_DETAILS,
  FETCHING_WALLET_BALANCE,
  LAST_ACTION_URL,
  GET_USER_UNPAID_COMMISSION_LOADING,
  GET_USER_UNPAID_COMMISSION_SUCCESS,
  REDIRECTED_FROM_MERCHBUY,
} from "../constants";

import { updateRaveBalance } from "./rave-wallet";

export const updateWalletBalance = () => async (dispatch, getState) => {
  try {
    const updateWalletBalanceResponse = await axios.get(
      `${currentAPI}/api/wallet/balance`
    );

    if (updateWalletBalanceResponse.status === 200) {
      dispatch({
        type: UPDATE_WALLET_BALANCE,
        payload: formatPrice(updateWalletBalanceResponse.data || 0),
      });
    }
  } catch (error) {
    //
  }
};

export const updateWalletStatus = (status) => (dispatch) => {
  dispatch({ type: UPDATE_WALLET_STATUS, payload: status });
};

export const switchWalletUsageMode = (mode) => (dispatch) => {
  dispatch({ type: SAVE_WALLET_USAGE_MODE, payload: mode });
};

export const hideBalance = (status) => (dispatch) => {
  dispatch({ type: HIDE_WALLET_BALANCE, payload: status });
};

export const saveLastTransferedRecipient = (data) => (dispatch) => {
  dispatch({ type: SAVE_WALLET_MONEY_RECIPIENTS, payload: data });
};

export const saveWalletFundAmount =
  (amount, status, redirect = null) =>
  (dispatch) => {
    dispatch({
      type: SAVE_WALLET_FUND_AMOUNT,
      payload: {
        amount,
        status,
      },
    });

    if (redirect) dispatch(push(redirect));
  };

export const saveWalletTransferAmount =
  (amount, status, redirect = null) =>
  (dispatch) => {
    dispatch({
      type: SAVE_WALLET_TRANSFER_AMOUNT,
      payload: {
        amount,
        status,
      },
    });

    if (redirect) dispatch(push(redirect));
  };

export const saveWalletTransferData =
  (recipientName, message, redirect) => (dispatch) => {
    dispatch({
      type: SAVE_WALLET_TRANSFER_DATA,
      payload: {
        recipientName,
        message,
      },
    });

    if (redirect) dispatch(push(redirect));
  };

export const getUserWalletData = () => async (dispatch, getState) => {
  try {
    const userId = getState().user.userId;
    const userDataResponse = await axios.get(
      `${currentAPI}/api/users/${userId}`
    );

    if (userDataResponse.status === 200) {
      const status = userDataResponse?.data?.walletSpaces?.status;
      dispatch(updateWalletStatus(status));
    }
  } catch (error) {
    console.error(error);
  }
};

export const getUserCardData = () => async (dispatch, getState) => {
  try {
    const getUserCardDataResponse = await axios.get(
      `${currentAPI}/api/card/all`
    );

    if (getUserCardDataResponse.status === 200) {
      const savedCards = getState().account.wallet.cards;
      const cards = getUserCardDataResponse.data;

      if (savedCards.length < cards.length) {
        cards.forEach((card) => {
          dispatch({ type: SAVE_USER_DEBIT_CARD, payload: card.cardData });
        });
      }
    }
  } catch (error) {
    //
  }
};

export const getAllBankAccounts = () => async (dispatch, getState) => {
  try {
    const getUserBankAccountsResponse = await axios.get(
      `${currentAPI}/api/bankAccount/all`
    );

    if (getUserBankAccountsResponse.status === 200) {
      const savedBankAccounts = getState().account.wallet.bankAccounts;
      const bankAccounts = getUserBankAccountsResponse.data;

      if (savedBankAccounts.length < bankAccounts.length) {
        bankAccounts.forEach((bankAccount) => {
          dispatch({
            type: SAVE_USER_BANK_ACCOUNT,
            payload: {
              bankAccountId: bankAccount.id,
              bankAccountDTO: bankAccount.bankAccountData,
            },
          });
        });
      }
    }
  } catch (error) {
    //
  }
};

export const addCardInitial = (debitCard) => async (dispatch, getState) => {
  const storedCards = getState().account.wallet.cards;
  const foundCard = storedCards.find(
    (card) => card.last4digits === debitCard.cardNumber.slice(-4)
  );

  if (foundCard) {
    toast.success("You already have this card");

    return {
      suggestedAuth: null,
      valid: false,
    };
  }

  try {
    dispatch({ type: SENDING_WALLET_DATA });

    const initialCardResponse = await axios.post(
      `${currentAPI}/api/rave/cards/initial`,
      JSON.stringify(debitCard)
    );

    if (
      initialCardResponse.status === 200 &&
      initialCardResponse.data.status === "success"
    ) {
      dispatch({ type: USER_WALLET_SUCCESS });
      const suggestedAuth = initialCardResponse.data.suggestedAuth;

      if (suggestedAuth === "PIN") {
        return {
          suggestedAuth,
          valid: true,
        };
      } else if (suggestedAuth === "VBVSECURECODE") {
        return {
          suggestedAuth,
          valid: true,
        };
      }
    }
  } catch (error) {
    dispatch({
      type: USER_WALLET_FAILED,
      payload: error.response.data.message,
    });

    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
  }
};

export const verifyTransaction = (bankAccount) => async (dispatch) => {
  dispatch({ type: SENDING_WALLET_DATA });

  try {
    const txRef = localStorage.getItem("txRef");

    const verifyUrl = bankAccount
      ? `${currentAPI}/api/rave/bankAccounts/verify`
      : `${currentAPI}/api/rave/cards/verify`;

    const verifyTransactionResponse = await axios.post(
      verifyUrl,
      JSON.stringify({ txRef })
    );

    if (verifyTransactionResponse.status === 200) {
      dispatch({ type: USER_WALLET_SUCCESS });
      return verifyTransactionResponse.data;
    }
  } catch (error) {
    dispatch({
      type: USER_WALLET_FAILED,
      payload: error.response.data.message,
    });

    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
  }
};

export const topUpBalance = (amount) => async () => {
  try {
    const topUpBalanceResponse = await axios.post(
      `${currentAPI}/api/transfers/walletTopUpByUserName?amount=${amount}`,
      null
    );

    if (topUpBalanceResponse.status === 200) {
      return topUpBalanceResponse.data;
    }
  } catch (error) {
    //
  }
};

export const chargeWallet = (amount, embedToken) => async (dispatch) => {
  dispatch({ type: SENDING_WALLET_DATA });

  try {
    const payWithTokenResponse = await axios.post(
      `${currentAPI}/api/rave/cards/tokenizedPayment`,
      JSON.stringify({
        amount,
        embeddedToken: embedToken,
      })
    );

    if (
      payWithTokenResponse.status === 200 &&
      payWithTokenResponse.data.status === "success"
    ) {
      localStorage.setItem("txRef", payWithTokenResponse.data.txRef);

      const { status } = await dispatch(verifyTransaction());

      if (status === "success") {
        await dispatch(updateRaveBalance());
        localStorage.removeItem("txRef");
        dispatch(saveWalletFundAmount(amount, "success"));
      }
    }
  } catch (error) {
    dispatch({
      type: USER_WALLET_FAILED,
      payload: error.message,
    });

    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
  }
};

export const saveUserDebitCard = (cardData) => async (dispatch) => {
  dispatch({ type: SENDING_WALLET_DATA });

  try {
    const card = {
      last4digits: cardData.cardNumber.slice(-4),
      embedToken: cardData.embedToken,
      cardBrand: cardData.cardBrand,
    };

    const saveUserDebitCardResponse = await axios.put(
      `${currentAPI}/api/card/`,
      JSON.stringify(card)
    );

    if (saveUserDebitCardResponse.status === 200) {
      dispatch({ type: USER_WALLET_SUCCESS });
      dispatch({ type: SAVE_USER_DEBIT_CARD, payload: card });
      dispatch(push("/user/wallet_cards_all"));
      toast.success("Your bank card was successfully added");
    }
  } catch (error) {
    dispatch({
      type: USER_WALLET_FAILED,
      payload: error.response.data.message,
    });

    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
  }
};

export const deleteUserDebitCard = (index) => async (dispatch, getState) => {
  dispatch({ type: SENDING_WALLET_DATA });

  try {
    const cardToken = getState().account.wallet.cards[index].embedToken;

    const deleteUserDebitCardResponse = await axios.delete(
      `${currentAPI}/api/card/${cardToken}`
    );

    if (deleteUserDebitCardResponse.status === 200) {
      dispatch({ type: USER_WALLET_SUCCESS });
      dispatch({ type: DELETE_USER_DEBIT_CARD, payload: index });
    }
  } catch (error) {
    dispatch({
      type: USER_WALLET_FAILED,
      payload: error.response.data.message,
    });

    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
  }
};

export const sendMoneyFromWalletToWallet =
  (recipientName, amount) => async (dispatch) => {
    dispatch({ type: SENDING_WALLET_DATA });

    try {
      const payWithTokenResponse = await axios.post(
        `${currentAPI}/api/transfersRave/walletToWallet`,
        JSON.stringify({
          amount,
          recipientName,
        })
      );

      if (payWithTokenResponse.status === 200) {
        await dispatch(updateRaveBalance());
        dispatch(saveWalletTransferAmount(amount, "success", "/"));
        toast.success("Your transfer was successfully complete");
      }
    } catch (error) {
      dispatch({
        type: USER_WALLET_FAILED,
        payload: error.message,
      });

      if (error.response && error.response.status === 400) {
        const { data } = (error && error.response) || {};
        data.message && toast.error(error.response.data.message);
      }
    }
  };

export const activateWallet = () => async () => {
  try {
    const activateWalletResponse = await axios.post(
      `${walletAPI}/api/wallet/activate`,
      null
    );

    if (activateWalletResponse.status === 200) {
      return true;
    }
  } catch (error) {
    toast.error("wallet cannot be funded at this time, please try again!");
  }
};

export const getWalletActivationStatus = () => async (dispatch) => {
  if (!navigator.onLine) return;

  try {
    const walletStatus = await axios.get(
      `${walletAPI}/api/wallet/v2/walletStatus`
    );

    if (walletStatus.status === 200) {
      dispatch({
        type: UPDATE_WALLET_STATUS,
        payload: walletStatus?.data?.status || "INACTIVE",
      });
    }
  } catch (error) {
    //
  }
};

export const getWalletBalance = () => async (dispatch) => {
  if (!navigator.onLine) return;

  try {
    const walletBalanceResponse = await axios.get(
      `${walletAPI}/api/wallet/v2/balance`
    );

    if (walletBalanceResponse.status === 200) {
      dispatch({
        type: UPDATE_WALLET_BALANCE,
        payload: {
          balance: walletBalanceResponse?.data?.seerBitBalance || 0,
        },
      });
    }
  } catch (error) {
    //
  }
};

export const generatePaymentLink = (payload) => async () => {
  try {
    const response = await axios.post(
      `${currentAPI}/api/paymentLink/generate`,
      payload
    );
    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      toast.error(error.response.data.message);
    }
  }
};

export const getPublicPaymentLinkDetails = (reference) => async () => {
  try {
    const response = await axios.get(
      `${currentAPI}/api/paymentLink/public/reference/${reference}`
    );
    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (ex) {
    return null;
  }
};

export const confirmPaymentLink = (payload) => async () => {
  try {
    const response = await axios.post(
      `${currentAPI}/api/paymentLink/public/complete/transaction`,
      JSON.stringify(payload)
    );
    if (response.status === 200) {
      if (response.data.paymentStatus === "SUCCESSFUL") {
        return response.data;
      }
      return false;
    }
    return false;
  } catch (error) {
    console.error(error.message);
    return false;
  }
};

export const getWalletBalanceAsync = () => async (dispatch) => {
  if (!navigator.onLine) return;

  dispatch({ type: FETCHING_WALLET_BALANCE, payload: true });

  try {
    const walletBalanceResponse = await axios.get(
      `${walletAPI}/api/wallet/v2/balanceSync`
    );

    dispatch({ type: FETCHING_WALLET_BALANCE, payload: false });
    if (walletBalanceResponse.status === 200) {
      dispatch({
        type: UPDATE_WALLET_BALANCE,
        payload: {
          balance: walletBalanceResponse?.data?.seerBitBalance || 0,
        },
      });
    }
  } catch (error) {
    dispatch({ type: FETCHING_WALLET_BALANCE, payload: false });
  }
};

export const initiateTopUp = (params) => async () => {
  try {
    const response = await axios.post(
      `${currentAPI}/api/wallet/v2/initiateTopUp`,
      JSON.stringify(params)
    );
    if (response.status === 200) {
      return {
        status: true,
        message: "",
        data: response.data,
      };
    }
  } catch (error) {
    console.error(error.message);
    return {
      status: false,
      message: error.message,
      data: null,
    };
  }
};

export const postTopUpTransaction = (params) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${currentAPI}/api/wallet/v2/completeTopUpTransaction`,
      JSON.stringify(params)
    );
    if (response.status === 200) {
      if (response.data.transactionStatus === "SUCCESSFUL") {
        return response.data.internalReference;
      }
      dispatch(getWalletTransactions());
      return false;
    }
    return false;
  } catch (error) {
    console.error(error.message);
    return false;
  }
};

export const verifyWalletPayment = (transactionRef) => async (dispatch) => {
  try {
    const verifyResponse = await axios.get(
      `${walletAPI}/api/payment/paystack/topup/verify/${transactionRef}`
    );

    if (verifyResponse.status === 200) {
      if (verifyResponse.data.status === "COMPLETE") {
        dispatch(push("/"));
      }
    }
  } catch (error) {
    console.error(error);
  }
};

export const WalletToWalletTxn = (params) => async (dispatch) => {
  dispatch({ type: SENDING_WALLET_DATA });
  const data = {
    amount: Number(params.amount) * 100,
    name: "0" + String(params.phoneNumber).slice(-10),
    narration: params.narration,
  };
  try {
    const WalletToWalletTransferResponse = await axios.post(
      `${walletAPI}/api/wallet/transfer/wallet_to_wallet`,
      JSON.stringify(data)
    );

    if (WalletToWalletTransferResponse.status === 200) {
      dispatch({ type: USER_WALLET_SUCCESS });
      // if(WalletToWalletTransferResponse.data.status === "success") {
      //     toast.success(`${formatPrice(params.amount || 0)} has been transfered successfully!`);

      //     dispatch(push('/'))
      // }
      return true;
    } else {
      dispatch({ type: USER_WALLET_FAILED });
      return false;
    }
  } catch (error) {
    dispatch({ type: USER_WALLET_FAILED });
    if (error.response && error.response.status === 400) {
      return false;
    }
  }
};

export const checkIfSpacesUser = (value) => async () => {
  if (!navigator.onLine) return;
  try {
    const response = await axios.post(
      `${currentAPI}/api/users/namecheck/`,
      JSON.stringify({ phoneNumber: value })
    );

    if (response.status === 200) {
      return response.data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getAccountDetaials = (accountNumber, bankCode) => async () => {
  if (!navigator.onLine) return;
  try {
    const response = await axios.get(
      `${currentAPI}/api/paystack/bank/resolve?account_number=${accountNumber}&bank_code=${bankCode}`
    );

    if (response.status === 200) {
      return response.data.data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const WalletToBankTransferOld = (params) => async (dispatch) => {
  dispatch({ type: SENDING_WALLET_DATA });
  const data = {
    amount: Number(params.amount) * 100,
    narration: params.narration,
    accountName: params.account_name,
    accountNumber: params.accountNumber,
    bankCode: params.bankCode,
    password: params.password,
  };
  try {
    const WalletToWalletTransferResponse = await axios.post(
      `${currentAPI}/api/walletService/transfer/wallet_to_bank`,
      JSON.stringify(data)
    );

    if (WalletToWalletTransferResponse.status === 200) {
      dispatch({ type: USER_WALLET_SUCCESS });
      return true;
    } else {
      dispatch({ type: USER_WALLET_FAILED });
      return false;
    }
  } catch (error) {
    dispatch({ type: USER_WALLET_FAILED });
    if (error.response && error.response.status === 400) {
      return false;
    }
  }
};

export const checkKYCV2 = (payload, callback) => async (dispatch, getState) => {
  dispatch({ type: SENDING_WALLET_DATA });
  try {
    const response = await axios.post(
      `${currentAPI}/api/wallet/checkKYCV2`,
      JSON.stringify(payload)
    );

    if (response.status === 200 || response.status === 208) {
      dispatch({ type: USER_WALLET_SUCCESS });
      return true;
    }

    return false;
  } catch (error) {
    dispatch({ type: USER_WALLET_FAILED, payload: "" });
    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};

      if (data.error) {
        const errorMsg = error.response.data.error;
        if (errorMsg === "ERROR_INVALID_BANK_DETAILS") {
          dispatch(
            saveTempBvnDetails({ ...payload, verifyBankError: errorMsg })
          );
        } else if (errorMsg === "ERROR_ON_3D_PARTY_APP_INTEGRATION") {
          dispatch(saveTempBvnDetails({ ...payload, bvnError: "Invalid BVN" }));
          callback();
        } else if (errorMsg === "ERROR_BVN_ALREADY_USED") {
          dispatch(
            saveTempBvnDetails({
              ...payload,
              bvnError: "BVN belongs to another User",
            })
          );
          callback();
        } else if (errorMsg === "FIRST_NAME_ERROR") {
          dispatch(
            saveTempBvnDetails({ ...payload, verifyFirstNameError: errorMsg })
          );
          callback();
        } else if (errorMsg === "LAST_NAME_ERROR") {
          dispatch(
            saveTempBvnDetails({ ...payload, verifyLastNameError: errorMsg })
          );
          callback();
        }
      }
    }
    return false;
  }
};

export const createTransactionPin = (payload) => async (dispatch, getState) => {
  dispatch({ type: SENDING_WALLET_DATA });
  try {
    const response = await axios.post(
      `${currentAPI}/api/users/createTransactionPin`,
      JSON.stringify(payload)
    );

    if (response.status === 200 || response.status === 208) {
      dispatch({ type: USER_WALLET_SUCCESS });
      return true;
    }

    return false;
  } catch (error) {
    dispatch({ type: USER_WALLET_FAILED, payload: "" });
    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
    return false;
  }
};

export const getSpacesUserName = (value) => async () => {
  if (!navigator.onLine) return;

  try {
    const response = await axios.post(
      `${currentAPI}/api/users/namecheck/`,
      JSON.stringify({ phoneNumber: value })
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return "";
  }
};

export const verifyPin = (pin) => async (dispatch) => {
  dispatch({ type: SENDING_WALLET_DATA });

  try {
    const response = await axios.post(
      `${currentAPI}/api/users/verifyTransactionPin`,
      { transactionPin: pin }
    );

    dispatch({ type: USER_WALLET_SUCCESS });
    if (response.status === 200) {
      const { verifiedTransactionPin } = response.data;
      return verifiedTransactionPin;
    }
  } catch (error) {
    dispatch({ type: USER_WALLET_FAILED, payload: "" });
    if (error.response && error.response.status === 404) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.error);
    }
  }
};

export const walletToWalletTransfer = (payload) => async (dispatch) => {
  dispatch({ type: SENDING_WALLET_DATA });

  try {
    const response = await axios.post(
      `${currentAPI}/api/wallet/v2/walletToWalletTransfer`,
      payload
    );

    dispatch({ type: USER_WALLET_SUCCESS });
    if (response.status === 200) {
      dispatch(getWalletTransactions());
      return response.data;
    }
  } catch (error) {
    dispatch({ type: USER_WALLET_FAILED, payload: "" });
    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
  }
};

export const activateWalletV2 = (payload) => async (dispatch) => {
  dispatch({ type: SENDING_WALLET_DATA });
  try {
    const response = await axios.put(
      `${currentAPI}/api/wallet/activate/wallet/`,
      JSON.stringify(payload)
    );

    dispatch({ type: USER_WALLET_SUCCESS });
    if (response.status === 200) {
      dispatch(saveTempBvnDetails({}));
      return true;
    }

    return false;
  } catch (error) {
    dispatch({ type: USER_WALLET_FAILED, payload: "" });
    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
    return false;
  }
};

export const verifyAccountNumber = (accountNumber, bankCode) => async () => {
  try {
    const response = await axios.get(
      `${currentAPI}/api/paystack/bank/resolve?account_number=${accountNumber}&bank_code=${bankCode}`
    );
    if (response.status === 200) {
      return response.data.data?.account_name || "";
    }
  } catch (error) {
    if (error.response && error.response.status === 422) {
      return "";
    }

    return "";
  }
};

export const walletToBankTransfer = (payload) => async (dispatch) => {
  dispatch({ type: SENDING_WALLET_DATA });

  try {
    const response = await axios.post(
      `${currentAPI}/api/wallet/v2/walletBankTransfer`,
      payload
    );

    dispatch({ type: USER_WALLET_SUCCESS });
    if (response.status === 200) {
      dispatch(getWalletTransactions());
      return response.data;
    }
  } catch (error) {
    dispatch({ type: USER_WALLET_FAILED, payload: "" });
    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
  }
};

export const addCustomer = (customerInfo) => async (dispatch) => {
  try {
    const addCustomerResponse = await axios.post(
      `${currentAPI}/api/customers/`,
      JSON.stringify(customerInfo)
    );

    if (addCustomerResponse.status === 200) {
      dispatch({ type: SAVE_CUSTOMER, payload: addCustomerResponse.data });
    }
  } catch (error) {
    if (error.message === "Network Error") {
      dispatch(
        addPendingRequest(
          "POST",
          `${currentAPI}/api/customers/`,
          customerInfo,
          [UPDATE_CUSTOMER_ID]
        )
      );

      dispatch({ type: SAVE_CUSTOMER, payload: customerInfo });
    } else {
      console.error(error);
    }
  }
};

export const saveTempBvnDetails = (details) => (dispatch) => {
  dispatch({ type: TEMP_SAVE_BVN_DETAILS, payload: details });
};

export const saveLastActionUrl = (url) => (dispatch) => {
  dispatch({ type: LAST_ACTION_URL, payload: url });
};

export const redirectedFromMerchbuy =
  (redirected = false) =>
  (dispatch) => {
    dispatch({ type: REDIRECTED_FROM_MERCHBUY, payload: redirected });
  };

export const getUserUnpaidCommission = () => async (dispatch) => {
  dispatch({ type: GET_USER_UNPAID_COMMISSION_LOADING, payload: true });

  try {
    const response = await axios.get(
      `${currentAPI}/api/userCommissions/unpaid`
    );
    if (response.status === 200) {
      dispatch({
        type: GET_USER_UNPAID_COMMISSION_SUCCESS,
        payload: response?.data,
      });
    }
  } catch {
    // we really just need this to fail silently
    return "";
  } finally {
    dispatch({ type: GET_USER_UNPAID_COMMISSION_LOADING, payload: false });
  }
};
