import React, { Fragment, useState, useEffect } from "react";

import { Form, Formik } from "formik";
import { useSelector } from "react-redux";
import styled from "styled-components";

import {
  TopHeader,
  AmountInputWithLabel,
  RippleButton,
} from "../../../../components";
import { SearchInput } from "../../../../components/forms/input/search-input/SearchInput";
import { BlurBackground } from "../../../../containers/BlurBackground";
import { ScreenContainer } from "../../../../containers/ScreenContainer";
import { colors } from "../../../../styles";
import { formatPrice } from "../../../../utils/currency/formatPriceWithComma";
import { ReactComponent as Bulb } from "../assets/bulb.svg";
import NoOrderIcon from "../assets/no_orders.svg";
import {
  OrderText,
  OrderNumber,
  NoRemittanceWrapper,
  NoRemittanceLogo,
  ContainerDiv,
  Text,
  Backdrop,
  ModalContainer,
} from "../styles";

const Border = styled.div`
  height: ${({ height }) => height || "8px"};
  margin: 0 -1em;
  background-color: ${colors.lightestGray};
`;

const OrderInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin: 0 -1em;
  align-items: start;
  background-color: ${colors.white};
`;

export const OrdersList = ({ setOpen, setSelectedOrders, selectedOrders }) => {
  const unpaidOrders = useSelector(
    (state) => state.applications.remittance.unpaidOrders
  );
  const [openModalPopUp, setOpenModalPopUp] = useState(false);
  const [clickedOrder, setClickedOrder] = useState({});

  const [options, setOptions] = useState([]);
  const [filterUnpaidOrders, setFilterUnpaidOrders] = useState("");
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    setOptions(
      unpaidOrders.filter(
        (x) => !selectedOrders.some((y) => y.orderNumber === x.orderNumber)
      ) || []
    );
  }, [unpaidOrders, selectedOrders]);

  useEffect(() => {
    if (searchValue) {
      setFilterUnpaidOrders(
        options.filter((x) => String(x.orderNumber).includes(searchValue))
      );
    } else {
      setFilterUnpaidOrders(options);
    }
  }, [searchValue, options]);

  const convertToPositive = (amount) => {
    if (amount < 0) {
      return amount * -1;
    }
    return amount;
  };

  const onSubmit = (values, { setErrors }) => {
    if (
      Number(values.amount) >
      Number(convertToPositive(clickedOrder.outstandingAmount))
    ) {
      return setErrors({
        amount: "Paid amount is greater than outstanding amount",
      });
    }
    setSelectedOrders([
      ...selectedOrders,
      { ...clickedOrder, amount: values.amount },
    ]);
    setOpen(false);
    setOpenModalPopUp(false);
  };

  return (
    <Fragment>
      {!openModalPopUp && (
        <TopHeader
          title="Orders"
          size="14px"
          withCancelIcon
          noBorderBottom
          backLink="#"
          backAction={() => setOpen(false)}
        />
      )}
      {openModalPopUp && (
        <BlurBackground position="fixed">
          <Backdrop onClick={() => setOpenModalPopUp(false)} />
          <ModalContainer>
            <Text align="start" size="14px" color={"#353645"}>
              How much are you paying for this order?
            </Text>
            <OrderNumber margin="19px 0 4px 0">{`Order No. ${
              clickedOrder.orderNumber
            } - ${clickedOrder.customer?.name || ""}`}</OrderNumber>
            <ContainerDiv bottom="24px">
              <Text right="4px">Balance left</Text>
              <Text color={colors.darkRed}>
                {formatPrice(
                  convertToPositive(clickedOrder.outstandingAmount) || 0
                )}
              </Text>
            </ContainerDiv>

            <Formik
              initialValues={{
                amount: "",
              }}
              onSubmit={onSubmit}
            >
              {({ initialValues }) => {
                return (
                  <Form>
                    <AmountInputWithLabel
                      label="Amount"
                      placeholder={"Amount"}
                      name="amount"
                      autocomplete={"off"}
                      initialValues={initialValues}
                      allowDecimal
                    />
                    <RippleButton
                      width="100%"
                      top="32px"
                      type="submit"
                      bottom="32px"
                    >
                      Add Order
                    </RippleButton>
                  </Form>
                );
              }}
            </Formik>
          </ModalContainer>
        </BlurBackground>
      )}
      <ScreenContainer>
        {!options || !options.length ? (
          <NoRemittanceWrapper>
            <NoRemittanceLogo icon={NoOrderIcon} />
            <Text top="18px" size="14px" color={colors.boldDark}>
              You don’t have any orders
            </Text>
            <Bulb width={"100%"} />
            <Text weight="400" top="8px" lineHeight="18px">
              Orders with unpaid balances will show up here
            </Text>
          </NoRemittanceWrapper>
        ) : (
          <>
            <OrderText top="24px">
              Select an order you want to pay for from the list below
            </OrderText>
            <SearchInput
              top="16px"
              bottom="16px"
              placeholder="Search for an order"
              onChange={(e) => setSearchValue(e.target.value)}
            />

            <Border />
            {filterUnpaidOrders.map((item, index) => (
              <div key={index}>
                <OrderInfo
                  onClick={() => {
                    setClickedOrder(item);
                    setOpenModalPopUp(!openModalPopUp);
                  }}
                >
                  <OrderNumber margin="0 0 4px 0">{`Order No. ${
                    item.orderNumber
                  } - ${item.customer?.name || ""}`}</OrderNumber>
                  <ContainerDiv>
                    <Text right="4px">Balance left</Text>
                    <Text color={colors.darkRed}>
                      {formatPrice(
                        convertToPositive(item.outstandingAmount) || 0
                      )}
                    </Text>
                  </ContainerDiv>
                </OrderInfo>
                <Border height="4px" />
              </div>
            ))}
          </>
        )}
      </ScreenContainer>
    </Fragment>
  );
};
