export const LOADING_FAQ = "help/loading_faq";
export const LOADING_FAQ_BY_CATEGORY = "help/loading_faq_by_category";
export const LOADING_COMPLAINTS_CATEGORIES =
  "help/loading_complaints_categories";
export const LOADING_ALL_COMPLAINTS = "help/loading_all_complaints";
export const LOADING_SUPPORT_CONTACT = "help/loading_support_contact";
export const LOADING_COMMENTS = "help/loading_comments";
export const LOADING_COMPLAINT = "help/loading_complaint";
export const LOADING_CONTACT = "help/loading_contact";
export const LOADING = "help/loading";
