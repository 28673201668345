export const filterProducts = (type, products, setProducts) => {
  if (type === "Filter") {
    setProducts([...products]);
  } else if (type === "N0 to N5,000") {
    setProducts(
      [...products]?.filter(
        (data) => data.minPrice >= 0 && data.maxPrice <= 5000
      )
    );
  } else if (type === "N5,000 to N50,000") {
    setProducts(
      [...products]?.filter(
        (data) => data.minPrice >= 5000 && data.maxPrice <= 50000
      )
    );
  } else if (type === "N50,000 to N100,000") {
    setProducts(
      [...products]?.filter(
        (data) => data.minPrice >= 50000 && data.maxPrice <= 100000
      )
    );
  } else if (type === "N100,000 to N500,000") {
    setProducts(
      [...products]?.filter(
        (data) => data.minPrice >= 100000 && data.maxPrice <= 500000
      )
    );
  } else if (type === "N500,000 and Above") {
    setProducts([...products]?.filter((data) => data.minPrice >= 500000));
  }
};
