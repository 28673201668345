import React, { Fragment } from "react";

import { bool, func, string, arrayOf, shape, any } from "prop-types";
import styled from "styled-components";

import { RippleLink } from "../..";
import { Overlay } from "../../../containers/OverlayContainer";
import {
  PopUp,
  PopUpContent,
  PopUpHeader,
  CancelButton,
  Radio,
  OptionList,
  LabelItem,
  LabelText,
  TextBlock,
  AdditionalText,
} from "../common";

const PopupWrapper = styled.div`
  width: 100%;
`;

export const CheckPopupDialog = ({
  open,
  title,
  items,
  cancel,
  color,
  size,
  headerPadding,
  popupPadding,
  width,
  contentPadding,
  labelItemPadding,
  noBorderBottom,
  buttonColor,
  buttonWeight,
  buttonHeight,
}) => {
  return (
    <Fragment>
      {open && (
        <Overlay bgc={"rgba(0, 0, 0, 0.4)"} zIndex={"99999"} onClick={cancel} />
      )}
      <PopUp open={open} zIndex={"100000"} padding={popupPadding}>
        <PopupWrapper>
          <PopUpContent radius="8px" width={width} padding={contentPadding}>
            <PopUpHeader
              color={color}
              padding={headerPadding}
              size={size}
              noBorderBottom={noBorderBottom}
            >
              {title}
            </PopUpHeader>
            <OptionList>
              {items.map(
                (
                  { Icon, title, subTitle, click, defaultChecked, link },
                  index
                ) => {
                  if (link) {
                    return (
                      <RippleLink to={link} key={index}>
                        <LabelItem onClick={click} padding={labelItemPadding}>
                          {Icon && <Icon />}
                          <TextBlock>
                            <LabelText>{title}</LabelText>
                            {subTitle && (
                              <AdditionalText>{subTitle}</AdditionalText>
                            )}
                          </TextBlock>
                        </LabelItem>
                      </RippleLink>
                    );
                  } else
                    return (
                      <LabelItem
                        padding={labelItemPadding}
                        key={index}
                        onClick={click}
                      >
                        {Icon && <Icon />}
                        <TextBlock>
                          <LabelText>{title}</LabelText>
                          {subTitle && (
                            <AdditionalText>{subTitle}</AdditionalText>
                          )}
                        </TextBlock>
                        <Radio
                          top={"4px"}
                          right={"4px"}
                          name={"filter"}
                          type={"radio"}
                          defaultChecked={defaultChecked}
                        />
                      </LabelItem>
                    );
                }
              )}
            </OptionList>
          </PopUpContent>
          <CancelButton
            color={buttonColor}
            top="16px"
            type="button"
            onClick={cancel}
            weight={buttonWeight}
            height={buttonHeight}
          >
            Cancel
          </CancelButton>
        </PopupWrapper>
      </PopUp>
    </Fragment>
  );
};

CheckPopupDialog.propTypes = {
  open: bool,
  cancel: func,
  title: string,
  items: arrayOf(
    shape({
      Icon: any,
      title: string,
      subTitle: string,
      click: func,
      defaultChecked: bool,
    })
  ),
};
