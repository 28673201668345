import React, { useState } from "react";

import { useLocation } from "react-router-dom";

import { TopHeader } from "../../../../components";
import { List } from "../../../../containers/ListContainer";
import { StatusText } from "../../../../containers/MessageContainer";
import { ScreenContainer } from "../../../../containers/ScreenContainer";
import { formatPrice } from "../../../../utils/currency/formatPriceWithComma";
import { formatCreationDate } from "../../../../utils/date/formatCreationDate";
import DesktopBackgroundLayout from "../../../DesktopBackgroundLayout";
import TransactionCard from "../../../home/transactions/transaction-card";
import {
  CompliantText,
  StatusWrapper,
  TransactionCategory,
  TransactionListItem,
  TransactionDetail,
} from "../../../home/transactions/transaction-details";
import { ReactComponent as PendingIcon } from "../assets/pending.svg";
import { ReactComponent as SuccessIcon } from "../assets/success.svg";
import SharePaymentLink from "../create-link/share-payment-link";

const PaymentLinkDetails = ({
  transactionInfo,
  backAction,
  link,
  showComplaintBtn = true,
  children,
}) => {
  let location = useLocation();
  const [transaction] = useState(transactionInfo || location.state);

  const onBackClick = () => {
    if (backAction) {
      backAction();
    }
  };

  return (
    <DesktopBackgroundLayout id="receipt">
      <TopHeader
        backAction={onBackClick}
        backLink={link || "/actions/payment-links/transactions"}
        noBorderBottom
        title={`${showComplaintBtn ? "Details" : ""}`}
      >
        {showComplaintBtn && <CompliantText>Log complaint</CompliantText>}
      </TopHeader>
      <ScreenContainer>
        <TransactionCard
          transaction={transaction}
          padding={"0px"}
          pathname={"/actions/payment-links/details"}
          containerWidth={"90%"}
        />
        <List fullScreen noBorderBottom>
          <TransactionListItem>
            <TransactionCategory>Status</TransactionCategory>
            <StatusWrapper>
              {transaction.status === "PENDING" && <PendingIcon />}
              {transaction.status !== "PENDING" && <SuccessIcon />}
              <StatusText type={transaction.status}>
                {transaction.status.toLowerCase()}
              </StatusText>
            </StatusWrapper>
          </TransactionListItem>
          {transaction?.requestedOn && (
            <TransactionListItem>
              <TransactionCategory>Requested on</TransactionCategory>
              <TransactionDetail>
                {transaction?.requestedOn &&
                  formatCreationDate(transaction?.requestedOn)}
              </TransactionDetail>
            </TransactionListItem>
          )}
          {transaction?.createdAt && (
            <TransactionListItem>
              <TransactionCategory>Created on</TransactionCategory>
              <TransactionDetail>
                {transaction.createdAt &&
                  formatCreationDate(transaction.createdAt)}
              </TransactionDetail>
            </TransactionListItem>
          )}
          {transaction.requestedBy && (
            <TransactionListItem>
              <TransactionCategory>Requested by</TransactionCategory>
              <TransactionDetail>{transaction?.requestedBy}</TransactionDetail>
            </TransactionListItem>
          )}
          {transaction.paymentDate && (
            <TransactionListItem>
              <TransactionCategory>Paid on</TransactionCategory>
              <TransactionDetail>
                {formatCreationDate(transaction?.paymentDate)}
              </TransactionDetail>
            </TransactionListItem>
          )}
          {transaction.transactionAmount && (
            <TransactionListItem>
              <TransactionCategory>Amount</TransactionCategory>
              <TransactionDetail>
                {formatPrice(transaction.transactionAmount)}
              </TransactionDetail>
            </TransactionListItem>
          )}
          {transaction?.reference && (
            <TransactionListItem>
              <TransactionCategory>Transaction reference</TransactionCategory>
              <TransactionDetail>{transaction?.reference}</TransactionDetail>
            </TransactionListItem>
          )}
          {transaction.narration && (
            <TransactionListItem>
              <TransactionCategory>description</TransactionCategory>
              <TransactionDetail>{transaction?.narration}</TransactionDetail>
            </TransactionListItem>
          )}
          {transaction?.firstName && (
            <TransactionListItem>
              <TransactionCategory>Customer Name</TransactionCategory>
              <TransactionDetail>{`${transaction.firstName} ${transaction.lastName}`}</TransactionDetail>
            </TransactionListItem>
          )}
          {transaction.description && (
            <TransactionListItem>
              <TransactionCategory>Narration</TransactionCategory>
              <TransactionDetail>{transaction.description}</TransactionDetail>
            </TransactionListItem>
          )}
          {transaction.paymentLink && (
            <TransactionListItem>
              <TransactionCategory>Link Created</TransactionCategory>
            </TransactionListItem>
          )}
        </List>
        {transaction.paymentLink && (
          <SharePaymentLink
            bottom="0px"
            paymentLink={transaction.paymentLink}
            status={transaction.status}
          />
        )}
        {transaction.paymentLink && transaction.status !== "PENDING" && (
          <TransactionCategory>Link Closed</TransactionCategory>
        )}
      </ScreenContainer>
      {children}
    </DesktopBackgroundLayout>
  );
};

export default PaymentLinkDetails;
