import React, { Fragment, useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as ForwardArrowIcon } from "../../../../assets/arrow.svg";
import TeamIcon from "../../../../assets/team.svg";
import {
  TopHeader,
  UserAvatar,
  // RippleLink,
  PageLogo,
} from "../../../../components";
import {
  ListHeading,
  ListSubHeading,
} from "../../../../containers/ListContainer";
import { Label, SecondaryText } from "../../../../containers/MessageContainer";
import {
  ScreenContainer,
  FlexCenteredBlock,
} from "../../../../containers/ScreenContainer";
import {
  agentLastSeen,
  getRejectedReasons,
} from "../../../../redux/ducks/applications/agents/actions";
import { getUserLastActiveState } from "../../../../redux/ducks/user/actions";
import { colors } from "../../../../styles";
// import { Badge } from '../../../../containers/BadgeContainer';
import DesktopBackgroundLayout from "../../../DesktopBackgroundLayout";

import PhoneIcon from "./assets/phone.svg";
import SMSIcon from "./assets/sms.svg";
import WhatsAppIcon from "./assets/whatsapp.svg";

const ReferralName = styled(ListHeading)`
  margin: 1em 0 4px 0;
  font-size: 16px;
  color: #071827;
  line-height: 24px;
`;

const ReferralPhoneNumber = styled(SecondaryText)`
  margin-top: 4px;
  color: #718596;
  font-weight: 400;
  line-height: 18px;
`;

// const CommisionsLink = styled(RippleLink)`
//     position: absolute;
//     top: 28px;
//     right: 24px;
// `;

const ContactAgentBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 24px;
  justify-content: center;

  & > div {
    margin-right: 60px;
    margin-bottom: 8px;

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

// const BadgeHeading = styled.h5`
//     position: absolute;
//     top: 16px;
//     left: 16px;
//     font-size: 12px;
//     font-weight: 100;
//     line-height: 15px;
//     color: ${colors.themeTextColor3};
// `;

// const BadgeCommisionsAmount = styled(ListHeading)`
//     position: absolute;
//     top: 35px;
//     left: 16px;
//     font-weight: bold;
//     margin: 4px 0 0 0;
// `;

const ContactOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

const ContactText = styled(ReferralPhoneNumber)``;

const ContactLink = styled.a`
  text-align: center;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > span {
    max-width: 67px;
    max-height: 26px;
    text-align: center;
    margin-top: 8px;
  }
`;

const ParticipatingTeams = styled.ul``;

const TeamsHeader = styled(ListHeading)`
  margin: 24px 0;
`;

const ListedTeam = styled.li`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 8px 16px;
  max-height: 48px;
  border-radius: 13px;
  border: 1px solid ${colors.gray3};
  margin-bottom: 8px;

  & > div {
    margin: 0;
  }
`;

const TeamName = styled(Label)`
  margin: auto;
  line-height: 15px;
  font-size: 12px;
  font-weight: 500;
  margin-left: 16px;
`;

const TeamLink = styled(Link)`
  margin-top: 8px;
`;

const AgentDetails = () => {
  let location = useLocation();
  const dispatch = useDispatch();
  const teams = useSelector((state) => state.applications.agents.teams);
  const referral = location.state;
  // const commissionsList = referral.commissionsList;
  const [rejectionReason, setRejectionReason] = useState([]);

  useEffect(() => {
    dispatch(getUserLastActiveState(referral.id, agentLastSeen));
  }, [dispatch, referral]);

  useEffect(() => {
    if (referral.status === "rejected") {
      dispatch(getRejectedReasons(referral.id, setRejectionReason));
    }
  }, [referral, dispatch]);

  const teamsParticipant = [];
  teams.forEach((team) => {
    team.members.forEach((member) => {
      if (member.id === referral.id) {
        teamsParticipant.push(team);
      } else return;
    });
  });

  return (
    <Fragment>
      <DesktopBackgroundLayout>
        <TopHeader
          title={"Agent Details"}
          size="14px"
          weight="400"
          noBorderBottom
          color="#071827"
        />
        <ScreenContainer>
          <FlexCenteredBlock top={"64px"} mtop="40px">
            <UserAvatar
              width={"72px"}
              height={"72px"}
              avatar={referral && referral.avatar}
            />
            <ReferralName>
              {referral && referral.firstName} {referral && referral.lastName}
            </ReferralName>
            <ReferralPhoneNumber>
              {referral && referral.msisdn.replace(/^.{1,4}/, 0)}
            </ReferralPhoneNumber>
            {rejectionReason && rejectionReason.length > 0 && (
              <SecondaryText style={{ fontWeight: "500px" }}>
                Reason(s) {referral && referral.firstName} application was
                Rejected
              </SecondaryText>
            )}
            {rejectionReason &&
              rejectionReason.length > 0 &&
              rejectionReason.map((reason, index) => (
                <ListSubHeading style={{ fontSize: "10px" }} key={index}>
                  {reason.details.docType}: {reason.details.text}
                </ListSubHeading>
              ))}
            <ContactAgentBlock>
              <ContactOption>
                <ContactLink href={`tel:${referral && referral.msisdn}`}>
                  <PageLogo
                    background="#E9F2FF"
                    width={"40px"}
                    height={"40px"}
                    iconHeight={"20px"}
                    iconWidth={"20px"}
                    Icon={PhoneIcon}
                  />
                  <ContactText>Call Agent</ContactText>
                </ContactLink>
              </ContactOption>
              <ContactOption>
                <ContactLink href={`sms:${referral && referral.msisdn}`}>
                  <PageLogo
                    background={"#FDEDE7"}
                    width={"40px"}
                    height={"40px"}
                    iconHeight={"20px"}
                    iconWidth={"20px"}
                    Icon={SMSIcon}
                  />
                  <ContactText>Send SMS</ContactText>
                </ContactLink>
              </ContactOption>
              <ContactOption>
                <ContactLink
                  href={`whatsapp://send?phone=${referral && referral.msisdn}`}
                >
                  <PageLogo
                    background={"#D3FDE9"}
                    width={"40px"}
                    height={"40px"}
                    iconHeight={"22.87px"}
                    iconWidth={"22.98px"}
                    Icon={WhatsAppIcon}
                  />
                  <ContactText>Whatsapp</ContactText>
                </ContactLink>
              </ContactOption>
            </ContactAgentBlock>
          </FlexCenteredBlock>
          {/* <Badge
                    top={"24px"}
                    height={"69px"}
                    background={colors.themeColor5}
                >
                    <BadgeHeading>Total Commissions Received</BadgeHeading>
                    <BadgeCommisionsAmount>{referral.commission}</BadgeCommisionsAmount>
                    <CommisionsLink to={{
                        pathname: "/actions/agents_commisions",
                        state: commissionsList
                    }}>
                        <ForwardArrowIcon />
                    </CommisionsLink>
                </Badge> */}
          {teamsParticipant &&
            teamsParticipant.map((team, index) => (
              <ParticipatingTeams>
                <TeamsHeader>Teams</TeamsHeader>
                <ListedTeam key={index}>
                  <PageLogo
                    width={"32px"}
                    height={"32px"}
                    iconHeight={"16px"}
                    iconWidth={"16px"}
                    Icon={TeamIcon}
                  />
                  <TeamName>{team.name}</TeamName>
                  <TeamLink
                    to={{
                      pathname: "/actions/agents_team_details",
                      state: teams[index],
                    }}
                  >
                    <ForwardArrowIcon />
                  </TeamLink>
                </ListedTeam>
              </ParticipatingTeams>
            ))}
        </ScreenContainer>
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default AgentDetails;
