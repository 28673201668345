import { push } from "connected-react-router";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { axios, currentAPI } from "../../../../../config";
import { countriesMap } from "../../../../../data/countries";
import {
  insertCountryCode,
  insertZero,
} from "../../../../../utils/inputs/formatPhoneNumber";
import { AUTH_ENTER_VALID_OTP } from "../../../../../utils/mix-panel/constants";
import { mixPanel } from "../../../../../utils/mix-panel/mixPanel";
import { resetCounter, setExpired } from "../../../timer/actions";
import { SAVE_USER_ID } from "../../../user/constants";
import { getUserPublicData } from "../../login/actions";
import {
  SENDING_CODE,
  SENDING_CODE_SUCCESS,
  SENDING_CODE_ERROR,
  LOADING,
  STOP_LOADING,
  INITIAL_PATH,
} from "../constants";

export const sendDeviceVerificationOtp = (msisdn) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await axios.get(
      `${currentAPI}/api/otp/send/mobile/device/verification?msisdn=${msisdn}`
    );
    if (response.status === 200) {
      dispatch({ type: STOP_LOADING });
      toast.success("OTP was sent to your phone");
    }
  } catch (error) {
    if (error.response && error.response.status === 403) {
      dispatch(push("locked"));
    }
  } finally {
    dispatch({ type: STOP_LOADING });
  }
};

export const sendCode =
  (code, startTime = new Date(), actionType) =>
  async (dispatch, getState) => {
    dispatch({ type: SENDING_CODE });

    try {
      const { userId, msisdn } = getState().user;

      const response = await axios.post(
        `${currentAPI}/api/otp/check?actionType=${actionType}&code=${code}&msisdn=${insertZero(
          msisdn
        )}`
      );

      const userData = await dispatch(getUserPublicData(userId));

      if (response.status === 200) {
        mixPanel.track(AUTH_ENTER_VALID_OTP, {
          Time: Math.round((new Date() - startTime) / 1000),
        });
        dispatch({ type: SENDING_CODE_SUCCESS });
        dispatch(resetCounter());

        if (userData === undefined) {
          dispatch(push("/user/create_role"));
        } else if (userData && userData.registrationFinished) {
          dispatch(push("/user/password_set"));
        } else if (userData.roleName === "ROLE_USER") {
          if (userData.firstName !== "") {
            let saved = localStorage.getItem("userData");
            saved = { ...userData };
            localStorage.setItem("userData", JSON.stringify(saved));

            dispatch(push("/user/create_user"));
          } else {
            dispatch(push("/user/create_role"));
          }
        } else if (userData.roleName === "ROLE_AGENT") {
          if (userData.bankDataPresent) {
            dispatch(push("/user/create_agent_group"));
          } else if (userData.documentsUploaded) {
            dispatch(push("/user/create_agent_group"));
            // dispatch(push('/user/create_agent_bank_account'));
          } else if (userData.country !== "") {
            dispatch(push("/user/create_agent_identity"));
          } else {
            dispatch(push("/user/create_agent"));
          }
        }
      }
    } catch (error) {
      dispatch({
        type: SENDING_CODE_ERROR,
        payload: error.message,
      });
      if (error.response && error.response.status === 400) {
        const { data } = (error && error.response) || {};
        data.message && toast.error(error.response.data.message);
      }
    }
  };

export const validateOTP =
  (actionType, phoneNumber, code) => async (dispatch, getState) => {
    dispatch({ type: SENDING_CODE });
    try {
      const response = await axios.put(
        `${currentAPI}/api/otp/validate?actionType=${actionType}&otp=${code}&msisdn=${encodeURIComponent(
          phoneNumber
        )}`
      );

      if (response.status === 200) {
        dispatch(resetCounter());
        dispatch(push("/user/create_role"));
        return true;
      }
    } catch (error) {
      dispatch({
        type: SENDING_CODE_ERROR,
        payload: error.message,
      });
      if (error.response && error.response.status === 400) {
        const { data } = (error && error.response) || {};
        data.message && toast.error(error.response.data.message);
      }
    } finally {
      dispatch({ type: SENDING_CODE_SUCCESS });
    }
  };

export const newValidateOTP =
  (actionType, phoneNumber, code) => async (dispatch, getState) => {
    dispatch({ type: SENDING_CODE });
    try {
      const response = await axios.post(
        `${currentAPI}/api/otp/check?actionType=${actionType}&code=${code}&msisdn=${encodeURIComponent(
          phoneNumber
        )}`
      );

      if (response.status === 200) {
        dispatch(resetCounter());
        dispatch(push("/user/password_set"));
        return true;
      }
    } catch (error) {
      dispatch({
        type: SENDING_CODE_ERROR,
        payload: error.message,
      });
      if (error.response && error.response.status === 400) {
        const { data } = (error && error.response) || {};
        data.message && toast.error(error.response.data.message);
      }
    } finally {
      dispatch({ type: SENDING_CODE_SUCCESS });
    }
  };

export const sendCodeFor403Validation =
  (code) => async (dispatch, getState) => {
    dispatch({ type: SENDING_CODE });

    try {
      const userId = getState().user.userId;

      const response = await axios.post(
        `${currentAPI}/api/otp/check?code=${code}&userId=${userId}`
      );

      if (response.status === 200) {
        dispatch({ type: SENDING_CODE_SUCCESS });
        dispatch(resetCounter());
        const history = useHistory();
        history.goBack();
      }
    } catch (error) {
      dispatch({
        type: SENDING_CODE_ERROR,
        payload: error.message,
      });
      //toast.error(error.response.data.error);
      if (error.response && error.response.status === 400) {
        const { data } = (error && error.response) || {};
        data.message && toast.error(error.response.data.message);
      }
    }
  };

export const resendCode = () => async (dispatch, getState) => {
  try {
    const country = getState().user.country;
    const countryCode = countriesMap.get(country).code;
    const msisdn = insertCountryCode(getState().user.msisdn, countryCode);

    const responseResendCode = await axios.get(
      `${currentAPI}/api/otp/send/mobile?msisdn=${encodeURIComponent(msisdn)}`
    );

    if (responseResendCode.status === 200) {
      dispatch(resetCounter());
    }
  } catch (error) {
    console.error(error);
  }
};

export const sendUssd = () => async (dispatch, getState) => {
  dispatch({ type: SENDING_CODE });

  try {
    const country = getState().user.country;
    const countryCode = countriesMap.get(country).code;
    const msisdn = insertCountryCode(getState().user.msisdn, countryCode);

    const sendUssdCodeResponse = await axios.get(
      `${currentAPI}/api/otp/send/ussd?msisdn=${encodeURIComponent(msisdn)}`
    );

    if (sendUssdCodeResponse.status === 200) {
      dispatch({ type: SENDING_CODE_SUCCESS });
      dispatch(setExpired(false));
      dispatch({
        type: SAVE_USER_ID,
        payload: sendUssdCodeResponse.data.userId,
      });
      toast.success(`Your pin code is: ${sendUssdCodeResponse.data.pinCode}`);
      dispatch(resetCounter());
    }
  } catch (error) {
    dispatch({ type: SENDING_CODE_ERROR, payload: error.message });
    //toast.error(error.response.data.error);
    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
  }
};

export const requestOtp =
  (actionType, channel, phoneNumber) => async (dispatch, getState) => {
    dispatch({ type: SENDING_CODE });

    try {
      const payload = {
        actionType: actionType,
        channel: channel,
        msisdn: phoneNumber,
      };

      const response = await axios.post(
        `${currentAPI}/api/otp/send/requestedChannel`,
        payload
      );

      if (response.status === 200) {
        dispatch(setExpired(false));
        dispatch(resetCounter());
        toast.success("Your OTP was sent");
        return true;
      }
    } catch (error) {
      dispatch({ type: SENDING_CODE_ERROR, payload: error.message });

      toast.error(
        `Sorry there is an error with sending an OTP. Please, choose another option to receive an OTP.`
      );
    } finally {
      dispatch({ type: SENDING_CODE_SUCCESS });
    }
  };

export const newResendCode = (channelType) => async (dispatch, getState) => {
  try {
    const { userId } = getState().user;

    const responseResendCode = await axios.get(
      `${currentAPI}/api/otp/resend/${channelType}?actionType=ONBOARDING&userId=${userId}`
    );
    if (responseResendCode.status === 200) {
      dispatch(resetCounter());
      return responseResendCode.data.userId;
    }
  } catch (error) {
    console.error(error);
  }
};

export const newPinResendCode = (channelType) => async (dispatch, getState) => {
  try {
    const userId = getState().user.userId;

    const responseResendCode = await axios.get(
      `${currentAPI}/api/otp/resend/${channelType}?actionType=RESET_LOGIN_PIN&userId=${userId}`
    );

    if (responseResendCode.status === 200) {
      dispatch(resetCounter());
      return responseResendCode.data.userId;
    }
  } catch (error) {
    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
  }
};

export const verifyDeviceOtp = (msisdn, code) => async (dispatch) => {
  dispatch({ type: SENDING_CODE });
  try {
    dispatch({ type: LOADING });
    const response = await axios.post(
      `${currentAPI}/api/otp/check/device/verification/code?msisdn=${msisdn}&code=${code}`
    );
    if (response.status === 200) {
      dispatch({ type: SENDING_CODE_SUCCESS });
      dispatch(setExpired(false));
      localStorage.setItem("fingerPrint", response.data.fingerPrint);
      toast.error(response.data.description);
      // dispatch({ type: STOP_LOADING });
      dispatch(push("/login"));
    }
  } catch (error) {
    if (error.response && error.response.status === 400) {
      dispatch({
        type: SENDING_CODE_ERROR,
        payload: error.response.data.error,
      });
      toast.error(error.response.data.error);
    }
  } finally {
    dispatch({ type: STOP_LOADING });
  }
};

export const sendOTPToWhatsapp = (actionType) => async (dispatch, getState) => {
  dispatch({ type: SENDING_CODE });

  try {
    const { msisdn } = getState().user;

    const sendWhatsappCodeResponse = await axios.get(
      `${currentAPI}/api/otp/send/whatsapp?actionType=${actionType}&msisdn=${encodeURIComponent(
        msisdn
      )}`
    );

    if (sendWhatsappCodeResponse.status === 200) {
      dispatch({ type: SENDING_CODE_SUCCESS });
      dispatch(setExpired(false));
      dispatch(resetCounter());
      toast.success(
        "Your OTP was sent to your Whatsapp account, associated with this phone number"
      );
      return true;
    }
  } catch (error) {
    dispatch({ type: SENDING_CODE_ERROR, payload: error.message });

    toast.error(
      "Sorry there is an error with sending an OTP to your Whatsapp. Please, choose another option to receive an OTP."
    );
  }
};

export const sendOTPToChannel =
  (actionType, channel = "mobile", via) =>
  async (dispatch, getState) => {
    dispatch({ type: SENDING_CODE });

    try {
      const { msisdn } = getState().user;

      const sendWhatsappCodeResponse = await axios.get(
        `${currentAPI}/api/otp/send/${channel}?actionType=${actionType}&msisdn=${encodeURIComponent(
          msisdn
        )}`
      );

      if (sendWhatsappCodeResponse.status === 200) {
        dispatch({ type: SENDING_CODE_SUCCESS });
        dispatch(setExpired(false));
        dispatch(resetCounter());
        toast.success(
          `Your OTP was sent to your ${via} associated with this phone number`
        );
        return true;
      }
    } catch (error) {
      dispatch({ type: SENDING_CODE_ERROR, payload: error.message });

      toast.error(
        "Sorry there is an error with sending an OTP to your Whatsapp. Please, choose another option to receive an OTP."
      );
    }
  };

export const sendDeviceVerificationOtpToWhatsApp =
  (msisdn) => async (dispatch) => {
    dispatch({ type: SENDING_CODE });
    try {
      const response = await axios.get(
        `${currentAPI}/api/otp/send/mobile/device/verification/whatsapp?msisdn=${msisdn}`
      );
      if (response.status === 200) {
        toast.success(
          "Your OTP was sent to your Whatsapp account, associated with this phone number"
        );
        dispatch({ type: SENDING_CODE_SUCCESS });
        dispatch(setExpired(false));
        dispatch(resetCounter());
      }
    } catch (error) {
      dispatch({ type: SENDING_CODE_ERROR, payload: error.message });
      toast.error(
        "Sorry there is an error with sending an OTP to your Whatsapp. Please, choose another option to receive an OTP."
      );
    }
  };

export const checkExistingEmail = (email) => async (dispatch, getState) => {
  if (!navigator.onLine) {
    toast.error("You can not perform this action offline...");
    return;
  }

  try {
    const userId = getState().user.userId;
    const checkExistingEmailResponse = await axios.post(
      `${currentAPI}/api/users/validate/email`,
      { email, userId }
    );

    if (checkExistingEmailResponse.status === 200) {
      return checkExistingEmailResponse.data;
    }
  } catch (error) {
    //
  }
};

export const checkReferralCode = (referralCode) => async () => {
  if (!navigator.onLine) {
    toast.error("You can not perform this action offline...");
    return;
  }

  try {
    const getAgentByReferralResponse = await axios.get(
      `${currentAPI}/api/users/agent/${referralCode}?referralCode=${referralCode}`
    );

    if (getAgentByReferralResponse.status === 200) {
      const { agentCodeToShare } = getAgentByReferralResponse.data;
      return agentCodeToShare;
    }
  } catch (error) {
    if (error.response && error.response.status === 404) {
      toast.error("Invalid referral code");
      //const { data } = (error && error.response) || {};
      //data.message && toast.error(error.response.data.error);
    }
  }
};

export const setInitialPath = (path) => (dispatch) => {
  dispatch({ type: INITIAL_PATH, payload: path });
};
