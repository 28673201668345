import React, { Fragment, useState, useRef } from "react";

import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";

import {
  TopHeader,
  UploadPicture,
  SelectBox,
  RippleButton,
  Loader,
  TextInputWithLabel,
  PhoneNumberInput,
} from "../../../../../components";
import { ReactComponent as BackArrow } from "../../../../../components/header/assets/back_icon.svg";
import { ImageCropper } from "../../../../../components/popup/image-cropper";
import { InputBlock } from "../../../../../containers/InputContainer";
import { Error } from "../../../../../containers/MessageContainer";
import { SlidingOverlay } from "../../../../../containers/OverlayContainer";
import { ScreenContainer } from "../../../../../containers/ScreenContainer";
import { verifyIdentity } from "../../../../../redux/ducks/applications/merchants/actions";
import { sendAvatar } from "../../../../../redux/ducks/user/actions/avatar";
import { colors } from "../../../../../styles";
import { toTitleCase } from "../../../../../utils/toTitleCase";
import DesktopBackgroundLayout from "../../../../DesktopBackgroundLayout";
import { ReactComponent as AlertIcon } from "../../../../home/shop-settings/assets/alert-circle.svg";
import { getStatusColor, getTextColor } from "../../../../home/utils";
import { Back } from "../../customers/customer-details";

import Avatar from "./assets/avatar.svg";
import { ReactComponent as Close } from "./assets/close.svg";
import { ReactComponent as File } from "./assets/file.svg";
import ProgressBar from "./progressBar";
import IdentityVerificationInitiated from "./verifyIdentityinitiate";
import { VerifyIdentityValidationSchema } from "./VerifyIdentityValidationSchema";

export const VerifyIdentityContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ top }) => top || "25px"};
  margin-bottom: ${({ bottom }) => bottom || "2px"};
`;

const Text = styled.h2`
  color: ${({ color }) => color || "#0b0c0e"};
  font-weight: ${({ weight }) => weight || "700"};
  font-size: ${({ size }) => size || "14px"};
  cursor: ${({ cursor }) => cursor || null};
`;

const VerificationStatus = styled.div`
  background-color: ${({ color }) => color};
  padding: 4px 8px;
  display: flex;
  align-items: center;
  height: 24px;
  border-radius: 24px;
  margin-left: auto;
  text-align: center;
`;

const UploadBox = styled.div`
  background: ${colors.myShop.packed.bg};
  border-radius: 4px;
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
`;

const ButtonContainer = styled.div`
  position: relative;
`;

const TextDiv = styled.div`
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 8px;
`;

const TextCover = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FileDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 62px 0 48px;
`;

const FileStyle = styled(File)`
  position: absolute;
  left: 19px;
`;

const Circle = styled.div`
  border-radius: 50%;
  background: #e2e9f0;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  right: 16px;
`;

const DisabledBlock = styled.div`
  opacity: 0.5;
`;

const DivText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VerifyIdentity = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const inputFile = useRef(null);
  const isLoading = useSelector(
    (state) => state.applications.merchants.isLoading
  );
  const merchVerify = useSelector(
    (state) => state.applications.merchants.merchVerify
  );
  const firstName = useSelector((state) => state.user.firstName);
  const avatar = useSelector((state) => state.user.avatar);
  const lastName = useSelector((state) => state.user.lastName);
  const msisdn = useSelector((state) => state.user.msisdn);
  const email = useSelector((state) => state.user.email);

  const [showCropper, setShowCropper] = useState(false);

  const [photo, setPhoto] = useState("");
  const [doc, setDoc] = useState(
    merchVerify?.identificationDocUrl ? merchVerify?.identificationDocUrl : ""
  );
  const [completed, setCompleted] = useState(0);
  const [showSuccess, setShowSuccess] = useState(
    merchVerify?.identificationDocUrl ? true : false
  );
  const [showVerificationInit, setShowVerificationInit] = useState(false);
  const [transition, setTransition] = useState("");
  const [base64URL, setBase64URL] = useState("");
  const [avatarbase64URL, setAvatarBase64URL] = useState("");
  const [imageBase64, setImageBase64] = useState("");
  const [docBase64, setDocBase64] = useState("");
  const [picture, setPicture] = useState(
    merchVerify?.imageUrl ? imageBase64 : ""
  );

  const [altLoading, setAltLoading] = useState(false);
  const identificationOption = [
    { label: "National ID", value: "NATIONAL_ID" },
    { label: "Voters Card", value: "WORK_ID" },
    { label: "Driver License", value: "DRIVER_LICENSE" },
    { label: "International Passport", value: "INTERNATIONAL_PASSPORT" },
  ];

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const handleFileInputChange = (e) => {
    let { file } = photo;

    file = e.target.files[0];

    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        setBase64URL(result, file);
      })
      .catch((err) => {
        console.log(err);
      });

    setPhoto(e.target.files[0]);
    setTransition("width 1s ease-in-out");
    setTimeout(() => setCompleted(100), 2000);
    setTimeout(() => setShowSuccess(true), 4000);
  };

  const onButtonClick = () => {
    inputFile.current.click();
  };

  const onCropperCancel = () => {
    setShowCropper(false);
    setPicture(merchVerify?.imageUrl ? imageBase64 : "");
  };
  const onCropperSave = async (url) => {
    if (url) {
      setShowCropper(false);
      setPicture(url);
      dispatch(sendAvatar(url));
    } else {
      toast.error("Image is still processing, wait for a sec...");
    }
  };

  const pAction = (image) => {
    const url = URL.createObjectURL(image);
    setShowCropper(true);
    setPicture(url);
  };

  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  getBase64FromUrl(merchVerify?.imageUrl).then(setImageBase64);
  getBase64FromUrl(merchVerify?.identificationDocUrl).then(setDocBase64);
  getBase64FromUrl(picture).then(setAvatarBase64URL);

  if (isLoading || altLoading)
    return (
      <SlidingOverlay>
        <Loader />
      </SlidingOverlay>
    );

  return (
    open && (
      <Fragment>
        {showVerificationInit && (
          <IdentityVerificationInitiated
            open={showVerificationInit}
            setOpen={() => setShowVerificationInit(!showVerificationInit)}
            cancel={setOpen}
          />
        )}

        {!showVerificationInit && (
          <SlidingOverlay>
            <DesktopBackgroundLayout>
              {showCropper && (
                <ImageCropper
                  avatar={picture}
                  onCancel={onCropperCancel}
                  onSave={onCropperSave}
                />
              )}

              <TopHeader title={"Verify your identity"}>
                <Back
                  onClick={() => {
                    setOpen();
                  }}
                >
                  <BackArrow />
                </Back>
              </TopHeader>
              <ScreenContainer>
                <VerifyIdentityContainer>
                  <UploadPicture
                    text={"Tap to change image"}
                    defaultPicture={merchVerify?.imageUrl ? avatar : Avatar}
                    pictureAction={pAction}
                    showCropper={showCropper}
                    picture={picture}
                  />
                  <Formik
                    initialValues={{
                      firstName: firstName,
                      lastName: lastName,
                      phoneNumber: msisdn,
                      avatarImage: merchVerify?.imageUrl
                        ? imageBase64
                        : picture,
                      country: "SA",
                      email: email,
                      identificationDocType:
                        merchVerify?.identificationDocType || "",
                      identificationDoc: merchVerify?.identificationDocUrl
                        ? docBase64
                        : "",
                    }}
                    validationSchema={VerifyIdentityValidationSchema}
                    onSubmit={(values, { setErrors }) => {
                      if (!merchVerify?.imageUrl && !picture) {
                        toast.error("Avatar Image is required");
                      } else {
                        setAltLoading(true);

                        values.avatarImage = picture
                          ? avatarbase64URL
                          : imageBase64;
                        values.identificationDoc = photo
                          ? base64URL
                          : docBase64;
                        const valuePick = identificationOption.filter(
                          (data) => data.label === values.identificationDocType
                        );
                        const params = {
                          avatarImage: values.avatarImage,
                          firstName: values.firstName,
                          lastName: values.lastName,
                          identificationDocType: valuePick[0].value,
                          identificationDoc: values.identificationDoc,
                        };
                        dispatch(verifyIdentity(params)).then((res) => {
                          if (res) {
                            setAltLoading(false);
                            setShowVerificationInit(true);
                          } else {
                            setAltLoading(false);
                          }
                        });
                      }
                    }}
                  >
                    {({
                      errors,
                      touched,
                      values,
                      setFieldValue,
                      handleChange,
                      initialValues,
                    }) => (
                      <Form>
                        <InputBlock>
                          <TextInputWithLabel
                            label={"First Name"}
                            type={"text"}
                            color={"#2E4457"}
                            value={values.firstName}
                            placeholder={"First name"}
                            name="firstName"
                            valid={`${!touched.firstName && !errors.firstName}`}
                            errors={
                              touched &&
                              touched.firstName &&
                              errors &&
                              errors.firstName
                            }
                          />

                          <TextInputWithLabel
                            label={"Last Name"}
                            type={"text"}
                            value={values.lastName}
                            color={"#2E4457"}
                            placeholder={"Last name"}
                            name="lastName"
                            valid={`${touched.lastName && !errors.lastName}`}
                            errors={
                              touched &&
                              touched.lastName &&
                              errors &&
                              errors.lastName
                            }
                          />
                          <DisabledBlock>
                            <PhoneNumberInput
                              label={"Phone Number"}
                              type={"number"}
                              color={"#2E4457"}
                              value={values.phoneNumber}
                              placeholder={"Phone Number"}
                              name={"phoneNumber"}
                              onKeyUp={(e) =>
                                (e.target.value = e.target.value.replace(
                                  /\s/g,
                                  ""
                                ))
                              }
                              valid={`${
                                !touched.phoneNumber && !errors.phoneNumber
                              }`}
                              errors={
                                touched &&
                                touched.phoneNumber &&
                                errors &&
                                errors.phoneNumber
                              }
                              readOnly
                            />
                          </DisabledBlock>
                          <DisabledBlock>
                            <TextInputWithLabel
                              label={"Email address"}
                              type={"text"}
                              color={"#2E4457"}
                              value={values.email}
                              placeholder={"Email address"}
                              name={"email"}
                              inputMode={"email"}
                              autoComplete={"email"}
                              valid={`${touched.email && !errors.email}`}
                              errors={
                                touched &&
                                touched.email &&
                                errors &&
                                errors.email
                              }
                              onBlur={(e) => {
                                if (!e.target.value) {
                                  e.target.value = email;
                                }
                              }}
                              onFocus={(e) => {
                                if (values.email.startsWith(msisdn)) {
                                  e.target.value = "";
                                }
                              }}
                              readOnly
                            />
                          </DisabledBlock>

                          <TextWrapper>
                            <DivText>
                              <Text>Means of ID</Text>
                            </DivText>
                            <VerificationStatus
                              color={
                                merchVerify?.verificationStatus
                                  ? getStatusColor(
                                      merchVerify?.verificationStatus
                                    )
                                  : "#FFC3BD"
                              }
                            >
                              {merchVerify?.verificationStatus === "FAILED" && (
                                <AlertIcon style={{ marginRight: "6.33px" }} />
                              )}
                              <Text
                                color={
                                  merchVerify?.verificationStatus
                                    ? getTextColor(
                                        merchVerify?.verificationStatus
                                      )
                                    : "#E01A00"
                                }
                                size={"10px"}
                                weight={"400"}
                              >
                                {merchVerify?.verificationStatus
                                  ? toTitleCase(
                                      merchVerify?.verificationStatus?.toLowerCase()
                                    )
                                  : "Unverified"}
                              </Text>
                            </VerificationStatus>
                          </TextWrapper>
                          <div>
                            <SelectBox
                              name={"identificationDocType"}
                              placeholder={"Select means of Identification"}
                              value={values.identificationDocType}
                              options={identificationOption}
                              handleChange={handleChange}
                              valid={`${
                                !touched.identificationDocType &&
                                !errors.identificationDocType
                              }`}
                              errors={
                                touched &&
                                touched.identificationDocType &&
                                errors &&
                                errors.identificationDocType
                              }
                              noSearch={true}
                              mbottom={"0"}
                              padding={"0"}
                              ListHeight={"100%"}
                              height={"60%"}
                              position={"fixed"}
                              fullMargin={"auto"}
                              fullMaxWidth={"375px"}
                              fullWidth={"100%"}
                            />
                            {errors &&
                              errors.identificationDocType &&
                              errors.identificationDocType && (
                                <Error>{errors.identificationDocType}</Error>
                              )}
                          </div>

                          <TextWrapper bottom={"6px"} top={"24px"}>
                            <Text weight={"400"}>Upload your ID card here</Text>
                          </TextWrapper>
                          <UploadBox>
                            <input
                              style={{ display: "none" }}
                              ref={inputFile}
                              onChange={handleFileInputChange}
                              type="file"
                            />
                            {!photo && !doc && (
                              <Text
                                color={"#227EFF"}
                                weight={"400"}
                                onClick={onButtonClick}
                              >
                                Click to Upload Document here
                              </Text>
                            )}
                            {(photo || doc) && (
                              <FileDiv>
                                <FileStyle />
                                <TextCover>
                                  <TextDiv>
                                    {doc
                                      ? merchVerify?.identificationDocType
                                      : photo.name}
                                  </TextDiv>
                                  {!showSuccess && (
                                    <ProgressBar
                                      bgcolor={"#227EFF"}
                                      completed={completed}
                                      transition={transition}
                                    />
                                  )}
                                  {(showSuccess || doc) && (
                                    <Text
                                      color={"#05944F"}
                                      size={"10px"}
                                      weight={"400"}
                                    >
                                      Upload Successful
                                    </Text>
                                  )}
                                </TextCover>

                                <Circle
                                  onClick={() => {
                                    setPhoto("");
                                    setShowSuccess(false);
                                    setTransition("");
                                    setCompleted(0);
                                    setDoc("");
                                  }}
                                >
                                  <Close />
                                </Circle>
                              </FileDiv>
                            )}
                          </UploadBox>
                        </InputBlock>
                        <ButtonContainer>
                          <RippleButton
                            type="submit"
                            top={"40px"}
                            disabled={
                              values.firstName === "" ||
                              values.lastName === "" ||
                              values.identificationDocType === "" ||
                              (merchVerify?.identificationDocUrl &&
                                !doc &&
                                !photo) ||
                              (!merchVerify?.identificationDocUrl && !photo)
                            }
                            height="56px"
                          >
                            Save
                          </RippleButton>
                        </ButtonContainer>
                      </Form>
                    )}
                  </Formik>
                </VerifyIdentityContainer>
              </ScreenContainer>
            </DesktopBackgroundLayout>
          </SlidingOverlay>
        )}
      </Fragment>
    )
  );
};

export default VerifyIdentity;
