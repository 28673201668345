import React from "react";

import { string, func, bool } from "prop-types";
import styled, { css } from "styled-components";

import { Message } from "../../../containers/MessageContainer";

const AnswerBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const AnswerVariantsBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const PadBlock = styled.label`
  width: calc(50% - 8px);
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  color: #22a8ff;

  ${({ selected }) =>
    selected &&
    css`
      background-color: #212c3d1a;
      color: #212c3d;
    `}
`;

export const YesNoBlock2 = ({ title, setAnswer, answer, name }) => {
  return (
    <AnswerBlock>
      <Message weight="500" color="#212c3d" top="0" bottom="16px">
        {title}
      </Message>
      <AnswerVariantsBlock>
        <PadBlock
          onClick={() => {
            if (name) {
              setAnswer(name, false);
            } else {
              setAnswer(false);
            }
          }}
          selected={!answer}
        >
          {"No"}
        </PadBlock>
        <PadBlock
          onClick={() => {
            if (name) {
              setAnswer(name, true);
            } else {
              setAnswer(true);
            }
          }}
          selected={answer}
        >
          {"Yes"}
        </PadBlock>
      </AnswerVariantsBlock>
    </AnswerBlock>
  );
};

YesNoBlock2.propTypes = {
  title: string,
  setAnswer: func,
  answer: bool,
  name: string,
};
