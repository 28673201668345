import React, { useState } from "react";

import { useSelector } from "react-redux";
import styled from "styled-components";

import { TopHeader } from "../../../../../components";
import { Close } from "../../../../../containers/HeaderContainer";
import {
  List,
  ListCheckedRow,
  ListHeading,
  ListSubHeading,
  ListItem,
  ListLeftBlock,
} from "../../../../../containers/ListContainer";
import { SlidingOverlay } from "../../../../../containers/OverlayContainer";
import { ScreenContainer } from "../../../../../containers/ScreenContainer";

const ListAdditionalInfo = styled.div`
  display: flex;
  flex-direction: row;
`;

export const AddProductCategory = ({ open, setOpen, category }) => {
  const productCategeries = useSelector(
    (state) => state.applications.merchbuy.merchbuyProductCategeries
  );
  const filteredProductCategory =
    productCategeries &&
    productCategeries.filter(
      (data) =>
        //data.listingOptions && data.listingOptions.isOnMerchBuy
        data.isAvailable
    );
  const [selectedCategory, setSelectedCategory] = useState();

  return (
    open && (
      <SlidingOverlay>
        <TopHeader
          title={"Product Categories"}
          noArrow={true}
          right={"16px"}
          withSpacesHeader
        >
          <Close left={"16px"} onClick={() => setOpen(false)} />
        </TopHeader>
        <ScreenContainer top="120px">
          <List fullScreen>
            {filteredProductCategory &&
              filteredProductCategory.map((item, index) => (
                <ListItem key={index} bottom={"8px"}>
                  <ListCheckedRow
                    bottom={"16px"}
                    value={item}
                    onClick={() => {
                      setSelectedCategory(item);
                      category(item);
                      setOpen();
                    }}
                    className={
                      selectedCategory && selectedCategory.id === item.id
                        ? "active"
                        : null
                    }
                  >
                    <ListLeftBlock left={"0"}>
                      <ListHeading>{item.name}</ListHeading>
                      <ListAdditionalInfo>
                        <ListSubHeading>{item.description}</ListSubHeading>
                      </ListAdditionalInfo>
                    </ListLeftBlock>
                  </ListCheckedRow>
                </ListItem>
              ))}
          </List>
        </ScreenContainer>
      </SlidingOverlay>
    )
  );
};
