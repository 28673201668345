import React, { Fragment, useEffect, useState } from "react";

import Countdown from "react-countdown";
import { useDispatch, useSelector } from "react-redux";
import { matchPath /*, useHistory */ } from "react-router-dom";
import styled from "styled-components";
import useRouter from "use-react-router";

import { TopHeader, Loader, Button } from "../../../../../components";
import FallbackProductImage from "../../../../../components/popup/merchbuy-add-to-cart-popup/assets/productImage.svg";
import { Message } from "../../../../../containers/MessageContainer";
import { merchbuyActions } from "../../../../../redux/ducks/applications/merchbuy/actions";
import { formatPrice } from "../../../../../utils/currency/formatPriceWithComma";
import { is_url } from "../../../../../utils/urls/isUrl";
import DesktopBackgroundLayout from "../../../../DesktopBackgroundLayout";
import { ReactComponent as SplitPieIcon } from "../../assets/split_pie_chart.svg";
import { ReactComponent as DeleteIcon } from "../../assets/trash.svg";
import { Container, Space, Main } from "../../styles";
import { GroupBuyInviteeSelectShare } from "../invitee-select-share/index";
import {
  ProductInfo,
  ProductBlock,
  ProductImage,
  SpacedBlock,
  BlockWithBorders,
  CenterBlock,
  SubTitle,
  Title,
} from "../styles";

const SelectedSlot = styled.h3`
  font-weight: 500;
  font-size: 14px;
  color: #29394f;
`;

const QtyText = styled.h3`
  color: #56636d;
  text-align: left;
  font-weight: 400;
  font-size: 12px;
`;

const Info = styled.h3`
  color: #6c7984;
  text-align: left;
  font-weight: 400;
  font-size: 12px;
`;

const InfoValue = styled.span`
  font-weight: 500;
  font-size: 12px;
  color: ${({ color }) => color || "#212c3d"};
`;

const GroupBuyInvitee = () => {
  //const history = useHistory();
  const { location } = useRouter();
  const dispatch = useDispatch();

  const { pathname } = location;
  const pattern = `(.*)?${"/actions/merchbuy/acceptance/:id"}`;
  const match = matchPath(pathname, { path: pattern }) || {};
  const shop = useSelector((state) => state.applications.myShop.shops[0]);
  const isLoading = useSelector(
    (state) => state.applications.merchbuy.isLoading
  );

  const [selectSharePopup, setSelectSharePopup] = useState(false);
  const [splitSelected, setSplitSelected] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedSplit, setSelectedSplit] = useState(0);
  const [groupBuy, setGroupBuy] = useState({});
  const [split, setSplit] = useState([]);
  const [unclaimedSplit, setUnclaimedSplit] = useState(0);

  const {
    images,
    organizerName,
    productBulkPrice,
    allParticipants,
    productName,
    productUnitValue,
    status,
    expiresAt,
    splits,
    id,
    productId,
  } = groupBuy || {};

  useEffect(() => {
    async function fetchData() {
      const response = await dispatch(
        merchbuyActions.getGroupBuyById(match.params.id)
      );
      response && setGroupBuy(response);
    }
    fetchData();
  }, [dispatch, match.params.id]);

  useEffect(() => {
    let result =
      splits &&
      splits.map((el, index) => {
        const participants =
          allParticipants &&
          allParticipants.find(
            (participant) =>
              participant && participant.groupBuySplitId === index
          );
        const slots = {
          name: `Slot ${index + 1}`,
          qty: el,
          amount: el * productBulkPrice,
          claimed: participants ? true : false,
        };
        return slots;
      });

    let unclaimed = result && result.filter((obj) => !obj.claimed);
    unclaimed && setUnclaimedSplit(unclaimed.length);
    result && setSplit(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productBulkPrice]);

  const getOrganizerPhone = () => {
    const organizer =
      allParticipants &&
      allParticipants.find(
        (participant) => participant && participant.organizer
      );
    return (organizer && organizer.phoneNumber) || "";
  };

  const handleAddToCart = () => {
    dispatch(
      merchbuyActions.addProductToCart({
        groupBuyId: id,
        groupBuySplitId: selectedIndex,
        productId: productId,
        quantity: selectedSplit,
        shopId: shop && shop.id,
      })
    );
  };

  const renderTime = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return <InfoValue color="#e02020">Expired</InfoValue>;
    } else {
      return (
        <InfoValue color="#e02020">
          {hours}:{minutes}:{seconds}
        </InfoValue>
      );
    }
  };

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <Fragment>
        <DesktopBackgroundLayout>
          <TopHeader
            backLink="/actions/merchbuy"
            title={"Group Buy"}
            right={"16px"}
            withSpacesHeader
          />
          <Main>
            <Container>
              <SubTitle weight="400" align="left">
                You have been invited to a groupbuy by{" "}
                {organizerName && organizerName}. View details of the group buy
                below. Don’t forget to pay immediately because it expires soon.
              </SubTitle>

              <ProductBlock>
                <ProductImage
                  src={
                    is_url(images ? images.baseImageUrl : "")
                      ? images.baseImageUrl
                      : FallbackProductImage
                  }
                  alt="product image"
                />
                <ProductInfo>
                  <Message top={"0"} size={"14px"} bottom={"10px"}>
                    {productName}
                  </Message>
                  <Message weight="500" size={"12px"}>
                    {productBulkPrice &&
                      (formatPrice(productBulkPrice || 0) +
                        ("/" + productUnitValue) ||
                        "/Unit")}
                  </Message>
                </ProductInfo>
              </ProductBlock>

              <div>
                <Title>Organizer Information</Title>
                <BlockWithBorders>
                  <div>
                    <SpacedBlock>
                      <Info>Full name:</Info>
                      <InfoValue>{organizerName && organizerName}</InfoValue>
                    </SpacedBlock>
                    <SpacedBlock top="8px">
                      <Info>Phone number:</Info>
                      <InfoValue>{getOrganizerPhone()}</InfoValue>
                    </SpacedBlock>
                    <SpacedBlock top="8px">
                      <Info>Expires in:</Info>
                      {expiresAt && (
                        <Countdown date={expiresAt} renderer={renderTime} />
                      )}
                    </SpacedBlock>
                    <SpacedBlock top="8px">
                      <Info>Number of slots:</Info>
                      <InfoValue>{splits && splits.length}</InfoValue>
                    </SpacedBlock>
                    <SpacedBlock top="8px">
                      <Info>Number of empty slots:</Info>
                      <InfoValue>{unclaimedSplit && unclaimedSplit}</InfoValue>
                    </SpacedBlock>
                  </div>
                </BlockWithBorders>

                <Title>Split</Title>
                <BlockWithBorders>
                  <div>
                    {!splitSelected ? (
                      <CenterBlock>
                        <SplitPieIcon style={{ minWidth: "100px" }} />
                        <SubTitle weight="400" bottom="16px">
                          This product has been split by{" "}
                          {splits && splits.length} people. Choose a share to
                          purchase.
                        </SubTitle>
                        <Button
                          top={"0px"}
                          type={"button"}
                          disabled={status === "FAILED"}
                          onClick={() => {
                            setSelectSharePopup(!selectSharePopup);
                          }}
                        >
                          Select my share
                        </Button>
                      </CenterBlock>
                    ) : (
                      <SpacedBlock>
                        <div>
                          <SelectedSlot>
                            Slot {selectedIndex + 1}:{" "}
                            {formatPrice(productBulkPrice * selectedSplit)}
                          </SelectedSlot>
                          <QtyText>{selectedSplit} Units</QtyText>
                        </div>
                        <DeleteIcon
                          onClick={() => {
                            setSplitSelected(false);
                            setSelectedIndex(-1);
                            setSelectedSplit(0);
                          }}
                        />
                      </SpacedBlock>
                    )}
                  </div>
                </BlockWithBorders>

                <Button
                  top={"10px"}
                  type={"button"}
                  disabled={!splitSelected}
                  onClick={handleAddToCart}
                >
                  Add To Cart
                </Button>
              </div>
            </Container>
          </Main>
          <Space height={"30px"} />
          <GroupBuyInviteeSelectShare
            open={selectSharePopup}
            cancel={setSelectSharePopup}
            productBulkPrice={productBulkPrice || 0}
            images={images}
            productName={productName}
            unitValue={productUnitValue}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            selectedSplit={selectedSplit}
            setSelectedSplit={setSelectedSplit}
            setSplitSelected={setSplitSelected}
            splits={split}
          />
        </DesktopBackgroundLayout>
      </Fragment>
    );
  }
};

export default GroupBuyInvitee;
