import React, { Fragment, useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";

import { Loader, RippleButton } from "../../../../../../components";
import { SearchInputWithCancel } from "../../../../../../components/forms/input/search-input/SearchInput";
import { BlurBackground } from "../../../../../../containers/BlurBackground";
import { List } from "../../../../../../containers/ListContainer";
import { Title } from "../../../../../../containers/MessageContainer";
import { ScreenContainer } from "../../../../../../containers/ScreenContainer";
import {
  getSabiCustomers,
  fetchDeliveryDetails,
} from "../../../../../../redux/ducks/applications/my-shop/actions/customers";
import { colors } from "../../../../../../styles";
import { ReactComponent as AddIcon } from "../../../assets/add_icon_white.svg";
import { ReactComponent as ContactIcon } from "../../../assets/contact_empty.svg";
import { ReactComponent as ContactIdeaIcon } from "../../../assets/contactIdea.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/goto_arrow.svg";
import { ReactComponent as NewInfoIcon } from "../../../assets/new-info-icon.svg";
import { Container, Divider } from "../../../styles";
import { SearchForSabiMerchant } from "../delivery-address-search-sabi-user";
import { EditDeliveryAddressDetails } from "../delivery-details-edit";

import InfoBadge from "./../../../../../../components/badges/info-badge/index";

const ModifiedContainer = styled(Container)`
  width: 100%;
`;

const HeadingWrapper = styled.div`
  margin: 14px 1em 0 1em;
  padding: 0 16px;
  background: ${colors.aliceBlue};
  box-shadow: 0px 15px 64px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeadingInnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const AddressText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  margin-left: 8px;
  color: ${colors.deepBlue};
`;

const DetailsSection = styled.div`
  padding: ${({ padding }) => padding || null};
  margin: ${({ margin }) => margin || "0"};
  min-height: ${({ minHeight }) => minHeight || "131px"};
  background-color: ${({ background }) => background || colors.white};
`;

const FlexContent = styled.div`
  display: flex;
`;

const ContentWrapper = styled(FlexContent)`
  display: flex;
  padding: 16px 1em;
  justify-content: space-between;
  align-items: flex-start;
`;

const Text = styled.p`
  margin: ${({ margin }) => margin || "0"};
  font-weight: ${({ fontWeight }) => fontWeight || "normal"};
  font-size: ${({ font }) => font || "12px"};
  color: ${({ color }) => color || null};
  text-align: ${({ align }) => align || null};
  width: ${({ width }) => width || null};
  padding-top: ${({ top }) => top || null};
  text-align: ${({ align }) => align || null};
  line-height: ${({ lineHeight }) => lineHeight || null};
`;

const DetailWrapper = styled.div`
  display: grid;
  gap: 6px;
`;

const DividerWrapper = styled.div`
  margin: 0 1em;
  margin-bottom: ${({ bottom }) => bottom || null};
`;

const Holder = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ContactWrapper = styled.div`
  width: 343px;
  height: 289px;
  border-radius: 8px;
  background: ${colors.white};
  margin: 0 1em 16px 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ButtonContainer = styled.div`
  padding: 0px 16px 12px;
  margin: 28px 0px 12px;
`;

const ModalContainer = styled.div`
  height: calc(236px + 66px);
  width: 100%;
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 8px 8px 0 0;
  @media (max-width: 576px) {
    height: auto;
  }
  padding: 24px 0px 0px;
`;
const Backdrop = styled.div`
  height: 100%;
  width: 100%;
`;

const SearchLengthText = styled.span`
  font-weight: 500;
  color: ${colors.black};
`;

export const DeliveryAddressList = ({
  close,
  getDeliveryDetails,
  merchbuyActions,
}) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const userDeliveryDetails = useSelector(
    (state) => state.applications.myShop.deliveryDetails
  );
  const isLoading = useSelector((state) => state.applications.myShop.isLoading);
  const contacts = useSelector(
    (state) => state.applications.myShop.customersAndReferrals
  );
  const deliveryAddressEditLimit = useSelector(
    (state) => state.applications.merchbuy.deliveryAddressEditLimit
  );
  const [deliveryContacts, setDeliveryContacts] = useState();
  const [searchedOptions, setSearchedOptions] = useState(deliveryContacts);
  const [searchValue, setSearchValue] = useState("");
  const [isEditingContactAddress, setIsEditingContactAddress] = useState(false);
  const [showNewAddressPopup, setShowNewAddressPopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showUsedUpAccessPopup, setShowUsedUpAccessPopup] = useState(false);

  useEffect(() => {
    dispatch(getSabiCustomers());
    dispatch(fetchDeliveryDetails());
  }, [dispatch]);

  useEffect(() => {
    setDeliveryContacts(contacts);
    if (deliveryContacts && deliveryContacts.length > 0 && searchValue) {
      const searchResults = deliveryContacts.filter(
        (x) =>
          String(x.fullName)
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          String(x.firstName)
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          String(x.lastName)
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          String(x.phoneNumber).includes(searchValue)
      );
      setSearchedOptions(searchResults);
    } else {
      setSearchedOptions(deliveryContacts);
    }
  }, [contacts, searchValue, deliveryContacts]);

  const [selectedDeliveryInformation, setSelectedDeliveryInformation] =
    useState(null);

  const checkRequiredFields = (address, lga, phoneNumber, state, city) => {
    return !address || !lga || !phoneNumber || !state;
  };

  const deliveryLocation =
    localStorage.getItem("deliveryLocation") &&
    JSON.parse(localStorage.getItem("deliveryLocation"));
  const { state: deliveryState } = deliveryLocation || {};

  const checkDeliveryLocation = (state) =>
    String(deliveryState).toLowerCase() === String(state).toLowerCase();

  const handleSelectSelfAddress = (userInfo) => {
    const { address, lga, phoneNumber, state, city } =
      userInfo || userDeliveryDetails || {};
    const isRequiredDetailsField = checkRequiredFields(
      address,
      lga,
      phoneNumber,
      state,
      city
    );
    const info = {
      ...userDeliveryDetails,
      firstName: user.firstName,
      lastName: user.lastName,
      contactType: "SELF",
    };

    if (!checkDeliveryLocation(state)) {
      return toast.error(
        "Province does not match shopping location, update county to your shopping location"
      );
    }

    if (isRequiredDetailsField) {
      return toast.error("Sorry your delivery information is incomplete");
    }

    setSelectedDeliveryInformation(info);
    close();
    getDeliveryDetails(info);
  };

  const handleSelectedContactAddress = (contact) => {
    const { shopAddress, lga, state, phoneNumber, city } = contact || {};
    const isRequiredDetailsField = checkRequiredFields(
      shopAddress,
      lga,
      phoneNumber,
      state,
      city
    );

    const info = {
      ...contact,
      contactType: "CONTACT",
    };

    if (!checkDeliveryLocation(state)) {
      return toast.error(
        "Sorry the customer's county does not match your shopping location"
      );
    }

    if (isRequiredDetailsField) {
      return toast.error("Sorry the customer's information is incomplete");
    }

    setSelectedDeliveryInformation(info);
    close();
    getDeliveryDetails(info);
  };

  if (isLoading) {
    return <Loader />;
  } else
    return (
      <Fragment>
        {showEditPopup && (
          <EditDeliveryAddressDetails
            open={showEditPopup}
            close={close}
            cancel={() => setShowEditPopup(false)}
            deliveryLocation={selectedDeliveryInformation}
            getDeliveryDetails={(values) => {
              dispatch(merchbuyActions.updateDeliveryLocation(values));
              getDeliveryDetails(values);
              setSelectedDeliveryInformation(values);
            }}
            setSelectedDeliveryInformation={setSelectedDeliveryInformation}
            handleSelectedContactAddress={handleSelectedContactAddress}
            isEditingContactAddress={isEditingContactAddress}
          />
        )}

        {showUsedUpAccessPopup && (
          <BlurBackground position="fixed">
            <Backdrop onClick={() => setShowUsedUpAccessPopup(false)}>
              <ModalContainer>
                <Title
                  left="16px"
                  textAlign="left"
                  size="20px"
                  lineHeight="30px"
                  bottom="24px"
                  weight="700"
                >
                  Edit Address
                </Title>
                <InfoBadge
                  icon={<NewInfoIcon />}
                  title="You have used up your address edit access."
                  message="Contact support for further clarification"
                  backgroundColor={"#FFF1F0"}
                  margin="0 16px 24px"
                  weight="400"
                  marginTop="4px"
                  color="#A31300"
                  bottom="4px"
                  size="10px"
                  height="16px"
                />

                <ButtonContainer>
                  <RippleButton
                    top="0px"
                    bottom="0px"
                    height="56px"
                    onClick={() => {
                      setShowUsedUpAccessPopup(false);
                    }}
                  >
                    Cancel
                  </RippleButton>
                </ButtonContainer>
              </ModalContainer>
            </Backdrop>
          </BlurBackground>
        )}

        {showNewAddressPopup && (
          <SearchForSabiMerchant
            open={showNewAddressPopup}
            cancel={() => setShowNewAddressPopup(false)}
            setSabiUserUse={(info) => {
              setShowNewAddressPopup(false);
              close();
              getDeliveryDetails(info);
            }}
            setSabiUserEdit={(info) => {
              setShowNewAddressPopup(false);
              setShowEditPopup(true);
              setSelectedDeliveryInformation(info);
            }}
          />
        )}
        {!showEditPopup && !showNewAddressPopup && (
          <Fragment>
            <SearchInputWithCancel
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
              title="Address Book"
              noArrow={true}
              placeholder={"Search Address"}
              onCancel={close}
              margin="1em 1em 0 1em"
              bottom="0"
            />
            <ScreenContainer top="0" color={colors.lightestGray} padding={"0"}>
              <ModifiedContainer>
                {!searchValue && (
                  <List noBorderBottom={true} fullScreen={false}>
                    <HeadingWrapper
                      onClick={() => {
                        setShowNewAddressPopup(true);
                      }}
                      minHeight="80px"
                      borderColor={colors.background.walletBadge}
                    >
                      <HeadingInnerWrapper>
                        <AddIcon />
                        <AddressText>Add Merchant Address</AddressText>
                      </HeadingInnerWrapper>
                      <ArrowIcon />
                    </HeadingWrapper>
                    <Text
                      margin="19px 1.5em 15px 1.5em"
                      color={colors.subGrey}
                      fontWeight="700"
                    >
                      YOUR ADDRESS
                    </Text>
                  </List>
                )}
                {!searchValue && (
                  <Fragment>
                    <List noBorderBottom={true}>
                      <DetailsSection padding="0px 16px">
                        <ContentWrapper>
                          <FlexContent>
                            <DetailWrapper>
                              {userDeliveryDetails?.receiverName && (
                                <Text
                                  color={colors.boldDark}
                                  fontWeight="500"
                                  font="14px"
                                >
                                  {userDeliveryDetails.receiverName}
                                </Text>
                              )}
                              {userDeliveryDetails?.address && (
                                <Text color={colors.lightDark} font="12px">
                                  {`${
                                    userDeliveryDetails.shopNumber
                                      ? userDeliveryDetails.shopNumber + ", "
                                      : ""
                                  } 
                                                                     ${
                                                                       userDeliveryDetails.address ||
                                                                       ""
                                                                     }`}
                                </Text>
                              )}
                              {userDeliveryDetails?.lga && (
                                <Text color={colors.lightDark} font="12px">
                                  {`${userDeliveryDetails.lga} Municipalities,`}
                                </Text>
                              )}
                              {userDeliveryDetails?.city && (
                                <Text color={colors.lightDark} font="12px">
                                  {userDeliveryDetails?.city}
                                </Text>
                              )}
                              {userDeliveryDetails?.state && (
                                <Text color={colors.lightDark} font="12px">
                                  {userDeliveryDetails.state}
                                </Text>
                              )}
                              {userDeliveryDetails?.msisdn && (
                                <Text color={colors.lightDark} font="12px">
                                  {userDeliveryDetails.msisdn}
                                </Text>
                              )}
                            </DetailWrapper>
                          </FlexContent>

                          <Text
                            onClick={() =>
                              handleSelectSelfAddress(userDeliveryDetails)
                            }
                            align="center"
                            color={colors.deepBlue}
                            fontWeight="bold"
                            font="14px"
                            lineHeight="21px"
                          >
                            Select
                          </Text>
                        </ContentWrapper>
                        <DividerWrapper bottom={"24px"}>
                          <Divider top={"0px"} bottom={"6px"} />
                          <Holder>
                            <Text
                              onClick={() => {
                                if (
                                  userDeliveryDetails.changeDeliveryAddressCount >=
                                  deliveryAddressEditLimit
                                ) {
                                  setShowUsedUpAccessPopup(true);
                                } else {
                                  setShowEditPopup(true);
                                  setSelectedDeliveryInformation({
                                    ...userDeliveryDetails,
                                    firstName: user.firstName,
                                    lastName: user.lastName,
                                    contactType: "SELF",
                                  });
                                  setIsEditingContactAddress(false);
                                }
                              }}
                              color={"#5C6F7F"}
                              fontWeight="700"
                            >
                              Edit
                            </Text>
                            <Text
                              color={"#5C6F7F"}
                              fontWeight="400"
                              font="10px"
                              lineHeight="16px"
                            >
                              {`Edited ${
                                userDeliveryDetails?.changeDeliveryAddressCount ||
                                0
                              } of ${deliveryAddressEditLimit} times`}
                            </Text>
                          </Holder>
                        </DividerWrapper>
                      </DetailsSection>
                    </List>
                    <Text
                      margin="8px 1.5em 8px 1.5em"
                      color={colors.subGrey}
                      fontWeight="700"
                    >
                      YOUR CONTACTS
                    </Text>
                  </Fragment>
                )}

                {(!searchedOptions || searchedOptions.length < 1) && (
                  <ContactWrapper>
                    <ContactIcon />
                    <Text
                      margin="16px 0"
                      color={colors.boldDark}
                      fontWeight="bold"
                      font="14px"
                    >
                      You don't have any contacts
                    </Text>
                    <ContactIdeaIcon />
                    <Text
                      margin="16px 0"
                      align="center"
                      width="296px"
                      color={colors.lightDark}
                    >
                      Try adding new contacts or updating your contact’s address
                      information so it can appear here
                    </Text>
                  </ContactWrapper>
                )}
                {searchValue && searchValue.length > 0 && (
                  <Text
                    margin="5emx 1.5em 16px 1.5em"
                    color={colors.subGrey}
                    fontWeight="normal"
                    font="12px"
                    top="35px"
                    align="center"
                  >
                    Showing{" "}
                    <SearchLengthText>
                      {searchedOptions && searchedOptions.length}
                    </SearchLengthText>{" "}
                    {searchedOptions && searchedOptions.length === 1
                      ? "result"
                      : "results"}
                  </Text>
                )}
                <List noBorderBottom={true}>
                  {searchedOptions &&
                    searchedOptions.length > 0 &&
                    searchedOptions.map((contact) => {
                      const {
                        id,
                        firstName,
                        lastName,
                        shopNumber,
                        shopAddress,
                        lga,
                        state,
                        changeAddressCount,
                      } = contact || {};

                      return (
                        <DetailsSection margin="4px 16px 0 16px" key={id}>
                          <ContentWrapper>
                            <FlexContent>
                              {contact && (
                                <DetailWrapper>
                                  <Text
                                    color={colors.boldDark}
                                    fontWeight="500"
                                    font="14px"
                                  >
                                    {`${firstName || ""} ${lastName || ""}`}
                                  </Text>
                                  <Text color={colors.lightDark} font="12px">
                                    {`${shopNumber ? shopNumber + ", " : ""} 
                                                                        ${
                                                                          shopAddress ||
                                                                          ""
                                                                        }`}
                                  </Text>
                                  {lga && (
                                    <Text color={colors.lightDark} font="12px">
                                      {`${lga} Municipalities`}
                                    </Text>
                                  )}
                                  {state && (
                                    <Text color={colors.lightDark} font="12px">
                                      {`${state} Province.`}
                                    </Text>
                                  )}
                                </DetailWrapper>
                              )}
                            </FlexContent>

                            <Text
                              onClick={() =>
                                handleSelectedContactAddress(contact)
                              }
                              align="center"
                              color={colors.deepBlue}
                              fontWeight="bold"
                              font="14px"
                              lineHeight="21px"
                            >
                              Select
                            </Text>
                          </ContentWrapper>
                          <DividerWrapper>
                            <Divider top={"0px"} bottom={"6px"} />
                            <Holder>
                              <Text
                                onClick={() => {
                                  if (
                                    changeAddressCount >=
                                    deliveryAddressEditLimit
                                  ) {
                                    setShowUsedUpAccessPopup(true);
                                  } else {
                                    setShowEditPopup(true);
                                    setSelectedDeliveryInformation({
                                      ...contact,
                                      contactType: "CUSTOMER",
                                    });
                                    setIsEditingContactAddress(true);
                                  }
                                }}
                                color={"#5C6F7F"}
                                fontWeight="700"
                              >
                                Edit
                              </Text>
                              <Text
                                color={"#5C6F7F"}
                                fontWeight="400"
                                font="10px"
                                lineHeight="16px"
                              >
                                {`Edited ${
                                  changeAddressCount || 0
                                } of ${deliveryAddressEditLimit} times`}
                              </Text>
                            </Holder>
                          </DividerWrapper>
                        </DetailsSection>
                      );
                    })}
                </List>
              </ModifiedContainer>
            </ScreenContainer>
          </Fragment>
        )}
      </Fragment>
    );
};
