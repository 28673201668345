import React, { Fragment, useState, useEffect } from "react";

import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import {
  TopHeader,
  Loader,
  SelectBox,
  RippleButton,
  FlatProgressBar,
  FileInput3,
  UploadPicture,
  PageLogo,
} from "../../../../../components";
import { NewTextInputWithLabel } from "../../../../../components/forms/input/new-text-input";
import { getAddressName } from "../../../../../components/google-map/getAddress";
import SimpleMap from "../../../../../components/google-map/simpleMap";
import { PopUp, PopUpContent } from "../../../../../components/popup/common";
import {
  ImageCropper,
  toDataURL,
} from "../../../../../components/popup/image-cropper";
import { Close } from "../../../../../containers/HeaderContainer";
import { InputBlock } from "../../../../../containers/InputContainer";
import { Overlay } from "../../../../../containers/OverlayContainer";
import {
  Container,
  FlexContainer,
  FlexDiv,
} from "../../../../../containers/ScreenContainer";
import { localAreas } from "../../../../../data/countries/nigeria/localAreas";
import { states } from "../../../../../data/countries/nigeria/states";
import { merchbuyActions } from "../../../../../redux/ducks/applications/merchbuy/actions";
import { verifyStoreLocation } from "../../../../../redux/ducks/applications/my-shop/actions/shop";
import { colors } from "../../../../../styles";
import { selectAreasByState } from "../../../../../utils/inputs/selectAreasByState";
import { tryParseJSONObject } from "../../../../../utils/tryParseJSONObject";
import DesktopBackgroundLayout from "../../../../DesktopBackgroundLayout";

import { ReactComponent as ShopSvg } from "./assets/shop.svg";
import {
  Space,
  Text,
  PageCount,
  SpanText,
  AddressDetails,
  Divider,
  ImageContainer,
  DocumentImage,
  FailedStatusDetails,
  AddressText,
} from "./styles";
import VerificationInitiated from "./verificationInitiated";
import { VerifyStoreValidationSchema } from "./VerifyStoreValidationSchema";

const VerifyStore = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const shop = useSelector((state) => state.applications.myShop.shops)[0];
  const loading = useSelector((state) => state.applications.myShop.isLoading);
  const isLoading = useSelector(
    (state) => state.auth.signup.merchant.isLoading
  );
  const [showMAP, setShowMAP] = useState(false);
  const [showCropper, setShowCropper] = useState(false);
  const [picture, setPicture] = useState();
  const [step, setStep] = useState(location.state || 1);
  const [formName, setFormName] = useState();
  const [pImages, setPImages] = useState({});
  const latestShopImages = shop?.shopImages?.slice(-3) || [];
  const shopImagesToDisplay = Object.values(pImages)?.length
    ? Object.values(pImages)
    : latestShopImages;
  const [showVerificationInit, setShowVerificationInit] = useState(false);
  const [showDocumentPreview, setShowDocumentPreview] = useState(false);
  const [documentUrl, setDocumentUrl] = useState(shop?.supportDocUrl || "");
  const [altLoading, setAltLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const comments = tryParseJSONObject(shop?.verificationComment) || {};
  const [showComment, setShowComment] = useState(true);
  const onCropperCancel = () => {
    setShowCropper(false);
    setPicture("");
  };

  const onCropperSave = async (url) => {
    if (url) {
      const image = await toDataURL(url);
      setPImages({ ...pImages, [formName]: image });
      setShowCropper(false);
    } else {
      toast.error("Image is still processing, wait for a sec...");
    }
  };

  const [address, setAddress] = useState("");
  const [coordinates, setCoordinates] = useState({
    longitude: shop?.longitude || 0,
    latitude: shop?.latitude || 0,
  });

  useEffect(() => {
    const changeAddress = async () => {
      setAddress(
        await getAddressName(coordinates.latitude, coordinates.longitude)
      );
    };
    changeAddress();
  }, [coordinates]);

  const pAction = (formName, image) => {
    setPicture(image);
    setFormName(formName);
    setShowCropper(true);
  };

  const onImageRemove = (formName) => {
    const images = { ...pImages };
    delete images[formName];

    const entries = Object.keys(images).flatMap((key) => [images[key]]);
    var newEntry = {};
    for (let i = 0; i < entries.length; i++) {
      newEntry = { ...newEntry, [`upload${i}`]: entries[i] };
    }
    setPImages(newEntry);
  };

  useEffect(() => {
    dispatch(merchbuyActions.getAllCities());
  }, [dispatch]);

  const handleSubmit = (values) => {
    const params = {
      ...values,
      supportDocType: values.supportDocType.toUpperCase().split(" ").join("_"),
      latitude: coordinates.latitude,
      longitude: coordinates.longitude,
      shopImages: Object.entries(pImages).map(
        (entries) => entries && entries[1]
      ),
    };
    setAltLoading(true);
    dispatch(verifyStoreLocation(shop?.id, params)).then((res) => {
      if (res) {
        setAltLoading(false);
        setShowVerificationInit(true);
      } else {
        setAltLoading(false);
        toast.error("An error occurred please try again");
      }
    });
  };

  if (loading || isLoading || altLoading) {
    return <Loader />;
  } else
    return (
      <Fragment>
        {showVerificationInit && <VerificationInitiated />}
        {!showVerificationInit && (
          <DesktopBackgroundLayout>
            {showCropper && (
              <ImageCropper
                avatar={picture}
                onCancel={onCropperCancel}
                onSave={onCropperSave}
              />
            )}
            {step === 3 ? (
              <TopHeader
                noBorderBottom
                size="14px"
                weight="400"
                title="Summary"
                color={colors.boldDark}
              />
            ) : (
              <TopHeader
                noBorderBottom
                size="14px"
                weight="400"
                title={showMAP ? "Location" : "Verify your store"}
                noArrow={showMAP}
                color={colors.boldDark}
              >
                {(step === 1 || step === 2) && (
                  <PageCount>
                    {step}
                    <SpanText as="span" color={colors.subGrey}>
                      /2
                    </SpanText>
                  </PageCount>
                )}
                {showMAP && (
                  <Close left={"true"} onClick={() => setShowMAP(false)} />
                )}
              </TopHeader>
            )}
            {(step === 1 || step === 2) && (
              <FlatProgressBar step={step} amount={2} />
            )}
            <Space val={"80px"} />
            <Formik
              initialValues={{
                state: shop?.countryState || "",
                lga: shop?.lga || "",
                city: shop?.city || "",
                shopNumber: shop?.shopNumber || "",
                streetName: shop?.streetAddress || "",
                supportDocType: shop?.supportDocType || "",
                supportDocImage: shop?.supportDocImage || "",
              }}
              validationSchema={VerifyStoreValidationSchema}
              onSubmit={(values, { setErrors }) => {}}
            >
              {({
                handleChange,
                errors,
                values,
                touched,
                setFieldValue,
                initialValues,
              }) => {
                return (
                  <>
                    <Form style={{ paddingBottom: "65px" }}>
                      {step === 1 && (
                        <>
                          <InputBlock>
                            <Container>
                              <Text
                                margin="0 0 4px"
                                weight="700"
                                size="16px"
                                lineHeight="24px"
                              >
                                Verify your address
                              </Text>
                              <Text color={colors.lightDark}>
                                Provide your address details for verification
                              </Text>
                              <SelectBox
                                name={"state"}
                                placeholder={"Province"}
                                value={values && values.state}
                                options={states}
                                handleChange={handleChange}
                                valid={`${!touched.state && !errors.state}`}
                                error={
                                  touched &&
                                  touched.state &&
                                  errors &&
                                  errors.state
                                }
                              />
                              <SelectBox
                                name={"lga"}
                                placeholder={"Municipalities"}
                                value={values && values.lga}
                                options={
                                  values.state
                                    ? selectAreasByState(
                                        values.state,
                                        localAreas
                                      )
                                    : []
                                }
                                handleChange={handleChange}
                                valid={`${!touched.lga && !errors.lga}`}
                                error={
                                  touched && touched.lga && errors && errors.lga
                                }
                              />
                              <NewTextInputWithLabel
                                name={"city"}
                                placeholder={"City"}
                                label={"City"}
                                value={values && values.city}
                                noClearButton
                                top="16px"
                                height="56px"
                              />
                              <NewTextInputWithLabel
                                label="Shop Number"
                                placeholder="Shop Number"
                                value={values && values.shopNumber}
                                name="shopNumber"
                                type="text"
                                noClearButton
                                height="56px"
                              />
                              <NewTextInputWithLabel
                                label="Street Name"
                                placeholder="Street Name"
                                value={values && values.streetName}
                                name="streetName"
                                type="text"
                                noClearButton
                                height="56px"
                              />
                            </Container>

                            {values.state &&
                              values.lga &&
                              values.city &&
                              values.shopNumber &&
                              values.streetName && (
                                <Container>
                                  <AddressDetails>
                                    <FlexDiv
                                      align="initial"
                                      style={{
                                        borderBottom: `1px solid ${colors.gray4}`,
                                        paddingBottom: "10.6px",
                                      }}
                                    >
                                      <ShopSvg />
                                      <Text
                                        margin="0 0 0 5.8px"
                                        color={colors.subGrey}
                                        size="12px"
                                        lineHeight="18px"
                                      >
                                        Your Shop Address
                                      </Text>
                                    </FlexDiv>
                                    <Text
                                      margin="8px 0 0"
                                      color={colors.subGrey}
                                      size="12px"
                                      lineHeight="18px"
                                    >
                                      {address}
                                    </Text>
                                  </AddressDetails>
                                  <Text margin="16px 0 8px">
                                    Drag the marker to the exact location
                                  </Text>
                                  <SimpleMap
                                    coordinates={coordinates}
                                    setCoordinates={setCoordinates}
                                    address={`${values.shopNumber} ${values.streetName} ${values.city} ${values.lga} ${values.state}`}
                                  />
                                </Container>
                              )}
                          </InputBlock>
                          <Container>
                            {showMAP && (
                              <RippleButton
                                type="button"
                                top={"24px"}
                                onClick={() => setShowMAP(false)}
                              >
                                Confirm
                              </RippleButton>
                            )}
                            {!showMAP && !showCropper && (
                              <RippleButton
                                type="button"
                                top={"120px"}
                                height="56px"
                                onClick={() => setStep(2)}
                                disabled={
                                  isLoading ||
                                  loading ||
                                  !values.state ||
                                  !values.lga ||
                                  !values.city ||
                                  !values.shopNumber ||
                                  !values.streetName ||
                                  !address
                                }
                              >
                                Save & Continue
                              </RippleButton>
                            )}
                          </Container>
                        </>
                      )}

                      {step === 2 && (
                        <>
                          <Text
                            margin="0 16px 4px"
                            weight="700"
                            size="16px"
                            lineHeight="24px"
                          >
                            Upload store documents
                          </Text>
                          <Text margin="0 16px" color={colors.lightDark}>
                            Upload documents that verify your store address
                          </Text>
                          <InputBlock>
                            <Container>
                              <SelectBox
                                name={"supportDocType"}
                                placeholder={"Store document"}
                                value={values && values.supportDocType}
                                options={[
                                  {
                                    value: "utilityBill",
                                    label: "Utility Bill",
                                  },
                                  {
                                    value: "invoice",
                                    label: "Invoice",
                                  },
                                ]}
                                handleChange={handleChange}
                                valid={`${
                                  !touched.supportDocType &&
                                  !errors.supportDocType
                                }`}
                                error={
                                  touched &&
                                  touched.supportDocType &&
                                  errors &&
                                  errors.supportDocType
                                }
                              />
                              <Text
                                margin="24px 0 4px"
                                color={colors.themeTextColor3}
                              >
                                Upload your means of verification here
                              </Text>
                              <FileInput3
                                mimeType={"image/*"}
                                name="supportDocImage"
                                compressFile
                                base64String
                                useFileName
                                value={values.supportDocImage}
                                label={values.supportDocType}
                                handleChange={(value, fileName) => {
                                  setDocumentUrl(value);
                                  setFieldValue("supportDocImage", value);
                                  setFileName(fileName);
                                }}
                                placeholder="Click to Upload Document here"
                              />

                              <Text
                                margin="32px 0 4px"
                                weight="700"
                                size="16px"
                                lineHeight="24px"
                              >
                                Upload store pictures
                              </Text>
                              <Text margin="0 0 24px" color={colors.lightDark}>
                                Upload a minimum of 2 pictures showing different
                                angles of your store. Each image must be unique.
                              </Text>
                              <FlexContainer justifyContent="initial">
                                <UploadPicture
                                  text={"Upload"}
                                  margin="0 12px 0 0"
                                  pictureAction={pAction}
                                  formName={"upload0"}
                                  version2={true}
                                  removeImage={onImageRemove}
                                  picture={pImages && pImages["upload0"]}
                                />
                                <UploadPicture
                                  text={"Upload"}
                                  margin="0 12px 0 0"
                                  pictureAction={pAction}
                                  formName={"upload1"}
                                  version2={true}
                                  removeImage={onImageRemove}
                                  picture={pImages && pImages["upload1"]}
                                  disabled={pImages && !pImages["upload0"]}
                                />
                                <UploadPicture
                                  text={"Upload"}
                                  margin="0 12px 0 0"
                                  pictureAction={pAction}
                                  formName={"upload2"}
                                  version2={true}
                                  removeImage={onImageRemove}
                                  picture={pImages && pImages["upload2"]}
                                  disabled={
                                    pImages &&
                                    (!pImages["upload0"] || !pImages["upload1"])
                                  }
                                />
                              </FlexContainer>
                            </Container>
                          </InputBlock>
                          <Container>
                            <RippleButton
                              type="button"
                              top={"120px"}
                              disabled={
                                isLoading ||
                                loading ||
                                !values.supportDocType ||
                                !values.supportDocImage ||
                                Object.keys(pImages).length < 2
                              }
                              height="56px"
                              onClick={() => {
                                setShowComment(false);
                                setStep(3);
                              }}
                            >
                              Save & Continue
                            </RippleButton>
                          </Container>
                        </>
                      )}

                      {step === 3 && (
                        <>
                          <Divider />
                          <Container
                            style={{
                              minHeight: "142px",
                              padding: "16px",
                            }}
                          >
                            <FlexContainer>
                              <Text
                                color={colors.subGrey}
                                weight="700"
                                size="10px"
                                lineHeight="16px"
                              >
                                ADDRESS
                              </Text>
                              <Text
                                onClick={() => setStep(1)}
                                color={colors.deepBlue}
                                weight="500"
                                size="10px"
                                lineHeight="16px"
                              >
                                Change
                              </Text>
                            </FlexContainer>
                            {shop?.verificationStatus === "UNVERIFIED" &&
                              comments?.address &&
                              showComment && (
                                <FailedStatusDetails margin="12px 0">
                                  <Text
                                    color={colors.textRed}
                                    size="12px"
                                    weight="500"
                                    lineHeight="18px"
                                  >
                                    {comments?.address}
                                  </Text>
                                </FailedStatusDetails>
                              )}
                            <AddressText>{`${values?.shopNumber || ""}, ${
                              values?.streetName || ""
                            }.`}</AddressText>
                            <AddressText>{`${values?.city || ""}, ${
                              values?.lga || ""
                            } Municipalities`}</AddressText>
                            <AddressText>{`${values?.state} Province`}</AddressText>
                          </Container>
                          <Divider />

                          <Container
                            style={{
                              minHeight: "124px",
                              padding: "16px",
                            }}
                          >
                            <FlexContainer>
                              <Text
                                color={colors.subGrey}
                                weight="700"
                                size="10px"
                                lineHeight="16px"
                              >
                                STORE DOCUMENTS
                              </Text>
                              <Text
                                onClick={() => {
                                  setStep(2);
                                  setFieldValue("supportDocType", "");
                                  setFieldValue("supportDocImage", "");
                                }}
                                color={colors.deepBlue}
                                weight="500"
                                size="10px"
                                lineHeight="16px"
                              >
                                Change
                              </Text>
                            </FlexContainer>
                            {shop?.verificationStatus === "UNVERIFIED" &&
                              comments?.document &&
                              showComment && (
                                <FailedStatusDetails margin="12px 0 8px">
                                  <Text
                                    color={colors.textRed}
                                    size="12px"
                                    weight="500"
                                    lineHeight="18px"
                                  >
                                    {comments?.document}
                                  </Text>
                                </FailedStatusDetails>
                              )}
                            <Text weight="500" margin="10px 0 0">
                              {values.supportDocType}
                            </Text>
                            <Text
                              weight="400"
                              margin="4px 0"
                              size="12px"
                              color={colors.lightDark}
                            >
                              {fileName}
                            </Text>
                            <Text
                              onClick={() => setShowDocumentPreview(true)}
                              weight="500"
                              size="12px"
                              lineHeight="18px"
                              color={colors.deepBlue}
                              margin="4px 0 0"
                            >
                              Preview
                            </Text>
                          </Container>
                          <Divider />

                          <Container
                            style={{
                              minHeight: "136px",
                              padding: "16px",
                            }}
                          >
                            <FlexContainer style={{ marginBottom: "10px" }}>
                              <Text
                                color={colors.subGrey}
                                weight="700"
                                size="10px"
                                lineHeight="16px"
                              >
                                STORE PICTURES
                              </Text>
                              <Text
                                onClick={() => setStep(2)}
                                color={colors.deepBlue}
                                weight="500"
                                size="10px"
                                lineHeight="16px"
                              >
                                Change
                              </Text>
                            </FlexContainer>
                            {shop?.verificationStatus === "UNVERIFIED" &&
                              comments?.pictures &&
                              showComment && (
                                <FailedStatusDetails margin="12px 0 12px">
                                  <Text
                                    color={colors.textRed}
                                    size="12px"
                                    weight="500"
                                    lineHeight="18px"
                                  >
                                    {comments?.pictures}
                                  </Text>
                                </FailedStatusDetails>
                              )}
                            <FlexContainer justifyContent="initial">
                              {shopImagesToDisplay?.map((image, index) => (
                                <PageLogo
                                  key={index}
                                  Icon={image}
                                  width={"64px"}
                                  height={"64px"}
                                  iconHeight={"64px"}
                                  iconWidth={"64px"}
                                  borderRadius="4px"
                                  objectFit="cover"
                                  margin="0 16px 0 0"
                                />
                              ))}
                            </FlexContainer>
                          </Container>
                          <Container>
                            <RippleButton
                              type="submit"
                              top={"120px"}
                              disabled={isLoading || loading}
                              height="56px"
                              onClick={() => handleSubmit(values)}
                            >
                              Save
                            </RippleButton>
                          </Container>
                        </>
                      )}
                    </Form>
                  </>
                );
              }}
            </Formik>
            {showDocumentPreview && (
              <PopUp open={true} onClick={() => setShowDocumentPreview(false)}>
                <Overlay bgc={"rgba(0,0,0,0.5)"} />
                <PopUpContent style={{ maxHeight: "90vh" }}>
                  <Container>
                    <ImageContainer>
                      <DocumentImage alt="document" src={documentUrl} />
                    </ImageContainer>

                    <RippleButton
                      bottom="20px"
                      onClick={() => setShowDocumentPreview(false)}
                    >
                      Dismiss preview
                    </RippleButton>
                  </Container>
                </PopUpContent>
              </PopUp>
            )}
          </DesktopBackgroundLayout>
        )}
      </Fragment>
    );
};

export default VerifyStore;
