import React from "react";

import Lottie from "react-lottie";

export const LottieControl = ({
  height,
  width,
  play = false,
  pause = false,
  animationData,
}) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Lottie
      options={defaultOptions}
      height={height}
      width={width}
      isStopped={!play}
      isPaused={pause}
    />
  );
};
