import React from "react";

import styled from "styled-components";

import { RippleButton } from "../../../../../components";
import { colors } from "../../../../../styles";
import { formatPrice } from "../../../../../utils/currency/formatPriceWithComma";
import History from "../../../../../utils/History";
import DesktopBackgroundLayout from "../../../../DesktopBackgroundLayout";
import bgImage from "../assets/Background.png";
import { ReactComponent as CheckIcon } from "../assets/glossy.svg";

const CongratulationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 60px;
`;

const CongratulationsInnerWrapper = styled.div`
  width: 128px;
  height: 24px;
  border-radius: 34px;
  margin-top: 12px;
  background: rgba(78, 153, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled.div`
  font-size: ${({ size }) => size || "14px"};
  color: ${({ color }) => color || colors.black};
  padding: ${({ padding }) => padding || null};
  line-height: ${({ lineHeight }) => lineHeight || null};
  font-weight: ${({ weight }) => weight || "400"};
  text-align: ${({ align }) => align || null};
  opacity: ${({ opacity }) => opacity || null};
`;

const Box = styled.div`
  width: 343px;
  margin: 16px 0;
  height: 228px;
  background: ${colors.white};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const NameInitial = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 32px;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ede6fe;
  color: #7e42f5;
`;

const NameWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  margin-top: 25px;
`;

const BalanceWrapper = styled.div`
  width: 87%;
  height: 45px;
  background: rgba(237, 242, 247, 0.5);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
`;

const ButtonWrapper = styled.div`
  margin-top: 100px;
  width: 343px;
`;

const Congratulations = ({ amount, name, balance, close }) => {
  return (
    <DesktopBackgroundLayout
      bgImage={`url(${bgImage})`}
      bgColor="rgba(31, 123, 250, 1)"
      bgSize="cover"
      bgPosition="center"
      fullHeight={true}
    >
      <CongratulationsWrapper>
        <CheckIcon />
        <CongratulationsInnerWrapper>
          <Text
            opacity={0.85}
            size="10px"
            lineHeight="16px"
            color={colors.white}
          >
            <span aria-label="congratulations emoji" role="img">
              🎉
            </span>{" "}
            Congratulations
          </Text>
        </CongratulationsInnerWrapper>
        <Text
          padding="8px 0 0 0"
          weight="bold"
          size="16px"
          lineHeight="24px"
          color={colors.white}
        >
          {balance === 0 ? "Payment Completed" : "Payment Added"}
        </Text>
        <Box>
          <Text
            padding="24px 0 0 0"
            weight="bold"
            size="20px"
            lineHeight="30px"
            color={colors.bgBlue}
          >
            {formatPrice(amount || 24000)}
          </Text>
          <Text
            padding="10px 0 0 0"
            weight="bold"
            size="10px"
            lineHeight="16px"
            color={colors.lightDark}
          >
            AMOUNT PAID
          </Text>
          <NameWrapper>
            <NameInitial>
              {name
                ?.split(" ")
                ?.map((n) => n[0])
                ?.join("")
                ?.toUpperCase()}
            </NameInitial>
            <Text
              padding="0 0 0 8px"
              weight="bold"
              lineHeight="21px"
              color={colors.boldDark}
            >
              {name}
            </Text>
          </NameWrapper>
          <BalanceWrapper>
            <Text padding="0 0 0 16px" lineHeight="21px" color={colors.subGrey}>
              Outstanding balance
            </Text>
            <Text
              padding="0 16px 0 0"
              weight="bold"
              lineHeight="21px"
              color="#A31300"
            >
              {formatPrice(balance)}
            </Text>
          </BalanceWrapper>
        </Box>
        <ButtonWrapper>
          <RippleButton
            onClick={close}
            width="343px"
            height="56px"
            backgroundColor={colors.white}
            color={colors.deepBlue}
          >
            Done
          </RippleButton>
          <RippleButton
            onClick={() => History.push("/")}
            border={`1px solid ${colors.white}`}
            width="343px"
            height="56px"
            backgroundColor="transparent"
            color={colors.white}
          >
            Go Home
          </RippleButton>
        </ButtonWrapper>
      </CongratulationsWrapper>
    </DesktopBackgroundLayout>
  );
};

export default Congratulations;
