export const surveyColors = {
  textColor1: "#5C6F7F",
};

export function getRatingValue(rate) {
  const rates = {
    1: "Bad",
    2: "Poor",
    3: "Average",
    4: "Good",
    5: "Excellent",
  };

  return rates[rate] ?? "Rating not found";
}

export function get3EmojiValue(rate) {
  const rates = {
    1: "No",
    2: "Not Really",
    3: "Yes",
  };

  return rates[rate] ?? "Rating not found";
}

export function getEmojiValue(rate) {
  const rates = {
    1: "Terrible",
    2: "Bad",
    3: "Neutral",
    4: "Good",
    5: "Amazing",
  };

  return rates[rate] ?? "Rating not found";
}

export const npsRates = [
  { value: "Terrible", score: 0 },
  { value: "Bad", score: 30 },
  { value: "Poor", score: 30 },
  { value: "Neutral", score: 50 },
  { value: "Average", score: 50 },
  { value: "Good", score: 70 },
  { value: "Amazing", score: 100 },
  { value: "Excellent", score: 100 },
  { value: "No", score: 30 },
  { value: "Not Really", score: 60 },
  { value: "Yes", score: 100 },
];

export const npsStarRates = [
  { value: "Bad", score: 0 },
  { value: "Poor", score: 30 },
  { value: "Average", score: 50 },
  { value: "Good", score: 70 },
  { value: "Excellent", score: 100 },
  { value: "Neutral", score: 50 },
  { value: "Average", score: 50 },
  { value: "Amazing", score: 100 },
];
