import React from "react";

import moment from "moment";
import Chart from "react-apexcharts";
import styled from "styled-components";

import { colors } from "../../../../../styles";
import { formatPrice } from "../../../../../utils/currency/formatPriceWithComma";
import { formatCurrency } from "../../../../../utils/currency/parseBalance";

import { ReactComponent as EmptySalesIcon } from "./assets/emptySales.svg";

const TabRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${colors.lightestGray};
  padding-bottom: 8px;
`;

const SalesText = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: ${colors.subGrey};
  padding-left: 17px;
`;

const FilterText = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: ${colors.boldDark};
  padding-right: 11.67px;
`;

const FilterRow = styled.div`
  display: flex;
  align-items: center;
  padding-right: 18.67px;
`;

const ReportWrapper = styled.div`
  min-height: 317px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${colors.white};

  .bar {
    width: 100%;
    height: 50%;
    padding: 0 5px 0 2px;
  }
  .apexcharts-toolbar {
    display: none;
  }
  path.apexcharts-bar-area,
  path.apexcharts-bar-area,
  path.apexcharts-bar-area,
  path.apexcharts-bar-area,
  path.apexcharts-bar-area,
  path.apexcharts-bar-area {
    fill: #93ecc1;
  }

  path.apexcharts-bar-area:last-child {
    fill: ${colors.textGreen};
  }

  tspan {
    font-size: 6.53488px;
    font-weight: 500;
    line-height: 10px;
    color: ;
  }
  div.bar {
    min-height: 223.571px;
  }
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: ${colors.lightDark};
  padding-top: 15px;
`;

const Amount = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${colors.deepBlue};
  margin-bottom: -13.69px;
`;

const DifferenceOutterWrapper = styled.div`
  background: ${colors.white};
  padding: 0 16px 17px 16px;
  width: 100%;
`;

const DifferenceWrapper = styled.div`
  height: 26px;
  background: ${({ less }) => (less ? "#FFC3BD" : "#e5fff2")};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DifferenceText = styled.div`
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: ${({ less }) => (less ? "#FF705E" : "#024525")};
  margin-left: 8.21px;
`;

const AmountText = styled.span`
  color: ${({ less }) => (less ? colors.red : colors.green)};
  font-weight: 500;
`;

const ProfitReport = ({
  filterType,
  setOpenFilterOptions,
  openFilterOptions,
  todayTotal,
  options,
  series,
  difference,
  sales,
}) => {
  return (
    <>
      <TabRow>
        <SalesText>Profit</SalesText>
        <FilterRow onClick={() => setOpenFilterOptions(!openFilterOptions)}>
          <FilterText>{filterType}</FilterText>
        </FilterRow>
      </TabRow>
      <ReportWrapper>
        {sales.length === 0 ? (
          <EmptySalesIcon />
        ) : (
          <>
            <Title>Today, {moment().format("MMM D")}</Title>
            <Amount>{formatPrice(todayTotal)}</Amount>
            <Chart
              options={options}
              series={series}
              className="bar"
              type="bar"
            />
            <DifferenceOutterWrapper>
              <DifferenceWrapper less={difference <= 0}>
                {difference > 0 && (
                  <span aria-label="emoji" role="img">
                    🎉
                  </span>
                )}
                <DifferenceText less={difference <= 0}>
                  You sold{" "}
                  <AmountText less={difference <= 0}>
                    {formatCurrency(difference)}
                  </AmountText>{" "}
                  {difference <= 0 ? "less" : "more"} than yesterday
                </DifferenceText>
              </DifferenceWrapper>
            </DifferenceOutterWrapper>
          </>
        )}
      </ReportWrapper>
    </>
  );
};

export default ProfitReport;
