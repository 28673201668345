import * as Yup from "yup";

import { containsLettersRegExp } from "../../../../../../../utils/regexp/containsLettersRegExp";
import { phoneRegExp } from "../../../../../../../utils/regexp/phoneRegExp";

export const AddCustomerValidationSchema = Yup.object().shape({
  customerPhoneNumber: Yup.string()
    .matches(containsLettersRegExp, "Cannot contain letters")
    .when("fullPay", {
      is: false,
      then: Yup.string()
        .matches(phoneRegExp, "Phone Number is not valid")
        .required("Required"),
      otherwise: Yup.string().when(["customerName", "customerPhoneNumber"], {
        is: (customerName, customerPhoneNumber) =>
          customerName && !customerPhoneNumber,
        then: Yup.string().required("Customer phone is required"),
      }),
    })
    .test("case 1", "Phone number is not valid!", (value) => {
      if (value && value.trim().startsWith("0") && value.trim().length > 11) {
        return false;
      } else return true;
    })
    .test("case 2", "Phone number is not valid!", (value) => {
      if (
        value &&
        !value.trim().startsWith("254") &&
        !value.trim().startsWith("0") &&
        value.trim().length > 10
      ) {
        return false;
      } else return true;
    })
    .test("case 3", "Phone number is not valid!", (value) => {
      if (
        value &&
        value.trim().startsWith("254") &&
        value.trim().length !== 13
      ) {
        return false;
      } else return true;
    }),
  customerName: Yup.string().when("fullPay", {
    is: false,
    then: Yup.string().nullable().required("Required"),
    otherwise: Yup.string().when(["customerName", "customerPhoneNumber"], {
      is: (customerName, customerPhoneNumber) =>
        !customerName && customerPhoneNumber,
      then: Yup.string().required("Customer name is required"),
    }),
  }),
});
