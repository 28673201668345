import React, { Fragment, useState, useEffect } from "react";

import { Formik, Form /*FieldArray, ErrorMessage*/ } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import useRouter from "use-react-router";

import {
  TopHeader,
  InputWithOnchange,
  UploadPicture,
  RippleButton,
  Loader,
  SwitchTrigger2,
  PageLogo,
} from "../../../../../../components";
import {
  ImageCropper,
  toDataURL,
} from "../../../../../../components/popup/image-cropper";
import { InputBlock } from "../../../../../../containers/InputContainer";
import {
  List,
  ListItem,
  ListHeading,
} from "../../../../../../containers/ListContainer";
import { SubTitle } from "../../../../../../containers/MessageContainer";
import { ActionBlock } from "../../../../../../containers/OverlayContainer";
import {
  ScreenContainer,
  FlexContainer,
  FlexDiv,
} from "../../../../../../containers/ScreenContainer";
import * as actions from "../../../../../../redux/ducks/applications/my-shop/actions/shop";
import { colors } from "../../../../../../styles";
import { formatCurrency } from "../../../../../../utils/currency/parseBalance";
import { toTitleCase } from "../../../../../../utils/toTitleCase";
import DesktopBackgroundLayout from "../../../../../DesktopBackgroundLayout";
import { ReactComponent as CloseIcon } from "../assets/close-icon.svg";
import { ReactComponent as DefaultIcon } from "../assets/default-product-icon.svg";
import { ReactComponent as ProgressBar } from "../assets/progress indicator bar.svg";
import { ReactComponent as Smiley } from "../assets/smiley.svg";

import { EditProductValidationSchema } from "./EditProductValidationSchema";

const AddProductContainer = styled.div`
  margin-top: 10px;
`;

const Title = styled.p`
  font-size: ${({ size }) => size || "16px"};
  font-weight: ${({ weight }) => weight || "700"};
  line-height: ${({ lineHeight }) => lineHeight || "24px"};
  color: ${({ color }) => color || colors.boldDark};
`;

const PicturesContainer = styled.div`
  display: flex;
  & > *:not(:last-child) {
    margin-right: 8px;
  }
`;

const InputBottomLabels = styled(SubTitle)`
  margin-left: ${({ left }) => left || "12px"};
  font-size: ${({ size }) => size || "10px"};
  color: ${({ color }) => color || colors.subGrey};
  font-weight: 400;
  line-height: 16px;
`;

const CompletionPercentage = styled(Title)`
  margin: 0;
`;

const ProfitText = styled(InputBottomLabels)``;

const MasterListContainer = styled.div`
  padding: 15px 16px;
  margin: 12px 0;
  border: 1px solid ${colors.lightGray};
  border-radius: 8px;
`;

const SpacedDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EditSingleIncompleteProduct = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { location } = useRouter();
  const isLoading = useSelector((state) => state.applications.myShop.isLoading);
  const shops = useSelector((state) => state.applications.myShop.shops);
  const currentShop = shops[0];
  const shopId = shops[0] && shops[0].id;
  const isOffline = useSelector((state) => state.offline.isOffline);
  const productsMasterList = useSelector(
    (state) => state.applications.myShop.productsMasterList
  );

  const [openMasterList, setOpenMasterList] = useState(false);
  const [showCropper, setShowCropper] = useState(false);
  const [picture, setPicture] = useState();
  const [formName, setFormName] = useState();
  const [pImages, setPImages] = useState({});
  const [quantityVal, setQuantityVal] = useState(1);
  const [productName, setProductName] = useState();
  const [costPrice, setCostPrice] = useState();
  const [sellingPrice, setSellingPrice] = useState();
  const [masterListImageUrl, setMasterListImageUrl] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const product = location && location.state;
  const [showUploadImage, setShowUploadImage] = useState(
    product?.images?.baseImageUrl ? true : false
  );

  const setSelectedProduct = (itemName, itemImage) => {
    itemName && setProductName(itemName);
    itemImage && setPImages({ ...pImages, upload0: itemImage });
    itemImage && setMasterListImageUrl(itemImage);
  };

  const pAction = (formName, image) => {
    setPicture(image);
    setFormName(formName);
    setShowCropper(true);
  };

  const onCropperCancel = () => {
    setShowCropper(false);
  };

  const onCropperSave = async (url) => {
    if (url) {
      const image = await toDataURL(url);
      setPImages({ ...pImages, [formName]: image });
      setShowCropper(false);
    } else {
      toast.error("Image is still processing, wait for a sec...");
    }
  };

  const onImageRemove = (formName) => {
    const images = { ...pImages };
    delete images[formName];
    if (formName === "upload0") {
      setMasterListImageUrl("");
    }

    const entries = Object.keys(images).flatMap((key) => [images[key]]);
    var newEntry = {};
    for (let i = 0; i < entries.length; i++) {
      newEntry = { ...newEntry, [`upload${i}`]: entries[i] };
    }
    setPImages(newEntry);
  };

  const onChangeWithFormater = (e, setValue) => {
    const {
      target: { value: inputValue = 0 },
    } = e;
    const value = parseInt(inputValue.replace(/\D/g, "") || 0, 10);
    setValue(value);
  };

  useEffect(() => {
    product && setProductName(product.productName);
    product && setCostPrice(product && product.costPrice);
    product && setSellingPrice(product && product.retailUnitPrice);
    product && setQuantityVal(product && product.quantity);
    product &&
      product.images &&
      setPImages({
        ...pImages,
        upload0: product.images.baseImageUrl,
        upload1: product.images.others && product.images.others[0],
        upload2: product.images.others && product.images.others[1],
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      searchTerm &&
        dispatch(
          actions.searchProductsOnMasterList(currentShop.branchId, searchTerm)
        );
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchTerm]);

  return isLoading ? (
    <Loader />
  ) : (
    <Fragment>
      <DesktopBackgroundLayout>
        <TopHeader
          size="14px"
          color={colors.boldDark}
          weight="400"
          title={"Update product"}
          noBorderBottom
        />
        <ScreenContainer paddingBottom={"100px"}>
          <Formik
            initialValues={{
              id: product.id,
              productName: productName || "",
              productDescription: product.productDescription || "",
              productCategory: product.productCategory || "",
              productUnit: product.productUnit || "",
              costPrice: costPrice || "",
              quantity: quantityVal || "",
              retailUnitPrice: sellingPrice || "",
              availableAtRetailPrice: product.availableAtRetailPrice || false,
              availableAtWholesalePrice:
                product.availableAtWholesalePrice || false,
              bulkPrices:
                product.bulkPrices && product.bulkPrices[0]
                  ? product.bulkPrices
                  : [{ price: "", moq: "" }],
              moq: product.moq || "",
              base64ProductImageString: "",
              otherImagesBase64String: [],
              images: {
                baseImageUrl: "",
                others: [],
              },
              masterListImageUrl: masterListImageUrl || "",
              localSettings: {
                hasInventory: true,
              },
            }}
            enableReinitialize
            validationSchema={EditProductValidationSchema}
            onSubmit={async (values, { setErrors }) => {
              const images = Object.entries(pImages).map(
                (entries) => entries && entries[1]
              );
              values = {
                ...values,
                productName: toTitleCase(values.productName),
                base64ProductImageString: masterListImageUrl
                  ? ""
                  : (images && images[0]) || "",
                otherImagesBase64String: [
                  (images && images[1]) || "",
                  (images && images[2]) || "",
                ],
                images: {
                  baseImageUrl: masterListImageUrl
                    ? ""
                    : (images && images[0]) || "",
                  others: [
                    (images && images[1]) || "",
                    (images && images[2]) || "",
                  ],
                },
              };
              const res = await dispatch(
                actions.updateProductV2(
                  currentShop.id,
                  product.id,
                  values,
                  false
                )
              );
              res &&
                history.replace({
                  pathname: "/actions/shop_product_details",
                  state: { product: values, shopId, success: true },
                });
            }}
          >
            {({
              errors,
              touched,
              values,
              setFieldValue,
              handleChange,
              initialValues,
            }) => {
              return (
                <AddProductContainer>
                  {showCropper && (
                    <ImageCropper
                      avatar={picture}
                      onCancel={onCropperCancel}
                      onSave={onCropperSave}
                    />
                  )}
                  <Form>
                    <Title>Complete Product details</Title>
                    <CompletionPercentage
                      color={colors.deepBlue}
                      size="12px"
                      lineHeight="18px"
                    >
                      75%
                    </CompletionPercentage>
                    <ProgressBar />

                    <InputBlock top="29px">
                      <InputWithOnchange
                        lineHeight="24px"
                        label={"Product Name"}
                        type={"text"}
                        placeholder={"Product Name"}
                        autoComplete={"off"}
                        name="productName"
                        bottom="18px"
                        height={"56px"}
                        background={colors.themeColor3}
                        borderColor={colors.lightGray}
                        color={colors.deepBlue}
                        value={values.productName}
                        valid={`${!touched.productName && !errors.productName}`}
                        errors={
                          touched &&
                          touched.productName &&
                          errors &&
                          errors.productName
                        }
                        onChange={(e) => setProductName(e.target.value)}
                        onFocus={(e) => {
                          if (isOffline === false) {
                            setSearchTerm(e.target.value);
                            setOpenMasterList(true);
                          }
                        }}
                        onKeyUp={(e) => {
                          if (isOffline === false) {
                            setSearchTerm(e.target.value);

                            const masterListProduct =
                              productsMasterList &&
                              productsMasterList.find(
                                (product) =>
                                  product.itemName === values.productName
                              );

                            if (
                              masterListProduct &&
                              masterListProduct.masterListImageUrl
                            ) {
                              setFieldValue(
                                "productName",
                                masterListProduct.itemName
                              );
                              setFieldValue(
                                "masterListImageUrl",
                                masterListProduct.masterListImageUrl
                              );
                            }
                          }
                        }}
                        noClearButton={true}
                        initialValues={initialValues}
                      />

                      {openMasterList &&
                        productsMasterList &&
                        productsMasterList.length > 0 && (
                          <MasterListContainer>
                            <SpacedDiv>
                              <Title
                                color={colors.subGrey}
                                weight="500"
                                size="14px"
                                lineHeight="21px"
                              >
                                Suggestions
                              </Title>
                              <CloseIcon
                                onClick={() => setOpenMasterList(false)}
                              />
                            </SpacedDiv>
                            <List style={{ border: "none" }} fullScreen>
                              {productsMasterList &&
                                productsMasterList.map((item, index) => (
                                  <ListItem
                                    key={index}
                                    bottom={"8px"}
                                    noBorderBottom
                                    align="center"
                                  >
                                    <PageLogo
                                      width={"32px"}
                                      height={"32px"}
                                      iconWidth={"16px"}
                                      iconHeight={"20px"}
                                      Icon={
                                        item.masterListImageUrl ||
                                        DefaultIcon ||
                                        ""
                                      }
                                      background={colors.gray4}
                                      borderRadius="4px"
                                      objectFit="cover"
                                    />
                                    <ListHeading
                                      style={{ margin: "0 0 0 8px" }}
                                      onClick={() => {
                                        setSelectedProduct(
                                          item.itemName,
                                          item.masterListImageUrl
                                        );
                                        setShowUploadImage(true);
                                        dispatch(
                                          actions.clearProductsOnMasterList()
                                        );
                                      }}
                                    >
                                      {item.itemName}
                                    </ListHeading>
                                  </ListItem>
                                ))}
                            </List>
                          </MasterListContainer>
                        )}
                      <FlexContainer style={{ marginBottom: "11px" }}>
                        <SubTitle
                          color={colors.themeColor2}
                          font="12px"
                          lineHeight="18px"
                        >
                          {" "}
                          Add Product Image
                        </SubTitle>
                        <SwitchTrigger2
                          checkStatus={showUploadImage}
                          switchStatus={() => {
                            if (
                              showUploadImage &&
                              Object.keys(pImages).length > 0
                            ) {
                              setPImages({});
                              setMasterListImageUrl("");
                            }
                            setShowUploadImage(!showUploadImage);
                          }}
                        />
                      </FlexContainer>
                      {showUploadImage && (
                        <PicturesContainer
                          style={{
                            marginBottom: "18px",
                            transition: "all 1s linear",
                          }}
                        >
                          <UploadPicture
                            text={"Upload"}
                            pictureAction={pAction}
                            formName={"upload0"}
                            version2={true}
                            removeImage={onImageRemove}
                            picture={pImages && pImages["upload0"]}
                          />

                          <UploadPicture
                            text={"Upload"}
                            pictureAction={pAction}
                            formName={"upload1"}
                            version2={true}
                            removeImage={onImageRemove}
                            picture={pImages && pImages["upload1"]}
                            disabled={pImages && !pImages["upload0"]}
                          />

                          <UploadPicture
                            text={"Upload"}
                            pictureAction={pAction}
                            formName={"upload2"}
                            version2={true}
                            removeImage={onImageRemove}
                            picture={pImages && pImages["upload2"]}
                            disabled={
                              pImages &&
                              (!pImages["upload0"] || !pImages["upload1"])
                            }
                          />
                        </PicturesContainer>
                      )}

                      <InputWithOnchange
                        label={"Cost Price"}
                        type={"text"}
                        inputMode={"numeric"}
                        placeholder={"Cost Price?"}
                        autoComplete={"off"}
                        name="costPrice"
                        bottom="4px"
                        height={"56px"}
                        background={colors.themeColor3}
                        value={formatCurrency(values.costPrice, "SA")}
                        valid={`${!touched.costPrice && !errors.costPrice}`}
                        errors={
                          touched &&
                          touched.costPrice &&
                          errors &&
                          errors.costPrice
                        }
                        noClearButton={true}
                        onChange={(e) => onChangeWithFormater(e, setCostPrice)}
                        initialValues={initialValues}
                        borderColor={colors.lightGray}
                        color={colors.deepBlue}
                      />
                      {costPrice ? (
                        <InputBottomLabels bottom="15px">
                          How much you bought the product
                        </InputBottomLabels>
                      ) : (
                        <InputBottomLabels color={colors.red} bottom="15px">
                          Add how much you bought the product
                        </InputBottomLabels>
                      )}

                      <InputWithOnchange
                        label={"Quantity in stock"}
                        type={"number"}
                        placeholder={"Quantity in stock"}
                        autoComplete={"off"}
                        name="quantity"
                        bottom="4px"
                        height={"56px"}
                        background={colors.themeColor3}
                        value={values.quantity}
                        valid={`${!touched.quantity && !errors.quantity}`}
                        errors={
                          touched &&
                          touched.quantity &&
                          errors &&
                          errors.quantity
                        }
                        onChange={(e) => {
                          const {
                            target: { value },
                          } = e;
                          if (value.length > 7) return;
                          setQuantityVal(value);
                        }}
                        onKeyUp={(e) => {}}
                        noClearButton={true}
                        initialValues={initialValues}
                        borderColor={colors.lightGray}
                        color={colors.deepBlue}
                      />
                      {quantityVal ? (
                        <InputBottomLabels bottom="15px">
                          How much do you have in stock
                        </InputBottomLabels>
                      ) : (
                        <InputBottomLabels color={colors.red} bottom="15px">
                          Add how much you have in stock
                        </InputBottomLabels>
                      )}

                      <InputWithOnchange
                        label={"Selling Price"}
                        type={"text"}
                        inputMode={"numeric"}
                        placeholder={"Selling Price"}
                        autoComplete={"off"}
                        name="retailUnitPrice"
                        bottom="4px"
                        height={"56px"}
                        background={colors.themeColor3}
                        value={formatCurrency(values.retailUnitPrice, "SA")}
                        valid={`${
                          !touched.retailUnitPrice && !errors.retailUnitPrice
                        }`}
                        errors={
                          touched &&
                          touched.retailUnitPrice &&
                          errors &&
                          errors.retailUnitPrice
                        }
                        onChange={(e) =>
                          onChangeWithFormater(e, setSellingPrice)
                        }
                        initialValues={initialValues}
                        borderColor={colors.lightGray}
                        color={colors.deepBlue}
                      />
                      {costPrice && costPrice > sellingPrice ? (
                        <InputBottomLabels color={colors.red} bottom="124px">
                          Your selling price should be higher than your cost
                          price
                        </InputBottomLabels>
                      ) : (
                        <InputBottomLabels bottom="124px">
                          How much do you want to sell the product
                        </InputBottomLabels>
                      )}

                      <FlexDiv align="initial" style={{ paddingLeft: "7px" }}>
                        <Smiley />
                        <ProfitText size="12px" left="7px">
                          Calculating your profit is easier when you add cost
                          price
                        </ProfitText>
                      </FlexDiv>

                      <ActionBlock direction={"row"} top={"0px"} bottom="10px">
                        <RippleButton
                          type="submit"
                          top="26px"
                          disabled={
                            !values.retailUnitPrice ||
                            !values.productName ||
                            !quantityVal ||
                            quantityVal < 1 ||
                            isLoading ||
                            (costPrice && costPrice > sellingPrice)
                          }
                        >
                          Update Product
                        </RippleButton>

                        <RippleButton
                          color="#2E4457"
                          top="26px"
                          backgroundColor={colors.white}
                          border={`1px solid ${colors.lightDark}`}
                          onClick={() => {
                            history.push({
                              pathname: "/actions/shop_product_details",
                              state: { product, shopId },
                            });
                          }}
                        >
                          Skip
                        </RippleButton>
                      </ActionBlock>
                    </InputBlock>
                  </Form>
                </AddProductContainer>
              );
            }}
          </Formik>
        </ScreenContainer>
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default EditSingleIncompleteProduct;
