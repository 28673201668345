import styled from "styled-components";

import { ListItem } from "../../../containers/ListContainer";
import { SubTitle, SecondaryText } from "../../../containers/MessageContainer";
import { colors } from "../../../styles";

export const PageTitle = styled.h4`
  font-size: 14px;
  font-weight: 700;
  color: ${({ color }) => color || colors.themeTextColor1};
  line-height: 18px;
`;

export const PageCount = styled.span`
  font-size: 10px;
  font-weight: 500;
  line-height: 13px;
  color: ${colors.themeColor6};
`;

export const PageList = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 16px;
  padding: 33px 16px;
  border-radius: 13px;
  border: 1px solid ${colors.gray3};
`;

export const StepsBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 21px;
`;

export const StepText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

export const Step = styled.div`
  display: flex;
  align-items: center;
`;

export const StepHeading = styled(SubTitle)`
  font-weight: 700;
  color: #071827;
  text-align: left;
  margin: 0;
`;

export const StepDetails = styled(SecondaryText)`
  margin: 6px 0 0 0;
  text-align: left;
  font-size: 14px;
  color: #718596;
`;

export const BrokenLine = styled.div`
  width: 0.5px;
  height: ${({ height }) => height || "10px"};
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 20px;
  background-color: #a0b1c0;
`;

export const InfoIntroduction = styled(SubTitle)`
  line-height: 17px;
  font-weight: 300;
  text-align: ${({ align }) => align || "center"};
  margin: ${({ margin }) => margin || "64px 0px 0px 0px"};
`;

export const SubInfoIntroduction = styled(SubTitle)`
  line-height: 17px;
  margin-top: 24px;
  font-weight: 700;
  text-align: left;
  color: #227eff;
`;

export const IconBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: ${({ bgColor }) => bgColor || "white"};
`;

export const ModifiedListItem = styled(ListItem)`
  align-items: center;
  border: none;
`;

export const HorizontalDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: ${({ left }) => left || "0px"};
`;

export const VerticalDiv = styled(HorizontalDiv)`
  flex-direction: column;
`;
