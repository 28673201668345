import * as Yup from "yup";

import { phoneRegExp } from "../../../../../utils/regexp/phoneRegExp";

export const AirtimePurchaseValidationSchema = Yup.object().shape({
  networkProvider: Yup.string().required("Required"),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .min(11, "Invalid phone number.")
    .test(
      "maxLength",
      "If starts with 0, can be maximum 11 digits",
      (value) => {
        if (value && value.trim().startsWith("0") && value.trim().length > 11) {
          return false;
        } else return true;
      }
    )
    .test(
      "minLength",
      "If doesn't start with 0, can be maximum 10 digits",
      (value) => {
        if (value && !value.startsWith("0") && value.length > 10) {
          return false;
        } else return true;
      }
    )
    .required("Required"),
});
