import * as Yup from "yup";

import { containsAllCharsExeptNumbers } from "../../../../utils/regexp/containsLettersRegExp";

export const PinCreationValidationSchema = Yup.object().shape({
  transactionPin: Yup.string()
    .matches(
      containsAllCharsExeptNumbers,
      "Pin cannot contain letters or special characters"
    )
    .nullable()
    .required("Required"),
  confirmTransactionPin: Yup.string()
    .matches(
      containsAllCharsExeptNumbers,
      "Pin cannot contain letters or special characters"
    )
    .nullable()
    .required("Required")
    .oneOf([Yup.ref("transactionPin"), null], "Pin must match"),
});
