import React, { Fragment, useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";

import EmptyResultsIcon from "../../../../../assets/empty-search-results.svg";
import LightBulbIcon from "../../../../../assets/lightbulb.svg";
import {
  PageLogo,
  TopHeader,
  OptionsPopupDialog,
  RippleButton,
} from "../../../../../components";
import { SearchInputWithCancel } from "../../../../../components/forms/input/search-input/SearchInput";
import {
  List,
  ListItem,
  ListHeading,
  ListSubHeading,
} from "../../../../../containers/ListContainer";
import {
  Message,
  Title,
  SecondaryText,
} from "../../../../../containers/MessageContainer";
import {
  FlexCenteredBlock,
  ScreenContainer,
  FilterAndSortSection,
} from "../../../../../containers/ScreenContainer";
import { getProductCategories } from "../../../../../redux/ducks/applications/my-shop/actions/shop";
import { colors } from "../../../../../styles";
import { formatPrice } from "../../../../../utils/currency/formatPriceWithComma";
import History from "../../../../../utils/History";
import {
  SHOP_START_CREATE_PRODUCT,
  MY_SHOP_HOME_INTERACTION,
} from "../../../../../utils/mix-panel/constants";
import { mixPanel } from "../../../../../utils/mix-panel/mixPanel";
import DesktopBackgroundLayout from "../../../../DesktopBackgroundLayout";

import { ReactComponent as AddIcon } from "./assets/add.svg";
import { ReactComponent as DownArrow } from "./assets/arrow.svg";
import { ReactComponent as Ascending } from "./assets/ascending.svg";
import DefaultIcon from "./assets/default-product.svg";
import { ReactComponent as Descending } from "./assets/descending.svg";
import InventoryIcon from "./assets/inventory.svg";
import { ReactComponent as QuantityIcon } from "./assets/quantity.svg";

const ListLeftImageBlock = styled.div`
  min-width: 32px;
  min-height: 32px;
  padding: 6px;
  background-color: ${colors.gray4};
  border-radius: 4px;
  margin-right: 16px;
`;

const Block = styled.div`
  display: flex;
`;

const FlexSpacedBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ backgroundColor }) => backgroundColor};
  margin-top: ${({ top }) => top};
  width: 100%;
`;

const ModifiedListItem = styled(ListItem)`
  display: flex;
  flex-direction: column;
  margin: 0 0 4px;
  padding: 12px 16px;
  background-color: ${colors.white};
  min-height: ${({ minHeight }) => minHeight || "65px"};
  overflow: hidden;
  cursor: pointer;
  -webkit-tap-highlight-color: ${colors.lightestGray};

  &:hover {
    background: transparent;
  }
`;

//Hidden because the bulk edit isn't ready

// const BulkEditText = styled(SecondaryText)`
//     text-transform: uppercase;
//     position: absolute;
//     right: 16px;
// `

const FilterSection = styled(FilterAndSortSection)`
  justify-content: initial;
`;

const FilterCell = styled.div`
  border: 1px solid ${colors.lightGray};
  padding: 6px 7px 6px 12px;
  background-color: ${colors.white};
  border-radius: 24px;
  display: flex;
  align-items: center;
  height: 30px;
  margin-right: ${({ right }) => right};
  min-width: ${({ width }) => width};
`;

const FilterText = styled(SecondaryText)`
  color: ${colors.subGrey};
  font-weight: 500;
  line-height: 18px;
  margin: 0 4px 0 0;
`;

const FilterTotal = styled.span`
  font-size: 10px;
  color: ${({ color }) => color || colors.boldDark};
  line-height: 16px;
  min-width: 16px;
  padding: 0 5px;
  height: 16px;
  background-color: ${colors.gray4};
  border-radius: 32px;
`;

const SortSection = styled.div`
  height: 40px;
  background-color: ${colors.white};
  border-top: 1px solid ${colors.gray4};
  border-bottom: 1px solid ${colors.gray4};
  display: flex;
  align-items: center;
  margin: 0 -16px;
  padding-left: 18px;
`;

const SortText = styled(SecondaryText)`
  margin: 0 8.67px 0 0;
  display: inline-block;
`;

const SortSubText = styled(SecondaryText)`
  margin: 0 0 0 5px;
  display: inline-block;
`;

const QuantityWrapper = styled.span`
  margin-left: auto;
  display: flex;
  align-items: center;

  & > svg {
    margin-right: 4px;
  }
`;

const Quantity = styled(SecondaryText)`
  display: inline-block;
  margin: 0;
  color: "${colors.subGrey}";
`;

const Container = styled.div`
  width: 100%;
`;

const RedText = styled(SecondaryText)`
  color: #ee6f44;
  display: inline-block;
  margin: 0;
`;

const ButtonContainer = styled.div`
  position: fixed;
  right: calc(50vw - 170px);
  bottom: 16px;
  width: 150px;

  @media (max-width: 576px) {
    right: 16px;
  }
`;

const StyledAddIcon = styled(AddIcon)`
  margin-right: 13px;
`;

const MoveUpContainer = styled.div`
  position: relative;
  width: inherit;
  margin: 0 auto 0;
  overflow: hidden;
  transition: all 0.3s ease-in;

  @media (min-width: 576px) {
    max-width: 375px;
  }
`;

const MoveLeftContainer = styled.div`
  transition: transform 0.2s linear;
`;

const ShopInventory = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const shops = useSelector((state) => state.applications.myShop.shops) || [];
  const shopId = location.state ? location.state : shops[0] && shops[0].id;
  const [searchValue, setSearchValue] = useState("");
  const [sortType, setSortType] = useState("A-Z");
  const [filterType, setFilterType] = useState("All");
  const [bulkEditOptionsOpen, setBulkEditOptionsOpen] = useState(false);
  const [sortOptionsOpen, setSortOptionsOpen] = useState(false);
  const inventory =
    (shops?.length && shops.find((shop) => shop.id === shopId).inventory) || [];
  const [allInventories, setAllInventory] = useState([]);
  const [isSearchResultsEmpty, setIsSearchResultsEmpty] = useState(false);
  const [outOfStock, setOutOfStock] = useState([]);
  const [incomplete, setIncomplete] = useState([]);

  useEffect(() => {
    setAllInventory(inventory);
    setOutOfStock(
      inventory.filter(
        (product) => !product.quantity && product.localSettings.hasInventory
      )
    );
    setIncomplete(
      inventory.filter(
        (product) => product.localSettings.hasInventory === false
      )
    );
  }, [inventory]);

  useEffect(() => {
    mixPanel.track(MY_SHOP_HOME_INTERACTION, {
      "Icon Name": "Inventory",
      Time: new Date().toLocaleString(),
    });
  }, []);

  useEffect(() => {
    dispatch(getProductCategories());
  }, [dispatch]);

  useEffect(() => {
    const searchResults = inventory?.filter((data) =>
      data.productName.toLowerCase().includes(searchValue.toLowerCase())
    );
    if (searchResults?.length === 0) return setIsSearchResultsEmpty(true);
    if (searchResults?.length > 0) {
      setIsSearchResultsEmpty(false);
      setAllInventory(searchResults);
    }
  }, [searchValue, inventory]);

  useEffect(() => {
    if (!inventory?.length) return;
    if (filterType === "All") {
      setAllInventory(inventory);
    } else if (filterType === "Out of stock") {
      setAllInventory(
        inventory.filter(
          (product) => !product.quantity && product.localSettings.hasInventory
        )
      );
    } else if (filterType === "Incomplete") {
      setAllInventory(
        inventory.filter((product) => !product.localSettings.hasInventory)
      );
    }
  }, [filterType, inventory]);

  useEffect(() => {
    if (!inventory?.length) return;
    if (sortType === "A-Z") {
      setAllInventory(
        inventory.sort((a, b) => a.productName.localeCompare(b.productName))
      );
      setSortOptionsOpen((sortOpen) => sortOpen === true && !sortOpen);
    } else if (sortType === "Z-A") {
      setAllInventory(
        inventory.sort((a, b) => b.productName.localeCompare(a.productName))
      );
      setSortOptionsOpen((sortOpen) => sortOpen === true && !sortOpen);
    }
  }, [sortType, inventory, setAllInventory]);

  const handleListItemClick = (product) => {
    product?.localSettings?.hasInventory
      ? history.push({
          pathname: "/actions/shop_product_details",
          state: { product, shopId },
        })
      : history.push({
          pathname: "/actions/shop_edit_single_incomplete_product",
          state: product,
        });
  };

  return (
    <Fragment>
      <DesktopBackgroundLayout>
        {!inventory?.length ? (
          <Fragment>
            <TopHeader
              title={"Products"}
              color={colors.boldDark}
              backgroundColor={colors.lightestGray}
              weight="400"
              size="12px"
              noBorderBottom
              withSpacesHeader={false}
              backLink={"/"}
            />
            <ScreenContainer color={colors.lightestGray} height="100vh">
              <FlexCenteredBlock
                top={"34px"}
                mtop="24px"
                color={colors.white}
                padding="5px 24px 47px"
                radius="8px"
              >
                <div>
                  <PageLogo
                    Icon={InventoryIcon}
                    width={"121.93px"}
                    height={"112px"}
                    iconHeight={"112px"}
                    iconWidth={"121.93px"}
                    margin={"64px auto 40px"}
                  />
                  <Title
                    weight="700"
                    color={colors.boldDark}
                    size="14px"
                    bottom="0"
                  >
                    No Products
                  </Title>
                  <Message
                    bottom={"20px"}
                    top={"0"}
                    align={"center"}
                    weight="400"
                    size="12px"
                    color={colors.subGrey}
                  >
                    You don't have any product
                  </Message>
                </div>
                <RippleButton
                  onClick={() => {
                    mixPanel.track(SHOP_START_CREATE_PRODUCT, {
                      "Entry Point": "Inventory",
                    });
                    History.push({
                      pathname: "/actions/shop_add_single_product",
                      state: { shopId },
                    });
                  }}
                  bottom="0px"
                  type={"button"}
                  top="0"
                  size="16px"
                >
                  Add Product
                </RippleButton>
              </FlexCenteredBlock>
            </ScreenContainer>
          </Fragment>
        ) : (
          <Fragment>
            <MoveUpContainer
              style={{
                transform: searchValue?.length ? "translate(0, -50px)" : null,
              }}
            >
              <TopHeader
                withSpacesHeader={false}
                title={"Products"}
                right={"56px"}
                backgroundColor={colors.lightestGray}
                size="14px"
                color={colors.boldDark}
                weight="400"
                noBorderBottom
                backLink={"/"}
              >
                {/* Hidden because the bulk edit isn't ready */}
                {/* <BulkEditText onClick={() => setBulkEditOptionsOpen(!bulkEditOptionsOpen)} color={colors.deepBlue} top='14px' weight='700' lineHeight='18px'>Bulk Edit</BulkEditText> */}
              </TopHeader>
            </MoveUpContainer>
            <ScreenContainer
              color={colors.lightestGray}
              top={searchValue?.length ? "0px" : "50px"}
              style={{ transition: "margin .3s linear" }}
              paddingBottom={"65px"}
            >
              <SearchInputWithCancel
                showCancelOnInteract={true}
                value={searchValue}
                placeholder="Search for product"
                onChange={(e) => setSearchValue(e.target.value)}
                onCancel={() => setSearchValue("")}
                onCancelInputValue={() => setSearchValue("")}
              />
              <MoveLeftContainer
                style={{
                  transform: searchValue?.length
                    ? "translate(-100vw, 0)"
                    : null,
                }}
              >
                <FilterSection top={"16px"} bottom="16px">
                  <FilterCell
                    right="8px"
                    width="65px"
                    onClick={() => setFilterType("All")}
                    style={{
                      border:
                        filterType === "All"
                          ? `2px solid ${colors.deepBlue}`
                          : null,
                    }}
                  >
                    <FilterText
                      style={{
                        color: filterType === "All" ? colors.boldDark : null,
                      }}
                    >
                      All
                    </FilterText>
                    <FilterTotal
                      style={{
                        color: filterType === "All" ? colors.gray4 : null,
                        backgroundColor:
                          filterType === "All" ? colors.deepBlue : null,
                      }}
                    >
                      {inventory?.length}
                    </FilterTotal>
                  </FilterCell>
                  <FilterCell
                    right="8px"
                    width="113px"
                    onClick={() => setFilterType("Out of stock")}
                    style={{
                      border:
                        filterType === "Out of stock"
                          ? `2px solid ${colors.deepBlue}`
                          : null,
                    }}
                  >
                    <FilterText
                      style={{
                        color:
                          filterType === "Out of stock"
                            ? colors.boldDark
                            : null,
                      }}
                    >
                      Out of stock
                    </FilterText>
                    <FilterTotal
                      style={{
                        color:
                          filterType === "Out of stock"
                            ? colors.gray4
                            : colors.red,
                        backgroundColor:
                          filterType === "Out of stock"
                            ? colors.deepBlue
                            : null,
                      }}
                    >
                      {outOfStock?.length}
                    </FilterTotal>
                  </FilterCell>
                  <FilterCell
                    width="115px"
                    onClick={() => setFilterType("Incomplete")}
                    style={{
                      border:
                        filterType === "Incomplete"
                          ? `2px solid ${colors.deepBlue}`
                          : null,
                    }}
                  >
                    <FilterText
                      style={{
                        color:
                          filterType === "Incomplete" ? colors.boldDark : null,
                      }}
                    >
                      Incomplete
                    </FilterText>
                    <FilterTotal
                      style={{
                        color: filterType === "Incomplete" ? colors.gray4 : "",
                        backgroundColor:
                          filterType === "Incomplete" ? colors.deepBlue : null,
                      }}
                    >
                      {incomplete?.length}
                    </FilterTotal>
                  </FilterCell>
                </FilterSection>

                <SortSection
                  onClick={() => setSortOptionsOpen(!sortOptionsOpen)}
                >
                  <SortText weight="500" color={colors.boldDark}>
                    Alphabet
                    <SortSubText as="span" weight="500" color={colors.subGrey}>
                      ({sortType})
                    </SortSubText>
                  </SortText>
                  <DownArrow />
                </SortSection>
              </MoveLeftContainer>
              {isSearchResultsEmpty ? (
                <FlexCenteredBlock
                  top="-80px"
                  mtop="-80px"
                  color={colors.white}
                  padding="40px 24px 47px"
                  radius="8px"
                >
                  <PageLogo
                    Icon={EmptyResultsIcon}
                    width={"126.5px"}
                    height={"105.7px"}
                    iconWidth={"126.5px"}
                    iconHeight={"105.7px"}
                    margin={"0px auto 24.3px"}
                    borderRadius="initial"
                  />
                  <Title
                    weight="700"
                    color={colors.boldDark}
                    size="14px"
                    bottom="0"
                  >
                    We couldn't find any matches
                  </Title>
                  <PageLogo
                    Icon={LightBulbIcon}
                    width={"24px"}
                    height={"24px"}
                    iconHeight={"13.33px"}
                    iconWidth={"13.33px"}
                    margin={"12px auto 7px"}
                    background={colors.lightYellowBg}
                  />
                  <Message
                    bottom={"18px"}
                    top={"0"}
                    align={"center"}
                    weight="400"
                    size="12px"
                    color={colors.subGrey}
                  >
                    Make sure the spelling is correct
                  </Message>

                  <RippleButton
                    bottom="0px"
                    onClick={() => {
                      mixPanel.track(SHOP_START_CREATE_PRODUCT, {
                        "Entry Point": "Inventory",
                      });
                      History.push({
                        pathname: "/actions/shop_add_single_product",
                        state: {
                          shopId,
                          productName: searchValue,
                        },
                      });
                    }}
                    type={"button"}
                    top="0"
                    size="16px"
                    backgroundColor="transparent"
                    color={colors.deepBlue}
                    border={`1px solid ${colors.deepBlue} `}
                    height="40px"
                  >
                    {`Create Product named '${searchValue}'`}
                  </RippleButton>
                </FlexCenteredBlock>
              ) : (
                <Fragment>
                  <List
                    fullScreen
                    style={{
                      marginTop: searchValue?.length ? "-90px" : null,
                      transition: "margin .3s linear",
                    }}
                  >
                    {allInventories?.map((product, index) => {
                      product.base64ProductImageString =
                        product.base64ProductImageString ||
                        (product.images && product.images.baseImageUrl);
                      return (
                        <ModifiedListItem
                          key={index}
                          minHeight="35px"
                          onClick={() => handleListItemClick(product)}
                        >
                          <FlexSpacedBlock>
                            <ListLeftImageBlock>
                              <img
                                src={
                                  (product.images &&
                                    product.images.baseImageUrl) ||
                                  product.base64ProductImageString ||
                                  DefaultIcon ||
                                  ""
                                }
                                onError={(e) => {
                                  e.target.src = DefaultIcon;
                                }}
                                alt="product"
                                width="24px"
                                height="24px"
                              />
                            </ListLeftImageBlock>
                            <Container>
                              <ListHeading
                                weight="400"
                                color={colors.boldDark}
                                bottom="0px"
                              >
                                {product.productName}
                              </ListHeading>
                              <Block>
                                <ListSubHeading
                                  fontSize="12px"
                                  weight="400"
                                  color={colors.boldDark}
                                  top=""
                                >
                                  {formatPrice(product.retailUnitPrice || 0)}
                                  /Unit
                                </ListSubHeading>
                                <QuantityWrapper>
                                  {product.localSettings.hasInventory && (
                                    <QuantityIcon />
                                  )}
                                  {product.quantity > 0 &&
                                    product.localSettings?.hasInventory && (
                                      <Quantity
                                        color={colors.subGrey}
                                        weight="500"
                                      >
                                        {product.quantity}
                                      </Quantity>
                                    )}
                                  {!product.quantity &&
                                    product.localSettings?.hasInventory && (
                                      <RedText weight="400">
                                        Out of stock
                                      </RedText>
                                    )}
                                  {!product.localSettings?.hasInventory && (
                                    <RedText weight="400">Incomplete</RedText>
                                  )}
                                </QuantityWrapper>
                              </Block>
                            </Container>
                          </FlexSpacedBlock>
                        </ModifiedListItem>
                      );
                    })}
                  </List>
                  <ButtonContainer>
                    <RippleButton
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                      padding="0 0 0 22px"
                      onClick={() => {
                        mixPanel.track(SHOP_START_CREATE_PRODUCT, {
                          "Entry Point": "Inventory",
                        });
                        History.push({
                          pathname: "/actions/shop_add_single_product",
                          state: { shopId },
                        });
                      }}
                      type={"button"}
                      radius="44px"
                    >
                      <StyledAddIcon />
                      Add Product
                    </RippleButton>
                  </ButtonContainer>
                </Fragment>
              )}
            </ScreenContainer>
          </Fragment>
        )}

        <OptionsPopupDialog
          open={sortOptionsOpen}
          title={"Sort"}
          cancel={() => {
            setSortType("A-Z");
            setSortOptionsOpen(!sortOptionsOpen);
          }}
          items={[
            {
              Icon: Descending,
              title: "A-Z",
              click: () => sortType !== "A-Z" && setSortType("A-Z"),
            },
            {
              Icon: Ascending,
              title: "Z-A",
              click: () => sortType !== "Z-A" && setSortType("Z-A"),
            },
          ]}
        />
        <OptionsPopupDialog
          open={bulkEditOptionsOpen}
          title={"Bulk Edit"}
          cancel={() => {
            setBulkEditOptionsOpen(!bulkEditOptionsOpen);
          }}
          items={[
            {
              Icon: Descending,
              title: "Bulk Edit Price",
              click: () => history.push("/actions/shop_inventory_edit"),
            },
            {
              Icon: Ascending,
              title: "Bulk Stock Update",
            },
          ]}
        />
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default ShopInventory;
