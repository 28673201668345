import React, { Fragment, useEffect, useState } from "react";

import { FieldArray, Form, Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";

import {
  ConfirmPopupDialog,
  InputWithLabel,
  Loader,
  SelectBox,
  TextareaWithLabel,
  TopHeader,
  UploadPicture,
} from "../../../../../components";
import {
  LightRippleButton,
  RippleButton,
} from "../../../../../components/button";
import { ReactComponent as SelectArrow } from "../../../../../components/forms/input/select/assets/arrow.svg";
import {
  ImageCropper,
  toDataURL,
} from "../../../../../components/popup/image-cropper";
import { InputBlock } from "../../../../../containers/InputContainer";
import { Message } from "../../../../../containers/MessageContainer";
import {
  FlexContainer,
  ScreenContainer,
  FlexDiv,
  SectionContainer,
} from "../../../../../containers/ScreenContainer";
import { productUnits } from "../../../../../data/products/units";
import * as actions from "../../../../../redux/ducks/applications/my-shop/actions/shop";
import { colors } from "../../../../../styles";
import { formatPrice } from "../../../../../utils/currency/formatPriceWithComma";
import { toTitleCase } from "../../../../../utils/toTitleCase";
import DesktopBackgroundLayout from "../../../../DesktopBackgroundLayout";
import { ReactComponent as CircularInfoIcon } from "../../assets/circular_info.svg";
import { ReactComponent as FormGroupSvg } from "../../assets/form_group.svg";
import { ReactComponent as InfoIcon } from "../../assets/info_big.svg";
import { ReactComponent as TrachSvg } from "../../assets/trash.svg";
import { ReactComponent as TrachSMSvg } from "../../assets/trash_sm.svg";
import { AddProductCategory } from "../../components/add-product-category";
import { InfoMessage } from "../../group-buy/styles";

import { EditProductValidationSchema } from "./EditProductValidationSchema";

const MoqWrapper = styled.div`
  position: relative;
`;

const FormGroupIcon = styled(FormGroupSvg)`
  position: absolute;
  z-index: 1;
  top: 22px;
  left: 5px;
`;

const ProductConfigWrapper = styled.div`
  border: 1px solid #eeeeee;
  border-radius: 13px;
  padding: 16px;
  margin: 24px 0;
`;

const TrashIcon = styled(TrachSvg)`
  right: 0;
  position: absolute;
  margin-right: 16px;
`;

const NewRuleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DropDownWrapper = styled.div`
  background: #f2f5fa;
  border-radius: 8px;
  border: 1px solid #eeeeee;
  align-items: center;
  display: flex;
  height: 48px;
  padding: 0 15px;
  justify-content: space-between;
`;

const EditProduct = ({ shopId, product, setShowEditPopup }) => {
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.applications.myShop.isLoading);
  const categories = useSelector(
    (state) => state.applications.merchbuy.merchbuyProductCategeries
  );
  const [selectedCategory, setSelectedCategory] = useState("");

  const [showCropper, setShowCropper] = useState(false);
  const [showProductCategory, setShowProductCategory] = useState(false);
  const [picture, setPicture] = useState();
  const [formName, setFormName] = useState();
  const [pImages, setPImages] = useState({});

  useEffect(() => {
    product &&
      product.images &&
      setPImages({
        ...pImages,
        upload0:
          product.images.baseImageUrl || product.base64ProductImageString,
        upload1: product.images.others && product.images.others[0],
        upload2: product.images.others && product.images.others[1],
      });

    if (product.productCategory) {
      const category = categories.find((x) => x.id === product.productCategory);
      setSelectedCategory(category);
    }
    // eslint-disable-next-line
  }, [product]);

  const onImageRemove = (formName) => {
    const images = { ...pImages };
    delete images[formName];

    const entries = Object.keys(images).flatMap((key) => [images[key]]);
    var newEntry = {};
    for (let i = 0; i < entries.length; i++) {
      newEntry = { ...newEntry, [`upload${i}`]: entries[i] };
    }
    setPImages(newEntry);
  };

  const pAction = (formName, image) => {
    setPicture(image);
    setFormName(formName);
    setShowCropper(true);
  };

  const onCropperCancel = () => {
    setShowCropper(false);
  };

  const onCropperSave = async (url) => {
    if (url) {
      const image = await toDataURL(url);
      setPImages({ ...pImages, [formName]: image });
      setShowCropper(false);
    } else {
      toast.error("Image is still processing, wait for a sec...");
    }
  };

  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] =
    useState(false);
  return isLoading ? (
    <Loader />
  ) : (
    <Fragment>
      <DesktopBackgroundLayout>
        <TopHeader
          backLink="#"
          backAction={() => {
            setShowEditPopup(false);
          }}
          title={
            product && product.productCategory
              ? "Edit product"
              : "Configure product"
          }
        >
          {product && product.productCategory && (
            <TrashIcon onClick={() => setShowDeleteConfirmationDialog(true)} />
          )}
        </TopHeader>
        <ScreenContainer top="50px">
          <Formik
            initialValues={{
              productName: product.productName || "",
              productDescription: product.productDescription || "",
              productCategory: "",
              productUnit: product.productUnit || "",
              moq: product.bulkPrices.length ? product.bulkPrices[0].moq : "",
              bulkPrices: product.bulkPrices.length
                ? product.bulkPrices
                : [{ price: "", moq: "" }],
              base64ProductImageString: "",
              otherImagesBase64String: [],
              images: {
                baseImageUrl: "",
                others: [],
              },
            }}
            enableReinitialize
            validationSchema={EditProductValidationSchema}
            onSubmit={async (values) => {
              let images = Object.entries(pImages).map(
                (entries) => entries && entries[1]
              );
              values = {
                ...product,
                productName: toTitleCase(values.productName),
                productDescription: values.productDescription,
                productUnit: values.productUnit,
                moq: values.moq,
                bulkPrices: values.bulkPrices,
                base64ProductImageString: (images && images[0]) || "",
                otherImagesBase64String: [
                  (images && images[1]) || "",
                  (images && images[2]) || "",
                ],
                images: {
                  baseImageUrl: (images && images[0]) || "",
                  others: [
                    (images && images[1]) || "",
                    (images && images[2]) || "",
                  ],
                },
                productCategory: selectedCategory.id,
                availableAtWholesalePrice: true,
              };
              const res = await dispatch(
                actions.updateProductV2(shopId, product.id, values)
              );
              res && setShowEditPopup();
            }}
          >
            {({
              errors,
              touched,
              values,
              setFieldValue,
              handleChange,
              initialValues,
              isValid,
            }) => (
              <Form>
                <InputBlock>
                  <InputWithLabel
                    label={"Product Name"}
                    type={"text"}
                    placeholder={"Product Name"}
                    autoComplete={"off"}
                    name="productName"
                    bottom="24px"
                    height={"56px"}
                    background={"#FFFFFF"}
                    value={values.productName}
                    valid={`${!touched.productName && !errors.productName}`}
                    errors={
                      touched &&
                      touched.productName &&
                      errors &&
                      errors.productName
                    }
                    onChange={(e) =>
                      setFieldValue("productName", e.target.value)
                    }
                    onKeyUp={(e) => {}}
                    // onFocus={e => { setOpenMasterList(!openMasterList) }}
                    noClearButton={true}
                    initialValues={initialValues}
                  />
                  <FlexContainer justifyContent="flex-end">
                    <FlexDiv>
                      <InfoIcon
                        style={{
                          width: "10px",
                          height: "10px",
                          marginRight: "4px",
                        }}
                      />
                      <Message color={"#212c3d99"} top="0" size="10px">
                        Upload 1 or more images
                      </Message>
                    </FlexDiv>
                  </FlexContainer>
                  <SectionContainer>
                    <FlexContainer
                      style={{ justifyContent: "center", marginBottom: "8px" }}
                    >
                      <UploadPicture
                        text={"Upload"}
                        pictureAction={pAction}
                        formName={"upload0"}
                        version2={true}
                        removeImage={onImageRemove}
                        picture={pImages && pImages["upload0"]}
                      />
                      <UploadPicture
                        text={"Upload"}
                        pictureAction={pAction}
                        formName={"upload1"}
                        version2={true}
                        removeImage={onImageRemove}
                        picture={pImages && pImages["upload1"]}
                        disabled={pImages && !pImages["upload0"]}
                      />
                      <UploadPicture
                        text={"Upload"}
                        pictureAction={pAction}
                        formName={"upload2"}
                        version2={true}
                        removeImage={onImageRemove}
                        picture={pImages && pImages["upload2"]}
                        disabled={
                          pImages &&
                          (!pImages["upload0"] || !pImages["upload1"])
                        }
                      />
                    </FlexContainer>
                    <FlexDiv align={"center"}>
                      <InfoIcon
                        style={{
                          width: "10px",
                          height: "10px",
                          marginRight: "4px",
                        }}
                      />
                      <Message color={"#212c3d99"} top="0" size="10px">
                        Not more than 2MB and in jpeg, png or gif formart
                      </Message>
                    </FlexDiv>
                  </SectionContainer>
                  <DropDownWrapper onClick={() => setShowProductCategory(true)}>
                    <Message
                      opacity={selectedCategory ? null : "50%"}
                      color={colors.themeTextColor3}
                      top="0"
                    >
                      {selectedCategory
                        ? selectedCategory.name
                        : "Select a product category"}
                    </Message>
                    <SelectArrow />
                  </DropDownWrapper>
                  <SelectBox
                    name={"productUnit"}
                    placeholder={"What unit is the product sold in?"}
                    value={values.productUnit}
                    options={productUnits}
                    handleChange={handleChange}
                    valid={`${!touched.productUnit && !errors.productUnit}`}
                    error={
                      touched &&
                      touched.productUnit &&
                      errors &&
                      errors.productUnit
                    }
                  />
                  <TextareaWithLabel
                    name={"productDescription"}
                    errorTop="41px"
                    value={values.productDescription}
                    placeholder={"Enter the description of this product"}
                    height={"96px"}
                    valid={`${
                      !touched.productDescription && !errors.productDescription
                    }`}
                    errors={
                      touched &&
                      touched.productDescription &&
                      errors &&
                      errors.productDescription
                    }
                    onChange={(e) =>
                      setFieldValue("productDescription", e.target.value)
                    }
                    onKeyUp={(e) => {}}
                  />
                  <InputWithLabel
                    label={"Minimum order quantity"}
                    type={"decimal"}
                    autoComplete={"off"}
                    value={values.moq}
                    inputMode={"numeric"}
                    placeholder={"Minimum order quantity"}
                    name="moq"
                    valid={`${!touched.moq && !errors.moq}`}
                    errors={touched && touched.moq && errors && errors.moq}
                    onKeyUp={(e) =>
                      setFieldValue("bulkPrices[0].moq", e.target.value)
                    }
                    noClearButton={true}
                  />
                  <InfoMessage>
                    <CircularInfoIcon
                      style={{ minWidth: "32px", marginRight: "8px" }}
                    />
                    <Message weight="500" size="12px" color="#579fd7" top={"0"}>
                      This is the smallest number of products a buy can order.
                    </Message>
                  </InfoMessage>
                  <ProductConfigWrapper>
                    <InfoMessage bottom="16px">
                      <CircularInfoIcon
                        style={{ minWidth: "32px", marginRight: "8px" }}
                      />
                      <Message
                        weight="500"
                        size="12px"
                        color="#579fd7"
                        top={"0"}
                      >
                        This is the price you set for this product based on the
                        quantity the customer orders. E.g. 4 cartons of indomie
                        = KES1,200.00
                      </Message>
                    </InfoMessage>
                    <FieldArray name="bulkPrices">
                      {({ push, pop }) => (
                        <Fragment>
                          {values.bulkPrices.map((bulkPrice, index) => (
                            <Fragment key={index}>
                              {index !== 0 && (
                                <NewRuleWrapper>
                                  <Message top="0" size="12px">
                                    NEW RULE
                                  </Message>
                                  <TrachSMSvg onClick={() => pop()} />
                                </NewRuleWrapper>
                              )}
                              <MoqWrapper>
                                <FormGroupIcon />
                                <InputWithLabel
                                  showError={false}
                                  label={"Wholesale unit price"}
                                  inputMode={"decimal"}
                                  type={"number"}
                                  value={bulkPrice.price}
                                  placeholder={"Wholesale unit price"}
                                  name={`bulkPrices[${index}].price`}
                                  onBlur={(e) =>
                                    (e.target.value = formatPrice(
                                      bulkPrice.price || 0
                                    ))
                                  }
                                  onFocus={(e) =>
                                    (e.target.value = bulkPrice.price)
                                  }
                                  onChange={handleChange}
                                  noClearButton={true}
                                  onKeyUp={(e) =>
                                    (e.target.value = String(
                                      e.target.value
                                    ).startsWith("0")
                                      ? null
                                      : e.target.value)
                                  }
                                  onKeyPress={(e) => {
                                    if (e.charCode === 46) e.preventDefault();
                                  }}
                                  borderColor={
                                    errors.bulkPrices &&
                                    errors.bulkPrices[index] &&
                                    errors.bulkPrices[index].price
                                      ? "red"
                                      : ""
                                  }
                                />
                                <InputWithLabel
                                  showError={false}
                                  label={"Quantity"}
                                  inputMode={"decimal"}
                                  type={"number"}
                                  value={bulkPrice.moq}
                                  placeholder={"Quantity"}
                                  name={`bulkPrices[${index}].moq`}
                                  onFocus={(e) =>
                                    (e.target.value = bulkPrice.moq)
                                  }
                                  onChange={handleChange}
                                  noClearButton={true}
                                  onKeyUp={(e) => {
                                    setFieldValue("moq", e.target.value);
                                    e.target.value = String(
                                      e.target.value
                                    ).startsWith("0")
                                      ? null
                                      : e.target.value;
                                  }}
                                  onKeyPress={(e) => {
                                    if (e.charCode === 46) e.preventDefault();
                                  }}
                                  borderColor={
                                    errors.bulkPrices &&
                                    errors.bulkPrices[index] &&
                                    errors.bulkPrices[index].moq
                                      ? "red"
                                      : ""
                                  }
                                />
                              </MoqWrapper>
                            </Fragment>
                          ))}
                          <LightRippleButton
                            type="button"
                            onClick={() => push({ price: "", moq: "" })}
                            disabled={false}
                            top={"8px"}
                            color={colors.blue2}
                          >
                            Add new pricing rule
                          </LightRippleButton>
                        </Fragment>
                      )}
                    </FieldArray>
                  </ProductConfigWrapper>
                  <RippleButton
                    type="submit"
                    disabled={!isValid}
                    top={"0px"}
                    color={"white"}
                  >
                    {product.productCategory ? "Update product" : "Add product"}
                  </RippleButton>
                </InputBlock>
              </Form>
            )}
          </Formik>
        </ScreenContainer>
        {showProductCategory && (
          <AddProductCategory
            category={setSelectedCategory}
            setOpen={() => {
              setShowProductCategory(false);
            }}
            open={showProductCategory}
          />
        )}
        {showCropper && (
          <ImageCropper
            avatar={picture}
            onCancel={onCropperCancel}
            onSave={onCropperSave}
          />
        )}
        <ConfirmPopupDialog
          open={showDeleteConfirmationDialog}
          title="Are you sure you want to delete this product from your MerchBuy storefront?"
          confirmationText="Deleting will mean recreating it when you want to sell it on MerchBuy again. "
          answers={[
            {
              variant: "No",
              action: () => setShowDeleteConfirmationDialog(false),
            },
            {
              variant: "Yes",
              action: () => {
                setShowDeleteConfirmationDialog(false);
                setShowEditPopup(false);
                dispatch(actions.deleteProduct(shopId, product.id, false));
              },
            },
          ]}
        />
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default EditProduct;
