import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";

import { axios, currentAPI } from "../../../../../../config";
import * as constants from "../../constants";

const baseUrl = `${currentAPI}/api/eazymoni/cableTV`;

export const getCabelProviders = () => async (dispatch) => {
  dispatch({ type: constants.LOADING_CABLE_PROVIDERS });

  try {
    const url = `${baseUrl}/getProviders`;
    const cabelProvidersResponse = await axios.get(url);

    if (cabelProvidersResponse.status === 200) {
      dispatch({
        type: constants.SET_PROVIDERS,
        payload: cabelProvidersResponse.data.responseData,
      });
      return cabelProvidersResponse.data.responseData;
    }
  } catch (error) {
    dispatch({ type: constants.PAYMENT_ERROR, payload: error.message });
  }
};

export const getSubscriptionPlansByProviderId =
  (tvProviderId) => async (dispatch) => {
    dispatch({ type: constants.PAYMENT_LOADING });
    try {
      const url = `${baseUrl}/getTvProviderSubscriptionPlansById?tvProviderId=${tvProviderId}`;
      const subscriptionPlansResponse = await axios.get(url);

      if (subscriptionPlansResponse.status === 200) {
        dispatch({
          type: constants.SET_PROVIDERS_ITEMS,
          payload: subscriptionPlansResponse.data.responseData,
        });
        return subscriptionPlansResponse.data.responseData;
      }
    } catch (error) {
      dispatch({ type: constants.PAYMENT_ERROR, payload: error.message });
    }
  };

export const cabelSubscriptionPurchase =
  (amount, billerId, paymentItemCode, requesterId) => async (dispatch) => {
    dispatch({ type: constants.PAYMENT_LOADING });

    try {
      const url = `${baseUrl}/subscriptionPurchase`;
      const purchaseResponse = await axios.post(url, {
        amount,
        billerId,
        paymentItemCode,
        requesterId,
        transactionRef: uuid(),
      });

      if (purchaseResponse.status === 200) {
        dispatch({ type: constants.PAYMENT_SUCCESS });
        return purchaseResponse.status;
      }
    } catch (error) {
      dispatch({ type: constants.PAYMENT_ERROR, payload: error.message });
      if (error.response && error.response.status === 400) {
        const { data } = (error && error.response) || {};
        data.message && toast.error(error.response.data.message);
      }
    }
  };

export const validateCabelCustomer =
  (billerId, paymentItemCode, requesterId, setErrors) => async (dispatch) => {
    try {
      dispatch({ type: constants.PAYMENT_LOADING });

      const url = `${baseUrl}/ValidateCustomer`;
      const validateCustomerResponse = await axios.post(url, {
        billerId,
        paymentItemCode,
        requesterId,
      });

      dispatch({ type: constants.PAYMENT_SUCCESS });
      if (!validateCustomerResponse.data.responseData.fullName) {
        dispatch({
          type: constants.PAYMENT_ERROR,
          payload: validateCustomerResponse.data.message,
        });
        toast.error("Decoder number was not found");
        return false;
      }
      return validateCustomerResponse.data.responseData;
    } catch (error) {
      dispatch({ type: constants.PAYMENT_ERROR, payload: error.message });

      if (error.response && error.response.status === 400) {
        const { data } = (error && error.response) || {};
        data.message && toast.error(error.response.data.message);
        setErrors({ name: "Value is not valid" });
      }

      return false;
    }
  };
