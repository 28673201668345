import React from "react";

import moment from "moment";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as DownloadSvg } from "../../assets/download.svg";
import { SubTitle } from "../../containers/MessageContainer";
import { SectionWrapper } from "../../containers/ScreenContainer";
import { colors } from "../../styles";
import { RippleButton } from "../button";

const InfoWrapper = styled.div`
  background-color: ${colors.white};
  min-height: 53px;
  margin: ${({ margin }) => margin || "16px 0"};
  padding: 16px;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ bottom }) => bottom || null};
  margin-top: ${({ top }) => top || "4px"};
  justify-content: ${({ justify }) => justify || "space-between"};
`;

const Text = styled.p`
  font-weight: ${({ fontWeight }) => fontWeight || "normal"};
  width: ${({ width }) => width || null};
  font-size: ${({ font }) => font || "14px"};
  color: ${({ color }) => color || null};
  margin: ${({ margin }) => margin || "0"};
  overflow-wrap: ${({ wrap }) => wrap || null};
`;

const AnchorLink = styled.a``;

const LightDarkText = styled(Text)`
  color: ${colors.lightDark};
`;

const BoldDarkText = styled(Text)`
  color: ${colors.boldDark};
`;

const ComplaintDetails = ({ details, files }) => {
  const history = useHistory();

  return (
    <>
      <SectionWrapper padding="1px 0 7px" bgcolor={colors.lightestGray}>
        <SubTitle
          weight={"700"}
          font={"12px"}
          color={colors.lightDark}
          textTransform={"uppercase"}
          lineHeight={"18px"}
          top={"22px"}
          bottom={"12px"}
        >
          Complaint Details
        </SubTitle>
      </SectionWrapper>
      <InfoWrapper margin={"0 -16px"}>
        <FlexWrapper bottom={"24px"}>
          <LightDarkText>Status</LightDarkText>
          {details?.statusType === "Closed" ||
          details?.statusType === "Solved" ? (
            <Text color={colors.textGreen}>Resolved</Text>
          ) : (
            <Text color={colors.textRed}>Opened</Text>
          )}
        </FlexWrapper>
        <FlexWrapper bottom={"24px"}>
          <LightDarkText>Date Created</LightDarkText>
          <BoldDarkText>
            {moment(details?.createdTime).format("lll")}
          </BoldDarkText>
        </FlexWrapper>

        <LightDarkText>Description</LightDarkText>
        <BoldDarkText margin="4px 0 24px" wrap={"break-word"}>
          {details?.description}
        </BoldDarkText>
        {details?.closedTime && (
          <FlexWrapper bottom={"24px"}>
            <LightDarkText>Date Resolved</LightDarkText>
            <BoldDarkText>
              {moment(details?.closedTime).format("lll")}
            </BoldDarkText>
          </FlexWrapper>
        )}
        {details?.assignee && (
          <FlexWrapper bottom={"24px"}>
            <LightDarkText>Support</LightDarkText>
            <BoldDarkText>{`${details?.assignee?.firstName} ${details?.assignee?.lastName}`}</BoldDarkText>
          </FlexWrapper>
        )}
        {files?.length > 0 && (
          <>
            <FlexWrapper>
              <LightDarkText>Uploaded file</LightDarkText>
              <AnchorLink
                href={files[0]?.imageDownloadUrl}
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                <DownloadSvg />
              </AnchorLink>
            </FlexWrapper>
            <BoldDarkText>{files?.[0]?.name}</BoldDarkText>
          </>
        )}
      </InfoWrapper>
      <RippleButton
        onClick={() => {
          history.push({
            pathname: "/actions/view_comment",
            state: {
              reference: details?.id,
              ticketId: details?.id,
            },
          });
        }}
      >
        Comment
      </RippleButton>
    </>
  );
};

export default ComplaintDetails;
