import React, { useEffect, useState } from "react";

import { gameService } from "../../services";
import Loader from "../../views/loader";
import PreviousWinner from "../../views/previousWinners";

const PreviousWinnerContainer = () => {
  const [loading, setLoading] = useState(false);
  const [winners, setWinners] = useState([]);

  const getTopUsers = () => {
    setLoading(true);
    gameService.getTopUsers().then((res) => {
      setLoading(false);
      if (res.status === 200) {
        setWinners(res?.data);
      }
    });
  };

  useEffect(() => {
    getTopUsers();
  }, []);

  return (
    <>
      {loading && <Loader loading={loading} />}
      <PreviousWinner winners={winners} />
    </>
  );
};

export default PreviousWinnerContainer;
