import React, { useState } from "react";

import { bool, func, string } from "prop-types";
import QRCode from "qrcode.react";
import styled from "styled-components";

import { currentHost } from "../../../config/API";
import { SubTitle } from "../../../containers/MessageContainer";
import { SlidingOverlay } from "../../../containers/OverlayContainer";
import {
  ViewContainer,
  FlexCenteredBlock,
  Container,
} from "../../../containers/ScreenContainer";
import { colors } from "../../../styles";
import { UserAvatar } from "../../avatar";
import { TopHeader } from "../../header";
import { ButtonWithShareIcon } from "../../index";
import { SharePopup } from "../../popup";

import { ReactComponent as QrcodeBorder } from "./assets/qr-code-border.svg";
import { ReactComponent as ShareIcon } from "./assets/share-icon.svg";

const QrShareBody = styled(ViewContainer)`
  height: 100%;
`;

const QrShareBodyWrapper = styled.div`
  margin: 35px 24px 0 24px;

  @media (min-width: 576px) {
    margin-top: 85px;
  }
`;

const QrCodeWrapper = styled.div`
  position: relative;
  background-color: #e9f2ff;
  padding: 44px 28px;
  border-radius: 13px 13px 0 0;

  & > svg {
    position: relative;

    &:first-child {
      left: calc(50% - 110px);
    }

    &:last-child {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

const UserDataWrapper = styled(FlexCenteredBlock)`
  padding: 32px 56px;
  background-color: ${colors.deepBlue};
  border-radius: 0 0 13px 13px;
`;

const UserInnerWrapper = styled(FlexCenteredBlock)`
  padding: 16px 36px;
  background-color: #fff;
  border-radius: 8px;
  text-align: center;
`;

const UserName = styled(SubTitle)`
  font-weight: 500;
  margin-top: 8px;
  line-height: 17px;
`;

export const QrShare = ({
  open,
  cancel,
  referralCode,
  fullName,
  avatar,
  backLink,
}) => {
  const [openSharePopup, setOpenSharePopup] = useState(false);
  const marketingMessage = `Hello, \n\nI am inviting you to join spaces, where you can create, manage and grow your store. \n\nVisit ${currentHost}/launch?referralCode=${referralCode}. \n\nKindly use my referral code ${referralCode} while signing up.`;

  return (
    open && (
      <SlidingOverlay>
        <TopHeader
          size="14px"
          weight="400"
          color="#071827"
          noBorderBottom
          title={"QR Code"}
          backAction={cancel}
          backLink={backLink}
        />
        <QrShareBody>
          <QrShareBodyWrapper>
            <QrCodeWrapper>
              <QrcodeBorder />
              <QRCode
                renderAs={"svg"}
                value={referralCode}
                bgColor={"transparent"}
                fgColor={"#227EFF"}
              />
            </QrCodeWrapper>
            <UserDataWrapper>
              <UserInnerWrapper>
                <UserAvatar width={"48px"} height={"48px"} avatar={avatar} />
                <UserName>{fullName}</UserName>
              </UserInnerWrapper>
            </UserDataWrapper>
          </QrShareBodyWrapper>
          <Container>
            <ButtonWithShareIcon
              radius="4px"
              icon={<ShareIcon />}
              top="95px"
              onClick={() => setOpenSharePopup(!openSharePopup)}
            />
          </Container>
        </QrShareBody>
        {openSharePopup && (
          <SharePopup
            url={`${currentHost}/launch?referralCode=${referralCode}`}
            marketingMessage={marketingMessage}
            open={openSharePopup}
            setOpen={setOpenSharePopup}
          />
        )}
      </SlidingOverlay>
    )
  );
};

QrShare.propTypes = {
  open: bool,
  setOpen: func,
  referralCode: string,
  fullName: string,
  avatar: string,
};
