import React, { Fragment, useState, useEffect } from "react";

import { useSelector } from "react-redux";
import styled from "styled-components";

import { Badge } from "../../../containers/BadgeContainer";
import { colors } from "../../../styles";
import { breakUpFormatPrice } from "../../../utils/currency/formatPriceWithComma";
import { LottieControl } from "../../lottie";

import animationData from "./assets/balance_loader.json";
import { ReactComponent as PayBillsIcon } from "./assets/pay-bills.svg";
import { ReactComponent as AirtimeIcon } from "./assets/phone.svg";
import { ReactComponent as TopupIcon } from "./assets/topup.svg";
import { ReactComponent as TransferIcon } from "./assets/transfer.svg";

const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const WalletBalanceWrapper = styled.span`
  display: flex;
`;

const WalletBalance = styled.h3`
  display: flex;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #ffffff;
  padding: 3px 0px;
  align-self: ${({ align }) => align || "flex-start"};
`;

const WalletBalanceAmount = styled.h3`
  margin-left: 3px;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
`;

const Dot = styled.span`
  height: 6px;
  width: 6px;
  background-color: #ffffff;
  border-radius: 50%;
  display: inline-block;
  margin-right: 1px;
  margin-left: 1px;
`;

const WalletBalanceTitle = styled.h6`
  font-size: 12px;
  margin-bottom: 4px;
  color: #ffffff;
  font-weight: 400;
  line-height: 18px;
`;

const IconWrapper = styled.div`
  display: flex;
  margin: 32px 0;
  justify-content: space-around;
  align-items: center;
`;

const IconText = styled.h6`
  font-size: 12px;
  font-weight: 400;
  color: 071827;
  margin-top: 10px;
`;

const TopRightCorner = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const ClickableItem = styled.div`
  cursor: pointer;
`;

const WalletBadge2 = ({
  classNames,
  addMoney,
  sendMoney,
  buyAirtime,
  payBills,
}) => {
  const fetchingWalletBalance = useSelector(
    (state) => state.account.wallet.fetchingWalletBalance
  );
  const balance = useSelector((state) => state.account.wallet.balance);
  const hideBalance = useSelector((state) => state.account.wallet.hideBalance);
  const [amount, setAmount] = useState([]);

  useEffect(() => {
    setAmount(breakUpFormatPrice(balance || 0));
  }, [balance]);

  return (
    <Fragment>
      <Badge
        boxShadow
        height="96px"
        background={colors.background.walletBadge}
        backgroundImage={
          "linear-gradient(94.68deg, #227eff 27.32%, #7e42f5 72.68%)"
        }
        justifyContent="center"
      >
        <FlexContainer>
          <WalletBalanceTitle>Wallet Balance</WalletBalanceTitle>
          {hideBalance ? (
            <WalletBalanceAmount>
              <Dot />
              <Dot />
              .
              <Dot />
              <Dot />
            </WalletBalanceAmount>
          ) : (
            <WalletBalanceWrapper>
              <WalletBalance>R</WalletBalance>
              <WalletBalanceAmount>{balance}.</WalletBalanceAmount>
              <WalletBalance align={"flex-end"}>{amount[2]}</WalletBalance>
            </WalletBalanceWrapper>
          )}
        </FlexContainer>
        {fetchingWalletBalance && (
          <TopRightCorner>
            <LottieControl
              height={40}
              width={40}
              play={fetchingWalletBalance}
              animationData={animationData}
            />
          </TopRightCorner>
        )}
      </Badge>
      <IconWrapper>
        <ClickableItem>
          <FlexContainer onClick={addMoney}>
            <TopupIcon />
            <IconText>Top Up</IconText>
          </FlexContainer>
        </ClickableItem>
        <ClickableItem>
          <FlexContainer onClick={sendMoney}>
            <TransferIcon />
            <IconText>Transfer</IconText>
          </FlexContainer>
        </ClickableItem>
        <ClickableItem>
          <FlexContainer onClick={buyAirtime}>
            <AirtimeIcon />
            <IconText>Airtime</IconText>
          </FlexContainer>
        </ClickableItem>
        <ClickableItem>
          <FlexContainer onClick={payBills}>
            <PayBillsIcon />
            <IconText>Pay Bills</IconText>
          </FlexContainer>
        </ClickableItem>
      </IconWrapper>
    </Fragment>
  );
};

export default WalletBadge2;
