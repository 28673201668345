import React from "react";

import InfiniteScroll from "react-infinite-scroll-component";

import { SearchingLoader } from "../loader";

export const InfiniteScrollList = ({
  data,
  children,
  fetchMore,
  hasMore,
  endMessage,
}) => {
  return (
    <InfiniteScroll
      dataLength={data.length}
      next={fetchMore}
      hasMore={hasMore}
      loader={data.length > 0 ? <SearchingLoader /> : <></>}
      endMessage={
        <p
          style={{
            textAlign: "center",
            fontWeight: "500",
            fontSize: "14px",
          }}
        >
          {endMessage}
        </p>
      }
      style={{ overflowX: "hidden" }}
    >
      {children}
    </InfiniteScroll>
  );
};
