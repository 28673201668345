import React from "react";

import styled from "styled-components";

import { RippleLink } from "../../../../../components";
import { ListItem } from "../../../../../containers/ListContainer";
import { formatPrice } from "../../../../../utils/currency/formatPriceWithComma";
import { parseMonthDateYear } from "../../../../../utils/date/parseDateFromStr";
import {
  OrderNumber,
  PaymentAmount,
  Divider,
  Text,
  Container,
} from "../../styles";

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CardListItem = styled(ListItem)`
  padding: 0px;
  align-items: center;
  margin: 1px -1em;
`;

const PaymentCard = ({ order, noBorderBottom }) => {
  return (
    <RippleLink
      to={{
        pathname: "/actions/remittance/remittance-order-details",
        state: order,
      }}
    >
      <CardListItem noBorderBottom={noBorderBottom}>
        <Container padding={"14px 16px"}>
          <FlexContainer>
            <OrderNumber>{`Order No. ${order?.orderNumber}`}</OrderNumber>
            <PaymentAmount>
              {formatPrice(order?.outstandingAmount || 0)}
            </PaymentAmount>
          </FlexContainer>
          <FlexContainer>
            <Text top="4px">{`Customer: ${order?.customer?.name || ""}`}</Text>
            <Text top="4px">{`Total value: ${formatPrice(
              order?.deliveredAmount || 0
            )}`}</Text>
          </FlexContainer>
          <Divider />
          <Text>
            {order?.orderDate &&
              `Created: ${parseMonthDateYear(order?.orderDate)}`}
          </Text>
        </Container>
      </CardListItem>
    </RippleLink>
  );
};

export default PaymentCard;
