import React, { useMemo } from "react";

import { useField } from "formik";
import { string, bool } from "prop-types";
import styled from "styled-components";

import {
  FormMetaData,
  InputLabel,
  InputLabelBlock,
  InputLabelContainer,
  TextInputField,
} from "../../../../containers/NewInputContainer";
import {
  formatCurrency,
  formatDecimalCurrency,
  decimalPadOutput,
} from "../../../../utils/currency/parseBalance";

const CurrencySymbol = styled.span`
  color: #6f798b;
  margin-right: 4px;
  margin-top: ${({ marginTop }) => marginTop || "0"};
  transition: all 0.3s ease;
`;

const AmountInputLabelBlock = styled(InputLabelBlock)`
  display: flex;
  align-items: ${({ inputIsEmpty }) => (inputIsEmpty ? "center" : "baseline")};
  transition: all 0.3 ease-in-out;
`;

const AmountInputLabel = styled(InputLabel)`
  left: 32px;
`;

export const NewAmountInputWithLabel = ({
  label,
  minHeight,
  description,
  allowDecimal,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);

  const { error, touched } = meta;
  const { setValue } = helpers;
  const { value: inputValue } = field;

  const formHasError = useMemo(
    () => touched && error && error.length > 0,
    [touched, error]
  );

  const stripAllString = (input) => input.replace(/\D/g, "");

  const formatInput = () => {
    if (allowDecimal) {
      return formatDecimalCurrency(inputValue);
    }
    return formatCurrency(stripAllString(String(inputValue)));
  };

  const onKeyUp = ({ target }) => {
    let value = "";

    if (allowDecimal) {
      value = decimalPadOutput(target.value);
    } else {
      value = parseInt(stripAllString(target.value) || 0, 10);
    }

    setValue(value ? value : "");
  };

  const formattedValueWithCurrency = formatInput();
  const [, ...amountWithoutCurrency] = formattedValueWithCurrency
    .replace("R", "")
    .split("");
  const amount = amountWithoutCurrency.join("");
  const amountToRender = amount[0] === "0" ? "" : amount;

  return (
    <InputLabelContainer minHeight={minHeight}>
      <AmountInputLabelBlock
        id={props.id}
        valid={touched ? String(touched && !formHasError) : ""}
        error={error}
        inputIsEmpty={amountToRender === ""}
      >
        <CurrencySymbol marginTop={amountToRender && "6px"}>R</CurrencySymbol>
        <TextInputField
          type="text"
          {...field}
          {...props}
          value={amountToRender}
          touched={touched ? "true" : "false"}
          showerror={String(formHasError)}
          valid={touched ? String(touched && !formHasError) : "true"}
          onKeyUp={onKeyUp}
          inputMode={"numeric"}
        />
        <AmountInputLabel
          valid={touched ? String(touched && !formHasError) : "true"}
        >
          {label}
        </AmountInputLabel>
      </AmountInputLabelBlock>

      {formHasError && (
        <FormMetaData showerror={String(formHasError)}>{error}</FormMetaData>
      )}

      {!formHasError && description && (
        <FormMetaData>{description}</FormMetaData>
      )}
    </InputLabelContainer>
  );
};

NewAmountInputWithLabel.propTypes = {
  label: string.isRequired,
  description: string,
  allowDecimal: bool,
};
