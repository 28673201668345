import React, { useState, useEffect } from "react";

import { bool, func, array, string } from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import {
  TopHeader,
  RippleButton,
  AddProductOnTheFly,
} from "../../../../../components";
import {
  SearchInput,
  SearchInputWithCancel,
} from "../../../../../components/forms/input/search-input/SearchInput";
import { Close } from "../../../../../containers/HeaderContainer";
import {
  List,
  ListCheckedRow,
  ListHeading,
  ListSubHeading,
  ListLeftBlock,
} from "../../../../../containers/ListContainer";
import { SlidingOverlay } from "../../../../../containers/OverlayContainer";
import { ScreenContainer } from "../../../../../containers/ScreenContainer";
import { colors } from "../../../../../styles";
import { formatCurrency } from "../../../../../utils/currency/parseBalance";
import { SHOP_START_CREATE_PRODUCT } from "../../../../../utils/mix-panel/constants";
import { mixPanel } from "../../../../../utils/mix-panel/mixPanel";
import { ReactComponent as PlusSVG } from "../../assets/plus.svg";

import { ReactComponent as CheckSVG } from "./assets/check.svg";
import { ReactComponent as UnCheckSVG } from "./assets/uncheck.svg";

const ProductOnTheFly = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  & > svg {
    margin-right: 16px;
  }
  & > p {
    margin: 0;
  }
`;

const Desc = styled.p`
  color: #227eff;
  margin-left: 9px;
  font-weight: bold;
  font-size: 14px;
`;

const ButtonWrapper = styled.div`
  position: fixed;
  background: ${({ bgColor }) => bgColor || "#FFFFFF"};
  padding: ${({ padding }) => padding || "16px"};
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 0;
  right: 0;
  bottom: 0px;
  width: 100%;
  max-width: 375px;
  margin: auto;
  @media (max-width: 576px) {
    max-width: 100%;
  }
`;

const AddProductsButton = styled(RippleButton)`
  position: relative;
  width: 156px;
`;

const Flex = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const Amount = styled(ListSubHeading)`
  font-size: 12px;
  color: #718596;
`;

const Suggested = styled.div`
  background-color: #edf2f7;
  width: 66px;
  height: 24px;
  border-radius: 24px;
  padding: 4px 8px;
  color: #071827;
  line-height: 16px;
  font-size: 10px;
`;

const BlueBox = styled.div`
  background-color: #227eff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProductScrollSection = styled.div`
  /* height: calc(100vh - (18vh + 48px));
    overflow-y: auto;
    overflow-x: hidden; */
`;

const WhiteWrapper = styled.div`
  display: flex;
  justify-content: ${({ justify }) => justify || "space-between"};
  align-items: center;
  height: ${({ height }) => height || "65px"};
  margin-top: ${({ top }) => top || null};
  background: ${colors.white};
  padding: 10px 1em;
`;

const Text = styled.div`
  color: ${({ color }) => color || "#071827"};
  font-size: ${({ size }) => size || "14px"};
  margin-top: ${({ top }) => top || ""};
  margin-left: ${({ left }) => left || ""};
  margin-right: ${({ right }) => right || ""};
  font-weight: ${({ weight }) => weight || ""};
  margin-bottom: ${({ bottom }) => bottom || ""};
`;

export const AddProductsForSale = ({
  addProductOnTheFly,
  shopId,
  open,
  setOpen,
  setProducts,
  setFieldValue,
  inventory,
  selectedProducts,
  searchProductsOnMasterList,
}) => {
  const dispatch = useDispatch();

  const [openAddProductOnTheFly, setOpenAddProductOnTheFly] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const searchedInventory = inventory.filter((data) =>
    data.productName.toLowerCase().includes(searchValue.toLowerCase())
  );
  const shops = useSelector((state) => state.applications.myShop.shops);
  const branchId = shops.find((shop) => shop.id === shopId).branchId;
  const [passedValues, setPassedValues] = useState({
    productName: "",
    base64ProductImageString: "",
  });
  const [showInput, setShowInput] = useState(false);

  const masterlist =
    useSelector((state) => state.applications.myShop.productsMasterList) || [];
  const productsMasterList = masterlist
    .slice(0, 5)
    .map((item) => {
      return {
        productName: item.itemName,
        base64ProductImageString: item.masterListImageUrl,
      };
    })
    .filter(
      (x) => !searchedInventory.includes((y) => y.productName !== x.productName)
    );

  const manageSelectedProducts = (arr, obj) => {
    if (
      !arr.some((item) => item.inventoryProductId === obj.inventoryProductId)
    ) {
      const newProduct = {
        inventoryProductId: obj.inventoryProductId,
        id: selectedProducts.length + 1,
        hasInventory: true,
        inStock: true,
        quantity: obj.status === "ON_THE_FLY" ? obj.quantity : 1,
        name: obj.productName,
        itemPrice: Number(obj.retailUnitPrice),
        costPrice: Number(obj.costPrice),
        base64ProductImageString: obj.base64ProductImageString,
      };

      setProducts((oldArray) => [...oldArray, newProduct]);
    }

    if (
      arr.some((item) => item.inventoryProductId === obj.inventoryProductId)
    ) {
      setProducts(
        selectedProducts.filter(
          (item) => item.inventoryProductId !== obj.inventoryProductId
        )
      );
    }
  };

  useEffect(() => {
    searchValue &&
      branchId &&
      dispatch(searchProductsOnMasterList(branchId, searchValue));
  }, [searchValue, branchId, dispatch, searchProductsOnMasterList]);

  return (
    open && (
      <SlidingOverlay position={"absolute"}>
        <TopHeader title={"Add Products"} noArrow>
          <Close left={"16px"} onClick={() => setOpen(!open)} />
        </TopHeader>
        <ScreenContainer color="#F9FAFC" top={"60px"}>
          <ProductScrollSection>
            {showInput ? (
              <SearchInputWithCancel
                name="searchValue"
                onChange={(e) =>
                  setSearchValue(
                    e.target.value.trim().length
                      ? e.target.value
                      : e.target.value.trim()
                  )
                }
                value={searchValue}
                placeholder="Search for any product"
                onCancel={() => {
                  setShowInput(false);
                  setSearchValue("");
                }}
                margin="0 0 0 0"
                onCancelInputValue={() => setSearchValue("")}
              />
            ) : (
              <SearchInput
                placeholder="Search for any product"
                onClick={() => setShowInput(true)}
              />
            )}
            <ProductOnTheFly
              onClick={() => {
                setOpenAddProductOnTheFly(!openAddProductOnTheFly);
                setPassedValues({
                  productName: searchValue,
                  base64ProductImageString: "",
                });
                mixPanel.track(SHOP_START_CREATE_PRODUCT, {
                  "Entry Point": "Sales",
                });
              }}
            />
            <List fullScreen>
              <WhiteWrapper justify="flex-start" bottom={"8px"}>
                <BlueBox>
                  <PlusSVG />
                </BlueBox>
                <Desc onClick={() => setOpenAddProductOnTheFly(true)}>
                  {searchValue
                    ? `Add new product named "${searchValue}"`
                    : `Add new product`}
                </Desc>
              </WhiteWrapper>
              {[...searchedInventory]
                .sort((a, b) => a.productName.localeCompare(b.productName))
                .map((item, index) => (
                  <WhiteWrapper
                    onClick={() => {
                      manageSelectedProducts(selectedProducts, item);
                    }}
                    key={index}
                    top={"4px"}
                  >
                    {selectedProducts.some(
                      (data) =>
                        data.inventoryProductId === item.inventoryProductId
                    ) ? (
                      <CheckSVG />
                    ) : (
                      <UnCheckSVG />
                    )}
                    <ListCheckedRow bottom={"16px"} value={item}>
                      <ListLeftBlock left={"0"} width={"100%"}>
                        <Flex>
                          <div>
                            <ListHeading
                              weight="400"
                              color="#071827"
                              maxWidth={"180px"}
                            >
                              {item.productName}
                            </ListHeading>
                            <Amount>
                              {formatCurrency(item.retailUnitPrice)}/Unit
                            </Amount>
                          </div>
                          <Text>
                            {item.quantity}{" "}
                            {item.quantity > 1 ? "Units" : "Unit"}
                          </Text>
                        </Flex>
                      </ListLeftBlock>
                    </ListCheckedRow>
                  </WhiteWrapper>
                ))}
            </List>
            <List fullScreen>
              {[...productsMasterList]
                .sort((a, b) => a.productName.localeCompare(b.productName))
                .map((item, index) => (
                  <WhiteWrapper
                    onClick={() => {
                      if (typeof item.retailUnitPrice === "number") {
                        manageSelectedProducts(selectedProducts, item);
                      } else {
                        setOpenAddProductOnTheFly((prev) => !prev);
                        setPassedValues({
                          productName: item.productName,
                          base64ProductImageString:
                            item.base64ProductImageString,
                        });
                      }
                    }}
                    key={index}
                    top={"4px"}
                  >
                    {selectedProducts.some(
                      (data) =>
                        data.inventoryProductId === item.inventoryProductId
                    ) ? (
                      <CheckSVG />
                    ) : (
                      <UnCheckSVG />
                    )}
                    <ListCheckedRow bottom={"16px"} value={item}>
                      <ListLeftBlock left={"0"} width={"100%"}>
                        <Flex>
                          <ListHeading
                            weight="400"
                            color="#071827"
                            maxWidth={"180px"}
                          >
                            {item.productName}
                          </ListHeading>
                          <Suggested>Suggested</Suggested>
                        </Flex>
                      </ListLeftBlock>
                    </ListCheckedRow>
                  </WhiteWrapper>
                ))}
            </List>
          </ProductScrollSection>
          <ButtonWrapper>
            <div>
              <Text size="12px" weight="400" color="#718596">
                Selected Products
              </Text>
              <Text top="1px" size="16px" weight="bold" color="#071827">
                {selectedProducts.length}
              </Text>
            </div>
            <AddProductsButton
              top="0"
              type="submit"
              disabled={selectedProducts.length === 0}
              onClick={() => {
                setOpen(!open);
                setFieldValue("salesItemDetails", selectedProducts);
                setSearchValue("");
                setProducts(selectedProducts);
              }}
            >
              {`Add Product(s)`}
            </AddProductsButton>
          </ButtonWrapper>
        </ScreenContainer>
        {openAddProductOnTheFly && (
          <AddProductOnTheFly
            open={openAddProductOnTheFly}
            setOpen={setOpenAddProductOnTheFly}
            addProduct={addProductOnTheFly}
            manageSelectedProducts={manageSelectedProducts}
            selectedProducts={selectedProducts}
            passedValues={passedValues}
            searchProductsOnMasterList={searchProductsOnMasterList}
            shopId={shopId}
          />
        )}
      </SlidingOverlay>
    )
  );
};

AddProductsForSale.propTypes = {
  open: bool,
  setOpen: func,
  shopId: string,
  setFieldValue: func,
  selectedProducts: array,
  setProducts: func,
  inventory: array,
  addProductOnTheFly: func,
  searchProductsOnMasterList: func,
};
