import * as Yup from "yup";

import { containsLettersRegExp } from "../../../../../../../utils/regexp/containsLettersRegExp";

export const AddProductValidationSchema = Yup.object().shape({
  productName: Yup.string().nullable().required("Required"),
  retailUnitPrice: Yup.string()
    .nullable()
    .required("Required")
    .matches(containsLettersRegExp, "Retail price cannot contain letters"),
  costPrice: Yup.string()
    .nullable()
    .matches(containsLettersRegExp, "Price cannot contain letters"),

  base64ProductImageString: Yup.mixed().when("availableAtRetailPrice", {
    is: (availableAtRetailPrice, masterListImageUrl) =>
      availableAtRetailPrice === true && masterListImageUrl === "",
    then: Yup.string().required("Product image is required!"),
  }),
  localCreatedDate: Yup.number(),
  masterListImageUrl: Yup.string(),
  bulkPrices: Yup.array().when("availableAtWholesalePrice", {
    is: (value) => value === true,
    then: Yup.array()
      .of(
        Yup.object().shape({
          moq: Yup.string()
            .test("case 1", "Invalid MOQ", (value) => {
              if (value && value.trim().startsWith("0")) {
                return false;
              } else return true;
            })
            .required("Required"),
          price: Yup.string().required("Required"),
        })
      )
      .min(1),
    otherwise: Yup.array().of(
      Yup.object().shape({
        moq: Yup.string().matches(
          containsLettersRegExp,
          "Minimum order price cannot contain letters"
        ),
        price: Yup.string().matches(
          containsLettersRegExp,
          "Wholesale price cannot contain letters"
        ),
      })
    ),
  }),
});
