export const LOADING_AGENCY_BANKING_DATA =
  "account/agency_banking/loading_agency_banking_data";
export const AGENCY_BANKING_REQUEST_LOADING =
  "account/agency_banking/agency_banking_request_loading";
export const AGENCY_BANKING_SIGNUP =
  "account/agency_banking/agency_banking_signup";
export const SAVE_AGENCY_BANKING_IDCARD_IMAGES =
  "account/agency_banking/save_agency_banking_idcard_images";
export const SAVE_AGENCY_BANKING_UTILITY_IMAGES =
  "account/agency_banking/save_agency_banking_utility_images";
export const SAVE_AGENCY_BANKING_PASSPORT_IMAGES =
  "account/agency_banking/save_agency_banking_passport_images";
