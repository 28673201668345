import axios from "axios";
import { toast } from "react-toastify";

import { onTokenExpire } from "../redux/ducks/auth/phone/actions";

import { currentAPI } from "./API";

const notSecuredEndpoints = [
  `${currentAPI}/api/token/generate-token`,
  `${currentAPI}/api/token/refresh-token`,
  `${currentAPI}/api/commissions/onboarding`,
  `${currentAPI}/api/users/nextOfKin`,
  `${currentAPI}/api/merchantAppIntegration/public/password-reset-spaces`,
];

axios.interceptors.request.use(
  (config) => {
    if (!navigator.onLine && config.method === "get") {
      return Promise.reject();
    }

    const token = localStorage.getItem("token");
    const fingerPrint = localStorage.getItem("fingerPrint");

    if (token && !notSecuredEndpoints.includes(config.url)) {
      config.headers["Authorization"] = `Bearer ${JSON.parse(token).token}`;
      config.headers["Content-Type"] = "application/json";
      config.headers["fingerprint"] = `${fingerPrint}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      return axios(originalRequest);
    }

    if (error.response && error.response.status === 403) {
      onTokenExpire();
    }

    if (error.response && error.response.status === 500) {
      //This Id is to prevent duplicate alert when there is multiple 500 error
      const toastId = "500-error-toast-Id";
      toast.error(
        "Sorry, an error occurred on our end. We are aware of this and our team are on it. Try again in a little bit",
        {
          toastId,
        }
      );
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export default axios;
