import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";

import { ComplaintDetails } from "../../../../components";
import { Button, RippleButton } from "../../../../components/button";
import { TopHeader } from "../../../../components/header";
import { PageLogo } from "../../../../components/logo";
import {
  ScreenContainer,
  Container,
} from "../../../../containers/ScreenContainer";
import {
  getComplaintFiles,
  getComplaintDetails,
} from "../../../../redux/ducks/applications/complaints/actions";
import { remittanceActions } from "../../../../redux/ducks/applications/remittance/actions";
import { colors } from "../../../../styles";
import { formatPrice } from "../../../../utils/currency/formatPriceWithComma";
import { parseDate } from "../../../../utils/date/parseDateFromStr";
import DesktopBackgroundLayout from "../../../DesktopBackgroundLayout";
import { paymentType } from "../../merchbuy/order-history-list/paymentType";
import { ReactComponent as CardIcon } from "../assets/card.svg";
import FallBackIcon from "../assets/fallBack.svg";
import { ReactComponent as MasterCardIcon } from "../assets/master_card.svg";
import { ReactComponent as WalletIcon } from "../assets/wallet.svg";
import { Text, ColoredCircle } from "../styles";

const Card = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction || "column"};
  justify-content: ${({ justify }) => justify || null};
  padding: 16px;
  border-radius: 4px;
  margin: ${({ margin }) => margin || "16px 16px 0px"};
  background-color: ${colors.white};
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ bottom }) => bottom || null};
  margin-top: ${({ top }) => top || "4px"};
  justify-content: ${({ justify }) => justify || "space-between"};
`;

const OrderText = styled.div`
  color: ${({ color }) => color || colors.themeTxtColor10};
  font-weight: ${({ weight }) => weight || "400"};
  font-size: 12px;
  line-height: 18px;
  margin-top: ${({ top }) => top || null};
  margin-left: ${({ left }) => left || null};
`;

const GridWrapper = styled.div`
  width: 100%;
  margin-left: 12px;
`;

const Border = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.gray4};
  margin-top: ${({ top }) => top || "8px"};
  margin-bottom: ${({ bottom }) => bottom || "8px"};
`;

const CenterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const ItemText = styled.div`
  color: ${({ color }) => color || colors.themeTxtColor10};
  font-weight: ${({ weight }) => weight || "400"};
  font-size: 14px;
  line-height: 21px;
  margin-top: ${({ top }) => top || null};
`;

export const RemittanceOrderDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { orderId } = location?.state || {};

  const [orderDetails, setOrderDetails] = useState({});
  const [complaintDetails, setComplaintDetails] = useState();
  const [complaintFiles, setComplaintFiles] = useState([]);
  const departmentId = process.env.REACT_APP_UX_SUPPORT_DEPARTMENT_ID; //country specific
  const issueTitle = "orders";
  const category = "orders";

  const handlePaymentHistory = () =>
    history.push({
      pathname: "/actions/remittance/remittance-order",
      state: location?.state,
    });

  const handleRemitPayment = () =>
    history.push({
      pathname: "/actions/remittance/remittance-single-order",
      state: location?.state,
    });

  useEffect(() => {
    dispatch(remittanceActions.getOrderDetails(orderId)).then(
      (orderDetails) => {
        orderDetails && setOrderDetails(orderDetails);
      }
    );
  }, [dispatch, orderId]);

  useEffect(() => {
    if (orderDetails?.orderNumber) {
      dispatch(getComplaintDetails(orderDetails?.orderNumber)).then((data) => {
        if (data) {
          setComplaintDetails(data);
        } else setComplaintDetails({});
      });
    }
  }, [dispatch, orderDetails]);

  useEffect(() => {
    if (complaintDetails?.id) {
      dispatch(getComplaintFiles(complaintDetails?.id)).then(
        (res) => res && setComplaintFiles(res)
      );
    }
  }, [dispatch, complaintDetails]);

  return (
    <DesktopBackgroundLayout>
      <TopHeader withSpacesHeader title="Orders" />
      <ScreenContainer
        color={colors.lightestGray}
        top="10px"
        padding="50px 0px"
      >
        <Card>
          <OrderText>Order No. {orderDetails?.orderNumber}</OrderText>
          <FlexWrapper>
            <OrderText weight="700">
              {formatPrice(orderDetails?.financialDetails?.totalPrice)}
            </OrderText>
            <OrderText color={colors.subGrey}>
              x{orderDetails?.numberOfItems} Items
            </OrderText>
          </FlexWrapper>
        </Card>
        <OrderText weight="700" top="16px" left="16px" color={colors.subGrey}>
          ITEMS IN ORDER
        </OrderText>
        {orderDetails?.orderItems &&
          orderDetails.orderItems.map((item, index) => (
            <Card key={index} margin="8px 16px 8px">
              <FlexWrapper>
                <PageLogo
                  Icon={item.productImageUrl}
                  fallback={FallBackIcon || null}
                  width="44px"
                  height="44px"
                  iconWidth="44px"
                  iconHeight="44px"
                  background="transparent"
                  borderRadius="4px"
                />
                <GridWrapper>
                  <OrderText>{item.productName || ""}</OrderText>
                  <FlexWrapper top="8px">
                    <OrderText weight="700">
                      {formatPrice(item.unitPrice)}
                    </OrderText>
                    <OrderText color={colors.subGrey}>
                      X{item.quantity}
                    </OrderText>
                  </FlexWrapper>
                </GridWrapper>
              </FlexWrapper>
              <Border />
              {item?.statusQuantities &&
                item?.statusQuantities.map((status, index) => (
                  <FlexWrapper key={index}>
                    <Text size="10px" lineHeight="16px">
                      <ColoredCircle
                        status={(status.statusDesc || "").toUpperCase()}
                      />
                      {`${status.statusDesc}: ${status.quantity} unit(s)`}
                    </Text>
                  </FlexWrapper>
                ))}
            </Card>
          ))}

        <Card margin="16px 0">
          <FlexWrapper top="0px">
            <OrderText color={colors.smoothGreyText}>Items Total</OrderText>
            <ItemText>
              {formatPrice(orderDetails?.financialDetails?.subTotal)}
            </ItemText>
          </FlexWrapper>
          <FlexWrapper top="8px">
            <OrderText color={colors.smoothGreyText}>Delivery Fee</OrderText>
            <ItemText>
              {formatPrice(orderDetails?.financialDetails?.deliveryCharge)}
            </ItemText>
          </FlexWrapper>
          <FlexWrapper top="8px">
            <OrderText weight="500" color={colors.lightGray2}>
              TOTAL
            </OrderText>
            <ItemText weight="700" color={colors.themeTextColor1}>
              {formatPrice(orderDetails?.financialDetails?.totalPrice)}
            </ItemText>
          </FlexWrapper>
          <CenterWrapper>
            <Border top="16px" bottom="8px" />
            <OrderText
              color={colors.deepBlue}
              onClick={() =>
                history.push("/actions/merchbuy/account/commission")
              }
            >
              See commission breakdown
            </OrderText>
          </CenterWrapper>
        </Card>

        <Card direction="row" margin="16px 0" justify="space-between">
          <ItemText color={colors.lightDark}>Delivered Value</ItemText>
          <ItemText>
            {formatPrice(orderDetails?.financialDetails?.deliveredValue)}
          </ItemText>
        </Card>

        <Card direction="row" margin="16px 0" justify="space-between">
          <ItemText color={colors.lightDark}>Order placed by</ItemText>
          <ItemText>{orderDetails?.placedBy?.name}</ItemText>
        </Card>

        <OrderText weight="700" top="16px" left="16px" color={colors.subGrey}>
          CUSTOMER
        </OrderText>

        <Card direction="row" margin="4px 0" justify="space-between">
          <ItemText>
            {`${orderDetails?.orderDelivery?.firstName} ${orderDetails?.orderDelivery?.lastName}`}
          </ItemText>
          <ItemText color={colors.deepBlue}>View Details</ItemText>
        </Card>

        <OrderText weight="700" top="16px" left="16px" color={colors.subGrey}>
          DELIVERY ADDRESS
        </OrderText>

        <Card margin="4px 0">
          <ItemText>
            {`${orderDetails?.orderDelivery?.firstName} ${orderDetails?.orderDelivery?.lastName}`}
          </ItemText>
          <OrderText top="8px" color={colors.lightDark}>
            {`${orderDetails?.orderDelivery?.address}`}
          </OrderText>
          <OrderText
            color={colors.lightDark}
          >{`${orderDetails?.orderDelivery?.lga} Municipalities`}</OrderText>
          <OrderText
            color={colors.lightDark}
          >{`${orderDetails?.orderDelivery?.state} Province.`}</OrderText>
        </Card>

        <OrderText weight="700" top="16px" left="16px" color={colors.subGrey}>
          PAYMENT METHOD
        </OrderText>

        <Card margin="4px 0">
          <>
            <FlexWrapper>
              <FlexWrapper>
                <Text size="14px">
                  {orderDetails?.paymentInfo?.paymentMethod === 2 && (
                    <CardIcon />
                  )}
                  {orderDetails?.paymentInfo?.paymentMethod === 3 && (
                    <WalletIcon />
                  )}
                  {orderDetails?.paymentInfo?.paymentMethod === 4 &&
                    "Post Paid"}
                </Text>
                <Text size="14px" color={colors.boldDark}>
                  {paymentType(orderDetails?.paymentInfo?.paymentMethod) &&
                    `${paymentType(orderDetails?.paymentInfo?.paymentMethod)}`}
                </Text>
              </FlexWrapper>
              {orderDetails?.paymentInfo?.paymentMethod === 2 &&
                orderDetails?.customerDetails.phoneNumber && (
                  <FlexWrapper>
                    <Text align="center" size="14px" color={colors.deepBlue}>
                      View transaction
                    </Text>
                  </FlexWrapper>
                )}
            </FlexWrapper>
            <FlexWrapper>
              {orderDetails?.paymentInfo?.paymentMethod === 2 && (
                <>
                  <MasterCardIcon />
                  <Text fontWeight="400" color={colors.subGrey}>
                    ****6677
                  </Text>
                </>
              )}
              {orderDetails?.paymentInfo?.paymentMethod === 3 && (
                <Text fontWeight="400" color={colors.subGrey}>
                  Reference - {orderDetails?.paymentInfo?.transactionReference}
                </Text>
              )}
            </FlexWrapper>
          </>
        </Card>
        <OrderText weight="700" top="16px" left="16px" color={colors.subGrey}>
          REMITTANCE
        </OrderText>
        <Card margin="8px 16px">
          <FlexWrapper>
            <OrderText color={colors.lightDark}>Delivered value</OrderText>
            <OrderText weight="700">
              {formatPrice(orderDetails?.financialDetails?.deliveredValue)}
            </OrderText>
          </FlexWrapper>
          <FlexWrapper>
            <OrderText color={colors.lightDark}>Amount paid</OrderText>
            <OrderText weight="700">
              {formatPrice(orderDetails?.totalAmountPaid)}
            </OrderText>
          </FlexWrapper>
          <FlexWrapper>
            <OrderText color={colors.lightDark}>Balance</OrderText>
            <OrderText weight="700">
              {formatPrice(orderDetails?.totalUnpaidBalance)}
            </OrderText>
          </FlexWrapper>
          <FlexWrapper top="16px">
            <Button
              top="0px"
              border={`1px solid ${colors.lightGray2}`}
              backgroundColor={colors.white}
              color={colors.lightGray2}
              width="48%"
              onClick={handlePaymentHistory}
            >
              Payment History
            </Button>
            <Button top="0px" width="48%" onClick={handleRemitPayment}>
              Remit payment
            </Button>
          </FlexWrapper>
        </Card>
        <Card direction="row" margin="12px 0" justify="space-between">
          <ItemText color={colors.subGrey}>Date</ItemText>
          <ItemText>{parseDate(orderDetails.orderDate)}</ItemText>
        </Card>

        {complaintDetails && Object.keys(complaintDetails).length > 0 && (
          <Container>
            <ComplaintDetails
              details={complaintDetails}
              files={complaintFiles}
            />
          </Container>
        )}

        {complaintDetails && !Object.keys(complaintDetails)?.length && (
          <Container>
            <RippleButton
              onClick={() => {
                history.push({
                  pathname: "/actions/complaints/log_complaint",
                  state: {
                    reference: orderDetails?.orderNumber,
                    departmentId,
                    issueTitle,
                    category,
                  },
                });
              }}
            >
              Dispute Order
            </RippleButton>
          </Container>
        )}
      </ScreenContainer>
    </DesktopBackgroundLayout>
  );
};

export default RemittanceOrderDetails;
