import React, { Fragment, useEffect, useMemo, useState } from "react";

import { useMachine } from "@xstate/react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { createMachine } from "xstate";

import {
  TopHeader,
  Loader,
  SuccessPage,
  Button,
} from "../../../../../components";
import { merchbuyActions } from "../../../../../redux/ducks/applications/merchbuy/actions";
import { colors } from "../../../../../styles";
import { formatPrice } from "../../../../../utils/currency/formatPriceWithComma";
import DesktopBackgroundLayout from "../../../../DesktopBackgroundLayout";
import { Container, Main, FixedBottom } from "../../styles";
import GroupBuyEducation from "../group-buy-education";
import {
  ProductImageBlock,
  SelectNumberOfBuyers,
  SelectQuantityToBuy,
} from "../product-quantity-and-buyers";
import { GroupBuySplitFriends } from "../split-friends/index";
import { GroupBuySplitQuantity } from "../split-quantity/index";
import {
  SubTitle,
  Title,
  Paragraph,
  Card,
  ModifiedQuantityBuyIcon,
  ModifiedParticipantsBuyIcon,
  PageIndicatorContainer,
  PageIndicator,
} from "../styles";
import {
  GroupBuyOrderConfirmation,
  GroupBuyShoppingBag,
  GroupBuySummary,
} from "../summary-page";

export const CurrentQuantity = styled.input`
  font-weight: 100;
  font-size: 16px;
  font-weight: 500;
  color: ${({ color }) => color || colors.themeTextColor1};
  margin-top: ${({ top }) => top || null};
  margin-left: ${({ left }) => left || null};
  width: ${({ width }) => width || "60%"};
  background: ${({ background }) => background || "transparent"};
  border: none;
  text-align: center;
  &::-webkit-inner-spin-button,
  -webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:focus {
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
`;

export const QuantityDisplay = styled.div`
  border-radius: 8px;
  max-width: 46px;
  background-color: ${({ background }) => background || colors.themeTextColor3};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BuyButtons = styled.div`
  display: flex;
  width: 90%;
`;

export const TitlePro = styled.span`
  margin: 0 0 18px 0;
  font-size: 10px;
  font-weight: 400;
  color: #56636d;
`;

export const ClickToEdit = styled(Paragraph)`
  color: #718596;
`;

export const QuantityAllocationSummary = styled.div`
  padding: 8px 0 0;
  border-top: 0.5px solid #edf2f7;
  font-size: 14px;
  width: 100%;
`;

const pages = {
  QUANTITY_SELECTION: "QUANTITY_SELECTION",
  NUMBER_OF_BUYERS_SELECTION: "NUMBER_OF_BUYERS_SELECTION",
  QUANTITY_ALLOCATION: "QUANTITY_ALLOCATION",
  FRIENDS_INVITATION: "FRIENDS_INVITATION",
  GROUPBUY_SUMMARY: "GROUPBUY_SUMMARY",
  GROUPBUY_SUCCESS: "GROUPBUY_SUCCESS",
};

// This machine is completely decoupled from React
const changePageState = createMachine({
  id: "toggle",
  initial: pages.QUANTITY_SELECTION,
  states: {
    [pages.QUANTITY_SELECTION]: {
      on: { NEXT: pages.NUMBER_OF_BUYERS_SELECTION },
    },
    [pages.NUMBER_OF_BUYERS_SELECTION]: {
      on: {
        NEXT: pages.QUANTITY_ALLOCATION,
        PREVIOUS: pages.QUANTITY_SELECTION,
        EDIT_QUANTITY: pages.QUANTITY_SELECTION,
      },
    },
    [pages.QUANTITY_ALLOCATION]: {
      on: {
        NEXT: pages.FRIENDS_INVITATION,
        PREVIOUS: pages.NUMBER_OF_BUYERS_SELECTION,
        EDIT_QUANTITY: pages.QUANTITY_SELECTION,
        EDIT_NUMBER_OF_BUYERS: pages.NUMBER_OF_BUYERS_SELECTION,
      },
    },
    [pages.FRIENDS_INVITATION]: {
      on: {
        NEXT: pages.GROUPBUY_SUMMARY,
        PREVIOUS: pages.QUANTITY_ALLOCATION,
        EDIT_QUANTITY: pages.QUANTITY_SELECTION,
        EDIT_NUMBER_OF_BUYERS: pages.NUMBER_OF_BUYERS_SELECTION,
        EDIT_QUANTITY_ALLOCATION: pages.QUANTITY_ALLOCATION,
      },
    },
    [pages.GROUPBUY_SUMMARY]: {
      on: {
        NEXT: pages.GROUPBUY_SUCCESS,
        PREVIOUS: pages.FRIENDS_INVITATION,
      },
    },
    [pages.GROUPBUY_SUCCESS]: {
      on: {
        NEXT: pages.GROUPBUY_SUCCESS,
      },
    },
  },
});

const GroupBuy = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(
    (state) => state.applications.merchbuy.isLoading
  );
  const { msisdn } = useSelector((state) => state.user);

  const [orderQuantity, setOrderQuantity] = useState(1);
  const [unitPrice, setUnitPrice] = useState(0);
  const [qtyLeftToShare, setQtyLeftToShare] = useState(0);
  const groupBuyEducationState = useSelector(
    (state) => state.applications.merchbuy.groupBuyEducation
  );

  //For Quantity Sharing
  const [tempQtySharingSplits, setTempQtySharingSplit] = useState({});
  const [noOfInvitees, setNoOfInvitees] = useState(1);
  const [invitedFriends, setInvitedFriends] = useState([]);
  const [invitedSlots, setInvitedSlots] = useState([]);
  const [currentPage, send] = useMachine(changePageState);

  const history = useHistory();

  const { product } = history.location.state || {};
  const { bulkPrices, unitValue, moq, minPrice, name } = product || {};
  const [showSummary, setShowSummary] = useState(false);

  useEffect(() => {
    const bulk =
      bulkPrices &&
      bulkPrices.find((price, index) => {
        if (!bulkPrices[index + 1]) {
          return orderQuantity >= price.moq;
        }
        return orderQuantity < bulkPrices[index + 1].moq;
      });

    bulkPrices && bulk && setUnitPrice(bulk.price);
  }, [orderQuantity, bulkPrices]);

  useEffect(() => {
    moq && setOrderQuantity(moq);
  }, [moq]);

  useEffect(() => {
    const share = Math.floor(orderQuantity / noOfInvitees);
    const remainder = Math.floor(orderQuantity % noOfInvitees);

    let friendsContacts = {};
    for (let i = 0; i < noOfInvitees; i++) {
      friendsContacts = { ...friendsContacts, [i]: share };
    }
    setQtyLeftToShare(remainder);
    setTempQtySharingSplit(friendsContacts);
  }, [orderQuantity, noOfInvitees]);

  const reduceQuantity = () => {
    if (bulkPrices && bulkPrices.length > 0) {
      if (orderQuantity <= bulkPrices[0].moq) {
        return setOrderQuantity(bulkPrices[0].moq);
      }

      setOrderQuantity(parseInt(orderQuantity) - 1);
    }
  };

  const increaseQuantity = () => {
    if (bulkPrices && bulkPrices.length > 0) {
      setOrderQuantity(parseInt(orderQuantity, 10) + 1);
    }
  };

  const reduceQuantityToShare = () => {
    if (noOfInvitees <= 1) return setNoOfInvitees(1);
    setNoOfInvitees(noOfInvitees - 1);
  };

  const increaseQuantityToShare = () => {
    if (noOfInvitees === orderQuantity) return;
    setNoOfInvitees(noOfInvitees + 1);
  };

  const handleQuantityManualInput = ({ target }) => {
    if (bulkPrices && bulkPrices.length > 0) {
      setOrderQuantity(
        target.value <= bulkPrices[0].moq
          ? bulkPrices[0].moq
          : target.value && parseInt(target.value)
      );
    }
  };

  const handleInviteeQuantityManualInput = ({ target }) => {
    setNoOfInvitees(!target.value ? 1 : parseInt(target.value));
  };

  const currentPrice = useMemo(() => {
    let quantitySpecified = orderQuantity ? orderQuantity : 1;

    if (bulkPrices && bulkPrices.length > 0) {
      const price = bulkPrices
        .filter((bulkPrice) => bulkPrice.moq <= Number(quantitySpecified))
        .sort((priceA, priceB) => {
          if (priceA.moq > priceB.moq) return -1;
          if (priceB.moq > priceA.moq) return 1;
          return 0;
        });

      return price[0]?.price;
    }

    return minPrice;
  }, [bulkPrices, orderQuantity, minPrice]);

  const cancelButtonColor = useMemo(() => {
    if (currentPage.value !== pages.QUANTITY_SELECTION) {
      return colors.deepBlue;
    }

    return "#A0B1C0";
  }, [currentPage.value]);

  const pageNumber = useMemo(() => {
    switch (currentPage.value) {
      case pages.QUANTITY_SELECTION:
        return 1;
      case pages.NUMBER_OF_BUYERS_SELECTION:
        return 2;
      case pages.QUANTITY_ALLOCATION:
        return 3;
      case pages.FRIENDS_INVITATION:
        return 4;
      default:
        return 5;
    }
  }, [currentPage.value]);

  const pageIndicatorWidth = useMemo(() => {
    const width = (pageNumber * 100) / Object.keys(pages).length;
    return `${width}%`;
  }, [pageNumber]);

  const disableContinueButton = useMemo(() => {
    if (currentPage.value === pages.QUANTITY_ALLOCATION) {
      return qtyLeftToShare > 0;
    }

    if (currentPage.value === pages.FRIENDS_INVITATION) {
      return invitedFriends.length < noOfInvitees - 1;
    }

    return orderQuantity < 1;
  }, [
    currentPage.value,
    qtyLeftToShare,
    orderQuantity,
    invitedFriends,
    noOfInvitees,
  ]);

  const totalPages = Object.keys(pages).length;

  const pageTitle =
    currentPage.value === pages.GROUPBUY_SUMMARY
      ? "Summary"
      : "Buy with friends";

  const handleContinueButtonClick = () => {
    if (currentPage.value === pages.GROUPBUY_SUMMARY) {
      setShowSummary(true);
      return;
    }

    if (currentPage.value === pages.FRIENDS_INVITATION) {
      validateNumbers();
      return;
    }

    send("NEXT");
  };

  const handleCancelButtonClick = () => {
    send("PREVIOUS");
  };

  const backToQuantitySelection = () => {
    send("EDIT_QUANTITY");
  };

  const backToNumberOfBuyersSelection = () => {
    send("EDIT_NUMBER_OF_BUYERS");
  };

  const backToQuantityAllocation = () => {
    send("EDIT_QUANTITY_ALLOCATION");
  };

  const handleAddToCart = async () => {
    const numbersOnly = invitedFriends.map((friend) => friend.number);

    const groupBuyCreated = await dispatch(
      merchbuyActions.addGroupBuyToCart(
        {
          bulkPrice: unitPrice,
          productId: product.id,
          totalQuantity: orderQuantity,
          inviteePhoneNumbers: numbersOnly,
          splits: invitedSlots,
        },
        //TODO ask where the shop ID comes from
        "shop-id"
      )
    );

    if (groupBuyCreated) {
      const { splits, id } = groupBuyCreated;

      const redirectToCartPage = false;

      const productAddedToCart = await dispatch(
        merchbuyActions.addProductToCart(
          {
            groupBuyId: id,
            groupBuySplitId: 0,
            productId: product.id,
            quantity: splits && splits[0],
            shopId: "shop-id",
          },
          redirectToCartPage
        )
      );

      if (productAddedToCart) {
        setShowSummary(false);
        send("NEXT");
      }
    }

    // if this is successful, then

    // after a successful cart addition
  };

  const validateNumbers = () => {
    if (invitedFriends.length < noOfInvitees - 1)
      return toast.error(
        `Please add at least ${noOfInvitees - 1} ${
          noOfInvitees - 1 === 1 ? "contact" : "contacts"
        }`
      );

    let userOwnNumber = invitedFriends.some((entry) => entry.number === msisdn);

    if (userOwnNumber)
      return toast.error("You can not add your own phone number");

    const numbersOnly = invitedFriends.map((friend) => friend.number);

    let duplicate = new Set(numbersOnly).size !== numbersOnly.length;
    if (duplicate)
      return toast.error(
        "Duplicate phone number found. Please check the phone numbers provided"
      );

    send("NEXT");
  };

  if (!groupBuyEducationState) return <GroupBuyEducation />;
  else if (isLoading) {
    return <Loader />;
  } else {
    return (
      <Fragment>
        <DesktopBackgroundLayout>
          {showSummary ? (
            <GroupBuyOrderConfirmation
              product={product}
              unitPrice={unitPrice}
              orderQuantity={orderQuantity}
              addToCart={handleAddToCart}
              setShowSummary={setShowSummary}
            />
          ) : currentPage.value === pages.GROUPBUY_SUCCESS ? (
            <SuccessPage
              title="Added to cart Successfully"
              subtitle={`${name} item has been added to cart.Checkout before the order expires.`}
              successfull={true}
              showDetailButton={true}
              doneText={"View Cart and Checkout"}
              detailText={"Continue Shopping"}
              onDoneClick={() => history.push("/actions/merchbuy/cart")}
              onDetailClick={() => history.push("/actions/merchbuy")}
              icon={GroupBuyShoppingBag}
            />
          ) : (
            <TopHeader
              title={pageTitle}
              right={"16px"}
              size={"14px"}
              withSpacesHeader
            >
              {currentPage.value !== pages.GROUPBUY_SUMMARY && (
                <Paragraph
                  style={{
                    position: "absolute",
                    right: "15px",
                  }}
                >
                  {pageNumber}/{totalPages - 2}
                </Paragraph>
              )}
            </TopHeader>
          )}

          {currentPage.value !== pages.GROUPBUY_SUCCESS && (
            <Main margin={"0 0 60px"} background={"#F9FAFC"}>
              <Container
                top={"5px"}
                width={
                  currentPage.value === pages.GROUPBUY_SUMMARY ? "100%" : ""
                }
              >
                {currentPage.value !== pages.GROUPBUY_SUMMARY ? (
                  <>
                    <PageIndicatorContainer>
                      <PageIndicator width={pageIndicatorWidth} />
                    </PageIndicatorContainer>

                    {/* Product Quantity Selection */}
                    {currentPage.value === pages.QUANTITY_SELECTION ? (
                      <SelectQuantityToBuy
                        product={product}
                        orderQuantity={orderQuantity}
                        reduceQuantity={reduceQuantity}
                        increaseQuantity={increaseQuantity}
                        handleQuantityManualInput={handleQuantityManualInput}
                        currentPrice={currentPrice}
                      />
                    ) : null}

                    {/* sharing quantity selection */}
                    {currentPage.value !== pages.QUANTITY_SELECTION ? (
                      <>
                        <ProductImageBlock
                          product={product}
                          currentPrice={currentPrice}
                        />
                        <Title margin={"20px 0 15px"} color={"#718596"}>
                          Quantity
                        </Title>
                        <Card
                          flexDirection={"column"}
                          alignItems={"flex-start"}
                          justifyContent={"center"}
                          padding={"0 40px"}
                          margin={"0 0 0 auto"}
                          width={"97%"}
                          onClick={backToQuantitySelection}
                        >
                          <Paragraph margin={"0 0 7px"}>
                            {orderQuantity} {unitValue} for{" "}
                            <span
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              {formatPrice(currentPrice * orderQuantity)}
                            </span>
                          </Paragraph>
                          <ClickToEdit>Click to edit</ClickToEdit>

                          <ModifiedQuantityBuyIcon />
                        </Card>
                        {currentPage.value ===
                        pages.NUMBER_OF_BUYERS_SELECTION ? (
                          <SelectNumberOfBuyers
                            noOfInvitees={noOfInvitees}
                            handleInviteeQuantityManualInput={
                              handleInviteeQuantityManualInput
                            }
                            reduceQuantityToShare={reduceQuantityToShare}
                            orderQuantity={orderQuantity}
                            increaseQuantityToShare={increaseQuantityToShare}
                          />
                        ) : null}

                        {pageNumber > 2 ? (
                          <>
                            <Title margin={"20px 0 15px"} color={"#718596"}>
                              Participants
                            </Title>

                            <Card
                              flexDirection={"column"}
                              alignItems={"flex-start"}
                              justifyContent={"center"}
                              padding={"0 40px"}
                              margin={"0 0 0 auto"}
                              width={"97%"}
                              onClick={backToNumberOfBuyersSelection}
                            >
                              <Paragraph margin={"0 0 7px"}>
                                {noOfInvitees}{" "}
                                {noOfInvitees > 1 ? "people" : "person"}
                              </Paragraph>
                              <ClickToEdit>Click to edit</ClickToEdit>

                              <ModifiedParticipantsBuyIcon />
                            </Card>
                          </>
                        ) : null}
                        {/* Quota Allocation block is here */}
                        {currentPage.value === pages.QUANTITY_ALLOCATION ? (
                          <>
                            <Title margin={"40px 0 10px"}>Share Quantity</Title>

                            <SubTitle>
                              Allocate quantity to each person/slot
                            </SubTitle>

                            <GroupBuySplitQuantity
                              noOfInvitees={noOfInvitees}
                              unitPrice={unitPrice}
                              qtyLeftToShare={qtyLeftToShare}
                              setQtyLeftToShare={setQtyLeftToShare}
                              tempQtySharingSplits={tempQtySharingSplits}
                              setInvitedSlots={setInvitedSlots}
                            />
                          </>
                        ) : null}
                        {pageNumber > 3 ? (
                          <GroupBuySplitFriends
                            product={product}
                            noOfInvitees={noOfInvitees}
                            unitPrice={unitPrice}
                            invitedFriends={invitedFriends}
                            setInvitedFriends={setInvitedFriends}
                            backToQuantityAllocation={backToQuantityAllocation}
                            invitedSlots={invitedSlots}
                          />
                        ) : null}
                      </>
                    ) : null}
                  </>
                ) : (
                  <GroupBuySummary
                    product={product}
                    currentPrice={currentPrice}
                    orderQuantity={orderQuantity}
                    noOfInvitees={noOfInvitees}
                    invitedFriends={invitedFriends}
                    invitedSlots={invitedSlots}
                    unitPrice={unitPrice}
                  />
                )}

                {/* Group Summary Page */}
              </Container>
            </Main>
          )}

          <FixedBottom>
            <BuyButtons>
              <Button
                top={"10px"}
                type={"button"}
                color={cancelButtonColor}
                backgroundColor={"transparent"}
                margin={"0 8px 0 0"}
                disabled={currentPage.value === pages.QUANTITY_SELECTION}
                border={`1px solid ${cancelButtonColor}`}
                onClick={handleCancelButtonClick}
              >
                Back
              </Button>

              <Button
                top={"10px"}
                type={"button"}
                disabled={disableContinueButton}
                onClick={handleContinueButtonClick}
              >
                {currentPage.value === pages.GROUPBUY_SUMMARY
                  ? "Proceed"
                  : "Continue"}
              </Button>
            </BuyButtons>
          </FixedBottom>
        </DesktopBackgroundLayout>
      </Fragment>
    );
  }
};

export default GroupBuy;
