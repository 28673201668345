import { toast } from "react-toastify";

import { axios, currentAPI } from "../../../../../../config";
import {
  LOADING_SALES_REPORT,
  YEARLY_SALES_REPORT_SUCCESS,
  MONTHLY_SALES_REPORT_SUCCESS,
  WEEKLY_SALES_REPORT_SUCCESS,
  DAILY_SALES_REPORT_SUCCESS,
  SALES_REPORT_FAILURE,
  LOADING_PRODUCT_REPORT,
  PRODUCT_REPORT_SUCCESS,
  PRODUCT_REPORT_FAILURE,
  SALES_REPORTS_EXTRA_DATA_SUCCESS,
  DATE_RANGE_SALES_REPORT_SUCCESS,
  DATED_SALES_REPORT_SUCCESS,
} from "../../constants";

const getSalesReport =
  (
    recordType,
    setSalesReport,
    formatYearlyBarChartData,
    formatBarChartData,
    initialData
  ) =>
  async (dispatch, getState) => {
    const merchantId =
      getState().applications.myShop.shops[0]?.merchantId || "";
    dispatch({ type: LOADING_SALES_REPORT });
    try {
      const getSalesReportResponse = await axios.get(
        `${currentAPI}/api/merchantAppIntegration/salesRecords/v1/sales/records/${merchantId}?recordType=${recordType}`
      );

      if (getSalesReportResponse.status === 200) {
        dispatch({
          type: SALES_REPORTS_EXTRA_DATA_SUCCESS,
          payload: getSalesReportResponse.data.extras || {},
        });
        switch (recordType) {
          case "YEARLY":
            return dispatch({
              type: YEARLY_SALES_REPORT_SUCCESS,
              payload: getSalesReportResponse.data.data
                ? setSalesReport(
                    formatYearlyBarChartData(
                      [...initialData.initYearlyData],
                      [...getSalesReportResponse.data.data]
                    )
                  )
                : [],
            });
          case "MONTHLY":
            return dispatch({
              type: MONTHLY_SALES_REPORT_SUCCESS,
              payload: getSalesReportResponse.data.data
                ? setSalesReport(
                    formatBarChartData(
                      [...initialData.initMonthlyData],
                      [...getSalesReportResponse.data.data]
                    )
                  )
                : [],
            });
          case "WEEKLY":
            return dispatch({
              type: WEEKLY_SALES_REPORT_SUCCESS,
              payload: getSalesReportResponse.data.data
                ? setSalesReport(
                    formatBarChartData(
                      [...initialData.initWeeklyData],
                      [...getSalesReportResponse.data.data]
                    )
                  )
                : [],
            });
          case "DAILY":
            return dispatch({
              type: DAILY_SALES_REPORT_SUCCESS,
              payload: getSalesReportResponse.data.data
                ? setSalesReport(
                    formatBarChartData(
                      [...initialData.initDailyData],
                      [...getSalesReportResponse.data.data]
                    )
                  )
                : [],
            });
          default:
            return dispatch({
              type: WEEKLY_SALES_REPORT_SUCCESS,
              payload: [],
            });
        }
      }
    } catch (error) {
      dispatch({ type: SALES_REPORT_FAILURE });
      if (error.response && error.response.status === 400) {
        const { data } = (error && error.response) || {};
        data.message &&
          toast.error(
            error.response.data.message || "Sorry, an error occured!"
          );
      }
    }
  };

const getProductReport = (recordType) => async (dispatch, getState) => {
  const merchantId = getState().applications.myShop.shops[0]?.merchantId || "";
  dispatch({ type: LOADING_PRODUCT_REPORT });
  try {
    const getProductReportResponse = await axios.get(
      `${currentAPI}/api/merchantAppIntegration/salesRecords/v1/top/sales/products/${merchantId}?recordType=${recordType}`
    );

    if (getProductReportResponse.status === 200) {
      dispatch({
        type: PRODUCT_REPORT_SUCCESS,
        payload: getProductReportResponse.data || [],
      });
    }
  } catch (error) {
    dispatch({ type: PRODUCT_REPORT_FAILURE, payload: error.message });
    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message &&
        toast.error(error.response.data.message || "Sorry, an error occured!");
    }
  }
};

const getSalesReportByDateRange =
  (start, end, setSalesReport) => async (dispatch, getState) => {
    const merchantId =
      getState().applications.myShop.shops[0]?.merchantId || "";
    dispatch({ type: LOADING_SALES_REPORT });
    try {
      const getSalesReportResponse = await axios.get(
        `${currentAPI}/api/merchantAppIntegration/salesRecords/v1/sales/records/withDate/${merchantId}?from=${start}&to=${end}`
      );
      if (getSalesReportResponse.status === 200) {
        dispatch({
          type: SALES_REPORTS_EXTRA_DATA_SUCCESS,
          payload: getSalesReportResponse.data.extras || {},
        });
        dispatch({
          type: DATE_RANGE_SALES_REPORT_SUCCESS,
          payload: getSalesReportResponse.data.data
            ? setSalesReport([...getSalesReportResponse.data.data])
            : [],
        });
      }
    } catch (error) {
      dispatch({ type: SALES_REPORT_FAILURE });
      if (error.response && error.response.status === 400) {
        const { data } = (error && error.response) || {};
        data.message &&
          toast.error(
            error.response.data.message || "Sorry, an error occured!"
          );
      }
    }
  };

const getProductReportByDateRange =
  (start, end) => async (dispatch, getState) => {
    const merchantId = getState().applications.myShop.shops[0].merchantId;
    dispatch({ type: LOADING_PRODUCT_REPORT });
    try {
      const getProductReportResponse = await axios.get(
        `${currentAPI}/api/merchantAppIntegration/salesRecords/v1/top/sales/products/withDate/${merchantId}?from=${start}&to=${end}`
      );

      if (getProductReportResponse.status === 200) {
        dispatch({
          type: PRODUCT_REPORT_SUCCESS,
          payload: getProductReportResponse.data || [],
        });
      }
    } catch (error) {
      dispatch({ type: PRODUCT_REPORT_FAILURE, payload: error.message });
      if (error.response && error.response.status === 400) {
        const { data } = (error && error.response) || {};
        data.message &&
          toast.error(
            error.response.data.message || "Sorry, an error occured!"
          );
      }
    }
  };

const getSalesReportBySpecificDay =
  (date, setSalesReport, formatBarChartData, initialData) =>
  async (dispatch, getState) => {
    const merchantId =
      getState().applications.myShop.shops[0]?.merchantId || "";
    dispatch({ type: LOADING_SALES_REPORT });
    try {
      const getSalesReportResponse = await axios.get(
        `${currentAPI}/api/merchantAppIntegration/salesRecords/v1/sales/records/withSpecificDate/${merchantId}?from=${date}`
      );
      if (getSalesReportResponse.status === 200) {
        dispatch({
          type: SALES_REPORTS_EXTRA_DATA_SUCCESS,
          payload: getSalesReportResponse.data.extras || {},
        });
        dispatch({
          type: DATED_SALES_REPORT_SUCCESS,
          payload: getSalesReportResponse.data.data
            ? setSalesReport(
                formatBarChartData(
                  [...initialData.initDailyData],
                  [...getSalesReportResponse.data.data]
                )
              )
            : [],
        });
      }
    } catch (error) {
      dispatch({ type: SALES_REPORT_FAILURE });
      if (error.response && error.response.status === 400) {
        const { data } = (error && error.response) || {};
        data.message &&
          toast.error(
            error.response.data.message || "Sorry, an error occured!"
          );
      }
    }
  };

const getProductReportBySpecificDay = (date) => async (dispatch, getState) => {
  const merchantId = getState().applications.myShop.shops[0]?.merchantId || "";
  dispatch({ type: LOADING_PRODUCT_REPORT });
  try {
    const getProductReportResponse = await axios.get(
      `${currentAPI}/api/merchantAppIntegration/salesRecords/v1/top/sales/products/withSpecificDate/${merchantId}?from=${date}`
    );

    if (getProductReportResponse.status === 200) {
      dispatch({
        type: PRODUCT_REPORT_SUCCESS,
        payload: getProductReportResponse.data || [],
      });
    }
  } catch (error) {
    dispatch({ type: PRODUCT_REPORT_FAILURE, payload: error.message });
    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message &&
        toast.error(error.response.data.message || "Sorry, an error occured!");
    }
  }
};

export const reportActions = {
  getSalesReport,
  getProductReport,
  getSalesReportByDateRange,
  getProductReportByDateRange,
  getSalesReportBySpecificDay,
  getProductReportBySpecificDay,
};
