import React from "react";

import { ErrorMessage } from "formik";
import { string, bool, any, func, object } from "prop-types";
import styled from "styled-components";

import { Clear } from "../../../../containers/HeaderContainer";
import {
  InputLabelBlock,
  InputWithValidation,
} from "../../../../containers/InputContainer";

const InputLabel = styled.label`
  font-size: 10px;
  position: absolute;
  top: 8px;
  height: 13px;
  margin-left: ${({ countryselection }) => (countryselection ? "56px" : "0")};
  display: none;
  color: ${({ color }) => color || "#718596"};
`;

const InputBlock = styled(InputLabelBlock)`
  /* border-radius: 8px; */
  // border: solid 1px #d8d8d8;
`;

const Children = styled.div`
  position: absolute;
  right: 8px;
  top: 12px;
`;

// very similar to inputWithLabel component, but created instead of modifying that component to avoid introducing a breaking change accross the app

export const InputWithOnchange = (props) => {
  const {
    label,
    id,
    placeholder,
    background,
    color,
    borderRadius,
    valid,
    errors,
    value,
    name,
    width,
    right,
    left,
    top,
    height,
    readOnly,
    inputMode,
    type,
    disabled,
    onKeyUp,
    onBlur,
    onFocus,
    onChange,
    countryselection,
    autoComplete,
    touched,
    setFieldValue,
    initialValues,
    noClearButton,
    as,
    maxLength,
    bottom,
    onKeyPress,
    pattern,
    step,
    list,
    nolabelmargin,
    autoFocus,
    children,
    borderColor,
    lineHeight,
  } = props;

  return (
    <InputBlock
      borderRadius={borderRadius}
      background={background}
      left={left}
      right={right}
      id={id}
      height={height}
      width={width}
      valid={valid}
      error={errors}
      bottom={bottom}
      top={top}
      borderColor={borderColor}
    >
      <InputWithValidation
        placeholder={placeholder}
        background={background}
        countryselection={countryselection && countryselection.toString()}
        nolabelmargin={nolabelmargin && nolabelmargin.toString()}
        touched={touched && touched.toString()}
        inputMode={inputMode && inputMode.toString()}
        autoComplete={autoComplete && autoComplete.toString()}
        valid={valid && valid.toString()}
        errors={errors}
        readOnly={readOnly}
        onBlur={onBlur}
        onKeyUp={onKeyUp}
        onFocus={onFocus}
        onChange={onChange}
        value={value}
        name={name}
        as={as}
        type={type}
        disabled={disabled}
        maxLength={maxLength}
        onKeyPress={onKeyPress}
        autoFocus={autoFocus}
        pattern={pattern}
        step={step}
        list={list}
        lineHeight={lineHeight}
      />
      <InputLabel
        color={color}
        countryselection={countryselection}
        touched={touched && touched.toString()}
      >
        {label}
      </InputLabel>
      {type === "text" && value && noClearButton === false && (
        <Clear
          right={"true"}
          onClick={() => {
            document.querySelector(`input[name=${name}]`).value = "";
            setFieldValue(name, initialValues[name]);
          }}
        />
      )}
      <Children>{children}</Children>
      <ErrorMessage name={name} component="div" className="form-error" />
    </InputBlock>
  );
};

InputWithOnchange.propTypes = {
  label: string.isRequired,
  placeholder: string.isRequired,
  name: string.isRequired,
  id: string,
  countrySelection: string,
  autoComplete: string,
  inputMode: string,
  valid: string,
  errors: string,
  width: string,
  height: string,
  type: string,
  right: string,
  left: string,
  top: string,
  as: string,
  noClearButton: bool,
  readOnly: bool,
  disabled: bool,
  touched: bool,
  onKeyUp: func,
  onChange: func,
  setFieldValue: func,
  initialValues: object,
  value: any,
  list: any,
  nolabelmargin: bool,
  autoFocus: bool,
};
