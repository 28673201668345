import React, { Fragment, useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { TopHeader, Button, RippleLink } from "../../../../components";
import { ScreenContainer } from "../../../../containers/ScreenContainer";
import { remittanceActions } from "../../../../redux/ducks/applications/remittance/actions";
import { colors } from "../../../../styles";
import { formatPrice } from "../../../../utils/currency/formatPriceWithComma";
import { parseMonthDateYear } from "../../../../utils/date/parseDateFromStr";
import { VIEW_REMITTANCE_HISTORY } from "../../../../utils/mix-panel/constants";
import { mixPanel } from "../../../../utils/mix-panel/mixPanel";
import DesktopBackgroundLayout from "../../../DesktopBackgroundLayout";
import { ReactComponent as Bulb } from "../assets/bulb.svg";
import NoRemittanceIcon from "../assets/no_remittance.svg";
import {
  FilterSection,
  RemittanceBlock,
  RemittanceImage,
  RemittanceInfo,
  RemitDate,
  RemitOrders,
  FilterChip,
  MetricBoard,
  StatusAndDot,
  StyledRemitIcon,
  StyledHomeIcon,
  FlexContainer,
  Amount,
  Text,
  ColoredCircle,
  NoRemittanceWrapper,
  NoRemittanceLogo,
  EmptyStateText,
} from "../styles";

const ButtonContainer = styled(RippleLink)`
  position: fixed;
  right: calc(50vw - 180px);
  bottom: 25px;
  width: 110px;
  align-items: center;
  @media (max-width: 576px) {
    right: 16px;
  }
`;

export const SingleRemittanceHistory = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const outstandingAmount = useSelector(
    (state) => state.applications.remittance.outstandingAmount
  );
  const [activeFilter, setActiveFilter] = useState("");
  const [remittanceDetails, setRemitanceDetails] = useState({});
  const [status, setStatus] = useState("");
  const { orderId } = location?.state || {};

  const toPositive = (n) => {
    if (remittanceDetails?.outstandingAmount < 0) {
      n = formatPrice(remittanceDetails?.outstandingAmount * -1);
    }
    return n;
  };

  useEffect(() => {
    async function fetchPaymentHistory() {
      const response = await dispatch(
        remittanceActions.getPaymentHistoryByOrderId(orderId, activeFilter)
      );
      response && setRemitanceDetails(response);
    }
    fetchPaymentHistory();
  }, [dispatch, activeFilter, orderId]);

  useEffect(() => {
    mixPanel.track(VIEW_REMITTANCE_HISTORY, {
      "Pending remittance on account": outstandingAmount,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setFilter = (name) => {
    setActiveFilter(name);
    if (status !== name) {
      setStatus(name);
    } else {
      setStatus("");
      setActiveFilter("");
    }
  };

  return (
    <Fragment>
      <DesktopBackgroundLayout>
        <TopHeader title="Remittance History" size="14px" withCancelIcon />
        <ScreenContainer height="100%" top="45px" color={colors.lightestGray}>
          <FlexContainer>
            <MetricBoard right="4px">
              <Text>Total Remitted</Text>
              <Text
                color={colors.black}
                size="16px"
                lineHeight="24px"
                weight="700"
              >
                {formatPrice(remittanceDetails?.currentPaidAmount || 0)}
              </Text>
            </MetricBoard>
            <MetricBoard left="4px">
              <Text>Balance</Text>
              <Text
                color={colors.darkRed}
                size="16px"
                lineHeight="24px"
                weight="700"
              >
                {toPositive(
                  formatPrice(remittanceDetails?.outstandingAmount || 0)
                )}
              </Text>
            </MetricBoard>
          </FlexContainer>
          <FilterSection top={"0px"}>
            <FilterChip
              active={activeFilter === ""}
              onClick={() => setFilter("")}
            >
              All
            </FilterChip>
            <FilterChip
              active={activeFilter === "APPROVED"}
              onClick={() => setFilter("APPROVED")}
            >
              Success
            </FilterChip>
            <FilterChip
              active={activeFilter === "PENDING"}
              onClick={() => setFilter("PENDING")}
            >
              Pending
            </FilterChip>
            <FilterChip
              active={activeFilter === "REJECTED"}
              onClick={() => setFilter("REJECTED")}
            >
              Rejected
            </FilterChip>
          </FilterSection>

          {!remittanceDetails?.transactions ||
          remittanceDetails?.transactions?.length === 0 ? (
            <NoRemittanceWrapper>
              <NoRemittanceLogo icon={NoRemittanceIcon} />
              <EmptyStateText top="18px" size="14px" color={colors.boldDark}>
                You don’t have any orders
              </EmptyStateText>
              <Bulb width={"100%"} />
              <EmptyStateText weight="400" top="8px" lineHeight="18px">
                When you make a purchase, you can track your items in orders.
              </EmptyStateText>
            </NoRemittanceWrapper>
          ) : (
            <>
              {remittanceDetails?.transactions &&
                remittanceDetails.transactions.map((item, index) => (
                  <RemittanceBlock key={index}>
                    <RemittanceImage>
                      <StyledHomeIcon />
                    </RemittanceImage>
                    <RemittanceInfo>
                      <FlexContainer>
                        <RemitDate>
                          {parseMonthDateYear(item.paymentDate)}
                        </RemitDate>
                        <Amount>{formatPrice(item.amountPaid || 0)}</Amount>
                      </FlexContainer>
                      <FlexContainer>
                        <RemitOrders>{`Order ${remittanceDetails.orderNumber}`}</RemitOrders>
                        <StatusAndDot>
                          <ColoredCircle
                            status={(item.approvalStatus || "").toUpperCase()}
                          />
                          {item?.approvalStatus && `${item.approvalStatus}`}
                        </StatusAndDot>
                      </FlexContainer>
                    </RemittanceInfo>
                  </RemittanceBlock>
                ))}
            </>
          )}
          <ButtonContainer
            to={{
              pathname: "/actions/remittance/remittance-single-order",
              state: remittanceDetails,
            }}
          >
            <Button type={"button"} radius="40px">
              <StyledRemitIcon /> Remit
            </Button>
          </ButtonContainer>
        </ScreenContainer>
      </DesktopBackgroundLayout>
    </Fragment>
  );
};
