import React, { Fragment, useEffect, useState } from "react";

import Skeleton from "react-loading-skeleton";
import { useSelector, useDispatch } from "react-redux";
import { matchPath } from "react-router-dom";
import styled from "styled-components";
import useRouter from "use-react-router";

import { ReactComponent as Descending } from "../../../../assets/high_to_low.svg";
import { ReactComponent as PriceSVG } from "../../../../assets/tag_price.svg";
import { ReactComponent as Ascending } from "../../../../assets/Vector.svg";
import {
  SearchHeader,
  OptionsPopupDialog,
  DeliveryLocationPopup,
  InfiniteScrollList,
} from "../../../../components/";
import {
  FilterAndSortSection,
  SortFilterCell,
} from "../../../../containers/ScreenContainer";
import { merchbuyActions } from "../../../../redux/ducks/applications/merchbuy/actions";
import { colors } from "../../../../styles";
import DesktopBackgroundLayout from "../../../DesktopBackgroundLayout";
import { ReactComponent as ArrowIcon } from "../assets/arrow.svg";
import { Cart, ListProducts } from "../components/";
import { Main, Container } from "../styles";
import { filterProducts } from "../utils/filterProducts";
import { sortProducts } from "../utils/sortProducts";

// import ProductSkeleton from './productSkeleton';

const Cell = styled.div`
  margin-right: 50px;
`;

const Card = styled.div`
  display: grid;
  width: 100%;
  gap: 10px;
  borderradius: 8px;
`;

const List = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3px;
  margin-top: -10px;
`;

const CardImagePlaceHolder = styled.div`
  width: 100%;
  height: 100px;
`;

const MerchbuyProducts = () => {
  const dispatch = useDispatch();

  const productsByCategory = useSelector(
    (state) => state.applications.merchbuy.merchbuyProducts
  );
  const numberOfElements = useSelector(
    (state) => state.applications.merchbuy.numberOfElements
  );

  const { location } = useRouter();
  const { pathname } = location;
  const pattern = `(.*)?${"/actions/merchbuy/products/:name/:id"}`;
  const match = matchPath(pathname, { path: pattern }) || {};

  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [openFilterOptions, setOpenFilterOptions] = useState(false);
  const [perPage] = useState(20);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [sortOptionsOpen, setSortOptionsOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [sortType, setSortType] = useState("Sort");
  const [filterType, setFilterType] = useState("Filter");
  const [deliveryLocation, setDeliveryLocation] = useState(
    JSON.parse(localStorage.getItem("deliveryLocation"))
  );

  const [openDeliverLocationPopup, setOpenDeliverLocationPopup] = useState(
    deliveryLocation === null
  );

  const getLocation = (data) => {
    if (data.state /*&& data.lga*/) {
      setDeliveryLocation(data);
      setOpenDeliverLocationPopup(!openDeliverLocationPopup);
    }
  };

  useEffect(() => {
    if (productsByCategory && productsByCategory.length < numberOfElements) {
      setPage(2);
      setHasMore(true);
    } else {
      setHasMore(false);
    }
  }, [productsByCategory, numberOfElements]);

  const addMoreDataToDisplay = async () => {
    if (products && products.length < numberOfElements) {
      const data = await dispatch(
        merchbuyActions.getProductListByProductCategoryID(
          match.params.id,
          page,
          perPage
        )
      );
      setPage(page + 1);
      setProducts([...products, ...data]);
    } else {
      setHasMore(false);
    }
  };

  useEffect(() => {
    dispatch(
      merchbuyActions.getProductListByProductCategoryID(
        match.params.id,
        page,
        perPage
      )
    );
  }, [dispatch, match.params.id, page, perPage]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    productsByCategory &&
      setProducts(
        productsByCategory.filter((data) =>
          data.name.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
  }, [searchValue, productsByCategory]);

  useEffect(() => {
    productsByCategory &&
      sortProducts(sortType, productsByCategory, setProducts);
  }, [sortType, productsByCategory, setProducts]);

  useEffect(() => {
    productsByCategory &&
      filterProducts(filterType, productsByCategory, setProducts);
  }, [filterType, productsByCategory, setProducts]);

  return (
    <Fragment>
      <DesktopBackgroundLayout>
        <SearchHeader
          backgroundColor={"#F9FAFC"}
          title={match.params.name}
          right={"56px"}
          maxWidth={"150px"}
          transform={"capitalize"}
          merchBuySearch
          sticky
          placeholder={"Search for products..."}
          handleSearch={setSearchValue}
          withSpacesHeader
        >
          <Cart />
        </SearchHeader>
        <Main color={"#F9FAFC"}>
          <FilterAndSortSection
            top={"7px"}
            bottom={"13px"}
            background={colors.white}
          >
            <SortFilterCell
              weight={"500"}
              height={"40px"}
              color={colors.themeTxtColor10}
              justifyContent={"center"}
              width={"100%"}
              border={`1px solid ${colors.border.top}`}
              onClick={() => setSortOptionsOpen(!sortOptionsOpen)}
            >
              <Cell>{sortType}</Cell>
              <ArrowIcon style={{ marginLeft: "35px" }} />
            </SortFilterCell>
            <SortFilterCell
              weight={"500"}
              height={"40px"}
              color={colors.themeTxtColor10}
              justifyContent={"center"}
              width={"100%"}
              border={`1px solid ${colors.border.top}`}
              onClick={() => setOpenFilterOptions(!openFilterOptions)}
            >
              <Cell>{filterType}</Cell>
              <ArrowIcon style={{ marginLeft: "35px" }} />
            </SortFilterCell>
          </FilterAndSortSection>
          <List>
            {isLoading &&
              Array(9)
                .fill()
                .map((_item, index) => (
                  <Card key={index}>
                    <CardImagePlaceHolder>
                      <Skeleton
                        width={`162px`}
                        height={`107px`}
                        style={{
                          gap: "10px",
                          marginTop: "10px",
                          borderRadius: "8px",
                          overflow: "hidden",
                          perspective: "1px",
                          margin: "10px 10px 0px 10px",
                        }}
                      />
                    </CardImagePlaceHolder>
                    <Skeleton
                      width={`109px`}
                      height={`12px`}
                      style={{
                        margin: "12px 0 12px 11px",
                      }}
                    />
                    <div style={{ marginTop: "-13px", marginLeft: "11px" }}>
                      <Skeleton width={`47px`} height={`12px`} />
                    </div>
                  </Card>
                ))}
          </List>
          {!isLoading && products.length > 0 && (
            <InfiniteScrollList
              data={products}
              fetchMore={addMoreDataToDisplay}
              hasMore={hasMore}
              endMessage=""
              color={"#F9FAFC"}
            >
              <Container>
                <ListProducts data={products} size={{ width: "146px" }} />
              </Container>
            </InfiniteScrollList>
          )}
        </Main>
        <OptionsPopupDialog
          open={sortOptionsOpen}
          title={"Sort By"}
          itemPadding="10px 0"
          itemMargin="0 16px"
          cancel={() => {
            setSortType("Sort");
            setSortOptionsOpen(!sortOptionsOpen);
          }}
          items={[
            {
              Icon: Ascending,
              title: "Price: High to Low",
              click: () => {
                setSortOptionsOpen(!sortOptionsOpen);
                sortType !== "Highest Price" && setSortType("Highest Price");
              },
            },
            {
              Icon: Descending,
              title: "Price: Low to High",
              click: () => {
                setSortOptionsOpen(!sortOptionsOpen);
                sortType !== "Lowest Price" && setSortType("Lowest Price");
              },
            },
            {
              Icon: Ascending,
              title: "None",
              click: () => {
                setSortOptionsOpen(!sortOptionsOpen);
                sortType !== "Sort" && setSortType("Sort");
              },
            },
          ]}
        />
        <OptionsPopupDialog
          open={openFilterOptions}
          title={"Filter"}
          itemPadding="10px 0"
          itemMargin="0 16px"
          cancel={() => {
            setFilterType("None");
            setOpenFilterOptions(!openFilterOptions);
          }}
          items={[
            {
              Icon: PriceSVG,
              title: "N0 to N5,000",
              click: () => {
                setOpenFilterOptions(!openFilterOptions);
                filterType !== "N0 to N5,000" && setFilterType("N0 to N5,000");
              },
            },
            {
              Icon: PriceSVG,
              title: "N5,000 to N50,000",
              click: () => {
                setOpenFilterOptions(!openFilterOptions);
                filterType !== "N5,000 to N50,000" &&
                  setFilterType("N5,000 to N50,000");
              },
            },
            {
              Icon: PriceSVG,
              title: "N50,000 to N100,000",
              click: () => {
                setOpenFilterOptions(!openFilterOptions);
                filterType !== "N50,000 to N100,000" &&
                  setFilterType("N50,000 to N100,000");
              },
            },
            {
              Icon: PriceSVG,
              title: "N100,000 to N500,000",
              click: () => {
                setOpenFilterOptions(!openFilterOptions);
                filterType !== "N100,000 to N500,000" &&
                  setFilterType("N100,000 to N500,000");
              },
            },
            {
              Icon: PriceSVG,
              title: "N500,000 and Above",
              click: () => {
                setOpenFilterOptions(!openFilterOptions);
                filterType !== "N500,000 and Above" &&
                  setFilterType("N500,000 and Above");
              },
            },
            {
              Icon: PriceSVG,
              title: "None",
              click: () => {
                setOpenFilterOptions(!openFilterOptions);
                filterType !== "Filter" && setFilterType("Filter");
              },
            },
          ]}
        />
        {openDeliverLocationPopup && (
          <DeliveryLocationPopup
            open={openDeliverLocationPopup}
            getLocation={getLocation}
            cancel={() => {
              setOpenDeliverLocationPopup(!openDeliverLocationPopup);
            }}
          />
        )}
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default MerchbuyProducts;
