import { push } from "connected-react-router";
import { toast } from "react-toastify";

import { currentAPI, axios } from "../../../../../config";
import { countriesMap } from "../../../../../data/countries";
import History from "../../../../../utils/History";
import { insertCountryCode } from "../../../../../utils/inputs/formatPhoneNumber";
import {
  CHECKOUT_WITH_WALLET,
  CHECKOUT_WITH_WALLET_SUCCESS,
} from "../../../../../utils/mix-panel/constants";
import { mixPanel } from "../../../../../utils/mix-panel/mixPanel";
import { getWalletBalance } from "../../../account/wallet/actions";
import {
  SENDING_CODE,
  SENDING_CODE_SUCCESS,
  SENDING_CODE_ERROR,
} from "../../../auth/check/constants";
import { resetCounter, setExpired } from "../../../timer/actions";
import { getUserData } from "../../../user/actions";
import {
  LOADING,
  FETCH_RECOMMENDED_PRODUCTS_SUCCESS,
  FETCH_RECOMMENDED_PRODUCTS_FAILURE,
  FETCH_POPULAR_PRODUCTS_SUCCESS,
  FETCH_POPULAR_PRODUCTS_FAILURE,
  FETCH_ALL_PRODUCTS_SUCCESS,
  FETCH_ALL_PRODUCTS_FAILURE,
  MINIMUM_ORDER_VALUE,
  FETCH_PRODUCTS_CATEGORIES_SUCCESS,
  FETCH_PRODUCTS_CATEGORIES_FAILURE,
  FETCH_SHOP,
  FETCH_SHOPS,
  FETCH_SHOPS_SUCCESS,
  FETCH_SHOPS_FAILURE,
  FETCH_AVAILABLE_STATES_SUCCESS,
  FETCH_AVAILABLE_STATES_FAILURE,
  FETCH_PRODUCT_BY_ID_SUCCESS,
  FETCH_PRODUCT_BY_ID_FAILURE,
  FETCH_SHOP_BY_ID_SUCCESS,
  FETCH_SHOP_BY_ID_FAILURE,
  FETCH_PRODUCTS_BY_SHOP_ID_SUCCESS,
  FETCH_PRODUCTS_BY_SHOP_ID_FAILURE,
  FETCH_SHOP_CART,
  FETCH_SHIPPING_OPTIONS,
  SAVE_LAST_ORDER_PLACED,
  MERCHBUY_DELIVERY_LOCATION,
  MERCHBUY_ORDER_HISTORY_SUCCESS,
  MERCHBUY_ORDER_HISTORY_FAILURE,
  PROCESSING_ORDER,
  SET_CHOSEN_ORDER_HISTORY,
  FETCH_CHOSEN_ORDER_HISTORY_FAILURE,
  CLEAR_CHOSEN_ORDER_HISTORY,
  LOADING_ORDER_HISTORIES,
  LOADING_ORDER_HISTORY,
  LOADING_ORDER_HISTORIES_BY_ITEMS,
  LOADING_GROUPBUY_ORDER_HISTORY,
  GROUPBUY_ORDER_HISTORY_SUCCESS,
  GROUPBUY_ORDER_HISTORY_FAILURE,
  FETCH_ALL_CITIES_FAILURE,
  FETCH_ALL_CITIES_SUCCESS,
  MERCHBUY_PAGINATED_SHOP_HISTORY_SUCCESS,
  MERCHBUY_PAGINATED_SHOP_HISTORY_FAILURE,
  MERCHBUY_PAGINATED_PRODUCT_HISTORY_SUCCESS,
  DELIVERY_ADDRESS_EDIT_LIMIT,
  FETCH_SHIPPING_CONFIGURATION,
  GROUP_BUY_EDUCATION,
  MERCHBUY_PAGINATED_PRODUCT_HISTORY_FAILURE,
  SAVE_PRODUCT_SEARCH_TERM,
  FETCH_MERCHBUY_DEALS_SUCCESS,
  FETCH_MERCHBUY_SEARCH_HISTORY_SUCCESS,
  FETCH_MERCHBUY_CATEGORY_BANNER_SUCCESS,
  FETCH_MERCHBUY_ALL_PRODUCTS_SUCCESS,
  FETCH_MERCHBUY_CART_CATEGORY_SUCCESS,
  FETCH_MERCHBUY_DEALS_FAILURE,
  FETCH_MERCHBUY_SEARCH_HISTORY_FAILURE,
  FETCH_MERCHBUY_CATEGORY_BANNER_FAILURE,
  FETCH_MERCHBUY_ALL_PRODUCTS_FAILURE,
  FETCH_MERCHBUY_CART_CATEGORY_FAILURE,
  MERCHBUY_PAGINATED_ORDER_HISTORY_SUCCESS,
  MERCHBUY_FILTER_ORDER_HISTORY_SUCCESS,
  MERCHBUY_ORDER_HISTORY_BY_ITEMS_SUCCESS,
  MERCHBUY_FILTER_ORDER_HISTORY_BY_ITEMS_SUCCESS,
  MERCHBUY_PAGINATED_ORDER_HISTORY_BY_ITEMS_SUCCESS,
  MERCHBUY_PAGINATED_CATEGORIES_SUCCESS,
  MERCHBUY_PAGINATED_CATEGORIES_FAILURE,
  FETCH_CATEGORIES,
  SAVE_LAST_DELIVERY_ADDRESS,
  FETCH_NEW_AVAILABLE_STATES_SUCCESS,
  FETCH_NEW_AVAILABLE_STATES_FAILURE,
  FETCH_AVAILABLE_COUNTRIES_SUCCESS,
  FETCH_AVAILABLE_COUNTRIES_FAILURE,
} from "../constants";

const getWareNextDeliveryLocations = () => async (dispatch) => {
  dispatch(request());

  try {
    const deliveryLocationsResponse = await axios.get(
      `${currentAPI}/api/warenext/delivery/locations`
    );

    dispatch(stoploading());
    if (deliveryLocationsResponse.status === 200) {
      // dispatch({
      //     type: FETCH_AVAILABLE_STATES_SUCCESS,
      //     payload: aVailableStatesResponse.data.data
      // });
    }
  } catch (error) {
    dispatch(stoploading());
    // dispatch({
    //     type: FETCH_AVAILABLE_STATES_FAILURE,
    //     payload: error.response.data.message
    // });
    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
  }
};

const getWareNextShippingPrices = (params) => async (dispatch) => {
  dispatch(request());
  try {
    const shippingPricesResponse = await axios.post(
      `${currentAPI}/api/warenext/delivery/prices`,
      JSON.stringify(params)
    );

    dispatch(stoploading());
    if (shippingPricesResponse.status === 200) {
      dispatch({
        type: FETCH_SHIPPING_OPTIONS,
        payload: shippingPricesResponse.data,
      });
    }
  } catch (error) {
    dispatch(stoploading());
    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
  }
};
const createWareNextShippingOrder = (params) => async (dispatch) => {
  dispatch(request());
  try {
    const shippingOrderResponse = await axios.post(
      `${currentAPI}/api/warenext/delivery`,
      JSON.stringify(params)
    );

    dispatch(stoploading());
    if (shippingOrderResponse.status === 200) {
      return shippingOrderResponse.data;
    }
  } catch (error) {
    dispatch(stoploading());
    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
  }
};

const getWareNextShippingOptions = (params) => async (dispatch) => {
  dispatch(request());
  try {
    const shippingPricesResponse = await axios.post(
      `${currentAPI}/api/warenext/v3/delivery/prices`,
      JSON.stringify(params)
    );

    dispatch(stoploading());
    if (shippingPricesResponse.status === 200) {
      dispatch({
        type: FETCH_SHIPPING_OPTIONS,
        payload: shippingPricesResponse.data,
      });
    }
  } catch (error) {
    dispatch(stoploading());
    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
  }
};

const trackWareNextShippingOrder = (orderNumber) => async (dispatch) => {
  dispatch(request());
  try {
    const shopDetialsResponse = await axios.get(
      `${currentAPI}/api/warenext/delivery/track/${orderNumber}`
    );

    dispatch(stoploading());
    if (shopDetialsResponse.status === 200) {
      // dispatch({
      //     type: FETCH_SHOP_BY_ID_SUCCESS,
      //     payload: shopDetialsResponse.data.data
      // });
    }
  } catch (error) {
    dispatch(stoploading());
    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
  }
};

const updateShippingLocally = (data) => async (dispatch) => {
  dispatch({
    type: FETCH_SHIPPING_OPTIONS,
    payload: data,
  });
};

const updateDeliveryLocation = (params) => async (dispatch) => {
  //dispatch(request());
  try {
    //
    // const updateDeliveryLocationResponse = await axios.post(
    //     `${currentAPI}/api/cart/products`,
    //     JSON.stringify(params),
    //     {
    //         headers: {
    //             Authorization: `Bearer ${token}`
    //         }
    //     }
    // );

    //dispatch(stoploading());
    //if (updateDeliveryLocationResponse.status === 200) {
    dispatch({
      type: MERCHBUY_DELIVERY_LOCATION,
      //payload: addProductToCartResponse.data.items
      payload: params,
    });
    // dispatch(
    //     push({
    //         pathname: "/actions/merchbuy/to-cart",
    //         params
    //     })
    // );
    //}
  } catch (error) {
    //dispatch(stoploading());
    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
  }
};

const saveLastDeliveryAddress = (params) => async (dispatch) => {
  try {
    dispatch({
      type: SAVE_LAST_DELIVERY_ADDRESS,
      payload: params,
    });
  } catch (error) {
    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
  }
};

const placeOrder = (params, paymentType, fromBuyNow) => async (dispatch) => {
  dispatch({
    type: PROCESSING_ORDER,
    payload: true,
  });
  try {
    const orderResponse = await axios.post(
      `${currentAPI}/api/merchantAppIntegration/orders/Order/process`,
      JSON.stringify(params)
    );

    if (orderResponse.status === 200) {
      const { data, status, message } = orderResponse.data || {};
      if (!status) {
        dispatch(push("/actions/merchbuy/cart"));
        toast.info(message);
        return {
          status,
        };
      }
      const {
        id,
        orderNumber,
        email,
        subTotal,
        totalPrice,
        deliveryFee,
        orderDelivery,
      } = data || {};
      const { firstName, lastName, deliveryOption } = orderDelivery || {};
      const order = {
        id,
        orderNumber,
        email,
        subTotal,
        totalPrice,
        deliveryFee,
        firstname: firstName,
        lastname: lastName,
        deliveryOption,
      };

      dispatch({
        type: SAVE_LAST_ORDER_PLACED,
        payload: order,
      });

      !fromBuyNow && localStorage.removeItem("cartItems");

      return {
        status,
        message: "",
        paymentType,
        order,
      };
    }
  } catch (error) {
    const { message } = (error.response && error.response.data) || {};
    return {
      status: false,
      message,
      paymentType,
      order: null,
    };
  } finally {
    dispatch({
      type: PROCESSING_ORDER,
      payload: false,
    });
  }
};

const payWithWallet = (params, mixpanelPayload) => async (dispatch) => {
  dispatch({
    type: PROCESSING_ORDER,
    payload: true,
  });

  const id = params.order.id;
  const totalPrice = params.order.totalPrice;
  const email = params.order.email;
  const subTotal = params.order.subTotal;
  const deliveryFee = params.order.deliveryFee;
  const orderNumber = params.order.orderNumber;

  mixPanel.track(CHECKOUT_WITH_WALLET, mixpanelPayload);

  const orderDetail = {
    amount: totalPrice,
    externalProcessCode: id,
    narration: "ORDER|PAYMENT|" + totalPrice + "|" + id,
    transactionCharge: 0,
    transactionServiceType: "MERCHBUY",
  };

  try {
    const paymentDetailResponse = await axios.post(
      `${currentAPI}/api/merchantAppIntegration/orders/Order/payWithWallet`,
      JSON.stringify(orderDetail)
    );

    if (paymentDetailResponse.status === 200) {
      const { transaction } = paymentDetailResponse.data || {};
      const trxRef = transaction?.internalTransactionReference || "";
      const paymentDetail = {
        orderId: id,
        orderNumber: orderNumber,
        partnerCode: "",
        payment: {
          deliveryCharge: deliveryFee,
          email: email,
          message: "",
          paymentMethod: 3,
          redirecturl: "",
          reference: trxRef,
          response: "",
          responseString: "",
          serviceCharge: 0,
          status: "",
          subTotal: subTotal,
          total: totalPrice,
          transactionId: trxRef,
          transactionReference: trxRef,
        },
        referralCode: "",
      };
      dispatch(getWalletBalance());

      const confirmTransaction = await dispatch(
        sendPaymentDetails(paymentDetail)
      );
      if (confirmTransaction) {
        mixPanel.track(CHECKOUT_WITH_WALLET_SUCCESS, mixpanelPayload);
      }

      return confirmTransaction;
    }
  } catch (error) {
    if (error.response && error.response.data) {
      //Not enough funds in your wallet. Please top it up.
    }
    return null;
  } finally {
    setTimeout(function () {
      dispatch({
        type: PROCESSING_ORDER,
        payload: false,
      });
    }, 2000);
  }
};

const sendPaymentDetails = (params) => async (dispatch) => {
  dispatch({
    type: PROCESSING_ORDER,
    payload: true,
  });
  try {
    const paymentDetailResponse = await axios.post(
      `${currentAPI}/api/merchantAppIntegration/orders/Order/transaction`,
      JSON.stringify(params)
    );
    if (paymentDetailResponse.status === 200) {
      return params.orderNumber;
    }
  } catch (error) {
    return null;
  } finally {
    setTimeout(function () {
      dispatch({
        type: PROCESSING_ORDER,
        payload: false,
      });
    }, 2000);
  }
};

const getAVailableStates = () => async (dispatch) => {
  dispatch(request());

  try {
    const aVailableStatesResponse = await axios.get(
      `${currentAPI}/api/merchantBuyIntegration/branches/availableState`
    );
    dispatch(stoploading());
    if (aVailableStatesResponse.status === 200) {
      dispatch({
        type: FETCH_AVAILABLE_STATES_SUCCESS,
        payload: aVailableStatesResponse.data.data,
      });
    }
  } catch (error) {
    dispatch(stoploading());
    dispatch({
      type: FETCH_AVAILABLE_STATES_FAILURE,
      payload: error.response?.data?.message || "",
    });
    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
  }
};

const getAllCities = () => async (dispatch) => {
  dispatch(request());
  try {
    const response = await axios.get(
      `${currentAPI}/api/warenext/delivery/cities`
    );
    dispatch(stoploading());
    if (response.status === 200) {
      dispatch({
        type: FETCH_ALL_CITIES_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch(stoploading());
    dispatch({
      type: FETCH_ALL_CITIES_FAILURE,
      payload: error?.response?.data?.message || "",
    });
  }
};

const getProductCategories =
  (page = 1, pageSize, location) =>
  async (dispatch) => {
    if (!navigator.onLine) return;

    dispatch({
      type: FETCH_CATEGORIES,
    });

    const deliveryLocation =
      JSON.parse(localStorage.getItem("deliveryLocation")) || location;
    const params = {
      page,
      size: pageSize,
      state: deliveryLocation && deliveryLocation.state,
    };

    try {
      const ProductCategoriesResponse = await axios.get(
        `${currentAPI}/api/merchantBuyIntegration/branches/all/category?`,
        { params }
      );

      dispatch(stoploading());

      if (ProductCategoriesResponse.status === 200) {
        dispatch({
          type: FETCH_PRODUCTS_CATEGORIES_SUCCESS,
          payload: {
            productCategory: ProductCategoriesResponse.data.data.content,
            totalElements: ProductCategoriesResponse.data.data.totalElements,
          },
        });
        return ProductCategoriesResponse.data.data.content;
      }
    } catch (error) {
      dispatch(stoploading());
      dispatch({
        type: FETCH_PRODUCTS_CATEGORIES_FAILURE,
        payload: error.response?.data?.message || "",
      });
      if (error.response && error.response.status === 400) {
        const { data } = (error && error.response) || {};
        data.message && toast.error(error.response.data.message);
      }
    }
  };

const getPaginatedProductCategory =
  (pageNumber, pageSize) => async (dispatch) => {
    if (!navigator.onLine) return;

    dispatch(request());

    const deliveryLocation = JSON.parse(
      localStorage.getItem("deliveryLocation")
    );

    const params = {
      page: pageNumber,
      size: pageSize,
      state: deliveryLocation && deliveryLocation.state,
    };

    try {
      const ProductHistoryResponse = await axios.get(
        `${currentAPI}/api/merchantBuyIntegration/branches/all/category`,
        { params }
      );
      if (ProductHistoryResponse.status === 200) {
        const { data } = ProductHistoryResponse;
        dispatch({
          type: MERCHBUY_PAGINATED_CATEGORIES_SUCCESS,
          payload: data.data.totalElements,
        });
        return data.data.content;
      }
    } catch (error) {
      dispatch({
        type: MERCHBUY_PAGINATED_CATEGORIES_FAILURE,
        payload: error.response?.data?.message || "",
      });
      if (error.response && error.response.status === 400) {
        const { data } = (error && error.response) || {};
        data.message && toast.error(error.response.data.message);
      }
    }
  };

const getProductsByProductCategoryID = (id) => async (dispatch) => {
  dispatch(request());
  const deliveryLocation =
    JSON.parse(localStorage.getItem("deliveryLocation")) || "";

  try {
    const ProductsByProductCategoryID = await axios.get(
      `${currentAPI}/api/merchantBuyIntegration/product/list/byProductCategory/${id}?direction=DESC&page=1&pageSize=24&sortBy=_id&state=${deliveryLocation.state}`
    );
    if (ProductsByProductCategoryID.status === 200) {
      dispatch({
        type: FETCH_ALL_PRODUCTS_SUCCESS,
        payload: {
          content: ProductsByProductCategoryID.data.data.content,
          totalElements: ProductsByProductCategoryID.data.data.totalElements,
        },
      });
      return ProductsByProductCategoryID.data.data.content;
    }
  } catch (error) {
    dispatch({
      type: FETCH_ALL_PRODUCTS_FAILURE,
      payload: error.response?.data?.message || "",
    });
    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
  } finally {
    dispatch(stoploading());
  }
};

const unsubscribe = () => async (dispatch) => {
  dispatch({
    type: FETCH_PRODUCT_BY_ID_SUCCESS,
    payload: {},
  });
  dispatch({
    type: FETCH_ALL_PRODUCTS_SUCCESS,
    payload: [],
  });
  dispatch({
    type: FETCH_SHOP_BY_ID_SUCCESS,
    payload: {},
  });
};

const searchProductsOnMerchbuy = (name) => async (dispatch) => {
  dispatch(request());
  const deliveryLocation =
    JSON.parse(localStorage.getItem("deliveryLocation")) || "";
  try {
    const products = await axios.get(
      `${currentAPI}/api/merchantBuyIntegration/product/list/all?direction=ASC&page=1&pageSize=7&searchString=${name}&sortBy=_id&state=${deliveryLocation.state}`
    );
    dispatch(stoploading());
    if (products.status === 200) {
      return products.data.data.content;
    }
  } catch (error) {
    return [];
  }
};

const getAllShops = (location, pageSize, searchValue) => async (dispatch) => {
  dispatch({
    type: FETCH_SHOPS,
  });
  const deliveryLocation =
    JSON.parse(localStorage.getItem("deliveryLocation")) || location;

  const params = {
    page: 1,
    pageSize: pageSize,
    searchString: searchValue,
    sortBy: "name",
    sorting: "ASC",
    state: deliveryLocation && deliveryLocation.state,
  };

  try {
    const allShops = await axios.get(
      `${currentAPI}/api/merchantBuyIntegration/business/branches/list`,
      {
        params,
      }
    );
    dispatch(stoploading());
    if (allShops.status === 200) {
      dispatch({
        type: FETCH_SHOPS_SUCCESS,
        payload: {
          shops: allShops.data.data.content,
          totalElements: allShops.data.data.totalElements,
        },
      });
      return allShops.data.data.content;
    }
  } catch (error) {
    dispatch(stoploading());
    dispatch({
      type: FETCH_SHOPS_FAILURE,
      payload: error.response?.data?.message || "",
    });

    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
  }
};

const paginatedShopHistory =
  (location, pageNumber, pageSize, searchTerm) => async (dispatch) => {
    const deliveryLocation =
      JSON.parse(localStorage.getItem("deliveryLocation")) || location;

    const params = {
      page: pageNumber,
      pageSize: pageSize,
      searchString: searchTerm,
      sortBy: "name",
      sorting: "ASC",
      state: deliveryLocation && deliveryLocation.state,
    };

    try {
      const ShopHistoryResponse = await axios.get(
        `${currentAPI}/api/merchantBuyIntegration/business/branches/list`,
        {
          params,
        }
      );
      if (ShopHistoryResponse.status === 200) {
        const { data } = ShopHistoryResponse;
        dispatch({
          type: MERCHBUY_PAGINATED_SHOP_HISTORY_SUCCESS,
          payload: data.data.totalElements,
        });
        return data.data.content;
      }
    } catch (error) {
      dispatch({
        type: MERCHBUY_PAGINATED_SHOP_HISTORY_FAILURE,
        payload: error.response?.data?.message || "",
      });
      if (error.response && error.response.status === 400) {
        const { data } = (error && error.response) || {};
        data.message && toast.error(error.response.data.message);
      }
    }
  };

const getPopularProducts = (num, location) => async (dispatch) => {
  if (!navigator.onLine) return;
  dispatch(request());
  const deliveryLocation =
    JSON.parse(localStorage.getItem("deliveryLocation")) || location;

  try {
    const popularProducts = await axios.get(
      `${currentAPI}/api/merchantBuyIntegration/product/homepage/popularProducts?limit=${num}&state=${deliveryLocation.state}`
    );
    dispatch(stoploading());
    if (popularProducts.status === 200) {
      dispatch({
        type: FETCH_POPULAR_PRODUCTS_SUCCESS,
        payload: popularProducts.data.data,
      });
    }
  } catch (error) {
    dispatch(stoploading());
    dispatch({
      type: FETCH_POPULAR_PRODUCTS_FAILURE,
      payload: error.response?.data?.message || "",
    });
    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
  }
};

const getMerchbuyLandingPageProducts = (filter, state) => async (dispatch) => {
  try {
    const result = await axios.get(
      `${currentAPI}/api/landingPage/product?filterBy=${filter}&page=${1}&size=${100}&state=${state}`
    );

    if (result.status === 200) {
      const { content: products } = result.data.data;
      if (filter === "DEALS") {
        dispatch({ type: FETCH_MERCHBUY_DEALS_SUCCESS, payload: products });
      } else if (filter === "SEARCH_HISTORY") {
        const { searchTerms } = result.data;
        dispatch({
          type: FETCH_MERCHBUY_SEARCH_HISTORY_SUCCESS,
          payload: { products, searchTerms },
        });
      } else if (filter === "CATEGORY_BANNER") {
        const { data } = result.data || {};
        dispatch({
          type: FETCH_MERCHBUY_CATEGORY_BANNER_SUCCESS,
          payload: data,
        });
      } else if (filter === "ALL_PRODUCT") {
        dispatch({
          type: FETCH_MERCHBUY_ALL_PRODUCTS_SUCCESS,
          payload: products,
        });
      } else if (filter === "CART_CATEGORY") {
        dispatch({
          type: FETCH_MERCHBUY_CART_CATEGORY_SUCCESS,
          payload: products,
        });
      }
    }
  } catch (ex) {
    if (filter === "DEALS") {
      dispatch({ type: FETCH_MERCHBUY_DEALS_FAILURE });
    } else if (filter === "SEARCH_HISTORY") {
      dispatch({ type: FETCH_MERCHBUY_SEARCH_HISTORY_FAILURE });
    } else if (filter === "CATEGORY_BANNER") {
      dispatch({ type: FETCH_MERCHBUY_CATEGORY_BANNER_FAILURE });
    } else if (filter === "ALL_PRODUCT") {
      dispatch({ type: FETCH_MERCHBUY_ALL_PRODUCTS_FAILURE });
    } else if (filter === "CART_CATEGORY") {
      dispatch({ type: FETCH_MERCHBUY_CART_CATEGORY_FAILURE });
    }
  }
};

const getRecommendedProducts = (num, location) => async (dispatch) => {
  if (!navigator.onLine) return;

  dispatch(request());
  const deliveryLocation =
    JSON.parse(localStorage.getItem("deliveryLocation")) || location;
  try {
    const recommendedProducts = await axios.get(
      `${currentAPI}/api/merchantBuyIntegration/product/homepage/recommendedProducts?limit=${num}&state=${deliveryLocation.state}`
    );
    dispatch(stoploading());
    if (recommendedProducts.status === 200) {
      dispatch({
        type: FETCH_RECOMMENDED_PRODUCTS_SUCCESS,
        payload: recommendedProducts.data.data,
      });
    }
  } catch (error) {
    dispatch(stoploading());
    dispatch({
      type: FETCH_RECOMMENDED_PRODUCTS_FAILURE,
      payload: error.response?.data?.message || "",
    });
    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
  }
};

const getShopDetials = (id) => async (dispatch) => {
  if (!navigator.onLine) return;

  dispatch({
    type: FETCH_SHOP,
    payload: id,
  });
  try {
    const shopDetialsResponse = await axios.get(
      `${currentAPI}/api/merchantBuyIntegration/branches/view/${id}`
    );
    dispatch(stoploading());
    if (shopDetialsResponse.status === 200) {
      dispatch({
        type: FETCH_SHOP_BY_ID_SUCCESS,
        payload: shopDetialsResponse.data.data,
      });
    }
  } catch (error) {
    dispatch(stoploading());
    dispatch({
      type: FETCH_SHOP_BY_ID_FAILURE,
      payload: error.response?.data?.message || "",
    });
    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
  }
};

const getProductDetials =
  (id, showLoader = true) =>
  async (dispatch) => {
    if (!navigator.onLine) return;

    showLoader && dispatch(request());

    try {
      const productDetialsResponse = await axios.get(
        `${currentAPI}/api/merchantBuyIntegration/product/view/${id}`
      );

      if (
        productDetialsResponse.status === 200 &&
        productDetialsResponse?.data?.data?.id
      ) {
        dispatch({
          type: FETCH_PRODUCT_BY_ID_SUCCESS,
          payload: productDetialsResponse.data.data,
        });

        return productDetialsResponse.data.data;
      } else {
        dispatch({
          type: FETCH_PRODUCT_BY_ID_FAILURE,
          payload: "An error occured!",
        });
      }
    } catch (error) {
      dispatch({
        type: FETCH_PRODUCT_BY_ID_FAILURE,
        payload: error.response?.data?.message || "",
      });
      if (error.response && error.response.status === 400) {
        const { data } = (error && error.response) || {};
        data.message && toast.error(error.response.data.message);
        History.push("/actions/merchbuy");
      }
    } finally {
      showLoader && dispatch(stoploading());
    }
  };

const setInitProductDetials = (data) => async (dispatch) => {
  dispatch({
    type: FETCH_PRODUCT_BY_ID_SUCCESS,
    payload: data,
  });
};

const getProductsByShopID =
  (id, page = 1, pageSize = 5, name = "") =>
  async (dispatch) => {
    if (!navigator.onLine) return;
    dispatch(request());
    const deliveryLocation =
      JSON.parse(localStorage.getItem("deliveryLocation")) || "";
    dispatch({
      type: FETCH_PRODUCTS_BY_SHOP_ID_SUCCESS,
      payload: [],
    });
    try {
      const response = await axios.get(
        `${currentAPI}/api/merchantBuyIntegration/product/list/byBranch/{branchId}?branchId=${id}&direction=ASC&page=${page}&pageSize=${pageSize}&searchTerm=${name}&sortBy=_id&state=${deliveryLocation.state}`
      );

      dispatch(stoploading());
      if (response.status === 200) {
        const { data } = response.data;
        dispatch({
          type: FETCH_PRODUCTS_BY_SHOP_ID_SUCCESS,
          payload: { products: data.content, totalItems: data.totalElements },
        });
        return data;
      }
    } catch (error) {
      dispatch(stoploading());
      dispatch({
        type: FETCH_PRODUCTS_BY_SHOP_ID_SUCCESS,
        payload: [],
      });
    }
  };

const paginatedProductHistory =
  (id, location, pageNumber, pageSize) => async (dispatch) => {
    const deliveryLocation =
      JSON.parse(localStorage.getItem("deliveryLocation")) || location;

    const params = {
      page: pageNumber,
      pageSize: pageSize,
      state: deliveryLocation && deliveryLocation.state,
    };

    try {
      const ProductHistoryResponse = await axios.get(
        `${currentAPI}/api/merchantBuyIntegration/product/list/byBranch/{branchId}?branchId=${id}&direction=DESC&page=1&pageSize=1000&sortBy=_id&state=${deliveryLocation.state}`,
        { params }
      );
      if (ProductHistoryResponse.status === 200) {
        const { data } = ProductHistoryResponse;
        dispatch({
          type: MERCHBUY_PAGINATED_PRODUCT_HISTORY_SUCCESS,
          payload: data.data.totalElements,
        });
        return data.data.content;
      }
    } catch (error) {
      dispatch({
        type: MERCHBUY_PAGINATED_PRODUCT_HISTORY_FAILURE,
        payload: error.response?.data?.message || "",
      });
      if (error.response && error.response.status === 400) {
        const { data } = (error && error.response) || {};
        data.message && toast.error(error.response.data.message);
      }
    }
  };

const getSearch = (data) => async (dispatch) => {
  const request = {
    branchId: `${data.branchId}`,
    maxPrice: "",
    minPrice: "",
    mobileNumber: `${data.mobileNumber}`,
    moq: "",
    page: "",
    searchFilter: "ALL",
    size: "",
    sortCriteria: "ASC",
    state: `${data.state}`,
    terms: `${data.terms || null}`,
  };
  try {
    const response = await axios.post(
      `${currentAPI}/api/searchTerms/branch/product/search`,
      JSON.stringify(request)
    );
    dispatch(stoploading());
    if (response.status === 200) {
      const { data } = response.data;
      dispatch({
        type: FETCH_PRODUCTS_BY_SHOP_ID_SUCCESS,
        payload: { products: data.content, totalItems: data.totalElements },
      });
      return data;
    }
  } catch (error) {
    dispatch(stoploading());
    dispatch({
      type: FETCH_PRODUCTS_BY_SHOP_ID_FAILURE,
      payload: error.response?.data?.message || "",
    });
    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
  }
};

const getShopCart = () => async (dispatch) => {
  dispatch(request());
  try {
    const cartItems = localStorage.getItem("cartItems")
      ? JSON.parse(localStorage.getItem("cartItems"))
      : [];
    dispatch(stoploading());
    dispatch({
      type: FETCH_SHOP_CART,
      payload: cartItems,
    });
  } catch (error) {
    dispatch(stoploading());
    console.error(error);
  }
};

const addProductToCart =
  (params, redirectToCartPage = true, showLoader = true) =>
  async (dispatch) => {
    showLoader && dispatch(request());
    try {
      const cartItems = localStorage.getItem("cartItems")
        ? JSON.parse(localStorage.getItem("cartItems"))
        : [];
      const existingItem = cartItems.find((item) => item.id === params.id);

      let newCartItems;
      if (existingItem) {
        newCartItems = cartItems.map((x) =>
          x.id === existingItem.id
            ? {
                ...existingItem,
                quantity: (existingItem?.quantity || 0) + params.quantity,
              }
            : x
        );
      } else {
        newCartItems = [...cartItems, params];
      }

      localStorage.setItem("cartItems", JSON.stringify(newCartItems));

      dispatch(stoploading());
      dispatch({
        type: FETCH_SHOP_CART,
        payload: newCartItems,
      });
      if (redirectToCartPage) {
        dispatch(
          push({
            pathname: "/actions/merchbuy/to-cart",
            params,
          })
        );
      }
      return true;
    } catch (error) {
      dispatch(stoploading());
      console.error(error);
    } finally {
      showLoader && dispatch(stoploading());
    }
  };

const addGroupBuyToCart = (params, shopId) => async (dispatch) => {
  dispatch(request());
  try {
    const addProductToCartResponse = await axios.post(
      `${currentAPI}/api/groupBuy/`,
      JSON.stringify(params)
    );

    if (addProductToCartResponse.status === 200) {
      const { id, productId, splits } = addProductToCartResponse.data || {};

      return { id, productId, splits };
    }
  } catch (error) {
    if (error.response) {
      const { data } = error.response;
      data.message && toast.error(data.message);
      return;
    }
    toast.error(error.message);
  } finally {
    dispatch(stoploading());
  }
};

const getGroupBuyById = (id) => async (dispatch) => {
  if (navigator.onLine && id) {
    dispatch(request());
    try {
      const groupBuyResponse = await axios.get(
        `${currentAPI}/api/groupBuy/${id}`
      );
      if (groupBuyResponse.status === 200) {
        dispatch(stoploading());
        return groupBuyResponse.data || {};
      }
    } catch (error) {
      dispatch(stoploading());
      if (error.response && error.response.status === 404) {
        const { data } = (error && error.response) || {};
        data.message && toast.error(error.response.data.message);
      }
    }
  } else {
    // dispatch({
    //     type: MY_SHOP_ERROR,
    //     payload: "No internet"
    // });
  }
};

const updateCart =
  (params, discountCode = "") =>
  async (dispatch) => {
    const data = {
      cartItems: params,
      discountCode,
    };
    try {
      await axios.put(`${currentAPI}/api/cart/`, JSON.stringify(data));
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const { data } = (error && error.response) || {};
        data.message && toast.error(error.response.data.message);
      }
    }
  };

const updateCartLocally = (data) => async (dispatch) => {
  localStorage.setItem("cartItems", JSON.stringify(data));

  dispatch({
    type: FETCH_SHOP_CART,
    payload: data,
  });
};

const deleteItemFromCart =
  (id, isGroupBuy = false) =>
  async (dispatch) => {
    dispatch(request());
    try {
      const cartItems = localStorage.getItem("cartItems")
        ? JSON.parse(localStorage.getItem("cartItems"))
        : [];
      const newCartItems = cartItems.filter((item) => item.id !== id);

      localStorage.setItem("cartItems", JSON.stringify(newCartItems));

      dispatch(stoploading());
      dispatch(getShopCart());
      return true;
    } catch (error) {
      dispatch(stoploading());
      console.error(error);
      return false;
    }
  };

const deleteAllItemsFromCart = () => async (dispatch) => {
  dispatch(request());
  try {
    localStorage.removeItem("cartItems");
    dispatch(stoploading());
    toast.info("We have updated your shopping location");
  } catch (error) {
    dispatch(stoploading());
    console.error(error);
  }
};

const clearOrderHistory = () => ({
  type: CLEAR_CHOSEN_ORDER_HISTORY,
});

const getOrderHistory = (params) => async (dispatch) => {
  const {
    actionType,
    page,
    size,
    searchTerm = "",
    sortDirection = "DESC",
    dateSearch = "LIFE_TIME",
    fromDate,
    toDate,
  } = params;

  dispatch({
    type: LOADING_ORDER_HISTORIES,
  });

  try {
    const OrderHistoryResponse = await axios.get(
      `${currentAPI}/api/merchantAppIntegration/orders/Order/v2/history?page=${page}&size=${size}&searchTerm=${searchTerm}&sortDirection=${sortDirection}&dateSearch=${dateSearch}&fromDate=${fromDate}&toDate=${toDate}`
    );
    dispatch(stoploading());
    if (OrderHistoryResponse.status !== 200) return;

    const { data } = OrderHistoryResponse;
    if (actionType === "allOrders") {
      dispatch({
        type: MERCHBUY_ORDER_HISTORY_SUCCESS,
        payload: { orders: data.data, totalOrders: data.totalRecords },
      });
    }
    if (actionType === "filterOrders") {
      dispatch({
        type: MERCHBUY_FILTER_ORDER_HISTORY_SUCCESS,
        payload: data.totalRecords,
      });
    }
    if (actionType === "paginateOrders") {
      dispatch({
        type: MERCHBUY_PAGINATED_ORDER_HISTORY_SUCCESS,
        payload: data.totalRecords,
      });
    }
    return data;
  } catch (error) {
    dispatch(stoploading());
    dispatch({
      type: MERCHBUY_ORDER_HISTORY_FAILURE,
      payload: error.response?.data?.message || "",
    });
    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
  }
};

const getOrderHistoryById = (id) => async (dispatch) => {
  dispatch({
    type: LOADING_ORDER_HISTORY,
    payload: id,
  });

  try {
    const OrderHistoryResponse = await axios.get(
      `${currentAPI}/api/merchantAppIntegration/orders/Order/${id}`
    );
    if (OrderHistoryResponse.status === 200) {
      dispatch({
        type: SET_CHOSEN_ORDER_HISTORY,
        payload: OrderHistoryResponse.data.data,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_CHOSEN_ORDER_HISTORY_FAILURE,
      payload: error.response?.data?.message || "",
    });
    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
  }
};

const getOrderHistoryByItems = (params) => async (dispatch) => {
  const {
    actionType,
    page,
    size,
    searchTerm = "",
    sortDirection = "DESC",
    dateSearch = "LIFE_TIME",
    fromDate,
    toDate,
  } = params;

  dispatch({
    type: LOADING_ORDER_HISTORIES_BY_ITEMS,
  });

  try {
    const OrderHistoryResponse = await axios.get(
      `${currentAPI}/api/merchantAppIntegration/orders/Order/v3/history/items?page=${page}&size=${size}&searchTerm=${searchTerm}&sortDirection=${sortDirection}&dateSearch=${dateSearch}&fromDate=${fromDate}&toDate=${toDate}`
    );
    dispatch(stoploading());
    if (OrderHistoryResponse.status !== 200) return;

    const { data } = OrderHistoryResponse;
    if (actionType === "allOrders") {
      dispatch({
        type: MERCHBUY_ORDER_HISTORY_BY_ITEMS_SUCCESS,
        payload: { orders: data.data, totalOrders: data.totalRecords },
      });
    }
    if (actionType === "filterOrders") {
      dispatch({
        type: MERCHBUY_FILTER_ORDER_HISTORY_BY_ITEMS_SUCCESS,
        payload: data.totalRecords,
      });
    }
    if (actionType === "paginateOrders") {
      dispatch({
        type: MERCHBUY_PAGINATED_ORDER_HISTORY_BY_ITEMS_SUCCESS,
        payload: data.totalRecords,
      });
    }
    return data;
  } catch (error) {
    dispatch(stoploading());
    dispatch({
      type: MERCHBUY_ORDER_HISTORY_FAILURE,
      payload: error.response?.data?.message || "",
    });
    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
  }
};

const getGroupBuyOrders = () => async (dispatch) => {
  dispatch({ type: LOADING_GROUPBUY_ORDER_HISTORY });
  try {
    const response = await axios.get(
      `${currentAPI}/api/groupBuy/participant/details`
    );
    if (response.status === 200) {
      dispatch({
        type: GROUPBUY_ORDER_HISTORY_SUCCESS,
        payload: response.data.content,
      });
    }
  } catch (error) {
    dispatch({
      type: GROUPBUY_ORDER_HISTORY_FAILURE,
      payload: error.response?.data?.message || "",
    });
  } finally {
    dispatch(stoploading());
  }
};

const getGroupBuyOrderById = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`${currentAPI}/api/groupBuy/${id}`);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    // dispatch({
    //     type: GROUPBUY_ORDER_HISTORY_FAILURE,
    //     payload: error.response.data.message
    // })
  } finally {
    // dispatch(stoploading());
  }
};

const sendInviteToFriends = (id, users) => async (dispatch) => {
  dispatch(request());
  try {
    const response = await axios.put(
      `${currentAPI}/api/groupBuy/${id}/participant`,
      JSON.stringify(users)
    );
    if (response.status === 200) {
      stoploading();
      toast.success("Invite sent successfully");
      return response.data;
    }
  } catch (error) {
    dispatch(stoploading());
    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
  } finally {
    dispatch(stoploading());
  }
};

const getFrequentSearchTerms = () => async (dispatch) => {
  if (!navigator.onLine) return {};

  try {
    const products = await axios.get(
      `${currentAPI}/api/searchTerms/frequent/auth`
    );
    if (products.status === 200) {
      return products.data;
    }
  } catch (error) {
    return {};
  }
};

const getFrequentSearchTermsOnChange = (terms) => async (dispatch) => {
  if (!navigator.onLine) return {};

  try {
    const productResponse = axios.get(
      `${currentAPI}/api/searchTerms/frequent/search?searchTermType=PRODUCT&terms=${terms}`
    );
    const shopResponse = axios.get(
      `${currentAPI}/api/searchTerms/frequent/search?searchTermType=SHOP&terms=${terms}`
    );
    const response = await axios.all([productResponse, shopResponse]);
    if (response[0].status === 200 && response[1].status === 200) {
      return {
        product: response[0].data.data || [],
        shop: response[1].data.data || [],
      };
    }
  } catch (error) {
    return {};
  }
};

const postSearchTerm = (params) => async (dispatch) => {
  if (!navigator.onLine) return {};
  dispatch(request());

  try {
    const response = await axios.post(
      `${currentAPI}/api/searchTerms/search`,
      JSON.stringify(params)
    );

    if (response.status === 200) {
      dispatch(stoploading());
      return response.data.data;
    }
  } catch (error) {
    dispatch(stoploading());
    return {};
  }
};

const getMinimumOrderValue = () => async (dispatch) => {
  dispatch(request());

  try {
    const response = await axios.get(
      `${currentAPI}/api/configProperties/?propertyName=MINIMUM_ORDER_VALUE`
    );
    dispatch(stoploading());
    if (response.status === 200) {
      dispatch({
        type: MINIMUM_ORDER_VALUE,
        payload: response.data.data,
      });
    }
  } catch (error) {
    dispatch(stoploading());
    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
  }
};

const getDeliveryAddressEditLimit = () => async (dispatch) => {
  dispatch(request());

  try {
    const response = await axios.get(
      `${currentAPI}/api/configProperties/?propertyName=DELIVERY_ADDRESS_EDIT_LIMIT`
    );
    dispatch(stoploading());

    if (response.status === 200) {
      dispatch({
        type: DELIVERY_ADDRESS_EDIT_LIMIT,
        payload: response?.data?.data?.value || 0,
      });
    }
  } catch (error) {
    dispatch(stoploading());
    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
  }
};

const getShippingConfiguration = () => async (dispatch) => {
  dispatch(request());

  try {
    const response = await axios.get(
      `${currentAPI}/api/configProperties/?propertyName=ACTIVATE_WARENEXT_FREE_SHIPPING`
    );
    if (response.status === 200) {
      dispatch({
        type: FETCH_SHIPPING_CONFIGURATION,
        payload: response.data.data,
      });
    }
  } catch (error) {
    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
  }
};

const getSabiUserDetailByPhoneNumber = (phoneNumber) => async (dispatch) => {
  dispatch(request());

  try {
    const response = await axios.get(
      `${currentAPI}/api/users/byMobile/${phoneNumber}`
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
  } finally {
    dispatch(stoploading());
  }
};

const sendTelephone = (phoneNumber, country) => async (dispatch) => {
  dispatch(request());

  const countryCode = countriesMap.get(country).code;
  const msisdn = insertCountryCode(phoneNumber, countryCode);

  try {
    const response = await axios.get(
      `${currentAPI}/api/otp/send/mobile?msisdn=${encodeURIComponent(msisdn)}`
    );
    if (response.status === 200) {
      const { userId } = response.data || {};
      return userId;
    }
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.status === 400 &&
      error.response.status !== "USER_EXIST"
    ) {
      dispatch(setExpired(true));
    }
  } finally {
    dispatch(stoploading());
  }
};

const sendCode = (code, userId) => async (dispatch) => {
  dispatch({ type: SENDING_CODE });

  try {
    const response = await axios.post(
      `${currentAPI}/api/otp/check?code=${code}&userId=${userId}`
    );
    if (response.status === 200) {
      dispatch({ type: SENDING_CODE_SUCCESS });
      dispatch(resetCounter());
      return true;
    }
  } catch (error) {
    dispatch({
      type: SENDING_CODE_ERROR,
      payload: error.message,
    });
    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
  }
};

const newSendCode = (actionType, phoneNumber, code) => async (dispatch) => {
  dispatch({ type: SENDING_CODE });

  try {
    const response = await axios.post(
      `${currentAPI}/api/otp/check?actionType=${actionType}&code=${code}&msisdn=${encodeURIComponent(
        phoneNumber
      )}`
    );
    if (response.status === 200) {
      dispatch({ type: SENDING_CODE_SUCCESS });
      dispatch(resetCounter());
      return true;
    }
  } catch (error) {
    dispatch({
      type: SENDING_CODE_ERROR,
      payload: error.message,
    });
    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
  }
};

const resendCode = (phoneNumber) => async (dispatch, getState) => {
  try {
    const country = getState().user.country;
    const countryCode = countriesMap.get(country).code;
    const msisdn = insertCountryCode(phoneNumber, countryCode);

    await axios.post(
      `${currentAPI}/api/authorize/reset/${encodeURIComponent(msisdn)}`
    );

    const responseResendCode = await axios.get(
      `${currentAPI}/api/otp/send/mobile?msisdn=${encodeURIComponent(msisdn)}`
    );

    if (responseResendCode.status === 200) {
      dispatch(resetCounter());
    }
  } catch (error) {
    console.error(error);
  }
};

const sendUssd = (phoneNumber) => async (dispatch, getState) => {
  dispatch({ type: SENDING_CODE });

  try {
    const country = getState().user.country;
    const countryCode = countriesMap.get(country).code;
    const msisdn = insertCountryCode(phoneNumber, countryCode);

    const sendUssdCodeResponse = await axios.get(
      `${currentAPI}/api/otp/send/ussd?msisdn=${encodeURIComponent(msisdn)}`
    );

    if (sendUssdCodeResponse.status === 200) {
      dispatch({ type: SENDING_CODE_SUCCESS });
      dispatch(setExpired(false));
      toast.success(`Your pin code is: ${sendUssdCodeResponse.data.pinCode}`);
      dispatch(resetCounter());
    }
  } catch (error) {
    dispatch({ type: SENDING_CODE_ERROR, payload: error.message });
    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
  }
};
const getProductListByProductCategoryID =
  (id, page, pageSize) => async (dispatch) => {
    dispatch(request());
    const deliveryLocation =
      JSON.parse(localStorage.getItem("deliveryLocation")) || "";

    try {
      const ProductsByProductCategoryID = await axios.get(
        `${currentAPI}/api/merchantBuyIntegration/product/list/byProductCategory/${id}?direction=DESC&page=${page}&pageSize=${pageSize}&sortBy=name&state=${deliveryLocation.state}`
      );
      if (ProductsByProductCategoryID.status === 200) {
        dispatch({
          type: FETCH_ALL_PRODUCTS_SUCCESS,
          payload: {
            content: ProductsByProductCategoryID.data.data.content,
            totalElements:
              ProductsByProductCategoryID.data.data.numberOfElements,
          },
        });
        return ProductsByProductCategoryID.data.data.content;
      }
    } catch (error) {
      dispatch({
        type: FETCH_ALL_PRODUCTS_FAILURE,
        payload: error.response?.data?.message || "",
      });
      if (error.response && error.response.status === 400) {
        const { data } = (error && error.response) || {};
        data.message && toast.error(error.response.data.message);
      }
    } finally {
      dispatch(stoploading());
    }
  };

const createAccountForMerchant = (userData) => async (dispatch) => {
  dispatch(request());

  const { msisdn, country } = userData;
  const countryCode = countriesMap.get(country).code;

  try {
    const payload = {
      ...userData,
      msisdn: insertCountryCode(msisdn, countryCode),
      businessPhoneNumber: insertCountryCode(
        userData.businessPhoneNumber,
        countryCode
      ),
    };

    const signupResponse = await axios.post(
      `${currentAPI}/api/v3/completeSignup`,
      payload
    );

    dispatch(stoploading());
    if (signupResponse.status === 200) {
      return true;
    }
  } catch (error) {
    dispatch(stoploading());
    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
  }
};

export const saveUserEducation = (params) => async (dispatch) => {
  dispatch(request());

  try {
    const response = await axios.post(
      `${currentAPI}/api/users/userEducation`,
      params
    );
    if (response.status === 201) {
      return response.data.data;
    }
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(stoploading());
    dispatch(getUserData());
  }
};

const groupBuyEducation = () => (dispatch) => {
  dispatch({
    type: GROUP_BUY_EDUCATION,
  });
};

export const cartItemsValidation = (params) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${currentAPI}/api/merchantBuyIntegration/product/views`,
      params
    );
    if (response.status === 200) {
      return response?.data?.data;
    }
  } catch (error) {
    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
  } finally {
    dispatch(stoploading());
  }
};

const getAvailableCountries = () => async (dispatch) => {
  dispatch(request());
  try {
    const availableCountriesResponse = await axios.get(
      `${currentAPI}/api/users/public/country?isMerchBuyAvailable=true&page=1&pageSize=50`
    );
    dispatch(stoploading());
    if (availableCountriesResponse.status === 200) {
      dispatch({
        type: FETCH_AVAILABLE_COUNTRIES_SUCCESS,
        payload: availableCountriesResponse?.data?.data?.content,
      });
    }
  } catch (error) {
    dispatch(stoploading());
    dispatch({
      type: FETCH_AVAILABLE_COUNTRIES_FAILURE,
      payload: error.response?.data?.message || "",
    });
    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
  }
};

const newGetAvailableStates = () => async (dispatch, getState) => {
  dispatch(request());
  const countries = getState().applications.merchbuy.availableCountries;
  const countryId = countries?.find(
    (country) => country.name === "South Africa"
  )?.id;
  try {
    const availableStatesResponse = await axios.get(
      `${currentAPI}/api/users/public/country/states/${countryId}?isMerchBuyState=true`
    );
    dispatch(stoploading());
    if (availableStatesResponse.status === 200) {
      dispatch({
        type: FETCH_NEW_AVAILABLE_STATES_SUCCESS,
        payload: availableStatesResponse?.data?.data?.content,
      });
    }
  } catch (error) {
    dispatch(stoploading());
    dispatch({
      type: FETCH_NEW_AVAILABLE_STATES_FAILURE,
      payload: error.response?.data?.message || "",
    });
    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
  }
};

const updateProductSearch = (searchTerm) => async (dispatch) => {
  dispatch({
    type: SAVE_PRODUCT_SEARCH_TERM,
    payload: searchTerm,
  });
};

function request() {
  return {
    type: LOADING,
    payload: true,
  };
}

function stoploading() {
  return {
    type: LOADING,
    payload: false,
  };
}

export const merchbuyActions = {
  clearOrderHistory,
  getWareNextDeliveryLocations,
  getWareNextShippingPrices,
  updateShippingLocally,
  createWareNextShippingOrder,
  trackWareNextShippingOrder,
  getAVailableStates,
  getProductCategories,
  getProductsByProductCategoryID,
  getAllShops,
  paginatedShopHistory,
  paginatedProductHistory,
  getPopularProducts,
  getRecommendedProducts,
  getShopDetials,
  getProductDetials,
  setInitProductDetials,
  getProductsByShopID,
  getShopCart,
  addProductToCart,
  updateCart,
  updateCartLocally,
  updateDeliveryLocation,
  deleteItemFromCart,
  placeOrder,
  searchProductsOnMerchbuy,
  sendPaymentDetails,
  unsubscribe,
  getOrderHistory,
  getOrderHistoryById,
  payWithWallet,
  getGroupBuyOrders,
  getGroupBuyOrderById,
  addGroupBuyToCart,
  getGroupBuyById,
  sendInviteToFriends,
  getFrequentSearchTerms,
  getFrequentSearchTermsOnChange,
  postSearchTerm,
  getAllCities,
  deleteAllItemsFromCart,
  getSearch,
  groupBuyEducation,
  getMinimumOrderValue,
  getDeliveryAddressEditLimit,
  getWareNextShippingOptions,
  getShippingConfiguration,
  updateProductSearch,
  getSabiUserDetailByPhoneNumber,
  sendCode,
  resendCode,
  sendUssd,
  sendTelephone,
  createAccountForMerchant,
  cartItemsValidation,
  getMerchbuyLandingPageProducts,
  getProductListByProductCategoryID,
  getPaginatedProductCategory,
  getOrderHistoryByItems,
  saveLastDeliveryAddress,
  newSendCode,
  getAvailableCountries,
  newGetAvailableStates,
};
