import React, { Fragment, useState } from "react";

import { connect } from "react-redux";

import { SpacesHeader } from "../../../components/spaces-header";
import History from "../../../utils/History";
import DesktopBackgroundLayout from "../../../views/DesktopBackgroundLayout";
import { ReactComponent as QuestionMark } from "../../assets/icons/question-mark.svg";
import GameFail from "../../assets/img/game-fail.svg";
import { Button } from "../../components";
import TermsDialog from "../terms";

import {
  FragmentWrapper,
  PageHeader,
  PageHeaderText,
  RightSide,
  HeaderAvatar,
  LeftSide,
  UserAvatar,
  UserAvatarContainer,
  ScoreContainer,
  ScoreInnerBox,
  ScoreText,
  ScoreTitleBox,
  ScoreTitleText,
  TryAgainTitle,
  TryAgainDescription,
  ButtonContainer,
  ButtonWrapper,
} from "./styles";

const LatestResults = ({ avatar }) => {
  const [openTerms, setOpenTerms] = useState(false);
  const { score, totalScore } = History?.location?.state;
  const gamesUserName = localStorage.getItem("gamesUserName");

  return (
    <Fragment>
      <DesktopBackgroundLayout>
        <TermsDialog open={openTerms} cancel={() => setOpenTerms(false)} />
        <SpacesHeader />
        <PageHeader>
          <LeftSide>
            <HeaderAvatar src={avatar ? avatar : null} />
            <PageHeaderText>Welcome, {gamesUserName}</PageHeaderText>
          </LeftSide>
          <RightSide>
            <QuestionMark onClick={() => setOpenTerms(true)} />
          </RightSide>
        </PageHeader>
        <UserAvatarContainer>
          <UserAvatar src={GameFail} />
        </UserAvatarContainer>

        <TryAgainTitle>You had a good run but it is what it is!</TryAgainTitle>

        <TryAgainDescription>
          Try again tomorrow, answer all questions right and you just might win
          our cash reward.
        </TryAgainDescription>

        <FragmentWrapper>
          <ScoreContainer>
            <ScoreTitleBox>
              <ScoreTitleText>YOUR SCORE</ScoreTitleText>
            </ScoreTitleBox>
            <ScoreInnerBox>
              <ScoreText>
                {score} / {totalScore}
              </ScoreText>
            </ScoreInnerBox>
          </ScoreContainer>

          <ButtonContainer>
            <ButtonWrapper>
              <Button onClick={() => History.push("/games")}>Okay</Button>
            </ButtonWrapper>
          </ButtonContainer>
        </FragmentWrapper>
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

LatestResults.propTypes = {};

const mapStateToProps = ({ user }) => ({
  avatar: user.avatar,
});

export default connect(mapStateToProps)(LatestResults);
