import React, { Fragment } from "react";

import { useDispatch } from "react-redux";

import { Button } from "../../../../../components";
import { merchbuyActions } from "../../../../../redux/ducks/applications/merchbuy/actions";
import DesktopBackgroundLayout from "../../../../DesktopBackgroundLayout";
import Cone from "../../assets/cone.png";
import Eclipse from "../../assets/eclipse.png";
import Eclipse2 from "../../assets/eclipse2.png";
import Exclaim from "../../assets/exclaim.png";
import Girl from "../../assets/girl.png";
import Milo from "../../assets/milo.png";
import Rectangle1 from "../../assets/rec1.png";
import Rectangle2 from "../../assets/rec2.png";
import Rectangle3 from "../../assets/rec3.png";
import Rectangle4 from "../../assets/rec4.png";
import Red from "../../assets/red.png";
import Wink from "../../assets/wink.png";
import {
  BuyDescription,
  BuyFeature,
  BuyProduct,
  RecommendItem,
  BuyCircle,
  MiloItem,
  WinkItem,
  GirlItem,
  ExclaimItem,
  RedItem,
  FixedBottom,
  BuyButtons,
  ExclaimAmount,
  RedAmount,
  WinkAmount,
  GirlAmount,
  ConeItem,
} from "../styles";

const GroupBuyEducation = () => {
  const dispatch = useDispatch();

  return (
    <Fragment>
      <DesktopBackgroundLayout>
        <ExclaimAmount style={{ backgroundImage: "url(" + Rectangle1 + ")" }}>
          #5,000
        </ExclaimAmount>
        <RedAmount style={{ backgroundImage: "url(" + Rectangle2 + ")" }}>
          #5,000
        </RedAmount>
        <WinkAmount style={{ backgroundImage: "url(" + Rectangle3 + ")" }}>
          #5,000
        </WinkAmount>
        <GirlAmount style={{ backgroundImage: "url(" + Rectangle4 + ")" }}>
          #5,000
        </GirlAmount>
        <RecommendItem style={{ backgroundImage: "url(" + Eclipse + ")" }}>
          <WinkItem
            src={Wink}
            alt=""
            style={{
              position: "absolute",
              top: "65%",
              left: "36%",
              borderRadius: "50%",
              zIndex: 2,
              backgroundColor: "#CAB3FB",
            }}
          />
          <ExclaimItem
            src={Exclaim}
            alt=""
            style={{
              position: "absolute",
              top: "30%",
              left: "29%",
              zIndex: 2,
              borderRadius: "50%",
              backgroundColor: "#A6CBFF",
            }}
          />
          <GirlItem
            src={Girl}
            alt=""
            style={{
              position: "absolute",
              top: "50%",
              left: "62%",
              zIndex: 2,
              borderRadius: "50%",
              backgroundColor: "#718596",
            }}
          />
          <RedItem
            src={Red}
            alt=""
            style={{
              position: "absolute",
              top: "24%",
              left: "55%",
              zIndex: 2,
              borderRadius: "50%",
              backgroundColor: "#FFD175",
            }}
          />
          <BuyCircle style={{ backgroundImage: "url(" + Eclipse2 + ")" }}>
            <MiloItem
              style={{ backgroundImage: "url(" + Milo + ")" }}
            ></MiloItem>
          </BuyCircle>
        </RecommendItem>
        <ConeItem src={Cone} alt="cone" resizeMode="contain" />
        <BuyFeature style={{ marginLeft: 55 }}>
          Introducing Buy with friends Feature
        </BuyFeature>
        <BuyDescription style={{ marginLeft: 30 }}>
          Split payment of goods with
          <br />
          your friends
        </BuyDescription>
        <BuyProduct style={{ marginLeft: 30 }}>
          Buy Products in bulk and split product items <br />
          and the bill with your friends
        </BuyProduct>
        <FixedBottom>
          <BuyButtons>
            <Button
              top={"10px"}
              type={"button"}
              margin={"0 8px 0 0"}
              backgroundColor="#227EFF"
              radius="4px"
              onClick={() => {
                dispatch(merchbuyActions.groupBuyEducation());
              }}
            >
              Get Started
            </Button>
          </BuyButtons>
        </FixedBottom>
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default GroupBuyEducation;
