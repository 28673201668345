import * as Yup from "yup";

export const BvnDetailValidationSchema = Yup.object().shape({
  bvn: Yup.string()
    .min(11, "BVN should be 11 characters long")
    .max(11, "BVN should be 11 characters long")
    .nullable()
    .required("Required"),
  firstName: Yup.string().nullable().required("Required"),
  lastName: Yup.string().nullable().required("Required"),
});
