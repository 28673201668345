import React, { Fragment, useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as Caution } from "../../../../../assets/caution.svg";
import { ReactComponent as MostRecent } from "../../../../../assets/most_recent1.svg";
import { ReactComponent as Oldest } from "../../../../../assets/oldest1.svg";
import { ReactComponent as Customer } from "../../../../../assets/user.svg";
import {
  TopHeader,
  RippleButton,
  RippleLink,
  OptionsPopupDialog,
  Loader,
  InfiniteScrollList,
} from "../../../../../components";
import { Logos } from "../../../../../components/logo/index";
import { Add } from "../../../../../containers/HeaderContainer";
import {
  List,
  ListItem,
  ListHeading,
  ListLeftBlock,
  ListSubHeading,
  ListHighlight,
} from "../../../../../containers/ListContainer";
import {
  Title,
  Message,
  PageCaption,
  SubTitle,
  MessageContainer,
} from "../../../../../containers/MessageContainer";
import {
  ScreenContainer,
  FlexCenteredBlock,
  FilterAndSortSection,
  SortFilterCell,
  ViewContainer,
  TotalContainer,
} from "../../../../../containers/ScreenContainer";
import { getCustomer } from "../../../../../redux/ducks/applications/my-shop/actions/customers";
import { formatCreationDate } from "../../../../../utils/date/formatCreationDate";
import { MODULE_INTERACTION } from "../../../../../utils/mix-panel/constants";
import { mixPanel } from "../../../../../utils/mix-panel/mixPanel";
import DesktopBackgroundLayout from "../../../../DesktopBackgroundLayout";
import CustomerDetails from "../customer-details";

import { ReactComponent as ArrowLogo } from "./assets/arrow.svg";
import { ReactComponent as AvatarLogo } from "./assets/avatar.svg";
import { ReactComponent as NoCustomerLogo } from "./assets/nocustomerlogo.svg";
import { filterCustomers } from "./filter";
import { sortCustomers } from "./sort";

const AddLink = styled(RippleLink)`
  width: 100%;
`;

const EmptyAdd = styled(RippleButton)`
  height: 56px;
`;
const CustomersList = () => {
  const dispatch = useDispatch();

  const search = useLocation().search;
  const fromReminder = new URLSearchParams(search).get("fromReminder");

  const customers = useSelector((state) => state.applications.myShop.customers);
  const customer = useSelector((state) => state.applications.myShop.customer);

  const debtorId = useSelector(
    (state) => state.applications.myShop.debtor?.debtorInfo?.customerId
  );

  const total = useSelector((state) => state.applications.myShop.total);
  const [allCustomers, setAllCustomers] = useState([...customers]);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [customerDetailsOpen, setCustomerDetailsOpen] = useState(false);
  const [sortType, setSortType] = useState("Most Recent");
  const [filterType, setFilterType] = useState("All Customers");
  const [openFilterOptions, setOpenFilterOptions] = useState(false);
  const [sortOptionsOpen, setSortOptionsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [customersToDisplay, setCustomersToDisplay] = useState([]);
  const perPage = 20;
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (fromReminder) {
      dispatch(getCustomer(debtorId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debtorId, fromReminder]);

  useEffect(() => {
    if (customer && customer.id === debtorId && fromReminder) {
      setSelectedCustomer(customer);
      setCustomerDetailsOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  useEffect(() => {
    const dataToAdd = allCustomers.slice(0, perPage);
    setCustomersToDisplay(dataToAdd);

    if (allCustomers.length > perPage) {
      setPage(2);
      setHasMore(true);
    }
  }, [allCustomers]);

  useEffect(() => {
    mixPanel.track(MODULE_INTERACTION, {
      "Icon Name": "My Customers",
      Time: new Date().toLocaleString(),
    });
  }, []);

  const addMoreDataToDisplay = () => {
    if (customersToDisplay.length < allCustomers.length) {
      const count = perPage * page;
      const dataToAdd = allCustomers.slice(0, count);
      setCustomersToDisplay(dataToAdd);
      setPage(page + 1);
    } else {
      setHasMore(false);
    }
  };

  useEffect(() => {
    sortCustomers(sortType, customers, setAllCustomers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortType]);

  useEffect(() => {
    filterCustomers(filterType, customers, setAllCustomers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterType]);

  if (isLoading) return <Loader />;
  return (
    <Fragment>
      <DesktopBackgroundLayout>
        {customers.length === 0 ? (
          <Fragment>
            <TopHeader title={"Customers"} backLink={"/my-shop"} />
            <ScreenContainer>
              <FlexCenteredBlock top={"53px"} mtop={"53px"}>
                <MessageContainer>
                  <Logos margin={"0 auto 15px auto"} display={"block"}>
                    <NoCustomerLogo />
                  </Logos>

                  <Title
                    size={"14px"}
                    bottom={"4px"}
                    lineHeight={"21px"}
                    weight={"bold"}
                  >
                    No customers Yet
                  </Title>
                  <Message
                    bottom={"24px"}
                    top={"0px"}
                    align={"center"}
                    padding={"0 1em"}
                    color={"#718596"}
                    lineHeight={"21px"}
                    weight={"normal"}
                  >
                    Customers are used to link the things you do on spaces to
                    real people. e.g Who did I sell a product to?
                  </Message>
                </MessageContainer>
                <AddLink to={"/actions/shop_customers_add"}>
                  <EmptyAdd radius={"4px"} size={"16px"}>
                    Add Customer
                  </EmptyAdd>
                </AddLink>
              </FlexCenteredBlock>
            </ScreenContainer>
          </Fragment>
        ) : (
          <Fragment>
            {!customerDetailsOpen && (
              <TopHeader title={"Customers"} backLink={"/my-shop"}>
                <Link to={"/actions/shop_customers_add"}>
                  <Add right={"16px"} />
                </Link>
              </TopHeader>
            )}
            <ScreenContainer>
              <FilterAndSortSection
                top={"16px"}
                justifyContent={"space-around"}
                bottom={"24px"}
                borderTop={"1px solid #EDF2F7"}
                borderBottom={"1px solid #EDF2F7"}
                style={{
                  marginLeft: "-22px",
                  marginRight: "-22px",
                  padding: "11px 22px",
                }}
              >
                <SortFilterCell
                  onClick={() => setSortOptionsOpen(!sortOptionsOpen)}
                  color={"#071827"}
                  weight={"500"}
                >
                  {sortType}
                  <ArrowLogo />
                </SortFilterCell>
                <SortFilterCell
                  onClick={() => setOpenFilterOptions(!openFilterOptions)}
                  color={"#071827"}
                  weight={"500"}
                >
                  {filterType}
                  <ArrowLogo />
                </SortFilterCell>
              </FilterAndSortSection>
              <TotalContainer width={"100%"} height={"88px"}>
                <PageCaption lineHeight={"20px"}>{total}</PageCaption>
                <SubTitle lineHeight={"25px"}>customers</SubTitle>
              </TotalContainer>
              <ViewContainer top={"20px"}>
                <Message
                  top={"0px"}
                  bottom={"24px"}
                  align={"left"}
                  size={"14px"}
                  weight={"bold"}
                  color={"#253246"}
                >
                  Your Customers
                </Message>
                <List noBorderBottom fullScreen>
                  <InfiniteScrollList
                    data={customersToDisplay}
                    fetchMore={addMoreDataToDisplay}
                    hasMore={hasMore}
                    endMessage="That's all your customers!"
                  >
                    {customersToDisplay &&
                      customersToDisplay.map((customer, index) => (
                        <ListItem
                          noBorderBottom
                          key={index}
                          onClick={() => {
                            setSelectedCustomer(customer);
                            setCustomerDetailsOpen(!customerDetailsOpen);
                          }}
                        >
                          <AvatarLogo />
                          <ListLeftBlock>
                            <ListHeading color={"#071827"} bottom={"4px"}>
                              {customer.name}
                            </ListHeading>
                            <ListSubHeading
                              top={"0"}
                              color={"#718596"}
                              fontSize={"12px"}
                              fontWeight={"normal"}
                            >
                              Added:
                              {formatCreationDate(customer.createdDate)}
                            </ListSubHeading>
                          </ListLeftBlock>
                          {customer.owing && customer.owing === true && (
                            <ListHighlight>
                              <Caution
                                style={{
                                  marginTop: "8px",
                                }}
                              />
                            </ListHighlight>
                          )}
                        </ListItem>
                      ))}
                  </InfiniteScrollList>
                </List>
              </ViewContainer>
              {sortOptionsOpen && (
                <OptionsPopupDialog
                  open={sortOptionsOpen}
                  title={"Sort"}
                  cancel={() => {
                    setSortType("Most Recent");
                    setSortOptionsOpen(!sortOptionsOpen);
                  }}
                  items={[
                    {
                      title: "Most Recent",
                      Icon: MostRecent,
                      click: () => {
                        sortType !== "Most Recent" &&
                          setSortType("Most Recent");
                        setSortOptionsOpen(!sortOptionsOpen);
                      },
                    },
                    {
                      title: "Oldest",
                      Icon: Oldest,
                      click: () => {
                        sortType !== "Oldest" && setSortType("Oldest");
                        setSortOptionsOpen(!sortOptionsOpen);
                      },
                    },
                  ]}
                />
              )}
              {sortOptionsOpen && (
                <OptionsPopupDialog
                  open={sortOptionsOpen}
                  title={"Sort By"}
                  cancel={() => {
                    setSortType("Most Recent");
                    setSortOptionsOpen(!sortOptionsOpen);
                  }}
                  items={[
                    {
                      title: "Most Recent",
                      Icon: MostRecent,
                      click: () => {
                        sortType !== "Most Recent" &&
                          setSortType("Most Recent");
                        setSortOptionsOpen(!sortOptionsOpen);
                      },
                    },
                    {
                      title: "Oldest",
                      Icon: Oldest,
                      click: () => {
                        sortType !== "Oldest" && setSortType("Oldest");
                        setSortOptionsOpen(!sortOptionsOpen);
                      },
                    },
                  ]}
                />
              )}
              {openFilterOptions && (
                <OptionsPopupDialog
                  title={"Filter"}
                  open={openFilterOptions}
                  cancel={() => {
                    setFilterType("All Customers");
                    setOpenFilterOptions(!openFilterOptions);
                  }}
                  items={[
                    {
                      title: "Owing Customers",
                      Icon: Customer,
                      click: () => {
                        filterType !== "Owing Customers" &&
                          setFilterType("Owing Customers");
                        setOpenFilterOptions(!openFilterOptions);
                      },
                    },
                  ]}
                />
              )}
              {customerDetailsOpen && (
                <CustomerDetails
                  customer={selectedCustomer}
                  open={customerDetailsOpen}
                  setOpen={setCustomerDetailsOpen}
                  fromReminder={fromReminder === null ? false : true}
                />
              )}
            </ScreenContainer>
          </Fragment>
        )}
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default CustomersList;
