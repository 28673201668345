import { parseTransactionsByDate } from "../../../../utils/date/parseTransactionsByDate";

export const filterByDate = (list, selectedFilter) => {
  switch (selectedFilter) {
    case "ALL":
      return parseTransactionsByDate(list);

    case "WALLET_TO_WALLET":
      return parseTransactionsByDate(
        list.filter((item) => item.transactionType === "WALLET_TO_WALLET")
      );

    case "DEBIT":
      return parseTransactionsByDate(
        list.filter((item) => item.transactionClass === "DEBIT")
      );

    case "CREDIT":
      return parseTransactionsByDate(
        list.filter((item) => item.transactionClass === "CREDIT")
      );

    case "TOP_UP_WALLET":
      return parseTransactionsByDate(
        list.filter((item) => item.transactionType === "TOP_UP_WALLET")
      );

    case "COMMISSION_TO_WALLET":
      return parseTransactionsByDate(
        list.filter((item) => item.transactionType === "COMMISSION_TO_WALLET")
      );

    case "CREDIT_BANK":
      return parseTransactionsByDate(
        list.filter((item) => item.transactionType === "CREDIT_BANK")
      );

    case "PAY_WITH_WALLET":
      return parseTransactionsByDate(
        list.filter((item) => item.transactionType === "PAY_WITH_WALLET")
      );

    case "Most Recent":
      return parseTransactionsByDate(
        list.sort((a, b) => b.createdDate.localeCompare(a.createdDate))
      );

    case "Oldest":
      return parseTransactionsByDate(
        list.sort((a, b) => a.createdDate.localeCompare(b.createdDate))
      );

    case "Low to High":
      return parseTransactionsByDate(
        list.sort((a, b) => a.transactionAmount - b.transactionAmount)
      );

    case "High to Low":
      return parseTransactionsByDate(
        list.sort((a, b) => b.transactionAmount - a.transactionAmount)
      );

    default:
      return list;
  }
};
