import { countriesMap } from "../../data/countries";

export const unparseBalance = (balance) => {
  return Number(balance.replace(/[^0-9.-]+/g, ""));
};

export const formatCurrency = (value = 0, country = "SA") => {
  return new Intl.NumberFormat(`en-${country}`, {
    style: "currency",
    currency: countriesMap.get(country).currency.code,
    currencyDisplay: "narrowSymbol",
  })
    .format(value || 0)
    .replace(/\D00(?=\D*$)/, "");
};

// Used when you need user to enter decimal values e.g #234.65
export const formatDecimalCurrency = (value = 0, country = "SA") => {
  const parts = value.toString().split(".");
  const integral = formatCurrency(parts[0].replace(/\D/g, ""));
  return integral + (parts[1] !== undefined ? `.${parts[1]}` : "");
};

export const decimalPadOutput = (num) => {
  const parts = num.toString().split(".");
  const value = parseInt(parts[0].replace(/\D/g, "") || 0, 10);
  return value + (parts[1] !== undefined ? `.${parts[1]}` : "").slice(0, 3);
};
