import React, { Fragment, useState } from "react";

import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { TopHeader } from "../../../../components";
import { ScreenContainer } from "../../../../containers/ScreenContainer";
import { colors } from "../../../../styles";
import { formatPrice } from "../../../../utils/currency/formatPriceWithComma";
import {
  parseMonthDateYear,
  parseDate,
} from "../../../../utils/date/parseDateFromStr";
import DesktopBackgroundLayout from "../../../DesktopBackgroundLayout";
import {
  Card,
  OrderDetailCards,
  CirclePlusIcon,
  FlexContainer,
  Text,
  CirclePendingIcon,
  CircleSuccessIcon,
  CircleRejectedIcon,
  LightText,
} from "../styles";

const HeaderSpace = styled.div`
  margin: 12px 0 26px 0;
  border-bottom: 1px solid ${colors.headerBorder};
`;

export const RemittancePaymentDetails = () => {
  const location = useLocation();
  const [transaction] = useState(location.state);

  return (
    <Fragment>
      <DesktopBackgroundLayout>
        <TopHeader
          noBorderBottom
          title={"Details"}
          backLink="/actions/remittance/remmitance-history"
        />
        {transaction ? (
          <ScreenContainer padding="16px" top="45px">
            <Card>
              <CirclePlusIcon />
              <FlexContainer padding="20px 0 20px">
                <Text
                  size="14px"
                  lineHeight="21px"
                  color={colors.themeTxtColor10}
                >
                  {parseMonthDateYear(transaction.dateRemitted)}
                </Text>
                <Text weight="500" color={colors.textGreen}>
                  {formatPrice(transaction.totalAmountRemitted || 0)}
                </Text>
              </FlexContainer>
            </Card>
            <HeaderSpace />

            <FlexContainer>
              <LightText>Status</LightText>
              <LightText
                weight="500"
                status={(transaction.approvalStatus || "").toUpperCase()}
              >
                {transaction?.approvalStatus === "Confirmed" && (
                  <CircleSuccessIcon />
                )}
                {transaction?.approvalStatus === "Rejected" && (
                  <CircleRejectedIcon />
                )}
                {transaction?.approvalStatus === "Pending" && (
                  <CirclePendingIcon />
                )}

                {transaction?.approvalStatus === "Confirmed"
                  ? "Successful"
                  : `${transaction.approvalStatus}`}
              </LightText>
            </FlexContainer>
            {transaction?.rejected ? (
              <>
                <FlexContainer padding="24px 0 0">
                  <LightText>Rejected on</LightText>
                  <LightText color={colors.themeTxtColor10}>
                    {parseDate(transaction?.rejected?.rejectedDate)}
                  </LightText>
                </FlexContainer>
                <FlexContainer padding="24px 0 0">
                  <LightText>Comment</LightText>
                </FlexContainer>
                <FlexContainer>
                  <LightText color={colors.themeTxtColor10}>
                    {transaction?.rejected?.comment || ""}
                  </LightText>
                </FlexContainer>
              </>
            ) : (
              <>
                {transaction?.approvalStatus === "Confirmed" && (
                  <FlexContainer padding="24px 0 0">
                    <LightText>Confirmed on</LightText>
                    <LightText color={colors.themeTxtColor10}>
                      {parseDate(transaction?.confirmed?.confirmedDate)}
                    </LightText>
                  </FlexContainer>
                )}
              </>
            )}

            <FlexContainer padding="24px 0 8px">
              <LightText>Orders</LightText>
              <LightText
                color={colors.themeTxtColor10}
              >{`${transaction.numberOfOrders} order (s)`}</LightText>
            </FlexContainer>
            {transaction?.orders &&
              transaction.orders.map((item, index) => (
                <OrderDetailCards key={index}>
                  <FlexContainer>
                    <Text
                      color={colors.themeTxtColor10}
                    >{`Order ${item.orderId}`}</Text>
                    <Text weight="500" color={colors.themeTxtColor10}>
                      {formatPrice(item.amount || 0)}
                    </Text>
                  </FlexContainer>
                  <Text>{item.customerName || ""}</Text>
                </OrderDetailCards>
              ))}
          </ScreenContainer>
        ) : (
          <ScreenContainer></ScreenContainer>
        )}
      </DesktopBackgroundLayout>
    </Fragment>
  );
};
