import React, { Fragment } from "react";

import styled from "styled-components";

import { Button } from "../../../../../components/button";
import { TopHeader } from "../../../../../components/header";
import FallbackProductImage from "../../../../../components/popup/merchbuy-add-to-cart-popup/assets/productImage.svg";
import { Close } from "../../../../../containers/HeaderContainer";
import { Message } from "../../../../../containers/MessageContainer";
import { SlidingOverlay } from "../../../../../containers/OverlayContainer";
import { formatPrice } from "../../../../../utils/currency/formatPriceWithComma";
import { is_url } from "../../../../../utils/urls/isUrl";
import { ReactComponent as Check } from "../../assets/check.svg";
import { ReactComponent as CircularInfoIcon } from "../../assets/circular_info.svg";
import { ReactComponent as ChartIcon } from "../../assets/pie_chart.svg";
import { ReactComponent as UnCheck } from "../../assets/uncheck.svg";
import { Divider } from "../../styles";
import {
  InfoMessage,
  Title,
  ProductInfo,
  ProductBlock,
  ProductImage,
  SpacedBlock,
} from "../styles";

const Container = styled.div`
  margin-top: ${({ top }) => top || "0"};
`;

const SlotSection = styled.div`
  padding: ${({ padding }) => padding || null};
  margin: ${({ margin }) => margin || "16px 0"};
  border-radius: 13px;
  border: 1px solid ${({ borderColor }) => borderColor || "#eeeeee"};
  min-height: ${({ minHeight }) => minHeight || "68px"};
  background-color: ${({ background }) => background || null};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  opacity: ${({ disabled }) => (disabled ? "0.6" : "1")};
`;

const SelectedSlot = styled.h3`
  font-weight: 500;
  font-size: 14px;
  color: #29394f;
`;

const QtyText = styled.h3`
  color: #56636d;
  text-align: left;
  font-weight: 400;
  font-size: 12px;
  margin-top: 4px;
`;

const FloatingBottomSection = styled.div`
  position: fixed;
  width: 100%;
  max-width: 375px;
  bottom: 1px;
  @media (max-width: 576px) {
    max-width: 100%;
  }
`;

const FBText = styled.h3`
  font-weight: 500;
  font-size: ${({ size }) => size || "10px"};
  color: #212c3d;
  margin-top: 8px;
`;

const PieChart = styled(ChartIcon)`
  margin: 0 8px 0 0;
  min-width: 32px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px 16px 6px 16px;
`;

export const GroupBuyInviteeSelectShare = ({
  open,
  cancel,
  productBulkPrice = 0,
  images,
  productName,
  unitValue,
  selectedIndex,
  setSelectedIndex,
  selectedSplit,
  setSelectedSplit,
  setSplitSelected,
  splits,
}) => {
  const renderInputs = () => {
    return (
      splits &&
      splits.map((el, index) => (
        <SlotSection
          key={index}
          onClick={() => {
            setSelectedIndex(index);
            setSelectedSplit(el.qty);
          }}
          disabled={el.claimed}
          margin="16px 16px"
          minHeight="68px"
          borderColor={selectedIndex === index && "#22a8ff"}
          background={selectedIndex === index && "#f1f6fd"}
        >
          <SpacedBlock align={"top"} style={{ padding: "16px" }}>
            <div>
              <SelectedSlot>
                {el.name}: {formatPrice(el.amount)}
              </SelectedSlot>
              <QtyText>{el.qty} Units</QtyText>
            </div>
            {selectedIndex === index ? <Check /> : <UnCheck />}
          </SpacedBlock>
        </SlotSection>
      ))
    );
  };

  const confirm = () => {
    setSplitSelected(true);
    cancel(!open);
  };

  return (
    open && (
      <SlidingOverlay zIndex={"1000"} position={"absolute"}>
        <Fragment>
          <TopHeader
            title={"Select Share"}
            right={"16px"}
            noArrow
            withSpacesHeader
          >
            <Close left={"16px"} onClick={() => cancel(!open)} />
          </TopHeader>
          <Container top="75px">
            <ProductBlock style={{ margin: "16px" }}>
              <ProductImage
                src={
                  is_url(images ? images.baseImageUrl : "")
                    ? images.baseImageUrl
                    : FallbackProductImage
                }
                alt="product image"
              />
              <ProductInfo>
                <Message top={"0"} size={"14px"} bottom={"10px"}>
                  {productName}
                </Message>
                <Message weight="500" size={"12px"}>
                  {productBulkPrice
                    ? formatPrice(productBulkPrice) +
                      "/" +
                      (unitValue || "Unit")
                    : ""}
                </Message>
              </ProductInfo>
            </ProductBlock>

            <Title style={{ margin: "16px" }}>Split</Title>
            <InfoMessage style={{ margin: "16px" }}>
              <CircularInfoIcon
                style={{ minWidth: "32px", marginRight: "8px" }}
              />
              <Message weight="500" size="12px" color="#579fd7" top={"0"}>
                The product has been split by {splits && splits.length} people.
                Choose a share.
              </Message>
            </InfoMessage>

            <Wrapper>
              <PieChart />
              <Message weight="500" size="14px" color="#253246" top={"0"}>
                Choose Your Share
              </Message>
            </Wrapper>

            <Divider top={"6px"} bottom={"16px"} />

            {renderInputs()}

            <FloatingBottomSection>
              <Divider top={"0px"} bottom={"0px"} />
              <SpacedBlock style={{ margin: "16px" }}>
                <div>
                  <FBText>Your share is</FBText>
                  <FBText size={"16px"}>
                    {formatPrice(productBulkPrice * selectedSplit)}
                  </FBText>
                </div>
                <Button
                  style={{
                    width: "fit-content",
                    padding: "12px 28px",
                    marginTop: "0px",
                  }}
                  disabled={!selectedSplit}
                  type={"button"}
                  onClick={confirm}
                >
                  Confirm
                </Button>
              </SpacedBlock>
            </FloatingBottomSection>
          </Container>
        </Fragment>
      </SlidingOverlay>
    )
  );
};

//export default GroupBuyInviteeSelectShare;
