import React from "react";

import { Formik, Form } from "formik";
import { bool, func } from "prop-types";
import styled from "styled-components";

import { Overlay } from "../../../containers/OverlayContainer";
import { InputWithLabel } from "../../forms/input/text";
import {
  PopUp,
  PopUpContent,
  PopUpHeader,
  InfoMessage,
  ConfirmButton,
} from "../../popup/common";

import { ValidationSchema } from "./validationSchema";

const Header = styled(PopUpHeader)`
  font-weight: 700;
  padding: 12px 8px;
`;

export const EnterReferralCodePopup = ({ open, setCode, cancel }) => {
  return (
    <PopUp open={open}>
      <Overlay onClick={cancel} nonSliding={true}></Overlay>
      <PopUpContent>
        <Header align={"left"}>My Agent</Header>
        <InfoMessage>Please enter agent referral code.</InfoMessage>
        <Formik
          initialValues={{
            referralCode: "",
          }}
          validationSchema={ValidationSchema}
          onSubmit={(values) => {
            setTimeout(() => {
              setCode(values.referralCode);
            }, 400);
          }}
        >
          {({ errors, touched, values, isValid, dirty }) => (
            <Form>
              <InputWithLabel
                label={"referral code"}
                type={"text"}
                value={values.referralCode}
                placeholder={"referral code"}
                name="referralCode"
                valid={`${touched.referralCode && !errors.referralCode}`}
                errors={
                  touched &&
                  touched.referralCode &&
                  errors &&
                  errors.referralCode
                }
                width={"90%"}
                left={"5%"}
                right={"5%"}
                top={"20px"}
              />
              <ConfirmButton type="submit" disabled={!(isValid && dirty)}>
                Submit
              </ConfirmButton>
            </Form>
          )}
        </Formik>
      </PopUpContent>
    </PopUp>
  );
};

EnterReferralCodePopup.propTypes = {
  open: bool,
  setCode: func,
  cancel: func,
};
