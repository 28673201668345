import React, { useEffect, useState } from "react";

import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import styled from "styled-components";

import {
  NewTextAreaWithLabel,
  RippleButton,
  TopHeader,
  Loader,
  FileInput3,
} from "../../../../components";
import { Title, SubTitle } from "../../../../containers/MessageContainer";
import { ScreenContainer } from "../../../../containers/ScreenContainer";
import { logComplaint } from "../../../../redux/ducks/applications/complaints/actions";
import { colors } from "../../../../styles";
import History from "../../../../utils/History";
import DesktopBackgroundLayout from "../../../DesktopBackgroundLayout";

import { ComplaintFormValidationSchema } from "./ComplaintFormValidationSchema";

const StyledButton = styled(RippleButton)`
  position: fixed;
  bottom: 40px;
  left: 0;
  right: 0;
  margin: auto;
  width: 343px;

  @media (max-width: 576px) {
    width: calc(100% - 32px);
  }
`;

const ComplaintsForm = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [transactionReference, setTransactionReference] = useState("");
  const [departmentId, setDepartmentId] = useState();
  const [issueTitle, setIssueTitle] = useState("");
  const [category, setCategory] = useState("");
  const [formData, setFormData] = useState();

  useEffect(() => {
    setTransactionReference(location?.state?.reference || "");
    setDepartmentId(location?.state?.departmentId || "");
    setIssueTitle(location?.state?.issueTitle || "");
    setCategory(location?.state?.category || "");
  }, [location]);

  if (loading) {
    return <Loader />;
  } else
    return (
      <DesktopBackgroundLayout>
        <TopHeader
          size={"14px"}
          color={colors.boldDark}
          title={"Log a Complaint/Enquiry"}
          noBorderBottom
        />

        <ScreenContainer>
          <Title
            size={"16px"}
            top={"25px"}
            weight={"700"}
            bottom={"6px"}
            lineHeight={"24px"}
            color={colors.boldDark}
            textAlign={"left"}
          >
            Complaint Information
          </Title>
          <SubTitle
            weight={"400"}
            bottom={"24px"}
            lineHeight={"21px"}
            color={colors.subGrey}
          >
            Kindly provide the following details
          </SubTitle>

          <Formik
            initialValues={{
              complaint: "",
            }}
            validationSchema={ComplaintFormValidationSchema}
            onSubmit={(values) => {
              setLoading(true);
              const params = {
                departmentId: departmentId,
                category: category,
                description: values.complaint,
                issueReferenceId: transactionReference,
                issueTitle: issueTitle,
                priority: "normal",
              };
              dispatch(logComplaint(params, formData)).then((res) => {
                if (res) {
                  toast.success("Complaint logged successfully");
                  History.goBack();
                }
                setLoading(false);
              });
            }}
          >
            {({ values }) => (
              <Form>
                <FileInput3
                  mimeType={"image/*"}
                  name="complaintImage"
                  compressFile
                  useFileName
                  useFormData
                  value={formData}
                  height="56px"
                  label={"Complaint Image"}
                  handleChange={(value) => {
                    setFormData(value);
                  }}
                  placeholder="Click to Upload Document here (optional)"
                />
                <NewTextAreaWithLabel
                  label={"Description"}
                  name={"complaint"}
                  placeholder={"Description"}
                  height={"150px"}
                  padding={"8px 16px"}
                  top="16px"
                />
                <StyledButton
                  top={"180px"}
                  type="submit"
                  disabled={!values.complaint}
                >
                  Log Complaint
                </StyledButton>
              </Form>
            )}
          </Formik>
        </ScreenContainer>
      </DesktopBackgroundLayout>
    );
};

export default ComplaintsForm;
