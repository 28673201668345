export const LOADING = "LOADING";
export const PROCESSING_ORDER = "PROCESSING_ORDER";
export const FETCH_RECOMMENDED_PRODUCTS_SUCCESS =
  "FETCH_RECOMMENDED_PRODUCTS_SUCCESS";
export const FETCH_RECOMMENDED_PRODUCTS_FAILURE =
  "FETCH_RECOMMENDED_PRODUCTS_FAILURE";
export const FETCH_POPULAR_PRODUCTS_SUCCESS = "FETCH_POPULAR_PRODUCTS_SUCCESS";
export const FETCH_POPULAR_PRODUCTS_FAILURE = "FETCH_POPULAR_PRODUCTS_FAILURE";
export const FETCH_ALL_PRODUCTS_SUCCESS = "FETCH_ALL_PRODUCTS_SUCCESS";
export const FETCH_ALL_PRODUCTS_FAILURE = "FETCH_ALL_PRODUCTS_FAILURE";
export const FETCH_PRODUCTS_CATEGORIES_SUCCESS =
  "FETCH_PRODUCTS_CATEGORIES_SUCCESS";
export const FETCH_PRODUCTS_CATEGORIES_FAILURE =
  "FETCH_PRODUCTS_CATEGORIES_FAILURE";
export const FETCH_PRODUCTS_BY_SHOP_ID_SUCCESS =
  "FETCH_PRODUCTS_BY_SHOP_ID_SUCCESS";
export const FETCH_PRODUCTS_BY_SHOP_ID_FAILURE =
  "FETCH_PRODUCTS_BY_SHOP_ID_FAILURE";
export const FETCH_SHOPS = "FETCH_SHOPS";
export const FETCH_SHOPS_SUCCESS = "FETCH_SHOPS_SUCCESS";
export const FETCH_SHOPS_FAILURE = "FETCH_SHOPS_FAILURE";
export const FETCH_SHOP_BY_ID_SUCCESS = "FETCH_SHOP_BY_ID_SUCCESS";
export const FETCH_SHOP_BY_ID_FAILURE = "FETCH_SHOP_BY_ID_FAILURE";
export const FETCH_SHOP = "FETCH_SHOP";
export const FETCH_PRODUCT_BY_ID_SUCCESS = "FETCH_PRODUCT_BY_ID_SUCCESS";
export const FETCH_PRODUCT_BY_ID_FAILURE = "FETCH_PRODUCT_BY_ID_FAILURE ";
export const FETCH_AVAILABLE_STATES_SUCCESS = "FETCH_AVAILABLE_STATES_SUCCESS";
export const FETCH_AVAILABLE_STATES_FAILURE = "FETCH_AVAILABLE_STATES_FAILURE";
export const FETCH_ALL_CITIES_SUCCESS = " FETCH_ALL_CITIES_SUCCESS";
export const FETCH_ALL_CITIES_FAILURE = " FETCH_ALL_CITIES_FAILURE ";
export const FETCH_SHOP_CART = "FETCH_SHOP_CART";
export const FETCH_SHIPPING_OPTIONS = "FETCH_SHIPPING_OPTIONS";
export const SAVE_LAST_ORDER_PLACED = "SAVE_LAST_ORDER_PLACED";
export const SERVICE_CHARGE_PERCENT = "SERVICE_CHARGE_PERCENT";
export const MERCHBUY_DELIVERY_LOCATION = "MERCHBUY_DELIVERY_LOCATION";
export const SET_CHOSEN_ORDER_HISTORY = "SET_CHOSEN_ORDER_HISTORY";
export const FETCH_CHOSEN_ORDER_HISTORY_FAILURE =
  "FETCH_CHOSEN_ORDER_HISTORY_FAILURE";
export const CLEAR_CHOSEN_ORDER_HISTORY = "CLEAR_CHOSEN_ORDER_HISTORY";
export const MERCHBUY_ORDER_HISTORY_SUCCESS = "MERCHBUY_ORDER_HISTORY_SUCCESS";
export const GROUPBUY_ORDER_HISTORY_SUCCESS = "GROUPBUY_ORDER_HISTORY_SUCCESS";
export const GROUPBUY_ORDER_HISTORY_FAILURE = "GROUPBUY_ORDER_HISTORY_FAILURE";
export const MERCHBUY_ORDER_HISTORY_FAILURE = "MERCHBUY_ORDER_HISTORY_FAILURE";
export const LOADING_ORDER_HISTORIES = "LOADING_ORDER_HISTORIES";
export const LOADING_ORDER_HISTORY = "LOADING_ORDER_HISTORY";
export const LOADING_ORDER_HISTORIES_BY_ITEMS =
  "LOADING_ORDER_HISTORIES_BY_ITEMS";

export const LOADING_GROUPBUY_ORDER_HISTORY = "LOADING_GROUPBUY_ORDER_HISTORY";
export const MERCHBUY_PAGINATED_CATEGORIES_SUCCESS =
  "MERCHBUY_PAGINATED_CATEGORIES_SUCCESS";
export const MERCHBUY_PAGINATED_CATEGORIES_FAILURE =
  "MERCHBUY_PAGINATED_CATEGORIES_FAILURE";

export const GROUP_BUY_EDUCATION = "LOADING_ORDER_HISTORY";
export const MERCHBUY_PAGINATED_SHOP_HISTORY_SUCCESS =
  "MERCHBUY_PAGINATED_SHOP_HISTORY_SUCCESS";
export const MERCHBUY_PAGINATED_SHOP_HISTORY_FAILURE =
  "MERCHBUY_PAGINATED_SHOP_HISTORY_FAILURE";
export const MERCHBUY_PAGINATED_PRODUCT_HISTORY_SUCCESS =
  "MERCHBUY_PAGINATED_PRODUCT_HISTORY_SUCCESS";
export const MERCHBUY_PAGINATED_PRODUCT_HISTORY_FAILURE =
  "MERCHBUY_PAGINATED_PRODUCT_HISTORY_FAILURE";
export const FETCH_SHIPPING_CONFIGURATION = "FETCH_SHIPPING_CONFIGURATION";
export const SAVE_PRODUCT_SEARCH_TERM = "SAVE_PRODUCT_SEARCH_TERM";
export const FETCH_SHOP_CART_DELETED_INFO = "FETCH_SHOP_CART_INFO";
export const MINIMUM_ORDER_VALUE = "MINIMUM_ORDER_VALUE";
export const DELIVERY_ADDRESS_EDIT_LIMIT = "DELIVERY_ADDRESS_EDIT_LIMIT";
export const FETCH_MERCHBUY_DEALS_SUCCESS = "FETCH_MERCHBUY_DEALS_SUCCESS";
export const FETCH_MERCHBUY_DEALS_FAILURE = "FETCH_MERCHBUY_DEALS_FAILURE";
export const FETCH_MERCHBUY_SEARCH_HISTORY_SUCCESS =
  "FETCH_MERCHBUY_SEARCH_HISTORY_SUCCESS";
export const FETCH_MERCHBUY_SEARCH_HISTORY_FAILURE =
  "FETCH_MERCHBUY_SEARCH_HISTORY_FAILURE";
export const FETCH_MERCHBUY_CATEGORY_BANNER_SUCCESS =
  "FETCH_MERCHBUY_CATEGORY_BANNER_SUCCESS";
export const FETCH_MERCHBUY_CATEGORY_BANNER_FAILURE =
  "FETCH_MERCHBUY_CATEGORY_BANNER_FAILURE";
export const FETCH_MERCHBUY_ALL_PRODUCTS_SUCCESS =
  "FETCH_MERCHBUY_ALL_PRODUCTS_SUCCESS";
export const FETCH_MERCHBUY_ALL_PRODUCTS_FAILURE =
  "FETCH_MERCHBUY_ALL_PRODUCTS_FAILURE";
export const FETCH_MERCHBUY_CART_CATEGORY_SUCCESS =
  "FETCH_MERCHBUY_CART_CATEGORY_SUCCESS";
export const FETCH_MERCHBUY_CART_CATEGORY_FAILURE =
  "FETCH_MERCHBUY_CART_CATEGORY_FAILURE";
export const FETCH_CATEGORIES = "FETCH_CATEGORIES";
export const MERCHBUY_PAGINATED_ORDER_HISTORY_SUCCESS =
  "MERCHBUY_PAGINATED_ORDER_HISTORY_SUCCESS";
export const MERCHBUY_FILTER_ORDER_HISTORY_SUCCESS =
  "MERCHBUY_FILTER_ORDER_HISTORY_SUCCESS";
export const MERCHBUY_ORDER_HISTORY_BY_ITEMS_SUCCESS =
  "MERCHBUY_ORDER_HISTORY_BY_ITEMS_SUCCESS";
export const MERCHBUY_PAGINATED_ORDER_HISTORY_BY_ITEMS_SUCCESS =
  "MERCHBUY_PAGINATED_ORDER_HISTORY_BY_ITEMS_SUCCESS,";
export const MERCHBUY_FILTER_ORDER_HISTORY_BY_ITEMS_SUCCESS =
  "MERCHBUY_FILTER_ORDER_HISTORY_BY_ITEMS_SUCCESS,";
export const SAVE_LAST_DELIVERY_ADDRESS = "SAVE_LAST_DELIVERY_ADDRESS,";
export const FETCH_AVAILABLE_COUNTRIES_SUCCESS =
  "FETCH_AVAILABLE_COUNTRIES_SUCCESS";
export const FETCH_AVAILABLE_COUNTRIES_FAILURE =
  "FETCH_AVAILABLE_COUNTRIES_FAILURE";
export const FETCH_NEW_AVAILABLE_STATES_SUCCESS =
  "FETCH_NEW_AVAILABLE_STATES_SUCCESS";
export const FETCH_NEW_AVAILABLE_STATES_FAILURE =
  "FETCH_NEW_AVAILABLE_STATES_FAILURE";
