import React, { Fragment, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";

import { ReactComponent as AgentsSvg } from "../../../../../assets/agents.svg";
import { IntroductionPopup } from "../../../../../components";
import { getAgentReferralsV2 } from "../../../../../redux/ducks/applications/agents/actions";
import {
  getAgentReferralsMerchantData,
  getMerchantReferralsData,
} from "../../../../../redux/ducks/applications/merchants/actions";
import { colors } from "../../../../../styles";
import History from "../../../../../utils/History";
import {
  MODULE_INTERACTION,
  SHOP_START_CREATE_PRODUCT,
  SHOP_START_CREATE_SALE,
} from "../../../../../utils/mix-panel/constants";
import { mixPanel } from "../../../../../utils/mix-panel/mixPanel";

import { ReactComponent as AddProductIcon } from "./assets/add_product.svg";
import { ReactComponent as AddSaleIcon } from "./assets/add_sale.svg";
// import { ReactComponent as BecomeAgentIcon } from "./assets/agent-briefcase.svg";
import { ReactComponent as AgentTeamIcon } from "./assets/agent-team.svg";
import { ReactComponent as ContactIcon } from "./assets/contact.svg";
import { ReactComponent as CustomersIcon } from "./assets/customers.svg";
import { ReactComponent as DepositIcon } from "./assets/deposit.svg";
import { ReactComponent as ElectricityBillIcon } from "./assets/electricity_bill.svg";
import { ReactComponent as GameIcon } from "./assets/game_pad.svg";
import { ReactComponent as MerchAppIcon } from "./assets/merchapp.svg";
import { ReactComponent as PinnedFileIcon } from "./assets/my-merchants.svg";
// import { ReactComponent as MyShopsIcon } from "./assets/my_shops.svg";
import { ReactComponent as AirtimeDataIcon2 } from "./assets/phone.svg";
import { ReactComponent as WithdrawIcon } from "./assets/withdraw.svg";

export const ActionsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(91px, 1fr));
  grid-gap: 11px;
  justify-items: center;
  @media screen and (max-width: 360px) {
    grid-gap: 8px;
  }
`;

export const ActionCell = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 168px;
  height: 56px;
  padding: 4px;
  border: 1px solid #edf2f7;
  border-radius: 8px;
  background-color: ${({ bg }) => bg || null};
  cursor: pointer;
  &:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -17px 0 0 -22px;
    background: white;
    border-radius: 6px;
    opacity: 0.7;
  }
  &:active:after {
    height: 34px;
    width: 45px;
    max-width: 160px;
    display: block;
    transform: scale(2);
    transition: 0.2s;
  }
`;

export const ActionCellTitle = styled.h6`
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  align-items: centre;
  text-align: center;
  color: ${colors.boldDark};
  margin-top: 8px;
  margin-left: 18px;
  margin-bottom: 10px;
`;

const ActionsList = ({ type, setStatusPopup, setOpenFeatureFixes }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const role = user && user.role;
  const userId = user && user.userId;
  const tier = user && user.tier;
  const agentState = useSelector((state) => state.user.agentState || "PENDING");
  const shops = useSelector((state) => state.applications.myShop.shops);
  const features = useSelector((state) => state.user.features);

  const [confirmUpgrade, setConfirmUpgrade] = useState(false);

  return (
    <ActionsGrid>
      {type === "AGENCYBANKING" && (
        <Fragment>
          <ActionCell
            bg={colors.dashboardActions[type]}
            onClick={() => {
              mixPanel.track(MODULE_INTERACTION, {
                "Icon Name": "Deposit",
                Time: new Date().toLocaleString(),
              });
            }}
          >
            <DepositIcon />
            <ActionCellTitle>Deposit</ActionCellTitle>
          </ActionCell>
          <ActionCell
            bg={colors.dashboardActions[type]}
            onClick={() => {
              mixPanel.track(MODULE_INTERACTION, {
                "Icon Name": "Withdraw",
                Time: new Date().toLocaleString(),
              });
            }}
          >
            <WithdrawIcon
              style={{ width: "20px", height: "20px", marginLeft: "14px" }}
            />
            <ActionCellTitle>Withdraw</ActionCellTitle>
          </ActionCell>
          <ActionCell
            bg={colors.dashboardActions[type]}
            onClick={() => {
              History.push("/actions/airtime");
              mixPanel.track(MODULE_INTERACTION, {
                "Icon Name": "Airtime & Data",
                Time: new Date().toLocaleString(),
              });
            }}
          >
            <AirtimeDataIcon2 />
            <ActionCellTitle>Airtime & Data</ActionCellTitle>
          </ActionCell>
          <ActionCell
            bg={colors.dashboardActions[type]}
            onClick={() => {
              History.push("/actions/electricity");
              mixPanel.track(MODULE_INTERACTION, {
                "Icon Name": "Electricity Bill",
                Time: new Date().toLocaleString(),
              });
            }}
          >
            <ElectricityBillIcon />
            <ActionCellTitle>Electricity Bill</ActionCellTitle>
          </ActionCell>
        </Fragment>
      )}
      {type === "LEARNING" && (
        <Fragment>
          <ActionCell
            bg={colors.white}
            onClick={() => {
              if (!features?.playGames?.status)
                return setOpenFeatureFixes(true);
              if (
                role === "ROLE_USER" ||
                (role === "ROLE_AGENT" && agentState === "APPROVED")
              ) {
                History.push("/games");
                mixPanel.track(MODULE_INTERACTION, {
                  "Icon Name": "Trivia",
                  Time: new Date().toLocaleString(),
                });
              } else {
                setStatusPopup(true);
              }
            }}
          >
            <GameIcon
              style={{ width: "20px", height: "20px", marginLeft: "14px" }}
            />
            <ActionCellTitle>Play Games</ActionCellTitle>
          </ActionCell>

          {/* {role === "ROLE_USER" && (
            <ActionCell
              bg={colors.white}
              onClick={() => setConfirmUpgrade(true)}
            >
              <BecomeAgentIcon
                style={{ width: "20px", height: "20px", marginLeft: "14px" }}
              />
              <ActionCellTitle>Be an agent</ActionCellTitle>
            </ActionCell>
          )} */}
        </Fragment>
      )}

      {type === "FINANCIAL" && (
        <Fragment>
          {/* <ActionCell
                            bg={colors.dashboardActions[type]}
                            onClick={() => {
                                if (agentState === "APPROVED") {
                                    mixPanel.track(MODULE_INTERACTION,
                                        {
                                            "Icon Name": "Merch List",
                                            "Time": (new Date()).toLocaleString()
                                        }
                                    )
                                } else {
                                    setStatusPopup(true);
                                }
                            }}
                        >
                            <MerchAppIcon/>
                            <ActionCellTitle>MerchList</ActionCellTitle>
                        </ActionCell> */}

          {tier && tier === "TIER_1" && (
            <Fragment>
              {/* <ActionCell
                                bg={colors.dashboardActions[type]}
                                onClick={() => {
                                    if (agentState === "APPROVED") {
                                        History.push("/actions/electricity");
                                        mixPanel.track(MODULE_INTERACTION,
                                            {
                                                "Icon Name": "Electricity Bill",
                                                "Time": (new Date()).toLocaleString()
                                            }
                                        )
                                    } else {
                                        setStatusPopup(true);
                                    }
                                }}
                            >
                                <ElectricityBillIcon2 />
                                <ActionCellTitle>Electricity Bill</ActionCellTitle>
                            </ActionCell> */}
            </Fragment>
          )}
        </Fragment>
      )}

      {type === "SHOP" && (
        <Fragment>
          {shops[0] && Object.keys(shops[0]).includes("branchId") && (
            <Fragment>
              <ActionCell
                bg={colors.dashboardActions[type]}
                onClick={() => {
                  if (
                    role === "ROLE_USER" ||
                    (role === "ROLE_AGENT" && agentState === "APPROVED")
                  ) {
                    History.push("/actions/shop_sales_add");
                    mixPanel.track(MODULE_INTERACTION, {
                      "Icon Name": "Add A Sale",
                      Time: new Date().toLocaleString(),
                    });
                    mixPanel.track(SHOP_START_CREATE_SALE, {
                      "Entry Point": "Dashboard",
                      Time: new Date().toLocaleString(),
                      "User ID": userId,
                      "shop ID": shops[0].id,
                    });
                  }
                }}
              >
                <AddSaleIcon
                  style={{ width: "20px", height: "20px", marginLeft: "14px" }}
                />
                <ActionCellTitle>Add A Sale</ActionCellTitle>
              </ActionCell>
              <ActionCell
                bg={colors.dashboardActions[type]}
                onClick={() => {
                  if (
                    role === "ROLE_USER" ||
                    (role === "ROLE_AGENT" && agentState === "APPROVED")
                  ) {
                    //History.push("/actions/shop_products_add");
                    History.push("/actions/shop_products_options");
                    mixPanel.track(MODULE_INTERACTION, {
                      "Icon Name": "Add A Product",
                      Time: new Date().toLocaleString(),
                    });
                    mixPanel.track(SHOP_START_CREATE_PRODUCT, {
                      "Entry Point": "Dashboard",
                    });
                  } else {
                    setStatusPopup(true);
                  }
                }}
              >
                <AddProductIcon
                  style={{ width: "20px", height: "20px", marginLeft: "14px" }}
                />
                <ActionCellTitle>Add A Product</ActionCellTitle>
              </ActionCell>
            </Fragment>
          )}
        </Fragment>
      )}

      {type === "CONTACTS" && (
        <Fragment>
          {role === "ROLE_AGENT" && (
            <ActionCell
              bg={colors.white}
              onClick={() => {
                if (!features?.agentTeam?.status)
                  return setOpenFeatureFixes(true);
                if (agentState === "APPROVED") {
                  dispatch(getAgentReferralsV2());
                  History.push("/actions/agents");
                  mixPanel.track(MODULE_INTERACTION, {
                    "Icon Name": "My Agents & Teams",
                    Time: new Date().toLocaleString(),
                  });
                } else {
                  setStatusPopup(true);
                }
              }}
            >
              <AgentTeamIcon
                style={{ width: "20px", height: "20px", marginLeft: "14px" }}
              />
              <ActionCellTitle>Agent team </ActionCellTitle>
            </ActionCell>
          )}

          {shops[0] && Object.keys(shops[0]).includes("branchId") && (
            <ActionCell
              bg={colors.white}
              onClick={() => {
                if (!features?.customers?.status)
                  return setOpenFeatureFixes(true);
                if (
                  role === "ROLE_USER" ||
                  (role === "ROLE_AGENT" && agentState === "APPROVED")
                ) {
                  History.push("/actions/shop_customers");
                  mixPanel.track(MODULE_INTERACTION, {
                    "Icon Name": "Customers",
                    Time: new Date().toLocaleString(),
                  });
                } else {
                  setStatusPopup(true);
                }
              }}
            >
              <CustomersIcon
                style={{ width: "20px", height: "20px", marginLeft: "14px" }}
              />
              <ActionCellTitle>Customers</ActionCellTitle>
            </ActionCell>
          )}

          {role === "ROLE_AGENT" && (
            <Fragment>
              <ActionCell
                bg={colors.white}
                onClick={() => {
                  if (!features?.myMerchants?.status)
                    return setOpenFeatureFixes(true);
                  if (agentState === "APPROVED") {
                    dispatch(getAgentReferralsMerchantData());
                    History.push("/actions/merchants");
                    mixPanel.track(MODULE_INTERACTION, {
                      "Icon Name": "My Merchants",
                      Time: new Date().toLocaleString(),
                    });
                  } else {
                    setStatusPopup(true);
                  }
                }}
              >
                <PinnedFileIcon
                  style={{ width: "20px", height: "20px", marginLeft: "14px" }}
                />
                <ActionCellTitle>My Merchants</ActionCellTitle>
              </ActionCell>
            </Fragment>
          )}

          {role === "ROLE_USER" && (
            <Fragment>
              <ActionCell
                bg={colors.white}
                onClick={() => {
                  dispatch(getMerchantReferralsData());
                  History.push("/actions/merchant_referrals");
                  mixPanel.track(MODULE_INTERACTION, {
                    "Icon Name": "Referrals",
                    Time: new Date().toLocaleString(),
                  });
                }}
              >
                <PinnedFileIcon
                  style={{ width: "20px", height: "20px", marginLeft: "14px" }}
                />
                <ActionCellTitle> My Referrals</ActionCellTitle>
              </ActionCell>
            </Fragment>
          )}
        </Fragment>
      )}

      {type === "SERVICES" && (
        <Fragment>
          <ActionCell
            bg={colors.dashboardActions[type]}
            onClick={() => {
              if (!navigator.onLine) {
                toast.error(
                  "sorry, merchbuy can't be accessed while you are offline"
                );
              } else {
                History.push("/actions/merchbuy");
                mixPanel.track(MODULE_INTERACTION, {
                  "Icon Name": "MerchBuy",
                  Time: new Date().toLocaleString(),
                });
              }
            }}
          >
            <MerchAppIcon />
            <ActionCellTitle>MerchBuy</ActionCellTitle>
          </ActionCell>
        </Fragment>
      )}

      {type === "HELP" && (
        <Fragment>
          <ActionCell
            bg={colors.white}
            onClick={() => {
              if (!features?.support?.status) return setOpenFeatureFixes(true);
              history.push("/help");
            }}
          >
            <ContactIcon
              style={{ width: "20px", height: "20px", marginLeft: "14px" }}
            />
            <ActionCellTitle>Contact Us</ActionCellTitle>
          </ActionCell>
        </Fragment>
      )}

      <IntroductionPopup
        open={confirmUpgrade}
        cancel={() => setConfirmUpgrade(!confirmUpgrade)}
        confirm={() => {
          setConfirmUpgrade(!confirmUpgrade);
          history.push("/actions/um_agent_region_selection");
        }}
        title={"Agent"}
        Logo={AgentsSvg}
        logoSpacing={"30px"}
        withConfirmation={true}
        confirmText={"Continue"}
        message={
          "Upgrade to an agent and earn money from merchants you sign up and services you offer."
        }
      />
    </ActionsGrid>
  );
};

export default ActionsList;
