import React, { Fragment, useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { matchPath, useHistory } from "react-router-dom";
import aa from "search-insights";
import { toast } from "react-toastify";
import styled from "styled-components";
import useRouter from "use-react-router";

import {
  TopHeader,
  ProductDetailsPopup,
  MerchbuyAddtoCartPopup,
  InfoPopupDialog,
  DeliveryLocationPopup,
  Button,
  SharePopup,
  BuynowPopup,
  Loader,
} from "../../../../components";
import { LightButton, InlineButton } from "../../../../components/button";
import { currentHost } from "../../../../config/API";
import { ScreenContainer } from "../../../../containers/ScreenContainer";
import { FeatureUndergoingFixes } from "../../../../components/popup/feature-undergoing-fixes";
import { merchbuyActions } from "../../../../redux/ducks/applications/merchbuy/actions";
import { colors } from "../../../../styles";
import { formatCurrency } from "../../../../utils/currency/parseBalance";
import {
  INITIATE_ADD_TO_CART,
  PRODUCT_DETAILS,
  PRODUCT_DESCRIPTION_POPUP,
  INITIATE_INSTANT_PURCHASE,
  COMPLETE_INSTANT_PURCHASE,
} from "../../../../utils/mix-panel/constants";
import { mixPanel } from "../../../../utils/mix-panel/mixPanel";
import { is_url } from "../../../../utils/urls/isUrl";
import DesktopBackgroundLayout from "../../../DesktopBackgroundLayout";
import { ReactComponent as BuyIcon } from "../assets/buy.svg";
import { ReactComponent as EmptyCartonSvg } from "../assets/empty_carton.svg";
import { ReactComponent as LightBulb } from "../assets/lightbulb.svg";
import ProductFallbackImage from "../assets/productFallbackImage.png";
import { ReactComponent as SearchSvg } from "../assets/search.svg";
import { ReactComponent as StoreSymbol } from "../assets/shop_icon.svg";

import { ListProducts } from "../components";
import {
  PageSubSectionsTopLeft,
  RecommendedProductsIcon,
  Description,
  MainProductImage,
  FixedBottom,
} from "../styles";
import { productCategoryName } from "../utils/getProductCatNameById";
import { BuyNowEducation } from "./buyNowEducation";

export const PageSubSectionsTopLeftTitle = styled.h4`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  margin-left: 10px;
  color: #29394f;
`;

const MoqWrapper = styled.div`
  display: grid;
  gap: 8px;
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 -1em;
  padding: 8px 16px;
  background: ${colors.white};
`;
const ShopIcon = styled(StoreSymbol)`
  height: 19.2px;
  width: 21.6px;
`;

const RecommendedProductWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const StoreNameText = styled.p`
  margin: 0;
  margin-left: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #071827;
`;

const DescriptionHeaderText = styled.p`
  margin: 8px 0 4px;
  font-size: 12px;
  font-weight: 700;
  color: #718596;
  line-height: 18px;
`;

const MoqContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MoqHeaderText = styled.p`
  margin: 0;
  color: #718596;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  width: 75%;
`;

const MoqBodyText = styled.p`
  color: ${colors.boldDark};
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  width: 75%;
`;

const BuyButtons = styled.div`
  display: flex;
  width: 90%;
`;

const PriceText = styled.p`
  margin: 8px 0 0;
  font-size: 20px;
  font-weight: 700;
  color: ${colors.boldDark};
  line-height: 30px;
`;

const TopIconWrapper = styled.div`
  display: flex;
  gap: 13px;
  position: absolute;
  right: 15px;
  align-items: center;
  justify-content: center;
`;

const CartCount = styled.div`
  width: 16px;
  height: 16px;
  background-color: #ee6f44;
  border-radius: 26px;
  position: absolute;
  margin-top: -32px;
  margin-left: 14px;
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  color: ${colors.white};
  padding-top: 2px;
`;
const ShopHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const UnAvailableProductContainer = styled.div`
  width: 334px;
  height: 343px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${colors.white};
  border-radius: 8px;
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  padding-top: 20px;
`;

const AlphaText = styled.p`
  margin-top: 19px;
  font-size: 14px;
  font-weight: 700;
  color: ${colors.boldDark};
  line-height: 21px;
`;

const BetaText = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: ${colors.themeTextColor11};
  line-height: 18px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.white};
  padding: 16px;
  align-items: ${({ align }) => align || null};
  margin: 0 -1em ${({ bottom }) => bottom || "0"};
`;

const ProductDetails = (selectedMoq, setSelectedMoq) => {
  const history = useHistory();
  const { location } = useRouter();
  const dispatch = useDispatch();

  const { state, pathname } = location;
  const pattern = `(.*)?${"/actions/merchbuy/product/:id"}`;
  const match = matchPath(pathname, { path: pattern }) || {};

  const cartItems = useSelector(
    (state) => state.applications.merchbuy.shopCart
  );
  const recommendedProducts = useSelector(
    (state) => state.applications.merchbuy.merchbuyRecommendedProducts
  );
  const productCategeries = useSelector(
    (state) => state.applications.merchbuy.merchbuyProductCategeries
  );
  const product = useSelector((state) => state.applications.merchbuy.product);
  const shopDetails = useSelector((state) => state.applications.merchbuy.shop);
  const userId = useSelector((state) => state.user.userId);
  const features = useSelector((state) => state.user.features);
  const minimumOrderValue = useSelector(
    (state) => state.applications.merchbuy.minimumOrderValue
  );

  const [openProductDetailsPopup, setOpenProductDetailsPopup] = useState(false);
  const [openCartPopup, setOpenCartPopup] = useState(false);
  const [openContactMerchant, setOpenContactMerchant] = useState(false);
  const [openBuynowPopup, setOpenBuynowPopup] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [showError, setShowError] = useState(false);
  const [deliveryLocation, setDeliveryLocation] = useState(
    JSON.parse(localStorage.getItem("deliveryLocation"))
  );
  const [openShareLinks, setOpenShareLinks] = useState(false);
  const url = `${currentHost}/actions/merchbuy/product/${match.params.id}`;
  const productIndex = process.env.REACT_APP_ALGOLIA_SEARCH_INDEX;

  const [openDeliverLocationPopup, setOpenDeliverLocationPopup] = useState(
    deliveryLocation === null
  );
  const [loaderText] = useState("Loading");
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isProductAvailable, setIsProductAvailable] = useState(true);
  const [openFeatureFixes, setOpenFeatureFixes] = useState(false);
  const [showEducate, setShowEducate] = useState(false);
  const [educatePage, setEducatePage] = useState(1);

  const { images, description, bulkPrices, unitValue, minPrice, maxPrice } =
    product || {};

  const getLocation = (data) => {
    if (data.state) {
      setDeliveryLocation(data);
      setOpenDeliverLocationPopup(!openDeliverLocationPopup);
    }
  };

  useEffect(() => {
    mixPanel.track(PRODUCT_DETAILS, {
      "Product id": product.id,
      "Product name": product.name,
      "Product category": productCategoryName(
        productCategeries,
        product.primaryCategory
      ),
      "Product max-price": product.maxPrice,
      "Shop name": shopDetails.name,
      "Shop id": shopDetails.id,
      Time: new Date().toLocaleString(),
    });

    aa("viewedObjectIDs", {
      userToken: userId,
      index: productIndex,
      eventName: "Product Viewed",
      objectIDs: [match.params.id],
    });
    dispatch(merchbuyActions.getRecommendedProducts(10, ""));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id]);

  useEffect(() => {
    dispatch(merchbuyActions.getProductDetials(match.params.id)).then(
      product.branchId &&
        dispatch(merchbuyActions.getShopDetials(product.branchId))
    );
  }, [match.params.id, product.branchId, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(merchbuyActions.unsubscribe());
    };
  }, [dispatch]);

  const handleAddToCart = () => {
    mixPanel.track(INITIATE_ADD_TO_CART, {
      "Product name": product.name,
      "Shop name": shopDetails.name,
      "Shop id": shopDetails.id,
      Time: new Date().toLocaleString(),
    });

    setOpenCartPopup(!openCartPopup);
  };

  const handleBuyNow = async (newProduct) => {
    const totalBuyNowPrice =
      newProduct?.quantity * newProduct?.bulkPrices?.[0].price;

    if (minimumOrderValue.value > totalBuyNowPrice) {
      setShowError(true);
    } else {
      setOpenBuynowPopup(!openBuynowPopup);

      const params = {
        productIds: [product?.id],
      };

      setLoading(true);
      const res = await dispatch(merchbuyActions.cartItemsValidation(params));

      if (res) {
        const itemCopy = res[0];
        if (
          !itemCopy?.listingOptions?.isOnMerchBuy ||
          itemCopy.state !== deliveryLocation.state
        ) {
          setIsProductAvailable(false);
          setLoading(false);
          toast.error("Product not available for purchase");
        } else {
          mixPanel.track(COMPLETE_INSTANT_PURCHASE, {
            "Product name": newProduct.name,
            "Product ID": newProduct.id,
            "Product Quantity": newProduct.quantity,
            "Product unit price": newProduct?.bulkPrices?.[0].price,
            "Product volume price pair": totalBuyNowPrice,
          });

          history.push({
            pathname: "/actions/merchbuy/checkout",
            state: {
              items: newProduct,
              fromBuyNow: true,
            },
          });
        }
      } else {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const isProductAvailable = async () => {
      const params = {
        productIds: [match?.params?.id],
      };
      setLoading(true);
      const res = await dispatch(merchbuyActions.cartItemsValidation(params));

      if (res) {
        const itemCopy = res[0];
        if (
          !itemCopy?.quantity?.retail ||
          !itemCopy?.listingOptions?.isOnMerchBuy ||
          itemCopy.state !== deliveryLocation.state
        ) {
          setIsProductAvailable(false);
        }
      }
      setLoading(false);
    };

    location?.state?.fromOrderHistory && isProductAvailable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleViewMore = () => {
    mixPanel.track(PRODUCT_DESCRIPTION_POPUP, {
      "Product id": product.id,
      "Product name": product.name,
      "Product category": productCategoryName(
        productCategeries,
        product.primaryCategory
      ),
      "Product max-price": product.maxPrice,
    });

    history.push({
      pathname: "/actions/merchbuy/description",
      state: product,
    });
  };

  const handleBuynowClick = () => {
    if (!features?.buyNow?.status) return setOpenFeatureFixes(true);
    mixPanel.track(INITIATE_INSTANT_PURCHASE, {
      "Product name": product.name,
      "Product ID": product.id,
    });
    setOpenBuynowPopup(true);
  };

  useEffect(() => {
    dispatch(merchbuyActions.getMinimumOrderValue());
  }, [dispatch]);

  useEffect(() => {
    const showEducation =
      localStorage.getItem("buyNowTutorial") &&
      JSON.parse(localStorage.getItem("buyNowTutorial"));
    !showEducation && setShowEducate(true);
  }, []);

  const handleCloseEducate = () => {
    setTimeout(() => {
      setShowEducate(false);
    }, 500);
  };

  const handleEducateDone = () => {
    handleCloseEducate();
    localStorage.setItem("buyNowTutorial", JSON.stringify(true));
  };

  if (loading) return <Loader text={loaderText} width={"140px"} />;

  return (
    <Fragment>
      <DesktopBackgroundLayout>
        <TopHeader
          title={"Product Details"}
          weight="400"
          noBorderBottom
          backLink={state?.fromBuyNow ? `/actions/merchbuy` : null}
        >
          <TopIconWrapper>
            <div onClick={() => history.push("/actions/merchbuy/search")}>
              <SearchSvg />
            </div>
            <div onClick={() => history.push("/actions/merchbuy/cart")}>
              <BuyIcon />
              <CartCount>{cartItems ? cartItems.length : 0}</CartCount>
            </div>
          </TopIconWrapper>
        </TopHeader>

        {showEducate && (
          <BuyNowEducation
            handleEducateDone={handleEducateDone}
            educatePage={educatePage}
            setEducatePage={setEducatePage}
          />
        )}

        <ScreenContainer color={colors.lightestGray} paddingTop="72px">
          {isProductAvailable ? (
            <Fragment>
              <ImageWrapper>
                <MainProductImage
                  src={
                    is_url(images && images.baseImageUrl)
                      ? images.baseImageUrl
                      : ProductFallbackImage
                  }
                  onError={(e) => {
                    e.target.src = ProductFallbackImage;
                  }}
                  alt="product image"
                  margin="0"
                  width={"345px"}
                  height={"269px"}
                />
              </ImageWrapper>
              <Container>
                <Description>
                  {(product.name && product.name.toLowerCase()) +
                    " - " +
                    (product.unitValue || "units")}
                </Description>
                <PriceText>
                  {minPrice && minPrice === maxPrice && maxPrice
                    ? `${formatCurrency(minPrice || 0)}`
                    : `${formatCurrency(minPrice || 0)} - ${formatCurrency(
                        maxPrice || 0
                      )} `}{" "}
                </PriceText>
              </Container>

              <Container>
                <MoqWrapper>
                  <MoqContainer>
                    <MoqHeaderText>Quantity</MoqHeaderText>
                    <MoqHeaderText>Price</MoqHeaderText>
                  </MoqContainer>
                  {bulkPrices &&
                    bulkPrices.map((bulk, index) => {
                      const moq =
                        index + 1 < bulkPrices.length
                          ? `${bulk.moq}-${
                              bulkPrices[index + 1].moq
                            } ${unitValue}`
                          : `>= ${bulk.moq} ${unitValue}`;
                      const price = formatCurrency(bulk.price);
                      return (
                        <MoqContainer key={index}>
                          <MoqBodyText>{moq}</MoqBodyText>
                          <MoqBodyText>{price}</MoqBodyText>
                        </MoqContainer>
                      );
                    })}
                </MoqWrapper>
              </Container>

              <DescriptionHeaderText>PRODUCT DESCRIPTION</DescriptionHeaderText>

              <Container align="start">
                <Description>{description}</Description>
                <InlineButton
                  weight="400"
                  height="18px"
                  onClick={handleViewMore}
                >
                  View more
                </InlineButton>
              </Container>

              <DescriptionHeaderText>SHOP INFORMATION</DescriptionHeaderText>

              <Container bottom="8px">
                <PageSubSectionsTopLeft>
                  <ShopHolder>
                    <ShopIcon />
                    <StoreNameText>
                      {shopDetails && shopDetails.name}
                    </StoreNameText>
                  </ShopHolder>
                  <InlineButton
                    weight="400"
                    height="18px"
                    onClick={() =>
                      shopDetails &&
                      history.push(`/actions/merchbuy/shop/${shopDetails.id}`)
                    }
                  >
                    {" "}
                    View Details
                  </InlineButton>
                </PageSubSectionsTopLeft>
              </Container>
            </Fragment>
          ) : (
            <UnAvailableProductContainer>
              <EmptyCartonSvg />
              <AlphaText>This product is not available</AlphaText>
              <LightBulb />
              <BetaText>
                You can choose to go back or view other
                <br /> recommended products
              </BetaText>
            </UnAvailableProductContainer>
          )}

          <Container>
            <RecommendedProductWrapper>
              <RecommendedProductsIcon />
              <PageSubSectionsTopLeftTitle>
                Recommended products
              </PageSubSectionsTopLeftTitle>
            </RecommendedProductWrapper>
            <ListProducts
              data={recommendedProducts?.slice(0, 3)}
              size={{ width: "102px", height: "98px" }}
              justifyContent={"center"}
            />
          </Container>
        </ScreenContainer>

        <FixedBottom>
          <BuyButtons>
            <Button
              top={"10px"}
              type={"button"}
              margin={"0 8px 0 0"}
              zIndex={showEducate && educatePage === 1 ? "2000" : null}
              backgroundColor="#227EFF"
              radius="4px"
              onClick={handleAddToCart}
            >
              Add To Cart
            </Button>
            <LightButton
              background="#FFFFFF"
              top="10px"
              color="#227EFF"
              zIndex={showEducate && educatePage === 2 ? "2000" : null}
              radius="4px"
              onClick={handleBuynowClick}
            >
              Buy Now
            </LightButton>
          </BuyButtons>
        </FixedBottom>
        <ProductDetailsPopup
          open={openProductDetailsPopup}
          data={product}
          productCategoryName={productCategoryName}
          productCategeries={productCategeries}
          cancel={() => {
            setOpenProductDetailsPopup(!openProductDetailsPopup);
          }}
        />
        <MerchbuyAddtoCartPopup
          open={openCartPopup}
          data={product}
          shopDetails={shopDetails}
          cancel={() => setOpenCartPopup(!openCartPopup)}
          confirm={merchbuyActions.addProductToCart}
        />
        <BuynowPopup
          open={openBuynowPopup}
          data={product}
          cancel={() => setOpenBuynowPopup(!openBuynowPopup)}
          confirm={handleBuyNow}
          selectedMoq={selectedMoq}
          setSelectedMoq={setSelectedMoq}
          showError={showError}
          minimumOrderValue={minimumOrderValue}
          totalPrice={totalPrice}
          setTotalPrice={setTotalPrice}
        />
        <InfoPopupDialog
          open={openContactMerchant}
          cancel={() => setOpenContactMerchant(!openContactMerchant)}
          title={"Contact Merchant"}
          message={"Coming Soon."}
          padding={"38px 8px"}
          messagePadding={"5px 0 40px 0"}
        />
        {openDeliverLocationPopup && (
          <>
            <DeliveryLocationPopup
              open={openDeliverLocationPopup}
              getLocation={getLocation}
              cancel={() => {
                setOpenProductDetailsPopup(!openProductDetailsPopup);
              }}
            />
            <MerchbuyAddtoCartPopup
              open={openCartPopup}
              data={product}
              cancel={() => setOpenCartPopup(!openCartPopup)}
              confirm={merchbuyActions.addProductToCart}
            />
            <InfoPopupDialog
              open={openContactMerchant}
              cancel={() => setOpenContactMerchant(!openContactMerchant)}
              title={"Contact Merchant"}
              message={"Coming Soon."}
              padding={"38px 8px"}
              messagePadding={"5px 0 40px 0"}
            />
          </>
        )}
        {openShareLinks && (
          <SharePopup
            url={url}
            marketingMessage={""}
            shareMessage={"Share link to product"}
            open={openShareLinks}
            setOpen={setOpenShareLinks}
          />
        )}
        {openFeatureFixes && (
          <FeatureUndergoingFixes cancel={() => setOpenFeatureFixes(false)} />
        )}
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default ProductDetails;
