import React, { useState } from "react";

import { number, func, any } from "prop-types";
import styled from "styled-components";

import {
  InputWithOnchange,
  RippleButton,
} from "../../../../../../../components";
import { ScreenContainer } from "../../../../../../../containers/ScreenContainer";
import { colors } from "../../../../../../../styles";
import { formatCurrency } from "../../../../../../../utils/currency/parseBalance";
import { SalesModal } from "../../../components/modal";

const RadioButtonBox = styled.div`
  display: flex;
  cursor: pointer;
  width: 259px;
  height: 40px;
  align-items: center;
  border-radius: 12px;
  background: #f2f5fa;
  margin: auto;
  padding: 0 5px;
`;

const AmountInput = styled.input.attrs({ type: "radio" })`
  display: inline-block;
  display: none;

  & + label {
    flex: 1;
    color: #071827;
    background: #f2f5fa;
    font-size: 12px;
    text-align: center;
    width: 112px;
    line-height: 18px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:checked + label {
    background: ${colors.white};
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.05);
    color: #212c3d;
    font-weight: 500;
    padding: 14px 0;
    border-radius: 8px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const PercentageInput = styled.input.attrs({ type: "radio" })`
  display: inline-block;
  display: none;
  & + label {
    flex: 1;
    color: #071827;
    background: #f2f5fa;
    font-size: 12px;
    text-align: center;
    width: 112px;
    line-height: 18px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:checked + label {
    background: ${colors.white};
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.05);
    color: #212c3d;
    font-weight: 500;
    padding: 14px 0;
    border-radius: 8px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  margin: 4px 0;
  font-style: oblique;
  font-size: 10px;
  font-weight: 400;
`;

const Space = styled.div`
  width: 13px;
`;

const ButtonWrapper = styled.div`
  position: fixed;
  padding: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 0;
  right: 0;
  bottom: 0px;
  width: 100%;
  max-width: 355px;
  margin: auto;
  @media (max-width: 576px) {
    max-width: 100%;
  }
`;

export const DiscountPopUp = ({
  open,
  cancel,
  setDiscount,
  subTotal,
  discount,
  setFieldValue,
}) => {
  const [viewType, setViewType] = useState(true);
  const [amount, setAmount] = useState(discount || "");
  const [percent, setPercent] = useState("");
  const [error, setError] = useState(false);

  return (
    <SalesModal height="85vh" open={open} title="Add Discount" cancel={cancel}>
      <RadioButtonBox>
        <AmountInput
          name="discount"
          id="amount"
          checked={viewType}
          onChange={() => setViewType((prev) => !prev)}
        />
        <label htmlFor="amount">Amount (R) </label>
        <PercentageInput
          name="discount"
          id="percentage"
          checked={!viewType}
          onChange={() => setViewType((prev) => !prev)}
        />
        <label htmlFor="percentage">Percentage (%)</label>
      </RadioButtonBox>

      <ScreenContainer top="0">
        {viewType ? (
          <div>
            <InputWithOnchange
              label={"Amount"}
              type="text"
              inputMode={"numeric"}
              name="amount"
              value={
                !amount || amount === 0 ? "" : formatCurrency(amount, "SA")
              }
              placeholder="R Amount"
              onChange={(e) => {
                const {
                  target: { value: inputValue = 0 },
                } = e;
                const value = parseInt(inputValue.replace(/\D/g, "") || 0, 10);
                setAmount(value);
              }}
              onKeyUp={(e) => {
                if (amount > subTotal) {
                  setError(true);
                } else {
                  setError(false);
                }
              }}
              height="56px"
            />
            {error && (
              <ErrorMessage>
                Discount value must not be greater than subtotal
              </ErrorMessage>
            )}
          </div>
        ) : (
          <div>
            <InputWithOnchange
              label={"Percentage"}
              placeholder={"% Percentage"}
              inputMode={"numeric"}
              value={!percent ? percent : `% ${percent}`}
              name="percentage"
              onChange={(e) => {
                const val =
                  e.target.value.length === 1
                    ? e.target.value
                    : e.target.value.substring(1);
                const trimmedVal = val.trim();
                const value = (Number(trimmedVal) / 100) * subTotal;
                setAmount(value);
                setPercent(Number(trimmedVal));
              }}
              onKeyUp={(e) => {
                if (percent > 100) {
                  setError(true);
                } else {
                  setError(false);
                }
              }}
              height="56px"
            />
            {error && (
              <ErrorMessage>value must not be greater than 100</ErrorMessage>
            )}
          </div>
        )}
      </ScreenContainer>
      <ButtonWrapper>
        <RippleButton
          backgroundColor={colors.white}
          color="#718596"
          border="1px solid #718596"
          type="button"
          onClick={() => {
            setDiscount(0);
            setAmount(0);
            setPercent(0);
            cancel();
          }}
        >
          Cancel
        </RippleButton>
        <Space />
        <RippleButton
          type="button"
          disabled={error}
          onClick={() => {
            setDiscount(amount);
            setFieldValue("discount", amount);
            cancel();
          }}
        >
          Apply
        </RippleButton>
      </ButtonWrapper>
    </SalesModal>
  );
};

DiscountPopUp.propTypes = {
  id: number,
  data: any,
  handleChange: func,
  remove: func,
};
