import React from "react";

import { toast } from "react-toastify";
import styled from "styled-components";

import { RippleButton } from "../../../../components";
import { colors } from "../../../../styles";
import { ReactComponent as CopySvg } from "../assets/copy.svg";
import { ReactComponent as LinkSvg } from "../assets/link.svg";

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.gray4};
  margin-bottom: ${({ status }) => (status === "PENDING" ? "16px" : "4px")};
  margin-top: 16px;
  padding: 0 10px;
  border-radius: 8px;
  height: 48px;
`;

const PaymentLinkText = styled.p`
  font-weight: 500;
  font-size: 12px;
  color: ${colors.bgBlue};
  margin: 0;
  max-width: 90%;
  overflow-wrap: break-word;
`;

const Container = styled.div`
  padding-bottom: ${({ bottom }) => bottom || "43px"};
  width: ${({ width }) => width || null};
  margin: ${({ margin }) => margin || null};
`;

const LinkSvgContainer = styled.div`
  width: 29.01px;
  height: 32px;
  border-radius: 4px;
  background-color: ${colors.lightGray};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8.16px;
  padding: 10px 6.3px;
`;

const SharePaymentLink = ({ paymentLink, bottom, status, width, margin }) => {
  const copyPaymentLink = () => {
    if ("clipboard" in navigator) {
      toast.success("copied to clipboard!");
      return navigator.clipboard.writeText(paymentLink);
    } else {
      toast.success("copied to clipboard!");
      return document.execCommand("copy", true, paymentLink);
    }
  };
  return (
    <Container bottom={bottom} width={width} margin={margin}>
      <LinkContainer status={status}>
        <LinkSvgContainer>
          <LinkSvg />
        </LinkSvgContainer>
        <PaymentLinkText>{paymentLink}</PaymentLinkText>
      </LinkContainer>
      {status === "PENDING" && (
        <ButtonWrapper>
          <CopySvg onClick={copyPaymentLink} />
          <RippleButton height="48px" top="0" width={"85%"}>
            Share Link
          </RippleButton>
        </ButtonWrapper>
      )}
    </Container>
  );
};

export default SharePaymentLink;
