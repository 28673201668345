import React, { Fragment, useState } from "react";

import { useSelector } from "react-redux";
import styled from "styled-components";

import { RippleLink } from "../../../../../components";
import {
  MenuOptionLogo,
  ArrowForward,
} from "../../../../../containers/MenuContainer";
import { colors } from "../../../../../styles";
import SFManagePrdouctIcon from "../../assets/manage-product.svg";
import MOIcons from "../../assets/merchbuy-orders.svg";

const OptionName = styled.h4`
  font-weight: 400;
  color: #071827;
  font-size: 14px;
  margin: auto 16px;
`;

const OptionSubText = styled.h4`
  font-weight: 400;
  color: #212c3d80;
  font-size: 10px;
  margin: 4px 56px 0 16px;
`;

const OptionWithSubText = styled.div`
  display: flex;
  flex-direction: column;
`;

const MenuOption = styled.li`
  display: flex;
  flex-direction: row;
  padding: 8px 0 8px 1em;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &:not(:first-of-type) {
    border-top: 1px solid ${colors.border.top};
  }
  &:last-of-type {
    border-bottom: 1px solid ${colors.border.bottom};
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
`;

const MerchBuyAccount = () => {
  const shops = useSelector((state) => state.applications.myShop.shops);
  const [shop] = useState(shops[0]);
  // const role = useSelector(state => state.user.role);
  // const agentState = useSelector(state => state.user.agentState || "PENDING")

  return (
    <Fragment>
      {/* <RippleLink to={"/actions/merchbuy/account/shopping-location"}>
                    <MenuOption style={{ alignItems: "center" }}>
                        <ImageWrapper>
                            <MenuOptionLogo icon={LOIcon} />
                        </ImageWrapper>
                        <OptionWithSubText>
                            <OptionName>Shopping Location</OptionName>
                            <OptionSubText>Manage the location you want to shop from</OptionSubText>
                        </OptionWithSubText>
                        <ArrowForward />
                    </MenuOption>
                </RippleLink> */}
      <RippleLink to={"/actions/merchbuy/account/orders"}>
        <MenuOption style={{ alignItems: "center" }}>
          <ImageWrapper>
            <MenuOptionLogo icon={MOIcons} />
          </ImageWrapper>
          <OptionWithSubText>
            <OptionName>MerchBuy Customer Orders</OptionName>
            <OptionSubText>
              Manage orders from your customers on merchbuy
            </OptionSubText>
          </OptionWithSubText>
          <ArrowForward />
        </MenuOption>
      </RippleLink>
      <RippleLink
        to={{
          pathname: "/actions/merchbuy/account/products",
          state: shop && {
            branchId: shop.branchId,
            merchantId: shop.merchantId,
          },
        }}
      >
        <MenuOption style={{ alignItems: "center" }}>
          <ImageWrapper>
            <MenuOptionLogo icon={SFManagePrdouctIcon} />
          </ImageWrapper>
          <OptionWithSubText>
            <OptionName>Manage your products</OptionName>
            <OptionSubText>
              List and manage the products you sell on MerchBuy
            </OptionSubText>
          </OptionWithSubText>
          <ArrowForward />
        </MenuOption>
      </RippleLink>
    </Fragment>
  );
};

export default MerchBuyAccount;
