import React, { Fragment, useState } from "react";

import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";

import {
  TopHeader,
  RippleButton,
  PasswordGroup,
  SuccessPage,
  Loader,
} from "../../../../../../components";
import { Message } from "../../../../../../containers/MessageContainer";
import {
  walletToWalletTransfer,
  verifyPin,
  addCustomer,
} from "../../../../../../redux/ducks/account/wallet/actions";
import { colors } from "../../../../../../styles";
import { formatPrice } from "../../../../../../utils/currency/formatPriceWithComma";
import { getInputValues } from "../../../../../../utils/inputs/getInputValues";
import DesktopBackgroundLayout from "../../../../../DesktopBackgroundLayout";
import { ReactComponent as FromSvg } from "../assets/from.svg";
import { ReactComponent as LineSvg } from "../assets/line.svg";
import { ReactComponent as SpacesLogo } from "../assets/small_spaces_logo.svg";
import { ReactComponent as ToSvg } from "../assets/to.svg";

import { CodeCheckFormValidationSchema } from "./CodeCheckFormValidationSchema";
import RatingPopup from "../../../../transactions/wallet-transactions/rating-popup";
const ParentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #0350bb;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const LinearLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
  width: 100%;
  height: 100%;
`;

const TopCard = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  background: #ffffff;
  padding: 32px 16px 24px 16px;
  border-radius: 8px 8px 0 0;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.05);
  z-index: 1;
`;

const BottomCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  background: #ffffff;
  padding: 16px;
  border-radius: 0 0 8px 8px;
  border-top-style: dashed;
  border-width: 1px;
  border-color: #b6c1de;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.05);
  z-index: 1;
`;

const BottomParentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  padding-bottom: 40px;
  width: 100%;
  height: 100%;
`;

const VerticalDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${({ right }) => right || null};
`;

const HorizontalDiv = styled.div`
  display: flex;
`;

const MLineSvg = styled(LineSvg)`
  margin: 0 0 2px 3px;
`;

const FormSection = styled(Form)`
  display: flex;
  height: 100%;
  width: 100%;
  padding: 0px 20px;
  flex-direction: column;
  justify-content: space-between;
`;

const WalletToWalletPin = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const isLoading = useSelector((state) => state.account.wallet.isLoading);
  const walletBalance = useSelector(
    (state) => state.account.wallet.balance || 0
  );
  const transactions = useSelector(
    (state) => state.account.transactions.transactionsList
  );
  const { name, receiverPhoneNumber, narration, amount, saveBeneficiary } =
    location.state || {};
  const [pin, setPin] = useState("");
  const [value, setValue] = useState("");
  const [openRating, setOpenRating] = useState(false);
  const [showSuccessPage, setShowSucessPage] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState(false);
  const [wrongPin, setWrongPin] = useState(false);
  const [ref, setRef] = useState("");
  const [beneficiary] = useState({
    name: name,
    bank: {
      name: "",
      accountNumber: "",
    },
    phoneNumber: receiverPhoneNumber,
    email: "",
    homeAddress: {
      address: "",
      state: "",
      lga: "",
    },
    officeAddress: {
      address: "",
      state: "",
      lga: "",
    },
  });

  const onSubmitClick = async () => {
    const verifiedPin = await dispatch(verifyPin(pin));
    if (verifiedPin) {
      const payload = {
        amount,
        narration,
        receiverPhoneNumber,
        transactionPin: pin,
      };
      const response = await dispatch(walletToWalletTransfer(payload));
      setShowSucessPage(true);
      setWrongPin(false);
      setOpenRating(!openRating);
      response && setTransactionStatus(true);
      !response && setTransactionStatus(false);
      response && setRef(response?.transactionReference);
      saveBeneficiary && dispatch(addCustomer(beneficiary));
    } else {
      setShowSucessPage(true);
      setTransactionStatus(false);
      setWrongPin(true);
    }
  };

  if (isLoading) return <Loader text="Processing" />;
  return (
    <Fragment>
      <DesktopBackgroundLayout>
        <TopHeader
          title={"Recharge"}
          backgroundColor={colors.bgBlue}
          color={colors.white}
          opaqueBackButton
          noBorderBottom
        />
        <ParentWrapper>
          <LinearLayout>
            <TopCard>
              <HorizontalDiv>
                <VerticalDiv right="22px">
                  <FromSvg />
                  <MLineSvg />
                  <ToSvg />
                </VerticalDiv>
                <VerticalDiv>
                  <VerticalDiv>
                    <Message
                      top="-5px"
                      color="#718596"
                      size="10px"
                      weight="400"
                    >
                      FROM
                    </Message>
                    <Message top="0px" color="#1A2630" size="14px" weight="500">
                      My Wallet
                    </Message>
                    <Message
                      top="0px"
                      color="#071827"
                      size="12px"
                      weight="400"
                    >{`Bal: ${formatPrice(walletBalance || 0)}`}</Message>
                  </VerticalDiv>
                  <VerticalDiv>
                    <Message
                      top="22px"
                      bottom="6px"
                      color="#516C82"
                      size="10px"
                      weight="400"
                    >
                      TO
                    </Message>
                    <HorizontalDiv>
                      <SpacesLogo />
                      <VerticalDiv>
                        <Message
                          left="8px"
                          top="0px"
                          color="#1A2630"
                          size="12px"
                          weight="500"
                        >{`${name}`}</Message>
                        <Message
                          left="8px"
                          top="0px"
                          color="#313645"
                          size="11px"
                          weight="400"
                        >{`${receiverPhoneNumber}`}</Message>
                        <Message
                          left="8px"
                          top="0px"
                          color="#718596"
                          size="11px"
                          weight="400"
                        >{`${narration}`}</Message>
                      </VerticalDiv>
                    </HorizontalDiv>
                  </VerticalDiv>
                </VerticalDiv>
              </HorizontalDiv>
            </TopCard>
            <BottomParentWrapper>
              <BottomCard>
                <Message top="0px" color="#718596" size="12px" weight="400">
                  Amount
                </Message>
                <Message
                  top="0px"
                  color="#071827"
                  size="16px"
                  weight="700"
                >{`${formatPrice(amount || 0)}`}</Message>
              </BottomCard>

              <Message color="#071827" top={"24px"} bottom={"4px"}>
                Enter Transaction PIN to continue
              </Message>
              <Formik
                initialValues={{
                  transactionPin: "",
                }}
                validationSchema={CodeCheckFormValidationSchema}
                onSubmit={(values, { resetForm }) => {}}
              >
                {({ touched, valid, errors, setFieldValue }) => (
                  <FormSection>
                    <PasswordGroup
                      count={4}
                      startIndex={1}
                      type={"password"}
                      valid={valid}
                      errors={errors}
                      name={"transactionPin"}
                      touched={touched}
                      align={"center"}
                      enteredValue={value || ""}
                      handleChange={(event) => {
                        setValue(event.target.value);
                        setFieldValue(
                          "transactionPin",
                          getInputValues("transactionPin")
                        );
                        setPin(getInputValues("transactionPin"));
                      }}
                    />
                    {!isLoading && (
                      <RippleButton
                        type="submit"
                        bottom="0px"
                        top="8px"
                        disabled={!pin || pin.length < 4}
                        backgroundColor={colors.deepBlue}
                        onClick={onSubmitClick}
                      >
                        Transfer
                      </RippleButton>
                    )}
                  </FormSection>
                )}
              </Formik>
            </BottomParentWrapper>
          </LinearLayout>
        </ParentWrapper>

        {showSuccessPage && (
          <div>
            <SuccessPage
              title={`${
                transactionStatus
                  ? "Processing transaction"
                  : "Transaction Failed!"
              }`}
              subtitle={
                transactionStatus
                  ? "Your payment is being processed. You will be notified once it has been completed."
                  : wrongPin
                  ? "The pin you provided is not correct. Kindly check and try again"
                  : "We couldn't complete your transaction. Kindly try again"
              }
              showDetailButton={transactionStatus}
              successfull={transactionStatus}
              doneText={transactionStatus ? "Done" : "Try Again"}
              onDoneClick={() => {
                if (transactionStatus) {
                  history.push("/user/wallet_index");
                } else {
                  setShowSucessPage(false);
                }
              }}
              onDetailClick={async () => {
                history.push(`/user/wallet_transaction_details/${ref}`);
              }}
            ></SuccessPage>
            {(transactions.length === 1 || transactions.length % 10 === 0) && (
              <div style={{ visibility: openRating ? "visible" : "hidden" }}>
                <RatingPopup
                  refId={ref}
                  setOpen={() => setOpenRating(!openRating)}
                />
              </div>
            )}
          </div>
        )}
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default WalletToWalletPin;
