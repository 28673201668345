import React, { Fragment, useState, useEffect } from "react";

import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import { TopHeader /*RippleLink*/ } from "../../../../../../components";
import { Close } from "../../../../../../containers/HeaderContainer";
import { ScreenContainer } from "../../../../../../containers/ScreenContainer";
import DesktopBackgroundLayout from "../../../../../DesktopBackgroundLayout";
import { ReactComponent as ArrowIcon } from "../assets/arrow.svg";
import SingleProductIcon from "../assets/single_product.svg";

const Menu = styled.section`
  margin: 16px 0;
  & > ul:not(:last-of-type) {
    margin-bottom: 32px;
  }
`;

const OptionName = styled.h4`
  font-weight: 500;
  color: #212c3d;
  font-size: 14px;
`;

const OptionSubText = styled.h4`
  font-weight: 400;
  color: rgba(33, 44, 61, 0.6);
  font-size: 12px;
  margin-top: 8px;
`;

const MenuOptionLogo = styled.div`
  height: 50px;
  width: 72px;
  border-radius: 50%;
  background-image: url(${({ icon }) => icon});
  background-repeat: no-repeat;
  background-position: left;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const MenuContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 16px 0;
  padding: 16px;
  border-radius: 10px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  overflow: hidden;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`;

const ArrowForward = styled(ArrowIcon)`
  width: 24px;
  height: 24px;
  border-radius: 10px;
  margin: 5px 0 11px 0;
  padding: 5px 8px 5px;
  background-color: #f2f4f8;
`;

const CircularClose = styled(Close)`
  width: 32px;
  height: 32px;
  border-radius: 15px;
  padding: 8px;
  background-color: #f2f4f8;
  align-items: center;
  opacity: 0.6;
`;

const TitleText = styled.h4`
  font-weight: 500;
  color: #212c3d;
  font-size: 16px;
  margin: 16px 0 24px 0;
`;

const ProductOptions = () => {
  const history = useHistory();
  const location = useLocation();
  const [productName, setProductName] = useState(
    location?.state?.productName || ""
  );

  useEffect(() => {
    setProductName(location?.state?.productName || "");
  }, [location]);

  return (
    <Fragment>
      <DesktopBackgroundLayout>
        <TopHeader title={"New Product/Group"} noArrow newHeader>
          <CircularClose left="16px" onClick={() => history.goBack()} />
        </TopHeader>
        <ScreenContainer top={"52px"} paddingBottom={"65px"}>
          <TitleText>
            Add a new product to your inventory or create a product category
          </TitleText>
          <Menu>
            <MenuContent
              onClick={() =>
                history.push({
                  pathname: "/actions/shop_add_single_product",
                  state: {
                    productName,
                  },
                })
              }
            >
              <ImageWrapper>
                <MenuOptionLogo icon={SingleProductIcon} />
                <ArrowForward />
              </ImageWrapper>
              <OptionName>Add a single product</OptionName>
              <OptionSubText>
                Add products to your inventory to sell as an individual unit.
                E.g. A satchet of noodles, a bottle of water. e.t.c.
              </OptionSubText>
            </MenuContent>

            {/* <RippleLink 
                        to={"/actions/shop_add_single_product"}
                    >
                        <MenuContent>
                            <ImageWrapper>
                                <MenuOptionLogo icon={SingleProductIcon} />
                                <ArrowForward />
                            </ImageWrapper>
                            <OptionName>Add a product as a package</OptionName>
                            <OptionSubText>Add propducts to your inventory as one or more packages to sell. E.g. A carton of noodles, a pack of water e.t.c.</OptionSubText>
                        </MenuContent>
                    </RippleLink>

                    <RippleLink 
                        to={"/actions/shop_add_single_product"}
                    >
                        <MenuContent>
                            <ImageWrapper>
                                <MenuOptionLogo icon={SingleProductIcon} />
                                <ArrowForward />
                            </ImageWrapper>
                            <OptionName>Create a product group </OptionName>
                            <OptionSubText>Grouping products help manage your inventory better. E.g. Product type, manufacturer, brand, e.t.c.</OptionSubText>
                        </MenuContent>
                    </RippleLink>

                    <RippleLink 
                        to={"/actions/shop_add_single_product"}
                    >
                        <MenuContent>
                            <ImageWrapper>
                                <MenuOptionLogo icon={SingleProductIcon} />
                                <ArrowForward />
                            </ImageWrapper>
                            <OptionName>Bulk upload with excel file</OptionName>
                            <OptionSubText>Upload an excel sheet containing your inventory list via the web app on your computer.</OptionSubText>
                        </MenuContent>
                    </RippleLink>
                     */}
          </Menu>
        </ScreenContainer>
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default ProductOptions;
