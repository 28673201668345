import React, { Fragment } from "react";

import styled from "styled-components";

import { colors } from "../../../../../styles";
import { ReactComponent as Fullstar } from "../../assets/fullstar.svg";
import { ReactComponent as Nullstar } from "../../assets/nullstar.svg";
import { getRatingValue } from "../utils";

export const StarRatingCover = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || "space-between"};
  width: 100%;
`;

export const RateParagraph = styled.p`
  font-weight: ${({ weight }) => weight || "400"};
  font-size: ${({ size }) => size || "12px"};
  color: ${({ color }) => color || colors.themeTextColor11};
`;

export const StarDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StarRating = ({
  rating,
  setRating,
  showValue,
  setShowValue,
  clickAction,
}) => {
  return (
    <Fragment>
      <StarRatingCover>
        {[...Array(5)].map((star, index) => {
          const ratingValue = index + 1;

          return (
            <label key={index}>
              <input
                type="radio"
                name="rating"
                value={getRatingValue(ratingValue)}
                onClick={() => {
                  setRating(ratingValue);
                  clickAction && clickAction();
                }}
                style={{ display: "none" }}
              />
              {ratingValue <= rating ? (
                <StarDiv>
                  <Fullstar
                    onClick={() => setShowValue(getRatingValue(ratingValue))}
                  />
                  {showValue === getRatingValue(ratingValue) && (
                    <RateParagraph weight={"500"} color={colors.boldDark}>
                      {getRatingValue(ratingValue)}
                    </RateParagraph>
                  )}
                </StarDiv>
              ) : (
                <StarDiv>
                  <Nullstar
                    onClick={() => setShowValue(getRatingValue(ratingValue))}
                  />
                  {!showValue && (
                    <RateParagraph>{getRatingValue(ratingValue)}</RateParagraph>
                  )}
                </StarDiv>
              )}
            </label>
          );
        })}
      </StarRatingCover>
    </Fragment>
  );
};

export default StarRating;
