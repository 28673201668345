import React from "react";

import styled from "styled-components";
import { Button, LightButton } from "../../../../components";
import { FlexContainer, FlexDiv } from "../../../../containers/ScreenContainer";
import { colors } from "../../../../styles";

import { ReactComponent as CancelIcon } from "../assets/cancel_icon.svg";
import { ReactComponent as RightEducateIcon } from "../assets/right-educate.svg";
import { ReactComponent as LeftEducateIcon } from "../assets/left-educate.svg";

const EducateToturialHolder = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 16px;
  position: absolute;
  bottom: 12px;
  @media (min-width: 768px) {
    bottom: 170px;
  }
`;

const EducateWrapper = styled.div`
  width: 100%;
  background-color: rgba(255, 255, 255, 0.15);
  padding: 16px 16px 32px 16px;
  border-radius: 4px;
  transition: opacity 0.5s linear 0s;
  animation: openPopup 0.3s ease-out;
  @keyframes openPopup {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0%);
    }
  }
`;

const EducateTitle = styled.p`
  margin: 0;
  color: ${colors.white};
  font-size: 12px;
  font-weight: 700;
  margin-top: 8px;
  line-height: 18px;
`;

const EducateMessage = styled.p`
  margin: 0;
  color: ${colors.white};
  font-size: 10px;
  font-weight: 500;
  margin-top: 8px;
  line-height: 16px;
`;

const EducateCount = styled.p`
  margin: 4px 0 0 0;
  color: ${colors.white};
  font-size: 10px;
  font-weight: 500;
  padding-top: 5px;
  padding-bottom: 0px;
`;

const CancelIconSvg = styled(CancelIcon)`
  position: absolute;
  right: 32px;
`;

const EducateButton = styled(Button)`
  background: ${({ background }) =>
    background || "linear-gradient(106.74deg, #5271F2 0%, #3D60F3 97.91%)"};
  border-radius: 17px;
  margin: 0 0 0 16px;
  height: 24px;
  width: 47px;
  font-size: 10px;
`;

const StyledLeftEducate = styled(LeftEducateIcon)`
  position: absolute;
  left: 50px;
  bottom: -43px;
`;

const StyledRightEducate = styled(RightEducateIcon)`
  position: absolute;
  right: 150px;
  bottom: -30px;
`;

export const EducateContainer = styled.div`
  height: 100%;
  position: fixed;
  z-index: ${({ zIndex }) => zIndex || "200"};
  width: 100%;
  max-width: inherit;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const BlurBackgroundEducate = styled.div`
  height: 100%;
  position: ${({ position }) => position || "relative"};
  z-index: ${({ zIndex }) => zIndex || "200"};
  width: 100%;
  max-width: inherit;
  background-color: rgba(0, 0, 0, 0.84);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  flex-direction: column;
  opacity: 0;
  -webkit-animation: fadeIn 0.2s ease-in-out both;
  animation: fadeIn 0.2s ease-in-out both;
  animation-delay: 0s;
  transition: all 0.2s ease-in;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  &.fadeOut {
    -webkit-animation: fadeOut 0.3s ease-in-out both;
    animation: fadeOut 0.3s ease-in-out both;
    transition: all 0.3s ease-in-out;
    @keyframes fadeOut {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }
  }
`;

const ButtonFlex = styled.div`
  display: flex;
  width: 90%;
  position: absolute;
  bottom: 80px;
  justify-content: ${({ content }) => content || null};
`;

export const BuyNowEducation = ({
  handleEducateDone,
  educatePage,
  setEducatePage,
}) => {
  return (
    <EducateContainer zIndex={"2000"}>
      <BlurBackgroundEducate>
        <EducateToturialHolder>
          <EducateWrapper>
            <CancelIconSvg onClick={handleEducateDone} />
            <EducateTitle>
              {educatePage === 1 ? "Add to Cart" : "Buy Now"}
            </EducateTitle>
            <EducateMessage>
              {educatePage === 1
                ? " Add this item to your cart and buy it along with other items in your cart"
                : "Pay for only this item now. Other items will still remain in your cart"}
            </EducateMessage>
            <FlexContainer top={"5px"}>
              <EducateCount>{educatePage}/2</EducateCount>
              <FlexDiv>
                {educatePage === 2 && (
                  <EducateButton
                    type={"button"}
                    background="transparent"
                    color={colors.lightBlue}
                    onClick={() => setEducatePage(1)}
                  >
                    Back
                  </EducateButton>
                )}
                <EducateButton
                  type={"button"}
                  onClick={() =>
                    educatePage === 1 ? setEducatePage(2) : handleEducateDone()
                  }
                >
                  {educatePage === 1 ? "Next" : "Done"}
                </EducateButton>
              </FlexDiv>
            </FlexContainer>
          </EducateWrapper>
          {educatePage === 1 ? <StyledLeftEducate /> : <StyledRightEducate />}
        </EducateToturialHolder>
        <ButtonFlex content={educatePage === 1 ? "flex-start" : "flex-end"}>
          {educatePage === 1 && (
            <Button
              top={"10px"}
              width="50%"
              type={"button"}
              margin={"0 8px 0 0"}
              backgroundColor={colors.themeTextColor12}
              radius="4px"
            >
              Add To Cart
            </Button>
          )}
          {educatePage === 2 && (
            <LightButton
              background={colors.switch.noob}
              width="50%"
              top="10px"
              color={colors.themeTextColor12}
              radius="4px"
            >
              Buy Now
            </LightButton>
          )}
        </ButtonFlex>
      </BlurBackgroundEducate>
    </EducateContainer>
  );
};
