import React, { Fragment, useState, useEffect } from "react";

import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";

import { SuccessPage } from "../../..";
import { ReactComponent as ContactPickerIcon } from "../../../../assets/contact.svg";
import {
  TopHeader,
  PageLogo,
  ChooseTab,
  InputWithLabel,
  SelectBox,
  RippleButton,
  PaymentConfirmation,
  Loader,
} from "../../../../components";
import ValidatePasswordModal from "../../../../components/validate-password";
import { InputBlock } from "../../../../containers/InputContainer";
import { SubTitle } from "../../../../containers/MessageContainer";
import {
  ScreenContainer,
  FlexCenteredBlock,
} from "../../../../containers/ScreenContainer";
import * as actions from "../../../../redux/ducks/applications/bill-payments/actions/airtime-and-data";
import { formatPrice } from "../../../../utils/currency/formatPriceWithComma";
import {
  selectContact,
  contactPickerSupport,
} from "../../../../utils/inputs/contactPickerSupport";

import { AirtimeValidationSchema } from "./AirtimeValidationSchema";
import { DataValidationSchema } from "./DataValidationSchema";

const SelectPaymentType = styled.section`
  margin-top: 24px;
  width: 100%;
`;

const ContactPicker = styled.div`
  position: relative;
`;

const OpenContactList = styled(ContactPickerIcon)`
  position: absolute;
  right: 16px;
  top: 16px;
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

const AirtimePay = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const isLoading = useSelector(
    (state) => state.applications.billPayments.isLoading
  );
  const dataProviders = useSelector(
    (state) => state.applications.billPayments.dataProviders
  );
  const dataPlans = useSelector(
    (state) => state.applications.billPayments.dataPlans
  );

  const [selectedProvider] = useState(location && location.state);
  const [amount, setAmount] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [paymentType, setPaymentType] = useState("Airtime");
  const [dataProvider, setDataProvider] = useState();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [transactionData, setTransactionData] = useState({});

  const providers = (selectedProvider && selectedProvider) || {};

  if (!selectedProvider) history.push("/actions/airtime");

  useEffect(() => {
    if (paymentType === "Data" && dataProviders) {
      const provider = dataProviders.find(
        (x) => x.name.toLowerCase() === providers.name.toLowerCase()
      );
      setDataProvider(provider);
      dispatch(actions.getDataPlans(provider && provider.billerId));
    }
  }, [paymentType, dispatch, dataProviders, providers.name]);

  useEffect(() => {
    dispatch(actions.getDataProviders());
  }, [dispatch]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      {paymentSuccess ? (
        <SuccessPage
          header={"Successful transaction"}
          body={`Your ${
            selectedProvider.name
          } ${paymentType} Purchase of ${formatPrice(
            transactionData.amount
          )} was successful`}
        />
      ) : (
        <Fragment>
          {openPasswordModal ? (
            <ValidatePasswordModal
              title={"Airtime & Data bill"}
              isLoading={isLoading}
              setOpen={setOpenPasswordModal}
              open={openPasswordModal}
              next={async () => {
                const { billerId, paymentItemCode, requesterId } =
                  transactionData;
                let amount = transactionData.amount;

                const { hasSubChargeFee, subChargeFee } = selectedProvider;
                amount = hasSubChargeFee
                  ? parseInt(amount, 10) + parseInt(subChargeFee, 10)
                  : amount;
                if (paymentType === "Airtime") {
                  const airtimeResponse = await dispatch(
                    actions.purchaseAirtime(
                      amount,
                      billerId,
                      paymentItemCode,
                      requesterId
                    )
                  );
                  if (airtimeResponse === 200) setPaymentSuccess(true);
                } else {
                  const dataResponse = await dispatch(
                    actions.purchaseDataPlan(
                      amount,
                      billerId,
                      paymentItemCode,
                      requesterId
                    )
                  );
                  if (dataResponse === 200) setPaymentSuccess(true);
                }
              }}
            />
          ) : (
            <Fragment>
              {!openConfirmation && <TopHeader title={"Airtime & Data"} />}
              <ScreenContainer>
                <FlexCenteredBlock top={"56px"}>
                  <PageLogo
                    Icon={selectedProvider.logoUrl}
                    width={"48px"}
                    height={"48px"}
                    top={"8px"}
                  />
                  <SubTitle top={"8px"}>{selectedProvider.type}</SubTitle>
                  <SelectPaymentType>
                    <ChooseTab
                      variants={[
                        {
                          title: "Airtime",
                          callback: () =>
                            paymentType !== "Airtime" &&
                            setPaymentType("Airtime"),
                        },
                        {
                          title: "Data",
                          callback: () =>
                            paymentType !== "Data" && setPaymentType("Data"),
                        },
                      ]}
                    />
                    {paymentType === "Airtime" ? (
                      <Formik
                        initialValues={{
                          amount: 0,
                          phoneNumber: "",
                        }}
                        validationSchema={AirtimeValidationSchema}
                        onSubmit={() => {
                          setTransactionData({
                            amount: amount && amount,
                            billerId:
                              selectedProvider && selectedProvider.billerId,
                            paymentItemCode:
                              selectedProvider &&
                              selectedProvider.paymentItemCode,
                            requesterId: phoneNumber,
                          });
                          setTimeout(() => {
                            setOpenConfirmation(!openConfirmation);
                          }, 200);
                        }}
                      >
                        {({
                          errors,
                          touched,
                          setFieldValue,
                          values,
                          initialValues,
                        }) => (
                          <Form>
                            <InputBlock top={"24px"}>
                              <InputWithLabel
                                label={"Amount"}
                                type={"number"}
                                value={values.amount}
                                placeholder={"Amount"}
                                name="amount"
                                onKeyUp={(e) =>
                                  setAmount(Number(e.target.value))
                                }
                                valid={`${!touched.amount && !errors.amount}`}
                                errors={
                                  touched &&
                                  touched.amount &&
                                  errors &&
                                  errors.amount
                                }
                                setFieldValue={setFieldValue}
                                initialValues={initialValues}
                              />
                              <ContactPicker>
                                <InputWithLabel
                                  label={"Phone number"}
                                  type={"number"}
                                  value={values.phoneNumber}
                                  placeholder={"Phone number"}
                                  name="phoneNumber"
                                  inputMode={"tel"}
                                  onKeyUp={() =>
                                    setPhoneNumber(values.phoneNumber)
                                  }
                                  noClearButton={
                                    contactPickerSupport ? true : false
                                  }
                                  valid={`${
                                    touched.phoneNumber && !errors.phoneNumber
                                  }`}
                                  errors={
                                    touched &&
                                    touched.phoneNumber &&
                                    errors &&
                                    errors.phoneNumber
                                  }
                                  setFieldValue={setFieldValue}
                                  initialValues={initialValues}
                                />
                                {contactPickerSupport && (
                                  <OpenContactList
                                    onClick={() =>
                                      selectContact(
                                        setFieldValue,
                                        "phoneNumber"
                                      )
                                    }
                                  />
                                )}
                              </ContactPicker>
                              <RippleButton
                                type="submit"
                                disabled={
                                  Object.values(values).some(
                                    (value) => value.length === 0
                                  ) || isLoading
                                }
                              >
                                Continue
                              </RippleButton>
                            </InputBlock>
                            {openConfirmation && (
                              <PaymentConfirmation
                                open={openConfirmation}
                                close={setOpenConfirmation}
                                confirm={setOpenPasswordModal}
                                transactionDetails={{
                                  billCategoryId:
                                    selectedProvider.billCategoryId,
                                  customer: phoneNumber,
                                  amount,
                                  paymentItemCode: null,
                                  subChargeFee: selectedProvider.subChargeFee,
                                  providerLogo: selectedProvider.logoUrl,
                                  biller_name: selectedProvider.name,
                                  billerId: selectedProvider.billerId,
                                  subHeading: phoneNumber,
                                }}
                              />
                            )}
                          </Form>
                        )}
                      </Formik>
                    ) : (
                      <Formik
                        initialValues={{
                          dataPlan: "",
                          phoneNumber: "",
                        }}
                        validationSchema={DataValidationSchema}
                        onSubmit={(values) => {
                          const selectedPlan = values.dataPlan
                            .split("-")[0]
                            .trim();
                          const dataPlan = dataPlans.find(
                            (plan) => selectedPlan === plan.name
                          );
                          setTransactionData({
                            amount: dataPlan && dataPlan.amount,
                            billerId: dataProvider && dataProvider.billerId,
                            paymentItemCode:
                              dataPlan && dataPlan.paymentItemCode,
                            requesterId: values && values.phoneNumber,
                          });
                          setTimeout(() => {
                            setOpenConfirmation(!openConfirmation);
                          }, 200);
                        }}
                      >
                        {({
                          errors,
                          touched,
                          setFieldValue,
                          values,
                          initialValues,
                          handleChange,
                        }) => (
                          <Form>
                            <InputBlock top={"24px"}>
                              <SelectBox
                                name={"dataPlan"}
                                placeholder={
                                  dataPlans && dataPlans.length
                                    ? "Data plans"
                                    : "No data plans found"
                                }
                                value={values.dataPlan}
                                options={
                                  dataPlans && dataPlans.length
                                    ? dataPlans.map((plan) => ({
                                        value: String(plan.paymentItemCode),
                                        label: `${plan.name} - ${plan.validity}(R${plan.amount})`,
                                      }))
                                    : []
                                }
                                handleChange={handleChange}
                                valid={`${
                                  !touched.dataPlan && !errors.dataPlan
                                }`}
                                error={
                                  touched &&
                                  touched.dataPlan &&
                                  errors &&
                                  errors.dataPlan
                                }
                              />
                              <ContactPicker>
                                <InputWithLabel
                                  label={"Phone number"}
                                  type={"number"}
                                  value={values.phoneNumber}
                                  placeholder={"Phone number"}
                                  name="phoneNumber"
                                  noClearButton={
                                    contactPickerSupport ? true : false
                                  }
                                  valid={`${
                                    touched.phoneNumber && !errors.phoneNumber
                                  }`}
                                  errors={
                                    touched &&
                                    touched.phoneNumber &&
                                    errors &&
                                    errors.phoneNumber
                                  }
                                  setFieldValue={setFieldValue}
                                  initialValues={initialValues}
                                />
                                {contactPickerSupport && (
                                  <OpenContactList
                                    onClick={() => selectContact(setFieldValue)}
                                  />
                                )}
                              </ContactPicker>
                              <RippleButton
                                type="submit"
                                disabled={
                                  Object.values(values).some(
                                    (value) => value.length === 0
                                  ) || isLoading
                                }
                              >
                                Continue
                              </RippleButton>
                            </InputBlock>
                            {openConfirmation && (
                              <PaymentConfirmation
                                open={openConfirmation}
                                close={setOpenConfirmation}
                                confirm={setOpenPasswordModal}
                                transactionDetails={{
                                  billCategoryId:
                                    selectedProvider.billCategoryId,
                                  customer: phoneNumber,
                                  amount: transactionData.amount,
                                  subHeading: values.dataPlan,
                                  subChargeFee: selectedProvider.subChargeFee,
                                  paymentItemCode:
                                    transactionData.paymentItemCode,
                                  providerLogo: selectedProvider.logoUrl,
                                  biller_name: selectedProvider.name,
                                  billerId: selectedProvider.billerId,
                                }}
                              />
                            )}
                          </Form>
                        )}
                      </Formik>
                    )}
                  </SelectPaymentType>
                </FlexCenteredBlock>
              </ScreenContainer>
            </Fragment>
          )}
        </Fragment>
      )}
    </>
  );
};

export default AirtimePay;
