import SouthAfricaFlag from "../../assets/flags/south-africa.svg";

import { SouthAfricaInfo } from "./south-africa";

const countriesMap = new Map();

countriesMap.set("SA", SouthAfricaInfo);

const countriesData = Array.from(countriesMap.values());
const countriesKeys = Array.from(countriesMap.keys());
const phoneCodes = countriesData.map((country) => country.code);
const countriesNames = countriesData.map((country, index) => ({
  value: countriesKeys[index],
  label: country.name,
}));

const flags = [
  { label: "SouthAfrica", value: SouthAfricaFlag, customAbbreviation: "SA" },
];

export {
  countriesMap,
  countriesNames,
  countriesKeys,
  flags,
  countriesData,
  phoneCodes,
};
