import React, { Fragment } from "react";

import { arrayOf, string, shape, func } from "prop-types";
import styled from "styled-components";

import {
  MenuOptions,
  MenuOption,
  MenuOptionLogo,
  OptionName,
  ArrowForward,
} from "../../containers/MenuContainer";
import { colors } from "../../styles";
import { RippleLink } from "../button";
import { SwitchTrigger } from "../switch";

const MenuHeader = styled.div`
  border-bottom: 1px solid ${colors.border.bottom};
  margin: 0 15px;
`;

const MenuHeaderText = styled.h3`
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 16px;
  color: ${colors.themeSubColor1};
  line-height: 18px;
`;

const OptionWithSwitch = styled.div`
  position: relative;
`;

const OptionSubText = styled.h4`
  font-weight: normal;
  color: #6f798b;
  font-size: 11px;
  margin: auto 16px;
`;

const OptionWithSubText = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MenuList = ({ title, options, bottom, optionsBgColor }) => {
  return (
    <Fragment>
      <MenuHeader>
        <MenuHeaderText>{title}</MenuHeaderText>
      </MenuHeader>
      <MenuOptions bgColor={optionsBgColor} bottom={bottom}>
        {options.map(
          (
            { name, icon, link, action, subText, checkStatus, switchStatus },
            index
          ) => (
            <Fragment key={index}>
              {link ? (
                <RippleLink to={link} onClick={action || null}>
                  <MenuOption key={index}>
                    <MenuOptionLogo icon={icon} />
                    <OptionName>{name}</OptionName>
                    <ArrowForward />
                  </MenuOption>
                </RippleLink>
              ) : (
                <MenuOption key={index}>
                  <OptionWithSwitch>
                    <MenuOptionLogo icon={icon} />
                    <OptionWithSubText>
                      <OptionName>{name}</OptionName>
                      <OptionSubText>{subText}</OptionSubText>
                    </OptionWithSubText>
                    <SwitchTrigger
                      checkStatus={checkStatus}
                      switchStatus={switchStatus}
                      top={"20px"}
                    />
                  </OptionWithSwitch>
                </MenuOption>
              )}
            </Fragment>
          )
        )}
      </MenuOptions>
    </Fragment>
  );
};

MenuList.propTypes = {
  title: string,
  options: arrayOf(
    shape({
      name: string,
      icon: string,
      link: string,
      action: func,
    })
  ),
};
