import * as Yup from "yup";

import { containsLettersRegExp } from "../../../../../utils/regexp/containsLettersRegExp";

export const EditProductValidationSchema = Yup.object().shape({
  productName: Yup.string().nullable().required("Required"),
  productDescription: Yup.string().nullable().required("Required"),
  // base64ProductImageString: Yup.mixed()
  //     .when('productName', {
  //         is: (productName, base64ProductImageString) => (productName && base64ProductImageString === ""),
  //         then: Yup.string()
  //         .required('Product image is required!')
  //     }),
  productUnit: Yup.string().nullable().required("Required"),
  // masterListImageUrl:  Yup.string(),

  bulkPrices: Yup.array().when("productName", {
    is: (value) => value !== null,
    then: Yup.array()
      .of(
        Yup.object().shape({
          moq: Yup.string()
            .test("case 1", "Invalid MOQ", (value) => {
              if (value && value.trim().startsWith("0")) {
                return false;
              } else return true;
            })
            .required("Required"),
          price: Yup.string().required("Required"),
        })
      )
      .min(1),
    otherwise: Yup.array().of(
      Yup.object().shape({
        moq: Yup.string().matches(
          containsLettersRegExp,
          "Minimum order price cannot contain letters"
        ),
        price: Yup.string().matches(
          containsLettersRegExp,
          "Wholesale price cannot contain letters"
        ),
      })
    ),
  }),
});
