import React from "react";

import { any } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import aa from "search-insights";

import { RippleLink } from "../../../../../components";
import { merchbuyActions } from "../../../../../redux/ducks/applications/merchbuy/actions";
import { formatPrice } from "../../../../../utils/currency/formatPriceWithComma";
import FallbackProductImage from "../../assets/productImage.svg";
import {
  GridItem,
  ProductInfo,
  ProductInfoDesc,
  ProductInfoPrice,
  ImageWrapper,
  ProductImage,
} from "../../styles";

export const Product = ({ data, size, left, searchTerm, gridWidth }) => {
  const dispatch = useDispatch();
  const { id, images, name, minPrice, maxPrice } = data || {};
  const userId = useSelector((state) => state.user.userId);
  const productIndex = process.env.REACT_APP_ALGOLIA_SEARCH_INDEX;

  const handleProductClick = () => {
    const { __queryID } = data;
    const eventName = __queryID
      ? "Product Clicked after Search"
      : "Product Clicked";
    const eventType = __queryID
      ? "clickedObjectIDsAfterSearch"
      : "clickedObjectIDs";
    const eventProperties = {
      objectIDs: [id],
      userToken: userId,
      index: productIndex,
      eventName,
    };

    if (__queryID) {
      eventProperties.queryID = __queryID;
      eventProperties.positions = [data.__position];
    }

    aa(eventType, {
      ...eventProperties,
    });

    dispatch(merchbuyActions.setInitProductDetials(data));
    dispatch(merchbuyActions.updateProductSearch(searchTerm));
  };

  return (
    <RippleLink
      to={`/actions/merchbuy/product/${id}${
        data.__queryID ? "?queryId=" + data.__queryID : ""
      }`}
      onClick={handleProductClick}
    >
      <GridItem width={gridWidth} radius={"8px"}>
        <ImageWrapper justifyContent="center">
          <ProductImage
            src={
              images.baseImageUrl.length !== 0
                ? images.baseImageUrl
                : FallbackProductImage
            }
            alt="product image"
            width={size.width}
            height={size.height}
            left={left}
            onError={(e) => {
              e.target.src = FallbackProductImage;
            }}
          />
        </ImageWrapper>
        <ProductInfo padding="0">
          <ProductInfoDesc>{name && name.toLowerCase()}</ProductInfoDesc>
          <ProductInfoPrice>
            {formatPrice(minPrice || 0)} - {formatPrice(maxPrice || 0)}
          </ProductInfoPrice>
        </ProductInfo>
      </GridItem>
    </RippleLink>
  );
};

Product.propTypes = {
  data: any,
  size: any,
};
