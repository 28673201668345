import React, { useEffect, useState } from "react";

import styled from "styled-components";

import { ReactComponent as AirtimePurchase } from "../../../../assets/airtime.svg";
import { RippleLink } from "../../../../components";
import {
  ListHeading,
  ListSubHeading,
  ListItem,
} from "../../../../containers/ListContainer";
import { Amount } from "../../../../containers/MessageContainer";
import { formatPrice } from "../../../../utils/currency/formatPriceWithComma";
import { formatCreationDate } from "../../../../utils/date/formatCreationDate";
import { upperCaseToTitleCase } from "../../../../utils/toTitleCase";

import { ReactComponent as BankTransfer } from "./assets/bank_transfer.svg";
import { ReactComponent as CommissionPayment } from "./assets/commission_payment.svg";
import { ReactComponent as PaymentLinkClosed } from "./assets/payment_link_closed.svg";
import { ReactComponent as PaymentLinkPending } from "./assets/payment_link_pending.svg";
import { ReactComponent as WalletTopUpIcon } from "./assets/wallet_top_up.svg";
import { ReactComponent as WalletTransfer } from "./assets/wallet_transfer.svg";

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 16px;
  align-items: center;
`;

const Container = styled.div`
  width: ${({ width }) => width || "100%"};
  display: flex;
  margin: 8px 0px;
  flex-direction: column;
`;

const TransactionText = styled(ListHeading)`
  color: #071827;
  font-weight: 400;
  line-height: 21px;
  margin: 0px;
`;

const SubText = styled(ListSubHeading)`
  color: #718596;
  margin-top: 2px;
  line-height: 16px;
  text-transform: capitalize;
`;

export const transactionTypeEnum = [
  {
    type: "CREDIT_BANK",
    value: "Bank Transfer",
    icon: BankTransfer,
  },
  {
    type: "TOP_UP_WALLET",
    value: "Wallet Top up",
    icon: WalletTopUpIcon,
  },
  {
    type: "WALLET_TO_WALLET",
    value: "Wallet Transfer",
    icon: WalletTransfer,
  },
  {
    type: "COMMISSION",
    // this got changed to COMMISSION
    value: "Commission Payment",
    icon: CommissionPayment,
  },
  {
    type: "PAY_WITH_WALLET",
    value: "Wallet Payment",
    icon: WalletTransfer,
  },
  {
    type: "SETTLEMENT",
    value: "Wallet Settlement",
    // we are defaulting all other transaction types icons to WalletTransfer now
    // we will change them when the design specifies exact icons for them
    icon: WalletTransfer,
  },
  {
    type: "AIRTIME_PURCHASE",
    value: "Airtime Purchase",
    icon: AirtimePurchase,
  },
  {
    type: "DATA_PURCHASE",
    value: "Data Purchase",
    icon: WalletTransfer,
  },
  {
    type: "UTILITIES",
    value: "Utilities",
    icon: WalletTransfer,
  },
  {
    type: "PAYMENT_LINK_OPEN",
    value: "Payment Link",
    icon: PaymentLinkPending,
  },
  {
    type: "PAYMENT_LINK_CLOSED",
    value: "Payment Link",
    icon: PaymentLinkClosed,
  },
];

const TransactionCard = ({
  pathname,
  transaction,
  noBorderBottom,
  background,
  containerWidth,
  padding,
}) => {
  const [transactionType, setTransactionType] = useState({});

  useEffect(() => {
    setTransactionType(
      transactionTypeEnum.find((x) => x.type === transaction.transactionType)
    );
  }, [transaction.transactionType]);

  return (
    <RippleLink
      // this negative margin is necessary so the items can touch the edge of the screen
      // then we will add a padding to the content wrapper so the hover effect looks nice
      // FYI - it is 1em because the component "Main" has a padding of 1em applied on it
      margin="0 -1em"
      to={{
        pathname: `${pathname || "/user/wallet_transaction_details"}`,
        state: transaction,
      }}
    >
      <ListItem
        padding={padding || "8px 1em"}
        bottom="8px"
        align="center"
        background={background}
        noBorderBottom={noBorderBottom}
      >
        {transactionType?.icon && <transactionType.icon />}
        <Container width={containerWidth}>
          <FlexContainer>
            {transaction?.firstName ? (
              <TransactionText>{`${transaction.firstName} ${transaction.lastName}`}</TransactionText>
            ) : (
              <TransactionText>{transactionType?.value}</TransactionText>
            )}
            <Amount
              txnClass={transaction?.transactionClass}
              type={transaction?.transactionStatus || transaction?.status}
            >
              {transaction?.transactionStatus === "SUCCESSFUL" &&
                transaction?.transactionClass === "CREDIT" &&
                " + "}
              {transaction?.transactionStatus === "SUCCESSFUL" &&
                transaction?.transactionClass === "DEBIT" &&
                " - "}
              {transaction?.amount
                ? formatPrice(transaction?.amount)
                : formatPrice(transaction?.transactionAmount)}
            </Amount>
          </FlexContainer>
          {transaction.createdDate && (
            <FlexContainer>
              <SubText>
                {transaction?.createdDate &&
                  formatCreationDate(transaction?.createdDate)}
              </SubText>
              <SubText>{transaction?.transactionStatus?.toLowerCase()}</SubText>
            </FlexContainer>
          )}
          {transaction.createdAt && (
            <FlexContainer>
              <SubText>
                {transaction?.createdAt &&
                  formatCreationDate(transaction?.createdAt)}
              </SubText>
              {transaction?.status && (
                <SubText>{upperCaseToTitleCase(transaction?.status)}</SubText>
              )}
            </FlexContainer>
          )}
          {transaction.requestedOn && (
            <FlexContainer>
              <SubText>
                {transaction?.requestedOn &&
                  formatCreationDate(transaction?.requestedOn)}
              </SubText>
            </FlexContainer>
          )}
        </Container>
      </ListItem>
    </RippleLink>
  );
};

export default TransactionCard;
