import React from "react";

import styled from "styled-components";

import { colors } from "../../../../styles";
import { formatPrice } from "../../../../utils/currency/formatPriceWithComma";
import {
  ModifiedAddIcon,
  ModifiedSubstractIcon,
} from "../../../../views/actions/merchbuy/group-buy/styles";

export const UpdateCalculatorBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  & > svg {
    cursor: pointer;
  }
`;

const CurrentQuantity = styled.input`
  font-size: 14px;
  font-weight: 500;
  // color:transparent;
  color: ${({ color }) => color || colors.themeTextColor1};
  margin-top: ${({ top }) => top || null};
  margin-left: ${({ left }) => left || null};
  width: 100%;
  background-color: inherit;
  border: none;
  text-align: center;
  &::-webkit-inner-spin-button,
  -webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
`;

const QuantityDisplay = styled.div`
  border-radius: 8px;
  max-width: 40px;
  height: 35px;
  padding: 3px;
  background-color: ${colors.themeColor3};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  justify-content: space-between;
`;

export const SlotName = styled.p`
  margin: 2px 0;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #212c3d;
`;

export const Price = styled.p`
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${colors.black};
  margin: 2px 0;
`;

export const GroupBuyQty = ({
  name,
  slot,
  splitCost,
  inputVal,
  handleChange,
  handleAddition,
  handleSubstraction,
  disabled = false,
}) => {
  const handleInput = (e) => {
    const {
      target: { value, name },
    } = e;
    handleChange({ name, val: value });
  };

  const handleAddInput = () => {
    handleAddition({ name });
  };

  const handleSubInput = () => {
    handleSubstraction({ name });
  };

  return (
    <Wrapper>
      <div>
        <SlotName>{slot}</SlotName>
        <Price>{formatPrice(splitCost || 0)}</Price>
      </div>
      <UpdateCalculatorBlock>
        <ModifiedSubstractIcon onClick={handleSubInput} />
        <QuantityDisplay>
          <CurrentQuantity
            value={inputVal}
            type={"number"}
            name={name}
            disabled={disabled}
            onChange={handleInput}
          />
        </QuantityDisplay>
        <ModifiedAddIcon disabled={disabled} onClick={handleAddInput} />
      </UpdateCalculatorBlock>
    </Wrapper>
  );
};
