import React, { useState } from "react";

import { string, array } from "prop-types";
import styled from "styled-components";

import { ReactComponent as AddIcon } from "../../assets/add.svg";
import { ReactComponent as SubstractIcon } from "../../assets/substract.svg";

const QuantityControls = styled.div`
  display: flex;
  flex-direction: row;
`;

const Input = styled.input`
  font-size: 12px;
  font-weight: 500;
  border-radius: 8px;
  text-align: center;
  color: #071827;
  line-height: 18px;
  width: 27px;
  height: 27px;
  padding: 0;
  background-color: #f2f5fa;
  border: none;
  outline: none;
  &:focus {
    background-color: #fff;
  }
`;

const changeQuantity = (
  selectedProducts,
  setProducts,
  inventoryProductId,
  setOpacity,
  action
) => {
  const newProducts = selectedProducts.map((product) => {
    if (product.inventoryProductId === inventoryProductId) {
      if (action === "add") {
        product.quantity = parseInt(product.quantity) + 1;
        setOpacity("1");
      } else {
        product.quantity = Math.max(product.quantity - 1, 1);
        product.quantity < 2 && setOpacity("0.4");
      }
    }

    return product;
  });

  setProducts(newProducts);
};

const enterQuantity = (
  selectedProducts,
  setProducts,
  inventoryProductId,
  setOpacity,
  value
) => {
  const newProducts = selectedProducts.map((product) => {
    if (product.inventoryProductId === inventoryProductId) {
      product.quantity = Math.max(0, value);
      product.quantity < 2 && setOpacity("0.4");
    }

    return product;
  });

  setProducts(newProducts);
};

export const SetSaleQuantity = ({
  selectedProducts,
  setProducts,
  inventoryProductId,
}) => {
  const [opacity, setOpacity] = useState(1);
  return (
    <QuantityControls>
      <SubstractIcon
        onClick={() =>
          changeQuantity(
            selectedProducts,
            setProducts,
            inventoryProductId,
            setOpacity,
            "substract"
          )
        }
        style={{ marginRight: "4px", opacity: opacity }}
      />
      <Input
        type="text"
        inputMode={"numeric"}
        value={
          selectedProducts.filter(
            (product) => product.inventoryProductId === inventoryProductId
          )[0].quantity
        }
        onChange={(e) =>
          enterQuantity(
            selectedProducts,
            setProducts,
            inventoryProductId,
            setOpacity,
            e.target.value.replace(/\D/g, "")
          )
        }
      />

      <AddIcon
        onClick={() =>
          changeQuantity(
            selectedProducts,
            setProducts,
            inventoryProductId,
            setOpacity,
            "add"
          )
        }
        style={{ marginLeft: "4px" }}
      />
    </QuantityControls>
  );
};

SetSaleQuantity.propTypes = {
  selectedProducts: array,
  inventoryProductId: string,
};
