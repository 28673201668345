import React, { Fragment, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { RippleButton, TopHeader } from "../../../../components";
import { SlidingOverlay } from "../../../../containers/OverlayContainer";
import { postSurvey } from "../../../../redux/ducks/user/actions/nps";
import { colors } from "../../../../styles";
import DesktopBackgroundLayout from "../../../DesktopBackgroundLayout";
import StarRating from "../../help/rate-spaces/components/StarRating";
import { npsStarRates } from "../../help/rate-spaces/utils";

import { ReactComponent as CloseIcon } from "./assets/close.svg";

export const RatingTextArea = styled.textarea`
  width: 100%;
  padding: 16px;
  border: 1px solid #cbd6e0;
  border-radius: 4px;
  height: ${({ height }) => height || "119px"};
  font-size: 14px;
  font-weight: 500;
  &:focus {
    outline: none;
  }
`;

const HeaderCloseDiv = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  position: absolute;
  left: 16px;
  top: 16px;
  background: ${colors.gray4};
`;

const RatingsContainer = styled.div`
  padding: 0 16px 32px 16px;
  margin-top: 85px;
`;

const RateBox = styled.div`
  padding: 16px;
  border-radius: 8px;
  margin-bottom: ${({ bottom }) => bottom || null};
`;

const FlexRating = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || "center"};
`;

const RateText = styled.div`
  font-weight: 700;
  font-size: 14px;
  color: ${colors.boldDark};
  margin-bottom: ${({ bottom }) => bottom || "16px"};
  text-align: center;
`;

const TransactionRating = ({
  setOpen,
  rating,
  setRating,
  showValue,
  setShowValue,
  refId,
  cancel,
}) => {
  const dispatch = useDispatch();
  const surveyTypes = useSelector((state) => state.user.surveyTypes);
  const surveyType = surveyTypes.find(
    (type) => type.title === "TRANSACTION SURVEY"
  );
  const [content, setContent] = useState("");
  const [altloading, setAltloading] = useState(false);

  const [score, setScore] = useState(0);
  const submitCriteria = score <= 30 && !content;

  useEffect(() => {
    setScore(npsStarRates.find((x) => x.value === showValue)?.score);
  }, [showValue]);

  const handleSubmit = () => {
    const params = {
      externalRef: refId,
      responded: true,
      surveyId: surveyType && surveyType.id,
      value1: score,
      value2: content,
    };
    setAltloading(true);
    dispatch(postSurvey(params)).then((res) => {
      if (res) {
        setAltloading(false);
        setOpen();
        cancel();
      } else {
        setAltloading(false);
      }
    });
  };

  return (
    <Fragment>
      <SlidingOverlay zIndex={"2000"}>
        <DesktopBackgroundLayout>
          <TopHeader noArrow title={"Rate Your Experience"}>
            <HeaderCloseDiv onClick={() => setOpen()}>
              <CloseIcon />
            </HeaderCloseDiv>
          </TopHeader>
          <RatingsContainer>
            <RateBox bottom={"20px"}>
              <RateText bottom={"16px"}>
                How satisfied were you with completing your transaction?
              </RateText>
              <FlexRating>
                <StarRating
                  rating={rating}
                  setRating={setRating}
                  showValue={showValue}
                  setShowValue={setShowValue}
                />
              </FlexRating>
            </RateBox>
            <hr style={{ background: colors.headerBorder }} />
            {(showValue === "Poor" || showValue === "Bad") && (
              <RateBox bottom={"20px"}>
                <RatingTextArea
                  value={content}
                  onChange={(event) => setContent(event.target.value)}
                  type={"text"}
                  placeholder={"Tell us how we can improve"}
                  name={"description"}
                />
              </RateBox>
            )}
            <RippleButton
              height={"56px"}
              top={"40%"}
              onClick={() => handleSubmit()}
              disabled={!showValue || altloading || submitCriteria}
            >
              {altloading ? "Submitting..." : "Submit"}
            </RippleButton>
          </RatingsContainer>
        </DesktopBackgroundLayout>
      </SlidingOverlay>
    </Fragment>
  );
};

export default TransactionRating;
