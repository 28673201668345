import React, { Fragment, useState, useEffect } from "react";

import { useSelector } from "react-redux";
import styled from "styled-components";

import { ReactComponent as PendingUserSVG } from "../../../assets/pending-user.svg";
import { MenuList, RippleLink } from "../../../components";
import {
  CategoryRow,
  CategoryTitle,
} from "../../../containers/MessageContainer";
import { ScreenContainer } from "../../../containers/ScreenContainer";
import { colors } from "../../../styles";
import { MODULE_INTERACTION } from "../../../utils/mix-panel/constants";
import { mixPanel } from "../../../utils/mix-panel/mixPanel";
import DesktopBackgroundLayout from "../../DesktopBackgroundLayout";
import AccountIcon from "../dashboard/assets/account.svg";
import ShopSettingsIcon from "../dashboard/assets/shop_settings.svg";
import ActionsList from "../dashboard/components/actions-grid";
import Navigation from "../navigation";
import { FeatureUndergoingFixes } from "../../../components/popup/feature-undergoing-fixes";

const PageTitle = styled.p`
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: ${colors.themeSubColor1};
  margin: 0 0 0 16px;
  padding-top: 16px;
`;

const DashboardWrapper = styled(ScreenContainer)`
  animation: fromRightTransform 0.5s ease;
  @keyframes fromRightTransform {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0%);
    }
  }
  margin-top: 24px;
`;

const DashboardCategory = styled.section``;

const DashboardBlock = styled.div`
  margin-bottom: 72px;
  transition: margin 0.2s linear;
  &.firstStep {
    margin-top: 10px;
    transition: margin 0.2s linear;
  }
  &.secondStep {
    margin-top: 20px;
    transition: margin 0.2s linear;
  }
  &.thirdStep {
    margin-top: 25px;
    transition: margin 0.2s linear;
  }
  &.fourthStep {
    margin-top: 30px;
    transition: margin 0.2s linear;
  }
`;

const AgentActivativationMessageBox = styled.div`
  display: flex;
  width: 100%;
  height: 64px;
  background-color: ${({ background }) => background};
  border-radius: 10px;
  align-items: center;
  padding-left: 12px;
  gap: 16px;
`;

const AgentActivativationMessageBoxRightSide = styled.div``;

const Paragraph = styled.div`
  padding: 7px 0;
  padding-right: 16px;
  font-size: 12px;
  color: #071827;
`;

const ImageWrapper = styled.div``;

const ActivationStatusLink = styled.div`
  color: #cd4213;
  font-size: 12px;
`;

const More = () => {
  const realms = useSelector((state) => state.user.realmNames);
  const role = useSelector((state) => state.user.role);
  const shops = useSelector((state) => state.applications.myShop.shops);
  const shop = shops[0];
  const agentState = useSelector((state) =>
    state.user.agentState ? state.user.agentState.toUpperCase() : "PENDING"
  );
  const [classNames, setClassNames] = useState("");
  const [setStatusPopup] = useState(false);
  const [openFeatureFixes, setOpenFeatureFixes] = useState(false);

  useEffect(() => {
    mixPanel.track(MODULE_INTERACTION, {
      "Icon Name": "More",
      Time: new Date().toLocaleString(),
    });
    window.addEventListener("scroll", handleScroll);
    return (_) => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    if (scrollY < 50) setClassNames("");
    if (scrollY > 50) setClassNames("firstStep");
    if (scrollY > 100) setClassNames("secondStep");
    if (scrollY > 120) setClassNames("thirdStep");
    if (scrollY > 140) setClassNames("fourthStep");
  };

  return (
    <Fragment>
      <DesktopBackgroundLayout>
        {openFeatureFixes && (
          <FeatureUndergoingFixes cancel={() => setOpenFeatureFixes(false)} />
        )}
        <ScreenContainer
          padding="0"
          top={"0"}
          color={"#EDF2F7"}
          paddingBottom="0"
        >
          <PageTitle>SETTINGS</PageTitle>
          {role === "ROLE_AGENT" && agentState !== "APPROVED" && (
            <ScreenContainer paddingBottom="0">
              <RippleLink to={"/user/account_kyc"}>
                <AgentActivativationMessageBox
                  background={
                    agentState === "PENDING"
                      ? colors.lightYellow
                      : colors.lightRed
                  }
                >
                  <ImageWrapper>
                    <PendingUserSVG />
                  </ImageWrapper>
                  <AgentActivativationMessageBoxRightSide>
                    <Paragraph>
                      {agentState === "PENDING"
                        ? "Your Space Force Agent activation is pending."
                        : "Your Space Force Agent profile was not approved."}
                    </Paragraph>
                    <ActivationStatusLink>
                      See activation details
                    </ActivationStatusLink>
                  </AgentActivativationMessageBoxRightSide>
                </AgentActivativationMessageBox>
              </RippleLink>
            </ScreenContainer>
          )}
          <MenuList
            optionsBgColor="#EDF2F7"
            bottom="8px"
            options={[
              {
                name: "Account Settings",
                icon: AccountIcon,
                link: "/user/account_settings",
              },
            ]}
          />
          {shop && (
            <MenuList
              options={[
                {
                  name: "Shop Settings",
                  icon: ShopSettingsIcon,
                  link: "/actions/shop_edit",
                },
              ]}
            />
          )}

          {(role !== "ROLE_AGENT" ||
            (role === "ROLE_AGENT" && agentState === "APPROVED")) && (
            <Fragment>
              <DashboardWrapper color={"#FBFCFD"}>
                <DashboardBlock className={classNames}>
                  <DashboardCategory>
                    <CategoryRow top="0">
                      <CategoryTitle>HELP</CategoryTitle>
                    </CategoryRow>
                    <ActionsList
                      type={"HELP"}
                      setStatusPopup={setStatusPopup}
                      setOpenFeatureFixes={setOpenFeatureFixes}
                    />
                  </DashboardCategory>

                  {(!realms || !realms.includes("TRIVIA")) && (
                    <DashboardCategory>
                      <CategoryRow>
                        <CategoryTitle>Make Money</CategoryTitle>
                      </CategoryRow>
                      <ActionsList
                        type={"LEARNING"}
                        setStatusPopup={setStatusPopup}
                        setOpenFeatureFixes={setOpenFeatureFixes}
                      />
                    </DashboardCategory>
                  )}

                  {(role !== "ROLE_AGENT" ||
                    (role === "ROLE_AGENT" && agentState === "APPROVED")) && (
                    <DashboardCategory>
                      <CategoryRow>
                        <CategoryTitle>CONTACTS</CategoryTitle>
                      </CategoryRow>
                      <ActionsList
                        type={"CONTACTS"}
                        setStatusPopup={setStatusPopup}
                        setOpenFeatureFixes={setOpenFeatureFixes}
                      />
                    </DashboardCategory>
                  )}
                </DashboardBlock>
              </DashboardWrapper>
            </Fragment>
          )}
        </ScreenContainer>
        <Navigation />
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default More;
