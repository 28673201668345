import React, { Fragment } from "react";

import { bool, string, func } from "prop-types";
import styled from "styled-components";

import { ReactComponent as ReminderCancelIcon } from "../../../assets/reminderCancel.svg";
import { Overlay } from "../../../containers/OverlayContainer";
import {
  ScreenContainer,
  ViewContainer,
} from "../../../containers/ScreenContainer";
import { colors } from "../../../styles";
import { PopUp, PopUpContent } from "../common";

const Title = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.boldDark};
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Comment = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: ${colors.lightDark};
  margin-top: 4px;
  text-align: left;
`;

const Padding = styled.div`
  padding: 16px;
`;

export const DebtReminderModal = ({
  open,
  cancel = () => null,
  children,
  customerName,
  height,
  maxHeight,
  isEdit,
  noHeader,
  reminderStatus,
}) => {
  return (
    <Fragment>
      <Overlay bgc="rgba(0, 0, 0, 0.4);">
        <PopUp padding="0" open={open} zIndex={"10000"}>
          <PopUpContent
            color="#F9FAFC"
            radius="20px 20px 0px 0px"
            height={height}
          >
            <ScreenContainer color="#F9FAFC" padding="0" top={"16px"}>
              {!noHeader && (
                <Padding>
                  <TitleWrapper>
                    <Title>
                      {isEdit && reminderStatus.active
                        ? "Edit Reminder"
                        : "Set Reminder"}
                    </Title>
                    <ReminderCancelIcon onClick={cancel} />
                  </TitleWrapper>
                  <Comment>
                    Kindly set an automatic reminder for{" "}
                    {customerName?.split(" ")[0]}
                  </Comment>
                </Padding>
              )}
              <ViewContainer
                overflowY="scroll"
                maxHeight={maxHeight}
                top={"0px"}
              >
                {children}
              </ViewContainer>
            </ScreenContainer>
          </PopUpContent>
        </PopUp>
      </Overlay>
    </Fragment>
  );
};
DebtReminderModal.propTypes = {
  open: bool,
  title: string,
  cancel: func,
};
