import styled from "styled-components";

import { RippleLink } from "../../../components";
import { colors } from "../../../styles";

import { ReactComponent as BuySvg } from "./assets/buy.svg";
import { ReactComponent as PopularProducts } from "./assets/popularProducts.svg";
import { ReactComponent as ProdIcon } from "./assets/prodicon.svg";
import { ReactComponent as ProductCategories } from "./assets/productCategories.svg";
import { ReactComponent as RecommendedProducts } from "./assets/recommendedProducts.svg";
import { ReactComponent as SearchIcon } from "./assets/search.svg";
import { ReactComponent as Cart } from "./assets/shoppingCart.svg";
import Shops from "./assets/ShopsIcon.svg";
import { ReactComponent as Store } from "./assets/storeIcon.svg";
import { ReactComponent as Trash } from "./assets/trash.svg";

export const SeeBreakdownText = styled.p`
  font-size: 12px;
  color: #22a8ff;
  font-weight: 600;
`;

export const CategoryWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ bottom }) => bottom || "19px"};
`;

export const CategoryFlex = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: ${({ borderBottom }) => borderBottom || null};
`;

export const SearchInputContainer = styled.div`
  margin-bottom: 30px;
`;

export const LinkText = styled.span`
  display: inline-block;
  justify-content: flex-end;
  color: ${colors.deepBlue};
  font-weight: bold;
  font-size: 12px;
  text-align: right;
  margin-right: 17px;
`;

export const FixedBottom = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  position: fixed;
  bottom: 0;
  background: ${({ background }) => background || "white"};
  box-shadow: 0 1px 10px 0 #dddddd, 0 4px 5px 0 #23000000,
    0 2px 4px -1px #33000000;
  width: 100%;
  height: 80px;
  justify-content: center;
  max-width: inherit;
  z-index: ${({ zIndex }) => zIndex || "100"};
`;

export const PositionRelative = styled.span`
  position: absolute;
`;

export const BuyIcon = styled(BuySvg)`
  width: 32px;
  height: 35px;
`;

export const CartIcon = styled(Cart)`
  position: absolute;
  right: 8px;
  height: 18px;
  width: 18px;
  top: 7px;
  cursor: pointer;
`;

export const TrashIcon = styled(Trash)`
  position: absolute;
  right: -3%;
  height: 24px;
  width: 24px;
  top: 20px;
  cursor: pointer;
`;

export const Icon = styled(ProdIcon)`
  position: absolute;
  top: 4px;
  left: 4px;
`;

export const QuantityCount = styled.span`
  font-size: 12px;
  font-weight: ${({ fontWeight }) => fontWeight || "500"};
  margin-top: 0;
  padding: ${({ padding }) => padding || "8px 0"};
  width: 35px;
  margin-left: ${({ left }) => left || null};
  text-align: center;
  background-color: ${colors.themeColor3};
  height: ${({ qtyHeight }) => qtyHeight || null};
  border-radius: ${({ radius }) => radius || "8px"};
`;

export const ProductCategoriesIcon = styled(ProductCategories)`
  height: 24px;
  width: 24px;
`;

export const ShopsIcon = styled.img.attrs({ src: Shops })`
  height: 48px;
  width: 48px;
`;

export const StoreIcon = styled(Store)`
  height: 52px;
  width: 52px;
`;

export const SecondStoreIcon = styled.img.attrs({ src: Shops })`
  height: 24px;
  width: 24px;
`;

export const RecommendedProductsIcon = styled(RecommendedProducts)`
  height: 24px;
  width: 24px;
`;
export const PopularProductsIcon = styled(PopularProducts)`
  height: 24px;
  width: 24px;
`;

export const NoOfItemsOnCart = styled.span`
  position: absolute;
  right: 8px;
  top: 7px;
  color: ${colors.white};
  background-color: ${colors.blue2};
  width: 18px;
  height: 18px;
  padding: 4px;
  font-size: 10px;
  border-radius: 50%;
  cursor: pointer;
`;

export const Main = styled.main`
  padding-top: 60px;
  padding-bottom: ${({ paddingBottom }) => paddingBottom || "65px"};
`;

export const Container = styled.div`
  width: ${({ width }) => width || "93%"};
  margin: ${({ margin }) => margin || "0 auto"};
  margin-top: ${({ top }) => top || "0"};
  height: ${({ height }) => height || null};
  padding: ${({ padding }) => padding || null};
  margin-bottom: ${({ bottom }) => bottom || "10px"};
  background: ${({ background }) => background || null};
`;

export const DetailsContainer = styled.div`
  width: ${({ width }) => width || "100%"};
  margin: 0 auto;
  margin-top: ${({ top }) => top || "10px"};
  height: ${({ height }) => height || null};
  padding: ${({ padding }) => padding || null};
`;

export const HorizontalScrollBox = styled.div`
  display: flex;
  overflow-x: scroll;
  width: 100%;
`;

export const AddGridBox = styled.div`
  display: grid;
  grid-template-columns: ${({ width }) => `repeat(auto-fill, ${width})`};
  justify-content: space-evenly;
`;

export const AddGridContainer = styled.div`
  display: grid;
  gap: ${({ gap }) => gap || null};
  grid-template-columns: ${({ width }) => `repeat(auto-fill, ${width})`};
  grid-auto-flow: column;
  grid-auto-columns: ${({ width }) => width || null};
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const GridItem = styled.div`
  margin: 5px 0;
  background-color: ${colors.white};
  padding: ${({ padding }) => padding || "8px"};
  padding-bottom: 16px;
  border: solid 2px ${colors.white};
  border-radius: ${({ radius }) => radius || null};
`;

export const SkeletalBox = styled.div`
  width: ${({ width }) => width || null};
  margin-top: ${({ top }) => top || "0"};
  height: ${({ height }) => height || null};
  padding: ${({ padding }) => padding || null};
  background-color: ${({ color }) => color || colors.gray4};
  border-radius: ${({ radius }) => radius || "32px"};
`;

export const SkeletalContainer = styled.div`
  margin: 0 auto;
  margin-top: ${({ top }) => top || "0"};
  display: flex;
  justify-content: space-between;
`;

export const AddFlex = styled.div`
  display: flex;
  width: 100%;
  justify-content: ${({ justifyContent }) => justifyContent || ""};
`;

export const AddFlexBox = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: ${({ justifyContent }) => justifyContent || ""};
  width: 100%;
  flex-wrap: wrap;
  justify-content: ${({ justifyContent }) => justifyContent || ""};
  width: 100%;
`;

export const ProductListFlex = styled.div`
  display: flex;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ItemFlex = styled.div`
  margin: 5px;
  margin-left: ${({ left }) => left || "5px"};
  flex: 1;
`;

export const PageSubSections = styled.div`
  padding: 14px;
`;

export const PageSubSectionsTop = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 700;
  height: ${({ height }) => height || null};
  padding: ${({ padding }) => padding || "10px"};
`;

export const Sections = styled(PageSubSectionsTop)`
  font-weight: 500;
  padding: 0px;
  margin-bottom: ${({ bottom }) => bottom || "0"};
`;

export const Circle = styled.span`
  position: absolute;
  right: 7px;
  top: 15px;
  background-color: ${colors.gray4};
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;
`;

export const PageSubSectionsTopLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${({ height }) => height || null};
`;

export const HorizontalSubSectionsTopLeft = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PageSubSectionsTopLeftTitle = styled.h4`
  font-weight: 700;
  font-size: 14px;
  margin-left: ${({ left }) => left || "8px"};
  color: ${colors.lightDark};
  font-size: ${({ font }) => font || "14px"};
  padding-left: ${({ paddingLeft }) => paddingLeft || null};
`;

export const Text = styled.p`
  font-weight: ${({ fontWeight }) => fontWeight || "normal"};
  width: ${({ width }) => width || null};
  font-size: ${({ font }) => font || "12px"};
  color: ${({ color }) => color || null};
  padding: ${({ padding }) => padding || null};
  text-align: ${({ align }) => align};
  margin: ${({ margin }) => margin || "0"};
  overflow-wrap: ${({ wrap }) => wrap || null};
`;

export const CircleText = styled.span`
  display: ${({ display }) => display || "flex"};
  color: ${({ color }) => color || colors.themeSubColor1};
  font-weight: ${({ weight }) => weight || "400"};
  line-height: ${({ lineHeight }) => lineHeight || "18px"};
  font-size: ${({ size }) => size || "12px"};
  align-items: center;
  opacity: ${({ opacity }) => opacity || 1};
`;

export const ProductImage = styled.img`
  width: ${({ width }) => width || "103px"};
  height: ${({ height }) => height || "98px"};
  margin-top: ${({ top }) => top || 0};
  margin-left: -2px;
  border-radius: 4px;
  object-fit: cover;
  padding: 0;
`;

export const RoundDetailsSection = styled.div`
  padding: ${({ padding }) => padding || null};
  margin: ${({ margin }) => margin || "16px 0"};
  border-radius: 13px;
  border: 1px solid ${({ borderColor }) => borderColor || colors.gray3};
  min-height: ${({ minHeight }) => minHeight || "97px"};
  background-color: ${({ background }) => background || null};
`;

export const ProductMainImage = styled.img`
  width: 32px;
  height: 32px;
  margin-bottom: 4px;
  border-radius: 4px;
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
  background-color: ${({ bg }) => bg || null};
  object-fit: ${({ wd }) => wd || null};
`;

export const MainProductImage = styled.img`
  width: ${({ width }) => width || "95%"};
  height: ${({ height }) => height || "269px"};
  margin: ${({ margin }) => margin || "16px 0"};
  padding: 0 !important;
  object-fit: cover;
  padding: 10px 5px;
  border-radius: 8px;
`;

export const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  color: ${colors.themeTextColor3};
  font-size: 10px;
  max-width: ${({ width }) => width || "100px"};
  padding: ${({ padding }) => padding || "10px"};
`;

export const ProductInfoDesc = styled.div`
  font-size: 12px;
  overflow: hidden;
  margin-top: 4px;
  text-transform: capitalize;
  line-height: 18px;
  font-weight: 400;
  color: ${colors.boldDark};
`;

export const ProductInfoOuter = styled.div`
  display: flex !important;
  align-items: center;
  height: 24px;
`;

export const ProductInfoPrice = styled.div`
  display: block;
  font-weight: 700;
  margin-top: 8px;
  font-size: 12px;
  line-height: 18px;
  color: ${colors.boldDark};
`;

export const ProductInfoBulkInfo = styled.div`
  display: block;
  font-size: 10px;
  font-weight: 400;
  color: ${colors.themeColor5};
`;

export const ProductCategoryName = styled.div`
  display: block;
  background-color: ${colors.background.component};
  color: #29394f;
  font-size: 14px;
  width: ${({ width }) => width || "150px"};
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  padding: 7px 14px;
  font-size: 10px;
  text-align: center;
  height: ${({ height }) => height || "75px"};
  border-radius: ${({ radius }) => radius || "12%"};
  text-transform: capitalize;
`;

export const ProductNameContainer = styled.div`
  position: relative;
  display: inline-block;
  border-bottom: 24px solid ${colors.lightGray};
  border-left: 25px solid ${colors.lightGray};
  border-right: 10px solid transparent;
  height: 0px;
  width: fit-content;
  text-transform: capitalize;
`;

export const ProductName = styled.p`
  position: relative;
  left: -12px;
  bottom: 8px;
  font-size: 12px;
`;

export const ProductIconContainer = styled.div`
  position: relative;
  display: inline-block;
  height: 24px;
  width: 24px;
  border-radius: ${({ radius }) => radius || null};
`;

export const ShopBlock = styled.div`
  margin: 3px 0;
  display: flex;
  width: 100%;
  height: 52px;
  align-items: center;
  padding-bottom: 4px;
  border-bottom: 3px solid ${colors.gray4};
`;

export const ShopImage = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 4px;
  overflow: hidden;
  object-fit: cover;
  padding: 0;
  margin-left: 16px;
  background-color: ${({ bg }) => bg || null};
  object-fit: ${({ wd }) => wd || null};
`;

export const ShopInfo = styled.div`
  flex: 1;
  font-size: 14px;
  padding: 5px 0 5px 10px;
`;

export const ShopName = styled.div`
  color: ${colors.boldDark};
  font-weight: 500;
  padding: 2px 0;
  font-size: 12px;
`;

export const ShopCategory = styled.div`
  color: ${colors.themeTextColor3};
  padding: 2px 0;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 10px;
`;

export const ShopLocation = styled.div`
  color: ${colors.lightDark};
  font-size: 12px;
  font-weight: 400;
`;

export const CenterText = styled.div`
  color: ${colors.themeTextColor4};
  margin: 20px 0;
  margin-bottom: 0;
  text-align: center;
  background-color: ${colors.lightestGray};
  padding: 10px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
`;

export const Description = styled.div`
  color: ${colors.boldDark};
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  white-space: nowrap;
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 4px;
`;

export const ArrowLink = styled(RippleLink)`
  position: absolute;
  top: 28px;
  right: 24px;
`;

export const LinkBox = styled.div`
  padding-top: 4px;
`;

export const BadgeHeading = styled.h5`
  position: absolute;
  top: 30px;
  left: 16px;
  font-size: 14px;
  line-height: 15px;
  color: ${colors.themeColor5};
  font-weight: 500;
`;

export const ActionButton = styled.button`
  display: inline-block;
  border: 1px solid ${colors.blue2};
  background-color: ${colors.white};
  color: ${colors.blue2};
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  width: ${({ width }) => width || "160px"};
  margin: 32px 0;
  margin-left: ${({ left }) => left || "0"};
  margin-right: ${({ right }) => right || "0"};
  cursor: pointer;
  position: relative;
  overflow: hidden;
  padding: 0;
  outline: none;
  &:focus::after {
    display: block;
  }
`;

export const Space = styled.div`
  height: ${({ height }) => height || "10px"};
  background: ${({ color }) => color || null};
  margin-bottom: ${({ bottom }) => bottom || "null"};
`;

export const MoqBox = styled.div`
  display: flex;
`;

export const MoqItem = styled.div`
  height: 67px;
  min-width: 140px;
  border-radius: 10px;
  background-color: ${colors.background.component};
  margin: 5px;
`;
export const MoqItemPrice = styled.div`
  font-weight: 700;
  padding: 10px 15px;
  color: ${colors.themeTextColor3};
`;
export const MoqItemDesc = styled.div`
  font-size: 10px;
  padding: 0 15px;
  color: ${colors.themeTextColor3};
`;

export const Divider = styled.div`
  height: 1px;
  background: #f0f0f0;
  width: 100%;
  margin-top: ${({ top }) => top || "20px"};
  margin-bottom: ${({ bottom }) => bottom || "20px"};
`;

export const Datalist = styled.div`
  position: fixed;
  background: ${colors.white};
  width: 93%;
  padding: 10px;
  height: 100%;
  z-index: 10000;
  border: 0;
`;

export const DatalistOption = styled.div`
  display: flex;
  padding: 10px 0;
  color: #29394f;
  font-size: 10px;
`;

export const DatalistTitle = styled.div`
  margin: 10px;
`;

export const CommissionAmountWrapper = styled.span`
  background-color: #189925;
  color: #ffffff;
  border-radius: 7px;
  font-size: 6px;
  font-weight: 500;
  padding: 2px 3px;
  display: inline-block;
  position: absolute;
  top: 4px;
  left: 7px;
`;
export const ImageWrapper = styled.div`
  margin-bottom: 5px;
  display: flex;
  margin-bottom: ${({ bottom }) => bottom || null};
  position: relative;
  border-radius: 13px;
  justify-content: ${({ justifyContent }) => justifyContent || null};
`;

export const Search = styled(SearchIcon)`
  position: absolute;
  right: ${({ right }) => right || "16px"};
  top: 20px;
  cursor: pointer;

  & > g {
    & > path {
      fill: ${({ color }) => color || colors.black};
    }
  }
`;

export const FullAddressHolder = styled.div`
  background: #ffffff;
  border: 1px solid ${colors.gray4};
  box-sizing: border-box;
  box-shadow: 0px 15px 84px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 12px;
  margin: 16px;
`;

export const ShopAddressInfo = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: ${colors.themeSubColor1};
  margin-left: ${({ left }) => left || "0px"};
`;

export const InnerTitle = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: ${({ color }) => color || colors.boldDark};
  margin: 8px 0 5px 16px;
`;

export const SeeAllBox = styled(RippleLink)`
  display: flex;
  position: absolute;
  height: 98px;
  width: 105px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  opacity: 0.9;
  background-color: ${colors.lightGray};
`;

export const SeeAllBoxWrapper = styled.div`
  display: flex;
  position: relative;
  height: 98px;
  width: 105px;
  margin: 14px 12px;
`;
