import React, { Fragment } from "react";

import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";

import {
  TopHeader,
  PageProgress,
  PageLogo,
  RippleButton,
  SelectBox,
} from "../../../../components";
import { InputBlock } from "../../../../containers/InputContainer";
import { Message } from "../../../../containers/MessageContainer";
import { ScreenContainer } from "../../../../containers/ScreenContainer";
import { countriesMap } from "../../../../data/countries";
import { localAreas } from "../../../../data/countries/nigeria/localAreas";
import { states } from "../../../../data/countries/nigeria/states";
import { saveMerchantRegion } from "../../../../redux/ducks/auth/signup/agent/actions";
import { findMapKeyByValues } from "../../../../utils/inputs/findMapKeyByValues";
import { selectAreasByState } from "../../../../utils/inputs/selectAreasByState";
import DesktopBackgroundLayout from "../../../DesktopBackgroundLayout";

import { AgentSelectRegionValidationSchema } from "./AgentSelectRegionValidationSchema";
import GlobeLogo from "./assets/globe.svg";

const UMAgentRegionSelection = () => {
  const dispatch = useDispatch();

  const regionData = useSelector((state) => state.user.regionData);

  return (
    <Fragment>
      <DesktopBackgroundLayout>
        <TopHeader title={"Municipalities Selection"} />
        <ScreenContainer>
          <PageLogo top={"64px"} Icon={GlobeLogo} />
          <PageProgress step={1} amount={4} />
          <Message align={"left"} bottom={"24px"}>
            Select a location where you’ll be using the app to help us customize
            your experience.
          </Message>
          <Formik
            initialValues={{
              country:
                (regionData.country &&
                  findMapKeyByValues(countriesMap, regionData.country)) ||
                "",
              state: regionData.state || "",
              lga: regionData.lga || "",
            }}
            validationSchema={AgentSelectRegionValidationSchema}
            onSubmit={(values) => {
              setTimeout(() => {
                dispatch(
                  saveMerchantRegion({
                    country: findMapKeyByValues(countriesMap, values.country),
                    state: values.state,
                    lga: values.lga,
                  })
                );
              }, 400);
            }}
          >
            {({ errors, touched, values, handleChange }) => (
              <Form>
                <InputBlock>
                  <SelectBox
                    name={"country"}
                    placeholder={"Select country"}
                    value={values && values.country}
                    options={[{ value: "SA", label: "South Africa" }]}
                    handleChange={handleChange}
                    valid={`${!touched.state && !errors.state}`}
                    error={touched && touched.state && errors && errors.state}
                  />
                  <SelectBox
                    name={"state"}
                    placeholder={"Province"}
                    value={values && values.state}
                    options={states}
                    handleChange={handleChange}
                    valid={`${!touched.state && !errors.state}`}
                    error={touched && touched.state && errors && errors.state}
                  />
                  <SelectBox
                    name={"lga"}
                    placeholder={"Municipalities"}
                    value={values && values.lga}
                    options={
                      values.state
                        ? selectAreasByState(values.state, localAreas)
                        : []
                    }
                    handleChange={handleChange}
                    valid={`${!touched.lga && !errors.lga}`}
                    error={touched && touched.lga && errors && errors.lga}
                  />
                  <RippleButton
                    type="submit"
                    disabled={Object.values(values).some((value) =>
                      value && value.length > 0 ? false : true
                    )}
                  >
                    Continue
                  </RippleButton>
                </InputBlock>
              </Form>
            )}
          </Formik>
        </ScreenContainer>
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default UMAgentRegionSelection;
