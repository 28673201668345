import React, { Fragment } from "react";

import styled from "styled-components";

import { Message, Title } from "../../../../containers/MessageContainer";

import { ReactComponent as SearchIcon } from "./assets/search_icon.svg";

const PageSubSectionsTopTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 21px;
  color: #718596;
  margin-top: 16px;
`;

const Wrapper = styled.div`
  height: 224px;
  width: 100%;
  background-color: #edf2f7;
  align-items: center;
  align-content: center;
  justify-items: center;
  display: grid;
  margin-top: 16px;
`;

const EmptyTransactionPage = () => (
  <Fragment>
    <PageSubSectionsTopTitle>Transaction History</PageSubSectionsTopTitle>
    <Wrapper>
      <SearchIcon />
      <Title size="14px" weight="700" color="#071827">
        No Transactions
      </Title>
      <Message color="#718596" bottom={"24px"} weight={"400"} top={"0"}>
        You don't have any transaction yet.
      </Message>
    </Wrapper>
  </Fragment>
);

export default EmptyTransactionPage;
