import React from "react";

import { number, func, any } from "prop-types";
import styled from "styled-components";

import { formatPrice } from "../../../../utils/currency/formatPriceWithComma";
import { InputWithLabel } from "../text";

// import Swipeout from 'rc-swipeout';
// import 'rc-swipeout/assets/index.css';
import { ReactComponent as Trash } from "./assets/trash.svg";

const BulkPriceBlock = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 36px;
  & div {
    &:first-of-type {
      margin-right: 8px;
    }
    &:last-of-type {
      margin-left: 8px;
    }
  }
`;

export const TrashIcon = styled(Trash)`
  flex: 24%;
  height: 24px;
  width: 24px;
  cursor: pointer;
`;

const Input = styled(InputWithLabel)`
  flex: 40%;
`;

export const BulkPriceInput = ({ id, data, handleChange, remove, error }) => {
  return (
    // <Swipeout
    //     right={[
    //         {
    //             text: 'hi',
    //             onPress:() => remove(id),
    //             style: { backgroundColor: '#e02020', color: 'white', marginBottom: '16px', marginTop: '10px' },
    //             className: 'custom-class-1'
    //         }
    //     ]}
    // >
    <BulkPriceBlock>
      <Input
        label={"Minimum order quantity"}
        inputMode={"decimal"}
        value={data.moq}
        type="number"
        placeholder={"MOQ"}
        name={`bulkPrices[${id}].moq`}
        noClearButton={true}
        onChange={handleChange}
        bottom={"0"}
        borderColor={
          error.bulkPrices && error.bulkPrices[id] && error.bulkPrices[id].moq
            ? "red"
            : ""
        }
        onKeyUp={(e) =>
          (e.target.value = String(e.target.value).startsWith("0")
            ? null
            : e.target.value)
        }
        onKeyPress={(e) => {
          if (e.charCode === 46) e.preventDefault();
        }}
      />
      <Input
        label={"Price"}
        inputMode={"decimal"}
        type="number"
        value={data.price}
        placeholder={"Wholesale price"}
        name={`bulkPrices[${id}].price`}
        onBlur={(e) => (e.target.value = formatPrice(data.price || 0))}
        onFocus={(e) => (e.target.value = data.price)}
        onChange={handleChange}
        noClearButton={true}
        bottom={"0"}
        borderColor={
          error.bulkPrices && error.bulkPrices[id] && error.bulkPrices[id].price
            ? "red"
            : ""
        }
        onKeyPress={(e) => {
          if (e.charCode === 46) e.preventDefault();
        }}
      />
      <TrashIcon onClick={() => remove(id)} />
    </BulkPriceBlock>
  );
};

BulkPriceInput.propTypes = {
  id: number,
  data: any,
  handleChange: func,
  remove: func,
};
