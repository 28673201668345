import React, { Fragment, useState, useEffect } from "react";

import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import {
  UserAvatar,
  TopHeader,
  RippleButton,
  InputWithLabel,
  SelectBank,
  SelectBox,
  TextareaWithLabel,
} from "../../../../../components";
import Avatar from "../../../../../components/avatar/assets/user.svg";
import { SelectCountryOverlay } from "../../../../../components/overlays/select-country";
import {
  InputBlock,
  OpenOverlaySelectBox,
} from "../../../../../containers/InputContainer";
import { Message, Error } from "../../../../../containers/MessageContainer";
import {
  ScreenContainer,
  FlexCenteredBlock,
} from "../../../../../containers/ScreenContainer";
import { countriesData, flags } from "../../../../../data/countries";
import { localAreas } from "../../../../../data/countries/nigeria/localAreas";
import { states } from "../../../../../data/countries/nigeria/states";
import { resolveBanks } from "../../../../../redux/ducks/account/wallet/actions/bank-account";
import { CLEAR_ACCOUNT } from "../../../../../redux/ducks/account/wallet/constants.js";
import { addCustomer } from "../../../../../redux/ducks/applications/my-shop/actions/customers";
import { selectAreasByState } from "../../../../../utils/inputs/selectAreasByState";
import { listToAlphabetMap } from "../../../../../utils/sorting/alphabeticSort";
import DesktopBackgroundLayout from "../../../../DesktopBackgroundLayout";
import {
  CountryFlag,
  CurrentSelectedCountry,
} from "../../../../launch/components/Main";
import { AddFields } from "../add-fields";
import { ReactComponent as CheckedIcon } from "../assets/checked.svg";
import { ReactComponent as Plus } from "../assets/plus.svg";

import { AddCustomerValidationSchema } from "./AddCustomerValidationSchema";
import { insertZero } from "../../../../../utils/inputs/formatPhoneNumber";

export const AddCustomerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
`;

export const CountryBlock = styled.div`
  position: relative;
  -webkit-align-items: center;
  align-items: center;
  background: transparent;
  font-size: 14px;
  width: 100%;
  max-width: 350px;
  padding: 1px;
  line-height: normal;
  margin-bottom: 0px;
  margin-right: 8px;
  border-radius: 4px;

  &::before {
    content: "";
    position: absolute;
    top: 18px;
    left: 42px;
    height: 20px;
    width: 1px;
    background: #cbd6e0;
    z-index: 1;
  }

  @media (max-width: 1200px) {
    max-width: initial;
    margin-right: 0;
    width: 100%;
  }
  @media (max-width: 768px) {
    width: 100%;
    max-width: 700px;
    margin-right: 0;
    line-height: normal;
    margin-bottom: 0px;
  }
`;

export const AddMore = styled.div`
  text-align: right;
  margin-top: 23px;
  font-weight: 500;
  font-size: 16px;
  color: #227eff;
`;

export const ImageSpan = styled.span`
  margin-right: 13px;
  position: relative;
  top: 2px;
`;

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const RemoveMessage = styled(Message)`
  &.hide {
    display: none;
    position: absolute;
  }
`;

export const AddButton = styled(RippleButton)`
  height: ${({ height }) => height || "56px"};
  &.top {
    top: 0;
    margin-top: 40px;
  }

  @media (max-width: 576px) {
    bottom: -10px;
  }
`;

export const AccountInput = styled(InputWithLabel)``;
export const AccountDiv = styled.div`
  position: relative;
`;

const AddCustomer = () => {
  const dispatch = useDispatch();
  const [selectedFields, setSelectedFields] = useState([]);
  const [openSelectBank, setOpenSelectBank] = useState(false);
  const [selectedBank, setSelectedBank] = useState(undefined);
  const [openAddFields, setOpenAddFields] = useState(false);
  const [disable, setDisable] = useState(false);
  const [top, setTop] = useState(false);
  const [className, setClassName] = useState(true);
  const [openCountrySelect, setOpenCountrySelect] = useState(false);
  const banks = useSelector((state) => state.account.wallet.bankName);

  const countriesInfo = listToAlphabetMap(
    countriesData
      .filter((country) => country.name === "Nigeria")
      .map((country) => country.name)
  );

  const msisdn = useSelector((state) => state.user.msisdn);
  useEffect(() => {
    dispatch({ type: CLEAR_ACCOUNT, payload: undefined });
  }, [dispatch]);
  return (
    <Fragment>
      <DesktopBackgroundLayout>
        {!openAddFields && !openSelectBank && (
          <TopHeader
            title={"New Customer"}
            backLink={"/actions/shop_customers"}
          />
        )}
        <ScreenContainer top={"80px"}>
          <FlexCenteredBlock mtop={"15px"} mbottom={"32px"}>
            <UserAvatar width={"72px"} height={"72px"} avatar={Avatar} />
          </FlexCenteredBlock>
          <AddCustomerContainer>
            <Formik
              initialValues={{
                name: "",
                phoneNumber: "",
                phoneNumber2: "",
                country: "SA",
                email: "",
                homeAddress: {
                  address: "",
                  state: "",
                  lga: "",
                  city: "",
                },

                bank: {
                  name: "",
                  accountNumber: "",
                },
                deliveryInstruction: "",
              }}
              validationSchema={AddCustomerValidationSchema}
              onSubmit={(values, { setErrors }) => {
                values.phoneNumber = insertZero(values.phoneNumber);
                if (values.phoneNumber2) {
                  values.phoneNumber2 = insertZero(values.phoneNumber2);
                }
                if (msisdn === values.phoneNumber) {
                  setErrors({
                    phoneNumber:
                      "Sorry, you cannot use your number for a customer",
                  });
                } else if (
                  values.phoneNumber2 &&
                  msisdn === values.phoneNumber2
                ) {
                  setErrors({
                    phoneNumber2:
                      "Sorry, you cannot use your number for a customer",
                  });
                } else {
                  setTimeout(() => dispatch(addCustomer(values)), 200);
                  setDisable(true);
                }
              }}
            >
              {({
                errors,
                touched,
                setFieldValue,
                initialValues,
                handleChange,
                values,
              }) => (
                <Form>
                  <InputBlock>
                    <InputWithLabel
                      label={"Full name"}
                      noClearButton
                      color={"#227EFF"}
                      type={"text"}
                      value={values.name}
                      placeholder={"Full name"}
                      name={"name"}
                      valid={`${!touched.name && !errors.name}`}
                      errors={touched && touched.name && errors && errors.name}
                      setFieldValue={setFieldValue}
                      initialValues={initialValues}
                    />
                    <Fragment>
                      <MessageWrapper>
                        <RemoveMessage
                          top={"16px"}
                          bottom={"9px"}
                          color={"#718596"}
                          size={"12px"}
                          weight={"normal"}
                          className={className ? "hide" : ""}
                        >
                          CONTACT
                        </RemoveMessage>
                        <RemoveMessage
                          top={"16px"}
                          bottom={"9px"}
                          color={"#CD4213"}
                          size={"12px"}
                          weight={"400"}
                          className={className ? "hide" : ""}
                          onClick={() => {
                            setSelectedFields(
                              selectedFields.filter(
                                (selectedField) =>
                                  selectedField !== "PHONE_NUMBER_2" &&
                                  selectedField !== "EMAIL"
                              )
                            );
                          }}
                        >
                          Remove field
                        </RemoveMessage>
                      </MessageWrapper>
                      <CountryBlock>
                        <CurrentSelectedCountry>
                          <CountryFlag
                            onClick={() =>
                              setOpenCountrySelect(!openCountrySelect)
                            }
                            src={
                              flags.filter(
                                (flag) =>
                                  flag.customAbbreviation === values.country
                              )[0].value
                            }
                            alt={
                              flags.filter(
                                (flag) =>
                                  flag.customAbbreviation === values.country
                              )[0].label
                            }
                          />
                        </CurrentSelectedCountry>
                        <SelectCountryOverlay
                          open={openCountrySelect}
                          setOpen={setOpenCountrySelect}
                          countriesInfo={countriesInfo}
                          currentCountry={values.country}
                          setCountry={setFieldValue}
                        />
                        <InputWithLabel
                          countryselection={"2px"}
                          label={"Phone Number"}
                          noClearButton
                          type={"number"}
                          bottom={"15px"}
                          value={values.phoneNumber}
                          placeholder={"Phone Number 1"}
                          name={"phoneNumber"}
                          onKeyUp={(e) =>
                            (e.target.value = e.target.value.replace(/\s/g, ""))
                          }
                          valid={`${
                            !touched.phoneNumber && !errors.phoneNumber
                          }`}
                          errors={
                            touched &&
                            touched.phoneNumber &&
                            errors &&
                            errors.phoneNumber
                          }
                          setFieldValue={setFieldValue}
                          initialValues={initialValues}
                        />
                      </CountryBlock>
                    </Fragment>

                    {selectedFields.includes("PHONE_NUMBER_2") && (
                      <CountryBlock>
                        <CurrentSelectedCountry>
                          <CountryFlag
                            onClick={() =>
                              setOpenCountrySelect(!openCountrySelect)
                            }
                            src={
                              flags.filter(
                                (flag) =>
                                  flag.customAbbreviation === values.country
                              )[0].value
                            }
                            alt={
                              flags.filter(
                                (flag) =>
                                  flag.customAbbreviation === values.country
                              )[0].label
                            }
                          />
                        </CurrentSelectedCountry>
                        <SelectCountryOverlay
                          open={openCountrySelect}
                          setOpen={setOpenCountrySelect}
                          countriesInfo={countriesInfo}
                          currentCountry={values.country}
                          setCountry={setFieldValue}
                        />
                        <InputWithLabel
                          countryselection={"2px"}
                          noClearButton
                          label={"Phone Number 2"}
                          type={"number"}
                          value={values.phoneNumber2}
                          placeholder={"Phone Number 2"}
                          name={"phoneNumber2"}
                          onKeyUp={(e) =>
                            (e.target.value = e.target.value.replace(/\s/g, ""))
                          }
                          valid={`${
                            !touched.phoneNumber2 && !errors.phoneNumber2
                          }`}
                          errors={
                            touched &&
                            touched.phoneNumber2 &&
                            errors &&
                            errors.phoneNumber2
                          }
                          setFieldValue={setFieldValue}
                          initialValues={initialValues}
                        />
                      </CountryBlock>
                    )}

                    {selectedFields.includes("EMAIL") && (
                      <InputWithLabel
                        label={"Email Address"}
                        noClearButton
                        mheight={"20px"}
                        type={"text"}
                        mtop={"0"}
                        value={values.email}
                        placeholder={"Email Address"}
                        name={"email"}
                        valid={`${!touched.email && !errors.email}`}
                        errors={
                          touched && touched.email && errors && errors.email
                        }
                        setFieldValue={setFieldValue}
                        initialValues={initialValues}
                      />
                    )}
                    {selectedFields.includes("HOUSE_ADDRESS") && (
                      <Fragment>
                        <MessageWrapper>
                          <Message
                            top={"16px"}
                            bottom={"9px"}
                            color={"#718596"}
                            size={"12px"}
                            weight={"normal"}
                          >
                            ADDRESS
                          </Message>
                          <Message
                            top={"16px"}
                            bottom={"9px"}
                            color={"#CD4213"}
                            size={"12px"}
                            weight={"400"}
                            onClick={() => {
                              setSelectedFields(
                                selectedFields.filter(
                                  (selectedField) =>
                                    selectedField !== "HOUSE_ADDRESS"
                                )
                              );
                            }}
                          >
                            Remove field
                          </Message>
                        </MessageWrapper>
                        <InputBlock>
                          <InputWithLabel
                            label={"Home Address"}
                            type={"text"}
                            bottom={"0px"}
                            noClearButton
                            value={values.homeAddress.address}
                            placeholder={"Street Address"}
                            name={"homeAddress.address"}
                            valid={`${
                              !(
                                touched &&
                                touched.homeAddress &&
                                touched.homeAddress.address
                              ) &&
                              !(
                                errors &&
                                errors.homeAddress &&
                                errors.homeAddress.address
                              )
                            }`}
                            errors={
                              touched &&
                              touched.homeAddress &&
                              touched.homeAddress.address &&
                              errors &&
                              errors.homeAddress &&
                              errors.homeAddress.address
                            }
                            setFieldValue={setFieldValue}
                            initialValues={initialValues}
                          />
                          <SelectBox
                            name={"homeAddress.state"}
                            opacity={"0.5"}
                            placeholder={"Province"}
                            value={values.homeAddress.state}
                            options={states}
                            handleChange={handleChange}
                            valid={`${
                              !(
                                touched &&
                                touched.homeAddress &&
                                touched.homeAddress.state
                              ) &&
                              !(
                                errors &&
                                errors.homeAddress &&
                                errors.homeAddress.state
                              )
                            }`}
                            error={
                              touched &&
                              touched.homeAddress &&
                              touched.homeAddress.state &&
                              errors &&
                              errors.homeAddress &&
                              errors.homeAddress.state
                            }
                          />
                          {errors &&
                            errors.homeAddress &&
                            errors.homeAddress.state && (
                              <Error>{errors.homeAddress.state}</Error>
                            )}
                          <SelectBox
                            name={"homeAddress.lga"}
                            placeholder={"Municipalities"}
                            opacity={"0.5"}
                            value={values.homeAddress.lga}
                            options={
                              values.homeAddress && values.homeAddress.state
                                ? selectAreasByState(
                                    values.homeAddress.state,
                                    localAreas
                                  )
                                : []
                            }
                            handleChange={handleChange}
                            valid={`${
                              !(
                                touched &&
                                touched.homeAddress &&
                                touched.homeAddress.lga
                              ) &&
                              !(
                                errors &&
                                errors.homeAddress &&
                                errors.homeAddress.lga
                              )
                            }`}
                            errors={
                              touched &&
                              touched.homeAddress &&
                              touched.homeAddress.lga &&
                              errors &&
                              errors.homeAddress &&
                              errors.homeAddress.lga
                            }
                          />
                          {errors &&
                            errors.homeAddress &&
                            errors.homeAddress.lga && (
                              <Error>{errors.homeAddress.lga}</Error>
                            )}
                          <InputWithLabel
                            label={"City"}
                            type={"text"}
                            top={"15px"}
                            noClearButton
                            value={values.homeAddress.city}
                            placeholder={"City"}
                            name={"homeAddress.city"}
                            valid={`${
                              !(
                                touched &&
                                touched.homeAddress &&
                                touched.homeAddress.city
                              ) &&
                              !(
                                errors &&
                                errors.homeAddress &&
                                errors.homeAddress.city
                              )
                            }`}
                            errors={
                              touched &&
                              touched.homeAddress &&
                              touched.homeAddress.city &&
                              errors &&
                              errors.homeAddress &&
                              errors.homeAddress.city
                            }
                            setFieldValue={setFieldValue}
                            initialValues={initialValues}
                          />
                        </InputBlock>
                      </Fragment>
                    )}

                    {selectedFields.includes("BANK_ACCOUNT") && (
                      <AccountDiv>
                        <MessageWrapper>
                          <Message
                            top={"16px"}
                            bottom={"9px"}
                            color={"#718596"}
                            size={"12px"}
                            weight={"normal"}
                          >
                            BANK INFORMATION
                          </Message>
                          <Message
                            top={"16px"}
                            bottom={"9px"}
                            color={"#CD4213"}
                            size={"12px"}
                            weight={"400"}
                            onClick={() => {
                              setSelectedFields(
                                selectedFields.filter(
                                  (selectedField) =>
                                    selectedField !== "BANK_ACCOUNT"
                                )
                              );
                            }}
                          >
                            Remove field
                          </Message>
                        </MessageWrapper>
                        <InputBlock>
                          <OpenOverlaySelectBox
                            style={{
                              opacity: "0.5",
                            }}
                            weight={"400"}
                            textColor={"#6F798B"}
                            onClick={() => setOpenSelectBank(!openSelectBank)}
                          >
                            {values.bank.name || "Bank"}
                          </OpenOverlaySelectBox>
                          {errors && errors.bank && errors.bank.name && (
                            <Error>{errors.bank.name}</Error>
                          )}
                          <AccountInput
                            label={"Account Number"}
                            bottom={"4px"}
                            type={"text"}
                            noClearButton
                            value={values.bank.accountNumber}
                            placeholder={"Account Number"}
                            name={"bank.accountNumber"}
                            valid={`${
                              !(
                                touched &&
                                touched.bank &&
                                touched.bank.accountNumber
                              ) &&
                              !(
                                errors &&
                                errors.bank &&
                                errors.bank.accountNumber
                              )
                            }`}
                            errors={
                              touched &&
                              touched.bank &&
                              touched.bank.accountNumber &&
                              errors &&
                              errors.bank &&
                              errors.bank.accountNumber
                            }
                            onKeyUp={(e) =>
                              banks === undefined
                                ? dispatch(
                                    resolveBanks(e.target.value, selectedBank)
                                  )
                                : dispatch({
                                    type: CLEAR_ACCOUNT,
                                    payload: undefined,
                                  })
                            }
                            setFieldValue={setFieldValue}
                            initialValues={initialValues}
                          />
                        </InputBlock>
                        {openSelectBank && (
                          <SelectBank
                            open={openSelectBank}
                            setOpen={setOpenSelectBank}
                            selectedBank={selectedBank}
                            setSelectedBank={setSelectedBank}
                            // getAvailableBanks={getAvailableBanks}
                            setFieldValue={setFieldValue}
                            fieldName={"bank.name"}
                          />
                        )}
                        <CheckedIcon
                          style={{
                            position: "absolute",
                            bottom: "21%",
                            left: "90%",
                            display: banks === undefined ? "none" : "block",
                          }}
                        ></CheckedIcon>
                        <Message
                          top={"0"}
                          left={"14px"}
                          size={"10px"}
                          color={"#00B361"}
                        >
                          {banks}
                        </Message>
                      </AccountDiv>
                    )}
                    {selectedFields.includes("DELIVERY_NOTE") && (
                      <Fragment>
                        <MessageWrapper>
                          <Message
                            top={"16px"}
                            bottom={"9px"}
                            color={"#718596"}
                            size={"12px"}
                            weight={"normal"}
                          >
                            DELIVERY NOTE
                          </Message>
                          <Message
                            top={"16px"}
                            bottom={"9px"}
                            color={"#CD4213"}
                            size={"12px"}
                            weight={"400"}
                            onClick={() => {
                              setSelectedFields(
                                selectedFields.filter(
                                  (selectedField) =>
                                    selectedField !== "DELIVERY_NOTE"
                                )
                              );
                            }}
                          >
                            Remove field
                          </Message>
                        </MessageWrapper>
                        <TextareaWithLabel
                          label={"Delivery Note"}
                          value={values.deliveryInstruction}
                          onChange={handleChange}
                          type={"text"}
                          height={"80px"}
                          placeholder={
                            "Please put down your thoughts / comments here. The merchant will not see it."
                          }
                          name={"deliveryInstruction"}
                          valid={`${
                            !touched.deliveryInstruction &&
                            !errors.deliveryInstruction
                          }`}
                          errors={
                            touched &&
                            touched.deliveryInstruction &&
                            errors &&
                            errors.deliveryInstruction
                          }
                          setFieldValue={setFieldValue}
                          initialValues={initialValues}
                        />
                      </Fragment>
                    )}
                  </InputBlock>
                  <AddMore
                    onClick={() => {
                      setOpenAddFields(!openAddFields);
                      setClassName(false);
                      setTop(true);
                    }}
                  >
                    <ImageSpan>
                      <Plus />
                    </ImageSpan>
                    Add more info
                  </AddMore>
                  <AddButton
                    disabled={
                      values.phoneNumber === "" || values.name === "" || disable
                    }
                    className={top ? "top" : ""}
                    top={"160px"}
                    size={"16px"}
                    type={"submit"}
                  >
                    Add Customer
                  </AddButton>
                </Form>
              )}
            </Formik>

            {openAddFields && (
              <AddFields
                open={openAddFields}
                setOpen={setOpenAddFields}
                selectedFields={selectedFields}
                setSelectedFields={setSelectedFields}
              />
            )}
          </AddCustomerContainer>
        </ScreenContainer>
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default AddCustomer;
