import React, { Fragment, useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import styled from "styled-components";

import { Loader, TopHeader, UserAvatar } from "../../../../components";
import { ScreenContainer } from "../../../../containers/ScreenContainer";
import {
  newPinResendCode,
  sendOTPToChannel,
} from "../../../../redux/ducks/auth/check/actions";
import { getUserPublicData } from "../../../../redux/ducks/auth/login/actions";
import { colors } from "../../../../styles";
import DesktopBackgroundLayout from "../../../DesktopBackgroundLayout";
// import { ReactComponent as CallImage } from '../../check/assets/call-img.svg'
import { ReactComponent as TextMessageImage } from "../../check/assets/text-message.svg";
import { ReactComponent as WhatsappImage } from "../../check/assets/whatsapp-img.svg";
import { ACTIONTYPES, OTPCHANNELS } from "../../otp-options";

const StyleAvatar = {
  display: "flex",
  justifyContent: "center",
  margin: "41px auto auto auto",
};

const StyledWhatsapPSvg = styled(WhatsappImage)`
  display: flex;
  justify-content: center;
  margin: 40px auto auto auto;
`;

// const StyledCallSvg = styled(CallImage)`
//     display: flex;
//     justify-content: center;
//     margin: 16px auto auto auto;
// `;

const StyledTextMessageSvg = styled(TextMessageImage)`
  display: flex;
  justify-content: center;
  margin: 16px auto auto auto;
`;

const MainText = styled.span`
  color: ${colors.themeTextColor11};
  font-weight: 700;
  font-size: 24px;
  justify-content: center;
  display: flex;
  margin: 16px auto auto auto;
`;

const SubText = styled.div`
  color: ${colors.themeTextColor10};
  font-weight: 400;
  font-size: 14px;
  justify-content: center;
  margin: 4px auto auto 39px;
`;

const PasswordOtpOptions = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const resend = location?.state?.resend;

  const { userId, firstName, avatar } = useSelector((state) => state.user);
  const isLoading = useSelector((state) => state.auth.check.isLoading);

  const [userData, setUserData] = useState(null);

  useEffect(() => {
    (async () => {
      const data = await dispatch(getUserPublicData(userId));
      if (data) setUserData(data);
    })();
  }, [dispatch, userId, firstName]);

  const whatsapp = async () => {
    if (resend) {
      let id = await dispatch(newPinResendCode("WHATSAPP"));
      id && history.push("/user/password_forgot");
    } else {
      requestOTP(ACTIONTYPES.RESET_LOGIN_PIN, OTPCHANNELS.WHATSAPP);
    }
  };

  // const voice = async () => {
  //     if (resend) {
  //         let id = dispatch(newPinResendCode("VOICE"));
  //         id && history.push('/user/password_forgot');
  //     } else {
  //         requestOTP(ACTIONTYPES.RESET_LOGIN_PIN, OTPCHANNELS.VOICE);
  //     }
  // };

  const sms = async () => {
    if (resend) {
      let id = dispatch(newPinResendCode("SMS"));
      id && history.push("/user/password_forgot");
    } else {
      requestOTP(ACTIONTYPES.RESET_LOGIN_PIN, OTPCHANNELS.SMS);
    }
  };

  const requestOTP = async (actionType, channel) => {
    const chanel = channel === OTPCHANNELS.SMS ? "mobile" : "whatsapp";
    const res = await dispatch(
      sendOTPToChannel(actionType, chanel, channel.toLowerCase())
    );
    res &&
      history.push({
        pathname: "/user/password_forgot",
        state: { channel, actionType },
      });
  };

  return isLoading ? (
    <Loader />
  ) : (
    <Fragment>
      <DesktopBackgroundLayout>
        <TopHeader title="OTP Verification" />
        <ScreenContainer top={"64px"}>
          <div style={StyleAvatar}>
            <UserAvatar avatar={avatar} width="72px" height="72px" />
          </div>
          <MainText>
            Hello,
            <strong style={{ color: "black", marginLeft: 4 }}>
              {firstName || userData?.firstName}
            </strong>
          </MainText>
          <SubText>
            Let's get you back into your account. Kindly <br />
            select an option so we can send you an OTP
          </SubText>
          <StyledWhatsapPSvg onClick={whatsapp} />
          {/* <StyledCallSvg onClick={voice}/> */}
          <StyledTextMessageSvg onClick={sms} />
        </ScreenContainer>
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default PasswordOtpOptions;
