import { USER_LOGOUT } from "../../user/constants";

import {
  LOADING_NOTIFICATIONS,
  LOADING_NOTIFICATIONS_SUCCESS,
  LOADING_NOTIFICATIONS_ERROR,
  SAVE_NOTIFICATIONS,
  TOGGLE_SEEN_STATE,
  SHOW_POPUP,
} from "./constants";

const initialState = {
  isLoading: false,
  showPopUp: true,
  allNotifications: [],
};

export default function notificationReducer(state = initialState, action) {
  switch (action.type) {
    case LOADING_NOTIFICATIONS: {
      if (state.allNotifications && state.allNotifications.length > 0) {
        return {
          ...state,
          isLoading: false,
        };
      } else {
        return {
          ...state,
          isLoading: true,
        };
      }
    }

    case LOADING_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case LOADING_NOTIFICATIONS_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case SAVE_NOTIFICATIONS: {
      return {
        ...state,
        allNotifications: action.payload,
      };
    }

    case TOGGLE_SEEN_STATE: {
      const notificationId = action.payload;

      return {
        ...state,
        allNotifications: state.allNotifications.map((notification) => {
          if (notification.id === notificationId) {
            notification.state =
              notification.state === "READ" ? "UNREAD" : "READ";
          }

          return notification;
        }),
      };
    }

    case SHOW_POPUP: {
      return {
        ...state,
        showPopUp: action.payload,
      };
    }

    case USER_LOGOUT: {
      return {
        isLoading: false,
        showPopUp: true,
        allNotifications: [],
      };
    }

    default: {
      return state;
    }
  }
}
