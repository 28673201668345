import React, { useEffect, useState } from "react";

import moment from "moment";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { RippleButton } from "../../../../../components";
import { DebtReminderModal } from "../../../../../components/popup";
import {
  setReminder,
  updateReminder,
} from "../../../../../redux/ducks/applications/my-shop/actions/debtors";
import { colors } from "../../../../../styles";
import { ReactComponent as WhatsappIcon } from "../assets/whatsappIcon.svg";

import FrequencyReminder from "./FrequentReminder";
import OneTimeReminder from "./OneTimeReminder";

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  padding: 0 16px;
`;

const WhatsappStyled = styled(WhatsappIcon)`
  width: 51.63px;
  height: 51.63px;
`;

const NotificationWrapper = styled.div`
  width: 100%;
  height: 64px;
  background: #e2fef0;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;
`;

const Notification = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: #071827;
  margin-left: 12.37px;
  text-align: left;
`;

const ButtonSpace = styled.div`
  width: 20px;
`;

const NotificationOutterWrapper = styled.div`
  margin: ${({ top }) => top || "28px"} 16px 40px 16px;
  padding-bottom: 50px;
`;

const SetReminderPopup = ({
  open,
  cancel,
  customerName,
  customerId,
  activeButton,
  isLoading,
  daysOfTheWeek,
  isEdit,
  reminderStatus,
}) => {
  const dispatch = useDispatch();
  const [activeBtn, setActiveBtn] = useState(
    activeButton || "One-Time Reminder"
  );
  const [selectedOneTimeDate, setSelectedOneTimeDate] = useState(null);
  const [timeTypes] = useState(["AM", "PM"]);
  const [activeTimeType, setActiveTimeType] = useState("AM");
  const [timeHour, setTimeHour] = useState("");
  const [timeMinute, setTimeMinute] = useState("");
  const [body, setBody] = useState({
    customerId: null,
    frequency: "MONTHLY",
    day: "",
    time: "",
    oneTime: activeBtn === "One-Time Reminder" ? true : false,
    oneTimeDate: null,
    active: true,
    reminderDates: [],
  });

  const handleActiveBtn = (active) => {
    setActiveBtn(active);
    setBody({
      ...body,
      oneTime: active === "One-Time Reminder" ? true : false,
    });
  };

  const handleSubmit = () => {
    if (!body.oneTime) {
      const twentyFourHrTime = moment(
        `${timeHour}:${timeMinute} ${activeTimeType}`,
        "h:mm A"
      ).format("HH:mm");
      dispatch(
        !isEdit && !reminderStatus?.customerId
          ? setReminder(customerId, {
              ...body,
              time: twentyFourHrTime,
            })
          : updateReminder(customerId, {
              ...body,
              time: twentyFourHrTime,
            })
      );
    } else {
      dispatch(
        !isEdit && !reminderStatus?.customerId
          ? setReminder(customerId, body)
          : updateReminder(customerId, body)
      );
    }
  };

  useEffect(() => {
    if (selectedOneTimeDate) {
      const { day, month, year } = selectedOneTimeDate;
      const newDate = moment(`${day}-${month}-${year}`, "D-M-YYYY").format(
        "DD-MM-YYYY"
      );
      setBody({
        ...body,
        oneTimeDate: newDate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOneTimeDate]);

  const disable =
    (activeBtn === "One-Time Reminder" && body.oneTimeDate === null) ||
    (activeBtn === "Frequent Reminder" &&
      (!body.day || !timeHour || !timeMinute));
  return (
    <DebtReminderModal
      isEdit={isEdit}
      customerName={customerName}
      maxHeight="80vh"
      height="85vh"
      open={open}
      cancel={cancel}
      reminderStatus={reminderStatus}
    >
      <ButtonWrapper>
        <RippleButton
          color={
            activeBtn === "One-Time Reminder" ? colors.white : colors.boldDark
          }
          backgroundColor={
            activeBtn === "One-Time Reminder" ? colors.deepBlue : colors.white
          }
          radius="60px"
          border={
            activeBtn === "One-Time Reminder" ? null : "1px solid #E2E9F0"
          }
          height="38px"
          onClick={() => handleActiveBtn("One-Time Reminder")}
        >
          One-Time Reminder
        </RippleButton>
        <ButtonSpace />
        <RippleButton
          color={
            activeBtn === "Frequent Reminder" ? colors.white : colors.boldDark
          }
          backgroundColor={
            activeBtn === "Frequent Reminder" ? colors.deepBlue : colors.white
          }
          radius="60px"
          border={
            activeBtn === "Frequent Reminder" ? null : "1px solid #E2E9F0"
          }
          height="38px"
          onClick={() => handleActiveBtn("Frequent Reminder")}
        >
          Frequent Reminder
        </RippleButton>
      </ButtonWrapper>
      {activeBtn === "One-Time Reminder" ? (
        <OneTimeReminder
          selectedOneTimeDate={selectedOneTimeDate}
          setSelectedOneTimeDate={setSelectedOneTimeDate}
        />
      ) : (
        <FrequencyReminder
          timeTypes={timeTypes}
          timeHour={timeHour}
          setTimeHour={setTimeHour}
          timeMinute={timeMinute}
          setTimeMinute={setTimeMinute}
          body={body}
          setBody={setBody}
          setActiveTimeType={setActiveTimeType}
          activeTimeType={activeTimeType}
          daysOfTheWeek={daysOfTheWeek}
          reminderStatus={reminderStatus}
        />
      )}

      <NotificationOutterWrapper
        top={activeBtn === "Frequent Reminder" ? "10px" : null}
      >
        <NotificationWrapper>
          <WhatsappStyled />
          <Notification>
            Kindly note that the automatic reminder will be sent to{" "}
            {customerName?.split(" ")[0]}’s Whatsapp
          </Notification>
        </NotificationWrapper>
        <RippleButton disabled={disable} onClick={handleSubmit} top="24px">
          {isLoading
            ? "Loading..."
            : isEdit && reminderStatus.active
            ? "Apply Edit"
            : "Set Reminder"}
        </RippleButton>
      </NotificationOutterWrapper>
    </DebtReminderModal>
  );
};

export default SetReminderPopup;
