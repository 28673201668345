import { axios, currentAPI } from "../../../../../config";
import { addPendingRequest } from "../../../offline/actions";
import {
  LOADING_NOTIFICATIONS,
  LOADING_NOTIFICATIONS_SUCCESS,
  LOADING_NOTIFICATIONS_ERROR,
  SAVE_NOTIFICATIONS,
  TOGGLE_SEEN_STATE,
} from "../constants";

export const toggleSeen = (state, id) => async (dispatch) => {
  const newState = state === "READ" ? "UNREAD" : "READ";
  if (newState === "UNREAD") return;

  if (navigator.onLine) {
    try {
      const toggleSeenResponse = await axios.patch(
        `${currentAPI}/api/notification/${id}?newState=${newState}`,
        null
      );

      if (toggleSeenResponse.status === 200) {
        dispatch({ type: TOGGLE_SEEN_STATE, payload: id });
      }
    } catch (error) {
      console.error(error);
    }
  } else {
    dispatch({ type: TOGGLE_SEEN_STATE, payload: id });
    dispatch(
      addPendingRequest(
        "PATCH",
        `${currentAPI}/api/notification/${id}?newState=${newState}`,
        null
      )
    );
  }
};

export const getNotifications = () => async (dispatch) => {
  if (!navigator.onLine) return;

  dispatch({ type: LOADING_NOTIFICATIONS });

  try {
    const getNotificationsResponse = await axios.get(
      `${currentAPI}/api/notification/all`
    );

    if (getNotificationsResponse.status === 200) {
      dispatch({
        type: SAVE_NOTIFICATIONS,
        payload: getNotificationsResponse.data.notificationItems.reverse(),
      });
      dispatch({ type: LOADING_NOTIFICATIONS_SUCCESS });
    }
  } catch (error) {
    dispatch({ type: LOADING_NOTIFICATIONS_ERROR });
    console.error(error);
  }
};
