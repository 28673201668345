import React, { useEffect, useState } from "react";

import { GroupBuyQty } from "../../../../../components";
import { Container } from "../../styles";
import { BlockWithBorders, Paragraph } from "../styles";

export const GroupBuySplitQuantity = ({
  noOfInvitees = 0,
  unitPrice = 0,
  qtyLeftToShare,
  setQtyLeftToShare,
  tempQtySharingSplits,
  setInvitedSlots,
}) => {
  const [sharingSlots, setSharingSlots] = useState({});

  const handleChange = ({ name, val }) => {
    const initialValue = Number(sharingSlots[name]);
    const currentValue = Number(val);
    const change = qtyLeftToShare + initialValue - currentValue;

    if (change >= 0) {
      setQtyLeftToShare(change);
      setSharingSlots({ ...sharingSlots, [name]: val });
    }
  };

  const handleAddition = ({ name }) => {
    let currentValue = sharingSlots[name];
    if (qtyLeftToShare) {
      currentValue = Number(currentValue);
      setQtyLeftToShare(qtyLeftToShare - 1);
      setSharingSlots({ ...sharingSlots, [name]: currentValue + 1 });
    }
  };

  const handleSubstraction = ({ name }) => {
    let currentValue = sharingSlots[name];
    if (currentValue && currentValue > 1) {
      currentValue = Number(currentValue);
      setQtyLeftToShare(qtyLeftToShare + 1);
      setSharingSlots({ ...sharingSlots, [name]: currentValue - 1 });
    }
  };

  const renderInputs = () => {
    const arr = [];

    for (let i = 0; i < noOfInvitees; i++) {
      arr.push(i);
    }

    return arr.map((el, index) => (
      <GroupBuyQty
        key={el}
        name={index}
        slot={index === 0 ? "Slot 1(Me)" : `Slot ${index + 1}`}
        splitCost={unitPrice * sharingSlots[index]}
        inputVal={sharingSlots[index]}
        handleChange={handleChange}
        handleAddition={handleAddition}
        handleSubstraction={handleSubstraction}
        disabled={false}
      />
    ));
  };

  useEffect(() => {
    setSharingSlots(tempQtySharingSplits);
  }, [tempQtySharingSplits]);

  useEffect(() => {
    const entries = Object.keys({ ...sharingSlots }).flatMap((key) => [
      sharingSlots[key],
    ]);

    setInvitedSlots(entries);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sharingSlots]);

  return (
    <>
      <Container width={"100%"}>
        <BlockWithBorders padding="10px" style={{ background: "#fff" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "12px",
              margin: "10px 0 15px",
            }}
          >
            <Paragraph>Quantity left to allocate:</Paragraph>
            <Paragraph
              style={{
                background: "#E9F2FF",
                mixBlendMode: "normal",
                border: "0.5px solid #EDF2F7",
                borderRadius: "4px",
                height: "24px",
                width: "24px",
                marginLeft: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {qtyLeftToShare}
            </Paragraph>
          </div>

          {renderInputs()}
        </BlockWithBorders>
      </Container>
    </>
  );
};
