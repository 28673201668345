import React, { Fragment, useState, useRef, useEffect } from "react";

import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Switch from "react-switch";
import styled from "styled-components";

import {
  RippleButton,
  TopHeader,
  InputWithOnchange,
  UserAvatar,
  SelectBox,
} from "../../../../../../components";
import { ScreenContainer } from "../../../../../../containers/ScreenContainer";
import { verifyAccountNumber } from "../../../../../../redux/ducks/account/wallet/actions";
import { colors } from "../../../../../../styles";
import { formatPrice } from "../../../../../../utils/currency/formatPriceWithComma";
import { formatCurrency } from "../../../../../../utils/currency/parseBalance";
import DesktopBackgroundLayout from "../../../../../DesktopBackgroundLayout";
import Spinner from "../assets/loader.gif";
import { ReactComponent as ValidUserSvg } from "../assets/success_mark.svg";

import { WalletToBankValidationSchema } from "./WalletToBankValidationSchema";

const Title = styled.h2`
  font-size: 16px;
  font-weight: 700;
  color: ${({ color }) => color || "#071827"};
  text-align: left;
  margin: 16px 0px 6px;
`;

const Message = styled.h2`
  font-size: ${({ size }) => size || "14px"};
  font-weight: 400;
  color: ${({ color }) => color || "#718596"};
  text-align: left;
  line-height: 21px;
  margin-bottom: 6px;
  margin-left: ${({ left }) => left || "0px"};
  margin-top: ${({ top }) => top || null};
`;

const Count = styled(Message)`
  font-size: 10px;
  text-align: right;
  margin-right: 16px;
  margin-top: 4px;
`;

const SwitchTrigger = styled(Switch)`
  & > .react-switch-bg {
    & > div {
      & > svg {
        display: none;
      }
    }
  }
`;

const VerticalDiv = styled.div`
  diplay: flex;
  flex-direction: column;
`;

const SaveBeneficiaryWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 38px;
  width: 100%;
  align-items: center;
`;

const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const FlexContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 85%;
  margin-top: 16px;
  padding-bottom: 16px;
  justify-content: space-between;
`;

const WalletToBankTransfer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const formRef = useRef();

  const { accountNumber, name } = location.state?.bank || {};
  const banks = useSelector((state) => state.account.wallet.availableBanks);
  const walletBalance = useSelector(
    (state) => state.account.wallet.balance || 0
  );
  const customers = useSelector((state) => state.applications.myShop.customers);
  const [narrationCount, setNarrationCount] = useState(0);
  const [receiverAccounNumber, setReceiverAccountNumber] = useState("");
  const [receiverName, setReceiverName] = useState("");
  const [saveBeneficiary, setSaveBeneficiary] = useState(false);
  const [modifiedBanksList, setModifiedBanksList] = useState([]);
  const [selectedBankCode, setSelectedBankCode] = useState("");

  useEffect(() => {
    if (banks.length > 0) {
      const bankList = banks.map((bank) => ({
        value: bank["code"],
        label: bank["name"],
      }));
      setModifiedBanksList(bankList);
    }
  }, [banks]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if (
        receiverAccounNumber &&
        receiverAccounNumber.length === 10 &&
        selectedBankCode
      ) {
        const res = await dispatch(
          verifyAccountNumber(receiverAccounNumber, selectedBankCode)
        );
        if (res) {
          setReceiverName(res);
        } else {
          setReceiverName("");
          // eslint-disable-next-line no-unused-expressions
          formRef.current?.setTouched({ accountNumber: true }, true);
          return formRef.current?.setErrors({
            accountNumber: "Account number does not exist",
          });
        }
      } else {
        setReceiverName(undefined);
        setSaveBeneficiary(false);
      }
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [dispatch, receiverAccounNumber, selectedBankCode]);

  useEffect(() => {
    if (location.state?.bank) {
      setReceiverAccountNumber(accountNumber);
      setSelectedBankCode(
        modifiedBanksList.find((bank) => bank.label === name)?.value
      );
    }
  }, [location.state, accountNumber, name, modifiedBanksList]);

  return (
    <Fragment>
      <DesktopBackgroundLayout>
        <TopHeader title={"Bank Transfer"} />
        <ScreenContainer height="100%" top="64px">
          <Title>Who would you like to transfer to?</Title>
          <Message top={"4px"}>
            Kindly provide the bank details that you will like to transfer to.
          </Message>

          <Formik
            innerRef={formRef}
            initialValues={{
              bankCode: "",
              bank: name || "",
              accountName: "",
              accountNumber: accountNumber || "",
              amount: "",
              narration: "",
            }}
            validationSchema={WalletToBankValidationSchema}
            onSubmit={(values, { setErrors }) => {
              if (values.amount < 100) {
                setErrors({ amount: "Amount should be at least 100" });
                return;
              }

              history.push({
                pathname: "/user/wallet_to_bank_transfer_pin",
                state: {
                  ...values,
                  bankCode: selectedBankCode,
                  accountName: receiverName,
                  saveBeneficiary: saveBeneficiary,
                },
              });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              setFieldValue,
              initialValues,
            }) => (
              <FlexContainer>
                <InputBlock>
                  <SelectBox
                    placeholder={"Bank"}
                    value={values.bank}
                    options={modifiedBanksList}
                    handleChange={(selected) => {
                      setFieldValue("bank", selected.label);
                      setFieldValue("bankCode", selected.value);
                      setSelectedBankCode(selected.value);
                    }}
                    valid={`${!touched.bank && !errors.bank}`}
                    error={touched && touched.bank && errors && errors.bank}
                  />

                  <InputWithOnchange
                    label={"Account Number"}
                    type={"text"}
                    value={values.accountNumber}
                    placeholder={"Account Number"}
                    name="accountNumber"
                    valid={`${!touched.accountNumber && !errors.accountNumber}`}
                    autoComplete={"off"}
                    errors={
                      touched &&
                      touched.accountNumber &&
                      errors &&
                      errors.accountNumber
                    }
                    bottom="0px"
                    height="56px"
                    top="16px"
                    maxLength={10}
                    background="#F2F5FA"
                    onChange={(e) => {
                      setReceiverAccountNumber(e.target.value);
                      setFieldValue("accountNumber", e.target.value);
                    }}
                    onKeyUp={(e) => {
                      e.target.value = e.target.value.replace(/\s/g, "");
                    }}
                    onFocus={(e) => {}}
                    noClearButton={true}
                    initialValues={initialValues}
                  >
                    {values.accountNumber.length === 10 &&
                    receiverName === undefined &&
                    selectedBankCode ? (
                      <UserAvatar
                        avatar={Spinner}
                        width={"42px"}
                        height={"42px"}
                      />
                    ) : null}
                    {receiverName && <ValidUserSvg height="42px" />}
                  </InputWithOnchange>
                  {receiverName && (
                    <Message left="16px" size={"10px"}>
                      {receiverName}
                    </Message>
                  )}

                  <InputWithOnchange
                    label={"Amount"}
                    type={"text"}
                    value={formatCurrency(values.amount, "SA")}
                    placeholder={"Amount"}
                    name="amount"
                    valid={`${!touched.amount && !errors.amount}`}
                    autoComplete={"off"}
                    errors={
                      touched && touched.amount && errors && errors.amount
                    }
                    bottom="0px"
                    height="56px"
                    top="16px"
                    background="#F2F5FA"
                    onChange={(e) => {
                      const {
                        target: { value: inputValue = 0 },
                      } = e;
                      const value = parseInt(
                        inputValue.replace(/\D/g, "") || 0,
                        10
                      );
                      setFieldValue("amount", value);
                    }}
                    onKeyUp={(e) => {}}
                    onFocus={(e) => {}}
                    noClearButton={true}
                    initialValues={initialValues}
                  />
                  <Message
                    left="16px"
                    size={"10px"}
                  >{`Wallet Balance: ${formatPrice(
                    walletBalance || 0
                  )}`}</Message>

                  <InputWithOnchange
                    label={"Narration"}
                    type={"text"}
                    value={values.narration}
                    placeholder={"Narration"}
                    name="narration"
                    valid={`${!touched.narration && !errors.narration}`}
                    autoComplete={"off"}
                    errors={
                      touched && touched.narration && errors && errors.narration
                    }
                    bottom="0px"
                    height="56px"
                    top="16px"
                    maxLength={40}
                    background="#F2F5FA"
                    onChange={(e) => {
                      setFieldValue("narration", e.target.value);
                      setNarrationCount(e.target.value.length);
                    }}
                    onKeyUp={(e) => {}}
                    onFocus={(e) => {}}
                    noClearButton={true}
                    initialValues={initialValues}
                  />
                  <Count>{`${parseInt(narrationCount)}/40`}</Count>

                  {receiverAccounNumber &&
                    receiverAccounNumber.length === 10 &&
                    customers &&
                    !customers.find(
                      (customer) =>
                        customer?.bank?.accountNumber === receiverAccounNumber
                    ) && (
                      <SaveBeneficiaryWrapper>
                        <VerticalDiv>
                          <Message color={"#0B0C0E"}>
                            Save as beneficiary
                          </Message>
                          <Message size={"12px"}>
                            Send money to your friends with ease.
                          </Message>
                        </VerticalDiv>
                        <SwitchTrigger
                          onChange={() => setSaveBeneficiary(!saveBeneficiary)}
                          checked={saveBeneficiary}
                          onColor={"#227EFF"}
                          height={21}
                          width={43}
                          offColor={"#BBC0C9"}
                        />
                      </SaveBeneficiaryWrapper>
                    )}
                </InputBlock>
                <RippleButton
                  type="submit"
                  top={"2px"}
                  disabled={!selectedBankCode || !receiverName}
                  backgroundColor={colors.deepBlue}
                >
                  Continue
                </RippleButton>
              </FlexContainer>
            )}
          </Formik>
        </ScreenContainer>
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default WalletToBankTransfer;
