import React, { Fragment, useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import { ReactComponent as AllOrderSVG } from "../../../../assets/all_products.svg";
import { ReactComponent as Ascending } from "../../../../assets/highest_sort_2.svg";
import { ReactComponent as Descending } from "../../../../assets/lowest_sort_2.svg";
import { ReactComponent as MoqSVG } from "../../../../assets/moq.svg";
import { ReactComponent as MostRecent } from "../../../../assets/most_recent.svg";
import { ReactComponent as Oldest } from "../../../../assets/oldest.svg";
import { ReactComponent as PriceSVG } from "../../../../assets/price.svg";
import {
  SearchHeader,
  OptionsPopupDialog,
  MoqPopup,
  PriceRangePopup,
  DeliveryLocationPopup,
} from "../../../../components";
import {
  FilterAndSortSection,
  SortFilterCell,
} from "../../../../containers/ScreenContainer";
import { merchbuyActions } from "../../../../redux/ducks/applications/merchbuy/actions";
import DesktopBackgroundLayout from "../../../DesktopBackgroundLayout";
import { ReactComponent as FilterIcon } from "../assets/filter.svg";
import { ReactComponent as SortIcon } from "../assets/sort.svg";
import { Cart, ListProducts } from "../components";
import { Main, Space, Container } from "../styles";
import { filterProducts } from "../utils/filterProducts";
import { sortProducts } from "../utils/sortProducts";

const MerchbuyRecommendedProducts = () => {
  const dispatch = useDispatch();

  const recommendedProducts = useSelector(
    (state) => state.applications.merchbuy.merchbuyRecommendedProducts
  );

  const [deliveryLocation, setDeliveryLocation] = useState(
    JSON.parse(localStorage.getItem("deliveryLocation"))
  );

  const [openDeliverLocationPopup, setOpenDeliverLocationPopup] = useState(
    deliveryLocation === null
  );

  useEffect(() => {
    dispatch(merchbuyActions.getRecommendedProducts(100, ""));
  }, [dispatch, openDeliverLocationPopup]);

  const [products, setProducts] = useState(recommendedProducts);
  const [searchValue, setSearchValue] = useState("");
  const [openFilterOptions, setOpenFilterOptions] = useState(false);
  const [sortOptionsOpen, setSortOptionsOpen] = useState(false);
  const [openMoqPopup, setOpenMoqPopup] = useState(false);
  const [openPriceRangePopup, setOpenPriceRangePopup] = useState(false);
  const [sortType, setSortType] = useState("Most Recent");
  const [filterType, setFilterType] = useState("All Products");
  const [moqValue, setMoqValue] = useState("");
  const [priceRange, setPriceRange] = useState({
    minPrice: "",
    maxPrice: "",
  });

  const getLocation = (data) => {
    if (data.state /*&& data.lga*/) {
      setDeliveryLocation(data);
      setOpenDeliverLocationPopup(!openDeliverLocationPopup);
    }
  };

  const setMoq = (data) => {
    if (data.moq) {
      setFilterType("By MOQ");
      setMoqValue(data.moq);
      setOpenMoqPopup(!openMoqPopup);
    }
  };

  const setFilterPrice = (data) => {
    if (data.minPrice && data.maxPrice) {
      setFilterType("BY Price");
      setPriceRange(data);
      setOpenPriceRangePopup(!openPriceRangePopup);
    }
  };

  useEffect(() => {
    recommendedProducts &&
      setProducts(
        recommendedProducts.filter((data) =>
          data.name.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
  }, [searchValue, recommendedProducts]);

  useEffect(() => {
    recommendedProducts &&
      sortProducts(sortType, recommendedProducts, setProducts);
  }, [sortType, recommendedProducts, setProducts]);

  useEffect(() => {
    recommendedProducts &&
      filterProducts(
        filterType,
        recommendedProducts,
        moqValue,
        priceRange,
        setProducts
      );
  }, [filterType, recommendedProducts, moqValue, priceRange, setProducts]);

  return (
    <Fragment>
      <DesktopBackgroundLayout>
        <SearchHeader
          title={"Recommended pro..."}
          right={"56px"}
          sticky
          placeholder={"Search for products..."}
          handleSearch={setSearchValue}
        >
          <Cart />
        </SearchHeader>
        <Main>
          <FilterAndSortSection top={"14px"}>
            <SortFilterCell
              justifyContent={"center"}
              color={"#212c3d"}
              width={"100%"}
              border={"1px solid #f2f5fa"}
              onClick={() => setSortOptionsOpen(!sortOptionsOpen)}
            >
              <SortIcon />
              {sortType}
            </SortFilterCell>
            <SortFilterCell
              justifyContent={"center"}
              color={"#212c3d"}
              width={"100%"}
              border={"1px solid #f2f5fa"}
              onClick={() => setOpenFilterOptions(!openFilterOptions)}
            >
              {filterType}
              <FilterIcon />
            </SortFilterCell>
          </FilterAndSortSection>
          <Container>
            <ListProducts
              data={products}
              size={{ width: "167px", height: "167px" }}
            />
          </Container>

          <Space height="50px" />
        </Main>
        <OptionsPopupDialog
          open={sortOptionsOpen}
          title={"Sort"}
          cancel={() => {
            setSortType("Most Recent");
            setSortOptionsOpen(!sortOptionsOpen);
          }}
          items={[
            {
              Icon: MostRecent,
              title: "Most Recent",
              click: () => {
                setSortOptionsOpen(!sortOptionsOpen);
                sortType !== "Most Recent" && setSortType("Most Recent");
              },
            },
            {
              Icon: Oldest,
              title: "Oldest",
              click: () => {
                setSortOptionsOpen(!sortOptionsOpen);
                sortType !== "Oldest" && setSortType("Oldest");
              },
            },
            {
              Icon: Ascending,
              title: "Price (High - Low)",
              click: () => {
                setSortOptionsOpen(!sortOptionsOpen);
                sortType !== "Highest Price" && setSortType("Highest Price");
              },
            },
            {
              Icon: Descending,
              title: "Price (Low - High)",
              click: () => {
                setSortOptionsOpen(!sortOptionsOpen);
                sortType !== "Lowest Price" && setSortType("Lowest Price");
              },
            },
          ]}
        />
        <OptionsPopupDialog
          open={openFilterOptions}
          title={"Filter"}
          cancel={() => {
            setFilterType("All Products");
            setOpenFilterOptions(!openFilterOptions);
          }}
          items={[
            {
              Icon: AllOrderSVG,
              title: "All Products",
              click: () => {
                setOpenFilterOptions(!openFilterOptions);
                filterType !== "All Products" && setFilterType("All Products");
              },
            },
            {
              Icon: PriceSVG,
              more: true,
              title: "Price",
              click: () => {
                setOpenFilterOptions(!openFilterOptions);
                setOpenPriceRangePopup(!openPriceRangePopup);
              },
            },
            {
              Icon: MoqSVG,
              more: true,
              title: "Minimum Order Quantity",
              click: () => {
                setOpenFilterOptions(!openFilterOptions);
                setOpenMoqPopup(!openMoqPopup);
              },
            },
          ]}
        />
        <MoqPopup
          open={openMoqPopup}
          setMoq={setMoq}
          cancel={() => {
            setOpenMoqPopup(!openMoqPopup);
          }}
        />
        <PriceRangePopup
          open={openPriceRangePopup}
          cancel={() => {
            setOpenPriceRangePopup(!openPriceRangePopup);
          }}
          setFilterPrice={setFilterPrice}
        />
        {openDeliverLocationPopup && (
          <DeliveryLocationPopup
            open={openDeliverLocationPopup}
            getLocation={getLocation}
            cancel={() => {
              setOpenDeliverLocationPopup(!openDeliverLocationPopup);
            }}
          />
        )}
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default MerchbuyRecommendedProducts;
