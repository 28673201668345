import React, { useState } from "react";

import styled from "styled-components";

import { RippleButton } from "../../../../../components";
import { colors } from "../../../../../styles";
import { ReactComponent as ArrowDown } from "../assets/arrowDown.svg";

const DateWrapper = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  letter-spacing: -0.01em;
  text-align: left;
`;

const CalendarWrapper = styled.div`
  padding: 0 16px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 4px 0 24px 0;
`;

const DaysWrapper = styled.div`
  display: flex;
  justify-content: ${({ week }) => (week ? "space-between" : "flex-start")};
  margin: 32px 5px 41px 5px;
  flex-wrap: wrap;
`;

const DaysInnerWrapper = styled.div`
  width: 45px;
  height: 43px;
  padding: 15px;
  background: ${({ active }) => (active ? colors.deepBlue : "transparent")};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DaysOfTheWeekWrapper = styled.div`
  width: 106px;
  height: 41px;
  padding: 0 9px 0 9px;
  background: ${({ active }) => (active ? colors.deepBlue : "transparent")};
  border: ${({ active }) => (active ? "none" : "1px solid #E2E9F0")};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0;
  border-radius: 4px;
`;

const Day = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: ${({ active }) => (active ? colors.white : colors.boldDark)};
`;

const DayOfTheWeek = styled.div`
  font-size: 14px;
  line-height: 21px;
  color: ${({ active }) => (active ? colors.white : colors.lightDark)};
`;

const TimeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 32px 5px 41px 0;
  flex-wrap: wrap;
  margin-top: 4px;
`;

const TimeInnerWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;

const TimeTabWrapper = styled.div`
  background: ${colors.gray4};
  border-radius: 12px;
  width: 95px;
  height: 40px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const TimeTab = styled.div`
  width: 39px;
  height: 30px;
  background: ${({ active }) => (active ? colors.white : "transparent")};
  box-shadow: ${({ active }) =>
    active ? "1px 1px 4px rgba(0, 0, 0, 0.05)" : null};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  cursor: pointer;
`;

const TimeType = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: ${colors.themeTextColor1};
`;

const TimeSeperator = styled.div`
  margin: 0 12px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: ${colors.boldDark};
`;

const TimeSelect = styled.div`
  width: 80px;
  height: 44px;
  background: #fcfcfc;
  border: 1px solid #e2e9f0;
  border-radius: 4px;
  color: #718596;
  display: flex;
  align-items: center;
  justify-content: center;

  .select {
    opacity: 0;
    width: 7px;
    position: relative;
    left: 10px;
  }
`;

const TimeOption = styled.div`
  line-height: 17px;
  color: #718596;
  margin-right: 2.67px;
`;

const ButtonSpace = styled.div`
  width: 20px;
`;

const daysOfTheMonth = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28,
];
const hoursOfTheDay = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
];

const minutesOfTheDay = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41,
  42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
];

const FrequencyReminder = ({
  body,
  setBody,
  timeTypes,
  setTimeHour,
  timeHour,
  setTimeMinute,
  timeMinute,
  activeTimeType,
  setActiveTimeType,
  daysOfTheWeek,
  reminderStatus,
}) => {
  const [types] = useState(["Monthly", "Weekly"]);

  const [activeType, setActiveType] = useState(
    reminderStatus?.active && !reminderStatus?.oneTime
      ? reminderStatus?.frequency === "MONTHLY"
        ? 0
        : 1
      : 0
  );
  const [activeDayOfTheMonth, setActiveDayOfTheMonth] = useState("");
  const [activeDayOfTheWeek, setActiveDayOfTheWeek] = useState("");

  const handleSetActiveType = (index) => {
    setActiveType(index);
    setBody({
      ...body,
      frequency: index === 0 ? "MONTHLY" : "WEEKLY",
    });
  };

  const handleDayOfTheWeek = (index, day) => {
    setActiveDayOfTheWeek(index);
    setBody({
      ...body,
      day,
    });
  };

  const handleDaysOfTheMonth = (index, day) => {
    setActiveDayOfTheMonth(index);
    setBody({
      ...body,
      day,
    });
  };
  return (
    <div>
      <CalendarWrapper>
        <DateWrapper>Frequency</DateWrapper>
        <ButtonWrapper>
          {types.map((type, index) => (
            <>
              <RippleButton
                top="0"
                weight={activeType === index ? "bold" : "500"}
                color={activeType === index ? colors.white : colors.subGrey}
                border={activeType === index ? "none" : "1px solid #E2E9F0"}
                onClick={() => handleSetActiveType(index)}
                backgroundColor={
                  activeType === index ? colors.deepBlue : colors.white
                }
                height="33px"
                key={index}
              >
                {type}
              </RippleButton>
              {index === 0 && <ButtonSpace />}
            </>
          ))}
        </ButtonWrapper>
        <DateWrapper>
          {activeType === 0 ? "Days of the Month" : "Day of the Week"}
        </DateWrapper>
        <DaysWrapper week={activeType === 1}>
          {activeType === 0 ? (
            <>
              {daysOfTheMonth.map((day, index) => (
                <DaysInnerWrapper
                  active={activeDayOfTheMonth === index}
                  onClick={() => handleDaysOfTheMonth(index, day)}
                  key={index}
                >
                  <Day active={activeDayOfTheMonth === index}>{day}</Day>
                </DaysInnerWrapper>
              ))}
            </>
          ) : (
            <>
              {daysOfTheWeek.map((day, index) => (
                <DaysOfTheWeekWrapper
                  active={activeDayOfTheWeek === index}
                  onClick={() => handleDayOfTheWeek(index, day.id)}
                  key={index}
                >
                  <DayOfTheWeek active={activeDayOfTheWeek === index}>
                    {day.title}
                  </DayOfTheWeek>
                </DaysOfTheWeekWrapper>
              ))}
            </>
          )}
        </DaysWrapper>
        <DateWrapper>Time</DateWrapper>
        <TimeWrapper>
          <TimeInnerWrapper>
            <TimeSelect>
              <TimeOption>{!timeHour ? "HH" : timeHour}</TimeOption>
              <select
                onChange={(e) => setTimeHour(e.target.value)}
                className="select"
              >
                {hoursOfTheDay.map((hour, index) => (
                  <option key={index} value={hour}>
                    {hour}
                  </option>
                ))}
              </select>
              <ArrowDown />
            </TimeSelect>
            <TimeSeperator>:</TimeSeperator>
            <TimeSelect>
              <TimeOption>{!timeMinute ? "MM" : timeMinute}</TimeOption>
              <select
                onChange={(e) => setTimeMinute(e.target.value)}
                className="select"
              >
                {minutesOfTheDay.map((minute, index) => (
                  <option key={index} value={minute}>
                    {minute}
                  </option>
                ))}
              </select>
              <ArrowDown />
            </TimeSelect>
          </TimeInnerWrapper>
          <TimeTabWrapper>
            {timeTypes.map((type, index) => (
              <TimeTab
                onClick={() => setActiveTimeType(type)}
                active={activeTimeType === type}
                key={index}
              >
                <TimeType>{type}</TimeType>
              </TimeTab>
            ))}
          </TimeTabWrapper>
        </TimeWrapper>
      </CalendarWrapper>
    </div>
  );
};

export default FrequencyReminder;
