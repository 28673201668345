import React, { Fragment, useState } from "react";

import moment from "moment";
// import { autoSign } from '../../../utils/inputs/autoFormat';
import { bool, func, any } from "prop-types";
import DatePicker from "react-datepicker";
import styled from "styled-components";

import { Overlay, ActionBlock } from "../../../containers/OverlayContainer";
import {
  PopUp,
  InfoMessage,
  CancelButton,
  ConfirmButton,
  PopUpContent,
  PopUpHeader,
} from "../common";
import "react-datepicker/dist/react-datepicker.css";

const InputRange = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
`;
const Pop = styled(PopUp)`
  height: 100% !important;
  margin-top: 24px;
`;

const Input = styled(DatePicker)`
  width: 80%;
  height: 48px;
  background: #f2f5fa;
  border-radius: 8px;
  padding-right: 8px;
  border: none;
  color: #56636d;
  font-size: 14px;
`;

const Space = styled.div`
  height: ${({ height }) => height || "10px"};
`;

export const SpeficDatePopup = ({
  open,
  setOpen,
  selectedDate,
  setSelectedDate,
  filterParam,
  setSelectedFilter,
}) => {
  const [currentDate, setCurrenDate] = useState(new Date());

  return (
    <Fragment>
      {open && (
        <Overlay
          onClick={() => setOpen(!open)}
          bgc={"rgba(0,0,0,0.4)"}
          zIndex="99999"
        />
      )}
      <Pop open={open} zIndex="100000">
        <PopUpContent>
          <PopUpHeader align={"center"}>Specific Day</PopUpHeader>
          <Space height={"130px"} />
          <InfoMessage top={"24px"} align={"center"}>
            Please enter date.
          </InfoMessage>
          <InputRange>
            <Input
              selected={currentDate}
              dateFormat="dd-MM-yyyy"
              onChange={(date) => setCurrenDate(date)}
            />
          </InputRange>
          <ActionBlock direction={"row"} top={"16px"}>
            <ConfirmButton
              onClick={() => {
                setSelectedDate(moment(currentDate).format("YYYY-MM-DD"));
                setOpen(!open);
                setSelectedFilter(filterParam);
              }}
              disabled={!currentDate}
            >
              Okay
            </ConfirmButton>
            <CancelButton type={"button"} onClick={() => setOpen(!open)}>
              Cancel
            </CancelButton>
          </ActionBlock>
        </PopUpContent>
      </Pop>
    </Fragment>
  );
};

SpeficDatePopup.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
  selectedDate: any,
  filterParam: any,
  setSelectedDate: func.isRequired,
};
