import { USER_LOGOUT } from "../../user/constants";

import {
  LOADING_TRANSACTIONS,
  TRANSACTIONS_LOADED,
  TRANSACTIONS_LOAD_ERROR,
  SAVE_TRANSACTIONS,
  CAN_RESET_TRANSACTION_PIN,
  SAVE_PAYMENT_TRANSACTIONS,
} from "./constants";

const initialState = {
  isLoading: false,
  errorMsg: "",
  transactionsList: [],
  canResetPin: false,
  paymentTransactionsList: [],
};

export default function transactionsReducer(state = initialState, action) {
  switch (action.type) {
    case LOADING_TRANSACTIONS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case TRANSACTIONS_LOADED: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case TRANSACTIONS_LOAD_ERROR: {
      return {
        ...state,
        isLoading: false,
        errorMsg: action.payload,
      };
    }

    case SAVE_TRANSACTIONS: {
      return {
        ...state,
        transactionsList: action.payload,
      };
    }

    case CAN_RESET_TRANSACTION_PIN: {
      return {
        ...state,
        canResetPin: action.payload,
      };
    }

    case SAVE_PAYMENT_TRANSACTIONS: {
      return {
        ...state,
        paymentTransactionsList: action.payload,
      };
    }

    case USER_LOGOUT: {
      return {
        isLoading: false,
        errorMsg: "",
        transactionsList: [],
        canResetPin: false,
      };
    }

    default: {
      return state;
    }
  }
}
