import React, { Fragment, useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as AgentsSVG } from "../../../../assets/agents.svg";
import { ReactComponent as ArrowIcon } from "../../../../assets/arrow.svg";
import UserIcon from "../../../../assets/user.svg";
import {
  UserAvatar,
  TopHeader,
  ApprovalStatusPopup,
  MenuList,
  RippleButton,
  IntroductionPopup,
} from "../../../../components";
import {
  Message,
  // SecondaryText,
  // SubTitle,
} from "../../../../containers/MessageContainer";
import { ScreenContainer } from "../../../../containers/ScreenContainer";
// import { hideBalance } from "../../../../redux/ducks/account/wallet/actions";
import { logoutUser } from "../../../../redux/ducks/user/actions";
import { colors } from "../../../../styles";
import History from "../../../../utils/History";
// import { SETTINGS_MERCHANT_UPGRADE } from "../../../../utils/mix-panel/constants";
// import { mixPanel } from "../../../../utils/mix-panel/mixPanel";
import DesktopBackgroundLayout from "../../../DesktopBackgroundLayout";

// import BankDataIcon from './assets/bank_data.svg';
// import HideWalletBalanceIcon from "./assets/hide_wallet_balance.svg";
import IdCard from "./assets/id_card.svg";
// import { ReactComponent as ConvertMerchantIcon } from "./assets/joinJet.svg";
import KYCIcon from "./assets/kyc.svg";
import { ReactComponent as LogoutIcon } from "./assets/logout.svg";
// import NextArrowIcon from "./assets/nextArrow.svg";
import PinIcon from "./assets/pin.svg";
import PrivacyPolicyIcon from "./assets/privacy_policy.svg";
import ReferralIcon from "./assets/referral.svg";
import TermsAndConditionsIcon from "./assets/terms_and_conditions.svg";
import TermsInfoOverlay from "./termsInfoOverlay";

const Menu = styled.section`
  margin: 18px 0;

  & > ul:not(:last-of-type) {
    margin-bottom: 32px;
  }
`;

const ArrowForward = styled(ArrowIcon)`
  position: absolute;
  right: 8px;
  top: 22px;
  color: #fff;
`;
const LogoutButton = styled(RippleButton)`
  text-align: left;
  color: ${colors.themeTextColor3};
  font-weight: 500;
  background-color: ${colors.white};
  border-radius: unset;
  padding: 5px;
  line-height: 25px;
  margin-top: 0;
  border-top: 1px solid ${colors.border.bottom};
  border-bottom: 1px solid ${colors.border.bottom};

  & > svg {
    position: relative;
    top: 5px;
    margin-right: 4px;
  }
`;

// const UpgradeBox = styled.div`
//   display: flex;
//   background: #227eff;
//   border-radius: 8px;
//   margin: 16px;
//   align-items: center;
//   box-shadow: 0px 5px 28px rgba(34, 126, 255, 0.3);
// `;
// const UgradeBoxLink = styled(RippleButton)`
//   position: relative;
//   display: flex;
//   flex-wrap: nowrap;
//   flex-direction: row;
//   width: 100%;
//   height: auto;
//   background: inherit;
//   padding: 15px 10px 5px;
//   margin: 0;
//   overflow: hidden;
// `;
// const NextArrow = styled.img`
//   position: absolute;
//   right: 15px;
//   top: 42px;
// `;
// const AgentMessageBoxLeftSide = styled.div`
//   position: relative;
//   margin-top: 6px;
//   text-align: left;
//   left: -10px;
// `;
// const ImageWrapper = styled.div`
//   position: relative;
//   width: auto;
//   left: -12px;
// `;

const UserBox = styled.section`
  display: flex;
  width: 100%;
  border: 1px solid ${colors.gray4};
  border-radius: 8px;
  margin: 23px 0px 30px 0px;
  padding: 16px;
  flex-direction: row;
`;

const UserType = styled.span`
  margin-left: ${({ margin }) => margin || null};
  margin-top: ${({ mTop }) => mTop || null};
  margin-bottom: ${({ mBottom }) => mBottom || null};
  background-color: ${({ backgroundColor }) =>
    backgroundColor || colors.setting.userTypeBg};
  border-radius: 4px;
  padding: 4px 16px;
  display: inline-block;
`;

const UserInfo = styled.div`
  align-items: left;
  margin-left: 8px;
`;
const LogoutBtnWrap = styled.div`
  position: relative;
  padding: 0 15px;
  text-align: left;
  margin-top: 30px;

  & svg:nth-child(2) {
    position: absolute;
    top: 18px;
    right: 20px;
    margin-right: 4px;
    z-index: 10;
  }
`;
const LogoutText = styled.span`
  margin: auto 16px;
`;
// const UMTitle = styled(SubTitle)`
//   color: ${colors.white};
//   font-weight: bold;
//   font-size: 16px;
//   line-height: 24px;
// `;

const AgentInfo = styled.div``;

const AccountSettings = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  // const balanceHidden = useSelector(
  //   (state) => state.account.wallet.hideBalance
  // );
  const [agentState] = useState(user.agentState ? user.agentState : "PENDING");
  const [confirmUpgrade, setConfirmUpgrade] = useState(false);
  const [openTermsOverlay, setOpenTermsOverlay] = useState(false);
  const [statusPopup, setStatusPopup] = useState(false);

  const [transactionPinOption, setTransactionPinOption] = useState([]);

  useEffect(() => {
    if (user.hasTransactionPin) {
      setTransactionPinOption([
        {
          name: "Change Wallet PIN",
          icon: PinIcon,
          link: "/user/update_transaction_pin",
        },
      ]);
    }
  }, [user]);

  return (
    <Fragment>
      <DesktopBackgroundLayout>
        <TopHeader title={"Account Settings"} />
        <ScreenContainer paddingBottom={"0px"}>
          <UserBox>
            <UserAvatar width={"48px"} height={"48px"} avatar={user.avatar} />
            <UserInfo>
              <Message
                top={"3px"}
                weight={"bold"}
                align={"left"}
                size={"16px"}
                color={colors.themeTxtColor10}
              >
                {user.firstName} {user.lastName}
              </Message>
              <Message
                top={"0"}
                weight={"400"}
                align={"left"}
                size={"12px"}
                color={colors.themeSubColor1}
              >
                {user.msisdn}
              </Message>
              {user.role === "ROLE_USER" ? (
                <UserType mTop={"12px"} mBottom={"5px"}>
                  <Message
                    top={"0px"}
                    weight={"500"}
                    align={"left"}
                    size={"10px"}
                    color={colors.setting.userTypeTextColor}
                  >
                    {"Merchant Account"}
                  </Message>
                </UserType>
              ) : (
                <AgentInfo>
                  <UserType mTop={"12px"} mBottom={"5px"}>
                    <Message
                      top={"0px"}
                      weight={"500"}
                      align={"left"}
                      size={"10px"}
                      color={colors.setting.userTypeTextColor}
                    >
                      {"Agent Account"}
                    </Message>
                  </UserType>
                  {/* {(tier && (
                                    <UserType margin={"8px"} backgroundColor={colors.setting.agentTier1Bg}>
                                        <Message top={"0px"} weight={"500"} align={"left"} size={"10px"} 
                                            color={colors.setting.agentTier1TextColor}>
                                            {(tier && tier === "TIER_2") ? "Tier 2" : "Tier 1"}
                                        </Message>
                                    </UserType>
                                ))} */}
                </AgentInfo>
              )}
            </UserInfo>
          </UserBox>
        </ScreenContainer>

        <Menu>
          {user.role === "ROLE_USER" ? (
            <MenuList
              title={"Account"}
              options={[
                {
                  name: "Personal Details",
                  icon: UserIcon,
                  link: "/user/update_data",
                },
                //  {
                //      name: "Next Of Kin",
                //      icon: NextOfKinIcon,
                //      link: "/user/account_next_of_kin"
                //  }
              ]}
            />
          ) : agentState === "APPROVED" ? (
            <MenuList
              title={"Account"}
              options={[
                {
                  name: "Personal Details",
                  icon: UserIcon,
                  link: "/user/update_data",
                },
                {
                  name: "ID Card",
                  icon: IdCard,
                  link: "/user/id_card",
                },
                {
                  name: "KYC",
                  icon: KYCIcon,
                  link: "/user/account_kyc",
                },
                //  {
                //      name: "Next Of Kin",
                //      icon: NextOfKinIcon,
                //      link: "/user/account_next_of_kin"
                //  },
                // {
                //     name: 'Bank Data',
                //     icon: BankDataIcon,
                //     link: '/user/account_kyc_bank_data',
                // },
              ]}
            />
          ) : (
            <MenuList
              title={"Account"}
              options={[
                {
                  name: "Personal Details",
                  icon: UserIcon,
                  link: "/user/update_data",
                },
                {
                  name: "KYC",
                  icon: KYCIcon,
                  link: "/user/account_kyc",
                },
                //  {
                //      name: "Next Of Kin",
                //      icon: NextOfKinIcon,
                //      link: "/user/account_next_of_kin"
                //  },
                // {
                // name: 'Bank Data',
                // icon: BankDataIcon,
                // link: '/user/account_kyc_bank_data',
                // },
              ]}
            />
          )}
          <MenuList
            title={"Privacy and security"}
            options={[
              {
                name: "Change Login PIN",
                icon: PinIcon,
                link: "/user/password_update",
              },
              ...transactionPinOption,
              // {
              //     name: "Change Wallet PIN",
              //     icon: PinIcon,
              //     link: "/user/update_transaction_pin"
              // },
              // {
              //   name: "Hide Wallet Balance",
              //   subText: "Hide your balance on the home screen",
              //   icon: HideWalletBalanceIcon,
              //   checkStatus: balanceHidden,
              //   switchStatus: () => {
              //     if (balanceHidden === true) {
              //       dispatch(hideBalance(!balanceHidden));
              //     } else {
              //       dispatch(hideBalance(!balanceHidden));
              //     }
              //   },
              // },
            ]}
          />
          {/* <MenuList
                    title={"Notifications"}
                    options={[
                        {
                            name: "Push Notifications",
                            icon: PushNotificationsIcon,
                            checkStatus: pushNotifications,
                            switchStatus: setPushNotifications
                        },
                        {
                            name: "SMS Notifications",
                            icon: SmsNotificationsIcon,
                            checkStatus: smsNotifications,
                            switchStatus: setSmsNotifications
                        }
                    ]}
                /> */}
          {/* {(process.env.REACT_APP_ENV_NAME === "release" || process.env.REACT_APP_ENV_NAME==="development") && (
                    <MenuList 
                        title={"Payment Methods & Bank Settings"}
                        options={[
                            {
                                name: "Debit Cards",
                                icon: DebitCardIcon,
                                link: "/user/wallet_cards_all",
                                action: () => dispatch(switchWalletUsageMode("manage"))
                            },
                            {
                                name: "Withdrawal Settings",
                                icon: BankDataIcon,
                                link: "/user/wallet_withdrawal_settings",
                                action: () => dispatch(switchWalletUsageMode("manage"))
                            }
                        ]}
                    />
                )} */}

          <MenuList
            title={"More Information"}
            options={[
              // {
              //     name: "Download account statement",
              //     icon: DownloadAccountStatementIcon,
              //     link: "/"
              // },
              // {
              //     name: "Help & Support",
              //     icon: HelpAndSupportIcon,
              //     link: "/help"
              // },
              {
                name: "Privacy Policy",
                icon: PrivacyPolicyIcon,
                link: "/privacyPolicy",
              },
              {
                name: "Terms and Conditions",
                icon: TermsAndConditionsIcon,
                link: "/termsAndConditions",
              },
              {
                name: "My Referring User",
                icon: ReferralIcon,
                link: "/actions/merchant_agent",
              },
            ]}
          />
          {/* {user.role === "ROLE_USER" && (
            <UpgradeBox>
              <UgradeBoxLink
                onClick={() => {
                  setConfirmUpgrade(!confirmUpgrade);
                  mixPanel.track(SETTINGS_MERCHANT_UPGRADE, {
                    Time: new Date().toLocaleDateString(),
                  });
                }}
              >
                <ImageWrapper>
                  <ConvertMerchantIcon></ConvertMerchantIcon>
                </ImageWrapper>
                <AgentMessageBoxLeftSide>
                  <UMTitle>Become an Agent</UMTitle>
                  <SecondaryText
                    weight={"400"}
                    margin={"0px"}
                    top={"5px"}
                    color={"rgba(255, 255, 255, 0.8)"}
                    lineHeight={"18px"}
                  >
                    Convert your account to join our agent network and earn
                    commisions.
                  </SecondaryText>
                </AgentMessageBoxLeftSide>
                <NextArrow src={NextArrowIcon} />
              </UgradeBoxLink>
            </UpgradeBox>
          )} */}
          <LogoutBtnWrap>
            <LogoutButton onClick={() => dispatch(logoutUser())}>
              <LogoutIcon /> <LogoutText>Logout</LogoutText>
            </LogoutButton>
            <ArrowForward />
          </LogoutBtnWrap>
        </Menu>
        {/* {(role === "ROLE_AGENT" && tier && tier === "TIER_1") && (    

                <UpgradeBox>         
                    {((agencyBankingState === 'PENDING' || agencyBankingState === 'REJECTED') ? (
                        <AgentMessageBoxLeftSide>
                            <SubTitle>
                               {agencyBankingState === "PENDING"
                                    ? "Your application is in review."
                                    : "Your application was rejected"
                                }
                                </SubTitle>
                            <SecondaryText weight={"400"}>Your application for tier-2 has been received and our team is currently reviewing it.</SecondaryText>
                            <RippleButton 
                                style={{ 
                                     width: 'min-content', 
                                     paddingLeft: '24px',
                                     paddingRight: '24px',
                                     whiteSpace: 'nowrap'
                                 }} 
                                 onClick={() => {
                                    History.push("/user/account_agency_banking_signup")
                                }}
                            >See Status</RippleButton>
                        </AgentMessageBoxLeftSide>
                       ) : (
                        <AgentMessageBoxLeftSide>
                            <SubTitle>Upgrade Account To Tier 2</SubTitle>
                            <SecondaryText weight={"400"}>Tier 2 agent account gives you the ability to offer agency banking services.</SecondaryText>
                            <RippleButton 
                                 style={{ 
                                    width: 'min-content', 
                                    paddingLeft: '24px',
                                    paddingRight: '24px',
                                }} 
                                onClick={() => {
                                    if (agentState === "APPROVED"){
                                        setOpenTermsOverlay(!openTermsOverlay)
                                    } else {
                                        setStatusPopup(true);
                                    }
                                }}
                            >Upgrade</RippleButton>
                        </AgentMessageBoxLeftSide>
                    ))}
                    <ImageWrapper>
                        <JetSVG></JetSVG>
                    </ImageWrapper>
                </UpgradeBox>
            )}            */}
        <ApprovalStatusPopup
          open={statusPopup}
          cancel={() => setStatusPopup(!statusPopup)}
          status={agentState}
        />
        <TermsInfoOverlay
          open={openTermsOverlay}
          cancel={() => {
            setOpenTermsOverlay(!openTermsOverlay);
          }}
          confirm={() => {
            setOpenTermsOverlay(!openTermsOverlay);
            History.push("/user/account_agency_banking_signup");
          }}
        />
        <IntroductionPopup
          open={confirmUpgrade}
          cancel={() => {
            setConfirmUpgrade(!confirmUpgrade);
          }}
          confirm={() => {
            setConfirmUpgrade(!confirmUpgrade);
            history.push("/actions/um_agent_region_selection");
          }}
          title={"Agent"}
          Logo={AgentsSVG}
          logoSpacing={"30px"}
          withConfirmation={true}
          confirmText={"Continue"}
          message={
            "Upgrade to an agent and earn money from merchants you sign up and services you offer."
          }
        />
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default AccountSettings;
