import React, { Fragment, useState } from "react";

import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { TopHeader, RippleButton, PasswordGroup } from "../../../../components";
import { Message, Title } from "../../../../containers/MessageContainer";
import {
  ScreenContainer,
  FloatingBottomSection,
} from "../../../../containers/ScreenContainer";
import { verifyAppPassword } from "../../../../redux/ducks/account/transactions/actions";
import { colors } from "../../../../styles";
import { getInputValues } from "../../../../utils/inputs/getInputValues";
import DesktopBackgroundLayout from "../../../DesktopBackgroundLayout";

import { PinCreationValidationSchema } from "./PinCreationValidationSchema";

const ForgotTransactionPin = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [value, setValue] = useState("");
  const isLoading = useSelector(
    (state) => state.account.transactions.isLoading
  );

  return (
    <Fragment>
      <DesktopBackgroundLayout>
        <TopHeader title={"Enter Pin"} noBorderBottom />
        <ScreenContainer>
          <Title
            top={"40px"}
            textAlign="left"
            size="16px"
            weight="700"
            color={colors.themeTxtColor10}
          >
            Enter Login PIN
          </Title>

          <Message top={"8px"}>
            Kindly provide your login PIN for confirmation
          </Message>

          <Formik
            initialValues={{
              pinCode: "",
            }}
            validationSchema={PinCreationValidationSchema}
            onSubmit={async (values) => {
              const res = await dispatch(verifyAppPassword(values));
              if (res?.status) {
                history.push("/user/wallet_index/forgot-transaction-otp");
              } else {
                toast.error(res.message);
              }
            }}
          >
            {({ valid, touched, errors, setFieldValue, values }) => (
              <Form>
                <Message
                  top={"45px"}
                  bottom={"0px"}
                  color={"#718596"}
                  size={"12px"}
                >
                  Enter PIN
                </Message>
                <PasswordGroup
                  count={6}
                  startIndex={1}
                  type={"password"}
                  valid={valid}
                  errors={errors}
                  name={"pinCode"}
                  touched={touched}
                  align={"center"}
                  enteredValue={value}
                  handleChange={(event) => {
                    setValue(event.target.value);
                    setFieldValue("pinCode", getInputValues("pinCode"));
                  }}
                />

                <FloatingBottomSection>
                  <RippleButton
                    type="submit"
                    disabled={values.pinCode.length < 6 || isLoading}
                  >
                    Continue
                  </RippleButton>
                </FloatingBottomSection>
              </Form>
            )}
          </Formik>
        </ScreenContainer>
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default ForgotTransactionPin;
