import React from "react";

import styled from "styled-components";

import DesktopBackgroundLayout from "../../views/DesktopBackgroundLayout";
import loaderSvg from "../loader/assets/loader_animation.svg";

import Merchant from "./assets/merchant_onboarding.png";

const LoaderOverlay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 9999;
  background: #ffffff;
`;

const MainOverlay = styled(LoaderOverlay)`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background: #ffffff;
`;

const SearchingLoaderOverlay = styled(LoaderOverlay)`
  left: 25%;
  margin-top: 20px;
`;

const ImageStyle = styled.img`
  margin-top: -20px;
  margin-left: 80px;
`;

const Message = styled.h3`
  text-align: center;
  font-size: ${({ size }) => size || "14px"};
  font-weight: 400;
  color: ${({ color }) => color || "#071827"};
  margin-right: 4px;
  line-height: 21px;
`;

const Searching = styled.div`
  display: flex;
  align-items: bottom;
  margin-top: 24px;
`;

export const Loader = ({ text = "Loading" }) => {
  return (
    <DesktopBackgroundLayout>
      <MainOverlay>
        <img src={loaderSvg} alt="Loading..."></img>
        <Searching>{text && <Message>{text}...</Message>}</Searching>
      </MainOverlay>
    </DesktopBackgroundLayout>
  );
};

export const SearchingLoader = () => {
  return (
    <SearchingLoaderOverlay>
      <img src={loaderSvg} alt="Loading..." width="30" height="30"></img>
      <ImageStyle src={Merchant} alt="Merchant" />
    </SearchingLoaderOverlay>
  );
};
