import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import successBanner from "../../../../../assets/success.gif";
import { Loader, RippleButton, SuccessPage } from "../../../../../components";
import { Title } from "../../../../../containers/MessageContainer";
import { ActionBlock } from "../../../../../containers/OverlayContainer";
import { ScreenContainer } from "../../../../../containers/ScreenContainer";
import {
  getWalletBalance,
  postTopUpTransaction,
} from "../../../../../redux/ducks/account/wallet/actions";
import { colors } from "../../../../../styles";
import { formatPrice } from "../../../../../utils/currency/formatPriceWithComma";
import { getParameterByName } from "../../../../../utils/getUrlParamsByName";
import History from "../../../../../utils/History";
import DesktopBackgroundLayout from "../../../../DesktopBackgroundLayout";
import RatingPopup from "../../../transactions/wallet-transactions/rating-popup";
import failedBanner from "./assets/failed.png";

const ResultBlock = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
`;

const ResultHeader = styled(Title)`
  margin-top: 32px;
  font-size: 16px;
`;

const SmallText = styled.div`
  font-size: 14px;
  color: ${colors.themeTextColor3};
  margin: 10px;
  text-align: center;
`;

const SuccessBanner = styled.img`
  width: 180px;
  height: 180px;
`;

const FailBanner = styled.img`
  width: 139px;
  height: 139px;
`;

const Footer = styled(ActionBlock)`
  position: absolute;
  bottom: 50px;
  width: 90%;
`;

const TransactionSuccessPage = ({
  next,
  nextProps = null,
  success = false,
  type,
  transactionData,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [altLoading, setAltLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [transactionStatus, setTransactionStatus] = useState(true);
  const [ref, setRef] = useState("");
  const [openRating, setOpenRating] = useState(false);
  const transactions = useSelector(
    (state) => state.account.transactions.transactionsList
  );
  const reference = getParameterByName("reference");

  useEffect(() => {
    if (transactionData) {
      setLoading(false);
    } else {
      const linkingReference = getParameterByName("linkingreference");
      const code = getParameterByName("code");
      const message = getParameterByName("message");

      const payload = { code, linkingReference, message, reference };
      const confirmTransaction = async () => {
        const isSuccessful = await dispatch(postTopUpTransaction(payload));
        if (isSuccessful) {
          setRef(isSuccessful);
          setTransactionStatus(true);
          setOpenRating(!openRating);
          setLoading(false);
          dispatch(getWalletBalance());
        } else {
          setTransactionStatus(false);
          setLoading(false);
        }
      };
      confirmTransaction();
    }
    // eslint-disable-next-line
  }, [dispatch, transactionData]);

  if (loading) return <Loader text="Processing" />;
  return (
    <DesktopBackgroundLayout>
      <ScreenContainer>
        <ResultBlock>
          {success ? (
            <SuccessBanner src={successBanner} />
          ) : (
            <FailBanner src={failedBanner} />
          )}
          <ResultHeader>
            {success ? "Successful transaction" : "Failed transaction"}
          </ResultHeader>
          {success ? (
            <SmallText>
              {`${formatPrice(
                transactionData?.amount || 0
              )} was successful sent to ${
                type === "bank"
                  ? transactionData?.account_name
                  : transactionData?.lastName + " " + transactionData?.firstName
              } `}
            </SmallText>
          ) : (
            <SmallText>
              {`${formatPrice(transactionData?.amount || 0)} to ${
                type === "bank"
                  ? transactionData?.account_name
                  : transactionData?.lastName + " " + transactionData?.firstName
              } failed`}
            </SmallText>
          )}
          <Footer>
            {success ? (
              <RippleButton onClick={() => History.push("/user/wallet_index")}>
                Okay
              </RippleButton>
            ) : (
              <>
                <RippleButton
                  top={"45px"}
                  disabled={altLoading}
                  onClick={async () => {
                    setAltLoading(true);
                    await next(nextProps);
                    setAltLoading(false);
                  }}
                >
                  Try again
                </RippleButton>
                <RippleButton
                  top={"20px"}
                  style={{
                    backgroundColor: `rgba(87,159,215,.4)`,
                    color: `rgb(59, 120, 220)`,
                    paddingLeft: 20,
                    paddingRight: 20,
                    opacity: `40%`,
                  }}
                  onClick={() => {
                    History.push("/user/wallet_index");
                  }}
                >
                  Cancel
                </RippleButton>
              </>
            )}
          </Footer>
        </ResultBlock>
      </ScreenContainer>

      {!transactionData && (
        <SuccessPage
          title={`Top Up ${transactionStatus ? "Successful" : "Failed"}`}
          subtitle={
            transactionStatus
              ? "Your wallet top up was successful"
              : "We couldn't complete your transaction. Kindy try again"
          }
          showDetailButton={transactionStatus}
          onDoneClick={() => history.push("/user/wallet_index")}
          doneText={transactionStatus ? "Done" : "Try Again"}
          successfull={transactionStatus}
          onDetailClick={async () => {
            history.push(`/user/wallet_transaction_details/${ref}`);
          }}
        />
      )}

      {(transactions.length === 1 || transactions.length % 10 === 0) && (
        <div style={{ visibility: openRating ? "visible" : "hidden" }}>
          <RatingPopup refId={ref} setOpen={() => setOpenRating(!openRating)} />
        </div>
      )}
    </DesktopBackgroundLayout>
  );
};

export default TransactionSuccessPage;
