import React, { Fragment, useState } from "react";

import moment from "moment";
import { bool, func, any } from "prop-types";
import DatePicker from "react-datepicker";
import styled from "styled-components";

import { Overlay, ActionBlock } from "../../../containers/OverlayContainer";
import { colors } from "../../../styles";
import {
  PopUp,
  InfoMessage,
  CancelButton,
  ConfirmButton,
  PopUpContent,
  PopUpHeader,
} from "../common";
import "react-datepicker/dist/react-datepicker.css";

const InputRange = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
`;

const Pop = styled(PopUp)`
  height: 100% !important;
  margin-top: 24px;
`;

const RangeSeparator = styled.div`
  width: 40px;
  height: 1px;
  color: ${colors.border.default};
`;

const Divider = styled.div`
  height: 1px;
  background: #c0c0c0;
  width: 17px;
  margin: 0 auto;
`;

const RangeInput = styled(DatePicker)`
  width: 139px;
  height: 48px;
  background: #f2f5fa;
  border-radius: 8px;
  padding-right: 8px;
  border: none;
  color: #56636d;
  font-size: 14px;
`;

const Space = styled.div`
  height: ${({ height }) => height || "10px"};
`;

export const DateRangePopup = ({
  open,
  setOpen,
  startDate,
  endDate,
  setStartDate,
  filterParam,
  setEndDate,
  setSelectedFilter,
  specificDate,
}) => {
  const [currentStartDate, setCurrentStartDate] = useState(new Date());
  const [currentEndDate, setCurrentEndDate] = useState(new Date());

  return (
    <Fragment>
      {open && (
        <Overlay
          onClick={() => setOpen(!open)}
          bgc={"rgba(0,0,0,0.4)"}
          zIndex="99999"
        />
      )}
      <Pop open={open} zIndex="100000">
        <PopUpContent>
          <PopUpHeader align={"left"}>Select Date</PopUpHeader>
          <Space height={"130px"} />

          <InfoMessage top={"24px"} align={"left"}>
            {`Please enter date ${!specificDate ? "range" : ""}.`}
          </InfoMessage>
          <InputRange>
            <RangeInput
              selected={currentStartDate}
              dateFormat="dd-MM-yyyy"
              onChange={(date) => setCurrentStartDate(date)}
            />
            <RangeSeparator>
              <Divider />
            </RangeSeparator>
            {!specificDate && (
              <RangeInput
                selected={currentEndDate}
                dateFormat="dd-MM-yyyy"
                onChange={(date) => setCurrentEndDate(date)}
                popperPlacement="top-end"
                popperModifiers={{
                  offset: {
                    enabled: true,
                    offset: "5px, 10px",
                  },
                  preventOverflow: {
                    enabled: true,
                    escapeWithReference: false,
                    boundariesElement: "viewport",
                  },
                }}
              />
            )}
          </InputRange>
          <ActionBlock direction={"row"} top={"16px"}>
            <ConfirmButton
              disabled={!currentStartDate && !currentEndDate}
              onClick={() => {
                setStartDate(moment(currentStartDate).format("YYYY-MM-DD"));
                !specificDate &&
                  setEndDate(moment(currentEndDate).format("YYYY-MM-DD"));
                setOpen(!open);
                setSelectedFilter(filterParam);
              }}
            >
              Okay
            </ConfirmButton>
            <CancelButton type={"button"} onClick={() => setOpen(!open)}>
              Cancel
            </CancelButton>
          </ActionBlock>
        </PopUpContent>
      </Pop>
    </Fragment>
  );
};

DateRangePopup.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
  startDate: any,
  endDate: any,
  filterParam: any,
  setStartDate: func.isRequired,
  setEndDate: func.isRequired,
};
