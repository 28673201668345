import React, { Fragment, useEffect, useState } from "react";

import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import {
  TopHeader,
  RippleButton,
  AmountInputWithLabel,
  TextInputWithLabel,
  FileInput3,
  SelectBox,
  SuccessPage,
} from "../../../../components";
import { ScreenContainer } from "../../../../containers/ScreenContainer";
import { remittanceActions } from "../../../../redux/ducks/applications/remittance/actions";
import { colors } from "../../../../styles";
import { formatPrice } from "../../../../utils/currency/formatPriceWithComma";
import {
  REMITTANCE_SINGLE_ORDER_START,
  REMITTANCE_SINGLE_ORDER_FINISH,
} from "../../../../utils/mix-panel/constants";
import { mixPanel } from "../../../../utils/mix-panel/mixPanel";
import DesktopBackgroundLayout from "../../../DesktopBackgroundLayout";
import {
  OrderNumber,
  Text,
  HeaderSpace,
  PageText,
  PageSubText,
  ContainerDiv,
  FlexFormContainer,
  InputBlock,
} from "../styles";

import { SingleRemitValidationSchema } from "./SingleRemitValidationSchema";

export const RemittanceSingleOrder = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const isLoading = useSelector(
    (state) => state.applications.remittance.isLoading
  );

  const [showSuccessPage, setShowSucessPage] = useState(false);
  const [remittanceStatus, setRemittanceStatus] = useState(false);

  const { orderNumber, outstandingAmount, id, orderId } = location?.state || {};

  const banks = [
    { value: "zenith", label: "Zenith - 1214403902" },
    { value: "gtb", label: "GTB - 0627779713" },
  ];

  const convertToPositive = (amount) => {
    if (amount < 0) {
      return amount * -1;
    }
    return amount;
  };

  const onSubmit = async (values, { setErrors }) => {
    if (Number(values.amount) > Number(convertToPositive(outstandingAmount))) {
      return setErrors({
        amount: "Paid amount is greater than outstanding amount",
      });
    }

    const payload = {
      outstandingOrders: [
        {
          orderId: id || orderId,
          orderNumber: orderNumber,
          paidAmount: values.amount,
          collectionAmounts: [
            {
              collectionMedium: 2,
              amount: values.amount,
            },
          ],
        },
      ],
      partnerReference: values.bankAccount,
      receiptBase64String: values.receiptBase64String,
      totalPaidAmount: values.amount,
      transactionReference: values.transactionReference,
    };

    const res = await dispatch(remittanceActions.logRemittance(payload));
    res && setShowSucessPage(true);
    res && setRemittanceStatus(true);
    res && mixPanel.track(REMITTANCE_SINGLE_ORDER_FINISH, {});
  };

  useEffect(() => {
    mixPanel.track(REMITTANCE_SINGLE_ORDER_START, {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DesktopBackgroundLayout>
      <Fragment>
        {showSuccessPage ? (
          <SuccessPage
            title={`${
              remittanceStatus ? "Remittance Successful" : "Remittance Failed!"
            }`}
            subtitle={
              remittanceStatus
                ? "Your payment has been logged successfully. You will be notified once it’s been reviewed."
                : "We couldn't complete your transaction. Kindy try again"
            }
            showDetailButton={remittanceStatus}
            successfull={remittanceStatus}
            doneText={remittanceStatus ? "Go to Home" : "Try Again"}
            detailText="View  Order"
            onDoneClick={() => {
              if (remittanceStatus) {
                history.push("/actions/remittance/dashboard");
              } else {
                setShowSucessPage(false);
              }
            }}
            onDetailClick={async () => {
              history.push({
                pathname: "/actions/remittance/remittance-order-details",
                state: location?.state,
              });
            }}
          />
        ) : (
          <Fragment>
            <TopHeader noBorderBottom title="Remit Order" />
            <ScreenContainer height="100%" top="65px">
              <OrderNumber>{`Order No. ${orderNumber}`}</OrderNumber>
              <ContainerDiv>
                <Text right="4px">Balance</Text>
                <Text color={colors.darkRed} weight="700">
                  {formatPrice(convertToPositive(outstandingAmount) || 0)}
                </Text>
              </ContainerDiv>
              <HeaderSpace />
              <Formik
                initialValues={{
                  amount: "",
                  receiptBase64String: "",
                  bankAccount: "",
                  transactionReference: "",
                }}
                validationSchema={SingleRemitValidationSchema}
                onSubmit={onSubmit}
              >
                {({ errors, touched, setFieldValue, values }) => (
                  <FlexFormContainer>
                    <InputBlock>
                      <PageText>How much do you want to remit?</PageText>
                      <PageSubText>
                        Make sure the amount matches your provided proof of
                        payment
                      </PageSubText>

                      <AmountInputWithLabel
                        name="amount"
                        label={"Amount"}
                        autocomplete={"off"}
                        allowDecimal
                      />

                      <PageText top="0">Enter transaction reference</PageText>
                      <PageSubText>
                        Kindly provide the transaction reference on your deposit
                        receipt
                      </PageSubText>

                      <TextInputWithLabel
                        name={"transactionReference"}
                        label={"Reference number"}
                      />

                      <PageText top="0">Upload transaction receipt</PageText>
                      <PageSubText>
                        Upload a proof of payment (bank teller or transfer
                        receipt) so we can start remit your payment
                      </PageSubText>
                      <FileInput3
                        mimeType={"image/*"}
                        name="receiptBase64String"
                        compressFile
                        base64String
                        placeholder={"Click to Upload Document here"}
                        value={values.receiptBase64String}
                        label="Proof of payment"
                        handleChange={(value) => {
                          if (value) {
                            var base64result = value.substr(
                              value.indexOf(",") + 1
                            );
                            setFieldValue("receiptBase64String", base64result);
                          }
                        }}
                      />

                      <PageText> What account did you remit to?</PageText>
                      <PageSubText>
                        {" "}
                        Kindly provide the bank details that you made payment to
                      </PageSubText>
                      <SelectBox
                        top="0px"
                        placeholder={"Bank Name"}
                        value={values.bankAccount}
                        title="Select Account"
                        options={banks}
                        checkable
                        showSearchBar={false}
                        buttonText="Continue"
                        handleChange={(selected) => {
                          setFieldValue("bankAccount", selected.label);
                        }}
                        valid={`${!touched.bankAccount && !errors.bankAccount}`}
                        error={
                          touched &&
                          touched.bankAccount &&
                          errors &&
                          errors.bankAccount
                        }
                      />
                    </InputBlock>

                    <RippleButton
                      type="submit"
                      disabled={
                        !values.amount ||
                        !values.receiptBase64String ||
                        !values.bankAccount ||
                        !values.transactionReference ||
                        isLoading
                      }
                    >
                      Submit
                    </RippleButton>
                  </FlexFormContainer>
                )}
              </Formik>
            </ScreenContainer>
          </Fragment>
        )}
      </Fragment>
    </DesktopBackgroundLayout>
  );
};
