import React, { useState, Fragment } from "react";

import { string, bool, func } from "prop-types";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { Search, Close } from "../../../containers/HeaderContainer";
import { TopHeader } from "../index";

const SearchInput = styled.input`
  border: none;
  outline-color: transparent;
  font-weight: 500;
  animation: slideIn 0.3s ease-out;
  background-color: #f4f4f4;
  padding: 15px;
  border-radius: 10px;
  width: 70%;
  @keyframes slideIn {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  &::placeholder {
    font-size: 16px;
    font-weight: 500;
  }
`;

export const SearchHeader = ({
  backgroundColor,
  title,
  noArrow,
  right,
  handleSearch,
  backAction,
  placeholder,
  customStyles,
  merchBuySearch,
  backLink,
  transform,
  maxWidth,
  children,
  noFixed,
  withSpacesHeader,
  color,
  weight,
  noBorderBottom,
  size,
}) => {
  const [openInput, setOpenInput] = useState(false);
  const [fadeIn, setFadeIn] = useState("");
  const history = useHistory();

  return (
    <Fragment>
      {!openInput ? (
        <TopHeader
          backgroundColor={backgroundColor}
          color={color}
          size={size}
          weight={weight}
          maxWidth={maxWidth}
          noBorderBottom={noBorderBottom}
          withSpacesHeader={withSpacesHeader}
          title={title}
          transform={transform}
          noFixed={noFixed}
          noArrow={noArrow}
          backAction={backAction}
          backLink={backLink}
        >
          <Search
            right={right}
            onClick={() => {
              setFadeIn("fadeIn");
              setTimeout(() => setOpenInput(!openInput), 300);
            }}
            className={fadeIn}
          />
          {children}
        </TopHeader>
      ) : (
        <TopHeader
          color={color}
          size={size}
          weight={weight}
          noBorderBottom={noBorderBottom}
          withSpacesHeader={withSpacesHeader}
          title={title}
          noFixed={noFixed}
          noArrow={noArrow}
          backAction={backAction}
          backLink={backLink}
          noTitle
        >
          {merchBuySearch ? (
            <SearchInput
              style={customStyles || null}
              onClick={() => history.push("/actions/merchbuy/search")}
              placeholder={placeholder}
            />
          ) : (
            <SearchInput
              style={customStyles || null}
              onChange={(e) => handleSearch(e.target.value)}
              placeholder={placeholder}
            />
          )}

          <Close
            right={"16px"}
            onClick={() => {
              setFadeIn("fadeOut");
              setOpenInput(!openInput);
              handleSearch("");
            }}
          />
        </TopHeader>
      )}
    </Fragment>
  );
};

SearchHeader.propTypes = {
  title: string.isRequired,
  handleSearch: func,
  merchBuySearch: bool,
  noArrow: bool,
  placeholder: string,
  right: string,
  backLink: string,
};
