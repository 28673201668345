import { toast } from "react-toastify";

import { axios, currentAPI } from "../../../../../config";
import { updateUserData } from "../../../../../redux/ducks/auth/update-user-data/actions";
import { fileToFormData } from "../../../../../utils/files/fileToFormData";
import { plusToZeroFormat } from "../../../../../utils/inputs/formatPhoneNumber";
import { SAVE_USER_AVATAR } from "../../constants";

export const sendAvatar = (avatar) => async (dispatch, getState) => {
  const userId = getState().user.userId;
  const formData = await fileToFormData(avatar);

  try {
    const sendFileResponse = await axios.post(
      `${currentAPI}/api/storage/${encodeURIComponent(userId)}/uploadAvatar`,
      formData
    );

    if (sendFileResponse.status === 200) {
      dispatch(saveAvatar(sendFileResponse.data));
    }
  } catch (error) {
    toast.error(
      error.message === "Network Error"
        ? error.message
        : "Sorry, we couldn't update your avatar, please try again"
    );
  }
};

export const sendAvatarToBucketBase64 = (base64) => async (dispatch) => {
  try {
    const uploadContentResponse = await axios.post(
      `${currentAPI}/api/storage/uploadContentBase64`,
      {
        base64Image: base64,
      }
    );

    if (uploadContentResponse.status === 200) {
      dispatch(saveAvatar(uploadContentResponse.data));
      dispatch(updateUserData({ avatar: uploadContentResponse.data }));
    }
  } catch (error) {
    if (error.message === "Network Error") {
      toast.error(error.message);
    }
    console.error(error);
  }
};

export const getUserAvatar = () => async (dispatch, getState) => {
  if (!navigator.onLine) return;

  try {
    const phoneNumber = getState().user.msisdn;

    const avatarResponse = await axios.get(
      `${currentAPI}/api/storage/downloadAvatar?url=${encodeURIComponent(
        plusToZeroFormat(phoneNumber)
      )}_avatar.png`
    );

    if (avatarResponse.status === 200) {
      dispatch(saveAvatar(avatarResponse.data));
      return avatarResponse.data;
    }
  } catch (error) {
    console.error(error);
  }
};

export const saveAvatar = (avatar) => (dispatch) => {
  dispatch({ type: SAVE_USER_AVATAR, payload: avatar });
};
