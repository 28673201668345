import { sortCartItems } from "../utils/sortCartItems";

export const getProductInfoForMixPanel = (carts) => {
  let totalItemCount = 0;
  const productIds = [];
  const productNames = [];
  const shopNames = [];
  const categoryNames = [];
  const sortedItems = sortCartItems(carts);
  carts.map((x) => {
    totalItemCount += x?.quantity;
    productIds.push(x?.product?.id);
    productNames.push(x?.product?.name);
    categoryNames.push(x?.product?.productDetails?.productCategory);
    return null;
  });
  sortedItems.map((x) => shopNames.push(x.shopName.trim()));

  return {
    totalItemCount,
    categoryNames,
    productNames,
    productIds,
    shopNames,
    sortedItems,
  };
};
