import React, { Fragment, useState, useEffect } from "react";

import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import {
  TopHeader,
  InputWithLabel,
  RippleButton,
  SelectBank,
} from "../../../../components";
import { VerificationStatus } from "../../../../components/verification-status";
import {
  InputBlock,
  OpenOverlaySelectBox,
} from "../../../../containers/InputContainer";
import { Message } from "../../../../containers/MessageContainer";
import {
  ScreenContainer,
  FlexContainer,
} from "../../../../containers/ScreenContainer";
import {
  getAvailableBanksNoAuth,
  updateAgentBankData,
} from "../../../../redux/ducks/account/wallet/actions/bank-account";
import DesktopBackgroundLayout from "../../../DesktopBackgroundLayout";

import BankLogo from "./assets/Bank.svg";
import BankIcon from "./assets/bankIcon.svg";
import { BankAccountValidationSchema } from "./BankAccountValidationSchema";

const CardDetailsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  min-height: 180px;
  background-color: #227eff;
  border-radius: 8px;
  padding: 25px 16px;
  overflow: hidden;
  margin-top: ${({ top }) => top || "60px"};

  &::before {
    content: url(${BankLogo});
    position: absolute;
    top: 35px;
    left: 25px;
  }

  &::after {
    content: url(${BankIcon});
    position: absolute;
    bottom: -4px;
    right: 0px;
    opacity: 0.1;
  }
`;

const CardDetailsContainer = styled.div`
  position: absolute;
  bottom: 5px;
  left: 25px;
`;
const CardBankName = styled.span`
  position: relative;
  color: #ffffff;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  margin-right: 10px;
`;
const CardBankAccountNumber = styled.span`
  position: relative;
  font-size: 15px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 1px;
  color: #ffffff;
  opacity: 0.9;
`;
const CardBankAccountName = styled.p`
  position: relative;
  display: flex;
  width: 100%;
  bottom: 10px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

const KYCAgentBankData = () => {
  const INCOMPLETE = "INCOMPLETE";
  const APPROVED = "APPROVED";

  const dispatch = useDispatch();
  const banks = useSelector((state) => state.account.wallet.availableBanks);
  const accountName = useSelector((state) => state.user);
  const defaultBankAccountData = useSelector(
    (state) => state.user.defaultBankAccountData
  );
  const [selectedBank, setSelectedBank] = useState(undefined);
  const [openSelectBank, setOpenSelectBank] = useState(false);
  const agentState = useSelector((state) =>
    state.user.defaultBankAccountData &&
    state.user.defaultBankAccountData.accountNumber
      ? APPROVED
      : INCOMPLETE
  );

  useEffect(() => {
    dispatch(getAvailableBanksNoAuth());
  }, [dispatch]);

  const getUserBank = () => {
    if (defaultBankAccountData && defaultBankAccountData.bankName) {
      return defaultBankAccountData.bankName;
    } else if (
      banks &&
      defaultBankAccountData &&
      defaultBankAccountData.bankCode
    ) {
      const bankObject = banks.find(
        (x) => x.Code === defaultBankAccountData.bankCode
      );
      if (bankObject) {
        return bankObject.Name;
      }
    }
    return "";
  };
  const userBankName = getUserBank();
  const userBankAccountNumber = defaultBankAccountData.accountNumber;
  const userFullAccountName = `${accountName.firstName}  ${accountName.lastName}`;

  return (
    <Fragment>
      <DesktopBackgroundLayout>
        <TopHeader title={"Bank Data"} />
        <ScreenContainer>
          {defaultBankAccountData !== "" && agentState === "APPROVED" ? (
            <CardDetailsWrapper>
              <CardDetailsContainer>
                <CardBankName>{userBankName}</CardBankName>
                <CardBankAccountNumber>
                  {userBankAccountNumber}
                </CardBankAccountNumber>
                <CardBankAccountName>{userFullAccountName}</CardBankAccountName>
              </CardDetailsContainer>
            </CardDetailsWrapper>
          ) : (
            <Formik
              initialValues={{
                accountNumber:
                  (defaultBankAccountData &&
                    defaultBankAccountData.accountNumber) ||
                  "",
                bank: getUserBank(),
                bankCode:
                  (defaultBankAccountData && defaultBankAccountData.bankCode) ||
                  "",
                currency:
                  (defaultBankAccountData && defaultBankAccountData.currency) ||
                  "NGN",
              }}
              enableReinitialize
              validationSchema={BankAccountValidationSchema}
              onSubmit={(values) => {
                values.bankCode = banks.find(
                  (x) => x.Name === values.bank
                ).Code;
                dispatch(updateAgentBankData(values));
              }}
            >
              {({ values, errors, touched, setFieldValue, initialValues }) => (
                <Form style={{ marginTop: "16px" }}>
                  <FlexContainer top={"25px"}>
                    <Message top={"0"}>Bank account details</Message>
                    {agentState === INCOMPLETE && (
                      <VerificationStatus status={agentState} />
                    )}
                  </FlexContainer>
                  <InputBlock>
                    <InputWithLabel
                      label={"Account number"}
                      type={"text"}
                      value={values.accountNumber}
                      placeholder={"Account number"}
                      inputMode={"numeric"}
                      name="accountNumber"
                      maxLength="10"
                      disabled={agentState === APPROVED}
                      valid={`${
                        !touched.accountNumber && !errors.accountNumber
                      }`}
                      errors={
                        touched &&
                        touched.accountNumber &&
                        errors &&
                        errors.accountNumber
                      }
                      clear={() => {
                        document.querySelector(
                          `input[name='accountNumber']`
                        ).value = "";
                        setFieldValue(
                          "accountNumber",
                          initialValues.accountNumber
                        );
                      }}
                    />
                    <OpenOverlaySelectBox
                      disabled={agentState === APPROVED}
                      onClick={() => setOpenSelectBank(!openSelectBank)}
                    >
                      {values.bank || "Bank"}
                    </OpenOverlaySelectBox>
                  </InputBlock>
                  {openSelectBank && (
                    <SelectBank
                      open={openSelectBank}
                      setOpen={setOpenSelectBank}
                      selectedBank={selectedBank}
                      setSelectedBank={setSelectedBank}
                      setFieldValue={setFieldValue}
                      fieldName={"bank"}
                    />
                  )}

                  {agentState === INCOMPLETE && (
                    <RippleButton
                      type="submit"
                      top={"24px"}
                      disabled={
                        !values.bank ||
                        String(values.accountNumber).length !== 10
                      }
                    >
                      Update
                    </RippleButton>
                  )}
                </Form>
              )}
            </Formik>
          )}
        </ScreenContainer>
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default KYCAgentBankData;
