import React, { Fragment, useEffect, useState } from "react";

import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { InputBlock } from "../../../../containers/InputContainer";
import { merchbuyActions } from "../../../../redux/ducks/applications/merchbuy/actions";
import { fetchDeliveryDetails } from "../../../../redux/ducks/applications/my-shop/actions/customers";
import * as actions from "../../../../redux/ducks/applications/my-shop/actions/customers";
import { mapAvailableStates } from "../../../../utils/inputs/mapAvailableStates";
import { mapCitiesByLga } from "../../../../utils/inputs/mapCitiesByLga";
import { mapLgaByAvaliableStates } from "../../../../utils/inputs/mapLgaByAvaliableStates";
import { ReactComponent as YourAddressIcon } from "../../merchbuy/assets/your-address.svg";
import SimpleMap from "../components/google-map/simpleMap";
import {
  Space,
  FullAddressHolder,
  ShopAddressInfo,
  Divider,
  InnerTitle,
} from "../styles";

import {
  Loader,
  TopHeader,
  SelectBox,
  InputWithLabel,
  RippleButton,
} from "./../../../../components";
import { colors } from "./../../../../styles";
import DesktopBackgroundLayout from "./../../../DesktopBackgroundLayout";
import { DeliveryAddressValidationSchema } from "./DeliveryAddressValidationSchema";

const ShopAddressWrapper = styled.div`
  display: flex;
`;

const Padding = styled.div`
  padding: 0 1em;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.boldDark};
`;

const Subtitle = styled.div`
  font-size: 14px;
  line-height: 21px;
  color: ${colors.lightDark};
  font-weight: 400;
  margin-top: 6px;
`;

const DeliveryAddress = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const listOfAvailableStatesAndLga = useSelector(
    (state) => state.applications.merchbuy.availableStates
  );
  const cities =
    useSelector((state) => state.applications.merchbuy.cities) || [];
  const deliveryLocation = useSelector(
    (state) => state.applications.myShop.deliveryDetails
  );
  const isLoading = useSelector((state) => state.applications.myShop.isLoading);

  const UserDeliveryLocation =
    localStorage.getItem("deliveryLocation") &&
    JSON.parse(localStorage.getItem("deliveryLocation"));
  const { state: UserDeliveryState } = UserDeliveryLocation || {};

  const [listOfAvailableStates, setListOfAvailableStates] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [coordinates, setCoordinates] = useState({});

  useEffect(() => {
    setCoordinates(
      deliveryLocation?.cordinates || { longitude: 0, latitude: 0 }
    );
  }, [deliveryLocation]);

  useEffect(() => {
    setListOfAvailableStates(mapAvailableStates(listOfAvailableStatesAndLga));
  }, [listOfAvailableStatesAndLga]);

  useEffect(() => {
    dispatch(fetchDeliveryDetails());
    dispatch(merchbuyActions.getAVailableStates());
    dispatch(merchbuyActions.getAllCities());
  }, [dispatch]);

  const onSubmit = async (values, { setErrors }) => {
    const payload = {
      deliveryInstruction: values.deliveryInstruction || null,
      phoneNumber: values.msisdn,
      receiverName: deliveryLocation?.receiverName,
      firstName: values.firstName,
      lastName: values.lastName,
      state: values.state,
      city: values.city,
      lga: values.lga,
      address: values.shopAddress,
      shopNumber: values.shopNumber,
      cordinates: {
        longitude: coordinates.longitude.toString(),
        latitude: coordinates.latitude.toString(),
      },
    };

    const res = await dispatch(
      actions.updateMerchantDelivery(
        values.userId,
        payload,
        deliveryLocation.contactType
      )
    );
    res && history.goBack();
  };

  const initialFormValues = {
    firstName: deliveryLocation?.firstName || "",
    lastName: deliveryLocation?.lastName || "",
    middleName: "",
    country: "SA",
    state: UserDeliveryState || "",
    lga:
      UserDeliveryState === deliveryLocation?.state
        ? deliveryLocation?.lga || deliveryLocation?.localGovt || ""
        : "",
    city:
      UserDeliveryState === deliveryLocation?.state
        ? (deliveryLocation?.city && deliveryLocation?.city.toUpperCase()) || ""
        : "",
    cordinates: deliveryLocation?.cordinates || "",
    msisdn: deliveryLocation?.phoneNumber || "",
    phoneNumber: deliveryLocation?.phoneNumber || "",
    shopAddress:
      deliveryLocation?.address || deliveryLocation?.shopAddress || "",
    deliveryInstruction:
      deliveryLocation?.deliveryInstruction ||
      deliveryLocation?.deliveryInstructions ||
      "",
    shopNumber: deliveryLocation?.shopNumber || "",
    userId: deliveryLocation?.userId || "",
  };

  return isLoading ? (
    <Loader />
  ) : (
    <Fragment>
      <DesktopBackgroundLayout>
        <TopHeader
          title="Update Address"
          backLink="/actions/merchbuy"
          withCancelIcon
        />
        <Space height="90px" />
        <Padding>
          <Title>Update Your Address</Title>
          <Subtitle>Kindly update your delivery address.</Subtitle>
        </Padding>
        <Formik
          initialValues={initialFormValues}
          validationSchema={DeliveryAddressValidationSchema}
          onSubmit={onSubmit}
        >
          {({
            handleChange,
            errors,
            values,
            touched,
            setFieldValue,
            initialValues,
          }) => {
            return (
              <Form>
                <InputBlock>
                  <Padding>
                    <SelectBox
                      placeholder={"Province"}
                      value={values.state}
                      title="Select Province"
                      height="90%"
                      disabled
                      isSelected={isSelected}
                      setIsSelected={setIsSelected}
                      options={listOfAvailableStates}
                      handleChange={(selected) => {
                        setFieldValue("state", selected.label);
                        setFieldValue("lga", "");
                        setFieldValue("city", "");
                      }}
                      valid={`${!touched.state && !errors.state}`}
                      errors={
                        touched && touched.state && errors && errors.state
                      }
                    />
                    <SelectBox
                      placeholder={"local government"}
                      value={values.lga}
                      top="8px"
                      title="Select Municipalities"
                      height="90%"
                      isSelected={isSelected}
                      setIsSelected={setIsSelected}
                      options={mapLgaByAvaliableStates(
                        values.state,
                        listOfAvailableStatesAndLga
                      )}
                      handleChange={(selected) => {
                        setFieldValue("lga", selected.label);
                        setFieldValue("city", "");
                      }}
                      valid={`${!touched.lga && !errors.lga}`}
                      errors={touched && touched.lga && errors && errors.lga}
                    />
                    <SelectBox
                      name={"city"}
                      placeholder={"Select City"}
                      value={values.city}
                      top="8px"
                      height="90%"
                      title="Select City"
                      isSelected={isSelected}
                      setIsSelected={setIsSelected}
                      options={mapCitiesByLga(values.lga, cities)}
                      handleChange={handleChange}
                      valid={`${!touched.city && !errors.city}`}
                      errors={touched && touched.city && errors && errors.city}
                    />
                    <InputWithLabel
                      label={"Shop Number"}
                      type={"text"}
                      value={values.shopNumber}
                      placeholder={"Shop Number"}
                      bottom="8px"
                      top="8px"
                      name="shopNumber"
                      noClearButton
                      valid={`${touched.shopNumber && !errors.shopNumber}`}
                      errors={
                        touched &&
                        touched.shopNumber &&
                        errors &&
                        errors.shopNumber
                      }
                      setFieldValue={setFieldValue}
                      initialValues={initialValues}
                    />
                    <InputWithLabel
                      label={"Street Name"}
                      type={"text"}
                      value={values.shopAddress}
                      placeholder={"Street Name"}
                      bottom="8px"
                      top="8px"
                      name="shopAddress"
                      noClearButton
                      valid={`${touched.shopAddress && !errors.shopAddress}`}
                      errors={
                        touched &&
                        touched.shopAddress &&
                        errors &&
                        errors.shopAddress
                      }
                      setFieldValue={setFieldValue}
                      initialValues={initialValues}
                    />
                  </Padding>

                  <FullAddressHolder>
                    <ShopAddressWrapper>
                      <YourAddressIcon />
                      <ShopAddressInfo left="6px">
                        Your Shop address
                      </ShopAddressInfo>
                    </ShopAddressWrapper>
                    <Divider />
                    <ShopAddressInfo>
                      {`
                                                ${values.shopNumber}
                                                ${values.shopAddress}
                                                ${values.city}
                                                ${values.lga}
                                                ${values.state}
                                            `}
                    </ShopAddressInfo>
                  </FullAddressHolder>
                  <InnerTitle>Drag the marker to the exact location</InnerTitle>
                  <Padding>
                    <SimpleMap
                      coordinates={coordinates}
                      setCoordinates={setCoordinates}
                      address={`${values.shopNumber} ${values.shopAddress} ${values.city} ${values.lga} ${values.state}`}
                    />
                  </Padding>
                  <Padding>
                    <RippleButton type="submit" bottom="32px">
                      Update
                    </RippleButton>
                  </Padding>
                </InputBlock>
              </Form>
            );
          }}
        </Formik>
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default DeliveryAddress;
