import React, { Fragment, useState } from "react";

import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import {
  Loader,
  TopHeader,
  PasswordGroup,
  RippleButton,
} from "../../../../components";
import { Message, Title } from "../../../../containers/MessageContainer";
import { ScreenContainer } from "../../../../containers/ScreenContainer";
import { setNewPassword } from "../../../../redux/ducks/auth/password/actions";
import { colors } from "../../../../styles";
import { getInputValues } from "../../../../utils/inputs/getInputValues";
import DesktopBackgroundLayout from "../../../DesktopBackgroundLayout";
import { ReactComponent as SetPinSvg } from "../../check/assets/setPin.svg";

import { SetPasswordValidationSchema } from "./SetPasswordValidationSchema";

const TopDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 51px;
  margin-bottom: 24px;
`;

const PinContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const FlexContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

const SetPassword = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.password.isLoading);
  const [value, setValue] = useState(undefined);
  const [confirmValue, setConfirmValue] = useState(undefined);

  return isLoading ? (
    <Loader />
  ) : (
    <Fragment>
      <DesktopBackgroundLayout>
        <TopHeader />
        <ScreenContainer height="100%">
          <Formik
            initialValues={{
              password: "",
              confirmPassword: "",
            }}
            validationSchema={SetPasswordValidationSchema}
            onSubmit={(values, { resetForm }) => {
              setTimeout(() => {
                resetForm({ password: "" });
                dispatch(setNewPassword(values.password));
              }, 1000);
            }}
          >
            {({ errors, valid, touched, setFieldValue, values }) => (
              <FlexContainer>
                <PinContainer>
                  <TopDiv>
                    <SetPinSvg />
                    <Title
                      top={"16px"}
                      size="16px"
                      weight="700"
                      color={colors.boldDark}
                    >
                      Almost Done
                    </Title>
                    <Message
                      align={"left"}
                      top={"8px"}
                      weight="400"
                      color={colors.lightDark}
                    >
                      Create a 6-digit PIN to secure your account
                    </Message>
                  </TopDiv>

                  <Message top={"0px"} bottom={"16px"}>
                    Enter PIN
                  </Message>
                  <PasswordGroup
                    count={6}
                    startIndex={1}
                    type={"password"}
                    valid={valid}
                    errors={errors}
                    name={"password"}
                    touched={touched}
                    align={"center"}
                    enteredValue={value}
                    handleChange={(event) => {
                      setValue(event.target.value);
                      setFieldValue("password", getInputValues("password"));
                    }}
                  />
                  <Message top={"24px"} bottom={"16px"}>
                    Re-enter PIN
                  </Message>
                  <PasswordGroup
                    count={6}
                    startIndex={7}
                    type={"password"}
                    valid={valid}
                    errors={errors}
                    name={"confirmPassword"}
                    touched={touched}
                    align={"center"}
                    enteredValue={confirmValue}
                    handleChange={(event) => {
                      setConfirmValue(event.target.value);
                      setFieldValue(
                        "confirmPassword",
                        getInputValues("confirmPassword")
                      );
                    }}
                  />
                </PinContainer>

                <RippleButton
                  type="submit"
                  top={"24px"}
                  disabled={
                    values.password.length < 6 ||
                    values.confirmPassword.length < 6 ||
                    values.password !== values.confirmPassword
                  }
                >
                  Continue
                </RippleButton>
              </FlexContainer>
            )}
          </Formik>
        </ScreenContainer>
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default SetPassword;
