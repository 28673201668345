import React, { Fragment, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled, { css } from "styled-components";

import {
  PageLogo,
  TopHeader,
  InfoPopupDialog,
  RippleButton,
  RippleLink,
} from "../../../components";
import {
  List,
  ListItem,
  ListHeading,
  ListLeftBlock,
} from "../../../containers/ListContainer";
import {
  Title,
  SecondaryText,
  SubTitle,
} from "../../../containers/MessageContainer";
import { CenteredBlock } from "../../../containers/ScreenContainer";
import { toggleSeen } from "../../../redux/ducks/account/notifications/actions";
import { colors } from "../../../styles";
import { relativeDateTime } from "../../../utils/date/formatCreationDate";
import DesktopBackgroundLayout from "../../DesktopBackgroundLayout";

import { ReactComponent as BonusIcon } from "./assets/bonus_payout.svg";
import { ReactComponent as CommissionIcon } from "./assets/commission-payout.svg";
import NotificationsEmptyIcon from "./assets/notifications_empty.svg";
import { ReactComponent as OrderIcon } from "./assets/order.svg";
import { ReactComponent as PromotionIcon } from "./assets/promo-tion.svg";
import { ReactComponent as TransactionIcon } from "./assets/trans-action.svg";
import { ReactComponent as WalletCreditIcon } from "./assets/wallet-credit.svg";
import { ReactComponent as WeeklyReportIcon } from "./assets/weekly-report.svg";
import { ReactComponent as WelcomeIcon } from "./assets/welcome.svg";

const NotificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.notificationBg};
`;

const HoldingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 8px;
`;

const NotificationItem = styled.div`
  width: 90%;
  margin: 7px 5%;
  font-size: 11px;
`;

const Notification = styled(ListItem)`
  margin: 0 16px;
  background-color: white;
  margin-bottom: 8px;
  border-radius: 2px;
  & > svg {
    min-width: 32px;
    min-height: 32px;
  }

  ${({ seen }) =>
    seen === "UNREAD" &&
    css`
      &::after {
        content: "";
        display: block;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: ${colors.redAlert};
        position: absolute;
        top: 6px;
        left: 42px;
      }
    `}
`;

const ListSubHeading = styled(SecondaryText)`
  display: inline;
  font-weight: 400;
  height: fit-content;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: ${({ top }) => (top ? `${top} 0 0 0` : "4px 0 0 0")};
  color: ${({ color }) => color || null};
`;

const ListSubDetail = styled.span`
  font-weight: 400;
  font-size: 12px;
  color: #56636d;
`;

const NotificationType = ({ type }) => {
  switch (type) {
    case "MERCHANT_ONBOARD": {
      return <CommissionIcon />;
    }

    case "AGENT_ONBOARD": {
      return <CommissionIcon />;
    }

    case "TRANSACTION": {
      return <WalletCreditIcon />;
    }

    case "WALLET_DEBIT": {
      return <TransactionIcon />;
    }

    case "WALLET_CREDIT": {
      return <WalletCreditIcon />;
    }

    case "BONUS": {
      return <BonusIcon />;
    }

    case "PROMOTION": {
      return <PromotionIcon />;
    }

    case "SIGN_UP": {
      return <WelcomeIcon />;
    }

    case "WEEKLY_SALES_REPORT": {
      return <WeeklyReportIcon />;
    }

    case "REFERRAL_SIGN_UP": {
      return <PromotionIcon />;
    }

    case "STATUS_OF_AGENCY_BANKING_APPLICATION_CHANGED": {
      return <PromotionIcon />;
    }

    case "ORDER_CREATION": {
      return <OrderIcon />;
    }

    default: {
      return <TransactionIcon />;
    }
  }
};

const mapNotificationName = (type) => {
  switch (type) {
    case "AGENT_ONBOARD": {
      return "Agent Onboarding";
    }

    case "MERCHANT_ONBOARD": {
      return "Merchant Onboarding";
    }

    case "BONUS": {
      return "Bonus";
    }

    case "WALLET_CREDIT": {
      return "Top-Up Balance";
    }

    case "WALLET_DEBIT": {
      return "Money Transfer";
    }

    case "SIGN_UP": {
      return "Welcome to Spaces";
    }

    case "WEEKLY_SALES_REPORT": {
      return "Weekly Sales Report";
    }

    case "REFERRAL_SIGN_UP": {
      return "My Referral";
    }

    case "STATUS_OF_AGENCY_BANKING_APPLICATION_CHANGED": {
      return "Agency Banking";
    }

    case "GROUP_BUY_PURCHASE_CREATION": {
      return " GROUP BUY PURCHASE CREATION";
    }

    case "GROUP_BUY_PURCHASE_INVITATION": {
      return "GROUP BUY PURCHASE INVITATION";
    }

    case "ORDER_CREATION": {
      return "NEW ORDER";
    }

    case "ORDER_REJECTION": {
      return "ORDER REJECTION";
    }

    case "TRANSACTION": {
      return "TRANSACTION";
    }

    case "BILLS_PAYMENT": {
      return "BILLS PAYMENT";
    }

    default: {
      return null;
    }
  }
};

const Notifications = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const notifications = useSelector(
    (state) => state.account.notifications.allNotifications
  );
  const [notificationPopup, setNotificationPopup] = useState(false);
  const [seletedItem, setSelectedItem] = useState({});

  return (
    <Fragment>
      <DesktopBackgroundLayout>
        <TopHeader title={"Notifications"} />
        {notifications && notifications.length === 0 ? (
          <CenteredBlock>
            <PageLogo
              Icon={NotificationsEmptyIcon}
              width={"184px"}
              height={"184px"}
              iconHeight={"auto"}
              iconWidth={"auto"}
              margin={"24px auto"}
            />
            <Title
              color={"#253246"}
              top={"-55px"}
              size="14px"
              lineHeight="21px"
              weight="700"
            >
              No Notifications
            </Title>
            <SubTitle
              top={"4px"}
              color={"#718596"}
              lineHeight="21px"
              weight="400"
              textAlign={"center"}
            >
              You currently don’t have any notifications. <br /> Notifications
              you get will show here.
            </SubTitle>
          </CenteredBlock>
        ) : (
          <NotificationContainer>
            <List noBorderBottom top={"71px"}>
              {notifications &&
                notifications.map((notification, index) => {
                  const {
                    state,
                    id,
                    type,
                    payload: { description, deepLink },
                    createdAt,
                  } = notification;
                  let textEl = (
                    <ListSubHeading
                      color={state === "UNREAD" ? "#071827" : "#718596"}
                    >
                      {description}
                    </ListSubHeading>
                  );
                  if (type === "WEEKLY_SALES_REPORT") {
                    const bodyIndex = description.indexOf("Body");
                    const title = description.slice(6, bodyIndex);
                    const body = description.slice(bodyIndex + 4);
                    textEl = (
                      <>
                        <ListSubHeading
                          color={state === "UNREAD" ? "#071827" : "#718596"}
                        >
                          {title}
                        </ListSubHeading>
                        <ListSubHeading
                          color={state === "UNREAD" ? "#071827" : "#718596"}
                        >
                          {body}
                        </ListSubHeading>
                      </>
                    );
                  }

                  return (
                    <Notification
                      bottom={"8px"}
                      key={index}
                      onClick={() => {
                        if (deepLink) {
                          const link = new URL(deepLink).pathname;
                          history.push(link);
                        } else {
                          setSelectedItem({
                            type,
                            createdAt,
                            description: textEl,
                            payload: notification.payload,
                          });
                          setNotificationPopup(!notificationPopup);
                        }
                        dispatch(toggleSeen(state, id));
                      }}
                      seen={state}
                    >
                      <NotificationType type={type} />
                      <ListLeftBlock width={"100%"}>
                        <HoldingContainer>
                          <ListHeading
                            weight={"700"}
                            fontSize={"14px"}
                            lineHeight={"21px"}
                            color={state === "UNREAD" ? "#071827" : "#718596"}
                          >
                            {mapNotificationName(type)}
                          </ListHeading>
                          <ListSubDetail>
                            {relativeDateTime(createdAt)}
                          </ListSubDetail>
                        </HoldingContainer>
                        {textEl}
                      </ListLeftBlock>
                    </Notification>
                  );
                })}
            </List>
          </NotificationContainer>
        )}
      </DesktopBackgroundLayout>

      <InfoPopupDialog
        open={notificationPopup}
        cancel={() => {
          setSelectedItem({});
          setNotificationPopup(!notificationPopup);
        }}
        title={mapNotificationName(seletedItem.type)}
        message={""}
      >
        <NotificationContainer>
          <NotificationItem>
            Created on {relativeDateTime(seletedItem.createdAt)}
          </NotificationItem>
          <NotificationItem>
            {seletedItem?.payload?.deepLink ? (
              <RippleLink to={seletedItem.payload.deepLink}>
                <RippleButton>Click</RippleButton>
              </RippleLink>
            ) : (
              seletedItem.payload?.summary || seletedItem.description
            )}
          </NotificationItem>
        </NotificationContainer>
      </InfoPopupDialog>
    </Fragment>
  );
};

export default Notifications;
