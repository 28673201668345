import React from "react";

import { string, func } from "prop-types";

import { colors } from "../../../styles";
import {
  BadgeWrapper,
  HorizontalLayout,
  SuccessMessage,
  CancelWrapper,
} from "../styles";

import { ReactComponent as CancelIcon } from "./assets/cancel.svg";
import { ReactComponent as SuccessIcon } from "./assets/id_card.svg";

const SuccessBadge = ({
  margin,
  radius,
  width,
  height,
  position,
  showIcon = true,
  message,
  onCancelClick,
}) => {
  return (
    <BadgeWrapper
      margin={margin}
      radius={radius}
      width={width}
      height={height}
      position={position}
      backgroundColor={colors.lightGreen}
    >
      {showIcon && <SuccessIcon />}
      <HorizontalLayout>
        <SuccessMessage>{message}</SuccessMessage>
      </HorizontalLayout>
      <CancelWrapper onClick={() => onCancelClick && onCancelClick()}>
        <CancelIcon />
      </CancelWrapper>
    </BadgeWrapper>
  );
};

SuccessBadge.propTypes = {
  message: string,
  onCancelClick: func,
};

export default SuccessBadge;
