import React, { useState } from "react";

import styled from "styled-components";

import { SearchHeader } from "../../../../../../../components";
import { Close } from "../../../../../../../containers/HeaderContainer";
import {
  List,
  ListHeading,
  ListItem,
} from "../../../../../../../containers/ListContainer";
import { SlidingOverlay } from "../../../../../../../containers/OverlayContainer";
import { ScreenContainer } from "../../../../../../../containers/ScreenContainer";
import { productUnits } from "../../../../../../../data/products/units";
import { ReactComponent as Add } from "../../assets/unit_add.svg";

const SpacedBlock = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: ${({ align }) => align || "center"};
  margin: ${({ top }) => top || "0px"};
  padding: 8px 16px 8px 16px;
`;

const ModifiedListItem = styled(ListItem)`
  display: flex;
  flex-direction: column;
  margin: 8px 16px 8px 16px;
  padding: 8px 8px 8px 8px;
  border-radius: 10px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  overflow: hidden;
  cursor: pointer;
  -webkit-tap-highlight-color: #f9fafc;

  &:hover {
    background: transparent;
  }
`;

export const ListLeftBlock = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const AddProductUnit = ({ open, setOpen, unit }) => {
  const [units, setUnits] = useState(productUnits);

  const handleSearch = (searchValue) => {
    setUnits(
      productUnits &&
        productUnits.filter(
          (data) =>
            data.value &&
            data.value.toLowerCase().includes(searchValue.toLowerCase())
        )
    );
  };

  return (
    open && (
      <SlidingOverlay>
        <SearchHeader
          title={"Product Unit"}
          placeholder={"Search product unit..."}
          handleSearch={handleSearch}
          noArrow={true}
          withSpacesHeader
        >
          <Close left={"16px"} onClick={() => setOpen(false)} />
        </SearchHeader>
        <ScreenContainer top="120px">
          <List fullScreen noBorderBottom>
            {units &&
              units.map((item, index) => (
                <ModifiedListItem key={index}>
                  <ListLeftBlock
                    value={item}
                    onClick={() => {
                      unit(item);
                      setOpen(false);
                      setUnits(productUnits);
                    }}
                  >
                    <SpacedBlock>
                      <ListHeading fontSize={"12px"}>{item.value}</ListHeading>
                      <Add />
                    </SpacedBlock>
                  </ListLeftBlock>
                </ModifiedListItem>
              ))}
          </List>
        </ScreenContainer>
      </SlidingOverlay>
    )
  );
};
