import React from "react";

import styled from "styled-components";

import { colors } from "../../../styles";
import { ReactComponent as DownwardsIcon } from "../assets/down.svg";

const FilterWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid ${colors.themeColor3};
  height: 40px;
`;

const FilterLeft = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  border-right: ${({ noBorderRight }) =>
    !noBorderRight ? `1px solid ${colors.themeColor3}` : "none"};
  justify-content: center;
  gap: 4.67px;
`;

const FilterRight = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const FilterText = styled.p`
  color: ${colors.boldDark};
  font-size: 12px;
  font-weight: 500;
`;
const ItemsFilters = ({ filterText, sortText, filterAction, sortAction }) => {
  return (
    <FilterWrapper>
      <FilterLeft onClick={() => filterAction()}>
        <FilterText>{filterText}</FilterText>
        <DownwardsIcon />
      </FilterLeft>
      <FilterRight onClick={() => sortAction()}>
        <FilterText>{sortText}</FilterText>
        <DownwardsIcon />
      </FilterRight>
    </FilterWrapper>
  );
};

export default ItemsFilters;
