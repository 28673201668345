/* eslint-disable array-callback-return */
import React, { Fragment, useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import {
  TopHeader,
  RippleButton,
  PageLogo,
  ConfirmCartPopupDialog,
  DeliveryLocationPopup,
  ProductDetailsPopup,
  RippleLink,
  Loader,
} from "../../../../../components";
import {
  SubList,
  SubListValue,
} from "../../../../../containers/CheckoutContainer";
import {
  List,
  ListItemNoDivider,
  ListHeading,
  ListLeftBlock,
  ListSubHeading,
} from "../../../../../containers/ListContainer";
import { Title } from "../../../../../containers/MessageContainer";
import {
  FlexCenteredBlock,
  ScreenContainer,
} from "../../../../../containers/ScreenContainer";
import { merchbuyActions } from "../../../../../redux/ducks/applications/merchbuy/actions";
import { getUserAdditionalInfo } from "../../../../../redux/ducks/user/actions";
import { colors } from "../../../../../styles";
import { formatPrice } from "../../../../../utils/currency/formatPriceWithComma";
import History from "../../../../../utils/History";
import { CART_OPEN } from "../../../../../utils/mix-panel/constants";
import { mixPanel } from "../../../../../utils/mix-panel/mixPanel";
import DesktopBackgroundLayout from "../../../../DesktopBackgroundLayout";
import { ReactComponent as NotificationIcon } from "../../assets/mov.svg";
import FallbackProductImage from "../../assets/productImage.svg";
import { ReactComponent as CartbasketIcon } from "../../assets/shopping-cart-basket.svg";
import SupermarketIcon from "../../assets/supermarket.svg";
import { CommissionBreakdown } from "../../shopping-cart/index";
import { Space, Container, Divider } from "../../styles";
import { productCategoryName } from "../../utils/getProductCatNameById";
import { ReactComponent as ArrowIcon } from "../assets/arrow.svg";
import { ReactComponent as CommissionIcon } from "../assets/badge.svg";
import { ReactComponent as DeleteIcon } from "../assets/deleteIcon.svg";
import { ReactComponent as GroupBuyBg } from "../assets/groupBuyIcon.svg";
import { ReactComponent as RemovedItem } from "../assets/removedItem.svg";
import Countdown from "../countdown";
import { getProductInfoForMixPanel } from "../helper";

import { SetAvailableQuantity } from "./set-available-quantity";

const CommissionOuterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${colors.white};
  height: 40px;
  padding: 0 20px;
`;

const CommissionWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const CommissionText = styled.div`
  padding: 0 10px;
  font-size: 14px;
  color: ${colors.themeTextColor5};
`;

const CommissionAmountText = styled.span`
  color: ${colors.darkGreen};
  font-weight: bold;
`;

const CheckoutOuterWrapper = styled.div`
  padding: 18px 20px 24px 20px;
`;

const ListItemChild = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  padding-top: 8px;
  opacity: ${({ grayOut }) => grayOut || null};
`;

const ModifiedListItem = styled(ListItemNoDivider)``;

const CartUpdate = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ModifiedContainer = styled(Container)`
  width: 100%;
  padding: 1em;
  padding-top: 0;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const TotalAmount = styled(ListSubHeading)`
  font-weight: bold;
  color: #212c3d;
  font-size: 12px;
`;

const DeliveryText = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: ${colors.gray5};
  margin-top: 5px;
`;

const ShopBlock = styled.section`
  margin: 4px 16px
    ${({ index, cartLength }) =>
      cartLength === 3 && index === 2
        ? "250px"
        : index === cartLength - 1
        ? "200px"
        : "4px"}
    16px;
  background: ${({ expired }) => (expired ? colors.gray4 : colors.white)};
  border: ${({ expired }) => (expired ? `1px solid ${colors.gray5}` : "none")};
  border-radius: 4px;
  padding: 3px 15px 15px 15px;
  box-shadow: 0px 15px 84px rgba(0, 0, 0, 0.05);
`;

const TitleText = styled.p`
  background-color: ${colors.white};
  font-size: 14px;
  text-align: center;
  padding: 15px 0;
  margin-top: 68px;
  margin-bottom: 8px;
  box-shadow: 0px 15px 84px rgb(0 0 0 / 5%);
`;

const RemoveCartWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 65px;
`;

const RemoveText = styled.div`
  color: ${colors.themeTxtColor10};
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 0.01em;
`;

const GroupBuyWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const GroupBuyBoxWrapper = styled.div`
  width: 78px;
  height: 20px;
  background: linear-gradient(94.68deg, #7e42f5 16.08%, #227eff 262.55%);
  border-radius: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const RemovedItemWrapper = styled.div`
  background: ${colors.lightYellow};
  height: 60px;
  margin: 15px 0 0 0;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
`;

const BottomFixedButton = styled.div`
  position: fixed;
  bottom: 0;
  width: 343px;
  margin: 25px;
`;

const GroupBuyBoxBgWrapper = styled.div`
  background: ${colors.background.lightCircle};
  width: 15px;
  height: 15px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px 1px 2px;
`;

const GroupBuyBoxText = styled.div`
  color: ${colors.white};
  font-size: 10px;
  margin-bottom: 1px;
`;

const GroupBuyEditText = styled.div`
  color: ${({ expired }) => (expired ? colors.red : colors.deepBlue)};
  font-size: 10px;
`;

const CheckoutWrapper = styled.div`
  background: ${colors.white};
  box-shadow: 0px -8px 16px rgba(0, 0, 0, 0.04);
  position: absolute;
  bottom: 0;
  width: 100%;
`;

const NotificationItemWrapper = styled.div`
  background: ${colors.lightYellow};
  height: 60px;
  margin: 3px 0 5px 0;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  animation: breathing 2s ease-out infinite normal;
`;
const NotificationText = styled.div`
  font-size: 12px;
  line-height: 18px;
  margin-left: 10px;
`;

const Amount = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  color: ${({ color }) => color || colors.themeTextColor1};
`;

let currentDate = new Date().toISOString();

const CartItems = () => {
  const dispatch = useDispatch();

  const cartItems = useSelector(
    (state) => state.applications.merchbuy.shopCart || []
  );
  const walletStatus = useSelector((state) => state.account.wallet.status);
  const isLoading = useSelector(
    (state) => state.applications.merchbuy.isLoading
  );
  const cartDeletedItemsInfo = useSelector(
    (state) => state.applications.merchbuy.shopCartDeletedInfo
  );
  const minimumOrderValue = useSelector(
    (state) => state.applications.merchbuy.minimumOrderValue
  );
  const deliveryLocation = useSelector(
    (state) => state.applications.merchbuy.deliveryLocation
  );
  const user = useSelector((state) => state.user);
  const role = useSelector((state) => state.user.role);
  const shopInfo = useSelector((state) => state.applications.myShop.shops[0]);
  const productCategeries = useSelector(
    (state) => state.applications.merchbuy.merchbuyProductCategeries
  );
  const [deliveryStateLga] = useState(
    JSON.parse(localStorage.getItem("deliveryLocation"))
  );
  const [deliveryDetails] = useState(
    deliveryLocation && Object.entries(deliveryLocation).length !== 0
      ? deliveryLocation
      : {
          receiverName: user && user.firstName + " " + user.lastName,
          shopName: shopInfo && shopInfo.shopName,
          streetAddress: shopInfo && shopInfo.streetAddress,
          lga: deliveryStateLga && deliveryStateLga.lga,
          state: deliveryStateLga && deliveryStateLga.state,
          msisdn: shopInfo && shopInfo.businessPhoneNumber,
          instruction: "",
        }
  );
  const [openProductDetailsPopup, setOpenProductDetailsPopup] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [subTotal, setSubTotal] = useState(
    cartItems.reduce(
      (accum, current) =>
        Number(accum) +
        Number(
          Number(current.quantity) >
            Number(current.bulkPrices[current.bulkPrices.length - 1].moq)
            ? current.minPrice
            : current.maxPrice
        ) *
          Number(current.quantity),
      0
    )
  );
  const [totalQuantity, setTotalQuantity] = useState(
    cartItems.reduce(
      (accum, current) => Number(accum) + Number(current.quantity),
      0
    )
  );
  const [deliveryFee] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [carts, setCarts] = useState([...cartItems]);
  const [productInView, setProductInView] = useState("");
  const [productIsGroupBuy, setProductIsGroupBuy] = useState(false);
  const [deliveryLoc, setDeliveryLoc] = useState(
    JSON.parse(localStorage.getItem("deliveryLocation"))
  );
  const [currentProduct, setCurrentProduct] = useState({});
  const [open, setOpen] = useState(false);
  const [openDeliverLocationPopup, setOpenDeliverLocationPopup] = useState(
    deliveryLoc === null
  );
  const [deleteNotification, setDeleteNotification] = useState(false);
  const [itemToBeDeleted, setItemToBeDeleted] = useState("");
  // const [deliveryDetails, setDeliveryDetails] = useState(false);
  // const [deliveryDetails, setDeliveryDetails] = useState(null);

  const getLocation = (data) => {
    if (data.state /*&& data.lga*/) {
      setDeliveryLoc(data);
      setOpenDeliverLocationPopup(!openDeliverLocationPopup);
    }
  };

  useEffect(() => {
    const data = getProductInfoForMixPanel(carts);
    mixPanel.track(CART_OPEN, {
      "Total cart value": subTotal,
      "Total item count": data.totalItemCount,
      "Total unique item": cartItems.length,
      "Total shops": data.sortedItems.length,
      "Item ids": data.productIds,
      "Item names": data.productNames,
      "Item shopNames": data.shopNames,
      "Wallet Status": walletStatus,
      Time: new Date().toLocaleString(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCarts([...cartItems]);
    if (cartDeletedItemsInfo) {
      setTimeout(() => {
        setDeleteNotification(cartDeletedItemsInfo.cartItemsDeleted);
        setTimeout(() => {
          setDeleteNotification(false);
        }, 3500);
      }, 1000);
    }
  }, [cartItems, cartDeletedItemsInfo, setCarts]);

  useEffect(() => {
    setTotalPrice(subTotal + deliveryFee);
  }, [subTotal, setTotalPrice, deliveryFee]);

  useEffect(() => {
    // getShippingPrice();
    dispatch(getUserAdditionalInfo());
    dispatch(merchbuyActions.getMinimumOrderValue());
    dispatch(merchbuyActions.getShopCart());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (!(deliveryLocation && Object.entries(deliveryLocation).length !== 0)) {
      dispatch(merchbuyActions.updateDeliveryLocation(deliveryDetails));
    }
  }, [deliveryLocation, dispatch, deliveryDetails]);

  const payload = {
    location: {
      destination: {
        address: deliveryDetails.streetAddress,
        city: deliveryDetails.state,
        country: "SA",
        email: "",
        name: deliveryDetails.receiverName,
        phone: deliveryDetails.msisdn,
        state: deliveryDetails.state,
      },
      origin: {
        address: "",
        city: "Lagos",
        country: "SA",
        email: "",
        name: "",
        phone: "",
        state: "Lagos",
      },
    },
    shipmentInfo: [
      {
        quantity: totalQuantity,
        weight: 1,
      },
    ],
  };

  const updateCart = (data) => {
    const newSubTotal = data.reduce(
      (accum, current) =>
        Number(accum) +
        Number(
          Number(current.quantity) >
            Number(current.bulkPrices[current.bulkPrices.length - 1].moq)
            ? current.minPrice
            : current.maxPrice
        ) *
          Number(current.quantity),
      0
    );
    const newTotalQuantity = data.reduce(
      (accum, current) => Number(accum) + Number(current.quantity),
      0
    );
    setSubTotal(newSubTotal);
    setTotalQuantity(newTotalQuantity);

    dispatch(merchbuyActions.updateCartLocally(data));

    //NOT SURE IF WE'D STILL NEED THIS

    // const dataToBeSent = data.map((info) => {
    //     return {
    //         groupBuyId: info.groupBuyId,
    //         groupBuyProductBulkPrice: info.groupBuyProductBulkPrice,
    //         groupBuySplitId: info.groupBuySplitId,
    //         merchBuyCommissions: {
    //             merchBuyAgentCommission: info.merchBuyCommissions.merchBuyAgentCommission,
    //             merchBuyReferralCommission: info.merchBuyCommissions.merchBuyReferralCommission,
    //             merchBuyTotalCommission: info.merchBuyCommissions.merchBuyTotalCommission,
    //         },
    //         productId: info.product.id,
    //         quantity: info.quantity,
    //         shopId: info.shopData.businessPhoneNumber,
    //     };
    // });

    // dispatch(merchbuyActions.updateCart(dataToBeSent));
  };

  const deleteItems = async () => {
    const deleted = await dispatch(
      merchbuyActions.deleteItemFromCart(productInView, productIsGroupBuy)
    );
    if (deleted) {
      const idx = cartItems.findIndex((x) => x.id === productInView);
      cartItems.splice(idx, 1);
      const newSubTotal = [...cartItems].reduce(
        (accum, current) =>
          Number(accum) +
          Number(
            Number(current.quantity) >
              Number(current.bulkPrices[current.bulkPrices.length - 1].moq)
              ? current.minPrice
              : current.maxPrice
          ) *
            Number(current.quantity),
        0
      );
      const newTotalQuantity = [...cartItems].reduce(
        (accum, current) => Number(accum) + Number(current.quantity),
        0
      );
      setSubTotal(newSubTotal);
      setTotalQuantity(newTotalQuantity);
      getShippingPrice();
    }
  };

  const getShippingPrice = () => {
    if (totalQuantity > 0) {
      dispatch(merchbuyActions.getWareNextShippingPrices(payload));
    } else {
      dispatch(merchbuyActions.updateShippingLocally([]));
    }
  };

  const calculateCommission = (input) => {
    const total = [...input].reduce(
      (accum, current) =>
        Number(accum) +
        Number(current.cost || 0) *
          Number(current.merchBuyCommissions?.merchBuyAgentCommission || 0),
      0
    );
    return total;
  };

  const showProductDescription = (product) => {
    const { name, moq, unitValue, productDetails } = product || {};
    const {
      productCategory: primaryCategory,
      productDescription: description,
    } = productDetails || {};
    setCurrentProduct({ name, moq, unitValue, primaryCategory, description });
    setOpenProductDetailsPopup(true);
  };

  return (
    <Fragment>
      {isLoading ? (
        <Loader />
      ) : (
        <DesktopBackgroundLayout>
          {carts && carts.length === 0 ? (
            <Fragment>
              <TopHeader
                backLink="/actions/merchbuy"
                withCancelIcon={true}
                title={"Cart"}
              />
              <ScreenContainer>
                <FlexCenteredBlock top={"30px"}>
                  <Space height={"80px"} />
                  <CartbasketIcon />
                  <Space height={"32px"} />

                  <Title>There is nothing in your cart</Title>
                  <BottomFixedButton>
                    <RippleButton
                      top={"110px"}
                      onClick={() => History.push("/actions/merchbuy")}
                    >
                      Start shopping
                    </RippleButton>
                  </BottomFixedButton>
                </FlexCenteredBlock>
              </ScreenContainer>
            </Fragment>
          ) : (
            <Fragment>
              <TopHeader
                backLink="/actions/merchbuy"
                title={`Cart (${carts && carts.length})`}
                noArrow={false}
              />
              <ScreenContainer padding="0" paddingBottom="40px">
                <TitleText>
                  Minimum order value {formatPrice(minimumOrderValue?.value)}
                </TitleText>
                <ModifiedContainer>
                  <List noBorderBottom={true} fullScreen>
                    <Fragment>
                      {carts.map((product, index) => (
                        <Fragment key={index}>
                          <ShopBlock
                            index={index}
                            cartLength={carts.length}
                            expired={
                              product.groupBuyTimer &&
                              product.groupBuyTimer < currentDate
                                ? true
                                : false
                            }
                          >
                            <ModifiedListItem
                              top={"4px"}
                              bottom={"4px"}
                              direction={"column"}
                            >
                              <ListItemChild key={index}>
                                <RippleLink
                                  to={`/actions/merchbuy/product/${product.id}`}
                                  onClick={() => {
                                    dispatch(
                                      merchbuyActions.setInitProductDetials(
                                        product
                                      )
                                    );
                                  }}
                                >
                                  <PageLogo
                                    width={"44px"}
                                    height={"44px"}
                                    iconWidth={"44px"}
                                    iconHeight={"44px"}
                                    Icon={
                                      product?.images?.baseImageUrl ||
                                      FallbackProductImage
                                    }
                                    fallback={SupermarketIcon}
                                    borderRadius="2px"
                                  />
                                </RippleLink>

                                <ListLeftBlock width="100%">
                                  {product.groupBuyId && (
                                    <GroupBuyWrapper>
                                      <GroupBuyBoxWrapper>
                                        <GroupBuyBoxBgWrapper>
                                          <GroupBuyBg />
                                        </GroupBuyBoxBgWrapper>
                                        <GroupBuyBoxText>
                                          Groupbuy
                                        </GroupBuyBoxText>
                                      </GroupBuyBoxWrapper>
                                      <GroupBuyEditText
                                        expired={
                                          product.groupBuyTimer &&
                                          product.groupBuyTimer < currentDate
                                            ? true
                                            : false
                                        }
                                      >
                                        {product.isGroupBuyOwner ? (
                                          `${
                                            product.groupBuyTimer &&
                                            product.groupBuyTimer < currentDate
                                              ? "EXPIRED"
                                              : "EDIT"
                                          }`
                                        ) : (
                                          <Countdown
                                            date={product.groupBuyTimer}
                                          />
                                        )}
                                      </GroupBuyEditText>
                                    </GroupBuyWrapper>
                                  )}
                                  <ListHeading
                                    bottom="8px"
                                    color={
                                      product.groupBuyTimer &&
                                      product.groupBuyTimer < currentDate
                                        ? colors.lightDark
                                        : colors.themeTextColor1
                                    }
                                    top="5px"
                                    weight={400}
                                    maxWidth="100%"
                                    fontSize="13px"
                                    noHeadingWrap
                                    onClick={() =>
                                      showProductDescription(product)
                                    }
                                  >
                                    {product &&
                                      (product?.name || "").toLowerCase()}
                                  </ListHeading>
                                  <Amount
                                    color={
                                      product.groupBuyTimer &&
                                      product.groupBuyTimer < currentDate
                                        ? colors.lightDark
                                        : colors.black
                                    }
                                    top="8px"
                                  >
                                    {product.maxPrice &&
                                      formatPrice(product.maxPrice)}
                                  </Amount>
                                </ListLeftBlock>
                              </ListItemChild>
                              {!carts.length - 1 && (
                                <Divider top={"16px"} bottom={"5px"} />
                              )}
                            </ModifiedListItem>
                            <CartUpdate>
                              <RemoveCartWrapper
                                onClick={() => {
                                  setItemToBeDeleted(product.name);
                                  setProductIsGroupBuy(
                                    product.groupBuyId ? true : false
                                  );
                                  setProductInView(product.id);
                                  setConfirmDelete(true);
                                }}
                              >
                                <DeleteIcon />
                                <RemoveText>REMOVE</RemoveText>
                              </RemoveCartWrapper>
                              <SetAvailableQuantity
                                isEditable={product.groupBuyId ? false : true}
                                position="relative"
                                right="0px"
                                width="30%"
                                qtyHeight="27px"
                                top="0"
                                productQuantity={product.quantity}
                                availableQuantity={product.productQuantity}
                                updateCart={updateCart}
                                productId={product.id}
                                carts={carts}
                                moq={product.moq}
                              />
                            </CartUpdate>
                          </ShopBlock>
                        </Fragment>
                      ))}
                    </Fragment>
                  </List>
                </ModifiedContainer>
              </ScreenContainer>
              <CheckoutWrapper>
                {role === "ROLE_AGENT" && (
                  <CommissionOuterWrapper>
                    <CommissionWrapper>
                      <CommissionIcon />
                      <CommissionText
                        padding="0 10px"
                        font={"14px"}
                        color={"#253246"}
                      >
                        You will earn{" "}
                        <CommissionAmountText>
                          {formatPrice(calculateCommission(carts))}
                        </CommissionAmountText>{" "}
                        in commisions
                      </CommissionText>
                    </CommissionWrapper>
                    <ArrowIcon
                      onClick={() => {
                        setOpen(!open);
                      }}
                    />
                  </CommissionOuterWrapper>
                )}
                <Divider top="0px" bottom="0px" />
                <CheckoutOuterWrapper>
                  <List noBorderBottom={true}>
                    <SubList>
                      <TotalWrapper>
                        <TotalAmount>Total</TotalAmount>
                        <DeliveryText>Delivery fee not included</DeliveryText>
                      </TotalWrapper>
                      <SubListValue>{formatPrice(totalPrice)}</SubListValue>
                    </SubList>
                  </List>
                  {deleteNotification && (
                    <RemovedItemWrapper>
                      <RemovedItem />
                    </RemovedItemWrapper>
                  )}
                  {minimumOrderValue?.value > totalPrice && (
                    <NotificationItemWrapper>
                      <NotificationIcon />
                      <NotificationText>
                        You must order items worth at least{" "}
                        {formatPrice(minimumOrderValue.value)} to checkout. Add
                        more items to checkout
                      </NotificationText>
                    </NotificationItemWrapper>
                  )}
                  <Fragment>
                    <Space height={"10px"} />
                    <RippleButton
                      backgroundColor={
                        minimumOrderValue?.value > totalPrice
                          ? "#A6CBFF"
                          : colors.deepBlue
                      }
                      top={"1px"}
                      onClick={() =>
                        minimumOrderValue?.value > totalPrice
                          ? null
                          : History.push("/actions/merchbuy/checkout")
                      }
                    >
                      Checkout
                    </RippleButton>
                  </Fragment>
                </CheckoutOuterWrapper>
              </CheckoutWrapper>
              <ConfirmCartPopupDialog
                open={confirmDelete}
                title="Remove from Cart"
                confirmationTextBegining="Are you sure you want to remove"
                confirmationTextEnding="from your cart?"
                productName={itemToBeDeleted}
                answers={[
                  {
                    variant: "Cancel",
                    action: () => {
                      setConfirmDelete(false);
                    },
                  },
                  {
                    variant: "OK",
                    action: () => {
                      deleteItems();
                      setConfirmDelete(false);
                    },
                  },
                ]}
              />
            </Fragment>
          )}
          {openDeliverLocationPopup && (
            <DeliveryLocationPopup
              open={openDeliverLocationPopup}
              getLocation={getLocation}
              cancel={() => {
                setOpenDeliverLocationPopup(!openDeliverLocationPopup);
              }}
            />
          )}
          {open && <CommissionBreakdown open={open} setOpen={setOpen} />}
          <ProductDetailsPopup
            open={openProductDetailsPopup}
            data={currentProduct}
            productCategoryName={productCategoryName}
            productCategeries={productCategeries}
            cancel={() => setOpenProductDetailsPopup(false)}
          />
        </DesktopBackgroundLayout>
      )}
    </Fragment>
  );
};

export default CartItems;
