import moment from "moment";

export function formatBarChartData(init, data) {
  const arr = [];
  for (let i = 0; i < init.length; i++) {
    const isExist = data.find(
      (x) =>
        String(x.recordType).toLowerCase() ===
        String(init[i].recordType).toLowerCase()
    );
    if (isExist) {
      isExist.recordType = init[i].recordType;
      arr.push(isExist);
    } else {
      arr.push(init[i]);
    }
  }

  return arr;
}

export function formatYearlyBarChartData(init, data) {
  let date = new Date();
  let month = date.getMonth();
  let year = date.getUTCFullYear();
  const arr = [];
  for (let i = 0; i < init.length; i++) {
    const isExist = data.find(
      (x) =>
        String(x.recordType).toLowerCase() ===
        String(init[i].month).toLowerCase()
    );
    if (isExist) {
      isExist.month = init[i].month;
      isExist.recordType = `${init[i].month}, ${
        month >= init[i].id ? year : year - 1
      }`;
      arr.push(isExist);
    } else {
      init[i].recordType = `${init[i].month}, ${
        month >= init[i].id ? year : year - 1
      }`;
      arr.push(init[i]);
    }
  }
  const sortedArray = arr.sort(
    (a, b) =>
      new moment(a.recordType).format("YYYYMM") -
      new moment(b.recordType).format("YYYYMM")
  );

  return sortedArray;
}

export function calPercentageCountColor(
  type,
  salesPercentageIncrease,
  salesIsPositive,
  profitPercentageIncrease,
  profitIsPositive
) {
  if (type === "Sales") {
    if (Number(salesPercentageIncrease) === 0) {
      return;
    } else {
      if (salesIsPositive) {
        return "green";
      } else {
        return "red";
      }
    }
  } else {
    if (Number(profitPercentageIncrease) === 0) {
      return;
    } else {
      if (profitIsPositive) {
        return "green";
      } else {
        return "red";
      }
    }
  }
}

export function isValidNum(value) {
  return typeof value === "number" ? value : 0;
}
