import React, { Fragment, useEffect, useState, Suspense } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import {
  DeliveryLocationPopup,
  TopHeader,
  InfiniteScrollList,
} from "../../../../components";
import { SearchInputWithCancel } from "../../../../components/forms/input/search-input/SearchInput";
import { merchbuyActions } from "../../../../redux/ducks/applications/merchbuy/actions";
import DesktopBackgroundLayout from "../../../DesktopBackgroundLayout";
import { ListProductCategories } from "../components";
import ProductCategorySkeleton from "../components/listProductCategories/productCategorySkeleton";
import { Main, AddFlexBox, Container, Space } from "../styles";

const Flex = styled(AddFlexBox)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: ${({ justifyContent }) => justifyContent || ""};
  width: 100%;
`;

const MerchbuyProductCategories = () => {
  const history = useHistory();
  const [productsToDisplay, setProductsToDisplay] = useState([]);
  const totalElements = useSelector(
    (state) => state.applications.merchbuy.totalElements
  );
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [perPage] = useState(30);

  const dispatch = useDispatch();
  const productCategory = useSelector(
    (state) => state.applications.merchbuy.merchbuyProductCategeries
  );
  const [searchValue, setSearchValue] = useState("");

  const [deliveryLocation, setDeliveryLocation] = useState(
    JSON.parse(localStorage.getItem("deliveryLocation"))
  );
  const [openDeliverLocationPopup, setOpenDeliverLocationPopup] = useState(
    deliveryLocation === null
  );

  const getLocation = (data) => {
    if (data.state) {
      setDeliveryLocation(data);
      setOpenDeliverLocationPopup(!openDeliverLocationPopup);
    }
  };

  useEffect(() => {
    dispatch(merchbuyActions.getProductCategories(page, perPage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const categoriesFilter = productsToDisplay.filter(
    (ctg) => ctg.products.length !== 0
  );

  const categories = categoriesFilter.filter((data) =>
    data.name.toLowerCase().includes(searchValue.toLowerCase())
  );

  useEffect(() => {
    setProductsToDisplay(productCategory.slice(0, perPage));
    if (productCategory && productCategory.length < totalElements) {
      setPage(2);
      setHasMore(true);
    } else {
      setHasMore(false);
    }
  }, [productCategory, totalElements, perPage]);

  const addMoreDataToDisplay = async () => {
    if (productsToDisplay.length < totalElements) {
      const data = await dispatch(
        merchbuyActions.getPaginatedProductCategory(page, perPage)
      );
      setProductsToDisplay([...productsToDisplay, ...data]);
      setPage(page + 1);
    } else {
      setHasMore(false);
    }
  };

  return (
    <Fragment>
      <DesktopBackgroundLayout>
        <TopHeader
          title={"Product Categories"}
          size={"14px"}
          weight={"400"}
          noBorderBottom
        />
        <Main>
          <Container top={"5px"}>
            <SearchInputWithCancel
              margin="0px 0px 31px 0px"
              placeholder="Search for a product"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              onCancel={() => {
                setSearchValue("");
                history.goBack();
              }}
              showCancelOnInteract
              onCancelInputValue={() => setSearchValue("")}
            />
            <Suspense fallback={<ProductCategorySkeleton />}>
              <InfiniteScrollList
                data={productsToDisplay}
                fetchMore={addMoreDataToDisplay}
                hasMore={hasMore}
                endMessage=""
              >
                <Flex direction={"column"}>
                  <ListProductCategories data={categories} />
                </Flex>
              </InfiniteScrollList>
            </Suspense>
          </Container>
        </Main>
        <Space height="50px" />
        {openDeliverLocationPopup && (
          <DeliveryLocationPopup
            open={openDeliverLocationPopup}
            getLocation={getLocation}
            cancel={() => {
              setOpenDeliverLocationPopup(!openDeliverLocationPopup);
            }}
          />
        )}
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default MerchbuyProductCategories;
