import React, { useState } from "react";

import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";

import {
  TopHeader,
  RippleButton,
  InputWithOnchange,
  Loader,
} from "../../../../../components";
import { Message } from "../../../../../containers/MessageContainer";
import {
  ScreenContainer,
  ViewContainer,
} from "../../../../../containers/ScreenContainer";
import { initiateTopUp } from "../../../../../redux/ducks/account/wallet/actions";
import { colors } from "../../../../../styles";
import { formatCurrency } from "../../../../../utils/currency/parseBalance";
import DesktopBackgroundLayout from "../../../../DesktopBackgroundLayout";

const FundButton = styled(RippleButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 92%;
  position: absolute;
  bottom: 80px;
  margin: 15px auto;

  @media (max-width: 576px) {
    bottom: 40px;
  }

  &:disabled {
    background-color: #a6cbff;
    opacity: 1;
  }
`;

const FundWallet = () => {
  const dispatch = useDispatch();

  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const country = useSelector((state) => state.user.country);

  const FundHook = () => {
    const payload = { amount };
    const onSubmit = async () => {
      if (amount < 100) {
        toast.error("Amount should be at least 100");
        return;
      }
      setLoading(true);
      const initiateTopUpResponse = await dispatch(initiateTopUp(payload));

      if (initiateTopUpResponse.status) {
        const { topUpRequestUrl } = initiateTopUpResponse.data || {};
        window.location.href = topUpRequestUrl;
      } else {
        setLoading(false);
        toast.error(initiateTopUpResponse.message);
      }
    };

    return (
      <FundButton type={"button"} disabled={amount <= 0} onClick={onSubmit}>
        Continue
      </FundButton>
    );
  };

  return loading ? (
    <Loader />
  ) : (
    <DesktopBackgroundLayout>
      <TopHeader title={"Top Up"} backLink={"/user/wallet_index"} />
      <ScreenContainer>
        <ViewContainer top={"0"}>
          <Message
            color={colors.themeTextColor1}
            size={"16px"}
            align={"left"}
            weight={"700"}
            lineHeight={"22px"}
            bottom={"6px"}
          >
            How much do you want to top up?
          </Message>
          <Message
            color="#718596"
            size={"14px"}
            align={"left"}
            top={"0px"}
            weight={"400"}
            lineHeight={"22px"}
            bottom={"32px"}
          >
            Kindly enter the amount you want to top up with
          </Message>
          <Formik
            initialValues={{
              amount: amount || "",
            }}
            enableReinitialize
            onSubmit={async (values, { setErrors }) => {
              values = {};
            }}
          >
            {({ values, initialValues }) => {
              return (
                <InputWithOnchange
                  color="#227EFF"
                  width="100%"
                  borderRadius="4px"
                  label={"Enter Amount"}
                  country={country}
                  type={"text"}
                  placeholder={"Enter Amount"}
                  autoComplete={"off"}
                  inputMode={"numeric"}
                  name="amount"
                  bottom="0"
                  height={"53px"}
                  value={formatCurrency(values.amount, "SA")}
                  onChange={(e) => {
                    const {
                      target: { value: inputValue = 0 },
                    } = e;
                    const value = parseInt(
                      inputValue.replace(/\D/g, "") || 0,
                      10
                    );
                    setAmount(value);
                  }}
                  onKeyUp={(e) => {}}
                  noClearButton={true}
                  initialValues={initialValues}
                />
              );
            }}
          </Formik>
          <FundHook />
        </ViewContainer>
      </ScreenContainer>
    </DesktopBackgroundLayout>
  );
};

export default FundWallet;
