import * as Yup from "yup";

import { containsLettersRegExp } from "../../../../../../utils/regexp/containsLettersRegExp";

export const CodeCheckFormValidationSchema = Yup.object().shape({
  pin: Yup.string()
    .matches(containsLettersRegExp, "PIN cannot contain letters")
    .test("case 1", "Invalid PIN", (value) => {
      if (value && value.trim().length !== 4) {
        return false;
      } else return true;
    })
    .nullable()
    .required("Required"),
  confirmPin: Yup.string()
    .matches(containsLettersRegExp, "PIN cannot contain letters")
    .test("case 1", "Invalid PIN", (value) => {
      if (value && value.trim().length !== 4) {
        return false;
      } else return true;
    })
    .nullable()
    .required("Required"),
});
