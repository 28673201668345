import React, { Fragment, useState } from "react";

import styled from "styled-components";

import { colors } from "../../../../../styles";
import { ReactComponent as ThinkingIcon } from "../../assets/thinking1.svg";
import { ReactComponent as ThinkingColouredIcon } from "../../assets/thinking1Color.svg";
import { ReactComponent as UglyIcon } from "../../assets/ugly1.svg";
import { ReactComponent as UglyColouredIcon } from "../../assets/ugly1Color.svg";
import { ReactComponent as Ugly2Icon } from "../../assets/ugly2.svg";
import { ReactComponent as Ugly2ColouredIcon } from "../../assets/ugly2Color.svg";
import { get3EmojiValue } from "../utils";

import { CircleDiv } from "./EmojiRating";
import { RateParagraph, StarDiv } from "./StarRating";

const EmojiLabel = styled.label`
  &:not(:last-of-type) {
    margin-right: 20px;
  }

  &:first-of-type {
    margin-left: 3px;
  }
`;

const StarRating1 = ({ rating, setRating, showValue, setShowValue }) => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const IconObject = [
    { bw: <UglyIcon />, color: <UglyColouredIcon /> },
    { bw: <ThinkingIcon />, color: <ThinkingColouredIcon /> },
    { bw: <Ugly2Icon />, color: <Ugly2ColouredIcon /> },
  ];
  return (
    <Fragment>
      {IconObject.map((icon, index) => {
        const ratingValue = index + 1;
        return (
          <EmojiLabel key={index}>
            <input
              type="radio"
              name="rating"
              value={get3EmojiValue(ratingValue)}
              onClick={() => {
                setRating(ratingValue);
                setSelectedIndex(index);
              }}
              style={{ display: "none" }}
            />
            {selectedIndex === index ? (
              <StarDiv
                onClick={() => setShowValue(get3EmojiValue(ratingValue))}
              >
                <CircleDiv background={colors.gray4}>{icon.color}</CircleDiv>
                <RateParagraph weight={"500"} color={colors.boldDark}>
                  {get3EmojiValue(ratingValue)}
                </RateParagraph>
              </StarDiv>
            ) : (
              <StarDiv
                onClick={() => setShowValue(get3EmojiValue(ratingValue))}
              >
                <CircleDiv>{icon.bw}</CircleDiv>
                {!showValue && (
                  <RateParagraph>{get3EmojiValue(ratingValue)}</RateParagraph>
                )}
              </StarDiv>
            )}
          </EmojiLabel>
        );
      })}
    </Fragment>
  );
};

export default StarRating1;
