import React, { Fragment, useState, useEffect } from "react";

import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";

import {
  TextInputWithLabel,
  TopHeader,
  PageLogo,
  RippleButton,
} from "../../../../../components";
import { InputBlock } from "../../../../../containers/InputContainer";
import {
  PageTitleWrapper,
  PageTitle,
  PageSubTitle,
} from "../../../../../containers/MessageContainer";
import {
  ScreenContainer,
  PageProgressLine,
} from "../../../../../containers/ScreenContainer";
import {
  checkExistingEmail,
  checkReferralCode,
} from "../../../../../redux/ducks/auth/check/actions";
import { getReferral } from "../../../../../redux/ducks/user/actions";
import { colors } from "../../../../../styles";
import { AUTH_PROVIDE_ACCOUNT_INFO } from "../../../../../utils/mix-panel/constants";
import { mixPanel } from "../../../../../utils/mix-panel/mixPanel";
import DesktopBackgroundLayout from "../../../../DesktopBackgroundLayout";
import StoreIcon from "../business-profile/assets/store.svg";

import { ReactComponent as Spinner } from "./assets/loading-spinner.svg";
import UserAvatar from "./assets/User_avatar.svg";
import { UserSignUpValidationSchema } from "./UserSignUpValidationSchema";

const PageProgressWrap = styled.div`
  position: absolute;
  display: flex;
  right: 15px;
  color: ${colors.themeTextColor0};
  z-index: 100;
`;

const PageProgressStep = styled.span`
  font-size: 14px;
  color: ${colors.themeTextColor1};
`;

const InputGroupWrapper = styled.div`
  position: relative;
`;

const LoaderSpin = styled(Spinner)`
  position: absolute;
  display: flex;
  top: 14px;
  right: 14px;
  width: 20px;
  height: 20px;
  transform: rotate(360deg);
  animation: ld-spinner 1.8s linear infinite;
  @keyframes ld-spinner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0.3;
    }
  }
`;

const InputLabelName = styled.div`
  position: absolute;
  top: 64px;
  color: ${colors.themeTxtColor10};
  font-size: 11px;
  letter-spacing: 0.01em;
  margin: 0 15px;
`;

const CardDetailsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  min-height: 80px;
  background-color: ${colors.deepBlue};
  border-radius: 8px;
  padding: 25px 16px;
  overflow: hidden;
  margin-top: 16px;
  margin-bottom: 32px;
  text-align: center;
  align-items: center;

  &::before {
    content: "";
    position: absolute;
    top: -70px;
    left: -45px;
    background: ${colors.bgBlue};
    width: 176px;
    height: 190px;
    border-radius: 100%;
    opacity: 0.2;
  }
`;

const CardBusinessName = styled.h4`
  color: ${colors.white};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
`;

const Information = styled.h4`
  color: ${colors.white};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: ${colors.themeTxtColor10};
  margin-bottom: 8px;
`;

const CardInitials = styled.h4`
  color: ${colors.pink};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
`;

const CircleAvatar = styled.div`
  display: flex;
  border-radius: 50px;
  background: ${colors.pink2};
  padding: 4px;
  height: 32px;
  width: 32px;
  margin-right: 8px;
  text-align: center;
  align-items: center;
  justify-content: center;
`;

const MerchantSignUp = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const { msisdn: phoneNumber, country } = user && user;
  const { isLoading: loadingAccountReferral } = useSelector(
    (state) => state.user.accountReferral
  );
  const [referralLinkCode] = useState(
    localStorage.getItem("referralCode") || ""
  );
  let userData = localStorage.getItem("userData");
  const {
    shopName = "",
    firstName,
    lastName,
    email,
    referralCode,
    referringAgentName,
  } = (userData && JSON.parse(userData)) || {};
  const [userReferralNumber, setUserReferralNumber] = useState();
  const [referralName, setReferralName] = useState("");

  useEffect(() => {
    if (userReferralNumber && userReferralNumber.length === 10) {
      dispatch(getReferral(userReferralNumber)).then((res) => {
        if (res) {
          setReferralName(`${res?.firstName || ""}  ${res?.lastName || ""}`);
        } else {
          setReferralName("");
        }
      });
    } else {
      setReferralName("");
    }
  }, [dispatch, userReferralNumber]);

  const onSubmit = (values, { setErrors }) => {
    setTimeout(async () => {
      if (!values.email) values.email = `${phoneNumber}@spaceso2o.com`;

      const hasExistingEmail = await dispatch(checkExistingEmail(values.email));
      if (hasExistingEmail === undefined) return;
      if (hasExistingEmail) return setErrors({ email: "Email already exists" });

      if (values.referralCode) {
        const refCode = values.referralCode.replace(/\s/g, "");
        if (refCode && refCode.length < 10) {
          toast.error("Invalid referral number");
          return;
        }

        const refNumber = `0${refCode.substr(refCode.length - 9)}`;
        var isSameUser = phoneNumber.localeCompare(refNumber);
        if (isSameUser === 0) {
          toast.error("You can not use your referral code here");
          return;
        }

        const isValidReferral = await dispatch(checkReferralCode(refNumber));
        if (!isValidReferral) {
          return;
        }
      }

      userData = JSON.parse(userData);
      userData = { ...userData, ...values };
      localStorage.setItem("userData", JSON.stringify(userData));

      mixPanel.track(AUTH_PROVIDE_ACCOUNT_INFO, {
        "Was Referred": values.referralCode !== "",
        Role: "Merchant",
        "Email Provided": !values.email.includes("@spaceso2o.com"),
      });

      history.push("/user/create_business_profile");
    }, 200);
  };

  return (
    <Fragment>
      <DesktopBackgroundLayout>
        <TopHeader title={"Seller Account"}>
          <PageProgressWrap>
            <PageProgressStep>1</PageProgressStep>/3
          </PageProgressWrap>
          <PageProgressLine lineWidth={"30%"} />
        </TopHeader>

        <ScreenContainer>
          {shopName ? (
            <div>
              <PageLogo
                top={"75px"}
                margin={"auto"}
                Icon={StoreIcon}
                borderRadius={"0"}
                iconWidth="72px"
                iconHeight="72px"
              />
              <PageTitleWrapper mtop={"10px"} mbottom={"35px"}>
                <PageTitle size={"16px"} top={"16px"} bottom={"6px"}>
                  We found a store you created
                </PageTitle>
                <PageSubTitle margin={"0"}>
                  Tell us more about your business
                </PageSubTitle>
              </PageTitleWrapper>
            </div>
          ) : (
            <div>
              <PageLogo
                top={"75px"}
                margin={"auto"}
                Icon={UserAvatar}
                iconWidth="72px"
                iconHeight="72px"
              />
              <PageTitleWrapper mtop={"10px"} mbottom={"35px"}>
                <PageTitle size={"16px"} top={"16px"} bottom={"6px"}>
                  Personal information
                </PageTitle>
                <PageSubTitle margin={"0"}>
                  Let's get to know more about you
                </PageSubTitle>
              </PageTitleWrapper>
            </div>
          )}

          <Formik
            initialValues={{
              firstName: firstName !== "Not Set" ? firstName : "",
              lastName: lastName !== "Not Set" ? lastName : "",
              email: email || "",
              shopName: shopName || "",
              referringAgentName,
              referralCode: referralCode
                ? referralCode.trim()
                : referralLinkCode
                ? referralLinkCode.trim()
                : "",
              country,
              phoneNumber,
            }}
            enableReinitialize={true}
            validationSchema={UserSignUpValidationSchema}
            onSubmit={onSubmit}
          >
            {({ values }) => {
              if (values.referralCode) {
                setUserReferralNumber(values.referralCode);
              }

              return (
                <Form>
                  <InputBlock>
                    {shopName ? (
                      <div>
                        <CardDetailsWrapper>
                          <CircleAvatar>
                            <CardInitials>
                              {shopName
                                .match(/(\b\S)?/g)
                                .join("")
                                .match(/(^\S|\S$)?/g)
                                .join("")
                                .toUpperCase()}
                            </CardInitials>
                          </CircleAvatar>
                          <CardBusinessName>{shopName}</CardBusinessName>
                        </CardDetailsWrapper>
                        <Information>
                          Let's get to know more about you
                        </Information>
                      </div>
                    ) : (
                      <div>
                        <TextInputWithLabel
                          type="text"
                          label="Shop Name"
                          name="shopName"
                          placeholder="Shop Name"
                        />
                      </div>
                    )}

                    <TextInputWithLabel
                      type="text"
                      label="First name"
                      name="firstName"
                      placeholder="First name"
                    />

                    <TextInputWithLabel
                      type="text"
                      label="Last name"
                      name="lastName"
                      placeholder="Last name"
                    />

                    <TextInputWithLabel
                      type="email"
                      label="Email"
                      name="email"
                      placeholder="Email"
                    />

                    <InputGroupWrapper>
                      <TextInputWithLabel
                        type="text"
                        label="Referral Number"
                        name="referralCode"
                        placeholder="Referral Number"
                      />

                      {loadingAccountReferral === "LOADING" && <LoaderSpin />}
                      {loadingAccountReferral === "SUCCESSFUL" && (
                        <InputLabelName>{referralName}</InputLabelName>
                      )}
                    </InputGroupWrapper>

                    <RippleButton
                      top={"60px"}
                      type="submit"
                      disabled={
                        values.firstName === "" ||
                        values.lastName === "" ||
                        (values.referralCode && !referralName)
                      }
                    >
                      Continue
                    </RippleButton>
                  </InputBlock>
                </Form>
              );
            }}
          </Formik>
        </ScreenContainer>
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default MerchantSignUp;
