import React, { useState, useEffect } from "react";

import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as Download } from "../../../../assets/download.svg";
import { TopHeader, RippleButton } from "../../../../components";
import { ReactComponent as BackArrow } from "../../../../components/header/assets/back_icon.svg";
import { SlidingOverlay } from "../../../../containers/OverlayContainer";
import { ScreenContainer } from "../../../../containers/ScreenContainer";
import { colors } from "../../../../styles";
import DesktopBackgroundLayout from "../../../DesktopBackgroundLayout";
import Closed from "../assets/closed.svg";
import { CategoryCover } from "../complaint-history";
import { classPick, divColor, textColor } from "../utils";
import { getComplaintById } from "../../../../redux/ducks/help/actions";
import { getComplaintComments } from "../../../../redux/ducks/applications/complaints/actions";

const CategoryText = styled.h3`
  color: ${({ color }) => color || colors.themeTextColor13};
  font-size: ${({ size }) => size || "14px"};
  font-weight: ${({ weight }) => weight || "400"};
  margin-bottom: ${({ bottom }) => bottom || null};
  padding: ${({ padding }) => padding || null};

  .open {
    color: ${colors.themeTxtColor11};
    font-size: 14px;
    font-weight: 500;
  }

  .hold {
    color: ${colors.themeTxtColor11};
    font-size: 14px;
    font-weight: 500;
  }

  .closed {
    color: ${colors.themeTxtColor12};
    font-size: 14px;
    font-weight: 500;

    &::before {
      content: url(${Closed});
      margin-right: 8px;
    }
  }
`;

const CategoryDiv = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  margin-bottom: 27px;
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CatDiv = styled.div`
  display: flex;
  align-items: center;
  .open {
    color: ${colors.textRed};
    font-size: 14px;
    font-weight: 500;
  }

  .hold {
    color: ${colors.themeSubColor2};
    font-size: 14px;
    font-weight: 500;
  }

  .closed {
    color: ${colors.themeTxtColor12};
    font-size: 14px;
    font-weight: 500;
    align-items: center;

    &::before {
      content: url(${Closed});
      margin-right: 8px;
    }
  }
`;

const Text = styled.h3``;

const DetailsDiv = styled.div`
  background: ${colors.white};
  margin-bottom: ${({ bottom }) => bottom || "24px"};
`;
const DetailsWrapper = styled.div`
  padding: ${({ padding }) => padding || "16px"};
`;
const ItemDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ bottom }) => bottom || "24px"};
`;

const ButtonWrapper = styled.div`
  padding: 0 16px;
`;

const Paragraph = styled.div`
  color: ${colors.boldDark};
  font-weight: 400;
  font-size: 14px;
  overflow-wrap: break-word;
`;

const Back = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  position: absolute;
  left: 16px;
  top: 16px;
`;

const AnchorLink = styled.a``;

const ComplaintDetails = ({
  complaint: data,
  open,
  setOpen,
  colorIndex,
  cancel,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const firstName = useSelector((state) => state.user.firstName);
  const lastName = useSelector((state) => state.user.lastName);

  const [complaint, setComplaint] = useState(data);
  const [complaintFiles, setComplaintFiles] = useState([]);
  const [comments, setComments] = useState([]);
  const values = [
    {
      name: "Date Created",
      value: `${moment(new Date(complaint?.createdTime)).format(
        "MMMM D, hh:mm A"
      )}`,
    },
    { name: "Reference", value: complaint?.issueReferenceId },
    {
      name: "Agent (CS Representative)",
      value: complaint?.assignee
        ? `${complaint?.assignee?.firstName} ${complaint?.assignee?.lastName}`
        : "Not assigned",
    },
    { name: "Complainant Name", value: `${firstName} ${lastName}` },
  ];

  const handleClick = () => {
    history.push("/help/complaint_history");
    setOpen(!open);
    cancel && cancel();
  };

  useEffect(() => {
    if (Object.keys(data || {}).length > 0) {
      const fetchData = async () => {
        const response = await dispatch(
          getComplaintById(data?.issueReferenceId)
        );
        response && setComplaint(response?.data);

        const commentsData = await dispatch(getComplaintComments(data?.id));
        const { ticketFiles, ticketComments } = commentsData || {};
        if (ticketFiles?.length > 0 || ticketComments?.length > 0) {
          setComplaintFiles(ticketFiles);
          setComments([...ticketFiles, ...ticketComments]);
        } else {
          return setComments([]);
        }
      };
      fetchData();
    }
  }, [dispatch, data]);

  return (
    open && (
      <SlidingOverlay>
        <DesktopBackgroundLayout>
          <TopHeader title={"Complaint Details"}>
            <Back onClick={handleClick}>
              <BackArrow />
            </Back>
          </TopHeader>
          <ScreenContainer
            color={colors.lightestGray}
            padding="1em 0"
            minHeight="100vh"
          >
            <CategoryDiv>
              <TextWrapper>
                <CategoryText>Complaint Category</CategoryText>
              </TextWrapper>
              <CategoryCover
                bottom="0"
                color={textColor(colorIndex)}
                background={divColor(colorIndex)}
              >
                {complaint?.subject}
              </CategoryCover>
            </CategoryDiv>
            <CategoryText
              size="10px"
              weight="400"
              color={colors.lightDark}
              bottom="7px"
              padding="0 16px"
            >
              COMPLAINT DETAILS
            </CategoryText>
            <DetailsDiv>
              <DetailsWrapper>
                <ItemDiv>
                  <CategoryText
                    size="14px"
                    weight="400"
                    color={colors.lightDark}
                  >
                    Status
                  </CategoryText>
                  <CatDiv>
                    <Text className={classPick(complaint?.status)}>
                      {complaint?.status}
                    </Text>
                  </CatDiv>
                </ItemDiv>

                {values?.map((item, index) => (
                  <ItemDiv key={index}>
                    <CategoryText
                      size="14px"
                      weight="400"
                      color={colors.lightDark}
                    >
                      {item?.name}
                    </CategoryText>
                    <CategoryText
                      size="14px"
                      weight="500"
                      color={colors.boldDark}
                    >
                      {item?.value}
                    </CategoryText>
                  </ItemDiv>
                ))}
              </DetailsWrapper>
            </DetailsDiv>

            {complaintFiles?.length > 0 && (
              <DetailsDiv>
                <DetailsWrapper>
                  <ItemDiv bottom={"5.5px"}>
                    <CategoryText
                      size="14px"
                      weight="400"
                      color={colors.lightDark}
                    >
                      Uploaded file
                    </CategoryText>
                    <AnchorLink
                      href={complaintFiles[0]?.imageDownloadUrl}
                      rel="noopener noreferrer"
                      target="_blank"
                      download
                    >
                      <Download />
                    </AnchorLink>
                  </ItemDiv>
                  <Paragraph>{complaintFiles[0]?.name}</Paragraph>
                </DetailsWrapper>
              </DetailsDiv>
            )}

            <DetailsDiv>
              <DetailsWrapper>
                <CategoryText
                  size="14px"
                  weight="400"
                  color={colors.lightDark}
                  bottom="4px"
                >
                  Description
                </CategoryText>
                <Paragraph>{complaint?.description}</Paragraph>
              </DetailsWrapper>
            </DetailsDiv>
            <ButtonWrapper>
              <RippleButton
                top={"30%"}
                size={"16px"}
                height={"56px"}
                onClick={() => {
                  history.push({
                    pathname: "/actions/view_comment",
                    state: {
                      reference: complaint?.id,
                      ticketId: complaint?.id,
                    },
                  });
                }}
              >
                Comment ({comments?.length})
              </RippleButton>
            </ButtonWrapper>
          </ScreenContainer>
        </DesktopBackgroundLayout>
      </SlidingOverlay>
    )
  );
};

export default ComplaintDetails;
