import React, { useCallback, useEffect, useState } from "react";

import { useMachine } from "@xstate/react";
import { Form, Formik } from "formik";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { createMachine } from "xstate";

import {
  InputWithOnchange,
  PasswordGroup,
  RippleButton,
  SelectBox,
  SuccessPage,
  TopHeader,
} from "../../../../../components";
import { Message } from "../../../../../containers/MessageContainer";
import {
  FloatingBottomSection,
  ScreenContainer,
} from "../../../../../containers/ScreenContainer";
import {
  getWalletBalance,
  verifyPin,
} from "../../../../../redux/ducks/account/wallet/actions";
import {
  fetchAllBillCategories,
  fetchAllBillersPerCategory,
  submitAirtimePurchaseRequest,
} from "../../../../../redux/ducks/account/wallet/actions/bills-payment";
import { colors } from "../../../../../styles";
import { formatCurrency } from "../../../../../utils/currency/parseBalance";
import { getInputValues } from "../../../../../utils/inputs/getInputValues";
import DesktopBackgroundLayout from "../../../../DesktopBackgroundLayout";
import { CodeCheckFormValidationSchema } from "../wallet-to-wallet/pin/CodeCheckFormValidationSchema";
import RatingPopup from "../../../transactions/wallet-transactions/rating-popup";
import { AirtimePurchaseValidationSchema } from "./AirtimeRechargeValidationSchema";
import NineMobileLogo from "./assets/9-mobile-logo.png";
import AirtelLogo from "./assets/airtel-logo.png";
import GloLogo from "./assets/glo-logo.png";
import { ReactComponent as InflowIcon } from "./assets/inflow.svg";
import MtnLogo from "./assets/mtn-logo.png";
import { ReactComponent as OutflowIcon } from "./assets/outflow.svg";
import { ReactComponent as Spinner } from "./assets/spinner.svg";
import {
  Title,
  ModifiedTransactionLinkIcon,
  ModifiedDiscountIcon,
  Artwork,
  Subtitle,
  CommissionBlock,
  FormWrapper,
  NetworkProviderDetails,
  Paragraph,
  TransactionDetails,
  AmountTitle,
  Balance,
  Details,
  OutflowAccount,
  FlowTitle,
  NewtworkProviderAndAmount,
  PhoneNumber,
  TransactionPinFormWrapper,
  TotalAmountBlock,
  NetworkProviderLogo,
  TransactionWrapper,
  TotalTransactionAmount,
  WalletBalance,
  WalletBalanceText,
  WalletBalanceAmount,
  SelectedNetworkProvider,
  SelectedNetworkProviderLogo,
  LoadingContainer,
} from "./assets/styles";

const networkProviders = [
  {
    label: "Airtel",
    icon: <AirtelLogo />,
    value: "airtel",
  },
  { label: "MTN", icon: <MtnLogo />, value: "mtn" },
  { label: "Glo", icon: <GloLogo />, value: "glo" },
  { label: "9mobile", icon: <NineMobileLogo />, value: "9mobile" },
];

const networkProviderLogos = new Map([
  ["Airtel", AirtelLogo],
  ["MTN", MtnLogo],
  ["Glo", GloLogo],
  ["9mobile", NineMobileLogo],
]);

const pages = {
  CONFIGURATION: "CONFIGURATION",
  CONFIRMATION: "CONFIRMATION",
};

// This machine is completely decoupled from React
const changePageState = createMachine({
  id: "changePage",
  initial: pages.CONFIGURATION,
  states: {
    [pages.CONFIGURATION]: {
      on: { NEXT: pages.CONFIRMATION },
    },
    [pages.CONFIRMATION]: {
      on: {
        BACK: pages.CONFIGURATION,
      },
    },
  },
});

// when there is a range per network provider
// just put them in here and validation will run
const rangeForNetworkProviders = new Map([
  ["Airtel", { min: 100, max: 20000 }],
  ["MTN", { min: 100, max: 20000 }],
  ["Glo", { min: 100, max: 20000 }],
  ["9mobile", { min: 100, max: 20000 }],
]);

export function AirtimeRecharge() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [currentPage, send] = useMachine(changePageState);
  const walletBalance = useSelector((state) => state.account.wallet.balance);
  const country = useSelector((state) => state.user.country);
  const [transaction, setTransaction] = useState({
    amount: "",
    phoneNumber: "",
    networkProvider: "",
  });
  const transactions = useSelector(
    (state) => state.account.transactions.transactionsList
  );
  const successfulTransactions = transactions.filter(
    (x) => x.transactionStatus === "SUCCESSFUL"
  );
  const [transactionStatus, setTransactionStatus] = useState("");
  const [openRating, setOpenRating] = useState(false);
  const billCategories =
    useSelector((state) => state.account.wallet?.billCategories) || [];
  const billersPerCategory =
    useSelector((state) => state.account.wallet?.billersPerCategory) || [];
  const walletErrorMessage =
    useSelector((state) => state.account.wallet?.errorMsg) || "";

  useEffect(() => {
    // we are making this request already on the previous page
    // but for whatever reason, if it is not available, we will dispatch here again

    if (billCategories.length === 0) {
      dispatch(fetchAllBillCategories());
    }
  }, [dispatch, billCategories]);

  useEffect(() => {
    if (billersPerCategory.length > 0 || billersPerCategory.length > 0) return;

    // get the billers for airtime purchase here
    const airtimeBill = billCategories.find(
      (category) => category.name === "Airtime Purchase"
    );

    if (!airtimeBill) return;

    dispatch(fetchAllBillersPerCategory(airtimeBill?.id));
  }, [dispatch, billersPerCategory, billCategories]);

  const updateTransaction = (values) => {
    setTransaction(values);
    send("NEXT");
  };

  const handleTransactionSubmission = async () => {
    // call the create airtime function here
    // show success page

    const selectedBiller = billersPerCategory.find(
      (biller) =>
        biller.name.toUpperCase() === transaction.networkProvider.toUpperCase()
    );

    const { amount, phoneNumber } = transaction;

    const airtimePurchase = {
      billerId: selectedBiller?.billerId,
      denomination: amount,
      msisdn: phoneNumber,
    };

    const airtimePurchasePurchaseRequestWasSuccessful = await dispatch(
      submitAirtimePurchaseRequest(airtimePurchase)
    );

    if (airtimePurchasePurchaseRequestWasSuccessful) {
      setTransactionStatus("success");
      setOpenRating(!openRating);
      return;
    }

    setTransactionStatus("failed");
  };

  const handleBackClick = () => {
    if (currentPage.value === pages.CONFIRMATION) {
      send("BACK");
      history.goBack();
      return;
    }
  };

  const withBlueBackground = () => {
    if (currentPage.value === pages.CONFIGURATION) return false;

    return true;
  };

  return (
    <DesktopBackgroundLayout
      style={{ background: "#227EFF" }}
      withBlueBackground={withBlueBackground()}
    >
      <div
        style={{
          visibility: transactionStatus === "success" ? "visible" : "hidden",
        }}
      >
        <SuccessPage
          title="Transaction Successful"
          subtitle="Your payment was successful"
          doneText="Done"
          detailText="View transaction details"
          showDetailButton={false}
          onDoneClick={() => history.push("/user/wallet_index")}
          onDetailClick={() => history.push("/user/wallet_index")}
        />
        {(transactions.length === 1 || transactions.length % 10 === 0) && (
          <div style={{ visibility: openRating ? "visible" : "hidden" }}>
            <RatingPopup
              refId={successfulTransactions[0]?.transactionReference}
              setOpen={() => setOpenRating(!openRating)}
            />
          </div>
        )}
      </div>

      <div
        style={{
          visibility: transactionStatus === "failed" ? "visible" : "hidden",
        }}
      >
        <SuccessPage
          title="Transaction failed!"
          subtitle="We couldn't complete your transaction. Kindly try again"
          doneText="Try Again"
          successfull={false}
          onDoneClick={() => {
            setTransactionStatus("");
            toast.error(walletErrorMessage);
          }}
        />
      </div>

      {transactionStatus === "" && (
        <>
          <TopHeader
            backgroundColor={withBlueBackground() ? colors.deepBlue : ""}
            title={"Buy Airtime"}
            backAction={handleBackClick}
            backLink={currentPage.value === pages.CONFIRMATION ? "#" : null}
            color={withBlueBackground ? colors.white : colors.black}
          />
          <ScreenContainer
            style={{ position: withBlueBackground() ? "absolute" : "static" }}
          >
            {currentPage.value === pages.CONFIGURATION && (
              <AmountConfiguration
                country={country}
                onSubmit={updateTransaction}
                walletBalance={walletBalance}
                transactionDetails={transaction}
              />
            )}
            {currentPage.value === pages.CONFIRMATION && (
              <TransactionConfirmation
                transactionDetails={transaction}
                walletBalance={walletBalance}
                handleTransactionSubmission={handleTransactionSubmission}
              />
            )}
          </ScreenContainer>
        </>
      )}
    </DesktopBackgroundLayout>
  );
}

function VerifyTransactionPin({ handleTransactionSubmission }) {
  const [pin, setPin] = useState("");
  const [value, setValue] = useState("");
  const dispatch = useDispatch();

  const verifyTransactionPin = useCallback(async () => {
    if (String(pin).length === 4) {
      // make the call to verify PIN here
      const pinVerificationSuccess = await dispatch(verifyPin(pin));

      if (pinVerificationSuccess) {
        await handleTransactionSubmission();
        return;
      }

      toast.error("Invalid Transaction PIN");
    }
  }, [dispatch, handleTransactionSubmission, pin]);

  const handlePinInput = (event, setFieldValue) => {
    const pinInput = getInputValues("transactionPin");
    setValue(event.target.value);

    setFieldValue("transactionPin", pinInput);
    setPin(pinInput);
  };

  useEffect(() => {
    if (String(pin).length === 4) {
      verifyTransactionPin();
    }
  }, [pin, verifyTransactionPin]);

  return (
    <TransactionPinFormWrapper>
      <Message color={"#071827"} top={"0"} bottom={"4px"} size={"10px"}>
        Enter Transaction PIN to continue
        <Formik
          initialValues={{
            transactionPin: "",
          }}
          validationSchema={CodeCheckFormValidationSchema}
        >
          {({ touched, valid, errors, setFieldValue }) => (
            <PasswordGroup
              count={4}
              startIndex={1}
              type={"password"}
              valid={valid}
              errors={errors}
              name={"transactionPin"}
              touched={touched}
              align={"center"}
              enteredValue={value || ""}
              handleChange={(event) => handlePinInput(event, setFieldValue)}
            />
          )}
        </Formik>
      </Message>
    </TransactionPinFormWrapper>
  );
}

function TransactionConfirmation({
  transactionDetails,
  walletBalance,
  handleTransactionSubmission,
}) {
  const isLoading = useSelector((state) => state.account.wallet.isLoading);
  const { networkProvider } = transactionDetails;

  return (
    <>
      <TransactionDetails>
        <NetworkProviderDetails>
          <Artwork>
            <OutflowIcon />
            <ModifiedTransactionLinkIcon />
            <InflowIcon />
          </Artwork>
          <Details>
            <TransactionWrapper>
              <FlowTitle>From</FlowTitle>
              <OutflowAccount>My Wallet</OutflowAccount>
              <Balance>Bal:{formatCurrency(walletBalance)}</Balance>
            </TransactionWrapper>

            <TransactionWrapper>
              <FlowTitle>To</FlowTitle>
              <NewtworkProviderAndAmount>
                <NetworkProviderLogo
                  src={networkProviderLogos.get(networkProvider)}
                />
                <TransactionWrapper>
                  <PhoneNumber>{transactionDetails.phoneNumber}</PhoneNumber>
                  <Paragraph>
                    Amount: {formatCurrency(transactionDetails.amount)}
                  </Paragraph>
                </TransactionWrapper>
              </NewtworkProviderAndAmount>
            </TransactionWrapper>
          </Details>
        </NetworkProviderDetails>

        <TotalAmountBlock>
          <AmountTitle>Amount</AmountTitle>
          <TotalTransactionAmount>
            {formatCurrency(transactionDetails.amount)}
          </TotalTransactionAmount>
        </TotalAmountBlock>
      </TransactionDetails>

      <VerifyTransactionPin
        transactionDetails={transactionDetails}
        handleTransactionSubmission={handleTransactionSubmission}
      />

      {isLoading ? (
        <LoadingContainer>
          <Spinner />
        </LoadingContainer>
      ) : null}
    </>
  );
}

function AmountConfiguration({
  country,
  onSubmit,
  walletBalance,
  transactionDetails,
}) {
  const dispatch = useDispatch();
  const fetchingWalletBalance = useSelector(
    (state) => state.account.wallet.fetchingWalletBalance
  );

  const formHasError = (errors) =>
    Object.values(errors).some((error) => error.length > 0);
  const allBillers =
    useSelector((state) => state.account.wallet?.billersPerCategory) || [];

  const handleAmountInput = (
    event,
    networkProvider,
    setFieldValue,
    setFieldError,
    setFieldTouched
  ) => {
    const {
      target: { value: inputValue = 0 },
    } = event;

    const value = parseInt(inputValue.replace(/\D/g, "") || 0, 10);
    setFieldValue("amount", value, false);
    validateAmount(networkProvider, value, setFieldError, setFieldTouched);
  };

  const validateAmount = (
    networkProvider,
    amount,
    setFieldError,
    setFieldTouched
  ) => {
    const { min, max } = rangeForNetworkProviders.get(networkProvider);

    setFieldTouched("amount", true, false);

    if (!amount) {
      setFieldError("amount", "Please enter a valid amount");

      return;
    }

    if (amount > walletBalance) {
      setFieldError("amount", "Insufficient balance!");
      return false;
    }

    if (amount < min) {
      setFieldError(
        "amount",
        `Amount must be at least ${formatCurrency(min, "SA")}`
      );

      return false;
    } else if (amount > max) {
      setFieldError(
        "amount",
        `The maximum amount is ${formatCurrency(max, "SA")}`
      );
      return false;
    } else {
      setFieldError("amount", "");
      return true;
    }
  };

  const getCommissionPercentage = (networkProvider, airtimeAmount) => {
    if (!networkProvider || !airtimeAmount) return 0;

    const networkProviderData =
      allBillers.find((biller) => biller.name === networkProvider) || {};

    // This will probably never happen but just in case a user makes to this place without the billers fetched, we will default to 0% commission
    if (!networkProviderData) return 0;

    const commissionRate = networkProviderData.commissionRate;
    const commission = (commissionRate / 100) * airtimeAmount;

    return commission;
  };

  useEffect(() => {
    dispatch(getWalletBalance());
  }, [dispatch]);

  return (
    <>
      <Title>How much airtime would you like to buy?</Title>
      <Subtitle>
        Kindly provide the following details for your recharge.
      </Subtitle>

      <FormWrapper>
        <Formik
          initialValues={{
            networkProvider: transactionDetails.networkProvider || "",
            phoneNumber: transactionDetails.phoneNumber || "",
            amount: transactionDetails.amount || "",
          }}
          validationSchema={AirtimePurchaseValidationSchema}
          onSubmit={(values, { setFieldError, setFieldTouched }) => {
            const amountIsValid = validateAmount(
              values.networkProvider,
              values.amount,
              setFieldError,
              setFieldTouched
            );

            if (amountIsValid) {
              onSubmit(values);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            initialValues,
            setFieldError,
            setFieldTouched,
          }) => (
            <Form>
              <FormWrapper>
                <SelectBox
                  placeholder={"Network Provider"}
                  value={values.networkProvider}
                  options={networkProviders}
                  handleChange={(selected) => {
                    setFieldValue("networkProvider", selected.label, false);
                  }}
                  valid={`${
                    !touched.networkProvider && !errors.networkProvider
                  }`}
                  error={
                    touched &&
                    touched.networkProvider &&
                    errors &&
                    errors.networkProvider
                  }
                >
                  <SelectedNetworkProvider>
                    <SelectedNetworkProviderLogo
                      src={networkProviderLogos.get(values.networkProvider)}
                      alt={values.networkProvider}
                    />
                    {values.networkProvider}
                  </SelectedNetworkProvider>
                </SelectBox>

                <InputWithOnchange
                  label={"Phone Number"}
                  type={"text"}
                  inputMode={"tel"}
                  value={values.phoneNumber}
                  placeholder={"Phone Number"}
                  name="phoneNumber"
                  valid={`${touched.phoneNumber && !errors.phoneNumber}`}
                  autoComplete={"off"}
                  errors={touched.phoneNumber && errors && errors.phoneNumber}
                  bottom="0px"
                  top="0"
                  maxLength={11}
                  background="#F2F5FA"
                  onChange={(e) =>
                    setFieldValue(
                      "phoneNumber",
                      e.target.value.replace(/\s/g, ""),
                      true
                    )
                  }
                  noClearButton={true}
                  disabled={values.networkProvider === ""}
                  initialValues={initialValues}
                />

                <InputWithOnchange
                  color="#227EFF"
                  width="100%"
                  borderRadius="4px"
                  label={"Enter Amount"}
                  country={country}
                  type={"text"}
                  placeholder={"Enter Amount"}
                  autoComplete={"off"}
                  inputMode={"numeric"}
                  name="amount"
                  bottom="0"
                  height={"53px"}
                  errors={errors && errors.amount}
                  disabled={values.networkProvider === ""}
                  value={formatCurrency(values.amount, "SA")}
                  onChange={(event) =>
                    handleAmountInput(
                      event,
                      values.networkProvider,
                      setFieldValue,
                      setFieldError,
                      setFieldTouched
                    )
                  }
                  noClearButton={true}
                  initialValues={initialValues}
                />
                {fetchingWalletBalance ? (
                  <Skeleton width={"50%"} height={10} />
                ) : errors.amount ? null : (
                  <WalletBalance>
                    <WalletBalanceText>Wallet balance</WalletBalanceText>
                    <WalletBalanceAmount>
                      {formatCurrency(walletBalance, "SA")}
                    </WalletBalanceAmount>
                  </WalletBalance>
                )}

                <CommissionBlock>
                  <Paragraph>
                    <ModifiedDiscountIcon />
                    Commission earned
                  </Paragraph>
                  <Paragraph>
                    {formatCurrency(
                      getCommissionPercentage(
                        values.networkProvider,
                        values.amount
                      )
                    )}
                  </Paragraph>
                </CommissionBlock>
                {!values.amount ? (
                  <Subtitle margin={"-10px 0 0"}>
                    Enter amount to show how much you can earn on this recharge
                  </Subtitle>
                ) : null}

                <FloatingBottomSection>
                  <RippleButton
                    type="submit"
                    top={"2px"}
                    backgroundColor={colors.deepBlue}
                    disabled={
                      values.networkProvider === "" || formHasError(errors)
                    }
                  >
                    Continue
                  </RippleButton>
                </FloatingBottomSection>
              </FormWrapper>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </>
  );
}
