export const parseTextCountRender = (count, word) => {
  let countToRender = `${count} ${word}s`;

  if (count < 2) {
    countToRender = `${count} ${word}`;
  }

  return countToRender;
};

export const grammarSpellCheck = (count) => {
  return count < 2 ? "is" : "are";
};

export const peopleGrammerCheck = (count) => {
  return count < 2 ? "person" : "people";
};
