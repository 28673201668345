import React, { memo, Fragment, useState, useEffect } from "react";

import { Form, Formik } from "formik";
import moment from "moment";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar, utils } from "react-modern-calendar-datepicker";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import "rc-swipeout/assets/index.css";
import useRouter from "use-react-router";

import { ReactComponent as CustomerSVG } from "../../../../../assets/customer.svg";
import {
  RippleButton,
  TopHeader,
  ConfirmPopupDialog,
  TextareaWithLabel,
} from "../../../../../components";
import { NewAmountInputWithLabel } from "../../../../../components/forms/input/new-amount-input";
import { Message } from "../../../../../containers/MessageContainer";
import { Overlay } from "../../../../../containers/OverlayContainer";
import { createSale } from "../../../../../redux/ducks/applications/my-shop/actions/sales";
import {
  searchProductsOnMasterList,
  addProductOnTheFly,
} from "../../../../../redux/ducks/applications/my-shop/actions/shop";
import { colors } from "../../../../../styles";
import { formatCurrency } from "../../../../../utils/currency/parseBalance";
import History from "../../../../../utils/History";
import DesktopBackgroundLayout from "../../../../DesktopBackgroundLayout";
import { AddProductsForSale } from "../../sales/add-products";
import SaleSuccess from "../sale-success";

import { ReactComponent as ArrowIcon } from "./assets/arrow.svg";
import { ReactComponent as ArrowBlackIcon } from "./assets/arrowBlack.svg";
import img from "./assets/calenderarrow.svg";
import { ReactComponent as CancelIcon } from "./assets/cancel.svg";
import { ReactComponent as CancelX } from "./assets/cancelX.svg";
import { ReactComponent as AddIcon } from "./assets/cross.svg";
import { ReactComponent as CurvedIcon } from "./assets/Curved.svg";
import { ReactComponent as DeleteSVG } from "./assets/delete.svg";
import firstTimeTutorial from "./assets/firstTime.svg";
import { ReactComponent as GoSVG } from "./assets/go.svg";
import { ReactComponent as RadioActive } from "./assets/radioActive.svg";
import { ReactComponent as RadioInactive } from "./assets/radioInactive.svg";
import { CustomersPopUp } from "./components/customers";
import { DiscountPopUp } from "./components/discount";
import ListProducts from "./components/list-products";
import { CustomWeekdays } from "./components/locale";
import AddSalesOptionPage from "./components/sales-options-page";
import { SaleValidationSchema } from "./SaleValidationSchema";
import { criterias } from "./validateSettingsOptions";

export const CalendarStyleOverride = styled.div`
    .custom-today-day {
        border-radius: 0 !important;
    }
    .Calendar__day.-selected {
        color: white !important;
    }
    .custom-calendar {
        border-radius: 0px !important;
        width: 100%;
        max-width: inherit;
        margin: 0 auto;
    }
    .Calendar__header {
        padding: 1em 2.9em 2.7em 2.9em !important;
    }
    .Calendar__weekDay {
        color: black !important;
        font-weight: 500 !important;
        font-size: 12px !important;
    }
    .Calendar__sectionWrapper {
        min-height: 24.8em;
    }
    .Calendar__monthYear > * {
        padding: 0 !important;
    }
    abbr[title] {
        text-decoration: none !important;
    }
    .Calendar__weekDay {
        color: black !important;
        font-weight: 500 !important;
        font-size: 12px !important;
    }
    .Calendar__monthArrow {
        background-image: url(${img});
    }
   .Calendar__day.-ltr.-selectedStart {
        border-radius: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .Calendar__day.-ltr.-selectedEnd {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-radius: 0;
    }
    .Calendar__day.-disabled {
        opacity: 0.4;
    }
    .Calendar__day.-selectedBetween {
        background: white;
        color: var(--cl-color-primary);
        border-radius: 0;
    .Calendar.-rtl .Calendar__monthArrowWrapper.-left,
    .Calendar__monthArrowWrapper.-right {
        transform: rotate(0deg);
    }
    .Calendar__day {
        color: black !important;
    }
    .Calendar.-rtl .Calendar__monthArrowWrapper.-right,
    .Calendar__monthArrowWrapper.-left {
        transform: rotate(180deg);
    }
`;

const PaymentInformationBlock = styled.div`
  margin: 7px 0 4px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding-bottom: 16px;
`;
const TutorialBg = styled.div`
  background: url("${firstTimeTutorial}");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 45.68px;
  margin-top: 57px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 1em;
`;
const Text = styled.div`
  color: ${({ color }) => color || "#212c3d"};
  font-size: ${({ size }) => size || "14px"};
  margin-top: ${({ top }) => top || ""};
  margin-bottom: ${({ bottom }) => bottom || ""};
  margin-left: ${({ left }) => left || ""};
  margin-right: ${({ right }) => right || ""};
  font-weight: ${({ weight }) => weight || ""};
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: ${({ position }) => position || "space-between"};
  padding: 10px ${({ paddingX }) => paddingX || "1em"};
  align-items: center;
`;

const OptionBox = styled.div`
  padding: ${({ padding }) => padding || "1em"};
  display: flex;
  align-items: center;
  justify-content: ${({ position }) => position || "flex-start"};
`;

const AddFlex = styled.div`
  display: flex;
  flex-direction: ${({ column }) => (column ? "column" : "row")};
  align-items: center;
  margin: ${({ margin }) => margin || "0 8px"};
`;

const CustomerName = styled.div`
  color: #071827;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
`;

const SectionHeader = styled.div`
  color: #212c3d;
  line-height: 15px;
  font-size: 10px;
  padding: ${({ padding }) => padding || "16px"};
  display: flex;
  align-items: center;
  height: 48px;
`;

const Span = styled.span`
  color: ${({ color }) => color || "#227eff"};
  font-size: ${({ size }) => size || "12px"};
  font-weight: ${({ weight }) => weight || "400"};
  margin-left: ${({ left }) => left || "auto"};
`;

const ErrorMessage = styled.div`
  color: red;
  margin: 4px 0;
  font-style: oblique;
  font-size: 10px;
  font-weight: 400;
`;

const TitleWrapper = styled.div`
  position: absolute;
  top: 23;
  left: 1em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SettingsWrapper = styled.div`
  position: absolute;
  top: 23;
  right: 1em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 97px;
  height: 32px;
  background: #708af6;
  border-radius: 34px;
`;

const Line = styled.div`
  width: 17px;
  height: 0px;
  opacity: 0.4;
  border: 0.5px solid ${colors.white};
  transform: rotate(90deg);
`;

const Radio = styled.div`
  width: 164px;
  height: 42px;
  background: ${({ active }) => (active ? "#E9F2FF" : colors.white)};
  border: 1px solid ${({ active }) => (active ? "#227EFF" : "#cbd6e0")};
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CustomerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 53px;
  margin-top: ${({ top }) => top || null};
  background: ${colors.white};
  padding: ${({ padding }) => padding || "10px 1em"};
`;

const WhiteWrapper = styled.div`
  background: ${colors.white};
  padding: ${({ paddingY }) => paddingY || "10px"}
    ${({ paddingX }) => paddingX || "1em"};
  margin-top: ${({ top }) => top || "4px"};
  width: 100%;
  height: ${({ height }) => height || null};
`;

const TodayWrapper = styled.div`
  height: 53px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const FooterWrapper = styled.div`
  height: 72px;
  position: fixed;
  background: ${colors.white};
  padding: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 0;
  right: 0;
  bottom: 0px;
  width: 100%;
  max-width: 375px;
  margin: auto;
  @media (max-width: 576px) {
    max-width: 100%;
  }
  z-index: ${({ zIndex }) => zIndex};
`;

const NameWrapper = styled.div`
  height: 24px;
  width: 24px;
  border-radius: 24px;
  background: #fff7e6;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SpanArrow = styled.span`
  transform: matrix(0.41, -0.91, -0.91, -0.41, 0, 0);
  width: 22.73px;
  height: 29.88px;
`;

const Margin = styled.div`
  margin: ${({ margin }) => margin};
  min-height: ${({ minHeight }) => (minHeight ? "87vh" : null)};
`;

const ButtonInnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Display = styled.div`
  display: ${({ open }) => (open ? "block" : "none")};
`;

const Padding = styled.div`
  padding: ${({ padding }) => padding};
`;

export const CalendarCover = styled.div`
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: ${({ bottom }) => bottom || "0px"};
  width: 100%;
  max-width: inherit;
  margin: auto;
  z-index: 100000;
`;

export const CalendarHeaderCover = styled.div`
  background: white;
  width: 100%;
  margin-bottom: 0;
  position: relative;
  bottom: -15px;
  z-index: 10000000;
  border-radius: 16px 16px 0px 0px;
`;

export const CalendarParagraph = styled.p`
  border-bottom: 1px solid #ebf1f6;
  padding: 24px 24px 20px 24px;
  font-weight: 500;
  font-size: 14px;
`;

const AddSale = () => {
  const dispatch = useDispatch();
  const route = useRouter();
  const initSalesOptions = localStorage.getItem("salesOptions")
    ? JSON.parse(localStorage.getItem("salesOptions"))
    : null;

  const { merchantId, branchId } = useSelector(
    (state) => state.applications.myShop.shops
  )[0];

  const currentShop = useSelector(
    (state) => state.applications.myShop.shops
  ).filter((shop) => shop.branchId === branchId)[0];
  const realms = useSelector((state) => state.user.realmNames);

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [text] = useState("Create Sale");
  const [buttonText, setButtonText] = useState(text);
  const [openProductsList, setOpenProductsList] = useState(false);
  const [confirmDeletionOpen, setOpenConfirmDeletionPopup] = useState(false);
  const [productIdToDelete, setProductIdToDelete] = useState(null);
  const [openAddSelectOptions, setOpenAddSelectOptions] = useState(false);
  const [salesOptions, setSalesOptions] = useState({
    addCustomer: initSalesOptions ? initSalesOptions.addCustomer : true,
    addItemSold: initSalesOptions
      ? initSalesOptions.addItemSold
      : realms.includes("ADVANCE_SALES"),
    addDiscount: initSalesOptions
      ? initSalesOptions.addDiscount
      : realms.includes("ADVANCE_SALES"),
    addServiceCharge: initSalesOptions
      ? initSalesOptions.addServiceCharge
      : false,
    addNotes: initSalesOptions ? initSalesOptions.addNotes : true,
    addDeliveryFee: initSalesOptions ? initSalesOptions.addDeliveryFee : false,
    addMoreOptions: initSalesOptions ? initSalesOptions.addMoreOptions : false,
  });

  const [openCustomerDetails, setOpenCustomerDetails] = useState(false);
  const [openDiscountPopup, setOpenDiscountPopup] = useState(false);
  const [openTutorial, setOpenTutorial] = useState(false);
  const [openTutorialCancel, setOpenTutorialCancel] = useState(false);
  const [paymentInFull, setPaymentInFull] = useState(true);
  const [saleResponse, setSaleResponse] = useState({});
  const [paymentTypes, setPaymentTypes] = useState([
    { title: "Full payment", active: true },
    { title: "Part payment", active: false },
  ]);
  const [startTime, setStartTime] = useState("");
  const [discount, setDiscount] = useState(null);

  const product = route.location.state ? route.location.state.product : null;
  const subTotal = salesOptions.addItemSold
    ? [...selectedProducts].reduce(
        (accum, val) => val.quantity * (val.newPrice || val.itemPrice) + accum,
        0
      )
    : 0;
  const [selectedCustomer, setSelectedCustomer] = useState(undefined);
  const [formData, setFormData] = useState({
    saleAmount: [...selectedProducts].reduce(
      (accum, val) => val.quantity * val.itemPrice + accum,
      0
    ),
    paymentAmount: 0,
    amountDue: "",
    customerPhoneNumber: "",
    discount: "",
    profit: 0,
    customerName: "",
    salesItemDetails: selectedProducts || null,
    notes: "",
  });
  const [errorMessage, setErrorMessage] = useState("");

  const dateFormat = (creationDate) => {
    let day;

    if (
      creationDate.day === new Date().getDate() &&
      creationDate.month === new Date().getMonth() + 1 &&
      creationDate.year === new Date().getFullYear()
    ) {
      day = "Today";
    } else if (
      creationDate.day === new Date().getDate() - 1 &&
      creationDate.month === new Date().getMonth() + 1 &&
      creationDate.year === new Date().getFullYear()
    ) {
      day = "Yesterday";
    } else {
      day = `${creationDate.day}/${creationDate.month}/${creationDate.year}`;
    }

    return day;
  };
  const [realDate, setRealDate] = useState(null);
  const [openDate, setOpenDate] = useState(false);
  const TodayDate = {
    day: new Date().getDate(),
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  };
  const [salesDate, setSalesDate] = useState(TodayDate);

  useEffect(() => {
    product &&
      setSelectedProducts((oldArray) => [
        ...oldArray,
        {
          inventoryProductId: product.inventoryProductId,
          id: selectedProducts.length + 1,
          hasInventory: true,
          inStock: true,
          quantity: product.status === "ON_THE_FLY" ? product.quantity : 1,
          name: product.productName,
          itemPrice: Number(product.retailUnitPrice),
          costPrice: Number(product.costPrice),
          base64ProductImageString: product.base64ProductImageString,
        },
      ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  useEffect(() => {
    const showTutorial = localStorage.getItem("settingsTutorial");
    if (!showTutorial) {
      setOpenTutorial(true);
    }
    setStartTime(new Date());
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setButtonText(text);
    }, 3000);
    return () => clearTimeout(timer);
  }, [buttonText, text]);

  const isDisable = (values) => {
    const allValues = {
      ...values,
      paymentInFull,
      selectedProducts,
      selectedCustomer,
    };
    return criterias(salesOptions, allValues);
  };

  const showRadio = !salesOptions.addItemSold || selectedProducts.length > 0;
  const [showSaleSuccess, setShowSaleSuccess] = useState(false);

  const handleCloseSuccess = () => {
    setShowSaleSuccess(false);
    setDiscount(0);
    setSelectedProducts([]);
    setSelectedCustomer(undefined);
    setFormData({
      saleAmount: 0,
      paymentAmount: 0,
      amountDue: "",
      customerPhoneNumber: "",
      discount: "",
      profit: 0,
      customerName: "",
      salesItemDetails: null,
      notes: "",
    });
  };

  const showCustomer =
    (salesOptions.addCustomer && selectedCustomer) ||
    (!paymentInFull && selectedCustomer);
  const cancelTutorial = () => {
    setOpenTutorial(false);
    localStorage.setItem("settingsTutorial", JSON.stringify(true));
  };

  return (
    <Fragment>
      {showSaleSuccess ? (
        <SaleSuccess
          paymentInFull={paymentInFull}
          data={formData}
          date={salesDate}
          subTotal={subTotal || formData?.saleAmount}
          discount={discount}
          cancel={handleCloseSuccess}
          shopName={currentShop?.shopName}
          shopId={currentShop?.branchId}
          customer={selectedCustomer?.customerName}
          products={selectedProducts}
          sale={saleResponse}
        />
      ) : (
        <DesktopBackgroundLayout bgColor="#F4F6F9">
          <>
            {!openProductsList && (
              <TopHeader
                noBorderBottom
                noTitle
                backgroundColor="#4566F3"
                color={colors.white}
                noArrow
              >
                <TitleWrapper>
                  <CancelIcon onClick={() => History.goBack()} />
                  <Text color={colors.white} weight="bold" left="20px">
                    Create a Sale
                  </Text>
                </TitleWrapper>
                <SettingsWrapper
                  onClick={() => {
                    setOpenTutorialCancel(true);
                    setOpenAddSelectOptions((prev) => !prev);
                  }}
                >
                  <Text
                    left="10px"
                    color={colors.white}
                    weight="500"
                    size="12px"
                  >
                    Settings
                  </Text>
                  <Line />
                  <ArrowIcon />
                </SettingsWrapper>
              </TopHeader>
            )}
            {openTutorial && (
              <TutorialBg>
                {openTutorialCancel && <CancelX onClick={cancelTutorial} />}
              </TutorialBg>
            )}
            <Fragment>
              <Formik
                initialValues={formData}
                validationSchema={SaleValidationSchema}
                onSubmit={async (values, { setErrors }) => {
                  const getCurrentTime = moment(new Date()).format("h:mm:ss a");
                  const getDate = moment(
                    new Date(salesDate.year, salesDate.month, salesDate.day)
                  )
                    .subtract(1, "month")
                    .format("YYYY-MM-DD");
                  const dateAndTime = `${getDate} ${getCurrentTime}`;
                  values.localSalesDate = dateAndTime;
                  values.customerName = selectedCustomer
                    ? selectedCustomer.customerName
                    : "";
                  values.customerPhoneNumber = selectedCustomer
                    ? selectedCustomer.customerPhoneNumber
                    : "";

                  if (!salesOptions.addItemSold && values.saleAmount === 0) {
                    return setErrors({
                      saleAmount: "Sales Amount is required!",
                    });
                  }

                  values.saleAmount =
                    (subTotal > 0 ? subTotal : values.saleAmount) - discount;
                  if (values.paymentAmount > values.saleAmount)
                    return setErrors({
                      paymentAmount:
                        "You cannot pay more than the products cost",
                    });
                  if (paymentInFull) {
                    values.paymentAmount = values.saleAmount;
                    setErrorMessage("");
                  } else {
                    values.amountDue = values.saleAmount - values.paymentAmount;
                    if (!values.customerName)
                      return setErrorMessage(
                        "Customer information is required!"
                      );
                  }

                  const response = await dispatch(
                    createSale(merchantId, branchId, values, startTime)
                  );
                  setFormData({ ...formData, ...values });
                  setSaleResponse(response);
                  setShowSaleSuccess(true);
                }}
              >
                {({
                  errors,
                  touched,
                  values,
                  setFieldValue,
                  isValid,
                  isDirty,
                }) => (
                  <Form>
                    <Margin minHeight={true} margin="0 0 150px 0">
                      {salesOptions.addItemSold && (
                        <Margin margin={openTutorial ? "0" : "64px 0 0 0"}>
                          {selectedProducts.length > 0 ? (
                            <>
                              <Text
                                top={openTutorial ? "10px" : "85px"}
                                left="16px"
                                color="#718596"
                                size="12px"
                                weight="500"
                              >
                                PRODUCT
                              </Text>
                              <ListProducts
                                selectedProducts={selectedProducts}
                                currentShop={currentShop}
                                setOpenConfirmDeletionPopup={
                                  setOpenConfirmDeletionPopup
                                }
                                setProductIdToDelete={setProductIdToDelete}
                                setSelectedProducts={setSelectedProducts}
                              />
                              <Margin margin="0 16px">
                                <RippleButton
                                  type="button"
                                  top="0"
                                  backgroundColor={colors.white}
                                  height="40px"
                                  border="1px dashed #227EFF"
                                  onClick={() =>
                                    setOpenProductsList((prev) => !prev)
                                  }
                                >
                                  <ButtonInnerWrapper>
                                    <AddIcon />
                                    <Span
                                      size="14px"
                                      color={colors.deepBlue}
                                      weight="500"
                                      left="13px"
                                    >
                                      Add Product
                                    </Span>
                                  </ButtonInnerWrapper>
                                </RippleButton>
                              </Margin>
                            </>
                          ) : (
                            <Fragment>
                              <CustomerWrapper>
                                <SectionHeader padding="0" borderTop="none">
                                  <Message top="0" color="#071827" size="14px">
                                    Products
                                  </Message>
                                </SectionHeader>
                                <OptionBox
                                  padding="0"
                                  onClick={() =>
                                    setOpenProductsList((prev) => !prev)
                                  }
                                >
                                  <CustomerSVG />
                                  <Text
                                    left="8px"
                                    weight="bold"
                                    size="14px"
                                    color={colors.deepBlue}
                                  >
                                    Add Product
                                  </Text>
                                </OptionBox>
                              </CustomerWrapper>
                              <Text
                                left="1em"
                                top="4px"
                                bottom="10px"
                                size="12px"
                                color="#718596"
                              >
                                What product(s) did you sell?{"  "}
                                <SpanArrow>
                                  <CurvedIcon />
                                </SpanArrow>
                              </Text>
                            </Fragment>
                          )}
                        </Margin>
                      )}
                      {salesOptions.addItemSold && selectedProducts.length > 0 && (
                        <CustomerWrapper
                          top={salesOptions.addItemSold ? "16px" : "4px"}
                        >
                          <SectionHeader padding="0" borderTop="none">
                            <Message top="0" color="#071827" size="14px">
                              Sub Total
                            </Message>
                          </SectionHeader>
                          <OptionBox padding="0">
                            <Text weight="500" size="14px" color="#071827">
                              {formatCurrency(subTotal)}
                            </Text>
                          </OptionBox>
                        </CustomerWrapper>
                      )}
                      {salesOptions.addDiscount && salesOptions.addItemSold && (
                        <CustomerWrapper
                          top={
                            salesOptions.addDiscount &&
                            !salesOptions.addItemSold
                              ? "64px"
                              : salesOptions.addItemSold &&
                                selectedProducts.length === 0
                              ? "16px"
                              : "4px"
                          }
                        >
                          <SectionHeader padding="0" borderTop="none">
                            <Message top="0" color="#071827" size="14px">
                              Discount
                            </Message>
                          </SectionHeader>
                          {discount > 0 ? (
                            <OptionBox padding="0">
                              <Text
                                right="14.68px"
                                weight="500"
                                size="14px"
                                color="#071827"
                              >
                                {formatCurrency(discount)}
                              </Text>
                              <GoSVG
                                onClick={() =>
                                  setOpenDiscountPopup((prev) => !prev)
                                }
                              />
                            </OptionBox>
                          ) : (
                            <OptionBox
                              padding="0"
                              onClick={() =>
                                setOpenDiscountPopup((prev) => !prev)
                              }
                            >
                              <CustomerSVG />
                              <Text
                                left="8px"
                                weight="bold"
                                size="14px"
                                color={colors.deepBlue}
                              >
                                Add Discount
                              </Text>
                            </OptionBox>
                          )}
                        </CustomerWrapper>
                      )}
                      <WhiteWrapper
                        paddingY={salesOptions.addItemSold ? "0" : "10px"}
                        top={
                          openTutorial
                            ? "0"
                            : !salesOptions.addItemSold
                            ? "65px"
                            : "0"
                        }
                      >
                        {!salesOptions.addItemSold && (
                          <Fragment>
                            <Text
                              size="12px"
                              weight="500"
                              color="#071827"
                              bottom={"10px"}
                              top={"10px"}
                            >
                              How much was the sale?
                            </Text>

                            <NewAmountInputWithLabel
                              label="Sales Amount"
                              placeholder={"Sales Amount"}
                              name="saleAmount"
                              bottom="12px"
                              height={"56px"}
                              background={"#f2f5fa"}
                              onKeyUp={() => setFormData(values)}
                            />
                          </Fragment>
                        )}
                        {salesOptions.addDiscount && !salesOptions.addItemSold && (
                          <CustomerWrapper padding="0" top="4px">
                            <SectionHeader padding="0" borderTop="none">
                              <Message top="0" color="#071827" size="14px">
                                Discount
                              </Message>
                            </SectionHeader>
                            {discount > 0 ? (
                              <OptionBox padding="0">
                                <Text
                                  right="14.68px"
                                  weight="500"
                                  size="14px"
                                  color="#071827"
                                >
                                  {formatCurrency(discount)}
                                </Text>
                                <GoSVG
                                  onClick={() =>
                                    setOpenDiscountPopup((prev) => !prev)
                                  }
                                />
                              </OptionBox>
                            ) : (
                              <OptionBox
                                padding="0"
                                onClick={() =>
                                  setOpenDiscountPopup((prev) => !prev)
                                }
                              >
                                <CustomerSVG />
                                <Text
                                  left="8px"
                                  weight="bold"
                                  size="14px"
                                  color={colors.deepBlue}
                                >
                                  Add Discount
                                </Text>
                              </OptionBox>
                            )}
                          </CustomerWrapper>
                        )}
                        <WhiteWrapper paddingY="0" paddingX="0">
                          {salesOptions.addItemSold &&
                            selectedProducts.length > 0 && (
                              <WhiteWrapper paddingX="0">
                                <FlexContainer paddingX="0" borderBottom="none">
                                  <Text color="#071827">Sales Total</Text>
                                  <Text
                                    weight="500"
                                    size="14px"
                                    color="#071827"
                                  >
                                    {formatCurrency(subTotal - (discount || 0))}
                                  </Text>
                                </FlexContainer>
                              </WhiteWrapper>
                            )}
                          {showRadio && (
                            <PaymentInformationBlock>
                              {paymentTypes.map((data, index) => (
                                <Radio
                                  onClick={() => {
                                    const newArr = [...paymentTypes];
                                    newArr[0].active = false;
                                    newArr[1].active = false;
                                    newArr[index].active = true;
                                    setPaymentTypes(newArr);
                                    setPaymentInFull((prev) => !prev);
                                  }}
                                  active={data.active}
                                  key={index}
                                >
                                  {data.active ? (
                                    <RadioActive />
                                  ) : (
                                    <RadioInactive />
                                  )}
                                  <Text left="8px" size="12px" weight="500">
                                    {data.title}
                                  </Text>
                                </Radio>
                              ))}
                            </PaymentInformationBlock>
                          )}
                        </WhiteWrapper>

                        {!paymentInFull && (
                          <Padding padding="0 0 10px 0">
                            <Text bottom={"10px"} size="12px" top={"24px"}>
                              <Span color="#071827" weight="500">
                                Balance{" "}
                              </Span>
                              (How much did the customer pay?)
                            </Text>

                            <NewAmountInputWithLabel
                              label="Amount Paid"
                              name="paymentAmount"
                              placeholder={"Amount Paid"}
                              disabled={
                                !salesOptions.addItemSold &&
                                (values.saleAmount === "" || !values.saleAmount)
                              }
                              bottom="12px"
                              height={"56px"}
                              background={"#f2f5fa"}
                              onKeyUp={() => setFormData(values)}
                            />
                          </Padding>
                        )}
                      </WhiteWrapper>
                      {(salesOptions.addCustomer || !paymentInFull) &&
                        !selectedCustomer && (
                          <CustomerWrapper top="4px">
                            <SectionHeader padding="0" borderTop="none">
                              <Message top="0" color="#718596" size="14px">
                                CUSTOMER
                              </Message>
                            </SectionHeader>
                            <OptionBox
                              padding="0"
                              onClick={() =>
                                setOpenCustomerDetails((prev) => !prev)
                              }
                            >
                              <CustomerSVG />
                              <Text
                                left="8px"
                                weight="bold"
                                size="14px"
                                color={colors.deepBlue}
                              >
                                Add Customer
                              </Text>
                            </OptionBox>
                          </CustomerWrapper>
                        )}
                      {showCustomer && (
                        <WhiteWrapper paddingX="0">
                          <OptionBox position={"space-between"}>
                            <AddFlex margin="0">
                              <NameWrapper>
                                <Text size="10px" weight="500" color="#D78F00">
                                  {selectedCustomer?.customerName
                                    ?.split(" ")
                                    .map((n) => n[0])
                                    .join("")
                                    .toUpperCase()}
                                </Text>
                              </NameWrapper>

                              <AddFlex column>
                                <CustomerName>
                                  {selectedCustomer.customerName}
                                </CustomerName>
                              </AddFlex>
                            </AddFlex>
                            <DeleteSVG
                              onClick={() => {
                                setSelectedCustomer(undefined);
                              }}
                            />
                          </OptionBox>
                        </WhiteWrapper>
                      )}

                      {!paymentInFull && !selectedCustomer && (
                        <Text
                          top="4px"
                          bottom="4px"
                          left="1em"
                          size="10px"
                          color="#E01A00"
                        >
                          **Customer required for part payment
                        </Text>
                      )}
                      <CustomerWrapper top="4px">
                        <Text size="14px" color="#718596">
                          Sales Date
                        </Text>
                        <TodayWrapper
                          onClick={() => setOpenDate((prev) => !prev)}
                        >
                          <Text
                            right="11px"
                            weight="bold"
                            size="14px"
                            color="#071827"
                          >
                            {realDate
                              ? dateFormat(realDate)
                              : dateFormat(TodayDate)}
                          </Text>
                          <ArrowBlackIcon />
                        </TodayWrapper>
                      </CustomerWrapper>
                      {salesOptions.addNotes && (
                        <WhiteWrapper>
                          <Text
                            bottom={"5px"}
                            color="#071827"
                            size="12px"
                            weight="500"
                          >
                            Notes
                          </Text>
                          <TextareaWithLabel
                            name={"notes"}
                            value={values.notes}
                            placeholder="Add additional notes to sale"
                            height={"53px"}
                            valid={`${!touched.notes && !errors.notes}`}
                            errors={
                              touched && touched.notes && errors && errors.notes
                            }
                            onChange={(e) => {
                              setFieldValue("notes", e.target.value);
                            }}
                            onKeyUp={() => setFormData(values)}
                          />
                        </WhiteWrapper>
                      )}
                      <Fragment>
                        {errorMessage && (
                          <ErrorMessage>{errorMessage}</ErrorMessage>
                        )}
                      </Fragment>
                      <Display open={openDiscountPopup}>
                        <DiscountPopUp
                          open={openDiscountPopup}
                          cancel={() => setOpenDiscountPopup((prev) => !prev)}
                          setDiscount={setDiscount}
                          subTotal={
                            salesOptions.addItemSold
                              ? subTotal
                              : values.saleAmount
                          }
                          discount={discount}
                          setFieldValue={setFieldValue}
                        />
                      </Display>
                      <Display open={openAddSelectOptions}>
                        <AddSalesOptionPage
                          open={openAddSelectOptions}
                          setOpenAddSelectOptions={setOpenAddSelectOptions}
                          setSalesOptions={setSalesOptions}
                          salesOptions={salesOptions}
                          setSelectedProducts={setSelectedProducts}
                          setSelectedCustomer={setSelectedCustomer}
                          setFieldValue={setFieldValue}
                          cancel={() =>
                            setOpenAddSelectOptions((prev) => !prev)
                          }
                        />
                      </Display>
                      <AddProductsForSale
                        searchProductsOnMasterList={searchProductsOnMasterList}
                        shopId={currentShop.id}
                        addProductOnTheFly={addProductOnTheFly}
                        open={openProductsList}
                        setOpen={setOpenProductsList}
                        setProducts={setSelectedProducts}
                        setFieldValue={setFieldValue}
                        inventory={currentShop.inventory}
                        selectedProducts={selectedProducts}
                      />
                    </Margin>
                    <FooterWrapper
                      zIndex={
                        openProductsList ||
                        openCustomerDetails ||
                        openDiscountPopup ||
                        openAddSelectOptions
                          ? "0"
                          : "99"
                      }
                    >
                      <div>
                        <Text color="#718596" size="12px">
                          {paymentInFull ? "Total Sale" : "Customer owes"}
                        </Text>
                        <Text
                          top="5px"
                          weight="bold"
                          color={paymentInFull ? colors.black : "#E02020"}
                          size="16px"
                        >
                          {formatCurrency(
                            paymentInFull
                              ? subTotal - (discount || 0) + values.saleAmount
                              : subTotal -
                                  (discount || 0) +
                                  ((values.saleAmount || 0) -
                                    values.paymentAmount),
                            "SA"
                          )}
                        </Text>
                      </div>
                      <RippleButton
                        type="submit"
                        width="148px"
                        top={"8px"}
                        disabled={isDisable(values)}
                        onClick={() => setButtonText("Saving...")}
                      >
                        {buttonText}
                      </RippleButton>
                    </FooterWrapper>
                  </Form>
                )}
              </Formik>
            </Fragment>

            <Display open={openCustomerDetails}>
              <CustomersPopUp
                open={openCustomerDetails}
                selectedCustomer={selectedCustomer}
                setSelectedCustomer={setSelectedCustomer}
                cancel={() => setOpenCustomerDetails((prev) => !prev)}
              />
            </Display>
          </>
          {openDate && (
            <Overlay bgc={"rgba(0, 0, 0, 0.4)"} zIndex={"99999"}>
              <CalendarCover>
                <CalendarHeaderCover>
                  <CalendarParagraph>Select Date</CalendarParagraph>
                </CalendarHeaderCover>
                <CalendarStyleOverride>
                  <Calendar
                    value={salesDate}
                    onChange={setSalesDate}
                    colorPrimary="#227EFF"
                    locale={CustomWeekdays}
                    maximumDate={utils().getToday()}
                    calendarClassName="custom-calendar"
                    calendarSelectedDayClassName="custom-today-day"
                    renderFooter={() => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "16px",
                        }}
                      >
                        <button
                          type="button"
                          onClick={() => {
                            setSalesDate(null);
                            setOpenDate((prev) => !prev);
                          }}
                          style={{
                            border: "1px solid #2E4457",
                            background: "#fff",
                            borderRadius: "4px",
                            color: "#2E4457",
                            width: "100px",
                            padding: "13px 15px",
                            fontSize: "14px",
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            setRealDate(salesDate);
                            setOpenDate((prev) => !prev);
                          }}
                          style={{
                            border: "none",
                            background: "#227EFF",
                            borderRadius: "4px",
                            color: "#FFFFFF",
                            padding: "13px 40px",
                            fontSize: "14px",
                          }}
                        >
                          Confirm
                        </button>
                      </div>
                    )}
                  />
                </CalendarStyleOverride>
              </CalendarCover>
            </Overlay>
          )}
          {confirmDeletionOpen && (
            <ConfirmPopupDialog
              open={confirmDeletionOpen}
              title={"Are you sure you want to remove this product?"}
              confirmationText={
                "Removing a product will remove it from the sale."
              }
              answers={[
                {
                  variant: "No",
                  action: () =>
                    setOpenConfirmDeletionPopup(!confirmDeletionOpen),
                },
                {
                  variant: "Yes",
                  action: () => {
                    setSelectedProducts(
                      selectedProducts.filter(
                        (item) => item.id !== productIdToDelete
                      )
                    );

                    setOpenConfirmDeletionPopup(!confirmDeletionOpen);
                  },
                },
              ]}
            />
          )}
        </DesktopBackgroundLayout>
      )}
    </Fragment>
  );
};

export default memo(AddSale);
