import {
  ForgotPassword,
  SetNewPassword,
  CustomerSaleReceipt,
  PasswordOtpOptions,
} from "../views";
import Inputs from "../views/inputs";
import { PrivacyPolicy } from "../views/privacy-policy";
import SkeletalLoading from "../views/skeletal-loading";
import { TermsAndConditions } from "../views/terms-conditions";

import { paymentLinkOpenRoutes } from "./actions/paymentLinkRoutes";

//import { ZendeskWindow } from '../components';
const devRoutes = [
  {
    path: "/skeletal-loader",
    component: SkeletalLoading,
    exact: true,
  },
  { path: "/inputs", exact: true, component: Inputs },
];

export const openRoutes = [
  {
    path: "/termsAndConditions",
    component: TermsAndConditions,
    exact: true,
  },
  {
    path: "/privacyPolicy",
    component: PrivacyPolicy,
    exact: true,
  },
  {
    path: "/user/password_forgot",
    component: ForgotPassword,
    exact: true,
  },
  {
    path: "/user/password_set",
    component: SetNewPassword,
    exact: true,
  },
  {
    path: "/receipt",
    component: CustomerSaleReceipt,
    exact: true,
  },
  {
    path: "/user/password-otp-options",
    component: PasswordOtpOptions,
    exact: true,
  },

  ...paymentLinkOpenRoutes,
];

if (process.env.NODE_ENV === "development") openRoutes.push(...devRoutes);
