import React, { Fragment } from "react";

import { ErrorMessage, useField } from "formik";
import { number, object, string } from "prop-types";
import styled, { css } from "styled-components";

import { TextInputField } from "../../../containers/NewInputContainer";
import { colors, fonts } from "../../../styles";

const PasswordBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(48px, 48px));
  gap: 11px;
  align-items: center;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "0")};
  margin-bottom: 4px;
`;

const ErrorBlock = styled(ErrorMessage)`
  font-size: 12px;
  font-family: ${fonts.main};
  color: ${colors.red};
  margin-top: 4px;

  ${({ disabled }) =>
    disabled &&
    css`
      display: none;
    `}
`;

const Password = styled(TextInputField)`
  border-radius: 4px;
  background-color: ${colors.border.default};
  padding: 10px;
  border: 1px solid transparent;
  width: 48px;
  height: 48px;
  text-align: center;
  font-size: 18px;
  font-family: ${fonts.main};
  -moz-appearance: textfield;
  position: relative;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${({ valid }) =>
    valid &&
    css`
      &:focus:not(:disabled) {
        border-color: ${colors.border.primary};
      }
    `}

  ${({ error }) =>
    error &&
    css`
      &,
      &:not(:disabled),
      &:focus:not(:disabled) {
        border-color: ${colors.border.error};
      }
    `}
`;

export const PasswordGroup = ({
  type,
  startIndex,
  count,
  enteredValue,
  // This enteredValue prop is important to this component - must be a state
  marginTop,
  ...props
}) => {
  const range = startIndex > count ? startIndex + count - 1 : count;

  const move = (event, id) => {
    const tempValue = event.target.value;

    event.target.value = tempValue.charAt(tempValue.length - 1);
    let selectedInput;
    if (id === count) {
      selectedInput =
        event.which === 8 && count - 1 > 0 ? `${count - 1}` : `${count}`;
    } else if (id === range) {
      selectedInput = event.which === 8 ? `${range - 1}` : `${range}`;
    } else {
      // selectedInput = ((event.which === 8) && (id - 1 > 0)) ? `${id - 1}` : `${id + 1}`;
      const temp = id;
      if (event.which === 8) {
        selectedInput = `${id - 1}`;
      } else if (event.target.value) {
        selectedInput = `${id + 1}`;
      }

      if (
        selectedInput <= 0 ||
        selectedInput > range ||
        selectedInput === null
      ) {
        selectedInput = temp;
      }
    }

    if (document.querySelector(`input[name=${props.name}]`).value < 0) {
      return;
    } else {
      document.getElementById(selectedInput) &&
        document.getElementById(selectedInput).focus();
    }

    // if (event.target.value.length > 1) {
    //     event.target.value = event.target.value.slice(0, 1);
    // };
  };
  const [field, meta] = useField(props.name);

  const { error, touched } = meta;

  const generatedBlocks = [];
  for (let i = startIndex; i <= range; i++) {
    generatedBlocks.push(
      <Fragment key={i}>
        <Password
          id={`${i}`}
          type={type}
          onKeyUp={(event) => move(event, i)}
          inputMode={"numeric"}
          {...field}
          {...props}
          value={enteredValue === undefined ? enteredValue : undefined}
          name={props.name}
          maxLength="1"
          autoComplete="new-password"
          error={error}
          valid={`${touched && !error}`}
        />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <PasswordBlock marginTop={marginTop}>{generatedBlocks}</PasswordBlock>
      {error && touched && (
        <ErrorBlock name={props.name} component="div" className="form-error" />
      )}
    </Fragment>
  );
};

PasswordGroup.propTypes = {
  count: number,
  type: string,
  errors: object,
  touched: object,
  startIndex: number,
  enteredValue: string,
  marginTop: string,
};
