import { toast } from "react-toastify";

import { walletAPI, axios } from "../../../../../config";
import {
  LOADING,
  FETCH_ALL_TRANSACTIONS_SUCCESS,
  FETCH__ALL_TRANSACTIONS_FAILURE,
  FETCH_TRANSACTION_DETAILS_SUCCESS,
  FETCH_TRANSACTION_DETAILS_FAILURE,
  LOADING_TRANSACTIONS,
  LOADING_TRANSACTION,
} from "../constants";

const getAllTransactions = () => async (dispatch) => {
  // dispatch data from the cache if it's available
  dispatch({ type: LOADING_TRANSACTIONS });

  try {
    const token = JSON.parse(localStorage.getItem("token")).token;
    // Get data from the server if there is none available in the cache
    const allTransactionsResponse = await axios.get(
      `${walletAPI}/api/transactionList/currentUser?dir=DESC&page=1&size=1000&sortby=createdAt`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(stoploading());
    if (allTransactionsResponse.status === 200) {
      dispatch({
        type: FETCH_ALL_TRANSACTIONS_SUCCESS,
        payload: allTransactionsResponse.data.content,
      });
    }
  } catch (error) {
    dispatch(stoploading());
    dispatch({
      type: FETCH__ALL_TRANSACTIONS_FAILURE,
      payload: error.message,
    });
    const { data } = (error && error.response) || {};
    const { message } = data || "";
    toast.error(navigator.onLine ? message : "You are currently offline!");
    // console.log(error.message);
  }
};

const getTransactionDetials = (id) => async (dispatch) => {
  // dispatch data from the cache if it's available
  dispatch({
    type: LOADING_TRANSACTION,
    payload: id,
  });

  try {
    // Get data from the server if there is none available in the cache
    const token = JSON.parse(localStorage.getItem("token")).token;
    const transactionDetialsResponse = await axios.get(
      `${walletAPI}/api/transactionList/details/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(stoploading());
    if (transactionDetialsResponse.status === 200) {
      dispatch({
        type: FETCH_TRANSACTION_DETAILS_SUCCESS,
        payload: transactionDetialsResponse.data,
      });
    }
  } catch (error) {
    dispatch(stoploading());
    dispatch({
      type: FETCH_TRANSACTION_DETAILS_FAILURE,
      payload: error.message,
    });
    toast.error(error.message);
    console.error(error.message);
  }
};

function stoploading() {
  return {
    type: LOADING,
    payload: false,
  };
}

export const transactionActions = {
  getAllTransactions,
  getTransactionDetials,
};
