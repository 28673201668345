import React from "react";

import { bool, object, func } from "prop-types";
import { useDispatch } from "react-redux";
import { Route, Redirect, withRouter, useLocation } from "react-router-dom";

import { setInitialPath } from "../redux/ducks/auth/check/actions";

const excludePaths = ["/more", "/user/account_settings"];

export const ProtectedRoute = withRouter(({ component, isAuthenticated }) => {
  let location = useLocation();
  const dispatch = useDispatch();

  if (location.pathname && !excludePaths.includes(location.pathname)) {
    dispatch(setInitialPath(location.pathname));
  }

  return isAuthenticated ? (
    <Route render={component} />
  ) : (
    <Redirect to={{ pathname: "/launch" }} />
  );
});

ProtectedRoute.propTypes = {
  isAuthenticated: bool,
  component: func,
  props: object,
  location: object,
};
