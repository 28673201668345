import React, { useState } from "react";

import { useDispatch } from "react-redux";
import styled from "styled-components";

import { ReactComponent as ContactsIcon } from "../../../../assets/contact.svg";
import { currentAPI, axios } from "../../../../config";

const Input = styled.input`
  height: 45px;
  font-size: 14px;
  color: black;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  position: relative;
  padding: 0 10px;
  background: #f2f5fa;
  margin-bottom: 5px;
  &::-webkit-inner-spin-button,
  -webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:focus {
    outline: 1px solid black;
  }
`;

const RightLabel = styled.div`
  top: 11px; //as padding
  position: absolute;
  right: 14px;
`;
const Wrapper = styled.div`
  position: relative;
  display: flex;
`;

const InputEl = ({ handleChange, isSpacesUser, inputVal, name }) => {
  const [phone, setPhone] = useState(inputVal);

  const handleInput = (e) => {
    const {
      target: { value, name },
    } = e;
    if (value.length > 11) return;
    setPhone(value);
    isSpacesUser(value);
    handleChange({ name, val: value });
  };

  return (
    <Input type={"number"} name={name} onChange={handleInput} value={phone} />
  );
};

export const PhoneCheck = ({
  handleChange,
  inputVal,
  name,
  active = false,
  callback = () => {},
}) => {
  const dispatch = useDispatch();
  const [onActive, setOnActive] = useState(active);
  const [spacesUser, setSpacesUser] = useState(false);

  const checkIfSpacesUser = (value) => async () => {
    if (!navigator.onLine) return;
    let contactName = "";
    try {
      const response = await axios.post(
        `${currentAPI}/api/users/namecheck/`,
        JSON.stringify({ phoneNumber: value })
      );
      if (response.status === 200) {
        setSpacesUser(true);

        contactName = `${response.data.firstName} ${response.data.lastName}`;
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setSpacesUser(false);
      }
    } finally {
      const contact = {
        name: contactName,
        number: value,
        position: name,
      };
      callback(contact);
    }
  };

  return (
    <Wrapper onClick={() => setOnActive(true)}>
      {
        <InputEl
          handleChange={handleChange}
          name={name}
          inputVal={inputVal}
          onActive={onActive}
          isSpacesUser={(value) => {
            if (value && value.length === 11) {
              dispatch(checkIfSpacesUser(value));
            } else {
              setSpacesUser(false);
            }
          }}
        />
      }
      {!inputVal && <RightLabel>{<ContactsIcon />}</RightLabel>}
      {inputVal && inputVal.length <= 11 && !spacesUser && (
        <RightLabel>{""}</RightLabel>
      )}
      {inputVal && inputVal.length === 11 && spacesUser && (
        <RightLabel>
          {<span style={{ color: "#22a8ff80" }}>- spaces user</span>}
        </RightLabel>
      )}
    </Wrapper>
  );
};
