import React from "react";

import { string, func, bool } from "prop-types";
import { useHistory, Link } from "react-router-dom";
import styled from "styled-components";

import { colors } from "../../styles";
import { UserAvatar } from "../avatar/index";

import { ReactComponent as BackArrow } from "./assets/back_icon.svg";
import { ReactComponent as CancelIcon } from "./assets/cancel-icon.svg";
import { ReactComponent as OpaqueBackArrow } from "./assets/opaque_back.svg";

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: ${({ newHeader }) => (newHeader ? "left" : "center")};
  max-width: inherit;
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "64px"};
  max-width: inherit;
  margin: ${({ margin }) => margin || null};
  padding: ${({ padding }) => padding || null};
  position: ${({ position }) => position || "fixed"};
  left: auto;
  right: auto;
  background-color: ${({ backgroundColor }) => backgroundColor || colors.white};
  background: ${({ background }) => background};
  z-index: 1000;
  border-bottom: ${({ noBorderBottom }) =>
    !noBorderBottom ? `1px solid ${colors.border.default}` : "none"};
  top: ${({ withSpacesHeader }) =>
    withSpacesHeader || `${({ top }) => top || "66px"}`};

  @media (max-width: 576px) {
    top: ${({ withSpacesHeader }) => withSpacesHeader || "0"};
    margin: ${({ mobileMargin }) => mobileMargin || null};
  }
`;

const Title = styled.h1`
  margin-left: ${({ newHeader }) => (newHeader ? "56px" : "0px")};
  height: fit-content;
  font-size: ${({ size }) => size || "1em"};
  font-weight: ${({ weight }) => weight || "500"};
  text-transform: ${({ transform }) => transform || null};
  letter-spacing: 0.04px;
  color: ${({ color }) => color || colors.themeTextColor1};
  @media (min-width: 576px) {
    font-weight: ${({ weight }) => weight || "normal"};
  }

  @media (max-width: 320px) {
    max-width: ${({ maxWidth }) => maxWidth || null};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const BackButtonWrapper = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  position: absolute;
  left: 16px;
  top: ${({ top }) => top || "16px"};
`;

export const TopHeader = ({
  top,
  margin,
  padding,
  position,
  width,
  title,
  backAction,
  noArrow,
  noTitle,
  transform,
  children,
  backLink,
  backgroundColor,
  opaqueBackButton,
  noBorderBottom,
  withSpacesHeader,
  newHeader,
  color,
  weight,
  size,
  fallback,
  id,
  Icon,
  maxWidth,
  iconWidth,
  iconHeight,
  objectFit,
  isIcon,
  withCancelIcon,
  background,
  mobileMargin,
  height,
  backButtonTop,
}) => {
  const history = useHistory();
  return (
    <Header
      backgroundColor={backgroundColor}
      noBorderBottom={noBorderBottom}
      position={position}
      width={width}
      margin={margin}
      padding={padding}
      top={top}
      height={height}
      background={background}
      mobileMargin={mobileMargin}
    >
      {!noArrow && (
        <BackButtonWrapper
          onClick={() => {
            backAction && backAction();
            if (!backLink) {
              history.goBack();
            }
          }}
          top={backButtonTop}
        >
          {backLink ? (
            <Link to={backLink}>
              {withCancelIcon ? (
                <CancelIcon />
              ) : opaqueBackButton ? (
                <OpaqueBackArrow />
              ) : (
                <BackArrow />
              )}
            </Link>
          ) : (
            <>
              {withCancelIcon ? (
                <CancelIcon />
              ) : opaqueBackButton ? (
                <OpaqueBackArrow />
              ) : (
                <BackArrow />
              )}
            </>
          )}
        </BackButtonWrapper>
      )}
      {!noTitle && (
        <Title
          size={size}
          weight={weight}
          color={color}
          newHeader={newHeader}
          transform={transform}
          maxWidth={maxWidth}
        >
          {title}
        </Title>
      )}
      {isIcon && (
        <div style={{ position: "absolute", right: "20px" }}>
          <UserAvatar
            width={iconWidth}
            height={iconHeight}
            avatar={Icon}
            alt="User avatar"
          ></UserAvatar>
        </div>
      )}
      {children}
    </Header>
  );
};

TopHeader.propTypes = {
  title: string,
  backAction: func,
  noArrow: bool,
  noTitle: bool,
  isIcon: bool,
  backLink: string,
};
