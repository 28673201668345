import React, { Fragment } from "react";

import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import {
  TopHeader,
  SelectBox,
  RippleButton,
  Loader,
  InputWithLabel,
  SelectCountry,
} from "../../../../../components";
import { InputBlock } from "../../../../../containers/InputContainer";
import { Message } from "../../../../../containers/MessageContainer";
import { ScreenContainer } from "../../../../../containers/ScreenContainer";
import { localAreas } from "../../../../../data/countries/nigeria/localAreas";
import { states } from "../../../../../data/countries/nigeria/states";
import { sendKYCAddress } from "../../../../../redux/ducks/account/kyc/actions";
import { emptyValues } from "../../../../../utils/inputs/conditions";
import { parseAddressToObject } from "../../../../../utils/inputs/parseAddressToObject";
import { selectAreasByState } from "../../../../../utils/inputs/selectAreasByState";
import DesktopBackgroundLayout from "../../../../DesktopBackgroundLayout";

import { KYCAddressValidationSchema } from "./KYCAddressValidationSchema";

const AddressContainer = styled.div`
  margin-top: 72px;
`;

const KYCAddressSelection = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.account.kyc.isLoading);
  const houseAddress = useSelector((state) => state.user.houseAddress);
  const addressData = parseAddressToObject(houseAddress);
  const country = useSelector((state) => state.user.country);

  return isLoading ? (
    <Loader />
  ) : (
    <Fragment>
      <DesktopBackgroundLayout>
        <TopHeader title={"Address"} />
        <ScreenContainer>
          <AddressContainer>
            <Message align={"left"}>
              Please provide your residential address.
            </Message>
            <Formik
              initialValues={{
                streetAddress: addressData.streetAddress || "",
                country: addressData.country
                  ? addressData.country
                  : country
                  ? country
                  : "",
                state: addressData.state || "",
                lga: addressData.lga || "",
              }}
              enableReinitialize
              validationSchema={KYCAddressValidationSchema}
              onSubmit={(values) => {
                setTimeout(() => {
                  dispatch(sendKYCAddress(values));
                }, 200);
              }}
            >
              {({
                errors,
                touched,
                setFieldValue,
                initialValues,
                values,
                handleChange,
              }) => (
                <Form>
                  <InputBlock>
                    <InputWithLabel
                      label={"Street Address"}
                      type={"text"}
                      value={values.streetAddress}
                      placeholder={"Street Address"}
                      name="streetAddress"
                      valid={`${
                        !touched.streetAddress && !errors.streetAddress
                      }`}
                      errors={
                        touched &&
                        touched.streetAddress &&
                        errors &&
                        errors.streetAddress
                      }
                      setFieldValue={setFieldValue}
                      initialValues={initialValues}
                    />
                    <SelectCountry
                      name={"country"}
                      handleChange={setFieldValue}
                      value={values.country}
                    />
                    <SelectBox
                      name={"state"}
                      placeholder={"Province"}
                      value={values.state}
                      options={states}
                      handleChange={handleChange}
                      valid={`${!touched.state && !errors.state}`}
                      error={touched && touched.state && errors && errors.state}
                    />
                    <SelectBox
                      name={"lga"}
                      placeholder={"Municipalities"}
                      value={values.lga}
                      options={
                        values.state
                          ? selectAreasByState(values.state, localAreas)
                          : []
                      }
                      handleChange={handleChange}
                      valid={`${!touched.lga && !errors.lga}`}
                      error={touched && touched.lga && errors && errors.lga}
                    />
                    <RippleButton
                      type="submit"
                      top={"24px"}
                      disabled={!emptyValues(values) && emptyValues(errors)}
                    >
                      Update
                    </RippleButton>
                  </InputBlock>
                </Form>
              )}
            </Formik>
          </AddressContainer>
        </ScreenContainer>
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default KYCAddressSelection;
