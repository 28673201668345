export const FilterComplaint = (type, complaints, setComplaint) => {
  const newComplaintsArray = [...complaints];
  if (type === "All types") {
    setComplaint(complaints);
  } else if (type === "UXP Support") {
    setComplaint(
      newComplaintsArray.filter((data) => data.category === "UXP Support")
    );
  } else if (type === "Wallet") {
    setComplaint(
      newComplaintsArray.filter((data) => data.category === "Wallet Operations")
    );
  } else if (type === "Agent Network") {
    setComplaint(
      newComplaintsArray.filter((data) => data.category === "Agent Network")
    );
  } else if (type === "Tech Product") {
    setComplaint(
      newComplaintsArray.filter((data) => data.category === "Tech Product")
    );
  } else if (type === "User Support") {
    setComplaint(
      newComplaintsArray.filter((data) => data.category === "User Support")
    );
  } else if (type === "Operations") {
    setComplaint(
      newComplaintsArray.filter((data) => data.category === "Operations")
    );
  }
};
