import React from "react";

import { number } from "prop-types";
import styled from "styled-components";

import { colors } from "../../../styles";

const ProgressComponent = styled.div`
  width: 100%;

  span {
    display: block;
  }
`;

const Bar = styled.div`
  border-radius: 8px;
  background: ${colors.background.progressBar};
`;

const Progress = styled.span`
  border-radius: inherit;
  background: #227eff;
  height: 4px;
  width: ${(props) => parseFloat((props.step / props.amount) * 100)}%;
  transition: width 0.2s ease-in;
`;

export const ProgressBar = ({ step, amount }) => {
  return (
    <ProgressComponent>
      <Bar>
        <Progress step={step} amount={amount} />
      </Bar>
    </ProgressComponent>
  );
};

ProgressBar.propTypes = {
  step: number,
  amount: number,
};
