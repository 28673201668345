import React, { Fragment, useState } from "react";

import { useHistory } from "react-router-dom";
import styled, { css } from "styled-components";

import { TopHeader, RippleButton } from "../../../../components";
import {
  PageTitleWrapper,
  PageTitle,
  PageSubTitle,
} from "../../../../containers/MessageContainer";
import {
  ScreenContainer,
  PageProgressLine,
} from "../../../../containers/ScreenContainer";
import { colors } from "../../../../styles";
import { SELECT_ACCOUNT_TYPE } from "../../../../utils/mix-panel/constants";
import { mixPanel } from "../../../../utils/mix-panel/mixPanel";
import DesktopBackgroundLayout from "../../../DesktopBackgroundLayout";

import AgentIcon from "./assets/AgentIcon.svg";
import SellerIcon from "./assets/SellerIcon.svg";

const PadBlock = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  background-color: ${({ bgColor }) => bgColor || colors.background.primaryPad};
  color: ${colors.themeTextColor1};
  padding: 10px;
  font-size: 14px;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 24px;

  ${({ selected }) =>
    selected &&
    css`
      color: ${colors.blue};
    `}
`;

const PadBlockIcon = styled.img``;

const BlockTitleWrapper = styled.div`
  margin: 8px;
`;

const PadBlockTitle = styled.h4`
  font-size: ${({ size }) => size || "14px"};
  font-weight: ${({ weight }) => weight || "bold"};
  color: ${({ color }) => color || "#071827"};
  line-height: 21px;
`;

const PadBlockDetail = styled.p`
  font-size: ${({ size }) => size || "12px"};
  font-weight: ${({ weight }) => weight || "400"};
  color: #718596;
  line-height: 18px;
  margin-top: 4px;
`;

const RadioWrapper = styled.div`
  position: relative;
  display: flex;
  height: 20px;
`;

const CheckButton = styled.span`
  display: inline-block;
  background: #ffffff;
  border: 2px solid #bbc0c9;
  min-width: 20px;
  height: 20px;
  border-radius: 100%;
  vertical-align: middle;
  &::after {
    content: "";
    display: block;
    position: relative;
    width: 0;
    height: 0;
    border-radius: 100%;
    background-color: #227eff;
    left: 50%;
    right: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all 110ms;
  }
`;

const PadRadioButton = styled.input`
  width: 20px;
  height: 20px;
  opacity: 0;
  &:checked + ${CheckButton} {
    border: 2px solid #227eff;
    opacity: 1;
    &::after {
      width: 10px;
      height: 10px;
      opacity: 1;
    }
  }
`;

const FloatingBottomSection = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0px;
  padding: 0px 16px 40px 16px;
  background-color: #ffffff;
`;

const ChooseRole = () => {
  const history = useHistory();
  const [value, setValue] = useState("");

  const handleClick = async () => {
    mixPanel.track(SELECT_ACCOUNT_TYPE, { role: value });
    if (value === "seller") {
      history.push("/user/create_user");
    } else {
      history.push("/user/create_agent");
    }
  };

  return (
    <Fragment>
      <DesktopBackgroundLayout>
        <TopHeader title={""} backLink={"/phone-signup"}>
          <PageProgressLine lineWidth={"0%"} />
        </TopHeader>

        <ScreenContainer
          paddingBottom={"40px"}
          flex={"flex"}
          direction={"column"}
        >
          <PageTitleWrapper mtop={"40px"} mbottom={"12px"}>
            <PageTitle size={"16px"}>Account type</PageTitle>
            <PageSubTitle margin={"4px"}>
              Select the profile that suits you perfectly
            </PageSubTitle>
          </PageTitleWrapper>

          <PadBlock
            selected={value === "seller"}
            onClick={() => {
              setValue("seller");
            }}
          >
            <PadBlockIcon src={SellerIcon}></PadBlockIcon>
            <BlockTitleWrapper>
              <PadBlockTitle>Seller</PadBlockTitle>
              <PadBlockDetail>
                As a seller and get access to tools that help you manage your
                shop and your products.
              </PadBlockDetail>
            </BlockTitleWrapper>
            <RadioWrapper>
              <PadRadioButton
                type="radio"
                name="accountType"
                value="seller"
                readOnly
                checked={value === "seller"}
              />
              <CheckButton />
            </RadioWrapper>
          </PadBlock>

          <PadBlock
            bgColor={colors.background.secondaryPad}
            selected={value === "agent"}
            onClick={() => {
              setValue("agent");
            }}
          >
            <PadBlockIcon src={AgentIcon}></PadBlockIcon>
            <BlockTitleWrapper>
              <PadBlockTitle>Agent</PadBlockTitle>
              <PadBlockDetail>
                As an agent, you help bring on merchants and facilitate
                distribution for products across the spaces network.
              </PadBlockDetail>
            </BlockTitleWrapper>
            <RadioWrapper>
              <PadRadioButton
                type="radio"
                name="accountType"
                value="agent"
                readOnly
                checked={value === "agent"}
              />
              <CheckButton />
            </RadioWrapper>
          </PadBlock>

          <FloatingBottomSection>
            <RippleButton
              type="submit"
              top={"auto"}
              disabled={!value}
              onClick={handleClick}
            >
              Continue
            </RippleButton>
          </FloatingBottomSection>
        </ScreenContainer>
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default ChooseRole;
