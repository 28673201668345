import React, { useState } from "react";

import { useField } from "formik";
import { string } from "prop-types";
import styled from "styled-components";

import { flags } from "../../../../data/countries";
import { NewTextInputWithLabel } from "../new-text-input";

const CountryFlag = styled.img`
  width: 18px;
  height: 12px;
`;

const CurrentSelectedCountry = styled.div`
  position: absolute;
  left: 18px;
  top: 22px;
  z-index: 2;
  cursor: pointer;
`;

const CountryPhoneBlock = styled.div`
  position: relative;
  -webkit-align-items: center;
  align-items: center;
  background: transparent;
  font-size: 14px;
  padding: 1px;
  line-height: normal;
  margin-bottom: 15px;
  margin-right: 8px;
  border-radius: 4px;
  width: 100%;

  &::before {
    content: "";
    position: absolute;
    top: 20px;
    left: 42px;
    height: 20px;
    width: 1px;
    background: #cbd6e0;
    z-index: 1;
  }
`;

const TextInputWrapper = styled(NewTextInputWithLabel)`
  padding-left: 32px;
`;

export const NewPhoneNumberInput = ({ label, description, ...props }) => {
  const [field] = useField(props.name);
  const [country] = useState("SA");

  return (
    <CountryPhoneBlock>
      <CurrentSelectedCountry>
        <CountryFlag
          // onClick={() => setOpenCountrySelect(!openCountrySelect)}
          src={
            flags.filter((flag) => flag.customAbbreviation === country)[0].value
          }
          alt={
            flags.filter((flag) => flag.customAbbreviation === country)[0].label
          }
        />
      </CurrentSelectedCountry>
      <TextInputWrapper
        {...props}
        {...field}
        label={label}
        description={description}
        autoComplete={`tel`}
        inputMode={"numeric"}
        pattern={"[0-9]*"}
        countryselection={true}
      />
    </CountryPhoneBlock>
  );
};

NewPhoneNumberInput.propTypes = {
  label: string.isRequired,
  countrySelection: string,
  description: string,
};
