import { USER_LOGOUT } from "../../user/constants";

import {
  CLEAR_ACCOUNT,
  SENDING_WALLET_DATA,
  USER_WALLET_SUCCESS,
  USER_WALLET_FAILED,
  SAVE_USER_DEBIT_CARD,
  DELETE_USER_DEBIT_CARD,
  SAVE_USER_BANK_ACCOUNT,
  DELETE_USER_BANK_ACCOUNT,
  SAVE_WALLET_FUND_AMOUNT,
  SAVE_WALLET_USAGE_MODE,
  UPDATE_WALLET_BALANCE,
  UPDATE_WALLET_STATUS,
  HIDE_WALLET_BALANCE,
  SAVE_WALLET_TRANSFER_AMOUNT,
  SAVE_WALLET_TRANSFER_DATA,
  SAVE_WALLET_MONEY_RECIPIENTS,
  GET_AVAILABLE_BANKS,
  SAVE_BENEFICIARY,
  PREPARE_TO_TRANSFER_MONEY,
  WALLET_TRANSACTION_ID,
  WALLET_SUCCESS_PAGE_TRANSACTION_RECORD,
  TEMP_SAVE_BVN_DETAILS,
  GET_ACCOUNT,
  FETCHING_WALLET_BALANCE,
  LAST_ACTION_URL,
  FETCH_ALL_BILL_CATEGORIES,
  FETCH_ALL_BILLERS_PER_CATEGORY,
  BILL_PAYMENT_RESPONSE,
  GET_USER_UNPAID_COMMISSION_LOADING,
  GET_USER_UNPAID_COMMISSION_SUCCESS,
  REDIRECTED_FROM_MERCHBUY,
} from "./constants";

const initialState = {
  isLoading: false,
  fetchingWalletBalance: false,
  errorMsg: "",
  mode: "manage",
  status: "INACTIVE",
  balance: 0,
  hideBalance: false,
  fund: {
    amount: 0,
    status: "notFunding",
    transactionId: "",
  },
  transfer: {
    amount: 0,
    status: "notTransfered",
    recipientName: undefined,
    message: "",
  },
  cards: [],
  bankAccounts: [],
  recipients: [],
  availableBanks: [],
  beneficiaries: [],
  personForTransfer: {},
  successPageRecord: {},
  tempBvnDetail: {},
  bankName: "",
  lastActionUrl: "",
  redirectedFromMerchbuy: false,
  billCategories: [],
  billersPerCategory: [],
  billPaymentResponse: {},
  commission: {
    loading: false,
    totalCommissionEarned: 0,
    userCommissionBreakDown: {
      content: [],
      totalPages: 0,
      totalElements: 0,
    },
  },
};

export default function walletReducer(state = initialState, action) {
  switch (action.type) {
    case SENDING_WALLET_DATA: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case FETCHING_WALLET_BALANCE: {
      return {
        ...state,
        fetchingWalletBalance: action.payload,
      };
    }

    case USER_WALLET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case USER_WALLET_FAILED: {
      return {
        ...state,
        isLoading: false,
        errorMsg: action.payload,
      };
    }

    case UPDATE_WALLET_BALANCE: {
      return {
        ...state,
        balance: action.payload.balance,
      };
    }

    case UPDATE_WALLET_STATUS: {
      return {
        ...state,
        status: action.payload,
      };
    }

    case SAVE_WALLET_FUND_AMOUNT: {
      return {
        ...state,
        fund: {
          amount: action.payload.amount,
          status: action.payload.status,
        },
      };
    }

    case WALLET_TRANSACTION_ID: {
      return {
        ...state,
        fund: {
          transactionId: action.payload,
        },
      };
    }

    case SAVE_WALLET_TRANSFER_AMOUNT: {
      return {
        ...state,
        transfer: {
          ...state.transfer,
          amount: action.payload.amount,
          status: action.payload.status,
        },
      };
    }

    case SAVE_WALLET_TRANSFER_DATA: {
      return {
        ...state,
        transfer: {
          ...state.transfer,
          recipientName: action.payload.recipientName,
          message: action.payload.message,
        },
      };
    }

    case SAVE_WALLET_USAGE_MODE: {
      return {
        ...state,
        mode: action.payload,
      };
    }

    case HIDE_WALLET_BALANCE: {
      return {
        ...state,
        hideBalance: action.payload,
      };
    }

    case SAVE_USER_DEBIT_CARD: {
      const { last4digits, embedToken, cardBrand } = action.payload;
      const cards = state.cards.slice();

      cards.push({
        last4digits,
        embedToken,
        cardBrand,
      });

      return {
        ...state,
        cards,
      };
    }

    case DELETE_USER_DEBIT_CARD: {
      const cards = state.cards.slice();
      cards.splice(action.payload, 1);

      return {
        ...state,
        cards,
      };
    }

    case SAVE_USER_BANK_ACCOUNT: {
      const bankAccounts = state.bankAccounts.slice();

      const bankAccount = {
        bankAccountId: action.payload.bankAccountId,
        bankAccountDTO: action.payload.bankAccountDTO,
      };

      return {
        ...state,
        bankAccounts: bankAccounts.concat(bankAccount),
      };
    }

    case DELETE_USER_BANK_ACCOUNT: {
      return {
        ...state,
        bankAccounts: state.bankAccounts.filter(
          (bankAccount) => bankAccount.bankAccountId !== action.payload
        ),
      };
    }

    case SAVE_WALLET_MONEY_RECIPIENTS: {
      return {
        ...state,
        recipients: [...state.recipients, action.payload],
      };
    }

    case GET_AVAILABLE_BANKS: {
      return {
        ...state,
        availableBanks: action.payload,
      };
    }
    case GET_ACCOUNT: {
      return {
        ...state,
        bankName: action.payload,
      };
    }
    case CLEAR_ACCOUNT: {
      return {
        ...state,
        bankName: action.payload,
      };
    }
    case SAVE_BENEFICIARY: {
      return {
        ...state,
        beneficiaries: action.payload.data,
      };
    }

    case WALLET_SUCCESS_PAGE_TRANSACTION_RECORD: {
      return {
        ...state,
        successPageRecord: action.payload,
      };
    }

    case PREPARE_TO_TRANSFER_MONEY: {
      return {
        ...state,
        personForTransfer: action.payload,
      };
    }

    case TEMP_SAVE_BVN_DETAILS: {
      return {
        ...state,
        tempBvnDetail: action.payload,
      };
    }

    case LAST_ACTION_URL: {
      return {
        ...state,
        lastActionUrl: action.payload,
      };
    }

    case USER_LOGOUT: {
      return {
        isLoading: false,
        errorMsg: "",
        mode: "manage",
        status: "INACTIVE",
        balance: 0,
        hideBalance: false,
        fund: {
          amount: 0,
          status: "notFunding",
          transactionId: "",
        },
        transfer: {
          amount: 0,
          status: "notTransfered",
          recipientName: undefined,
          message: "",
        },
        cards: [],
        bankAccounts: [],
        recipients: [],
        successPageRecord: {},
        tempBvnDetail: {},
      };
    }

    case FETCH_ALL_BILL_CATEGORIES: {
      return {
        ...state,
        billCategories: action.payload,
      };
    }

    case FETCH_ALL_BILLERS_PER_CATEGORY: {
      return {
        ...state,
        billersPerCategory: action.payload,
      };
    }

    case BILL_PAYMENT_RESPONSE: {
      return {
        ...state,
        billPaymentResponse: action.payload,
      };
    }

    // I am adding this so we can have a nice loading state in commissions
    case GET_USER_UNPAID_COMMISSION_LOADING: {
      return {
        ...state,
        commission: {
          ...state.commission,
          loading: action.payload,
        },
      };
    }

    case GET_USER_UNPAID_COMMISSION_SUCCESS: {
      return {
        ...state,
        commission: {
          ...state.commission,
          ...action.payload,
        },
      };
    }

    case REDIRECTED_FROM_MERCHBUY: {
      return {
        ...state,
        redirectedFromMerchbuy: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
