export const getDate = (dateStamp) => {
  const currentDate = new Date().getTime();
  const date = new Date(dateStamp).getTime();
  const diff = (currentDate - date) / (1000 * 3600 * 24);
  const hoursAndMinutes = new Intl.DateTimeFormat("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  }).format(new Date(dateStamp));

  if (diff < 1) {
    return `Today, ${hoursAndMinutes}`;
  } else if (diff < 2) {
    return `Yesterday, ${hoursAndMinutes}`;
  } else {
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "long",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }).format(new Date(dateStamp));
  }
};
