import React, { Fragment } from "react";

import { Formik, Form } from "formik";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import {
  TextInputWithLabel,
  TopHeader,
  PageLogo,
  RippleButton,
  Loader,
  SelectBox,
} from "../../../../../components";
import { InputBlock } from "../../../../../containers/InputContainer";
import {
  PageTitleWrapper,
  PageTitle,
  PageSubTitle,
} from "../../../../../containers/MessageContainer";
import {
  ScreenContainer,
  PageProgressLine,
} from "../../../../../containers/ScreenContainer";
import { localAreas } from "../../../../../data/countries/nigeria/localAreas";
import { states } from "../../../../../data/countries/nigeria/states";
import { colors } from "../../../../../styles";
import { selectAreasByState } from "../../../../../utils/inputs/selectAreasByState";
import { AUTH_PROVIDE_BUSINESS } from "../../../../../utils/mix-panel/constants";
import { mixPanel } from "../../../../../utils/mix-panel/mixPanel";
import DesktopBackgroundLayout from "../../../../DesktopBackgroundLayout";
import { ReactComponent as TakenNameIcon } from "./assets/invalid.svg";
import StoreIcon from "./assets/store.svg";
import { ReactComponent as ValidNameIcon } from "./assets/valid.svg";
import { MerchantBusinessProfileValidationSchema } from "./MerchantBusinessProfileValidationSchema";

const PageProgressWrap = styled.div`
  position: absolute;
  display: flex;
  right: 15px;
  color: ${colors.themeTextColor0};
  z-index: 100;
`;

const PageProgressStep = styled.span`
  font-size: 14px;
  color: ${colors.themeTextColor1};
`;

export const ValidNameCheck = styled(ValidNameIcon)`
  position: absolute;
  display: flex;
  top: 18px;
  right: 14px;
  width: 20px;
  height: 12px;
`;

export const TakenNameCheck = styled(TakenNameIcon)`
  position: absolute;
  display: flex;
  top: 15px;
  right: 14px;
  width: 20px;
  height: 20px;
`;

export const InputLabelName = styled.div`
  position: relative;
  top: -10px;
  color: ${({ color }) => color || colors.themeTxtColor10};
  font-size: 11px;
  letter-spacing: 0.01em;
  margin: 0 15px;
`;

const MerchantBusinessProfile = ({ isLoading }) => {
  const history = useHistory();

  let userData = localStorage.getItem("userData");
  const { streetAddress, state, lga } =
    (userData && JSON.parse(userData)) || {};

  return isLoading ? (
    <Loader />
  ) : (
    <Fragment>
      <DesktopBackgroundLayout>
        <TopHeader title={"Seller Account"}>
          <PageProgressWrap>
            <PageProgressStep>2</PageProgressStep>/3
          </PageProgressWrap>
          <PageProgressLine lineWidth={"70%"} />
        </TopHeader>
        <ScreenContainer>
          <PageLogo
            top={"75px"}
            margin={"auto"}
            borderRadius={"0"}
            Icon={StoreIcon}
            iconWidth="72px"
            iconHeight="72px"
          />
          <PageTitleWrapper mtop={"10px"} mbottom={"35px"}>
            <PageTitle size={"16px"} top={"16px"} bottom={"6px"}>
              Store Information
            </PageTitle>
            <PageSubTitle margin={"0"}>
              Tell us more about your business
            </PageSubTitle>
          </PageTitleWrapper>
          <Formik
            initialValues={{
              state: state || "",
              lga: lga || "",
              streetAddress: streetAddress || "",
            }}
            validationSchema={MerchantBusinessProfileValidationSchema}
            onSubmit={(values, { setErrors }) => {
              userData = JSON.parse(userData);
              userData = { ...userData, ...values };
              localStorage.setItem("userData", JSON.stringify(userData));
              mixPanel.track(AUTH_PROVIDE_BUSINESS, {
                LGA: values.lga,
                State: values.state,
              });
              history.push("/user/create_pin");
            }}
          >
            {({
              errors,
              values,
              touched,
              handleChange,
              setFieldValue,
              initialValues,
            }) => {
              return (
                <Form>
                  <InputBlock>
                    <SelectBox
                      placeholder={"Province"}
                      value={values.state}
                      options={states}
                      height="90%"
                      handleChange={(selected) => {
                        if (values.state !== selected.label) {
                          setFieldValue("lga", "");
                        }
                        setFieldValue("state", selected.label);
                      }}
                      valid={`${!touched.state && !errors.state}`}
                      error={touched && touched.state && errors && errors.state}
                    />

                    <SelectBox
                      name={"lga"}
                      placeholder={"Municipalities"}
                      value={values.lga}
                      height="90%"
                      options={
                        values.state
                          ? selectAreasByState(values.state, localAreas)
                          : []
                      }
                      handleChange={handleChange}
                      valid={`${!touched.lga && !errors.lga}`}
                      error={touched && touched.lga && errors && errors.lga}
                    />

                    <TextInputWithLabel
                      type="text"
                      top="8px"
                      label="Street Address"
                      name="streetAddress"
                      placeholder="Street Address"
                    />

                    <RippleButton
                      top={"60px"}
                      type="submit"
                      disabled={Object.values(values).some(
                        (value) => value.length === 0
                      )}
                    >
                      Continue
                    </RippleButton>
                  </InputBlock>
                </Form>
              );
            }}
          </Formik>
        </ScreenContainer>
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default MerchantBusinessProfile;
