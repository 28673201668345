import React, { Fragment, useState, useEffect } from "react";

import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

// import { getAvailableBanks } from '../../../../../redux/ducks/account/wallet/actions/bank-account';
import {
  TopHeader,
  InputWithLabel,
  SelectBank,
  SelectBox,
  TextareaWithLabel,
  UserAvatar,
} from "../../../../../components";
import Avatar from "../../../../../components/avatar/assets/user.svg";
import { SelectCountryOverlay } from "../../../../../components/overlays/select-country";
import {
  InputBlock,
  OpenOverlaySelectBox,
} from "../../../../../containers/InputContainer";
import { Message, Error } from "../../../../../containers/MessageContainer";
import {
  ViewContainer,
  ScreenContainer,
  FlexCenteredBlock,
} from "../../../../../containers/ScreenContainer";
import { countriesData, flags } from "../../../../../data/countries";
import { localAreas } from "../../../../../data/countries/nigeria/localAreas";
import { states } from "../../../../../data/countries/nigeria/states";
import { resolveBanks } from "../../../../../redux/ducks/account/wallet/actions/bank-account";
import { CLEAR_ACCOUNT } from "../../../../../redux/ducks/account/wallet/constants.js";
import { updateCustomer } from "../../../../../redux/ducks/applications/my-shop/actions/customers";
import { selectAreasByState } from "../../../../../utils/inputs/selectAreasByState";
import { listToAlphabetMap } from "../../../../../utils/sorting/alphabeticSort";
import DesktopBackgroundLayout from "../../../../DesktopBackgroundLayout";
import {
  CountryFlag,
  CurrentSelectedCountry,
} from "../../../../launch/components/Main";
import {
  RemoveMessage,
  CountryBlock,
  MessageWrapper,
  AccountInput,
  AccountDiv,
  AddButton,
  ImageSpan,
  AddMore,
} from "../add-customer";
import { AddFields } from "../add-fields";
import { ReactComponent as CheckedIcon } from "../assets/checked.svg";
import { ReactComponent as Plus } from "../assets/plus.svg";
import CustomerDetails from "../customer-details";

import { EditCustomerValidationSchema } from "./EditCustomerValidationSchema";

const identifySelectedFields = (customer) => {
  const selectedFields = [];

  if (customer.phoneNumber2) selectedFields.push("PHONE_NUMBER_2");

  if (customer.email) selectedFields.push("EMAIL");

  if (
    customer.homeAddress &&
    Object.values(customer.homeAddress).every((value) => value !== "")
  ) {
    selectedFields.push("HOUSE_ADDRESS");
  }

  if (
    customer.bank &&
    Object.values(customer.bank).every((value) => value !== "")
  ) {
    selectedFields.push("BANK_ACCOUNT");
  }

  if (customer.deliveryInstruction) selectedFields.push("DELIVERY_NOTE");

  return selectedFields;
};

const EditCustomerDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const customer = location.state;
  const [customerDetailsOpen, setCustomerDetailsOpen] = useState(false);
  const [openSelectBank, setOpenSelectBank] = useState(false);
  const [selectedBank, setSelectedBank] = useState(undefined);
  const [openAddFields, setOpenAddFields] = useState(false);
  const [selectedFields, setSelectedFields] = useState(
    identifySelectedFields(customer)
  );
  const [top, setTop] = useState(false);
  const [className, setClassName] = useState(true);
  const [openCountrySelect, setOpenCountrySelect] = useState(false);
  const banks = useSelector((state) => state.account.wallet.bankName);
  const getCustomers = useSelector(
    (state) => state.applications.myShop.customers
  );

  const getError = useSelector((state) => state.applications.myShop.error);

  const modifiedCustomer = getCustomers.filter(
    (getCustomer) =>
      getCustomer.name === customer.name ||
      getCustomer.phoneNumber === customer.phoneNumber
  );

  const countriesInfo = listToAlphabetMap(
    countriesData
      .filter((country) => country.name === "Nigeria")
      .map((country) => country.name)
  );

  useEffect(() => {
    dispatch({ type: CLEAR_ACCOUNT, payload: undefined });
  }, [dispatch]);

  const msisdn = useSelector((state) => state.user.msisdn);

  return (
    <Fragment>
      <DesktopBackgroundLayout>
        {!openSelectBank && !openAddFields && !customerDetailsOpen && (
          <TopHeader title={"Edit Customer"} />
        )}
        <ScreenContainer top={"80px"}>
          <FlexCenteredBlock mtop={"15px"} mbottom={"32px"}>
            <UserAvatar width={"72px"} height={"72px"} avatar={Avatar} />
          </FlexCenteredBlock>
          <ViewContainer top={"25px"}>
            <Formik
              initialValues={{
                name: customer.name,
                phoneNumber: customer.phoneNumber,
                phoneNumber2: customer.phoneNumber2
                  ? customer.phoneNumber2
                  : "",
                country: "SA",
                email: customer.email ? customer.email : "",
                homeAddress: customer.homeAddress
                  ? {
                      address: customer.homeAddress.address,
                      state: customer.homeAddress.state,
                      lga: customer.homeAddress.lga,
                      city: customer.homeAddress.city,
                    }
                  : {
                      address: "",
                      state: "",
                      lga: "",
                      city: "",
                    },
                bank: customer.bank
                  ? {
                      name: customer.bank.name,
                      accountNumber: customer.bank.accountNumber,
                    }
                  : {
                      name: "",
                      accountNumber: "",
                    },
                deliveryInstruction: customer.deliveryInstruction
                  ? customer.deliveryInstruction
                  : "",
              }}
              validationSchema={EditCustomerValidationSchema}
              onSubmit={(values, { setErrors }) => {
                // values.phoneNumber = insertZero(values.phoneNumber);
                // if (values.phoneNumber2) {
                //     values.phoneNumber2 = insertZero(values.phoneNumber2);
                // }
                if (msisdn === values.phoneNumber) {
                  setErrors({
                    phoneNumber:
                      "Sorry, you cannot use your number for a customer",
                  });
                  toast.error(
                    "Sorry, you cannot use your number for a customer"
                  );
                } else if (
                  values.phoneNumber2 &&
                  msisdn === values.phoneNumber2
                ) {
                  setErrors({
                    phoneNumber2:
                      "Sorry, you cannot use your number for a customer",
                  });
                  toast.error(
                    "Sorry, you cannot use your number for a customer"
                  );
                } else if (
                  values.phoneNumber.trim().startsWith("0") &&
                  values.phoneNumber.trim().length <= 10
                ) {
                  setErrors({
                    phoneNumber: "Must be minimum 10 digits length",
                  });
                  toast.error("Must be minimum 10 digits length");
                } else if (
                  values.phoneNumber2.trim().startsWith("0") &&
                  values.phoneNumber2.trim().length <= 10
                ) {
                  setErrors({
                    phoneNumber2: "Must be minimum 10 digits length",
                  });
                  toast.error("Must be minimum 10 digits length");
                } else {
                  setTimeout(() => {
                    dispatch(updateCustomer(customer.id, values));
                    if (getError === undefined || !getError) {
                      setCustomerDetailsOpen(!customerDetailsOpen);
                    } else {
                      setCustomerDetailsOpen(false);
                    }
                  }, 200);
                }
              }}
            >
              {({
                errors,
                touched,
                setFieldValue,
                initialValues,
                handleChange,
                handleBlur,
                values,
              }) => (
                <Form>
                  <InputBlock>
                    <InputWithLabel
                      label={"Full name"}
                      noClearButton
                      type={"text"}
                      value={values.name}
                      placeholder={"Full name"}
                      name={"name"}
                      valid={`${!touched.name && !errors.name}`}
                      errors={touched && touched.name && errors && errors.name}
                      setFieldValue={setFieldValue}
                      initialValues={initialValues}
                    />
                    <Fragment>
                      <MessageWrapper>
                        <RemoveMessage
                          top={"16px"}
                          bottom={"9px"}
                          color={"#718596"}
                          size={"12px"}
                          weight={"normal"}
                          className={className ? "hide" : ""}
                        >
                          CONTACT
                        </RemoveMessage>
                        <RemoveMessage
                          top={"16px"}
                          bottom={"9px"}
                          color={"#CD4213"}
                          size={"12px"}
                          weight={"400"}
                          className={className ? "hide" : ""}
                          onClick={() => {
                            setSelectedFields(
                              selectedFields.filter(
                                (selectedField) =>
                                  selectedField !== "PHONE_NUMBER_2" &&
                                  selectedField !== "EMAIL"
                              )
                            );
                          }}
                        >
                          Remove field
                        </RemoveMessage>
                      </MessageWrapper>
                      <CountryBlock>
                        <CurrentSelectedCountry>
                          <CountryFlag
                            onClick={() =>
                              setOpenCountrySelect(!openCountrySelect)
                            }
                            src={
                              flags.filter(
                                (flag) =>
                                  flag.customAbbreviation === values.country
                              )[0].value
                            }
                            alt={
                              flags.filter(
                                (flag) =>
                                  flag.customAbbreviation === values.country
                              )[0].label
                            }
                          />
                        </CurrentSelectedCountry>
                        <SelectCountryOverlay
                          open={openCountrySelect}
                          setOpen={setOpenCountrySelect}
                          countriesInfo={countriesInfo}
                          currentCountry={values.country}
                          setCountry={setFieldValue}
                        />
                        <InputWithLabel
                          countryselection={"2px"}
                          label={"Phone Number"}
                          noClearButton
                          type={"number"}
                          bottom={"15px"}
                          value={values.phoneNumber}
                          placeholder={"Phone Number 1"}
                          name={"phoneNumber"}
                          onKeyUp={(e) =>
                            (e.target.value = e.target.value.replace(/\s/g, ""))
                          }
                          valid={`${
                            !touched.phoneNumber && !errors.phoneNumber
                          }`}
                          errors={
                            touched &&
                            touched.phoneNumber &&
                            errors &&
                            errors.phoneNumber
                          }
                          setFieldValue={setFieldValue}
                          initialValues={initialValues}
                        />
                      </CountryBlock>
                    </Fragment>
                    {selectedFields.includes("PHONE_NUMBER_2") && (
                      <CountryBlock>
                        <CurrentSelectedCountry>
                          <CountryFlag
                            onClick={() =>
                              setOpenCountrySelect(!openCountrySelect)
                            }
                            src={
                              flags.filter(
                                (flag) =>
                                  flag.customAbbreviation === values.country
                              )[0].value
                            }
                            alt={
                              flags.filter(
                                (flag) =>
                                  flag.customAbbreviation === values.country
                              )[0].label
                            }
                          />
                        </CurrentSelectedCountry>
                        <SelectCountryOverlay
                          open={openCountrySelect}
                          setOpen={setOpenCountrySelect}
                          countriesInfo={countriesInfo}
                          currentCountry={values.country}
                          setCountry={setFieldValue}
                        />
                        <InputWithLabel
                          countryselection={"2px"}
                          noClearButton
                          label={"Phone Number 2"}
                          type={"number"}
                          value={values.phoneNumber2}
                          placeholder={"Phone Number 2"}
                          name={"phoneNumber2"}
                          onKeyUp={(e) =>
                            (e.target.value = e.target.value.replace(/\s/g, ""))
                          }
                          valid={`${
                            !touched.phoneNumber2 && !errors.phoneNumber2
                          }`}
                          errors={
                            touched &&
                            touched.phoneNumber2 &&
                            errors &&
                            errors.phoneNumber2
                          }
                          setFieldValue={setFieldValue}
                          initialValues={initialValues}
                        />
                      </CountryBlock>
                    )}
                    {selectedFields.includes("EMAIL") && (
                      <InputWithLabel
                        label={"Email Address"}
                        noClearButton
                        type={"text"}
                        mtop={"0"}
                        mheight={"20px"}
                        value={values.email}
                        placeholder={"Email Address"}
                        name={"email"}
                        valid={`${!touched.email && !errors.email}`}
                        errors={
                          touched && touched.email && errors && errors.email
                        }
                        setFieldValue={setFieldValue}
                        initialValues={initialValues}
                      />
                    )}
                    {selectedFields.includes("HOUSE_ADDRESS") && (
                      <Fragment>
                        <MessageWrapper>
                          <Message
                            top={"16px"}
                            bottom={"9px"}
                            color={"#718596"}
                            size={"12px"}
                            weight={"normal"}
                          >
                            ADDRESS
                          </Message>
                          <Message
                            top={"16px"}
                            bottom={"9px"}
                            color={"#CD4213"}
                            size={"12px"}
                            weight={"400"}
                            onClick={() => {
                              setSelectedFields(
                                selectedFields.filter(
                                  (selectedField) =>
                                    selectedField !== "HOUSE_ADDRESS"
                                )
                              );
                            }}
                          >
                            Remove field
                          </Message>
                        </MessageWrapper>
                        <InputBlock>
                          <InputWithLabel
                            label={"Home Address"}
                            type={"text"}
                            bottom={"0px"}
                            noClearButton
                            value={values.homeAddress.address}
                            placeholder={"Street Address"}
                            name={"homeAddress.address"}
                            valid={`${
                              !(
                                touched &&
                                touched.homeAddress &&
                                touched.homeAddress.address
                              ) &&
                              !(
                                errors &&
                                errors.homeAddress &&
                                errors.homeAddress.address
                              )
                            }`}
                            errors={
                              touched &&
                              touched.homeAddress &&
                              touched.homeAddress.address &&
                              errors &&
                              errors.homeAddress &&
                              errors.homeAddress.address
                            }
                            setFieldValue={setFieldValue}
                            initialValues={initialValues}
                          />
                          <SelectBox
                            name={"homeAddress.state"}
                            opacity={"0.5"}
                            placeholder={"Province"}
                            value={values.homeAddress.state}
                            options={states}
                            handleChange={handleChange}
                            valid={`${
                              !(
                                touched &&
                                touched.homeAddress &&
                                touched.homeAddress.state
                              ) &&
                              !(
                                errors &&
                                errors.homeAddress &&
                                errors.homeAddress.state
                              )
                            }`}
                            error={
                              touched &&
                              touched.homeAddress &&
                              touched.homeAddress.state &&
                              errors &&
                              errors.homeAddress &&
                              errors.homeAddress.state
                            }
                          />
                          {errors &&
                            errors.homeAddress &&
                            errors.homeAddress.state && (
                              <Error>{errors.homeAddress.state}</Error>
                            )}
                          <SelectBox
                            name={"homeAddress.lga"}
                            opacity={"0.5"}
                            placeholder={"Municipalities"}
                            value={values.homeAddress.lga}
                            options={
                              values.homeAddress && values.homeAddress.state
                                ? selectAreasByState(
                                    values.homeAddress.state,
                                    localAreas
                                  )
                                : []
                            }
                            handleChange={handleChange}
                            valid={`${
                              !(
                                touched &&
                                touched.homeAddress &&
                                touched.homeAddress.lga
                              ) &&
                              !(
                                errors &&
                                errors.homeAddress &&
                                errors.homeAddress.lga
                              )
                            }`}
                            errors={
                              touched &&
                              touched.homeAddress &&
                              touched.homeAddress.lga &&
                              errors &&
                              errors.homeAddress &&
                              errors.homeAddress.lga
                            }
                          />
                          {errors &&
                            errors.homeAddress &&
                            errors.homeAddress.lga && (
                              <Error>{errors.homeAddress.lga}</Error>
                            )}
                          <InputWithLabel
                            label={"City"}
                            top={"15px"}
                            type={"text"}
                            noClearButton
                            value={values.homeAddress.city}
                            placeholder={"City"}
                            name={"homeAddress.city"}
                            valid={`${
                              !(
                                touched &&
                                touched.homeAddress &&
                                touched.homeAddress.city
                              ) &&
                              !(
                                errors &&
                                errors.homeAddress &&
                                errors.homeAddress.city
                              )
                            }`}
                            errors={
                              touched &&
                              touched.homeAddress &&
                              touched.homeAddress.city &&
                              errors &&
                              errors.homeAddress &&
                              errors.homeAddress.city
                            }
                            setFieldValue={setFieldValue}
                            initialValues={initialValues}
                          />
                        </InputBlock>
                      </Fragment>
                    )}
                    {selectedFields.includes("BANK_ACCOUNT") && (
                      <AccountDiv>
                        <MessageWrapper>
                          <Message
                            top={"16px"}
                            bottom={"9px"}
                            color={"#718596"}
                            size={"12px"}
                            weight={"normal"}
                          >
                            BANK INFORMATION
                          </Message>
                          <Message
                            top={"16px"}
                            bottom={"9px"}
                            color={"#CD4213"}
                            size={"12px"}
                            weight={"400"}
                            onClick={() => {
                              setSelectedFields(
                                selectedFields.filter(
                                  (selectedField) =>
                                    selectedField !== "BANK_ACCOUNT"
                                )
                              );
                            }}
                          >
                            Remove field
                          </Message>
                        </MessageWrapper>
                        <InputBlock>
                          <OpenOverlaySelectBox
                            style={{
                              opacity: "0.5",
                            }}
                            weight={"400"}
                            textColor={"#6F798B"}
                            onClick={() => setOpenSelectBank(!openSelectBank)}
                          >
                            {values.bank.name || "Bank"}
                          </OpenOverlaySelectBox>
                          {errors && errors.bank && errors.bank.name && (
                            <Error>{errors.bank.name}</Error>
                          )}
                          <AccountInput
                            label={"Account Number"}
                            bottom={"4px"}
                            type={"text"}
                            noClearButton
                            value={values.bank.accountNumber}
                            placeholder={"Account Number"}
                            name={"bank.accountNumber"}
                            valid={`${
                              !(
                                touched &&
                                touched.bank &&
                                touched.bank.accountNumber
                              ) &&
                              !(
                                errors &&
                                errors.bank &&
                                errors.bank.accountNumber
                              )
                            }`}
                            errors={
                              touched &&
                              touched.bank &&
                              touched.bank.accountNumber &&
                              errors &&
                              errors.bank &&
                              errors.bank.accountNumber
                            }
                            onKeyUp={(e) =>
                              banks === undefined
                                ? dispatch(
                                    resolveBanks(e.target.value, selectedBank)
                                  )
                                : dispatch({
                                    type: CLEAR_ACCOUNT,
                                    payload: undefined,
                                  })
                            }
                            setFieldValue={setFieldValue}
                            initialValues={initialValues}
                          />
                        </InputBlock>
                        {openSelectBank && (
                          <SelectBank
                            open={openSelectBank}
                            setOpen={setOpenSelectBank}
                            selectedBank={selectedBank}
                            setSelectedBank={setSelectedBank}
                            // getAvailableBanks={getAvailableBanks}
                            setFieldValue={setFieldValue}
                            fieldName={"bank.name"}
                          />
                        )}
                        <CheckedIcon
                          style={{
                            position: "absolute",
                            bottom: "21%",
                            left: "90%",
                            display: banks === undefined ? "none" : "block",
                          }}
                        ></CheckedIcon>
                        <Message
                          top={"0"}
                          left={"14px"}
                          size={"10px"}
                          color={"#00B361"}
                        >
                          {banks}
                        </Message>
                      </AccountDiv>
                    )}
                    {selectedFields.includes("DELIVERY_NOTE") && (
                      <Fragment>
                        <MessageWrapper>
                          <Message
                            top={"16px"}
                            bottom={"9px"}
                            color={"#718596"}
                            size={"12px"}
                            weight={"normal"}
                          >
                            DELIVERY NOTE
                          </Message>
                          <Message
                            top={"16px"}
                            bottom={"9px"}
                            color={"#CD4213"}
                            size={"12px"}
                            weight={"400"}
                            onClick={() => {
                              setSelectedFields(
                                selectedFields.filter(
                                  (selectedField) =>
                                    selectedField !== "DELIVERY_NOTE"
                                )
                              );
                            }}
                          >
                            Remove field
                          </Message>
                        </MessageWrapper>
                        <TextareaWithLabel
                          label={"Delivery Note"}
                          value={values.deliveryInstruction}
                          onChange={handleChange}
                          type={"text"}
                          height={"80px"}
                          placeholder={
                            "Please put down your thoughts / comments here. The merchant will not see it."
                          }
                          name={"deliveryInstruction"}
                          valid={`${
                            !touched.deliveryInstruction &&
                            !errors.deliveryInstruction
                          }`}
                          errors={
                            touched &&
                            touched.deliveryInstruction &&
                            errors &&
                            errors.deliveryInstruction
                          }
                          setFieldValue={setFieldValue}
                          initialValues={initialValues}
                        />
                      </Fragment>
                    )}
                  </InputBlock>
                  <AddMore
                    onClick={() => {
                      setOpenAddFields(!openAddFields);
                      setClassName(false);
                      setTop(true);
                    }}
                  >
                    <ImageSpan>
                      <Plus />
                    </ImageSpan>
                    Add more info
                  </AddMore>
                  <AddButton
                    className={top ? "top" : ""}
                    top={"160px"}
                    size={"16px"}
                    type={"submit"}
                  >
                    Update Customer
                  </AddButton>
                </Form>
              )}
            </Formik>
            {openAddFields && (
              <AddFields
                open={openAddFields}
                setOpen={setOpenAddFields}
                selectedFields={selectedFields}
                setSelectedFields={setSelectedFields}
              />
            )}
            {customerDetailsOpen && (
              <CustomerDetails
                customer={modifiedCustomer[0]}
                open={customerDetailsOpen}
                setOpen={setCustomerDetailsOpen}
              />
            )}
          </ViewContainer>
        </ScreenContainer>
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default EditCustomerDetails;
