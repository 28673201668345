import domtoimage from "dom-to-image";
import { jsPDF } from "jspdf";
import { toast } from "react-toastify";

export const htmlToFormData = (id, callback, dispatch) => {
  return new Promise((resolve, reject) => {
    var element = document.getElementById(id);
    const scale = 750 / element.offsetWidth;
    domtoimage
      .toPng(element, {
        height: element.offsetHeight * scale,
        width: element.offsetWidth * scale,
        style: {
          transform: "scale(" + scale + ")",
          transformOrigin: "top left",
          width: element.offsetWidth + "px",
          height: element.offsetHeight + "px",
        },
      })
      .then(async function (base64) {
        try {
          const url = await dispatch(callback(base64));
          url && resolve(url);
        } catch (error) {
          if (error.message === "Network Error") {
            toast.error(error.message);
          } else {
            reject("");
          }
        }
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
  });
};

export const htmlToPdf = async (frontId, backId, callback, dispatch) => {
  var frontElement = document.getElementById(frontId);
  var backElement = document.getElementById(backId);
  const scaleFrontElement = 750 / frontElement.offsetWidth / 2;
  const scaleBackElement = 750 / backElement.offsetWidth / 2;

  var frontBase64 = await domtoimage.toPng(frontElement, {
    height: frontElement.offsetHeight * scaleFrontElement,
    width: frontElement.offsetWidth * scaleFrontElement,
    style: {
      transform: "scale(" + scaleFrontElement + ")",
      transformOrigin: "top left",
      width: frontElement.offsetWidth + "px",
      height: frontElement.offsetHeight + "px",
    },
  });

  var backBase64 = await domtoimage.toPng(backElement, {
    height: backElement.offsetHeight * scaleBackElement,
    width: backElement.offsetWidth * scaleBackElement,
    style: {
      transform: "scale(" + scaleBackElement + ")",
      transformOrigin: "top left",
      width: backElement.offsetWidth + "px",
      height: backElement.offsetHeight + "px",
    },
  });

  try {
    const url1 = await dispatch(callback(frontBase64));
    const url2 = await dispatch(callback(backBase64));
    if (url1 && url2) {
      return Promise.all([url1, url2]);
    }
  } catch (error) {
    if (error.message === "Network Error") {
      toast.error(error.message);
    } else {
      Promise.reject(error);
    }
  }
};

export const downloadSalesReceipt = async (receiptId) => {
  var receiptElement = document.getElementById(receiptId);
  const scaleReceiptElement = 750 / receiptElement.offsetWidth / 2;
  const outputHeight = receiptElement.offsetHeight * scaleReceiptElement;
  const outputWidth = receiptElement.offsetWidth * scaleReceiptElement;

  var receiptBase64 = await domtoimage.toPng(receiptElement, {
    height: outputHeight,
    width: outputWidth,
    style: {
      transform: "scale(" + scaleReceiptElement + ")",
      transformOrigin: "top left",
      width: receiptElement.offsetWidth + "px",
      height: receiptElement.offsetHeight + "px",
    },
  });

  try {
    const pdf = new jsPDF("p", "px", "a4");
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();

    const widthRatio = pageWidth / outputWidth;
    const heightRatio = pageHeight / outputHeight;
    const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

    const canvasWidth = outputWidth * ratio;
    const canvasHeight = outputHeight * ratio;
    const marginX = (pageWidth - canvasWidth) / 2;
    const marginY = (pageHeight - canvasHeight) / 2;

    pdf.addImage(
      receiptBase64,
      "JPEG",
      marginX,
      marginY,
      canvasWidth,
      canvasHeight
    );
    //pdf.output('dataurlnewwindow');
    pdf.save("download.pdf");
    return "success";
  } catch (error) {
    return error;
  }
};
