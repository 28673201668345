export function tryParseJSONObject(jsonString) {
  try {
    const parsedString = JSON.parse(jsonString);
    if (parsedString && typeof parsedString === "object") {
      return parsedString;
    }
  } catch (e) {
    console.log(e);
  }
  return false;
}
