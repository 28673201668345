export const sortSales = (type, sales, dateRange, singleDate, setAllSales) => {
  if (type === "All time") {
    setAllSales(
      sales.sort(
        (a, b) =>
          new Date(b.salesInfo.localSalesDate).getTime() -
          new Date(a.salesInfo.localSalesDate).getTime()
      )
    );
  } else if (type === "Specific date") {
    setAllSales(
      sales.filter(
        (data) =>
          new Date(data.salesInfo.localSalesDate).getDate() ===
            singleDate.day &&
          new Date(data.salesInfo.localSalesDate).getMonth() + 1 ===
            singleDate.month &&
          new Date(data.salesInfo.localSalesDate).getFullYear() ===
            singleDate.year
      )
    );
  } else if (type === "Date range") {
    setAllSales(
      sales.filter(
        (data) =>
          new Date(data.salesInfo.localSalesDate).getDate() >=
            dateRange.from.day &&
          new Date(data.salesInfo.localSalesDate).getMonth() + 1 >=
            dateRange.from.month &&
          new Date(data.salesInfo.localSalesDate).getFullYear() ===
            dateRange.from.year &&
          new Date(data.salesInfo.localSalesDate).getDate() <=
            dateRange.to.day &&
          new Date(data.salesInfo.localSalesDate).getMonth() + 1 <=
            dateRange.to.month &&
          new Date(data.salesInfo.localSalesDate).getFullYear() ===
            dateRange.to.year
      )
    );
  }
};
