import React from "react";

import styled from "styled-components";

import ProductFallbackImage from "../../../../../components/popup/merchbuy-add-to-cart-popup/assets/productImage.svg";
import { formatPrice } from "../../../../../utils/currency/formatPriceWithComma";
import { is_url } from "../../../../../utils/urls/isUrl";
import {
  Paragraph,
  ProductBlock,
  ProductImage,
  ProductImageContainer,
} from "../styles";

const SingleRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  font-size: 12px;
  align-items: center;
  justify-items: center;
  width: 100%;
  margin-bottom: 22px;
`;

export const SharedQuantityRow = ({
  images,
  invitedSlots: sharingFormula,
  unitPrice,
}) => {
  return (
    <>
      {sharingFormula.map((quantity, index) => (
        <SingleRow key={index}>
          <Paragraph fontSize={"12px"} textAlign={"left"} width={"100%"}>
            Slot {index + 1}
            {index === 0 ? "(You)" : null}
          </Paragraph>

          <ProductBlock
            margin={"0"}
            padding={"0"}
            shadow={"none"}
            alignItems={"center"}
          >
            <ProductImageContainer
              height={"24px"}
              width={"24px"}
              marginRight={"8px"}
            >
              <ProductImage
                src={
                  is_url(images ? images.baseImageUrl : "")
                    ? images.baseImageUrl
                    : ProductFallbackImage
                }
                onError={(e) => {
                  e.target.src = ProductFallbackImage;
                }}
                alt="product image"
              />
            </ProductImageContainer>

            <Paragraph fontSize={"12px"}>x {quantity} pcs</Paragraph>
          </ProductBlock>

          <Paragraph fontSize={"12px"} textAlign={"right"} width={"100%"}>
            {formatPrice(unitPrice * quantity)}
          </Paragraph>
        </SingleRow>
      ))}
    </>
  );
};
