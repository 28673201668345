import React, { Fragment, useEffect } from "react";

import { number, bool, func } from "prop-types";

import {
  ResendLabel,
  ResendCode,
  ResendTimer,
  SendViaWhatsApp,
} from "../../containers/MessageContainer";
import { colors } from "../../styles";

export const ResendCodeTimer = ({
  counter,
  expired,
  sendViaWhatsApp,
  setExpired,
  tick,
  resendCode,
  resendButtonText,
  showWhatsAppButton = true,
}) => {
  useEffect(() => {
    const timer =
      counter >= 0 &&
      !expired &&
      setInterval(() => {
        tick(counter);
      }, 1000);

    if (counter < 0) clearInterval(timer);

    return () => clearInterval(timer);
  }, [counter, tick, expired]);

  useEffect(() => {
    if (counter < 0) setExpired(true);
  }, [counter, setExpired]);

  const sendViaWhatsAppClick = (event) => {
    event.preventDefault();
    sendViaWhatsApp();
  };

  return counter >= 0 && !expired ? (
    <ResendLabel>
      Resend in <ResendTimer color={colors.deepBlue}>0:{counter}</ResendTimer>
    </ResendLabel>
  ) : (
    <Fragment>
      <ResendCode
        type="button"
        style={{ color: colors.deepBlue }}
        onClick={(event) => {
          event.preventDefault();
          resendCode();
        }}
      >
        {resendButtonText || "Resend Code"}
      </ResendCode>
      {showWhatsAppButton && (
        <SendViaWhatsApp type="button" onClick={sendViaWhatsAppClick}>
          Get code via whatsapp
        </SendViaWhatsApp>
      )}
    </Fragment>
  );
};

ResendCodeTimer.propTypes = {
  counter: number,
  expired: bool,
  setExpired: func,
  tick: func,
  resendCode: func,
};
