import React from "react";

import { any, string } from "prop-types";

import FallbackProductImage from "../../assets/productImage.svg";
import {
  AddGridBox,
  AddGridContainer,
  ProductListFlex,
  SeeAllBoxWrapper,
  SeeAllBox,
  LinkText,
  ProductImage,
} from "../../styles";
import { Product } from "../productItem";

export const ListProducts = ({ data, size, left, searchTerm }) => {
  return (
    <AddGridBox width={size.width}>
      {data &&
        data.map((item, index) => (
          <Product
            key={index}
            gridWidth={size.width}
            data={item}
            size={size}
            left={left}
            searchTerm={searchTerm}
          />
        ))}
    </AddGridBox>
  );
};

export const ListSampleProducts = ({ data, size, left, name, id, gap }) => {
  return (
    <AddGridContainer width={size.width} gap={gap}>
      {data &&
        data
          .slice(0, 5)
          .map((item, index) => (
            <Product key={index} data={item} size={size} left={left} />
          ))}
      {data?.length > 5 && (
        <SeeAllBoxWrapper>
          <ProductImage
            src={FallbackProductImage}
            alt="product image"
            width={size.width}
            height={size.height}
          />
          <SeeAllBox to={`/actions/merchbuy/products/${name}/${id}`}>
            <LinkText>See All</LinkText>
          </SeeAllBox>
        </SeeAllBoxWrapper>
      )}
    </AddGridContainer>
  );
};

export const ListProductsFlex = ({ data, size, left }) => {
  return (
    <ProductListFlex>
      {data &&
        data.map((item, index) => (
          <Product
            key={index}
            gridWidth="115px"
            data={item}
            size={size}
            left={left}
          ></Product>
        ))}
    </ProductListFlex>
  );
};

ListProducts.propTypes = {
  data: any,
  size: any,
  justifyContent: string,
};
