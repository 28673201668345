import { USER_LOGOUT } from "../../user/constants";

import {
  LOADING_MERCHANTS_REFERRALS_DATA,
  LOADING_MERCHANTS_REFERRALS_COMPLETED,
  LOADING_AGENT_REFERRALS_MERCHANTS_DATA,
  LOADING_AGENT_REFERRALS_MERCHANTS_COMPLETED,
  LOADING_MERCHANTS_VERIFICATION_COMPLETED,
  LOADING_MERCHANTS_VERIFICATION_DATA,
  SAVE_AGENTS_MERCHANTS,
  MERCHANT_LAST_SEEN,
  SAVE_MERCHANTS_COMMISSIONS,
  SAVE_MERCHANT_REFERRALS,
  SAVE_REFERRED_MERCHANT_COUNT,
  VERIFY_IDENTITY_DETAILS,
} from "./constants";

const initialState = {
  isLoading: false,
  merchantsList: [],
  merchantsListCount: 0,
  merchantsReferral: [],
  merchVerify: {},
};

export default function merchantsReducer(state = initialState, action) {
  switch (action.type) {
    case LOADING_MERCHANTS_REFERRALS_DATA: {
      if (state.merchantsReferral && state.merchantsReferral.length > 0) {
        return {
          ...state,
          isLoading: false,
        };
      } else {
        return {
          ...state,
          isLoading: true,
        };
      }
    }

    case LOADING_MERCHANTS_REFERRALS_COMPLETED: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case LOADING_MERCHANTS_VERIFICATION_DATA: {
      if (state.merchVerify && Object.keys(state.merchVerify).length > 0) {
        return {
          ...state,
          isLoading: false,
        };
      } else {
        return {
          ...state,
          isLoading: true,
        };
      }
    }

    case LOADING_MERCHANTS_VERIFICATION_COMPLETED: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case SAVE_MERCHANT_REFERRALS: {
      return {
        ...state,
        merchantsReferral: action.payload,
      };
    }

    case LOADING_AGENT_REFERRALS_MERCHANTS_DATA: {
      if (state.merchantsList && state.merchantsList.length > 0) {
        return {
          ...state,
          isLoading: false,
        };
      } else {
        return {
          ...state,
          isLoading: true,
        };
      }
    }

    case LOADING_AGENT_REFERRALS_MERCHANTS_COMPLETED: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case SAVE_REFERRED_MERCHANT_COUNT: {
      return {
        ...state,
        merchantsListCount: action.payload,
      };
    }

    case SAVE_AGENTS_MERCHANTS: {
      return {
        ...state,
        merchantsList: action.payload,
      };
    }

    case MERCHANT_LAST_SEEN: {
      return {
        ...state,
        merchantsList: state.merchantsList.map((merchant) => {
          if (merchant.id === action.payload.id) {
            merchant.lastSeen = action.payload.lastSeen;
          }

          return merchant;
        }),
      };
    }

    case SAVE_MERCHANTS_COMMISSIONS: {
      const merchantId = action.payload.id;
      const foundCommissions = action.payload.foundCommissions;
      const totalCommission = action.payload.totalCommission;

      return {
        ...state,
        merchantsList: state.merchantsList.map((merchant) => {
          if (merchant.id === merchantId) {
            merchant.commissionsList = foundCommissions;
            merchant.commission = totalCommission;
          }

          return merchant;
        }),
      };
    }

    case VERIFY_IDENTITY_DETAILS: {
      return {
        ...state,
        merchVerify: action.payload,
      };
    }

    case USER_LOGOUT: {
      return {
        isLoading: false,
        merchantsList: [],
        merchantsReferral: [],
        merchantsListCount: 0,
        merchVerify: {},
      };
    }

    default: {
      return state;
    }
  }
}
