import React, { Fragment, useEffect, useState } from "react";

import Skeleton from "react-loading-skeleton";
import { useSelector, useDispatch } from "react-redux";
import { matchPath } from "react-router-dom";
import styled from "styled-components";
import useRouter from "use-react-router";

import { ReactComponent as SearchIcon } from "../../../../../src/assets/search.svg";
import FallbackShopImage from "../../../../../src/views/actions/merchbuy/assets/shop_image.svg";
import {
  DeliveryLocationPopup,
  InfiniteScrollList,
} from "../../../../components";
import { SearchInputWithCancel } from "../../../../components/forms/input/search-input/SearchInput";
import { TopHeader } from "../../../../components/header/index";
import { Badge } from "../../../../containers/BadgeContainer";
import { merchbuyActions } from "../../../../redux/ducks/applications/merchbuy/actions";
import { colors } from "../../../../styles";
import { is_url } from "../../../../utils/urls/isUrl";
import DesktopBackgroundLayout from "../../../DesktopBackgroundLayout";
import { Cart, ListProducts } from "../components";
import {
  PageSubSections,
  PageSubSectionsTop,
  Space,
  ProductMainImage,
  Main,
} from "../styles";

import { ShopInfoSection, ProductCategory } from "./style";

export const PageTopLeftShopTitle = styled.h4`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  margin-left: -10px;
  font-weight: 700;
  margin-left: ${({ left }) => left || "8px"};
  color: ${colors.lightDark};
  font-size: ${({ font }) => font || "14px"};
  padding-left: ${({ paddingLeft }) => paddingLeft || null};
`;

export const PageSubSectionsTopLeft = styled.div`
  display: flex;
  align-items: center;
  height: ${({ height }) => height || null};
`;
export const ShopDetailsName = styled.div`
  display: block;
  margin-left: auto;
  margin-top: 4px;
  margin-right: auto;
  text-align: center;
  font-size: 14px;
  color: #071827;
  font-weight: 700;
  line-height: 21px;
`;

export const ShopDetailsState = styled.div`
  display: block;
  margin-left: auto;
  font-weight: 400;
  margin-right: auto;
  text-align: center;
  font-size: 12px;
  color: #718596;
  line-height: 18px;
  margin-top: 4px;
  margin-bottom: 20px;
`;

const Card = styled.div`
  display: grid;
  width: 100%;
  gap: 10px;
  borderradius: 8px;
`;

const List = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-top: -10px;
`;

const CardImagePlaceHolder = styled.div`
  width: 100%;
  height: 100px;
`;

const StyledSearchIcon = styled(SearchIcon)`
  position: absolute;
  right: 52px;
`;

const ShopsContainer = styled.div`
  margin: 20px;
  margin-bottom: 0px;
`;

const ShopDetails = () => {
  const { location } = useRouter();
  const { pathname } = location;
  const pattern = `(.*)?${"/actions/merchbuy/shop/:id"}`;
  const match = matchPath(pathname, { path: pattern }) || {};
  const [hasMore, setHasMore] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const perPage = 50;
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [productCategoryLoading, setProductCategoryLoading] = useState(true);
  const dispatch = useDispatch();
  const [shopProducts, setShopProducts] = useState([]);
  const user = useSelector((state) => state.user);
  const totalProducts = useSelector(
    (state) => state.applications.merchbuy.merchbuyProductsByShopTotal
  );
  const shopDetails = useSelector((state) => state.applications.merchbuy.shop);

  const products = shopProducts;
  const [activeCategory, setActiveCategory] = useState("");
  const [deliveryLocation, setDeliveryLocation] = useState(
    JSON.parse(localStorage.getItem("deliveryLocation"))
  );
  const [openDeliverLocationPopup, setOpenDeliverLocationPopup] = useState(
    deliveryLocation === null
  );

  const getProductCategories = async (branchId, primaryCategoryId) => {
    const data = await dispatch(
      merchbuyActions.getProductsByProductCategoryID(
        branchId,
        primaryCategoryId
      )
    );
    setLoading(false);
    setShopProducts(data);
  };

  const getLocation = (data) => {
    if (data.state /*&& data.lga*/) {
      setDeliveryLocation(data);
      setOpenDeliverLocationPopup(!openDeliverLocationPopup);
    }
  };

  const fetchProducts = async () => {
    const products = await dispatch(
      merchbuyActions.getProductsByShopID(match.params.id, page, perPage)
    );
    if (products) {
      const { content } = products;
      setShopProducts(content);
    }
  };

  const fetchMore = () => {
    setTimeout(async () => {
      const newPage = page + 1;
      setPage(newPage);
      const products = await dispatch(
        merchbuyActions.getProductsByShopID(
          match.params.id,
          newPage,
          perPage,
          searchValue
        )
      );
      if (products) {
        const newProducts = [...shopProducts, ...products.content];
        setShopProducts(newProducts);
      }
    }, 3000);
  };

  const getAllProducts = async () => {
    setLoading(true);
    const products = await dispatch(
      merchbuyActions.getProductsByShopID(match.params.id, page, perPage)
    );
    setLoading(false);
    if (products) {
      const { content } = products;
      setShopProducts(content);
    }
  };

  useEffect(() => {
    if (products.length < totalProducts) return setHasMore(true);
    return setHasMore(false);
  }, [products, totalProducts]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setLoading(false);
      setProductCategoryLoading(false);
    }, 2000);
    return () => {
      clearTimeout(timeOut);
    };
  }, []);

  useEffect(() => {
    dispatch(merchbuyActions.getShopDetials(match.params.id));
  }, [match.params.id, dispatch]);

  useEffect(() => {
    setDeliveryLocation(localStorage.getItem("deliveryLocation"));
    fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      dispatch(merchbuyActions.unsubscribe());
    };
  }, [dispatch]);

  return (
    <Fragment>
      <DesktopBackgroundLayout>
        {!showSearchInput ? (
          <TopHeader title="Shop Details" size="14px">
            <StyledSearchIcon
              onClick={() => {
                setShowSearchInput(true);
              }}
            />
            <Cart />
          </TopHeader>
        ) : (
          <ShopsContainer>
            <SearchInputWithCancel
              placeholder="Search for products..."
              onCancel={() => {
                setShowSearchInput(false);
              }}
              onChange={(e) => {
                setSearchValue(e.target.value);
                const location = JSON.parse(deliveryLocation);
                const data = {
                  branchId: match.params.id,
                  terms: e.target.value,
                  state: location.state,
                  mobileNumber: user.msisdn,
                };
                setTimeout(async () => {
                  const searchResult = await dispatch(
                    merchbuyActions.getSearch(data)
                  );
                  setShopProducts(searchResult.content);
                }, 3000);
              }}
            />
          </ShopsContainer>
        )}
        <Main paddingTop={showSearchInput ? "0" : "65px"}>
          <Badge>
            <ProductMainImage
              src={
                is_url(shopDetails.imageUrl)
                  ? shopDetails.imageUrl
                  : FallbackShopImage
              }
              bg={is_url(shopDetails.imageUrl) ? null : "#E9F2FF"}
              wd={is_url(shopDetails.imageUrl) ? null : "scale-down"}
              alt="product image"
              width={"100%"}
              height={"auto"}
            />
            <ShopDetailsName>{shopDetails.name || ""}</ShopDetailsName>
            <ShopDetailsState>
              {" "}
              {shopDetails.location && shopDetails.location.state} Nigeria
            </ShopDetailsState>
          </Badge>
          <ShopInfoSection>
            {productCategoryLoading &&
              Array(2)
                .fill()
                .map((_item, index) => (
                  <Skeleton
                    width={`89px`}
                    height={"26px"}
                    key={index}
                    style={{
                      marginLeft: "3px",
                      display: "flex",
                      gap: "10px",
                      marginTop: "5px",
                      borderRadius: "15px",
                      overflow: "hidden",
                      perspective: "1px",
                      float: "left",
                    }}
                  />
                ))}
            {!shopDetails.productCategories
              ? null
              : !productCategoryLoading &&
                shopDetails.productCategories.map((item, index) => (
                  <ProductCategory
                    width={"auto"}
                    height={"30px"}
                    radius={"32px"}
                    key={index}
                    style={{
                      border:
                        activeCategory === item.id ? "1px solid #227EFF" : "",
                      marginTop: 5,
                    }}
                    onClick={() => {
                      setLoading(true);
                      setActiveCategory(item.id);
                      setPage(0);
                      getProductCategories(match.params.id, item.id);
                      if (item.id === activeCategory) {
                        getAllProducts();
                        setPage(0);
                        setActiveCategory("");
                      }
                    }}
                  >
                    {item.name}
                  </ProductCategory>
                ))}
            <Space />
          </ShopInfoSection>
          <PageSubSections>
            <PageSubSectionsTop>
              <PageSubSectionsTopLeft>
                <PageTopLeftShopTitle>Products</PageTopLeftShopTitle>
              </PageSubSectionsTopLeft>
            </PageSubSectionsTop>
            <List>
              {loading &&
                Array(9)
                  .fill()
                  .map((_item, index) => (
                    <Card key={index}>
                      <CardImagePlaceHolder>
                        <Skeleton
                          width={`102px`}
                          height={`102px`}
                          style={{
                            gap: "10px",
                            marginTop: "10px",
                            borderRadius: "8px",
                            overflow: "hidden",
                            perspective: "1px",
                          }}
                        />
                      </CardImagePlaceHolder>
                      <Skeleton
                        width={`102px`}
                        height={`12px`}
                        style={{
                          marginTop: "10px",
                          marginLeft: "1px",
                        }}
                      />
                      <div style={{ marginTop: "-9px", marginLeft: "1px" }}>
                        <Skeleton width={`47px`} height={`12px`} />
                      </div>
                    </Card>
                  ))}
            </List>
            <InfiniteScrollList
              data={products}
              fetchMore={fetchMore}
              hasMore={hasMore}
              endMessage=""
            >
              {!loading && (
                <ListProducts
                  data={products}
                  size={{ width: "98px", height: "98px" }}
                />
              )}
            </InfiniteScrollList>
          </PageSubSections>
          {openDeliverLocationPopup && (
            <DeliveryLocationPopup
              open={openDeliverLocationPopup}
              getLocation={getLocation}
              cancel={() => {
                setOpenDeliverLocationPopup(!openDeliverLocationPopup);
              }}
            />
          )}
        </Main>
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default ShopDetails;
