import React from "react";

import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledContainer = styled.div`
  display: block;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 425px) {
    max-width: 100%;
  }
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
  @media (min-width: 1366px) {
    max-width: 1248px;
  }
`;
const Row = styled.div`
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`;
const StyledFooter = styled.footer`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  background: #ffffff;
  align-items: center;
  width: 100%;
  padding: 10px 15px;
  padding-bottom: 60px;
  margin: 0;
  font-size: 14px;
  line-height: 24px;
  @media (max-width: 768px) {
    padding: 5px 25px 15px;
    text-align: center;
  }
`;
const FooterColumnText = styled.div`
  position: relative;
  -webkit-flex: 0 0 33.33333333%;
  -ms-flex: 0 0 33.33333333%;
  flex: 0 0 33.33333333%;
  max-width: 100%;
  right: 66.5%;

  font-size: 14px;
  line-height: 24px;
  margin-right: auto !important;
  @media (max-width: 768px) {
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    width: 100%;
    right: 0;
    text-align: center;
  }
`;
const FooterColumnLink = styled.div`
  position: relative;
  -webkit-flex: 0 0 66.66666667%;
  -ms-flex: 0 0 66.66666667%;
  flex: 0 0 66.66666667%;
  max-width: 100%;
  left: 75.5%;

  font-size: 14px;
  line-height: 24px;
  @media (max-width: 768px) {
    display: block;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    width: 100%;
    left: 0;
    text-align: center;
    margin-bottom: 20px;
  }
`;
const Copyright = styled.p`
  color: #071827;
  margin: 0;

  @media (max-width: 768px) {
    color: #718596;
  }
`;
const FooterLink = styled(Link)`
  color: #071827;
  cursor: pointer;
  outline: 0 none;
  margin-left: 31px;
  margin-right: 31px;

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    color: #227eff;
  }
  @media (max-width: 768px) {
    display: block;
    width: 100%;
    color: #071827;
    margin: 0;
    margin-bottom: 15px;
  }
`;

export const Footer = () => {
  return (
    <StyledContainer>
      <Row>
        <StyledFooter>
          <FooterColumnLink>
            <FooterLink to="/privacyPolicy">Privacy Policy</FooterLink>
            <FooterLink to="/termsAndConditions">
              Terms and Conditions
            </FooterLink>
          </FooterColumnLink>
          <FooterColumnText>
            <Copyright>© 2021 Spaces Inc.</Copyright>
          </FooterColumnText>
        </StyledFooter>
      </Row>
    </StyledContainer>
  );
};
