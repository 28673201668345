import React, { useEffect } from "react";
import { any } from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { AddFlexBox } from "../../styles";
import { ProductCategoryItem } from "../ProductCategoryItem";
import { getUserAdditionalInfo } from "../../../../../redux/ducks/user/actions";

export const ListProductCategories = ({ data }) => {
  const dispatch = useDispatch();
  const realms = useSelector((state) => state.user.realmNames);

  useEffect(() => {
    dispatch(getUserAdditionalInfo());
  }, [dispatch]);

  if (!realms.includes("CUSTOM PRODUCT CATEGORY")) {
    return (
      <AddFlexBox justifyContent={"center"}>
        {data &&
          data
            .filter((category) => category.products.length > 0)
            .map((item, index) => (
              <ProductCategoryItem key={index} data={item} />
            ))}
      </AddFlexBox>
    );
  }
  return (
    <AddFlexBox justifyContent={"center"}>
      {data &&
        data
          .filter((category) => category.products.length > 0)
          .map((item, index) => {
            if (realms.includes(item.name.toUpperCase())) {
              return <ProductCategoryItem key={index} data={item} />;
            } else {
              return <></>;
            }
          })}
    </AddFlexBox>
  );
};

ListProductCategories.propTypes = {
  data: any,
};
