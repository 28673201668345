import React, { Fragment, useState } from "react";

import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as Divider } from "../../../../../assets/line.svg";
import { ReactComponent as Line } from "../../../../../assets/short-line.svg";
import { RippleLink } from "../../../../../components";
import NewSuccessPage from "../../../../../components/success-page/new-success-page";
import {
  FlexContainer,
  FlexDiv,
} from "../../../../../containers/ScreenContainer";
import { colors } from "../../../../../styles";
import { formatPrice } from "../../../../../utils/currency/formatPriceWithComma";
import { months } from "../../../../../utils/date/months";

import { ReactComponent as CustomerLine } from "./assets/blank-line-customer.svg";
import { ReactComponent as Send } from "./assets/send.svg";

const PricesContainer = styled.div`
  width: calc(100% - 32px);
  max-width: 311px;
  margin: 0 auto;
  margin-top: ${({ top }) => top};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ padding }) => padding};
  background-color: rgba(237, 242, 247, 0.8);
  border-radius: 8px;
  min-height: ${({ height }) => height};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = styled.p`
  font-size: ${({ size }) => size || "12px"};
  margin: 0;
  margin-top: ${({ top }) => top};
  margin-bottom: ${({ bottom }) => bottom};
  font-weight: ${({ weight }) => weight || "500"};
  line-height: ${({ lineHeight }) => lineHeight || "18px"};
  color: ${({ color }) => color || colors.lightDark};
  text-transform: ${({ uppercase }) => uppercase && "uppercase"};
  text-align: ${({ align }) => align};
`;

const Amount = styled.p`
  font-size: ${({ size }) => size || "12px"};
  font-weight: 700;
  line-height: ${({ lineHeight }) => lineHeight || "18px"};
  color: ${({ color }) => color || colors.boldDark};
  margin: 0;
`;

const SaleSuccess = ({
  paymentInFull,
  data,
  cancel,
  customer,
  products,
  shopName,
  date,
  subTotal,
  discount,
  shopId,
  sale,
}) => {
  const [showMoreProducts, setShowMoreProducts] = useState(false);
  const history = useHistory();
  return (
    <NewSuccessPage
      headerText="Sales Record Successful"
      showCongratIcon={true}
      newBtnText="Record New Sale"
      newBtnLink={cancel}
      homeLink={() => history.push("/my-shop")}
    >
      {products.length > 0 && (
        <Fragment>
          <FlexContainer style={{ width: "calc(100% - 32px)" }}>
            <Text
              top="25px"
              weight="700"
              size="10px"
              lineHeight="16px"
              uppercase
            >
              Items Bought
            </Text>
          </FlexContainer>
          <PricesContainer padding="8px" top="8px" height="52px">
            <Text
              style={{ width: "100%" }}
              weight="400"
              color={colors.boldDark}
            >
              {products[0].name}
            </Text>
            <FlexContainer style={{ width: "100%" }}>
              <Text>{formatPrice(products[0].itemPrice)}</Text>
              <Text weight="400">x{products[0].quantity}</Text>
            </FlexContainer>
          </PricesContainer>
        </Fragment>
      )}
      {products.length > 1 && !showMoreProducts && (
        <Text
          onClick={() => setShowMoreProducts(true)}
          color={colors.boldDark}
          top="4px"
        >{`+ ${products.length - 1}  more product`}</Text>
      )}
      {showMoreProducts && (
        <Fragment>
          {products.slice(1).map((product, index) => (
            <PricesContainer key={index} padding="8px" top="8px" height="52px">
              <Text
                style={{ width: "100%" }}
                weight="400"
                color={colors.boldDark}
              >
                {product.name}
              </Text>
              <FlexContainer style={{ width: "100%" }}>
                <Text>{formatPrice(product.itemPrice)}</Text>
                <Text weight="400">x{product.quantity}</Text>
              </FlexContainer>
            </PricesContainer>
          ))}
          <Text
            onClick={() => setShowMoreProducts(false)}
            color={colors.boldDark}
            top="4px"
          >
            VIEW LESS
          </Text>
        </Fragment>
      )}

      <PricesContainer top="16px" padding="16px 16px 24px">
        <Column>
          <Amount color={colors.bgBlue} size="20px" lineHeight="30px">
            {formatPrice(subTotal)}
          </Amount>
          <Text
            bottom="13px"
            align="center"
            size="10px"
            lineHeight="16px"
            weight="700"
            uppercase
          >
            Total Sale
          </Text>
        </Column>
        {(!paymentInFull || discount > 0) && (
          <Fragment>
            <Divider />
            <FlexContainer style={{ width: "100%" }} top="13px">
              <Text>Subtotal</Text>
              <Amount>{formatPrice(subTotal)}</Amount>
            </FlexContainer>
          </Fragment>
        )}
        {discount > 0 && (
          <Fragment>
            <FlexContainer style={{ width: "100%" }} top="8px">
              <Text>Discount</Text>
              <Amount>({formatPrice(discount)})</Amount>
            </FlexContainer>
            <FlexContainer
              style={{ width: "100%", marginBottom: "17px" }}
              top="8px"
            >
              <Text>Total</Text>
              <Amount>{formatPrice(subTotal - discount)}</Amount>
            </FlexContainer>
          </Fragment>
        )}
        {!paymentInFull && (
          <Fragment>
            <FlexContainer style={{ width: "100%" }} top="8px">
              <Text>Paid</Text>
              <Amount>{formatPrice(data?.paymentAmount)}</Amount>
            </FlexContainer>
            <FlexContainer
              style={{ width: "100%", marginBottom: "17px" }}
              top="8px"
            >
              <Text>Balance</Text>
              {discount > 0 ? (
                <Amount color={colors.red}>
                  {formatPrice(subTotal - discount - data?.paymentAmount)}
                </Amount>
              ) : (
                <Amount color={colors.red}>
                  {formatPrice(data?.amountDue)}
                </Amount>
              )}
            </FlexContainer>
          </Fragment>
        )}
        <Divider />
        <FlexContainer style={{ width: "100%" }} top="10px">
          <Text weight="400">Customer</Text>
          <Text weight="400">Date</Text>
        </FlexContainer>
        <FlexContainer style={{ width: "100%" }} top="3px">
          {customer ? (
            <Text color={colors.boldDark}>{customer}</Text>
          ) : (
            <CustomerLine />
          )}
          <Text weight="400" color={colors.boldDark}>
            {`${months[date?.month - 1]} ${date?.day}, ${date?.year}`}
          </Text>
        </FlexContainer>
      </PricesContainer>
      <FlexDiv align="initial" style={{ margin: "19px 0 0" }}>
        <Line />
        <Text
          style={{ margin: "0 9px", maxWidth: "200px" }}
          align="center"
          size="10px"
          weight="400"
          lineHeight="16px"
          uppercase
        >
          {shopName}
        </Text>
        <Line />
      </FlexDiv>

      <RippleLink
        to={{
          pathname: "/actions/shop_sale_receipt",
          state: { sale: sale?.salesRecordViewDto, shopId },
        }}
      >
        <FlexDiv align="initial" style={{ margin: "19px 0 35px" }}>
          <Send style={{ marginRight: "8px" }} />
          <Text color="#051926" size="14px" weight="400" lineHeight="21px">
            Send Receipt
          </Text>
        </FlexDiv>
      </RippleLink>
    </NewSuccessPage>
  );
};

export default SaleSuccess;
