export const IS_ONLINE = "/actions/offline_is_online";
export const ADD_PENDING_REQUEST = "/actions/offline_add_pending_request";
export const REMOVE_PENDING_REQUEST = "/actions/offline_remove_pending_request";
export const REMOVE_ALL_PENDING_REQUESTS =
  "/actions/offline_remove_all_pending_requests";
export const UPDATE_PENDING_REQUEST = "/actions/offline_update_pending_request";
export const UPDATE_PENDING_SALE_INVENTORY_PRODUCT =
  "/actions/offline_update_sale_inventory_product";
export const UPDATE_PENDING_SALE_CUSTOMER_ID =
  "/actions/offline_update_sale_customer_id";
export const RESET_OFFLINE_STATUS = "/actions/offline_reset_offline_status";
