import { push } from "connected-react-router";
import { toast } from "react-toastify";

import { axios, currentAPI } from "../../../../../../config";
import { countriesMap } from "../../../../../../data/countries";
import { persistToken } from "../../../../../../utils/auth/persistToken";
import {
  insertCountryCode,
  insertZero,
} from "../../../../../../utils/inputs/formatPhoneNumber";
import {
  AUTH_PASSWORD_SETUP,
  AUTH_PROVIDE_BUSINESS,
} from "../../../../../../utils/mix-panel/constants";
import { mixPanel } from "../../../../../../utils/mix-panel/mixPanel";
import { createInactiveRaveWallet } from "../../../../account/wallet/actions/rave-wallet";
import {
  getShopFromMerchapp,
  getShops,
} from "../../../../applications/my-shop/actions/shop";
import {
  getUserAdditionalInfo,
  resetMerchappPassword,
  getUserAgentByReferralCode,
  getUserData,
  storeUserData,
  storeReferringUserData,
} from "../../../../user/actions";
import {
  ACTIVATE_USER_ACCOUNT,
  SET_USER_LOGIN_STATUS,
  SAVE_USER_ID,
} from "../../../../user/constants";
import {
  CREATING_ACCOUNT,
  CREATING_ACCOUNT_SUCCESS,
  CREATING_ACCOUNT_ERROR,
  SAVE_MERCHANT_BUSINESS_PROFILE,
} from "../constants";

export const createAccountV2 = (userData) => async (dispatch, getState) => {
  dispatch({ type: CREATING_ACCOUNT });
  const { msisdn, country } = userData;
  const countryCode = countriesMap.get(country).code;
  const initialPath = getState().auth.check.initialPath;
  const startTime = new Date();
  try {
    const payload = {
      ...userData,
      phoneNumber: insertZero(userData.phoneNumber),
      msisdn: insertCountryCode(msisdn, countryCode),
      businessPhoneNumber: insertCountryCode(
        userData.businessPhoneNumber,
        countryCode
      ),
      businessCategories: [],
      referralCode: userData.referralCode || null,
    };

    const signupResponse = await axios.post(
      `${currentAPI}/api/v2/completeSignup`,
      payload
    );

    if (signupResponse.status === 200) {
      const {
        agentUser,
        tokenData,
        userData: responseData,
      } = signupResponse.data || {};
      persistToken(tokenData);

      tokenData && (await dispatch(storeUserData(responseData)));
      agentUser && (await dispatch(storeReferringUserData(agentUser.data)));
      tokenData && (await dispatch(getShops()));

      mixPanel.track(AUTH_PASSWORD_SETUP, {
        Role: "Merchant",
        Lga: userData.lga,
        State: userData.state,
        referralCode: userData.referralCode,
        Time: Math.round((new Date() - startTime) / 1000),
      });

      dispatch({ type: ACTIVATE_USER_ACCOUNT });
      dispatch({ type: SET_USER_LOGIN_STATUS });
      dispatch({ type: CREATING_ACCOUNT_SUCCESS });
      dispatch(push(initialPath ? initialPath : "/"));
    }
  } catch (error) {
    dispatch({
      type: CREATING_ACCOUNT_ERROR,
      payload: error.message,
    });
    if (error.response && error.response.status === 400) {
      const { data } = (error && error.response) || {};
      data.message && toast.error(error.response.data.message);
    }
  }
};

export const createMerchantAccountV2 =
  (userData) => async (dispatch, getState) => {
    dispatch({ type: CREATING_ACCOUNT });

    const initialPath = getState().auth.check.initialPath;
    const startTime = new Date();

    try {
      const payload = {
        country: "South Africa",
        countryCode: "27",
        currency: "ZAR",
        role: "ROLE_USER",
        msisdn: insertCountryCode(userData.msisdn, "+27"),
        phoneNumber: userData.msisdn,
        email: userData.email,
        firstName: userData.firstName,
        lastName: userData.lastName,
        password: userData.password,
        referralCode: userData.referralNumber || null,
        lga: userData.lga,
        state: userData.state,
        streetAddress: userData.streetAddress,
      };

      const shopPayload = {
        shopName: userData.shopName,
        lga: userData.lga,
        countryState: userData.state,
        streetAddress: userData.streetAddress,
        businessCategories: [],
      };

      const signupResponse = await axios.post(
        `${currentAPI}/api/v2/completeSignup/new`,
        payload
      );

      if (signupResponse.status === 200) {
        const {
          id,
          agentUser,
          tokenData,
          userData: responseData,
        } = signupResponse.data || {};
        persistToken(tokenData);

        id && (await dispatch({ type: SAVE_USER_ID, payload: id }));
        tokenData && (await dispatch(storeUserData(responseData)));
        agentUser && (await dispatch(storeReferringUserData(agentUser.data)));
        tokenData && (await dispatch(getUserAdditionalInfo()));
        tokenData && (await dispatch(createShop(shopPayload)));
        tokenData && (await dispatch(getShops()));

        mixPanel.track(AUTH_PASSWORD_SETUP, {
          Role: "Merchant",
          Lga: userData.lga,
          State: userData.state,
          referralCode: userData.referralCode,
          Time: Math.round((new Date() - startTime) / 1000),
        });

        dispatch({ type: ACTIVATE_USER_ACCOUNT });
        dispatch({ type: SET_USER_LOGIN_STATUS });
        dispatch({ type: CREATING_ACCOUNT_SUCCESS });
        dispatch(push(initialPath ? initialPath : "/"));
        return true;
      }
    } catch (error) {
      dispatch({
        type: CREATING_ACCOUNT_ERROR,
        payload: error.message,
      });
      if (error.response && error.response.status === 400) {
        const { data } = (error && error.response) || {};
        data.message && toast.error(error.response.data.message);
      }
      return false;
    }
  };

export const createShop = (shopInfo) => async (dispatch, getState) => {
  try {
    const ShopDTO = { ...shopInfo };

    const createShopResponse = await axios.post(
      `${currentAPI}/api/shops`,
      JSON.stringify(ShopDTO)
    );

    if (createShopResponse.status === 200) {
      // dispatch({ type: SAVE_SHOP, payload: ShopDTO });
    }
  } catch (error) {
    //
  }
};

export const createAccount = (password) => async (dispatch, getState) => {
  dispatch({ type: CREATING_ACCOUNT });

  const phoneNumber = getState().user.msisdn;
  const userId = getState().user.userId;
  // const firstName    = getState().user.firstName;
  // const lastName     = getState().user.lastName;
  // const email        = getState().user.email;
  // const country      = getState().user.country;
  const referralCode = getState().user.referralCode;
  const isOnMerchApp = getState().user.isOnMerchApp;
  const initialPath = getState().auth.check.initialPath;
  const role = getState().user.role;
  const startTime = new Date();

  try {
    const responseSignIn = await axios.post(
      `${currentAPI}/api/authorize/${userId}`,
      {
        username: phoneNumber,
        password,
      }
    );

    if (responseSignIn.status === 200) {
      // await dispatch(
      //     sendUserInfoOnSignUp(
      //         userId,
      //         {
      //             email,
      //             firstName,
      //             lastName,
      //             country,
      //             referralCode: referralCode || ""
      //         }
      //     )
      // );

      const responseGenerateToken = await axios.post(
        `${currentAPI}/api/token/generate-token`,
        {
          password,
          username: phoneNumber,
        }
      );

      if (responseGenerateToken.status === 200) {
        const tokenData = responseGenerateToken.data;
        persistToken(tokenData);

        isOnMerchApp &&
          tokenData &&
          (await dispatch(
            resetMerchappPassword(phoneNumber, password, tokenData)
          ));
        isOnMerchApp && (await dispatch(getShopFromMerchapp(phoneNumber)));
        !isOnMerchApp &&
          (await dispatch(sendMerchantBusinessProfile(userId, password)));
        userId && (await dispatch(getUserAdditionalInfo()));
        tokenData && (await dispatch(createInactiveRaveWallet()));
        referralCode &&
          (await dispatch(getUserAgentByReferralCode(referralCode)));
        tokenData && (await dispatch(getUserData()));
        // referralCode  && await dispatch(sendReferralCodeForCommision(referralCode));

        mixPanel.track(AUTH_PASSWORD_SETUP, {
          Role: role === "ROLE_USER" ? "Merchant" : "Agent",
          Time: Math.round((new Date() - startTime) / 1000),
        });

        dispatch({ type: CREATING_ACCOUNT_SUCCESS });
        dispatch({ type: ACTIVATE_USER_ACCOUNT });
        dispatch({ type: SET_USER_LOGIN_STATUS });
        dispatch(push(initialPath ? initialPath : "/"));
      }
    }
  } catch (error) {
    dispatch({
      type: CREATING_ACCOUNT_ERROR,
      payload: error.message,
    });

    console.error(error);
  }
};

export const saveMerchantBusinessProfile = (businessProfile) => (dispatch) => {
  dispatch({ type: SAVE_MERCHANT_BUSINESS_PROFILE, payload: businessProfile });
  dispatch(push("/user/create_pin"));
};

export const sendMerchantBusinessProfile =
  (userId, password) => async (dispatch, getState) => {
    const businessProfile = getState().auth.signup.merchant.businessProfile;
    businessProfile.password = password;
    businessProfile.referralCode = getState().user.referralCode;

    try {
      const merchAppTelephoneResponse = await axios.put(
        `${currentAPI}/api/users/merchantBusinessData/${userId}`,
        JSON.stringify(businessProfile),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (merchAppTelephoneResponse.status === 200) {
        mixPanel.track(AUTH_PROVIDE_BUSINESS, {
          LGA: businessProfile.lga,
          State: businessProfile.state,
          "Business Categories": businessProfile.businessCategories,
        });
        return merchAppTelephoneResponse.data;
      }
    } catch (error) {
      console.error(error);
    }
  };

export const sendReferralCodeForCommision =
  (referralCode) => async (dispatch, getState) => {
    const generatedByUserID = getState().user.userId;

    try {
      await axios.put(
        `${currentAPI}/api/commissions/onboarding`,
        JSON.stringify({
          generatedByUserID,
          referralCode,
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

export const verifyShopName =
  (businessName, setErrors, type) => async (dispatch) => {
    if (!navigator.onLine) {
      toast.error("You can not perform this action offline...");
      return false;
    }

    dispatch({ type: CREATING_ACCOUNT });
    try {
      const verifyShopNameResponse = await axios.post(
        `${currentAPI}/api/merchantAppIntegration/public/verifyShop`,
        {
          businessName,
        }
      );

      const { code } = verifyShopNameResponse.data;

      if (code === 200) {
        dispatch({ type: CREATING_ACCOUNT_SUCCESS });
        if (type === "shopName") {
          setErrors({ shopName: "shop name already taken" });
        } else {
          setErrors({
            businessName: "Business name is taken, try another name",
          });
        }
        return false;
      } else if (code === 202) {
        dispatch({ type: CREATING_ACCOUNT_SUCCESS });
        return true;
      }
    } catch (error) {
      dispatch({ type: CREATING_ACCOUNT_SUCCESS });
      if (type === "shopName") {
        setErrors({ shopName: "shop name already taken" });
      } else {
        setErrors({ businessName: "Business name is taken, try another name" });
      }
      return false;
    }
  };

export const setUserRoleMerchant = (id) => async () => {
  try {
    await axios.put(`${currentAPI}/api/users/public/setUserMerchantRole/${id}`);
  } catch (error) {
    console.error(error);
  }
};
