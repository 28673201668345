import React, { useState } from "react";

import { useSelector } from "react-redux";
import styled, { keyframes } from "styled-components";

import { colors } from "../../../../styles";
import StarRating from "../../help/rate-spaces/components/StarRating";

import { ReactComponent as BackPic1 } from "./assets/backpic1.svg";
import { ReactComponent as BackPic2 } from "./assets/backpic2.svg";
import { ReactComponent as BackPic3 } from "./assets/backpic3.svg";
import TransactionRating from "./transaction-rating";

const breatheAnimation = keyframes`
 0% { bottom: -40px; }
 100% { bottom: 0; }
`;

const RatingContainer = styled.div`
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  background-color: white;
  width: 100%;
  max-width: inherit;
  margin: auto;
  z-index: 1500;
  transition: all 0.2s ease-in;
  animation-name: ${breatheAnimation};
  animation-duration: 2s;
  @media (min-width: 576px) {
    margin: auto;
    max-width: 375px;
    width: 100%;
  }
`;

const TopText = styled.div`
  font-weight: ${({ weight }) => weight || "700"};
  font-size: ${({ size }) => size || "16px"};
  margin-bottom: ${({ bottom }) => bottom || null};
  margin-top: ${({ top }) => top || null};
  color: ${({ color }) => color || colors.themeTextColor11};
  text-align: center;
`;

const StarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
`;

const TransparentDiv = styled.div`
  background-color: #0350bb;
  padding: 11px 16px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: ${colors.gray4};
`;

const BackEffect1 = styled.div`
  position: absolute;
  opacity: 0.4;
  top: ${({ top }) => top || null};
  left: ${({ left }) => left || null};
  right: ${({ right }) => right || null};
`;

const RatingPopup = ({ refId, setOpen }) => {
  const transactions = useSelector(
    (state) => state.account.transactions.transactionsList
  );
  const [rating, setRating] = useState(null);
  const [showValue, setShowValue] = useState("");
  const [openFullRating, setOpenFullRating] = useState(false);

  const ordinal = (n) => {
    var s = ["th", "st", "nd", "rd"];
    var v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  };

  return (
    <>
      <RatingContainer>
        <TransparentDiv>
          <div>
            You completed your{" "}
            <span style={{ fontWeight: "700", color: colors.gray4 }}>
              {ordinal(transactions.length)}{" "}
            </span>
            transaction!
          </div>
          <BackEffect1 top={"-8px"}>
            <BackPic1 />
          </BackEffect1>
          <BackEffect1 right={"90px"} top={"-8px"}>
            <BackPic2 />
          </BackEffect1>
          <BackEffect1 right={"0px"} top={"-8px"}>
            <BackPic3 />
          </BackEffect1>
        </TransparentDiv>
        <div style={{ padding: "26px 16px 29px 16px" }}>
          <TopText color={colors.themeTxtColor10} bottom={"5px"}>
            Rate your experience
          </TopText>
          <TopText weight={"400"} bottom={"32px"}>
            Let us know how we are doing
          </TopText>
          <StarContainer>
            <StarRating
              rating={rating}
              setRating={setRating}
              showValue={showValue}
              setShowValue={setShowValue}
              clickAction={() => setOpenFullRating(!openFullRating)}
            />
          </StarContainer>
          <TopText weight={"500"} top={"40px"} onClick={() => setOpen()}>
            Not now
          </TopText>
        </div>
      </RatingContainer>
      {openFullRating && (
        <TransactionRating
          refId={refId}
          rating={rating}
          setRating={setRating}
          showValue={showValue}
          setShowValue={setShowValue}
          setOpen={() => setOpenFullRating(!openFullRating)}
          cancel={setOpen}
        />
      )}
    </>
  );
};

export default RatingPopup;
