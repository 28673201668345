import React, { useState } from "react";

import Switch from "react-switch";
import styled from "styled-components";

import { RippleButton } from "../../../../../../../components";
import { SalesModal } from "../../../components/modal";

const SwitchTrigger = styled(Switch)`
  & > .react-switch-bg {
    & > div {
      & > svg {
        display: none;
      }
    }
  }
`;

const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
`;

const OptionTitle = styled.div`
  font-size: 14px;
  line-height: 21px;
  color: #212c3d;
`;

const BottomSection = styled.div`
  font-size: 14px;
  line-height: 21px;
  color: #212c3d;
  padding: 1em;
  margin-top: auto;
`;

const Button = styled(RippleButton)`
  height: 56px;
  border-radius: 10px;
  background: #227eff;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin-top: 0;
`;

const CheckBoxArea = styled.div`
  display: flex;
  color: #1e3448;
  font-size: 14px;
  justify-content: center;
`;

const Space = styled.div`
  height: 10px;
  padding-top: 30%;
`;

const CheckBox = styled.input.attrs({ type: "checkbox" })`
  display: inline-block;
  width: 25px;
  height: 25px;
  background: papayawhip;
  border-radius: 3px;
  -webkit-transition: all 150ms;
  transition: all 150ms;
  margin: 10px 15px;
`;

const ButtonWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0px;
  width: 100%;
  max-width: 375px;
  margin: auto;
  @media (max-width: 576px) {
    max-width: 100%;
  }
`;

const SalesOptionPage = ({
  setOpenAddSelectOptions,
  salesOptions,
  setSalesOptions,
  setSelectedProducts,
  open,
  cancel,
  setSelectedCustomer,
  setFieldValue,
}) => {
  const [addCustomer, setAddCustomer] = useState(salesOptions.addCustomer);
  const [addItemSold, setAddItemSold] = useState(salesOptions.addItemSold);
  const [addDiscount, setAddDiscount] = useState(salesOptions.addDiscount);

  const [addNotes, setAddNotes] = useState(salesOptions.addNotes);
  const [checked, setChecked] = useState(true);

  function saveOption() {
    const data = {
      addCustomer,
      addItemSold,
      addDiscount,
      addNotes,
    };
    if (checked) {
      localStorage.setItem("salesOptions", JSON.stringify(data));
    }
    setSalesOptions(data);
    setOpenAddSelectOptions((prev) => !prev);
    setSelectedProducts([]);
    setSelectedCustomer(undefined);
    setFieldValue("notes", "");
    setFieldValue("customerName", "");
    setFieldValue("discount", 0);
    setFieldValue("amountDue", "");
    setFieldValue("paymentAmount", 0);
  }

  return (
    <SalesModal height="90vh" open={open} title="Sales Options" cancel={cancel}>
      <OptionContainer>
        <OptionTitle>Add Customer</OptionTitle>
        <SwitchTrigger
          onChange={() => setAddCustomer(!addCustomer)}
          checked={addCustomer}
          onColor={"#227EFF"}
          height={21}
          width={43}
          offColor={"#BBC0C9"}
        />
      </OptionContainer>
      <OptionContainer>
        <OptionTitle>Items Sold</OptionTitle>
        <SwitchTrigger
          onChange={() => setAddItemSold(!addItemSold)}
          checked={addItemSold}
          onColor={"#227EFF"}
          height={21}
          width={43}
          offColor={"#BBC0C9"}
        />
      </OptionContainer>
      <OptionContainer>
        <OptionTitle>Discount Offer</OptionTitle>
        <SwitchTrigger
          onChange={() => setAddDiscount(!addDiscount)}
          checked={addDiscount}
          onColor={"#227EFF"}
          height={21}
          width={43}
          offColor={"#BBC0C9"}
        />
      </OptionContainer>

      <OptionContainer>
        <OptionTitle>Notes</OptionTitle>
        <SwitchTrigger
          onChange={() => setAddNotes(!addNotes)}
          checked={addNotes}
          onColor={"#227EFF"}
          height={21}
          width={43}
          offColor={"#BBC0C9"}
        />
      </OptionContainer>
      <Space />
      <ButtonWrapper>
        <BottomSection>
          <CheckBoxArea>
            <>
              <CheckBox
                defaultChecked={checked}
                onChange={() => setChecked(!checked)}
              />
              <p>Remember this selection for All sale</p>
            </>
          </CheckBoxArea>
          <Button type="button" onClick={saveOption}>
            Save
          </Button>
        </BottomSection>
      </ButtonWrapper>
    </SalesModal>
  );
};

export default SalesOptionPage;
