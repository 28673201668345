import React from "react";

import { shape, string, bool } from "prop-types";
import { useDispatch } from "react-redux";

import { RippleButton } from "../../../../../../../../components";
import { Overlay } from "../../../../../../../../containers/OverlayContainer";
import { addCardWith3DSecure } from "../../../../../../../../redux/ducks/account/wallet/actions/cards/3d-secure";

const OTP3DSecureModal = ({ debitCard, otpModalOpen }) => {
  const dispatch = useDispatch();

  return (
    otpModalOpen && (
      <Overlay opacity={"100%"}>
        <iframe
          title={"otpFrame"}
          width={"100%"}
          height={"70%"}
          id={"frame"}
        ></iframe>
        <RippleButton onClick={() => dispatch(addCardWith3DSecure(debitCard))}>
          Confirm card with 3D Secure
        </RippleButton>
      </Overlay>
    )
  );
};

OTP3DSecureModal.propTypes = {
  otpModalOpen: bool,
  debitCard: shape({ cardNumber: string, expirationDate: string, cvv: string }),
};

export default OTP3DSecureModal;
