import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as BlueHomeICon } from "../../assets/home-blue.svg";
import { ReactComponent as GreyHomeIcon } from "../../assets/home-grey.svg";
import { ReactComponent as BlueOrderIcon } from "../../assets/order-blue.svg";
import { ReactComponent as OrderIcon } from "../../assets/orders.svg";
import { ReactComponent as Cart } from "../../assets/shoppingCartGrey.svg";
import { ReactComponent as UserIcon } from "../../assets/user.svg";
import { ReactComponent as UserUnselectIcon } from "../../assets/user2.svg";

const FooterContainer = styled.div`
  background: #ffffff;
  width: 100%;
  min-width: 250px;
  padding: 9px 24px;
  height: 50px;
  position: fixed;
  max-width: 375px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  z-index: 50;
`;
const SpanContainer = styled.div`
  cursor: pointer;
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FooterText = styled.span`
  font-size: 12px;
  color: ${({ chosenOption }) => (chosenOption ? "#579fd7" : "#abbbc8")};
`;

export const MerchbuyFooter = () => {
  const [chosenOption, setChosenOption] = useState("");
  const history = useHistory();
  useEffect(() => {
    let {
      location: { pathname },
    } = history || {};

    if (pathname.lastIndexOf("/") + 1 === pathname.length) {
      pathname = pathname.slice(0, pathname.length - 1);
    }
    let chosenPage = pathname.slice(pathname.lastIndexOf("/") + 1);
    if (chosenPage.indexOf("/")) {
      chosenPage.slice(chosenPage.indexOf("/"));
    }
    setChosenOption(chosenPage);
  }, [history]);

  return (
    <FooterContainer>
      <SpanContainer
        onClick={() => {
          setChosenOption("merchbuy");
          history.push("/actions/merchbuy");
        }}
      >
        {chosenOption === "merchbuy" ? <BlueHomeICon /> : <GreyHomeIcon />}
        <FooterText chosenOption={chosenOption === "merchbuy"}>Home</FooterText>
      </SpanContainer>
      <SpanContainer
        onClick={() => {
          setChosenOption("cart");
          history.push("/actions/merchbuy/cart");
        }}
      >
        <Cart fill={chosenOption === "cart" ? "#579fd7" : "#B8BFC5"} />
        <FooterText chosenOption={chosenOption === "cart"}>Cart</FooterText>
      </SpanContainer>
      <SpanContainer
        onClick={() => {
          setChosenOption("orders");
          history.push("/actions/merchbuy/order-history");
        }}
      >
        {chosenOption === "order-history" ? <BlueOrderIcon /> : <OrderIcon />}
        <FooterText chosenOption={chosenOption === "order-history"}>
          My Orders
        </FooterText>
      </SpanContainer>
      <SpanContainer
        onClick={() => {
          setChosenOption("account");
          history.push("/actions/merchbuy/account");
        }}
      >
        {chosenOption === "account" ? <UserIcon /> : <UserUnselectIcon />}
        <FooterText chosenOption={chosenOption === "account"}>
          My Account
        </FooterText>
      </SpanContainer>
    </FooterContainer>
  );
};
