import React from "react";

const ProgressBar = (props) => {
  const { bgcolor, completed, transition } = props;

  const containerStyles = {
    height: 4,
    width: "100%",
    backgroundColor: "#D2D6DB",
    borderRadius: 8,
  };

  const fillerStyles = {
    height: "100%",
    width: `${completed}%`,
    backgroundColor: bgcolor,
    borderRadius: "inherit",
    textAlign: "right",
    transition: transition,
  };

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}></div>
    </div>
  );
};

export default ProgressBar;
