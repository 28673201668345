import React, { Fragment } from "react";

import styled from "styled-components";

import { LightButton } from "../../../components";
import { BlurBackground } from "../../../containers/BlurBackground";
import { colors } from "../../../styles";

import { ReactComponent as ActivateIcon } from "./assets/umbrella.svg";

const Container = styled.div`
  height: 364px;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  background-color: ${colors.white};
  border-radius: 15px 21px 0 0;
  padding: 42px 24px 40px;
`;

const Text = styled.h3`
  color: ${colors.cobalt};
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  margin: 24px 0 8px;
`;

const Description = styled.h5`
  color: ${colors.themeTextColor11};
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const BlurPopUpBackground = styled(BlurBackground)`
  bottom: 0;
  position: fixed;
  z-index: 9999;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 375px;

  @media (max-width: 576px) {
    width: 100%;
  }
`;

export const FeatureUndergoingFixes = ({ cancel }) => {
  return (
    <Fragment>
      <BlurPopUpBackground onClick={cancel}>
        <Container>
          <IconWrapper>
            <ActivateIcon />
          </IconWrapper>
          <Text>Temporarily Unavailable</Text>
          <Description>
            We are currently working on creating a better experience for you
          </Description>
          <LightButton
            onClick={cancel}
            size="12px"
            weight="500"
            color={colors.deepBlue}
            background={colors.white}
            margin={"32px 0 0"}
            height={"32px"}
            borderColor={colors.deepBlue}
          >
            Check Later
          </LightButton>
        </Container>
      </BlurPopUpBackground>
    </Fragment>
  );
};
