import { USER_LOGOUT } from "../../user/constants";

import {
  LOADING_AGENCY_BANKING_DATA,
  AGENCY_BANKING_REQUEST_LOADING,
  AGENCY_BANKING_SIGNUP,
  SAVE_AGENCY_BANKING_IDCARD_IMAGES,
  SAVE_AGENCY_BANKING_UTILITY_IMAGES,
  SAVE_AGENCY_BANKING_PASSPORT_IMAGES,
} from "./constants";

const initialState = {
  isLoading: false,
  agencyBankDetails: {},
  idCard: undefined,
  utilityBill: undefined,
  passportPhoto: undefined,
};

export default function agencyBankingReducer(state = initialState, action) {
  switch (action.type) {
    case LOADING_AGENCY_BANKING_DATA: {
      if (
        typeof state.agencyBankDetails === "object" &&
        Object.keys(state.agencyBankDetails).length
      ) {
        return {
          ...state,
          isLoading: false,
        };
      } else {
        return {
          ...state,
          isLoading: true,
        };
      }
    }

    case AGENCY_BANKING_REQUEST_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }

    case SAVE_AGENCY_BANKING_IDCARD_IMAGES: {
      return {
        ...state,
        idCard: action.payload,
      };
    }

    case SAVE_AGENCY_BANKING_UTILITY_IMAGES: {
      return {
        ...state,
        utilityBill: action.payload,
      };
    }

    case SAVE_AGENCY_BANKING_PASSPORT_IMAGES: {
      return {
        ...state,
        passportPhoto: action.payload,
      };
    }

    case AGENCY_BANKING_SIGNUP: {
      return {
        ...state,
        agencyBankDetails: action.payload,
      };
    }

    case USER_LOGOUT: {
      return {
        isLoading: false,
        agencyBankDetails: {},
        idCard: undefined,
        utilityBill: undefined,
        passportPhoto: undefined,
      };
    }

    default: {
      return state;
    }
  }
}
