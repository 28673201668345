import React, { Fragment, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import { ReactComponent as ForwardIcon } from "../../../../assets/arrow.svg";
import {
  TopHeader,
  RippleLink,
  PageLogo,
  Loader,
} from "../../../../components";
import {
  List,
  ListItem,
  ListLeftBlock,
  ListHeading,
  ListHighlight,
} from "../../../../containers/ListContainer";
import { Message } from "../../../../containers/MessageContainer";
import {
  ScreenContainer,
  ViewContainer,
} from "../../../../containers/ScreenContainer";
import { getCabelProviders } from "../../../../redux/ducks/applications/bill-payments/actions/entertainment";

const SelectBlock = styled(ListHighlight)`
  margin: auto 0 auto 16px;
`;

const SelectProvider = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(
    (state) => state.applications.billPayments.isLoading
  );
  const availablePackages = useSelector(
    (state) => state.applications.billPayments.allProviders
  );

  useEffect(() => {
    dispatch(getCabelProviders());
  }, [dispatch]);

  return isLoading ? (
    <Loader />
  ) : (
    <Fragment>
      <TopHeader title={"Entertainment"} />
      <ScreenContainer>
        <ViewContainer>
          <Message bottom={"24px"}>Select an entertainment provider</Message>
          <List fullScreen>
            {availablePackages &&
              availablePackages.length &&
              availablePackages.map((provider, index) => (
                <RippleLink
                  key={index}
                  to={{
                    pathname: "/actions/entertainment_pay",
                    state: provider,
                  }}
                >
                  <ListItem style={{ alignItems: "center" }} bottom={"8px"}>
                    <PageLogo
                      Icon={provider.logoUrl}
                      width={"32px"}
                      height={"32px"}
                      iconWidth={"32px"}
                      iconHeight={"32px"}
                    />
                    <ListLeftBlock>
                      <ListHeading>{provider.name}</ListHeading>
                    </ListLeftBlock>
                    <SelectBlock>
                      <ForwardIcon />
                    </SelectBlock>
                  </ListItem>
                </RippleLink>
              ))}
          </List>
        </ViewContainer>
      </ScreenContainer>
    </Fragment>
  );
};

export default SelectProvider;
