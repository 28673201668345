import React, { Fragment, useState } from "react";

import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { SuccessPage } from "../../../../../components";
import DesktopBackgroundLayout from "../../../../DesktopBackgroundLayout";
import CustomerDetails from "../customer-details";
const AddCustomerSuccess = () => {
  const customers = useSelector((state) => state.applications.myShop.customers);
  const [customerDetailsOpen, setCustomerDetailsOpen] = useState(false);
  const history = useHistory();

  function handleClick() {
    history.push("/actions/shop_customers_add");
  }

  function handleDone() {
    setCustomerDetailsOpen(!customerDetailsOpen);
  }
  return (
    <Fragment>
      <DesktopBackgroundLayout>
        {!customerDetailsOpen && (
          <SuccessPage
            title={"Contact created"}
            subtitle={"Your customer has been successfully created"}
            doneText={"Create another customer"}
            detailText={"View customer's details"}
            showDetailButton
            onDoneClick={handleClick}
            onDetailClick={handleDone}
          />
        )}
        {customerDetailsOpen && (
          <CustomerDetails
            customer={customers[0]}
            open={customerDetailsOpen}
            setOpen={setCustomerDetailsOpen}
          />
        )}
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default AddCustomerSuccess;
