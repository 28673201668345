import * as Yup from "yup";

import { containsLettersRegExp } from "../../../../utils/regexp/containsLettersRegExp";

export const SingleRemitValidationSchema = Yup.object().shape({
  amount: Yup.string()
    .matches(containsLettersRegExp, "Amount cannot contain letters")
    .required("Required")
    .test("check last character", "Invalid Amount", function (value) {
      return !value?.endsWith(".");
    }),
  receiptBase64String: Yup.string().required(
    "Please upload a proof of payment"
  ),
  bankAccount: Yup.string().required(
    "Please indicate the bank account paid into"
  ),
  transactionReference: Yup.string().required(
    "Please enter a transaction reference"
  ),
});
