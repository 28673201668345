import React, { Fragment, useCallback, useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import { useHistory } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styled from "styled-components";

import {
  RippleLink as Link,
  DeliveryLocationPopup,
} from "../../../../components/";
import { SearchInput } from "../../../../components/forms/input/search-input/SearchInput";
import { BlurBackground } from "../../../../containers/BlurBackground";
import { ScreenContainer } from "../../../../containers/ScreenContainer";
import { bannerInteraction } from "../../../../redux/ducks/applications/banners/actions";
import { merchbuyActions } from "../../../../redux/ducks/applications/merchbuy/actions";
import { RippleButton } from "../../../../components";
import { colors } from "../../../../styles";
import {
  MODULE_INTERACTION,
  MERCHBUY_INTERACTION,
} from "../../../../utils/mix-panel/constants";
import { mixPanel } from "../../../../utils/mix-panel/mixPanel";
import DesktopBackgroundLayout from "../../../DesktopBackgroundLayout";
import Navigation from "../../../home/navigation";
import PendingActivation from "../../../home/pending-activation";
import { ReactComponent as BuySvg } from "../assets/buy.svg";
import { ReactComponent as LocationSvg } from "../assets/location.svg";
import { ReactComponent as ShoppingLocationSvg } from "../assets/map-location.svg";
import { ReactComponent as MapSvg } from "../assets/map.svg";
import { ReactComponent as MyOrderIcon } from "../assets/my_order.svg";
import { ReactComponent as RightArrowSvg } from "../assets/right-arrow.svg";
import { ReactComponent as ShoppingOptionSvg } from "../assets/shopping-options.svg";
import { TopNavIconsMenu, ListProducts, ListProductsFlex } from "../components";
import EducateSearchUser from "../search-educate";
import {
  PageSubSections,
  PageSubSectionsTop,
  PageSubSectionsTopLeft,
  Space,
  Container,
  LinkBox,
} from "../styles";

import SkeletonCard from "./skeleton";

export const PageTitle = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: ${colors.themeTextColor1};
`;

const RippleLink = styled(Link)`
  font-size: 12px;
  font-weight: 700;
  color: ${colors.deepBlue};
`;

const ProductCategoryImage = styled.img`
  width: 100%;
  height: 95px;
  margin-bottom: 4px;
  border-radius: 4px;
  object-fit: cover;
  padding: 0;
`;

const ProductTitleHeader = styled.h4`
  font-weight: 500;
  font-size: 12px;
  color: ${colors.subGrey};
`;

const PageSection = styled(PageSubSections)`
  padding: 20px 0 0;
  cursor: pointer;
`;

const ShoppingOptionTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
  text-align: center;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
  align-items: center;
`;

const InputWrapper = styled.div`
  width: 70%;
  margin-right: 10px;
`;

const BuyIcon = styled(BuySvg)`
  width: 32px;
  height: 35px;
`;

const CartCount = styled.div`
  width: 13px;
  height: 13px;
  background-color: #ee6f44;
  border-radius: 26px;
  position: absolute;
  margin-top: -43px;
  margin-left: 19px;
  text-align: center;
  font-size: 8px;
  font-weight: bold;
  color: ${colors.white};
  padding-top: 2px;
`;

const ShoppingOptionWrapper = styled.div`
  height: calc(260px + 66px);
  width: 100%;
  background-color: white;
  position: fixed;
  bottom: 0;
  padding: 24px 0;
  border-radius: 16px 16px 0 0;
  @media (max-width: 576px) {
    height: auto;
  }
`;

const ShoppingOptionItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 16px;
`;

const ItemsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px 0;
`;

const ItemText = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  color: #071827;
`;

const EmptyProductsContainer = styled.div`
  width: 343px;
  height: 333px;
  border: 1px solid #edf2f7;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const EmptyProductTitle = styled.h4`
  color: #0350bb;
  font-size: 20px;
  font-weight: 700;
`;

const EmptyProductDesc = styled.p`
  color: #5c6f7f;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  align-items: center;
  line-height: 21px;
`;

const MerchbuyIndex = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const role = useSelector((state) => state.user.role);
  const agentState = useSelector((state) =>
    state.user.agentState ? state.user.agentState.toUpperCase() : "PENDING"
  );
  const allBanners = useSelector(
    (state) => state.applications.banners.allBanners
  );
  const merchbuySearchHistoryProducts = useSelector(
    (state) => state.applications.merchbuy.merchbuySearchHistoryProducts
  );
  const user = useSelector((state) => state.user);

  const userEducations = (user && user.userEducations) || [];
  const hasCompletedSearchEdu = userEducations.includes("Search Education");

  const merchbuyAllProducts = useSelector(
    (state) => state.applications.merchbuy.merchbuyAllProducts
  );
  const merchbuyCartCategory = useSelector(
    (state) => state.applications.merchbuy.merchbuyCartCategory
  );
  const merchbuyCategoryBanners = useSelector(
    (state) => state.applications.merchbuy.merchbuyCategoryBanners
  );
  const [rows, setRow] = useState([]);
  const cartItems = useSelector(
    (state) => state.applications.merchbuy.shopCart
  );
  const [deliveryLocation, setDeliveryLocation] = useState(
    JSON.parse(localStorage.getItem("deliveryLocation"))
  );
  const [showPendingActivationPage, setShowPendingActivationPage] =
    useState(false);

  const [openDeliverLocationPopup, setOpenDeliverLocationPopup] = useState(
    deliveryLocation === null
  );
  const [topBanner, setTopBanner] = useState([]);
  const [bannerName] = useState("MERCHBUY_TOP_LANDING_PAGE");
  const [showShoppingOption, setShowShoppingOption] = useState(false);
  const [shoppingOptions, setShoppingOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const { products: searchHistoryProducts, searchTerms = 0 } =
    merchbuySearchHistoryProducts || {};

  const getProducts = useCallback(() => {
    const items = [];
    const nums = merchbuyAllProducts?.length / 9;
    let from = 0;
    let to = 9;
    let productCategoryFrom = 0;
    let productCategoryTo = 1;
    let searchTerm = "";
    for (let id = 0; id < nums; id++) {
      searchTerm = searchTerms
        ? searchTerms[Math.floor(Math.random() * searchTerms?.length)]
        : searchTerm;
      items.push({
        id,
        from,
        to,
        productCategoryFrom,
        productCategoryTo,
        searchTerm,
      });
      from += 9;
      to += 9;
      productCategoryFrom += 1;
      productCategoryTo += 1;
    }
    setRow(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchbuyAllProducts]);

  useEffect(() => {
    getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProducts]);

  useEffect(() => {
    const options = [
      {
        id: 1,
        title: "My Orders",
        icon: MyOrderIcon,
        onClick: () => {
          mixPanel.track(MERCHBUY_INTERACTION, {
            "Icon Name": "my orders",
            Time: new Date().toLocaleString(),
          });
          history.push("/actions/merchbuy/order-history");
        },
      },
      {
        id: 2,
        title: "Shopping location",
        icon: ShoppingLocationSvg,
        onClick: () => {
          mixPanel.track(MERCHBUY_INTERACTION, {
            "Icon Name": "shipping location",
            Time: new Date().toLocaleString(),
          });
          history.push("/actions/merchbuy/shopping-location");
        },
        value: deliveryLocation && deliveryLocation.state,
      },
    ];

    if (role !== "ROLE_AGENT") {
      options.push({
        id: 3,
        title: "Delivery address",
        icon: MapSvg,
        onClick: () => history.push("/actions/merchbuy/delivery-address"),
      });
    }

    setShoppingOptions(options);
  }, [deliveryLocation, history, role]);

  const getLocation = (data) => {
    if (data.state) {
      setDeliveryLocation(data);
      setOpenDeliverLocationPopup(!openDeliverLocationPopup);
    }
  };

  useEffect(() => {
    mixPanel.track(MODULE_INTERACTION, {
      "Icon Name": "MerchBuy",
      Time: new Date().toLocaleString(),
    });
  }, []);

  useEffect(() => {
    if (allBanners && allBanners.length > 0) {
      setTopBanner(
        allBanners.filter(
          (x) =>
            x.bannerConfig?.bannerName === bannerName &&
            (x?.states || []).includes(deliveryLocation?.state)
        )
      );
    }
  }, [allBanners, bannerName, deliveryLocation]);

  useEffect(() => {
    if (role === "ROLE_AGENT" && agentState !== "APPROVED") {
      setShowPendingActivationPage(true);
    }
  }, [agentState, role]);

  useEffect(() => {
    window.addEventListener("storage", () => {
      setDeliveryLocation(JSON.parse(localStorage.getItem("deliveryLocation")));
    });
    const getProducts = async () => {
      if (deliveryLocation !== null) {
        const promise = await Promise.allSettled([
          dispatch(merchbuyActions.getProductCategories()),
          dispatch(
            merchbuyActions.getMerchbuyLandingPageProducts(
              "DEALS",
              deliveryLocation.state
            )
          ),
          dispatch(
            merchbuyActions.getMerchbuyLandingPageProducts(
              "SEARCH_HISTORY",
              deliveryLocation.state
            )
          ),
          dispatch(
            merchbuyActions.getMerchbuyLandingPageProducts(
              "CATEGORY_BANNER",
              deliveryLocation.state
            )
          ),
          dispatch(
            merchbuyActions.getMerchbuyLandingPageProducts(
              "ALL_PRODUCT",
              deliveryLocation.state
            )
          ),
          dispatch(
            merchbuyActions.getMerchbuyLandingPageProducts(
              "CART_CATEGORY",
              deliveryLocation.state
            )
          ),
        ]);
        promise && setLoading(false);
      }
    };
    getProducts();
    dispatch(merchbuyActions.getAllShops(deliveryLocation));
    dispatch(merchbuyActions.getRecommendedProducts(100, deliveryLocation));
    dispatch(merchbuyActions.getPopularProducts(12, deliveryLocation));
    dispatch(merchbuyActions.getShopCart());
  }, [dispatch, deliveryLocation]);

  const onImageClick = (imageId, interactiveLink) => {
    dispatch(bannerInteraction(bannerName, imageId));
    window.location.href = `${interactiveLink}`;
  };

  const handleClose = () => {
    setTimeout(() => {
      setShowShoppingOption(false);
    }, 1000);
  };

  return (
    <DesktopBackgroundLayout>
      {showPendingActivationPage ? (
        <PendingActivation pageTitle="MerchBuy" />
      ) : (
        <Fragment onClick={handleClose}>
          {showShoppingOption && (
            <BlurBackground position="fixed" onClick={handleClose}>
              <ShoppingOptionWrapper>
                <ShoppingOptionTitle>Shopping Options</ShoppingOptionTitle>
                {shoppingOptions.map((shoppingOption) => (
                  <ShoppingOptionItemWrapper
                    onClick={shoppingOption.onClick}
                    key={shoppingOption.id}
                  >
                    <ItemsContainer>
                      <shoppingOption.icon />
                      <ItemText>{shoppingOption.title}</ItemText>
                    </ItemsContainer>
                    <ItemsContainer>
                      <ItemText>{shoppingOption.value}</ItemText>
                      <RightArrowSvg />
                    </ItemsContainer>
                  </ShoppingOptionItemWrapper>
                ))}
              </ShoppingOptionWrapper>
            </BlurBackground>
          )}

          {!hasCompletedSearchEdu && <EducateSearchUser />}
          <ScreenContainer top={"0px"}>
            <HeaderWrapper>
              <InputWrapper>
                <SearchInput
                  onClick={() => history.push("/actions/merchbuy/search")}
                  placeholder="Search for a product"
                />
              </InputWrapper>
              <div
                onClick={() => {
                  mixPanel.track(MERCHBUY_INTERACTION, {
                    "Icon Name": "cart",
                    Time: new Date().toLocaleString(),
                  });
                  history.push("/actions/merchbuy/cart");
                }}
              >
                <BuyIcon />
                <CartCount>{cartItems ? cartItems.length : 0}</CartCount>
              </div>
              <div>
                <ShoppingOptionSvg
                  onClick={() => setShowShoppingOption(true)}
                />
              </div>
            </HeaderWrapper>
            <Container width="100%">
              {topBanner && (
                <Carousel showThumbs={false} autoPlay infiniteLoop>
                  {topBanner.map((banner) => {
                    const { imageId, interactiveLink, imageUrl } = banner;
                    return (
                      <div
                        onClick={() => {
                          onImageClick(imageId, interactiveLink);
                          mixPanel.track(MERCHBUY_INTERACTION, {
                            "Icon Name": "slider",
                            Time: new Date().toLocaleString(),
                          });
                        }}
                        key={imageId}
                      >
                        <img src={imageUrl} alt="" />
                      </div>
                    );
                  })}
                </Carousel>
              )}
            </Container>
            <Space height="20px" />
            {loading ? (
              <SkeletonCard />
            ) : rows.length > 0 ? (
              rows.map((item) => {
                const {
                  id,
                  from,
                  to,
                  productCategoryFrom,
                  productCategoryTo,
                  searchTerm,
                } = item;
                return (
                  <Fragment key={id}>
                    <TopNavIconsMenu />
                    {searchHistoryProducts &&
                      searchHistoryProducts.slice(from, to).length > 0 && (
                        <PageSection>
                          <PageSubSectionsTop padding="0">
                            <PageSubSectionsTopLeft>
                              <ProductTitleHeader>
                                Based on your search
                              </ProductTitleHeader>
                            </PageSubSectionsTopLeft>
                            <LinkBox>
                              <RippleLink
                                to={{
                                  pathname: "/actions/merchbuy/search",
                                  state: { searchTerm },
                                }}
                              >
                                See All
                              </RippleLink>
                            </LinkBox>
                          </PageSubSectionsTop>
                          <ListProducts
                            data={searchHistoryProducts.slice(from, to)}
                            size={{ width: "105px", height: "98px" }}
                          />
                        </PageSection>
                      )}
                    {merchbuyCategoryBanners &&
                      merchbuyCategoryBanners
                        .slice(productCategoryFrom, productCategoryTo)
                        .map((x) => (
                          <PageSection
                            key={x.id}
                            onClick={() =>
                              history.push(
                                `/actions/merchbuy/products/${x.name}/${x.id}`
                              )
                            }
                          >
                            <ProductCategoryImage src={x.imageUrl} />
                          </PageSection>
                        ))}
                    {merchbuyAllProducts &&
                      merchbuyAllProducts.slice(from, to).length > 0 && (
                        <PageSection>
                          <PageSubSectionsTop padding="0">
                            <PageSubSectionsTopLeft>
                              <ProductTitleHeader>
                                More for you
                              </ProductTitleHeader>
                            </PageSubSectionsTopLeft>
                            <LinkBox>
                              <RippleLink
                                to={"/actions/merchbuy/popular-products"}
                              >
                                See All
                              </RippleLink>
                            </LinkBox>
                          </PageSubSectionsTop>
                          <ListProducts
                            data={merchbuyAllProducts.slice(from, to)}
                            size={{ width: "105px", height: "98px" }}
                          />
                        </PageSection>
                      )}
                    {merchbuyCartCategory &&
                      merchbuyCartCategory.slice(from, to).length > 0 && (
                        <PageSection>
                          <PageSubSectionsTop padding="0">
                            <PageSubSectionsTopLeft>
                              <ProductTitleHeader>
                                Recommended for you
                              </ProductTitleHeader>
                            </PageSubSectionsTopLeft>
                            <LinkBox>
                              <RippleLink
                                to={"/actions/merchbuy/popular-products"}
                              >
                                See All
                              </RippleLink>
                            </LinkBox>
                          </PageSubSectionsTop>
                          <ListProductsFlex
                            data={merchbuyCartCategory.slice(from, to)}
                            size={{ width: "105px", height: "98px" }}
                          />
                        </PageSection>
                      )}
                  </Fragment>
                );
              })
            ) : (
              <div>
                <EmptyProductsContainer>
                  <LocationSvg />
                  <EmptyProductTitle>We are almost here</EmptyProductTitle>
                  <EmptyProductDesc>
                    Sorry, we are yet to deliver to the location you selected.
                    Please choose a different shopping location
                  </EmptyProductDesc>
                </EmptyProductsContainer>
                <RippleButton
                  onClick={() =>
                    history.push("/actions/merchbuy/shopping-location")
                  }
                >
                  Choose another Location
                </RippleButton>
              </div>
            )}
            <Space height={"50px"} />
            {openDeliverLocationPopup && (
              <DeliveryLocationPopup
                open={openDeliverLocationPopup}
                getLocation={getLocation}
                cancel={() => {
                  setOpenDeliverLocationPopup(!openDeliverLocationPopup);
                }}
              />
            )}
          </ScreenContainer>
        </Fragment>
      )}
      <Navigation />
    </DesktopBackgroundLayout>
  );
};

export default MerchbuyIndex;
