import React from "react";

import {
  CreatePaymentLink,
  PaymentLinks,
  PayWithPaymentLink,
  AllPaymentLink,
  PaymentDetail,
} from "../../views/actions/payment-links";

export const paymentLinkProtectedRoutes = [
  {
    path: "/actions/payment-links",
    exact: true,
    main: () => <PaymentLinks />,
    public: false,
  },
  {
    path: "/actions/payment-links/details",
    exact: true,
    main: () => <PaymentDetail />,
    public: false,
  },
  {
    path: "/actions/payment-links/transactions",
    exact: true,
    main: () => <AllPaymentLink />,
    public: false,
  },
  {
    path: "/actions/payment-links/create",
    exact: true,
    main: () => <CreatePaymentLink />,
    public: false,
  },
];

export const paymentLinkOpenRoutes = [
  {
    path: "/actions/payment-links/pay",
    component: PayWithPaymentLink,
    exact: true,
  },
];
