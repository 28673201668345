import React, { Fragment, useState, useEffect } from "react";

import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import {
  Loader,
  TopHeader,
  PasswordGroup,
  ResendCodeTimer,
} from "../../../components";
import { Message, Title } from "../../../containers/MessageContainer";
import { ScreenContainer } from "../../../containers/ScreenContainer";
import {
  sendDeviceVerificationOtpToWhatsApp,
  sendDeviceVerificationOtp,
  verifyDeviceOtp,
} from "../../../redux/ducks/auth/check/actions";
import { tick, setExpired } from "../../../redux/ducks/timer/actions";
import { colors } from "../../../styles";
import { getInputValues } from "../../../utils/inputs/getInputValues";
import DesktopBackgroundLayout from "../../DesktopBackgroundLayout";

import { ReactComponent as NewDeviceAvater } from "./assets/new_device_logo.svg";
import { CodeCheckFormValidationSchema } from "./CodeCheckFormValidationSchema";

const Container = styled.div`
  margin-top: 90px;
  text-align: center;
`;

// const SmsAppContainer = styled.div`
//     display: flex;
//     justify-content: center;
// `;

const NewDevice = () => {
  const dispatch = useDispatch();
  const msisdn = useSelector((state) => state.user.msisdn);
  const isLoading = useSelector((state) => state.auth.check.isLoading);
  const expired = useSelector((state) => state.timer.expired);
  const counter = useSelector((state) => state.timer.counter);
  const [value, setValue] = useState("");
  const [code, setCode] = useState("");

  useEffect(() => {
    if (value.length === 6) {
      dispatch(verifyDeviceOtp(msisdn, value));
    }
    if (code.length === 6) {
      dispatch(verifyDeviceOtp(msisdn, code));
    }
  }, [dispatch, code, value, msisdn]);

  return isLoading ? (
    <Loader />
  ) : (
    <Fragment>
      <DesktopBackgroundLayout>
        <TopHeader noTitle noBorderBottom />
        <ScreenContainer>
          <Container>
            <NewDeviceAvater />
            <Title textAlign="left" size="24px" top="42px" weight="bold">
              New Device Login
            </Title>
            <Message top="8px" bottom="24px" size="14px" align="left">
              Kindly provide the 6-digit OTP that was sent to your number{" "}
              <strong style={{ color: colors.deepBlue }}>{msisdn}</strong> to
              confirm it is you.
            </Message>
            <Formik
              initialValues={{
                code: "",
              }}
              validationSchema={CodeCheckFormValidationSchema}
            >
              {({ values, errors, valid, touched, setFieldValue }) => (
                <Form>
                  <Message size={"10px"} align="left">
                    {" "}
                    Enter Pin{" "}
                  </Message>
                  <PasswordGroup
                    count={6}
                    startIndex={1}
                    type="number"
                    valid={valid}
                    name="code"
                    align={"center"}
                    errors={errors}
                    touched={touched}
                    enteredValue={value || ""}
                    handleChange={(event) => {
                      const ele = document.querySelector('input[name="code"]');
                      if (ele.value.length === 6) {
                        const instantOTP = ele.value;
                        setValue(instantOTP);
                      } else {
                        setValue(event.target.value);
                        setFieldValue("code", getInputValues("code"));
                        setCode(getInputValues("code"));
                      }
                    }}
                  />
                  <Message size="14px">
                    <ResendCodeTimer
                      counter={counter}
                      expired={expired}
                      // sendUssd={() => dispatch(sendUssd())}
                      setExpired={(condition) =>
                        dispatch(setExpired(condition))
                      }
                      sendViaWhatsApp={() =>
                        dispatch(sendDeviceVerificationOtpToWhatsApp(msisdn))
                      }
                      tick={(counter) => dispatch(tick(counter))}
                      resendCode={() =>
                        dispatch(sendDeviceVerificationOtp(msisdn))
                      }
                    />
                  </Message>
                  {/* <SmsAppContainer>
                                        <Message size="14px">Open</Message>
                                        <Message color={colors.link.active} left="5px" size="14px">SMS App</Message>
                                    </SmsAppContainer> */}
                </Form>
              )}
            </Formik>
          </Container>
        </ScreenContainer>
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default NewDevice;
