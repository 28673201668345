import React, { Fragment, useState, useEffect } from "react";

import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import {
  TopHeader,
  InputWithOnchange,
  RippleButton,
  PageLogo,
  SelectBox,
} from "../../../../../components";
import {
  PageTitleWrapper,
  PageTitle,
  PageSubTitle,
} from "../../../../../containers/MessageContainer";
import {
  ScreenContainer,
  PageProgressLine,
  PageProgressWrap,
  PageProgressStep,
  FloatingBottomSection,
} from "../../../../../containers/ScreenContainer";
import {
  verifyAccountNumber,
  getAvailableBanksNoAuth,
} from "../../../../../redux/ducks/account/wallet/actions/bank-account";
import DesktopBackgroundLayout from "../../../../DesktopBackgroundLayout";

import AgentBankAccountIcon from "./assets/bank.svg";
import preloader from "./assets/input_preloader.gif";
import { BankAccountValidationSchema } from "./BankAccountValidationSchema";

const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Text = styled.h3`
  font-size: 10px;
  color: ${({ color }) => color || "#227EFF"};
  font-weight: 400;
  margin-left: 20px;
  line-height: 16px;
`;

const AgentBankAccount = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const banks = useSelector((state) => state.account.wallet.availableBanks);
  const [modifiedBanksList, setModifiedBankList] = useState([]);
  const [accounHolderName, setAccountHolderName] = useState(undefined);
  const [accountNumber, setAccountNumber] = useState("");
  const [bankCode, setBankCode] = useState("");
  let userData = localStorage.getItem("userData");

  useEffect(() => {
    dispatch(getAvailableBanksNoAuth());
  }, [dispatch]);

  useEffect(() => {
    banks &&
      setModifiedBankList(
        banks.map((bank) => ({ value: bank["code"], label: bank["name"] })) ||
          []
      );
  }, [banks]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (accountNumber.length === 10) {
        dispatch(verifyAccountNumber(accountNumber, bankCode)).then((res) =>
          setAccountHolderName(res)
        );
      } else {
        setAccountHolderName(undefined);
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [dispatch, accountNumber, bankCode]);

  return (
    <Fragment>
      <DesktopBackgroundLayout>
        <TopHeader title={"Agent Account"}>
          <PageProgressWrap>
            <PageProgressStep>3</PageProgressStep>/4
          </PageProgressWrap>
          <PageProgressLine lineWidth={"75%"} />
        </TopHeader>

        <ScreenContainer height="100%">
          <PageLogo
            top={"26px"}
            margin={"auto"}
            borderRadius={"0"}
            Icon={AgentBankAccountIcon}
            iconWidth="72px"
            iconHeight="72px"
          />
          <PageTitleWrapper mtop={"10px"} mbottom={"24px"}>
            <PageTitle size={"16px"} top={"16px"} bottom={"4px"}>
              Bank Account
            </PageTitle>
            <PageSubTitle margin={"0"}>
              Please provide your bank account details
            </PageSubTitle>
          </PageTitleWrapper>

          <Formik
            initialValues={{
              accountNumber: "",
              bankName: "",
              bankCode: "",
              currency: "R",
            }}
            enableReinitialize={true}
            validationSchema={BankAccountValidationSchema}
            onSubmit={(values) => {
              values.accountOwnerName = accounHolderName;

              userData = JSON.parse(userData);
              userData = { ...userData, ...values };
              localStorage.setItem("userData", JSON.stringify(userData));

              history.push("/user/create_agent_pin");
            }}
          >
            {({ values, errors, touched, setFieldValue, initialValues }) => (
              <Form>
                <InputBlock>
                  <SelectBox
                    placeholder={"Bank"}
                    value={values.bankName}
                    options={modifiedBanksList}
                    handleChange={(selected) => {
                      setFieldValue("bankName", selected.label);
                      setFieldValue("bankCode", selected.value);
                      setBankCode(selected.value);
                    }}
                    valid={`${!touched.bankName && !errors.bankName}`}
                    error={
                      touched && touched.bankName && errors && errors.bankName
                    }
                  />

                  <InputWithOnchange
                    type="text"
                    maxLength="10"
                    top="16px"
                    bottom="12px"
                    height="56px"
                    name="accountNumber"
                    label="Account number"
                    placeholder="Account number"
                    value={values.accountNumber}
                    disabled={!values.bankName && !values.bankCode}
                    background={"#f2f5fa"}
                    valid={`${!touched.accountNumber && !errors.accountNumber}`}
                    errors={
                      touched &&
                      touched.accountNumber &&
                      errors &&
                      errors.accountNumber
                    }
                    onChange={(e) => {
                      setFieldValue("accountNumber", e.target.value);
                      setAccountNumber(e.target.value);
                    }}
                    onKeyPress={(event) => {
                      if (event.charCode < 48 || event.charCode > 57) {
                        event.preventDefault();
                      }
                    }}
                  >
                    {values.accountNumber.length === 10 &&
                    accounHolderName === undefined ? (
                      <img
                        alt={"loader"}
                        width={20}
                        height={20}
                        src={preloader}
                      />
                    ) : null}
                  </InputWithOnchange>
                  {accounHolderName === undefined ? null : accounHolderName ? (
                    <Text color={"#071827"}>{accounHolderName}</Text>
                  ) : (
                    <Text color={"#E01A00"}>Invalid Account Number</Text>
                  )}
                </InputBlock>
                <FloatingBottomSection>
                  <RippleButton
                    type="submit"
                    top={"24px"}
                    disabled={
                      !values.bankName ||
                      !values.accountNumber ||
                      String(values.accountNumber).length !== 10 ||
                      !accounHolderName
                    }
                  >
                    Continue
                  </RippleButton>
                </FloatingBottomSection>
              </Form>
            )}
          </Formik>
        </ScreenContainer>
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default AgentBankAccount;
