import React, { Fragment, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import { ReactComponent as ForwardIcon } from "../../../../assets/arrow.svg";
import {
  TopHeader,
  RippleLink,
  PageLogo,
  Loader,
} from "../../../../components";
import {
  List,
  ListItem,
  ListLeftBlock,
  ListHighlight,
  ListHeading,
} from "../../../../containers/ListContainer";
import { Message } from "../../../../containers/MessageContainer";
import {
  ScreenContainer,
  ViewContainer,
} from "../../../../containers/ScreenContainer";
import { getUtilityProviders } from "../../../../redux/ducks/applications/bill-payments/actions/utility-services";

const SelectBlock = styled(ListHighlight)`
  margin: auto 0 auto 16px;
`;

const SelectProvider = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(
    ({ applications }) => applications.billPayments.isLoading
  );
  const allProviders = useSelector(
    ({ applications }) => applications.billPayments.allProviders
  );

  useEffect(() => {
    dispatch(getUtilityProviders("Electric"));
  }, [dispatch]);

  return isLoading ? (
    <Loader />
  ) : (
    <Fragment>
      <TopHeader title={"Electricity Bill"} />
      <ScreenContainer>
        <ViewContainer>
          <Message bottom={"24px"}>Select a network provider</Message>
          <List fullScreen>
            {allProviders &&
              allProviders.length > 0 &&
              allProviders.map((provider, index) => (
                <RippleLink
                  key={index}
                  to={{ pathname: "/actions/electricity_pay", state: provider }}
                >
                  <ListItem style={{ alignItems: "center" }} bottom={"8px"}>
                    <PageLogo
                      Icon={provider.logoUrl}
                      width={"32px"}
                      height={"32px"}
                      iconWidth={"32px"}
                      iconHeight={"32px"}
                    />
                    <ListLeftBlock>
                      <ListHeading
                        style={{ fontSize: "12px", whiteSpace: "normal" }}
                      >
                        {provider.name}
                      </ListHeading>
                    </ListLeftBlock>
                    <SelectBlock>
                      <ForwardIcon />
                    </SelectBlock>
                  </ListItem>
                </RippleLink>
              ))}
          </List>
        </ViewContainer>
      </ScreenContainer>
    </Fragment>
  );
};
export default SelectProvider;
