import React from "react";

import { bool, func, string } from "prop-types";
import styled from "styled-components";

import { Title, SubTitle } from "../../../containers/MessageContainer";
import { Overlay, ActionBlock } from "../../../containers/OverlayContainer";
import { FlexCenteredBlock } from "../../../containers/ScreenContainer";
import { ReferralBadge } from "../../badges";
import { PopUp, PopUpContent, OkayCancelButton, PopUpHeader } from "../common";

import { ReactComponent as MarketplaceIcon } from "./assets/marketplace.svg";

const BadgeWrapper = styled.div`
  margin: 16px 0;
  padding: 0 8px;
`;

const EmptyStateContent = styled(FlexCenteredBlock)`
  padding: 43px 16px 50px 16px;
`;

export const StorefrontLinkPopup = ({ open, cancel, link, shopName }) => {
  return (
    open && (
      <PopUp open={open}>
        <Overlay bgc={"rgba(0, 0, 0, 0.45)"} onClick={cancel}></Overlay>
        <PopUpContent>
          {link && (
            <PopUpHeader align={"center"}>Share Marketplace Links</PopUpHeader>
          )}
          {!link ? (
            <EmptyStateContent>
              <MarketplaceIcon />
              <Title top={"34px"}>No marketplace listings</Title>
              <SubTitle top={"8px"}>
                Make your shop available on a marketplace
              </SubTitle>
            </EmptyStateContent>
          ) : (
            <BadgeWrapper>
              <ReferralBadge
                embedded={true}
                shareData={link}
                marketingMessage={`Visit my shop on Merchlist`}
                url={link}
                shareMessage={link}
                title={shopName}
              />
            </BadgeWrapper>
          )}
          <ActionBlock top={"24px"}>
            <OkayCancelButton type="button" onClick={cancel}>
              Cancel
            </OkayCancelButton>
          </ActionBlock>
        </PopUpContent>
      </PopUp>
    )
  );
};

StorefrontLinkPopup.propTypes = {
  open: bool,
  cancel: func,
  link: string,
};
