import { Field } from "formik";
import styled, { css } from "styled-components";

import ChevronDownIcon from "../assets/chevron_down.svg";
import { colors, fonts } from "../styles";

export const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ top }) => top || null};
  margin-bottom: ${({ bottom }) => bottom || null};
  height: ${({ height }) => height || null};
`;

export const InputLabelBlock = styled.div`
  position: relative;
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "48px"};
  padding: ${({ textCenter }) => (textCenter ? "14px 0" : "14px")};
  padding: ${({ padTopLeft }) => padTopLeft || "14px"};
  margin-top: ${({ top }) => top || null};
  margin-bottom: ${({ bottom, error }) =>
    bottom
      ? !error
        ? bottom
        : `calc(${bottom} + 16px)`
      : !error
      ? "16px"
      : "32px"};
  margin-right: ${({ right }) => right || "0px"};
  margin-left: ${({ left }) => left || "0px"};
  background-color: ${({ background }) =>
    background || `${colors.border.default}`};
  border: ${({ border }) => border || "1px"} solid
    ${({ borderColor }) => borderColor || colors.white};
  border-radius: ${({ borderRadius }) => borderRadius || "4px"};
  transition: all 0.1s linear;

  ${({ valid }) =>
    valid &&
    css`
      &:focus:not(:disabled) {
        border-color: ${colors.border.active};
      }
    `}
  ${({ error }) =>
    error &&
    css`
      &:not(:disabled),
      &:focus:not(:disabled) {
        border-color: ${colors.border.error};
      }
    `}
    &:focus:not(:disabled) {
    border-color: ${colors.border.active};
  }
  &:not(:disabled) .form-error {
    font-size: 12px;
    font-family: ${fonts.main};
    font-size: 12px;
    color: ${colors.red};
    position: relative;
    min-width: 300px;
    top: 18px;
    right: 16px;
    left: ${({ textLeft }) => textLeft || "0px"};
  }
  &:disabled .form-error {
    display: none;
  }
  &::placeholder,
  &:disabled {
    opacity: 0.5;
  }
  @media (max-width: 767px) {
    &:not(:disabled) .form-error {
      display: block;
      font-size: 11px;
      width: 100%;
      text-align: left;
    }
  }
`;

export const TextareaInputBlock = styled(Field)`
  position: relative;
  width: 100%;
  height: ${({ inputheight }) => inputheight || null};
  font-size: ${({ size }) => size || "14px"};
  border-radius: 4px;
  outline-color: transparent;
  background-color: ${({ background }) =>
    background || `${colors.themeColor3}`};
  border: none;
  outline: none;

  ${({ nolabelmargin }) =>
    !nolabelmargin &&
    css`
      &:not(:placeholder-shown) {
        margin-top: ${({ mtop }) => mtop || "8px"};
      }
    `}
  &:not(:placeholder-shown) + label {
    display: block;
  }
`;

export const InputWithValidation = styled(Field)`
  border: none;
  padding: ${({ countryselection }) => (countryselection ? "0 50px" : "0")};
  height: 20px;
  font-size: 14px;
  width: 100%;
  position: relative;
  background-color: ${({ background }) =>
    background || `${colors.border.default}`};
  outline: none;
  appearance: textfield;
  letter-spacing: ${({ letterSpacing }) => letterSpacing || "1.2px"};
  line-height: 24px;
  &input::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  line-height: ${({ lineHeight }) => lineHeight};

  ${({ nolabelmargin }) =>
    !nolabelmargin &&
    css`
      &:not(:placeholder-shown) {
        margin-top: ${({ mtop }) => mtop || "8px"};
      }
    `}
  &:not(:placeholder-shown) + label {
    display: block;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 30px ${colors.border.default} inset !important;
    box-shadow: 0 0 0 30px ${colors.border.default} inset !important;
  }
  @media (max-width: 767px) {
    height: ${({ mheight }) => mheight || "initial"};
  }
`;

export const OpenOverlaySelectBox = styled(InputLabelBlock)`
  position: relative;
  padding: 16px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  background-color: #f2f5fa;
  border: 1px solid #e2e9f0;
  border-radius: 4px;
  height: 56px;
  color: ${({ textColor }) => textColor || colors.themeTextColor1};
  opacity: ${({ disabled }) => (disabled ? "70%" : "100%")};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  ${({ height }) => height && `height: ${height}`};
  &::after {
    content: url(${ChevronDownIcon});
    position: absolute;
    width: 24px;
    height: 24px;
    text-align: center;
    cursor: pointer;
    right: 16px;
    top: 14px;
  }
`;

export const CustomInput = styled.input`
  border: none;
  line-height: 18px;
  font-size: 14px;
  width: 100%;
  position: relative;
  background-color: ${colors.border.default};
  outline: none;
  padding: 16px;
  border-radius: 13px;
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 30px ${colors.border.default} inset !important;
    box-shadow: 0 0 0 30px ${colors.border.default} inset !important;
  }
`;
