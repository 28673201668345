export const fileToFormData = async (fileUri, fileData = null) => {
  let fileToSend;
  const formData = new FormData();

  if (typeof fileUri === "string") {
    fileToSend = await fetch(fileUri)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new File([blob], fileData?.fileName || "name", {
            type: blob.type,
            lastModified: new Date(),
          })
      );
  } else {
    fileToSend = fileUri;
  }

  formData.append("file", fileToSend);
  return formData;
};
