import React from "react";

import styled from "styled-components";

import { TopHeader } from "../../../../../components";
import { PopUp, PopUpContent } from "../../../../../components/popup/common";
import {
  List,
  ListItem,
  ListSubHeading,
  ListLeftBlock,
} from "../../../../../containers/ListContainer";
import { SlidingOverlay } from "../../../../../containers/OverlayContainer";
import {
  ScreenContainer,
  ViewContainer,
} from "../../../../../containers/ScreenContainer";
import CheckedIcon from "../assets/checkmark.svg";

export const CheckBox = styled(ListLeftBlock)`
  width: 100%;
  > div {
    position: absolute;
    top: 16px;
    right: 2px;
    height: 24px;
    width: 24px;
    border: 2px solid #bbc0c9;
    border-radius: 4px;
  }

  &.active {
    > div {
      background: #227eff;
      border: none;
    }
    &:after {
      content: url(${CheckedIcon});
      position: absolute;
      top: 16px;
      right: 7px;
    }
  }
`;

const FieldName = styled(ListSubHeading)`
  color: #071827;
  font-size: 14px;
  font-weight: 500;
  margin: auto 0;
  padding-bottom: 8px;
`;

const FieldCancel = styled.div`
  color: #227eff;
  font-weight: 500;
  font-size: 16px;
  margin-top: 40px;
  text-align: center;
`;

export const AddFields = ({
  setOpen,
  open,
  selectedFields,
  setSelectedFields,
}) => {
  const availableFields = [
    { title: "Phone Number 2", value: "PHONE_NUMBER_2" },
    { title: "Email Address", value: "EMAIL" },
    { title: "Address", value: "HOUSE_ADDRESS" },
    { title: "Bank Account", value: "BANK_ACCOUNT" },
    { title: "Delivery Note", value: "DELIVERY_NOTE" },
  ];

  return (
    <SlidingOverlay>
      <PopUp open={open} zIndex={"10000"}>
        <PopUpContent height={"450px"}>
          <TopHeader
            title={"More Information"}
            size={"16px"}
            weight={"bold"}
            position={"relative"}
            width={"null"}
            margin={"0 16px"}
            top={"0"}
            noArrow
          />
          <ScreenContainer top={"0"}>
            <ViewContainer top={"-16px"}>
              <List margin={"0 16px"}>
                {availableFields.map((field, index) => (
                  <ListItem
                    padding={"0"}
                    margin={"0"}
                    key={index}
                    onClick={() => {
                      if (selectedFields.includes(field.value)) {
                        setSelectedFields(
                          selectedFields.filter(
                            (selectedField) => selectedField !== field.value
                          )
                        );
                      } else {
                        setSelectedFields((fields) => [...fields, field.value]);
                      }
                    }}
                  >
                    <CheckBox
                      className={
                        selectedFields.includes(field.value) ? "active" : ""
                      }
                    >
                      <div></div>
                      <FieldName>{field.title}</FieldName>
                    </CheckBox>
                  </ListItem>
                ))}
              </List>
            </ViewContainer>
            <FieldCancel onClick={() => setOpen(!open)}>Done</FieldCancel>
          </ScreenContainer>
        </PopUpContent>
      </PopUp>
    </SlidingOverlay>
  );
};
