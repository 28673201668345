import React from "react";

import {
  BubbleChartLegendBox,
  AddFlex,
  LegendProductPercentage,
  LegendProductName,
  LegendProductCount,
  LegendFlexItem,
} from "../reports.styles";

export const LegendBox = ({ productsReport, open }) => {
  const colors = [
    "#015436",
    "#02b57d",
    "#579fd7",
    "#6c7984",
    "#92cfdf",
    "#ffaa00",
  ];

  return (
    <BubbleChartLegendBox open={open}>
      {productsReport.length ? (
        productsReport.map((product, key) => (
          <AddFlex key={key}>
            <LegendProductPercentage color={colors[key]}>
              {Math.round(product.percentage)}%
            </LegendProductPercentage>
            <LegendFlexItem>
              <LegendProductName>
                {" "}
                {product && product.name
                  ? product.name.toLowerCase()
                  : "unnamed product"}
              </LegendProductName>
              <LegendProductCount>{product.count}</LegendProductCount>
            </LegendFlexItem>
          </AddFlex>
        ))
      ) : (
        <></>
      )}
    </BubbleChartLegendBox>
  );
};
