import React, { useEffect, useState } from "react";

import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as AirtimeIcon } from "../../../../../assets/airtime.svg";
import { TopHeader } from "../../../../../components";
import { ReactComponent as ShsIcon } from "../../../../../components/badges/wallet-badge2/assets/shs.svg";
import { ScreenContainer } from "../../../../../containers/ScreenContainer";
import { fetchAllBillCategories } from "../../../../../redux/ducks/account/wallet/actions/bills-payment";
import DesktopBackgroundLayout from "../../../../DesktopBackgroundLayout";
import { ReactComponent as RightArrow } from "../../../../home/dashboard/assets/right-arrow.svg";
import { FeatureUndergoingFixes } from "../../../../../components/popup/feature-undergoing-fixes";

const Bill = styled.button`
  background: #edf2f7;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 14px;
  border: none;

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const Bills = styled.div`
  margin: 17px 0;
`;

const IconAndTitle = styled.div`
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  gap: 16px;
`;

const ScaledRightArrow = styled(RightArrow)`
  transform: scale(1.5);
`;

const ScaledDownAirtimeIcon = styled(AirtimeIcon)`
  height: 32px;
  width: 32px;
`;

const ScaledDownShsIcon = styled(ShsIcon)`
  height: 32px;
  width: 32px;
`;

const billPayments = [
  {
    name: "SHS Payment",
    route: "/actions/shs_transfer",
    icon: <ScaledDownShsIcon />,
    featureName: "walletShsPayment",
  },
  {
    name: "Buy Airtime and Earn",
    route: "/user/wallet/airtime-recharge",
    icon: <ScaledDownAirtimeIcon />,
    featureName: "walletBuyAirtimeAndEarn",
  },
];

export function PayBills() {
  const history = useHistory();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.account.wallet.isLoading);
  const features = useSelector((state) => state.user.features);

  const [openFeatureFixes, setOpenFeatureFixes] = useState(false);

  useEffect(() => {
    dispatch(fetchAllBillCategories());
  }, [dispatch]);

  const clickAction = (url, feature) => {
    if (!features?.[feature]?.status) return setOpenFeatureFixes(true);
    history.push(url);
  };

  return (
    <DesktopBackgroundLayout>
      <TopHeader title={"Pay Bills"} backLink={"/user/wallet_index"} />
      {openFeatureFixes && (
        <FeatureUndergoingFixes cancel={() => setOpenFeatureFixes(false)} />
      )}
      <ScreenContainer>
        <p>What would you like to do?</p>
        <Bills>
          {isLoading
            ? [1, 2].map((i) => (
                <Skeleton
                  key={i}
                  style={{ height: "64px", marginBottom: "10px" }}
                />
              ))
            : billPayments.map((bill) => (
                <Bill
                  key={bill.name}
                  onClick={() => clickAction(bill.route, bill.featureName)}
                >
                  <IconAndTitle>
                    {bill.icon}
                    <p>{bill.name}</p>
                  </IconAndTitle>
                  <ScaledRightArrow />
                </Bill>
              ))}
        </Bills>
      </ScreenContainer>
    </DesktopBackgroundLayout>
  );
}
