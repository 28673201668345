import * as constants from "./constants";

const initialState = {
  isLoading: false,
  errorMsg: "",
  allProviders: [],
  allProvidersItems: [],
  selectedProvider: {},
  selectedProvidersItems: {},
  airtimeProviders: [],
  dataProviders: [],
  dataPlans: [],
};

export default function billsPaymentReducer(state = initialState, action) {
  switch (action.type) {
    case constants.PAYMENT_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case constants.PAYMENT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case constants.PAYMENT_ERROR: {
      return {
        ...state,
        isLoading: false,
        errorMsg: action.payload,
      };
    }
    case constants.SET_PROVIDERS_ITEMS: {
      return {
        ...state,
        isLoading: false,
        errorMsg: "",
        allProvidersItems: action.payload,
      };
    }
    case constants.SET_PROVIDERS: {
      return {
        ...state,
        isLoading: false,
        errorMsg: "",
        allProvidersItems: [],
        allProviders: action.payload,
      };
    }
    case constants.SET_AIRTIME_PROVIDERS: {
      return {
        ...state,
        isLoading: false,
        errorMsg: "",
        airtimeProviders: action.payload,
      };
    }
    case constants.SET_DATA_PROVIDERS: {
      return {
        ...state,
        isLoading: false,
        errorMsg: "",
        dataProviders: action.payload,
      };
    }
    case constants.SET_DATA_PLANS: {
      return {
        ...state,
        isLoading: false,
        errorMsg: "",
        dataPlans: action.payload,
      };
    }
    case constants.LOADING_AIRTIME_PROVIDERS: {
      if (state.airtimeProviders && state.airtimeProviders.length > 0) {
        return {
          ...state,
          isLoading: false,
        };
      } else {
        return {
          ...state,
          isLoading: true,
        };
      }
    }
    case constants.LOADING_DATA_PROVIDERS: {
      if (state.dataProviders && state.dataProviders.length > 0) {
        return {
          ...state,
          isLoading: false,
        };
      } else {
        return {
          ...state,
          isLoading: true,
        };
      }
    }

    case constants.LOADING_ELECTRICITY_PROVIDERS: {
      if (state.allProviders && state.allProviders.length > 0) {
        return {
          ...state,
          isLoading: false,
        };
      } else {
        return {
          ...state,
          isLoading: true,
        };
      }
    }

    case constants.LOADING_CABLE_PROVIDERS: {
      if (state.allProviders && state.allProviders.length > 0) {
        return {
          ...state,
          isLoading: false,
        };
      } else {
        return {
          ...state,
          isLoading: true,
        };
      }
    }

    default: {
      return state;
    }
  }
}
