import React, { Fragment, useState, useEffect } from "react";

import { useSelector } from "react-redux";

import {
  Loader,
  TopHeader,
  InfiniteScrollList,
  UserAvatar,
} from "../../../../components";
import {
  List,
  ListHeading,
  ListSubHeading,
} from "../../../../containers/ListContainer";
import { ViewContainer } from "../../../../containers/ScreenContainer";
import { MODULE_INTERACTION } from "../../../../utils/mix-panel/constants";
import { mixPanel } from "../../../../utils/mix-panel/mixPanel";
import DesktopBackgroundLayout from "../../../DesktopBackgroundLayout";
import { ModifiedListItem, VerticalDiv } from "../styles";
import { getDate } from "../utils/date";

const MerchantReferralsList = () => {
  const referrals = useSelector(
    (state) => state.applications.merchants.merchantsReferral
  );
  const [isLoading, setIsLoading] = useState(true);
  const [referralsToDisplay, setReferralsToDisplay] = useState([]);
  const perPage = 20;
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const dataToAdd = referrals.slice(0, perPage);
    setReferralsToDisplay(dataToAdd);

    if (referrals.length > perPage) {
      setPage(2);
      setHasMore(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    mixPanel.track(MODULE_INTERACTION, {
      "Icon Name": "Merchant referrals",
      Time: new Date().toLocaleString(),
    });
  }, []);

  const addMoreDataToDisplay = () => {
    if (referralsToDisplay.length < referrals.length) {
      const count = perPage * page;
      const dataToAdd = referrals.slice(0, count);
      setReferralsToDisplay(dataToAdd);
      setPage(page + 1);
    } else {
      setHasMore(false);
    }
  };

  if (isLoading) return <Loader />;

  return (
    <Fragment>
      <DesktopBackgroundLayout>
        <TopHeader
          size="14px"
          weight="400"
          color="#071827"
          noBorderBottom
          title={"My Referrals"}
        />
        <ViewContainer style={{ padding: "0 16px" }} top={"64px"}>
          <Fragment>
            <List noBorderBottom>
              <InfiniteScrollList
                data={referralsToDisplay}
                fetchMore={addMoreDataToDisplay}
                hasMore={hasMore}
                endMessage="That's all your referrals!"
              >
                {referralsToDisplay &&
                  referralsToDisplay.map((referral, index) => (
                    <ModifiedListItem
                      key={index}
                      height={"48px"}
                      bottom={"8px"}
                      style={{ overflowX: "hidden" }}
                      padding="0"
                    >
                      <UserAvatar
                        avatar={referral.avatar}
                        width={"32px"}
                        height={"32px"}
                      />
                      <VerticalDiv left={"16px"}>
                        <ListHeading color="#071827">{`${referral.firstName} ${referral.lastName}`}</ListHeading>
                        <ListSubHeading
                          top={"0px"}
                          fontSize="12px"
                          color="#718596"
                          fontWeight="400"
                          maxWidth="100%"
                        >
                          Date Added: {getDate(referral.createdAt)}
                        </ListSubHeading>
                      </VerticalDiv>
                    </ModifiedListItem>
                  ))}
              </InfiniteScrollList>
            </List>
          </Fragment>
        </ViewContainer>
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default MerchantReferralsList;
