import React, { Fragment, useState } from "react";

import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";

import {
  TopHeader,
  RippleButton,
  PasswordGroup,
  SuccessPage,
  CopyReferralBadge,
  Loader,
} from "../../../../../components";
import { Message } from "../../../../../containers/MessageContainer";
import { shsActions } from "../../../../../redux/ducks/applications/shs/actions";
import { colors } from "../../../../../styles";
import { formatPrice } from "../../../../../utils/currency/formatPriceWithComma";
import { getInputValues } from "../../../../../utils/inputs/getInputValues";
import DesktopBackgroundLayout from "../../../../DesktopBackgroundLayout";
import { ReactComponent as FromSvg } from "../assets/from.svg";
import { ReactComponent as LineSvg } from "../assets/line.svg";
import { ReactComponent as SpacesLogo } from "../assets/small_spaces_logo.svg";
import { ReactComponent as ToSvg } from "../assets/to.svg";

import { CodeCheckFormValidationSchema } from "./CodeCheckFormValidationSchema";

const ParentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #0350bb;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const LinearLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
  width: 100%;
  height: 100%;
`;

const TopCard = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 64px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  background: #ffffff;
  padding: 32px 16px 24px 16px;
  border-radius: 8px 8px 0 0;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.05);
  z-index: 1;
`;

const BottomCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  background: #ffffff;
  padding: 16px;
  border-radius: 0 0 8px 8px;
  border-top-style: dashed;
  border-width: 1px;
  border-color: #b6c1de;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.05);
  z-index: 1;
`;

const BottomParentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  padding-bottom: 40px;
  width: 100%;
  height: 100%;
`;

const VerticalDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 22px;
`;

const HorizontalDiv = styled.div`
  display: flex;
`;

const MLineSvg = styled(LineSvg)`
  margin: 0 0 2px 3px;
`;

const FormSection = styled(Form)`
  display: flex;
  height: 100%;
  width: 100%;
  padding: 0px 20px;
  flex-direction: column;
  justify-content: space-between;
`;

const ShsRechargePin = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation().state;
  const isLoading = useSelector((state) => state.applications.shs.isLoading);
  const walletBalance = useSelector(
    (state) => state.account.wallet.balance || 0
  );
  const [customerInfo] = useState(location?.customerInfo || {});
  const [payload] = useState(location?.payload || {});

  const [unlockCode, setUnlockCode] = useState("");
  const [pin, setPin] = useState("");
  const [value, setValue] = useState("");
  const [showSuccessPage, setShowSucessPage] = useState(false);
  const [ref, setRef] = useState();

  const onSubmitClick = async () => {
    //Verify Pin
    const verifiedPin = await dispatch(shsActions.verifyPin(pin));
    if (verifiedPin) {
      const code = await dispatch(shsActions.billPayment(payload));
      code && setUnlockCode(code?.unlockCode);
      code && setShowSucessPage(true);
      code && setRef(code?.transactionReference);
    } else {
      toast.error("You have entered a wrong PIN");
    }
  };

  if (isLoading) return <Loader />;
  return (
    <Fragment>
      <DesktopBackgroundLayout>
        <TopHeader
          title={"Recharge"}
          backgroundColor={colors.bgBlue}
          color={colors.white}
          opaqueBackButton
          noBorderBottom
        />
        <ParentWrapper>
          <LinearLayout>
            <TopCard>
              <HorizontalDiv>
                <VerticalDiv>
                  <FromSvg />
                  <MLineSvg />
                  <ToSvg />
                </VerticalDiv>
                <VerticalDiv>
                  <VerticalDiv>
                    <Message
                      top="-5px"
                      color="#718596"
                      size="10px"
                      weight="400"
                    >
                      FROM
                    </Message>
                    <Message top="0px" color="#1A2630" size="14px" weight="500">
                      My Wallet
                    </Message>
                    <Message
                      top="0px"
                      color="#071827"
                      size="12px"
                      weight="400"
                    >{`Bal ${formatPrice(walletBalance || 0)}`}</Message>
                  </VerticalDiv>
                  <VerticalDiv>
                    <Message
                      top="22px"
                      bottom="6px"
                      color="#516C82"
                      size="10px"
                      weight="400"
                    >
                      TO
                    </Message>
                    <HorizontalDiv>
                      <SpacesLogo />
                      <VerticalDiv>
                        <Message
                          left="8px"
                          top="0px"
                          color="#1A2630"
                          size="12px"
                          weight="500"
                        >{`${customerInfo.customerFirstName} ${customerInfo.customerLastName}`}</Message>
                        <Message
                          left="8px"
                          top="0px"
                          color="#313645"
                          size="11px"
                          weight="400"
                        >{`${payload.shsCustomerPhoneNumber}`}</Message>
                      </VerticalDiv>
                    </HorizontalDiv>
                  </VerticalDiv>
                </VerticalDiv>
              </HorizontalDiv>
            </TopCard>
            <BottomParentWrapper>
              <BottomCard>
                <Message top="0px" color="#718596" size="12px" weight="400">
                  Amount
                </Message>
                <Message
                  top="0px"
                  color="#071827"
                  size="16px"
                  weight="700"
                >{`${formatPrice(payload?.amount || 0)}`}</Message>
              </BottomCard>

              <Message color="#071827" top={"24px"} bottom={"4px"}>
                Enter Transaction PIN to continue
              </Message>
              <Formik
                initialValues={{
                  pin: "",
                }}
                validationSchema={CodeCheckFormValidationSchema}
                onSubmit={(values, { resetForm }) => {}}
              >
                {({ touched, valid, errors, setFieldValue }) => (
                  <FormSection>
                    <PasswordGroup
                      count={4}
                      startIndex={1}
                      type={"password"}
                      valid={valid}
                      errors={errors}
                      name={"pin"}
                      touched={touched}
                      align={"center"}
                      enteredValue={value || ""}
                      handleChange={(event) => {
                        setValue(event.target.value);
                        setFieldValue("pin", getInputValues("pin"));
                        setPin(getInputValues("pin"));
                      }}
                    />
                    {!isLoading && (
                      <RippleButton
                        bottom="0px"
                        top="8px"
                        disabled={!pin || pin.length < 4}
                        backgroundColor={colors.deepBlue}
                        onClick={onSubmitClick}
                      >
                        Transfer
                      </RippleButton>
                    )}
                  </FormSection>
                )}
              </Formik>
            </BottomParentWrapper>
          </LinearLayout>
        </ParentWrapper>
        {showSuccessPage && (
          <SuccessPage
            title="Recharge Successful"
            subtitle="Your payment has been successfully made"
            showDetailButton
            onDoneClick={() => {
              history.push("/user/wallet_index");
            }}
            onDetailClick={async () => {
              history.push(`/user/wallet_transaction_details/${ref}`);
            }}
          >
            <CopyReferralBadge
              title="Activation Code"
              referralCode={unlockCode}
            />
          </SuccessPage>
        )}
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default ShsRechargePin;
