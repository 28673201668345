import React, { Fragment, useEffect, useState } from "react";

import { Formik, Form } from "formik";
import { string, func, bool } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";

import {
  Loader,
  TopHeader,
  RippleButton,
  InputWithLabel,
  SelectBox,
} from "../../../../../../components";
import { Close } from "../../../../../../containers/HeaderContainer";
import { InputBlock } from "../../../../../../containers/InputContainer";
import { ScreenContainer } from "../../../../../../containers/ScreenContainer";
import { flags } from "../../../../../../data/countries";
import { merchbuyActions } from "../../../../../../redux/ducks/applications/merchbuy/actions";
import * as actions from "../../../../../../redux/ducks/applications/my-shop/actions/customers";
import { colors } from "../../../../../../styles";
import { mapAvailableStates } from "../../../../../../utils/inputs/mapAvailableStates";
// import { mapCitiesByLga } from '../../../../../../utils/inputs/mapCitiesByLga';
import { mapLgaByAvaliableStates } from "../../../../../../utils/inputs/mapLgaByAvaliableStates";
import { ReactComponent as ShopIcon } from "../../../assets/shop_icon_blue.svg";
import VerticalLine from "../../../assets/vline.svg";
import { Space } from "../../../styles";
import SimpleMap from "../../google-map/simpleMap";

import InfoBadge from "./../../../../../../components/badges/info-badge/index";
import { UpdateDeliveryDetailsValidationSchema } from "./UpdateDeliveryDetailsValidationSchema";

const Padding = styled.div`
  padding: 0 1em;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #071827;
`;

const InnerTitle = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: ${({ color }) => color || "#071827"};
  margin: 8px 0 5px 16px;
`;

const Subtitle = styled.div`
  font-size: 14px;
  line-height: 21px;
  color: #718596;
  padding-top: 6px;
  padding-bottom: 24px;
`;

const FullAddressHolder = styled.div`
  background: #ffffff;
  border: 1px solid #edf2f7;
  box-sizing: border-box;
  box-shadow: 0px 15px 84px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 12px;
  margin: 16px;
`;

const ShopAddressInfo = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #718596;
  margin-left: ${({ left }) => left || "0px"};
`;

const Divider = styled.div`
  border: 1px solid #edf2f7;
  margin: 8px 0;
`;

const ShopAddressWrapper = styled.div`
  display: flex;
`;

const CountryPhoneBlock = styled.div`
  position: relative;
`;

const CurrentSelectedCountry = styled.div`
  position: absolute;
  left: 16px;
  top: 12px;
  z-index: 2;
  cursor: pointer;
  &::after {
    content: url(${VerticalLine});
    position: absolute;
    width: 24px;
    height: 24px;
    text-align: center;
    cursor: pointer;
    top: 3px;
  }
`;

const CountryFlag = styled.img`
  width: 24px;
  height: 24px;
`;

const checkRequiredFields = (address, lga, phoneNumber, state, city) =>
  !address || !lga || !phoneNumber || !state;

export const EditDeliveryAddressDetails = ({
  open,
  cancel,
  deliveryLocation,
  getDeliveryDetails,
  isEditingContactAddress,
  handleSelectedContactAddress,
  setSelectedDeliveryInformation,
  setShowEditPopup,
  close,
}) => {
  const dispatch = useDispatch();
  const shopInfo = useSelector((state) => state.applications.myShop.shops[0]);
  const user = useSelector((state) => state.user);
  const isLoading = useSelector((state) => state.applications.myShop.isLoading);

  const AgentDeliveryLocation =
    localStorage.getItem("deliveryLocation") &&
    JSON.parse(localStorage.getItem("deliveryLocation"));
  const { state: AgentdeliveryState } = AgentDeliveryLocation || {};

  const { businessPhoneNumber, shopName, lga } = shopInfo || {};
  const listOfAvailableStatesAndLga = useSelector(
    (state) => state.applications.merchbuy.availableStates
  );
  const [listOfAvailableStates, setListOfAvailableStates] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [coordinates, setCoordinates] = useState(
    deliveryLocation?.cordinates || { longitude: 0, latitude: 0 }
  );
  const deliveryAddressEditLimit = useSelector(
    (state) => state.applications.merchbuy.deliveryAddressEditLimit
  );

  useEffect(() => {
    setListOfAvailableStates(mapAvailableStates(listOfAvailableStatesAndLga));
  }, [listOfAvailableStatesAndLga]);

  useEffect(() => {
    dispatch(merchbuyActions.getAVailableStates());
    dispatch(merchbuyActions.getAllCities());
  }, [dispatch]);

  const checkDeliveryLocation = (state) =>
    String(AgentdeliveryState).toLowerCase() === String(state).toLowerCase();

  const handleSelectSelfAddress = (userInfo) => {
    const { address, lga, phoneNumber, state, city } =
      userInfo || deliveryLocation || {};
    const isRequiredDetailsField = checkRequiredFields(
      address,
      lga,
      phoneNumber,
      state,
      city
    );
    const info = {
      ...deliveryLocation,
      contactType: "SELF",
    };

    if (!checkDeliveryLocation(state)) {
      return toast.error(
        "state does not match shopping location, update state to your shopping location"
      );
    }

    if (isRequiredDetailsField || !checkDeliveryLocation(state)) {
      setShowEditPopup(true);
      setSelectedDeliveryInformation && setSelectedDeliveryInformation(info);
    } else {
      setSelectedDeliveryInformation && setSelectedDeliveryInformation(info);
    }
    close();
    getDeliveryDetails(info);
  };

  const onSubmit = (values) => {
    let payload = {
      ...values,
      deliveryInstructions: values.deliveryInstruction,
      cordinates: {
        longitude: coordinates.longitude.toString(),
        latitude: coordinates.latitude.toString(),
      },
    };

    if (deliveryLocation && deliveryLocation?.contactType === "SELF") {
      payload = {
        address: values.shopAddress,
        deliveryInstruction: values.deliveryInstruction || null,
        lga: values.lga,
        phoneNumber: values.msisdn,
        receiverName: `${values.firstName} ${values.lastName}`,
        firstName: values.firstName,
        lastName: values.lastName,
        state: values.state,
        city: values.lga,
        shopNumber: values.shopNumber,
      };

      handleSelectSelfAddress(payload);
      return dispatch(
        actions.updateMerchantDelivery(
          user.userId,
          payload,
          deliveryLocation.contactType
        )
      );
    } else if (
      deliveryLocation &&
      deliveryLocation?.contactType === "CUSTOMER"
    ) {
      handleSelectedContactAddress(payload);
      return dispatch(actions.editSabiCustomers(payload, deliveryLocation?.id));
    } else {
      handleSelectedContactAddress(payload);
      return dispatch(actions.addSabiCustomers(payload));
    }
  };

  const handleSubmit = (values, setErrors) => {
    setTimeout(() => {
      if (businessPhoneNumber) {
        const isUserPhoneNumber = businessPhoneNumber === values.msisdn;
        if (
          (deliveryLocation &&
            deliveryLocation.contactType !== "SELF" &&
            isUserPhoneNumber) ||
          (!deliveryLocation && isUserPhoneNumber)
        ) {
          return setErrors({
            msisdn: "Sorry, you cannot use your number for a customer!",
          });
        }
      }
      onSubmit(values);
      getDeliveryDetails(values);
      cancel();
    }, 200);
  };

  const initialFormValues = {
    firstName:
      deliveryLocation?.firstName ||
      deliveryLocation?.fullName ||
      user?.firstName ||
      "",
    lastName:
      deliveryLocation?.lastName ||
      deliveryLocation?.fullName ||
      user?.lastName ||
      "",
    middleName: "",
    country: "SA",
    state: AgentdeliveryState || "",
    lga:
      AgentdeliveryState === deliveryLocation.state
        ? deliveryLocation?.lga || deliveryLocation?.localGovt || lga || ""
        : "",
    // city:
    //     AgentdeliveryState === deliveryLocation.state
    //         ? (deliveryLocation?.city && deliveryLocation?.city.toUpperCase()) || ''
    //         : '',
    customerId:
      deliveryLocation?.customerId ||
      deliveryLocation?.userId ||
      deliveryLocation?.id ||
      "",
    cordinates: deliveryLocation?.cordinates || "",
    msisdn: deliveryLocation?.phoneNumber || businessPhoneNumber || "",
    phoneNumber: deliveryLocation?.phoneNumber || businessPhoneNumber || "",
    shopAddress:
      deliveryLocation?.address || deliveryLocation?.shopAddress || "",
    deliveryInstruction:
      deliveryLocation?.deliveryInstruction ||
      deliveryLocation?.deliveryInstructions ||
      "",
    shopNumber: deliveryLocation?.shopNumber || "",
    shopName: deliveryLocation?.shopName || shopName || "",
  };

  return isLoading ? (
    <Loader />
  ) : (
    open && (
      <Fragment>
        {!isSelected && (
          <TopHeader
            title={"Update Address"}
            noArrow
            noBorderBottom
            withSpacesHeader
          >
            <Close left={"16px"} onClick={cancel} />
          </TopHeader>
        )}
        <ScreenContainer top="0" padding="0">
          {!isSelected && (
            <Fragment>
              <Space height="71px" />
              {user.role === "ROLE_AGENT" && (
                <InfoBadge
                  margin="0 16px 24px"
                  weight="400"
                  title={`Kindly note, you only have access to change your address ${deliveryAddressEditLimit} times`}
                  marginTop="4px"
                  message={`It has been changed ${
                    deliveryLocation?.changeDeliveryAddressCount ||
                    deliveryLocation?.changeAddressCount
                  } time(s)`}
                  color={colors.warning}
                  backgroundColor={colors.lightYellowBg}
                  top="4px"
                  bottom="4px"
                />
              )}

              <Padding>
                <Title>
                  {!isEditingContactAddress
                    ? "Update Your Address"
                    : "Update Contact's Address"}
                </Title>
                <Subtitle>Kindly update your delivery address.</Subtitle>
              </Padding>
            </Fragment>
          )}
          <Formik
            initialValues={initialFormValues}
            validationSchema={UpdateDeliveryDetailsValidationSchema}
            onSubmit={(values, { setErrors }) =>
              handleSubmit(values, setErrors)
            }
          >
            {({
              errors,
              touched,
              values,
              handleChange,
              setFieldValue,
              initialValues,
              setErrors,
            }) => {
              return (
                <Form>
                  <InputBlock>
                    <Padding>
                      <InputWithLabel
                        label="First Name"
                        type={"text"}
                        value={values.firstName}
                        placeholder={"First Name"}
                        bottom="8px"
                        name="firstName"
                        disabled
                        valid={`${!touched.firstName && !errors.firstName}`}
                        errors={
                          touched &&
                          touched.firstName &&
                          errors &&
                          errors.firstName
                        }
                        setFieldValue={setFieldValue}
                        initialValues={initialValues}
                      />
                    </Padding>
                    <Padding>
                      <InputWithLabel
                        label="Last Name"
                        type={"text"}
                        value={values.lastName}
                        placeholder={"Last Name"}
                        bottom="8px"
                        name="lastName"
                        disabled
                        valid={`${!touched.lastName && !errors.lastName}`}
                        errors={
                          touched &&
                          touched.lastName &&
                          errors &&
                          errors.lastName
                        }
                        setFieldValue={setFieldValue}
                        initialValues={initialValues}
                      />
                    </Padding>
                    <Padding>
                      <CountryPhoneBlock>
                        <CurrentSelectedCountry>
                          <CountryFlag
                            src={
                              flags.filter(
                                (flag) =>
                                  flag.customAbbreviation === values.country
                              )[0].value
                            }
                            alt={
                              flags.filter(
                                (flag) =>
                                  flag.customAbbreviation === values.country
                              )[0].label
                            }
                          />
                        </CurrentSelectedCountry>
                        <InputWithLabel
                          label={"Phone Number"}
                          type={"text"}
                          value={values.msisdn}
                          placeholder={"Phone Number"}
                          bottom="8px"
                          name="msisdn"
                          disabled
                          countryselection
                          valid={`${touched.msisdn && !errors.msisdn}`}
                          errors={
                            touched && touched.msisdn && errors && errors.msisdn
                          }
                          setFieldValue={setFieldValue}
                          initialValues={initialValues}
                        />
                      </CountryPhoneBlock>
                    </Padding>
                    <InnerTitle>Delivery Notes</InnerTitle>
                    <Padding>
                      <InputWithLabel
                        label={"Delivery Note"}
                        type={"text"}
                        value={values.deliveryInstruction}
                        placeholder={"Note for Delivery"}
                        bottom="8px"
                        name="deliveryInstruction"
                        valid={`${
                          touched.deliveryInstruction &&
                          !errors.deliveryInstruction
                        }`}
                        errors={
                          touched &&
                          touched.deliveryInstruction &&
                          errors &&
                          errors.deliveryInstruction
                        }
                        setFieldValue={setFieldValue}
                        initialValues={initialValues}
                      />
                    </Padding>
                    <Space height="24px" />
                    <InnerTitle color={"#718596"}>
                      ENTER ADDRESS DETAILS
                    </InnerTitle>
                    <Padding>
                      <SelectBox
                        placeholder={"Province"}
                        value={values.state}
                        top="0px"
                        title="Select Province"
                        height="90%"
                        disabled
                        isSelected={isSelected}
                        setIsSelected={setIsSelected}
                        options={listOfAvailableStates}
                        handleChange={(selected) => {
                          setFieldValue("state", selected.label);
                          setFieldValue("lga", "");
                          setFieldValue("city", "");
                        }}
                        valid={`${!touched.state && !errors.state}`}
                        errors={
                          touched && touched.state && errors && errors.state
                        }
                      />
                    </Padding>
                    <Padding>
                      <SelectBox
                        placeholder={"Municipalities"}
                        value={values.lga}
                        top="8px"
                        title="Select Municipalities"
                        height="90%"
                        isSelected={isSelected}
                        setIsSelected={setIsSelected}
                        options={mapLgaByAvaliableStates(
                          values.state,
                          listOfAvailableStatesAndLga
                        )}
                        handleChange={(selected) => {
                          setFieldValue("lga", selected.label);
                          setFieldValue("city", "");
                        }}
                        valid={`${!touched.lga && !errors.lga}`}
                        errors={touched && touched.lga && errors && errors.lga}
                      />
                    </Padding>
                    {/* <Padding>
                                            <SelectBox
                                                name={'city'}
                                                placeholder={'Select City'}
                                                value={values.city}
                                                top="8px"
                                                height="90%"
                                                title="Select City"
                                                isSelected={isSelected}
                                                setIsSelected={setIsSelected}
                                                options={mapCitiesByLga(values.lga, cities)}
                                                handleChange={handleChange}
                                                valid={`${!touched.city && !errors.city}`}
                                                errors={touched && touched.city && errors && errors.city}
                                            />
                                        </Padding>
                                        <Padding>
                                            <InputWithLabel
                                                label={'Shop Number'}
                                                type={'text'}
                                                value={values.shopNumber}
                                                placeholder={'Shop Number'}
                                                bottom="8px"
                                                top="8px"
                                                name="shopNumber"
                                                maxLength={15}
                                                valid={`${touched.shopNumber && !errors.shopNumber}`}
                                                errors={touched && touched.shopNumber && errors && errors.shopNumber}
                                                setFieldValue={setFieldValue}
                                                initialValues={initialValues}
                                            />
                                        </Padding> */}

                    <Padding>
                      <InputWithLabel
                        label={"Street Name"}
                        type={"text"}
                        value={values.shopAddress}
                        placeholder={"Street Name"}
                        bottom="8px"
                        top="8px"
                        name="shopAddress"
                        valid={`${touched.shopAddress && !errors.shopAddress}`}
                        errors={
                          touched &&
                          touched.shopAddress &&
                          errors &&
                          errors.shopAddress
                        }
                        setFieldValue={setFieldValue}
                        initialValues={initialValues}
                      />
                    </Padding>

                    <FullAddressHolder>
                      <ShopAddressWrapper>
                        <ShopIcon />
                        <ShopAddressInfo left="6px">
                          Your Shop address
                        </ShopAddressInfo>
                      </ShopAddressWrapper>
                      <Divider />
                      <ShopAddressInfo>
                        {`
                                                    ${values.shopNumber}
                                                    ${values.shopAddress}
                                                    ${values.city}
                                                    ${values.lga}
                                                    ${values.state}
                                                `}
                      </ShopAddressInfo>
                    </FullAddressHolder>
                    <InnerTitle>
                      Drag the marker to the exact location
                    </InnerTitle>
                    <Padding>
                      <SimpleMap
                        coordinates={coordinates}
                        setCoordinates={setCoordinates}
                        address={`${values.shopNumber} ${values.shopAddress} ${values.city} ${values.lga} ${values.state}`}
                      />
                    </Padding>
                    <Padding>
                      <RippleButton type="submit">Update address</RippleButton>
                    </Padding>
                  </InputBlock>
                </Form>
              );
            }}
          </Formik>
        </ScreenContainer>
      </Fragment>
    )
  );
};

EditDeliveryAddressDetails.propTypes = {
  open: bool,
  cancel: func,
  receiverName: string,
  shopName: string,
  streetAddress: string,
  state: string,
  lga: string,
  instruction: string,
  msisdn: string,
  getDeliveryDetails: func,
};
