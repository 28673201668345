import React, { useState, useEffect } from "react";

import { bool, func, object } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { matchPath } from "react-router";
import aa from "search-insights";
import styled from "styled-components";
import useRouter from "use-react-router";

import { Overlay, ActionBlock } from "../../../containers/OverlayContainer";
import { colors } from "../../../styles";
import { formatPrice } from "../../../utils/currency/formatPriceWithComma";
import { COMPLETE_ADD_TO_CART } from "../../../utils/mix-panel/constants";
import { mixPanel } from "../../../utils/mix-panel/mixPanel";
import { is_url } from "../../../utils/urls/isUrl";
import { PopUp, PopUpContent, CancelButton, ConfirmButton } from "../common";

import { ReactComponent as AddIcon } from "./assets/add.svg";
import FallbackProductImage from "./assets/productImage.svg";
import { ReactComponent as SubstractIcon } from "./assets/substract.svg";

const ProductBlock = styled.div`
  margin: 20px 0;
  display: flex;
  width: 100%;
`;

const ProductImage = styled.img`
  width: 82px;
  height: 82px;
  margin-top: 0;
  margin-left: 10px;
  border-radius: 20%;
  object-fit: cover;
  padding: 10px 0;
  background-color: ${colors.themeColor3};
`;

const ProductInfo = styled.div`
  flex: 1;
  font-size: 14px;
  padding: 5px 15% 5px 10px;
`;
const ModifiedSubstractIcon = styled(SubstractIcon)`
  margin: 0px 8px;
`;
const ModifiedAddIcon = styled(AddIcon)`
  margin: 0px 8px;
`;

const Title = styled.div`
  font-size: 12px;
  font-weight: 400;
  margin: 10px 0 2px;
  text-align: left;
`;

const ProductDesc = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  text-align: left;
`;

const UpdateCalculatorBlock = styled.div`
  display: flex;

  flex-direction: row;
  align-items: center;
  justify-content: center;
  & > svg {
    cursor: pointer;
  }
  margin-left: 10px;
`;

const QuantityDisplay = styled.div`
  border-radius: 8px;
  width: 64px;
  height: 32px;
  background-color: ${colors.themeColor3};
  text-align: center;
`;

const CurrentQuantity = styled.input`
  position: relative;
  top: calc(50% - 8px);
  font-weight: 100;
  font-size: 14px;
  font-weight: 500;
  color: ${({ color }) => color || colors.themeTextColor1};
  margin-top: ${({ top }) => top || null};
  margin-left: ${({ left }) => left || null};
  width: 70%;
  background-color: inherit;
  border: none;
  text-align: center;
  &:focus {
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
`;

const MoqParagraph = styled.div`
  font-size: 10px;
  color: ${colors.themeSubColor1};
  font-weight: 500;
  text-align: left;
`;

const OuterQuantityHold = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const QuantityHold = styled.div`
  line-height: 18px;
  margin-left: 10px;
`;
const PriceHolder = styled.p`
  margin-top: 8px;
  font-size: 12px;
  color: ${colors.boldDark};
  font-weight: 700;
  line-height: 18px;
`;

const ProductDescText = styled.h3`
  font-size: 12px;
  color: ${colors.boldDark};
  font-weight: 400;
  line-height: 18px;
`;

export const MerchbuyAddtoCartPopup = ({
  open,
  cancel,
  data,
  confirm,
  shopDetails,
}) => {
  const [selectedMoq, setSelectedMoq] = useState(
    data.bulkPrices ? data.bulkPrices[0].moq : 0
  );
  const [IDX, setIDEX] = useState(0);
  const [isDisabled, setIsDisDisabled] = useState(false);

  const dispatch = useDispatch();
  const { location } = useRouter();
  const { pathname } = location;
  const pattern = `(.*)?${"/actions/merchbuy/product/:id"}`;
  const match = matchPath(pathname, { path: pattern }) || {};

  const userId = useSelector((state) => state.user.userId);
  const productIndex = process.env.REACT_APP_ALGOLIA_SEARCH_INDEX;

  useEffect(() => {
    if (data.bulkPrices) setSelectedMoq(data.bulkPrices[0]?.moq);
  }, [data]);

  const setPosition = (val) => {
    for (let i = 0; i < data?.bulkPrices?.length; i++) {
      if (!data.bulkPrices[i + 1]) {
        return setIDEX(i);
      } else if (
        val >= data.bulkPrices[i]?.moq &&
        val < data.bulkPrices[i + 1]?.moq
      ) {
        return setIDEX(i);
      }
    }
  };

  const handleAddToCart = () => {
    const locationQuery = new URLSearchParams(location.search);
    // we need to fire different events so we know where the user is coming from
    // if the user started his journey from the landing page, then the event name should be different
    // if it is from the search page, then the event name should be different too
    const queryId = locationQuery.get("queryId");

    const eventType = queryId
      ? "convertedObjectIDsAfterSearch"
      : "convertedObjectIDs";
    const eventName = queryId
      ? "Product Added to Cart after Search"
      : "Product Added to Cart";

    const eventProperties = {
      objectIDs: [match.params.id],
      userToken: userId,
      index: productIndex,
      eventName,
    };

    if (queryId) {
      eventProperties.queryID = queryId;
    }

    aa(eventType, {
      ...eventProperties,
    });

    const { bulkPrices } = data || {};
    const selectedBulkPrices = bulkPrices[IDX];
    const { price, moq } = selectedBulkPrices || {};
    const formatedPrice = formatPrice(price || 0);

    data.quantity = selectedMoq;
    data.productId = data.id;
    data.shopId = data.branchId;

    setIsDisDisabled(true);

    mixPanel.track(COMPLETE_ADD_TO_CART, {
      "Product id": data.id,
      "Product name": MerchbuyAddtoCartPopup.name,
      "Product quantity": selectedMoq,
      "Product unit price": formatedPrice,
      "Product volume price pair": `${moq}: ${formatedPrice}`,
      "Shop id": shopDetails.id,
      "Shop name": shopDetails.name,
      Time: new Date().toLocaleString(),
    });

    dispatch(confirm(data));
    setIsDisDisabled(true);
  };

  return (
    <PopUp open={open} padding={"0px"}>
      <Overlay onClick={cancel} bgc={"rgba(0,0,0,.4)"} />
      <PopUpContent width={"375px"} height={"327px"} radius={"3px"}>
        <ProductBlock>
          <ProductImage
            src={
              is_url(data.images ? data.images.baseImageUrl : "")
                ? data.images.baseImageUrl
                : FallbackProductImage
            }
            alt="product image"
          />
          <ProductInfo>
            <ProductDesc>
              <ProductDescText>
                {" "}
                {data.name} <br />{" "}
              </ProductDescText>
              <PriceHolder>
                {" "}
                {data.bulkPrices &&
                  (formatPrice(data?.bulkPrices[IDX]?.price || 0) +
                    ("/" + data?.unitValue) ||
                    "/Unit")}
              </PriceHolder>
            </ProductDesc>
          </ProductInfo>
        </ProductBlock>
        <hr
          style={{
            margin: "-10px auto 16px",
            width: "90%",
            backgroundColor: "#EDF2F7",
            border: "solid 1px #EDF2F7",
          }}
        />

        <OuterQuantityHold>
          <QuantityHold>
            <Title> Select Quantity</Title>
            <MoqParagraph>
              MINIMUM ORDER QUANTITY:{" "}
              {data.moq + " " + (data.unitValue || "Units")}
            </MoqParagraph>
          </QuantityHold>

          <UpdateCalculatorBlock>
            <ModifiedSubstractIcon
              onClick={() => {
                setSelectedMoq(
                  parseInt(selectedMoq) - 1 <= data.moq
                    ? data.moq
                    : parseInt(selectedMoq) - 1
                );
                setPosition(parseInt(selectedMoq) - 1);
              }}
            />
            <QuantityDisplay>
              <CurrentQuantity
                value={selectedMoq}
                type={"number"}
                onChange={(e) => {
                  setSelectedMoq(
                    e.target.value < 0
                      ? parseInt(data.moq)
                      : parseInt(e.target.value)
                  );
                  setPosition(
                    e.target.value < 0
                      ? parseInt(data.moq)
                      : parseInt(e.target.value)
                  );
                }}
              />
            </QuantityDisplay>
            <ModifiedAddIcon
              onClick={() => {
                setSelectedMoq(parseInt(selectedMoq) + 1);
                setPosition(parseInt(selectedMoq) + 1);
              }}
            />
          </UpdateCalculatorBlock>
        </OuterQuantityHold>

        <ActionBlock
          direction={"row"}
          top={"50px"}
          bottom="10px"
          style={{ padding: "0 16px" }}
        >
          <CancelButton
            type={"button"}
            onClick={cancel}
            border
            color="#227EFF"
            weight="500"
          >
            Cancel
          </CancelButton>
          <ConfirmButton
            disabled={isDisabled || selectedMoq < data.moq || !selectedMoq}
            onClick={handleAddToCart}
            bgColor="#227EFF"
            color="#fff"
            weight="500"
          >
            Continue
          </ConfirmButton>
        </ActionBlock>
      </PopUpContent>
    </PopUp>
  );
};

MerchbuyAddtoCartPopup.propTypes = {
  open: bool,
  cancel: func,
  data: object,
  shopDetails: object,
  confirm: func,
};
