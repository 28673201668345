import React, { Fragment } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import styled from "styled-components";

import { Loader, TopHeader } from "../../../components";
import { ScreenContainer } from "../../../containers/ScreenContainer";
import {
  newResendCode,
  requestOtp,
} from "../../../redux/ducks/auth/check/actions";
import { colors } from "../../../styles";
import DesktopBackgroundLayout from "../../DesktopBackgroundLayout";
// import { ReactComponent as CallImage } from "../check/assets/call-img.svg";
import { ReactComponent as OtpImage } from "../check/assets/otp-img.svg";
import { ReactComponent as TextMessageImage } from "../check/assets/text-message.svg";
import { ReactComponent as WhatsappImage } from "../check/assets/whatsapp-img.svg";

const StyledOtpSvg = styled(OtpImage)`
  display: flex;
  justify-content: center;
  margin: 41px auto auto auto;
  width: 72px;
  height: 72px;
`;

const StyledWhatsapPSvg = styled(WhatsappImage)`
  display: flex;
  justify-content: center;
  margin: 40px auto auto auto;
`;
// const StyledCallSvg = styled(CallImage)`
//   display: flex;
//   justify-content: center;
//   margin: 16px auto auto auto;
// `;
const StyledTextMessageSvg = styled(TextMessageImage)`
  display: flex;
  justify-content: center;
  margin: 16px auto auto auto;
`;

const MainText = styled.span`
  color: ${colors.themeTextColor10};
  font-weight: 700;
  font-size: 24px;
  justify-content: center;
  display: flex;
  margin: 16px auto auto auto;
`;
const style = {
  color: `${colors.themeTextColor11}`,
  fontWeight: "400",
  fontSize: "14px",
  justifyContent: "center",
  marginLeft: "15px",
};

const SubText = styled.div`
  color: ${colors.themeTextColor11};
  font-weight: 400;
  font-size: 14px;
  justify-content: center;
  margin: 4px auto auto 39px;
`;

export const OtpType = {
  ONBOARDING: "onboarding",
  FORGOTPASSWORD: "forgot password",
  NEWDEVICELOGIN: "new device login",
  VERIFYMERCHANT: "verify merchant",
  WALLETPIN: "wallet pin",
};

export const OTPCHANNELS = {
  SMS: "SMS",
  VOICE: "VOICE",
  WHATSAPP: "WHATSAPP",
};

export const ACTIONTYPES = {
  ONBOARDING: "ONBOARDING",
  RESET_LOGIN_PIN: "RESET_LOGIN_PIN",
  AGENT_CHECKOUT: "AGENT_CHECKOUT",
};

const OtpOptions = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector((state) => state.auth.check.isLoading);
  const resend = location?.state?.resend;
  const msisdn = useSelector((state) => state.user.msisdn);

  const whatsapp = async () => {
    if (resend) {
      let id = await dispatch(newResendCode("WHATSAPP"));
      id && history.push("/check");
    } else {
      requestOTP(ACTIONTYPES.ONBOARDING, OTPCHANNELS.WHATSAPP);
    }
  };

  // const voice = async () => {
  //   if (resend) {
  //     let id = dispatch(newResendCode("VOICE"));
  //     id && history.push("/check");
  //   } else {
  //     requestOTP(ACTIONTYPES.ONBOARDING, OTPCHANNELS.VOICE);
  //   }
  // };

  const sms = async () => {
    if (resend) {
      let id = dispatch(newResendCode("SMS"));
      id && history.push("/check");
    } else {
      requestOTP(ACTIONTYPES.ONBOARDING, OTPCHANNELS.SMS);
    }
  };

  const requestOTP = async (actionType, channel) => {
    const res = await dispatch(requestOtp(actionType, channel, msisdn));
    res && history.push({ pathname: "/check", state: { channel, actionType } });
  };

  return isLoading ? (
    <Loader />
  ) : (
    <Fragment>
      <DesktopBackgroundLayout>
        <TopHeader title="OTP Verification" />
        <ScreenContainer top={"64px"}>
          <StyledOtpSvg />
          <MainText>Almost there!</MainText>
          <SubText>
            Let's verify your phone number. Kindly select <br />
            <span style={style}>an option so we can send you an OTP</span>
          </SubText>

          <StyledWhatsapPSvg onClick={whatsapp} />
          {/* <StyledCallSvg onClick={voice} /> */}
          <StyledTextMessageSvg onClick={sms} />
        </ScreenContainer>
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default OtpOptions;
