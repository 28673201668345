import React, { Fragment, useState, useEffect } from "react";

import { string, array, bool } from "prop-types";
import styled from "styled-components";

import { SubTitle } from "../../containers/MessageContainer";
import TransactionCard from "../../views/home/transactions/transaction-card";

import { ReactComponent as DownwardsIcon } from "./assets/down.svg";
import { ReactComponent as UpwardsIcon } from "./assets/upward.svg";

const DropdownBodyInactive = styled.div`
  position: relative;
  padding: 16px;
  background-color: #f2f5fa;
`;

const DropdownBodyActive = styled.div`
  position: relative;
  padding: 0 16px 16px 16px;
`;

const DropdownTitle = styled(SubTitle)`
  color: #718596;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400px;
`;

const DropdownTitleActive = styled(DropdownTitle)`
  margin: 16px 0;
`;

const DropdownContainer = styled.div`
  margin-top: ${({ top }) => top || null};
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

export const DropdownList = ({
  who,
  title,
  top,
  transactionList,
  subHeading,
  detailsLink,
  customList = false,
  children,
  index,
  text = "transactions",
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (index === 0) setOpen(true);
  }, [setOpen, index]);

  return (
    <DropdownContainer top={top}>
      {!open ? (
        <DropdownBodyInactive>
          <HeaderWrapper onClick={() => setOpen(!open)}>
            <DropdownTitle>{`${title} (${transactionList.length} ${text})`}</DropdownTitle>
            <DownwardsIcon />
          </HeaderWrapper>
        </DropdownBodyInactive>
      ) : (
        <DropdownBodyActive>
          <HeaderWrapper onClick={() => setOpen(!open)}>
            <DropdownTitleActive>{title}</DropdownTitleActive>
            <UpwardsIcon />
          </HeaderWrapper>
          {customList === false ? (
            transactionList &&
            transactionList.map((transaction, index) => (
              <TransactionCard
                transaction={transaction}
                key={transaction.transactionReference}
              />
            ))
          ) : (
            <Fragment>{children}</Fragment>
          )}
        </DropdownBodyActive>
      )}
    </DropdownContainer>
  );
};

DropdownList.propTypes = {
  transactionList: array,
  title: string,
  subHeading: string,
  customList: bool,
  detailsLink: string,
};
