import { formatPrice } from "./formatPriceWithComma";
import { unparseBalance } from "./parseBalance";

export const calculateCapitalization = (members) => {
  const membersCommissions = members.map((member) =>
    unparseBalance(member.commission)
  );

  const membersCapitalization = membersCommissions.reduce((sum, current) => {
    return (sum += current);
  }, 0);

  return formatPrice(membersCapitalization || 0);
};
