import React, { useState, Fragment } from "react";

import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";

import { RippleButton } from "../../../../../components";
import { LightButton } from "../../../../../components/button";
import { BlurBackground } from "../../../../../containers/BlurBackground";
import { List } from "../../../../../containers/ListContainer";
import { orderStatus } from "../../../../../enums";
import {
  saveLastActionUrl,
  redirectedFromMerchbuy,
} from "../../../../../redux/ducks/account/wallet/actions";
import { merchbuyActions } from "../../../../../redux/ducks/applications/merchbuy/actions";
import { colors } from "../../../../../styles";
import { formatPrice } from "../../../../../utils/currency/formatPriceWithComma";
import History from "../../../../../utils/History";
import { ReactComponent as Check } from "../../assets/radio.svg";
import { ReactComponent as UnCheck } from "../../assets/radio_uncheck.svg";
import { Space } from "../../styles";
import { ReactComponent as ActivateIcon } from "../assets/activate_icon.svg";
// import { ReactComponent as Debit } from '../assets/debit_card.svg';
import { ReactComponent as Delivery } from "../assets/pay_on_delivery.svg";
// import { ReactComponent as Wallet } from '../assets/spaces_wallet.svg';
import { PaymentInfo } from "../payment-info";

const FooterWrapper = styled.div`
  background: ${colors.lightestGray};
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1em;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
`;

const PaymentOptionWrapper = styled.div`
  min-height: 56px;
  max-height: 73px;
  border-radius: 4px;
  background: ${colors.white};
  margin: 0 2em 8px 2em;
  border: 1px solid ${({ borderColor }) => borderColor || colors.gray4};
`;

const DetailWrapper = styled.div`
  display: grid;
  gap: 6px;
`;

const Text = styled.p`
  margin: ${({ margin }) => margin || "0"};
  font-weight: ${({ fontWeight }) => fontWeight || "normal"};
  font-size: ${({ font }) => font || "12px"};
  color: ${({ color }) => color || null};
  text-align: ${({ align }) => align || null};
  width: ${({ width }) => width || null};
  padding-top: ${({ top }) => top || null};
  text-align: ${({ align }) => align || null};
`;

const TextBalance = styled.span`
  color: ${colors.red};
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-contant: flex-start;
`;

const BlurPopUpBackground = styled(BlurBackground)`
  bottom: 0;
  position: absolute;
`;

const WalletActivationContainer = styled.div`
  height: 299px;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  background-color: white;
  border-radius: 15px 21px 0 0;
  padding: 42px 21px 32px 24px;
`;

const ActivateWalletText = styled.h6`
  color: ${colors.textActivate};
  font-size: 12px;
  font-weight: 400;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 12px;
`;

const DeactivateTitleText = styled.h3`
  color: ${colors.bgBlue};
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 16px;
`;

const DeactivateTitleDescription = styled.h5`
  color: ${colors.lightDark};
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
`;

const Payment = ({
  priceInfo,
  setPaymentMethod,
  setStep,
  deliveryDetails,
  setOpenFeatureFixes,
}) => {
  const dispatch = useDispatch();
  // const realms = useSelector((state) => state.user.realmNames);
  const walletBalance = useSelector(
    (state) => state.account.wallet.balance || 0
  );
  const walletStatus = useSelector((state) => state.account.wallet.status);
  const features = useSelector((state) => state.user.features);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  const [canUserPayWithWallet] = useState(
    walletBalance >= priceInfo.totalPrice
  );
  const [openActivatePopup, setOpenActivatePopup] = useState(false);
  const paymentMethods = [
    // {
    //     value: 'wallet',
    //     name: 'Spaces Wallet',
    //     balance: walletBalance,
    //     icon: <Wallet />,
    //     enum: 3,
    // featureName: 'payWithWallet'
    // },
    // {
    //     icon: <Debit />,
    //     value: 'paystack',
    //     name: 'Debit / Credit Card',
    //     enum: 2,
    // featureName: 'payWithCard'
    // },
    {
      icon: <Delivery />,
      name: "Pay on Delivery",
      value: "pod",
      enum: 1,
      featureName: "payOnDelivery",
    },
  ];

  // realms &&
  //     !realms.includes('PAYMENT ON DELIVERY') &&
  //     paymentMethods.push({
  //         icon: <Delivery />,
  //         name: 'Pay on Delivery',
  //         value: 'pod',
  //         enum: 1,
  //     });

  const handleOnContinue = () => {
    if (selectedPaymentMethod.value === "wallet" && walletStatus !== "ACTIVE") {
      setOpenActivatePopup(true);
    } else if (
      selectedPaymentMethod.value === "wallet" &&
      !canUserPayWithWallet
    ) {
      toast.error("Insufficient balance in wallet. Please top-up your wallet");
    } else {
      setPaymentMethod(selectedPaymentMethod);
      setStep(orderStatus.summary);
    }
  };

  const clickAction = (method) => {
    if (
      (method.value === "wallet" && !features?.wallet?.status) ||
      !features?.[method?.featureName]?.status
    ) {
      return setOpenFeatureFixes(true);
    }
    setSelectedPaymentMethod(method);
  };

  return (
    <Fragment>
      <List noBorderBottom={false}>
        <Text margin="16px 16px 8px" color={colors.subGrey} fontWeight="700">
          PAYMENT METHOD
        </Text>
      </List>
      <List fullScreen>
        {paymentMethods &&
          paymentMethods.map((method) => (
            <PaymentOptionWrapper
              key={method.value}
              onClick={() => clickAction(method)}
              borderColor={
                selectedPaymentMethod &&
                selectedPaymentMethod.value === method.value
                  ? colors.deepBlue
                  : null
              }
            >
              <ContentWrapper>
                <DetailWrapper>
                  <TextWrapper>
                    {method.icon}
                    <Text
                      margin="0 0 0 15px"
                      color={colors.boldDark}
                      font="14px"
                    >
                      {method.name}
                    </Text>
                  </TextWrapper>
                  {method.balance >= 0 && (
                    <Text margin="2px 0" color={colors.lightDark}>
                      Balance{" "}
                      <TextBalance>{formatPrice(method.balance)}</TextBalance>
                    </Text>
                  )}
                </DetailWrapper>
                {selectedPaymentMethod &&
                selectedPaymentMethod.value === method.value ? (
                  <Check />
                ) : (
                  <UnCheck />
                )}
              </ContentWrapper>
            </PaymentOptionWrapper>
          ))}
        <Space height={"16px"} />
      </List>

      <PaymentInfo priceInfo={priceInfo} />
      <Space color={colors.lightestGray} height="100px" />
      <FooterWrapper>
        <RippleButton
          disabled={selectedPaymentMethod ? false : true}
          onClick={handleOnContinue}
        >
          Proceed to Summary
        </RippleButton>
        <Space color={colors.lightestGray} height="38px" />
      </FooterWrapper>

      {openActivatePopup && (
        <BlurPopUpBackground onClick={() => setOpenActivatePopup(false)}>
          <WalletActivationContainer>
            <IconWrapper>
              <ActivateIcon />
              <ActivateWalletText>Activate your wallet</ActivateWalletText>
            </IconWrapper>
            <DeactivateTitleText>
              Your wallet has not been activated
            </DeactivateTitleText>
            <DeactivateTitleDescription>
              Provide more information about yourself so we can help verify and
              activate your wallet.
            </DeactivateTitleDescription>
            <LightButton
              onClick={() => {
                dispatch(saveLastActionUrl("/actions/merchbuy/checkout"));
                dispatch(redirectedFromMerchbuy(true));
                dispatch(
                  merchbuyActions.saveLastDeliveryAddress(deliveryDetails)
                );
                History.push("/user/account_bank_data");
              }}
              size="16px"
              weight="500"
              color={colors.white}
              background={colors.deepBlue}
            >
              Activate Wallet
            </LightButton>
          </WalletActivationContainer>
        </BlurPopUpBackground>
      )}
    </Fragment>
  );
};

export default Payment;
