import React, { Fragment } from "react";

import { useLocation } from "react-router-dom";
import styled from "styled-components";

import PhoneIcon from "../../../../assets/phone.svg";
import ShopIcon from "../../../../assets/shop-new.svg";
import SMSIcon from "../../../../assets/sms-new.svg";
import WhatsAppIcon from "../../../../assets/whatsapp.svg";
import { TopHeader, UserAvatar, PageLogo } from "../../../../components";
import {
  DetailsName,
  DetailsDate,
  DetailsContactOption,
  DetailsContactLink,
  DetailsContactText,
  DetailsBusinessDescription,
  DetailsDescriptionBody,
  DetailsBusinessDescriptionHeading,
} from "../../../../containers/DetailsContainer";
import {
  ListHeading,
  ListLeftBlock,
} from "../../../../containers/ListContainer";
import {
  SecondaryText,
  SubTitle,
} from "../../../../containers/MessageContainer";
import {
  ScreenContainer,
  FlexCenteredBlock,
  FlexDiv,
} from "../../../../containers/ScreenContainer";
import { colors } from "../../../../styles";
import { formatCreationDate } from "../../../../utils/date/formatCreationDate";
import History from "../../../../utils/History";
import DesktopBackgroundLayout from "../../../DesktopBackgroundLayout";

import BusinessEmailIcon from "./assets/blue-email.svg";
import BusinessCallIcon from "./assets/blue-phone.svg";
import BusinessSmsIcon from "./assets/blue-sms.svg";
import BusinessWhatsappIcon from "./assets/blue-whatsapp.svg";

const DetailContactWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin: 15px;
  align-items: center;
`;

export const LabelsList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(74px, min-content));
  grid-column-gap: 24px;
  grid-row-gap: 16px;
`;

export const BusinessCategoriesList = styled.ul`
  margin: 8px 0;
`;

export const InfoCategoryHeading = styled(SecondaryText)`
  color: ${({ color }) => color || colors.themeColor6};
  margin-top: ${({ top }) => top};
  margin-bottom: ${({ bottom }) => bottom || "13px"};
`;

export const Space = styled.div`
  height: ${({ height }) => height || "10px"};
`;

export const ShopNameContainer = styled(DetailsBusinessDescriptionHeading)`
  margin-bottom: 25px;
  padding: 0;
`;

export const ShopName = styled(DetailsContactText)`
  margin-left: 8px;
`;

export const HorizontalLine = styled.hr`
  height: 2px;
  background-color: #e9e9e9;
  margin-bottom: 16px;
`;

export const StyledFlexDiv = styled(FlexDiv)`
  & > a:not(:first-child) {
    margin-right: ${({ right }) => right || "16px"};

    &:nth-child(2) {
      margin-left: auto;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

export const BusinessLink = styled.a``;

const MerchantDetails = () => {
  let location = useLocation();
  const merchant = location.state;
  if (!merchant) History.push("/");
  const businessProfile = merchant && merchant.businessProfile;

  return (
    <Fragment>
      <DesktopBackgroundLayout>
        <TopHeader
          title={"Merchant Details"}
          size="14px"
          weight="400"
          noBorderBottom
          color="#071827"
        />
        <ScreenContainer>
          <FlexCenteredBlock top={"20px"} mtop={"20px"}>
            <UserAvatar
              width={"72px"}
              height={"72px"}
              avatar={merchant && merchant.avatar}
            />
            <DetailsName fontSize="16px" bottom="0" color="#071827">
              {merchant && merchant.firstName} {merchant && merchant.lastName}
            </DetailsName>
            <DetailsDate weight="400" color="#718596">
              Last seen: {formatCreationDate(merchant && merchant.lastSeen)}
            </DetailsDate>
            <DetailContactWrapper right="48px">
              <DetailsContactOption>
                <DetailsContactLink
                  maxWidth="100%"
                  href={`tel:${merchant && merchant.msisdn}`}
                >
                  <PageLogo
                    width={"40px"}
                    height={"40px"}
                    iconHeight={"20px"}
                    iconWidth={"20px"}
                    Icon={PhoneIcon}
                  />
                  <DetailsContactText
                    fontSize="12px"
                    weight="400"
                    color="#56636D"
                  >
                    Call Merchant
                  </DetailsContactText>
                </DetailsContactLink>
              </DetailsContactOption>
              <DetailsContactOption>
                <DetailsContactLink
                  maxWidth="100%"
                  href={`sms:${merchant && merchant.msisdn}`}
                >
                  <PageLogo
                    background={"#FDEDE7"}
                    width={"40px"}
                    height={"40px"}
                    iconHeight={"20px"}
                    iconWidth={"20px"}
                    Icon={SMSIcon}
                  />
                  <DetailsContactText
                    fontSize="12px"
                    weight="400"
                    color="#56636D"
                  >
                    Send SMS
                  </DetailsContactText>
                </DetailsContactLink>
              </DetailsContactOption>
              <DetailsContactOption>
                <DetailsContactLink
                  maxWidth="100%"
                  href={`whatsapp://send?phone=${merchant && merchant.msisdn}`}
                >
                  <PageLogo
                    background={"#D3FDE9"}
                    width={"40px"}
                    height={"40px"}
                    iconHeight={"22px"}
                    iconWidth={"22px"}
                    Icon={WhatsAppIcon}
                  />
                  <DetailsContactText
                    fontSize="12px"
                    weight="400"
                    color="#56636D"
                  >
                    Whatsapp
                  </DetailsContactText>
                </DetailsContactLink>
              </DetailsContactOption>
            </DetailContactWrapper>
          </FlexCenteredBlock>
          <Space height={"30px"} />
          <ShopNameContainer>
            <PageLogo
              background="#E3E9FC"
              width={"32px"}
              height={"32px"}
              iconHeight={"22px"}
              iconWidth={"18px"}
              borderRadius="4px"
              Icon={ShopIcon}
            />
            <ShopName fontSize="12px" weight="400" color="#718596">
              {businessProfile && businessProfile.businessName}
            </ShopName>
          </ShopNameContainer>
          <DetailsBusinessDescriptionHeading padding="0" left="0">
            <ListHeading color="#1F2138" weight="700">
              User Information
            </ListHeading>
          </DetailsBusinessDescriptionHeading>
          <DetailsBusinessDescription top="15px" radius="4px">
            <DetailsDescriptionBody color="#718596" left={"16px"} right="16px">
              <InfoCategoryHeading top="8px">ADDRESS</InfoCategoryHeading>
              <HorizontalLine />
              <SubTitle
                weight="400"
                color="#071827"
                lineHeight="21px"
                style={{ margin: "0" }}
              >
                {businessProfile && businessProfile.streetAddress}
                <br />
                {businessProfile && businessProfile.lga} Municipalities
                <br />
                {businessProfile && businessProfile.state} Province
              </SubTitle>
            </DetailsDescriptionBody>
            <DetailsDescriptionBody color="#718596" left={"16px"} right="16px">
              <InfoCategoryHeading top="32px">CONTACT</InfoCategoryHeading>
              <HorizontalLine />
              <StyledFlexDiv>
                <ListLeftBlock left="0">
                  <SubTitle
                    weight="400"
                    color="#071827"
                    lineHeight="21px"
                    style={{ margin: "0" }}
                  >
                    {businessProfile && businessProfile.email}
                  </SubTitle>
                  <SecondaryText color="#718596" top="0" weight="400">
                    E-mail Address
                  </SecondaryText>
                </ListLeftBlock>
                <BusinessLink
                  href={`mailto:${
                    businessProfile && businessProfile.businessPhoneNumber
                  }`}
                >
                  <PageLogo
                    background={"#E9F2FF"}
                    width={"36px"}
                    height={"36px"}
                    iconHeight={"16.2px"}
                    iconWidth={"18px"}
                    Icon={BusinessEmailIcon}
                  />
                </BusinessLink>
              </StyledFlexDiv>
              <HorizontalLine />
              <StyledFlexDiv>
                <ListLeftBlock left="0">
                  <SubTitle
                    weight="400"
                    color="#071827"
                    lineHeight="21px"
                    style={{ margin: "0" }}
                  >
                    {businessProfile && businessProfile.businessPhoneNumber}
                  </SubTitle>
                  <SecondaryText color="#718596" top="0" weight="400">
                    Business Phone Number
                  </SecondaryText>
                </ListLeftBlock>
                <BusinessLink
                  href={`tel:${
                    businessProfile && businessProfile.businessPhoneNumber
                  }`}
                >
                  <PageLogo
                    background={"#E9F2FF"}
                    width={"36px"}
                    height={"36px"}
                    iconHeight={"18px"}
                    iconWidth={"18px"}
                    Icon={BusinessCallIcon}
                  />
                </BusinessLink>
                <BusinessLink
                  href={`sms:${
                    businessProfile && businessProfile.businessPhoneNumber
                  }`}
                >
                  <PageLogo
                    background={"#E9F2FF"}
                    width={"36px"}
                    height={"36px"}
                    iconHeight={"18px"}
                    iconWidth={"18px"}
                    Icon={BusinessSmsIcon}
                  />
                </BusinessLink>
                <BusinessLink
                  href={`whatsapp://send?phone=${
                    businessProfile && businessProfile.businessPhoneNumber
                  }`}
                >
                  <PageLogo
                    background={"#E9F2FF"}
                    width={"36px"}
                    height={"36px"}
                    iconHeight={"23px"}
                    iconWidth={"23px"}
                    Icon={BusinessWhatsappIcon}
                  />
                </BusinessLink>
              </StyledFlexDiv>
            </DetailsDescriptionBody>
          </DetailsBusinessDescription>
          <Space height={"50px"} />
        </ScreenContainer>
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default MerchantDetails;
