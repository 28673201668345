import React, { Fragment } from "react";

import { string, bool, func } from "prop-types";
import styled from "styled-components";

import { colors } from "../../styles";
import { RippleButton } from "../button/index";

import bg from "./assets/background.svg";
import { ReactComponent as FailedIcon } from "./assets/failed_icon.svg";
import { ReactComponent as SuccessIcon } from "./assets/success_image.svg";

const ChildrenWrapper = styled.div`
  width: 100%;
  padding: 24px 16px 24px 16px;
`;

const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1100;
  justify-content: center;
  position: ${({ position }) => position || "absolute"};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url(${(props) => props.background});

  @media (max-width: 576px) {
    position: ${({ mposition }) => mposition || "fixed"};
  }
`;

const ScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 16px 16px 24px 16px;
`;

const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 8px;
  font-weight: ${({ weight }) => weight || "500"};
  text-align: center;
  color: ${colors.white};
  line-height: 36px;
  margin-top: 24px;
`;

const SubTitle = styled.h3`
  font-size: ${({ size }) => size || "14px"};
  font-weight: 400;
  text-align: center;
  color: ${colors.aliceBlue};
  line-height: 21px;
  margin-bottom: 21px;
  padding: 9px 30px 0 30px;
`;

export const SuccessPage = ({
  icon: Icon,
  title,
  subtitle,
  position,
  mposition,
  weight,
  size,
  children,
  successfull = true,
  showDetailButton = false,
  doneText = "Done",
  onDoneClick,
  onDetailClick,
  detailText,
}) => {
  return (
    <Fragment>
      <Overlay background={bg} position={position} mposition={mposition}>
        <ScreenContainer>
          {Icon ? <Icon /> : successfull ? <SuccessIcon /> : <FailedIcon />}
          <Title weight={weight}>{title}</Title>
          <SubTitle size={size}>{subtitle}</SubTitle>
          <ChildrenWrapper>{children}</ChildrenWrapper>
        </ScreenContainer>
        <ButtonWrapper>
          <RippleButton
            type="button"
            radius="4px"
            backgroundColor={colors.white}
            color={colors.deepBlue}
            onClick={() => onDoneClick && onDoneClick()}
          >
            {doneText}
          </RippleButton>
          {showDetailButton && (
            <RippleButton
              type="button"
              radius="4px"
              top="24px"
              backgroundColor="transparent"
              color={colors.white}
              onClick={() => onDetailClick && onDetailClick()}
            >
              {detailText ? detailText : "View transaction Details"}
            </RippleButton>
          )}
        </ButtonWrapper>
      </Overlay>
    </Fragment>
  );
};

SuccessPage.propTypes = {
  title: string,
  subtitle: string,
  showDetailButton: bool,
  onDoneClick: func,
  onDetailClick: func,
};
