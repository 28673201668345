import React from "react";

import { useSelector } from "react-redux";

import { RippleLink } from "../../../../../components";
import { BuyIcon, NoOfItemsOnCart, Circle } from "../../styles";

export const Cart = () => {
  const cartItems = useSelector(
    (state) => state.applications.merchbuy.shopCart
  );
  return (
    <RippleLink to={"/actions/merchbuy/cart"}>
      <Circle>
        <BuyIcon />
      </Circle>
      <NoOfItemsOnCart>{cartItems ? cartItems.length : 0}</NoOfItemsOnCart>
    </RippleLink>
  );
};
