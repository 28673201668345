import React, { Fragment } from "react";

import { Carousel } from "react-responsive-carousel";
import { useHistory } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styled from "styled-components";

import { RippleButton } from "../../components";
import DesktopBackgroundLayout from "../DesktopBackgroundLayout";

import FirstOnboarding from "./assets/Onboarding-1.png";
import SecondOnboarding from "./assets/Onboarding-2.png";
import ThirdOnboarding from "./assets/Onboarding-3.png";
import FourthOnboarding from "./assets/Onboarding-4.png";

const StyleOverrideCarousel = styled(Carousel)`
  .carousel .slide img {
    width: inherit;
  }

  .carousel .control-dots {
    position: absolute;
    bottom: auto;
    margin-top: 50px;
    top: 330px;
  }

  .carousel .control-dots .dot {
    transition: opacity 0.25s ease-in;
    opacity: 1;
    box-shadow: none;
    cursor: pointer;
    margin: 0 4px;
    width: 6px;
    height: 6px;
    background: #d6e6f0;
  }

  .carousel .control-dots .dot.selected {
    box-shadow: none;
    width: 18px;
    height: 6px;
    background: #fe8f49;
    border-radius: 4px;
  }
`;

const SliderHolder = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: ${({ padding }) => padding || "52px 16px 60px"};
  background: ${({ background }) => background || "#F9F9FA"};
  min-height: ${({ minHeight }) => minHeight || "100vh"};
  overflow: hidden;
`;

const CarouselLayout = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SliderImageWrapper = styled.div`
  position: relative;
  margin-bottom: 40px;
  justify-content: ${({ content }) => content || null};
  right: ${({ right }) => right || null};
  height: ${({ height }) => height || null};
  text-align: ${({ textAlign }) => textAlign || null};
  max-height: 290px;
`;

const SliderImage = styled.img`
  display: flex;
  align-items: center;
  position: relative;
  width: ${({ width }) => width || "100%"};
`;

const SliderTitle = styled.div`
  display: flex;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  justify-content: center;
  text-align: center;
  color: #003277;
  margin-top: 40px;
`;

const SliderSubTitle = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  padding: 16px 15px;
  text-align: center;
  color: #718596;
`;

const ContentLayout = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 40px;
  @media (max-width: 425px) {
    height: 30vh;
  }
  @media (max-width: 375px) {
    height: 20vh;
  }
`;
const ButtonWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 375px;
  width: inherit;
  padding: 15px 15px 40px;
  background: #f9f9fa;
`;

const carouselItem = [
  {
    id: 1,
    image: <SliderImage src={FirstOnboarding} alt="Spaces Onboarding" />,
    title: "Create your shop",
    subTitle:
      "Start your shop and sell products for your sales as simple as ABC.",
    content: "flex-end",
    right: "-16px",
    textAlign: "right",
  },
  {
    id: 2,
    image: <SliderImage src={SecondOnboarding} alt="Spaces Onboarding" />,
    title: "Manage your inventory",
    subTitle:
      "We constantly try to simplify what you need to manage your inventory in your shop",
    content: "flex-end",
    right: "-16px",
    textAlign: "right",
  },
  {
    id: 3,
    image: <SliderImage src={ThirdOnboarding} alt="Spaces Onboarding" />,
    title: "Buy in bulk",
    subTitle:
      "A wide variety of products to help you stock up directly from distributors at the best prices",
    content: "center",
    right: "",
    textAlign: "",
  },
  {
    id: 4,
    image: <SliderImage src={FourthOnboarding} alt="Spaces Onboarding" />,
    title: "Manage your Earnings",
    subTitle: "Keep track of how much you are making from your business",
    content: "center",
    right: "",
    textAlign: "",
  },
];

export const GetStarted = () => {
  const history = useHistory();

  return (
    <Fragment>
      <DesktopBackgroundLayout>
        <CarouselLayout>
          <StyleOverrideCarousel
            showThumbs={false}
            showArrows={false}
            showStatus={false}
            swipeable
            autoPlay
            infiniteLoop
          >
            {carouselItem.map((carousel) => (
              <SliderHolder key={carousel.id}>
                <SliderImageWrapper
                  content={carousel.content}
                  right={carousel.right}
                  textAlign={carousel.textAlign}
                >
                  {carousel.image}
                </SliderImageWrapper>
                <ContentLayout>
                  <SliderTitle>{carousel.title}</SliderTitle>
                  <SliderSubTitle>{carousel.subTitle}</SliderSubTitle>
                </ContentLayout>
              </SliderHolder>
            ))}
          </StyleOverrideCarousel>
          <ButtonWrapper>
            <RippleButton
              top={"auto"}
              onClick={() => history.push("/phone-signup")}
            >
              Get Started
            </RippleButton>
          </ButtonWrapper>
        </CarouselLayout>
      </DesktopBackgroundLayout>
    </Fragment>
  );
};
