import React from "react";

import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar, utils } from "react-modern-calendar-datepicker";
import styled from "styled-components";

import { colors } from "../../../../../styles";
import arrowLeft from "../assets/arrowLeft.svg";
import arrowRight from "../assets/arrowRight.svg";

const CalendarWrapper = styled.div`
  background: ${colors.white};
  padding: 16px;
  .Calendar {
    box-shadow: none;
    font-size: 12px;
    padding-top: 0;
    width: 100%;
    max-width: 100%;
    min-height: 250px;
  }
  .Calendar__header {
    padding: 0;
  }
  .Calendar__weekDays {
    padding: 0;
    margin-top: 32px;
    font-size: 12px;
    color: ${colors.black};
    font-weight: 500;
    line-height: 18px;
    text-align: center;
  }
  .Calendar__monthYear {
    font-size: 14px;
  }
  .Calendar__monthYear > * {
    padding: 0;
  }
  .Calendar__section.-hiddenPrevious {
    padding: 0;
    opacity: 0;
  }
  .Calendar__section.-hiddenNext {
    padding: 0;
    opacity: 0;
  }
  .Calendar__section.-shown {
    padding: 0;
  }
  .Calendar__day.-ltr {
    font-size: 12px;
    padding: 13px;
    height: 46px;
    width: 46px;
  }
  .Calendar__day.-selected {
    border-radius: 0;
  }
  abbr[title] {
    text-decoration: none;
  }
  .Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween) {
    font-weight: 400;
  }
  .Calendar__monthArrow {
    background-image: url("${arrowLeft}");
  }
  .Calendar__monthArrowWrapper.-left .Calendar__monthArrow {
    background-image: url("${arrowRight}");
  }
  .Calendar__monthArrowWrapper.-right {
    transform: none;
  }
  .Calendar__monthArrowWrapper.-left {
    transform: none;
  }
  .Calendar__sectionWrapper {
    min-height: 250px;
  }
  .Calendar__monthArrowWrapper:disabled,
  .Calendar__monthArrowWrapper.-hidden {
    opacity: 0.2;
  }
`;

const DateWrapper = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.01em;
  text-align: left;
  border-bottom: 1px solid #ebf1f6;
  padding: 25px 16px 16px 20px;
  background: ${colors.white};
`;

const myCustomLocale = {
  // months list by order
  months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],

  // week days by order
  weekDays: [
    {
      name: "Monday",
      short: "Mo",
    },
    {
      name: "Tuesday",
      short: "Tu",
    },
    {
      name: "Wednesday",
      short: "We",
    },
    {
      name: "Thursday",
      short: "Th",
    },
    {
      name: "Friday",
      short: "Fr",
    },
    {
      name: "Saturday",
      short: "Sa",
      isWeekend: true,
    },
    {
      name: "Sunday",
      short: "Su",
      isWeekend: true,
    },
  ],
  // just play around with this number between 0 and 6
  weekStartingIndex: 0,

  // return a { year: number, month: number, day: number } object
  getToday(gregorainTodayObject) {
    return gregorainTodayObject;
  },

  // return a native JavaScript date here
  toNativeDate(date) {
    return new Date(date.year, date.month - 1, date.day);
  },

  // return a number for date's month length
  getMonthLength(date) {
    return new Date(date.year, date.month, 0).getDate();
  },

  // return a transformed digit to your locale
  transformDigit(digit) {
    return digit;
  },
};

const maximumDate = {
  year: new Date().getFullYear(),
  month: 12,
  day: 31,
};

const OneTimeReminder = ({ selectedOneTimeDate, setSelectedOneTimeDate }) => {
  return (
    <div>
      <DateWrapper>Select Date</DateWrapper>
      <CalendarWrapper>
        <Calendar
          colorPrimary={colors.deepBlue}
          value={selectedOneTimeDate}
          onChange={setSelectedOneTimeDate}
          shouldHighlightWeekends={false}
          locale={myCustomLocale}
          minimumDate={utils().getToday()}
          maximumDate={maximumDate}
        />
      </CalendarWrapper>
    </div>
  );
};

export default OneTimeReminder;
