import React, { Fragment, useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import aa from "search-insights";
import styled from "styled-components";

import { RippleButton, usePaystackPayment } from "../../../../../components";
import { List } from "../../../../../containers/ListContainer";
import { orderStatus } from "../../../../../enums";
import { merchbuyActions } from "../../../../../redux/ducks/applications/merchbuy/actions";
import { colors } from "../../../../../styles";
import { formatPrice } from "../../../../../utils/currency/formatPriceWithComma";
import { PROCESS_PAYMENT } from "../../../../../utils/mix-panel/constants";
import { mixPanel } from "../../../../../utils/mix-panel/mixPanel";
import { CommissionBreakdown } from "../../shopping-cart/index";
import {
  Space,
  PageSubSectionsTop,
  Text,
  PageSubSectionsTopLeftTitle,
} from "../../styles";
import { getProductInfoForMixPanel } from "../helper";
import { PaymentInfo } from "../payment-info";

const FlexContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const DeliveryDetailsWrapper = styled.div`
  display: grid;
  gap: 7px;
  padding: 1em;
  background: ${colors.white};
`;

const EditWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const NameWrapper = styled.div`
  height: 24px;
  width: 24px;
  background: #ede6fe;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const ChangeDeliveryText = styled.h4`
  font-size: 13px;
  color: ${colors.deepBlue};
  font-weight: 600;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify || "space-between"};
`;

const DetailsSection = styled.div`
  background: ${colors.white};
  height: ${({ height }) => height || "97px"};
  display: grid;
  padding: 1em;
`;

const ShippingFee = styled.span`
  color: ${colors.deepBlue};
  line-height: 15px;
`;

const FooterWrapper = styled.div`
  background: ${colors.lightestGray};
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1em;
`;

const Summary = ({
  setOrderId,
  setStep,
  priceInfo,
  paymentMethod,
  deliveryDetails,
  shippingDetails,
  setShowAddressPopup,
  setShowShippingPopup,
  setShowPin,
  shouldPlaceOrder,
  setShouldPlaceOrder,
  setStatusEnum,
  fromBuyNow,
  buyNowItems,
}) => {
  const dispatch = useDispatch();
  const cartItems = useSelector(
    (state) => state.applications.merchbuy.shopCart
  );
  const walletStatus = useSelector((state) => state.account.wallet.status);
  const user = useSelector((state) => state.user);
  const walletBalance = useSelector(
    (state) => state.account.wallet.balance || 0
  );
  const [open, setOpen] = useState(false);

  const [totalItemCount] = useState(
    fromBuyNow
      ? cartItems.reduce(
          (accum, current) => Number(accum) + Number(current?.quantity),
          0
        )
      : buyNowItems.reduce(
          (accum, current) => Number(accum) + Number(current?.quantity),
          0
        )
  );
  const [shopNameList] = useState(
    fromBuyNow
      ? buyNowItems?.flatMap((x) => x?.shopData && [x?.shopData?.shopName])
      : cartItems?.flatMap((x) => x?.shopData && [x?.shopData?.shopName]) || []
  );
  const [shopIDs] = useState(
    fromBuyNow
      ? buyNowItems?.flatMap((x) => x.shopData && [x.shopData.shopName])
      : cartItems?.flatMap((x) => x.product && [x.product.shopId]) || []
  );
  const [productIDList] = useState(
    fromBuyNow
      ? buyNowItems?.flatMap((x) => x.shopData && [x.shopData.shopName])
      : cartItems?.flatMap((x) => x.product && [x.product.id]) || []
  );
  const [productNameList] = useState(
    fromBuyNow
      ? buyNowItems?.flatMap((x) => x.shopData && [x.shopData.shopName])
      : cartItems?.flatMap((x) => x.product && [x.product.name]) || []
  );
  const [productCategory] = useState(
    fromBuyNow
      ? buyNowItems?.flatMap((x) => x.shopData && [x.shopData.shopName])
      : cartItems?.flatMap(
          (x) =>
            x.product?.productDetails && [
              x.product.productDetails.productCategory,
            ]
        ) || []
  );

  const [paystackKey] = useState(process.env.REACT_APP_PAYSTACK_KEY);
  const [deliveryStateLga] = useState(
    JSON.parse(localStorage.getItem("deliveryLocation"))
  );
  const { subTotal, totalPrice: total, vat: totalVAT } = priceInfo;
  // const { shippingPrice, deliveryDate, deliveryProvider } = shippingDetails || {};
  const receiverName =
    deliveryDetails.receiverName?.trim() ||
    deliveryDetails.fullName?.trim() ||
    `${deliveryDetails.firstName?.trim()} ${deliveryDetails.lastName?.trim()}` ||
    "";

  const shopNumber = deliveryDetails.shopNumber;
  const city = deliveryDetails.city;
  const address =
    deliveryDetails.shopAddress ||
    deliveryDetails.streetAddress ||
    deliveryDetails.address;
  const lga = deliveryDetails.lga || deliveryDetails.localGovt;
  const phoneNumber = deliveryDetails.msisdn || deliveryDetails.phoneNumber;
  const state = deliveryDetails.state;
  const fullAddress = `${shopNumber} ${address} ${city} ${lga} ${state}`;
  const [firstName, lastName] = receiverName.split(" ");
  const userId = useSelector((state) => state.user.userId);
  const productIndex = process.env.REACT_APP_ALGOLIA_SEARCH_INDEX;

  const initializePayment = usePaystackPayment();

  const placeOrder = () => {
    const products = [];
    const items = fromBuyNow ? [...buyNowItems] : [...cartItems];
    for (let i = 0; i < items.length; i++) {
      const data = {
        itemId: items[i]?.id,
        quantity: items[i]?.quantity,
        groupBuySplitId: items[i].groupBuySplitId,
        groupBuyId: items[i].groupBuyId,
        groupBuyBulkPrice: items[i].groupBuyProductBulkPrice,
        vat: (items[i].maxPrice + items[i]?.quantity) * 0,
      };
      products.push(data);
    }
    const payload = {
      products,
      checkoutUserType: 1,
      customerComment: deliveryDetails.deliveryInstruction,
      location: deliveryStateLga.state,
      orderDelivery: {
        address: fullAddress,
        deliveryCharge: shippingDetails.totalAmount,
        deliveryCompany: shippingDetails.partner,
        deliveryOption: shippingDetails.option,
        firstName,
        lastName,
        lga,
        phoneNumber,
        serviceCharge: 0,
        state,
        subTotal,
        total,
        totalVAT,
      },
    };

    return dispatch(merchbuyActions.placeOrder(payload, paymentMethod.enum));
  };

  const placeOrderHook = async () => {
    const response = await placeOrder();
    if (response.status) {
      const data = getProductInfoForMixPanel(
        fromBuyNow ? buyNowItems : cartItems
      );
      mixPanel.track(PROCESS_PAYMENT, {
        "Total cart value": subTotal,
        "Total item count": data?.totalItemCount,
        "Total unique item": fromBuyNow ? buyNowItems : cartItems?.length,
        "Total shops": data?.sortedItems?.length,
        "Item ids": data?.productIds,
        "Item names": data?.productNames,
        "Item shopNames": data?.shopNames,
        "Item categories": data?.categoryNames,
        "Wallet Status": walletStatus,
        "Payment type": paymentMethod?.name,
        Time: new Date().toLocaleString(),
      });
      // send algolia events here for all products
      // all cartItems
      aa("convertedObjectIDs", {
        userToken: userId,
        index: productIndex,
        eventName: "Products Purchased",
        // queryID: 'cba8245617aeace44',
        objectIDs: [...buyNowItems?.map((item) => item?.id)] || [
          ...cartItems?.map((item) => item.id),
        ],
      });

      let paymentDetail = {
        orderId: response?.order?.id,
        orderNumber: response?.order?.orderNumber,
        partnerCode: "",
        payment: {
          deliveryCharge: response?.order?.deliveryFee,
          email: response?.order?.email,
          message: "",
          paymentMethod: paymentMethod?.enum,
          redirecturl: "",
          reference: null,
          response: "",
          responseString: "",
          serviceCharge: 0, //serviceCharge,
          status: "",
          subTotal: response?.order?.subTotal,
          total: response?.order?.totalPrice,
          transactionId: null,
          transactionReference: null,
        },
        referralCode: "",
      };

      if (response.paymentType === 2) {
        const config = {
          email: user.email,
          firstname: user.firstName,
          lastname: user.lastName,
          amount: Math.ceil(priceInfo.totalPrice * 100),
          publicKey: paystackKey,
        };
        initializePayment(
          config,
          (reference) => {
            paymentDetail = {
              ...paymentDetail,
              payment: {
                ...paymentDetail.payment,
                reference: reference.reference,
                transactionId: reference.trans,
                transactionReference: reference.trxref,
              },
            };
            //Save transaction reference
            dispatch(merchbuyActions.sendPaymentDetails(paymentDetail)).then(
              (orderNo) => {
                if (orderNo) {
                  successfulOrder(orderNo);
                }
              }
            );
          },
          () => console.log("payment closed")
        );
      } else if (response.paymentType === 3) {
        const mixpanelPayload = {
          "Payment type": "Wallet",
          "Total order value": total,
          "Item Category": productCategory,
          "Total item count": totalItemCount,
          "Total unique items count": fromBuyNow
            ? buyNowItems
            : cartItems.length,
          "Shop name list": shopNameList,
          "Shop ID list": shopIDs,
          "Total shop": shopNameList.length,
          "Item ID list": productIDList,
          "Item name list": productNameList,
          "Wallet balance": walletBalance,
        };
        const orderNo = await dispatch(
          merchbuyActions.payWithWallet(response, mixpanelPayload)
        );
        if (orderNo) {
          successfulOrder(orderNo);
        } else {
          failedPayment();
        }
      } else {
        dispatch(merchbuyActions.sendPaymentDetails(paymentDetail)).then(
          (orderNo) => {
            if (orderNo) {
              successfulOrder(orderNo);
            }
          }
        );
      }
    } else {
      failedOrder();
    }
  };

  const successfulOrder = (orderNumber) => {
    setOrderId(orderNumber);
    setStatusEnum(0);
    setStep(orderStatus.placed);
  };

  const failedOrder = () => {
    setStatusEnum(1);
    setStep(orderStatus.placed);
  };

  const failedPayment = () => {
    setStatusEnum(2);
    setStep(orderStatus.placed);
  };

  const avatarName = (name) => {
    return name
      ? name
          .split(" ")
          .map((n) => n[0])
          .join("")
      : "";
  };

  useEffect(() => {
    if (shouldPlaceOrder) {
      setShouldPlaceOrder(false);
      placeOrderHook();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldPlaceOrder, setShouldPlaceOrder]);

  return (
    <Fragment>
      <List noBorderBottom={false}>
        <PageSubSectionsTop padding={"16px 16px 8px"}>
          <PageSubSectionsTopLeftTitle left="0" font="12px">
            DELIVERY ADDRESS
          </PageSubSectionsTopLeftTitle>
          <ChangeDeliveryText
            onClick={() => {
              setShowAddressPopup(true);
            }}
          >
            CHANGE
          </ChangeDeliveryText>
        </PageSubSectionsTop>

        <DeliveryDetailsWrapper>
          <TextWrapper>
            <NameWrapper>
              <Text font="10px" color="#7E42F5" fontWeight="500">
                {avatarName(
                  deliveryDetails?.receiverName ||
                    deliveryDetails?.fullName ||
                    `${deliveryDetails?.firstName || ""} ${
                      deliveryDetails?.lastName || ""
                    }`
                )}
              </Text>
            </NameWrapper>
            <Text font="14px" color="#29394f">
              {deliveryDetails?.receiverName ||
                deliveryDetails?.fullName ||
                `${deliveryDetails?.firstName || ""} ${
                  deliveryDetails?.lastName || ""
                }`}
            </Text>
          </TextWrapper>
          <Text color={colors.lightDark}>
            {`${
              deliveryDetails.shopNumber
                ? deliveryDetails.shopNumber + ", "
                : ""
            } 
                            ${
                              deliveryDetails.shopAddress ||
                              deliveryDetails.streetAddress ||
                              deliveryDetails.address ||
                              ""
                            }`}
          </Text>
          <Text color={colors.lightDark}>{`${
            deliveryDetails.lga || deliveryDetails.localGovt
          } Municipalities`}</Text>
          <Text
            color={colors.lightDark}
          >{`${deliveryDetails.state} Province`}</Text>
          <EditWrapper>
            <Text color={colors.lightDark}>
              {deliveryDetails.msisdn || deliveryDetails.phoneNumber}
            </Text>
          </EditWrapper>
        </DeliveryDetailsWrapper>
      </List>
      <List noBorderBottom={false}>
        <PageSubSectionsTop padding={"12px 16px 8px"}>
          <PageSubSectionsTopLeftTitle left="0" font="12px">
            DELIVERY METHOD
          </PageSubSectionsTopLeftTitle>
          <ChangeDeliveryText
            onClick={() => {
              setShowShippingPopup(true);
            }}
          >
            {/* CHANGE */}
          </ChangeDeliveryText>
        </PageSubSectionsTop>
        <DetailsSection>
          <ContentWrapper>
            <FlexContent>
              <Text padding="0 0 8px 0" font="14px" color={colors.boldDark}>
                {/* {deliveryProvider} */}
                Free Shipping
              </Text>
              <Text padding="4px 0 0 0" color={colors.lightDark}>
                {/* {deliveryDate} */}
                Estimated Delivery: 2 days
              </Text>
              <Text padding="4px 0 0 0" color={colors.lightDark}>
                Shipping Fee: <ShippingFee>{formatPrice(0)} (FREE)</ShippingFee>
              </Text>
            </FlexContent>
          </ContentWrapper>
        </DetailsSection>
      </List>
      <List noBorderBottom={false}>
        <PageSubSectionsTop padding={"12px 16px 8px"}>
          <PageSubSectionsTopLeftTitle left="0" font="12px">
            PAYMENT METHOD
          </PageSubSectionsTopLeftTitle>
          <ChangeDeliveryText onClick={() => setStep(orderStatus.payment)}>
            {/* CHANGE */}
          </ChangeDeliveryText>
        </PageSubSectionsTop>
        <DetailsSection height="56px">
          <ContentWrapper justify="flex-start">
            {paymentMethod?.icon}
            <Text padding="0 0 0 10px" font="14px" color={colors.boldDark}>
              {paymentMethod?.name}
            </Text>
          </ContentWrapper>
        </DetailsSection>
      </List>
      <Space color={colors.lightestGray} height="16px" />
      <PaymentInfo priceInfo={priceInfo} />
      <Space color={colors.lightestGray} height="150px" />
      <FooterWrapper>
        <RippleButton
          onClick={() => {
            if (paymentMethod.enum === 3) {
              setShowPin(true);
            } else {
              placeOrderHook();
            }
          }}
        >
          Pay
        </RippleButton>
        <Space color={colors.lightestGray} height="38px" />
      </FooterWrapper>

      {open && <CommissionBreakdown open={open} setOpen={setOpen} />}
    </Fragment>
  );
};

export default Summary;
