import React, { Fragment } from "react";

import { TopHeader } from "../../components";
import { Message, BoldText, SubTitle } from "../../containers/MessageContainer";
import { ScreenContainer } from "../../containers/ScreenContainer";
import DesktopBackgroundLayout from "../DesktopBackgroundLayout";

import PrivacyPdf from "./privacyFolder/Vumele_Privacy_Policy.pdf";

export const PrivacyPolicy = () => (
  <Fragment>
    <DesktopBackgroundLayout>
      <TopHeader title={"Privacy Policy"} />
      <ScreenContainer>
        <SubTitle textAlign="left" top="30px">
          Please read carefully before using this service.
        </SubTitle>
        <Message>Welcome to the Vumele App</Message>

        <SubTitle textAlign="left" top="30px">
          1. Introduction
        </SubTitle>
        <Message textAlign="left" top="3px">
          O2O Network Limited SA (Vumele) is a conscientious company. We respect
          your privacy, and we are committed to protecting your personal data.
          This privacy policy will inform you of how we look after your personal
          data and inform you of your privacy rights and how the law protects
          you. This privacy policy identifies the data we collect from you, why
          we collect your data, how we use your data, how you can control your
          data and how we manage, store, protect or share your data. This
          privacy policy is issued by O2O Network Limited SA (Vumele) and only
          applies to the activities of O2O Network Limited SA (Vumele) and not
          those of its affiliates. The words "Vumele” we", "us" or "our" in this
          privacy policy, refers to O2O Network Limited SA (Vumele) . This
          privacy policy is provided in a layered format so you can easily
          navigate to the specific areas set out below. Alternatively, you can
          download a pdf version of the policy
          <BoldText>
            <a href={PrivacyPdf} rel="noreferrer noopener" download>
              {" "}
              here
            </a>
          </BoldText>
        </Message>

        <SubTitle textAlign="left" top="30px">
          2. Purpose and Scope of this Privacy Policy
        </SubTitle>
        <Message top="3px">
          This privacy policy aims to give you information on how Vumele may
          collect and process your personal data when you use this website,
          contact us by telephone or email, or visit our locations. This privacy
          policy applies generally to personal data processed in connection with
          and in the course of Vumele’s business. It serves to set out Vumele’s
          approach to your personal data. This privacy policy is not intended
          for children. We do not provide services to children, and we do not
          knowingly collect data relating to children, except where we collect
          data of dependents, next-of-kin, or in other representative capacity
          from data subjects. This privacy policy should be read together with
          any other privacy notice which we may provide on specific occasions
          when we are collecting or processing personal data about you.
        </Message>

        <SubTitle textAlign="left" top="30px">
          3. What data do we collect about you?
        </SubTitle>
        <Message top="3px">
          The data we collect from you is personal data. Personal data, or
          personal information, means any information about an individual from
          which that person can be identified. We may collect, use, store
          specific types of personal data we may collect are as follows: <br />{" "}
          <br />
          • Identity Data: includes data such as first name, maiden name, last
          name, social media username or similar identifier, marital status,
          title, date of birth (age), next-of-kin data, biometric data, your
          password and gender.
          <br /> <br />
          • Contact Data: includes data such as home address, email address and
          telephone number. <br />
          <br />
          • Financial Data: includes data such payment card details, bank
          information and payment information. <br /> <br />
          • Transaction Data: includes data such as details about payments to
          and from you and other details of products and services you have
          purchased from us.
          <br /> <br />
          • Marketing and Communication Data: includes data regarding your
          preferences in receiving marketing materials from us as well as your
          communication preferences.
          <br />
        </Message>

        <SubTitle textAlign="left" top="30px">
          4. How is your personal data collected?
        </SubTitle>
        <Message top="3px">
          We use different methods to collect data from and about you including
          through:
          <br />
          <br />
          - Direct collection: You may give us your data by filling in forms
          (e.g., the contact form on our website); creating your profile; by
          correspondence and conversations (including via email and telephone);
          or through contracts we may enter with you. <br />
          <br />
          - Automated technologies: As you interact with our website, we will
          automatically collect data about your equipment, browsing actions and
          patterns. We collect this personal data by using cookies and other
          similar technologies. We collect such data to be able to properly
          provide you with our services. <br />
          <br />
          - Third parties or publicly available sources: We may also receive
          your personal data from third parties to whom you have legally
          provided such data to, such as your employer, benefactors, etc. To the
          extent that such data is disclosed by third parties, different rules
          may apply to their use or disclosure of your information. We do not
          control how they use or share your data and we cannot make any
          representations or warranties as to these. We however have control
          over data that you have voluntarily shared to us directly or through
          your usage of our website. With your consent, we use your information
          to fulfil requests to receive information or materials from us, to
          carry out services for your benefit and to process applications and
          requests from you. We do not use your data for any other purpose than
          for the purposes listed out in this policy and we do not sell, lend,
          or rent any personally data about you to any third parties. <br />
        </Message>

        <SubTitle textAlign="left" top="30px">
          5. Lawful basis for our use of your personal data
        </SubTitle>
        <Message top="3px">
          We will only use your personal data as allowed by law. Under the
          Protection of Personal Information Act (POPI Act) (POPIA), personal
          data may be processed under any of the following lawful basis:
          <br />
          <br />
          - Where you consent to our use of your personal data
          <br />
          - Where we need to perform the contract which we are about to enter or
          have entered into with you.
          <br />
          - Where we need to comply with a legal obligation.
          <br />
          - Where we need to protect your vital interest or the vital interest
          of another individual
          <br />
          - Where it is in the interest of the general public to process your
          data. <br />
          <br />
          While we mostly collect and process your data with your consent, we
          may collect and process your data under any of the identified lawful
          basis depending on the circumstance. We do not always need your
          consent to process your data. However, we do need your consent before
          we can process your data for purposes such as direct marketing
          communications. You are at liberty to withdraw your consent to such
          kinds of processing at any time. Where such withdrawal makes us unable
          to proceed with providing you with certain services, we will inform
          you accordingly. We may also be lawfully collecting and using
          information from you if you are from the European Economic Area (EEA)
          in line with the GDPR.
        </Message>

        <SubTitle textAlign="left" top="30px">
          6. Why we use your data
        </SubTitle>
        <Message top="3px">
          We collect data to enable us process requests which you make, or which
          are made on your behalf with your consent and to provide you with our
          services.
          <br />
          We collect data to personalise your experience and improve customer
          services (your information helps us better respond to your individual
          needs) <br />
          We collect data to be able to communicate with you, to provide further
          information on our products and services and to assist you. <br />
          We also collect data to be able to respond to questions or requests
          which you submit as well as anticipate and resolve problems with any
          services we offer to you.
          <br />
          We collect data to process transactions.
        </Message>

        <SubTitle textAlign="left" top="30px">
          7. Failure to provide data
        </SubTitle>
        <Message top="3px">
          Where you refuse to provide data required under contract or under law
          when requested, we may not be able to perform the contract we have or
          are trying to enter with you (for example, to provide you with goods).
          Where we have to cancel an order, you have made with us because you
          have not provided us with relevant data, we will notify you.
        </Message>

        <SubTitle textAlign="left" top="30px">
          8. Change of purpose
        </SubTitle>
        <Message top="3px">
          We will only use your personal data for the purposes for which we
          collected it, unless we reasonably consider that we need to use it for
          another reason and that reason is compatible with the original
          purpose. If you wish to get an explanation as to how the processing
          for the new purpose is compatible with the original purpose, please
          contact us. If we need to use your personal data for an unrelated
          purpose, we will notify you and we will explain the legal basis which
          allows us to do so. Please note that we may process your personal data
          without your knowledge or consent, in compliance with the above rules,
          where this is required or permitted by law.
        </Message>

        <SubTitle textAlign="left" top="30px">
          9. Disclosures of your personal data
        </SubTitle>
        <Message top="3px">
          It may be necessary for us to share your personal data with third
          parties. These third parties may include internal third parties such
          as members of our company or our affiliates, or external third parties
          such as our service providers, business partners, and merchants. We
          may disclose any information about you as required by law and we may
          make such disclosures to law enforcement agencies and government
          officials, as necessary or appropriate under the relevant
          circumstance. Other than these instances and other instances allowed
          under law, we do not disclose or share your personal data provided to
          us without your authorisation. We require all third parties to respect
          the security of your personal data and to treat it in accordance with
          the law. We do not allow our third-party service providers to use your
          personal data for their own purposes and only permit them to process
          your personal data for specified purposes and in accordance with our
          instructions.
        </Message>

        <SubTitle textAlign="left" top="30px">
          10. International transfers
        </SubTitle>
        <Message top="3px">
          We are incorporated in South Africa. Information collected via our
          website, through direct interactions with you, or from use of our help
          services may be transferred from time to time but we do not routinely
          transfer your data outside of South Africa. Whenever we transfer your
          personal data out of South Africa, we ensure that a similar degree of
          protection is afforded to it in the country to which it is
          transferred. You hereby consent to such transfers where such adequate
          protection has been ensured for your data.
        </Message>

        <SubTitle textAlign="left" top="30px">
          11. Data Security
        </SubTitle>
        <Message top="3px">
          We have well-maintained systems for storing and managing your data,
          and we commit to carefully utilising your data in line with the
          provisions of this policy. We have suitable security measures in place
          to prevent your personal data from being accidentally lost or used or
          accessed in an unauthorised way by a third party. <br />
          In respect to any credit card or other payment processing details you
          have provided us, your personal information will be stored in the most
          secure manner possible.
          <br />
          In addition, we limit access to your personal data to only those
          employees, agents, merchants, service providers and other third
          parties who need to have access to your personal data in order to
          enable us to provide our services to you. They will only process your
          personal data on our instructions.
          <br />
          We have put in place procedures to deal with any suspected personal
          data breach and will notify you and any applicable regulator of a
          breach where we are legally required to do so.
          <br />
          We admit however that no database is completely secure or “hacker
          proof” and we only guarantee the safety of your data to the extent of
          our undertaking all reasonable measures to protect your data.
          <br />
        </Message>

        <SubTitle textAlign="left" top="30px">
          12. Data Retention
        </SubTitle>
        <Message top="3px">
          We will only retain your personal data for as long as reasonably
          necessary to fulfil the purposes we collected it for, including for
          the purposes of satisfying any legal, regulatory, tax, accounting, or
          reporting requirements. We may retain your personal data for a longer
          period in the event of a complaint or if we reasonably believe there
          is a prospect of litigation in respect to our relationship with you.
          <br />
          To determine the appropriate retention period for personal data, we
          consider the amount, nature and sensitivity of the personal data, the
          potential risk of harm from unauthorised use or disclosure of your
          personal data, the purposes for which we process your personal data
          and whether we can achieve those purposes through other means, and the
          applicable legal, regulatory, tax, accounting, or other requirements.
          We have an internal data retention policy in place to this effect.
        </Message>

        <SubTitle textAlign="left" top="30px">
          13. Your rights
        </SubTitle>
        <Message top="3px">
          In addition to being able to control the data your directly provide to
          us, you may exercise any of the below rights with respect to your
          data: <br />
          <ol>
            1. Request information about any of your personal data which we are
            processing, and request access to your personal information which we
            process.{" "}
          </ol>
          <ol>
            2. Request correction of personal information that we hold about you
            to make them more accurate or to reflect change in circumstances.{" "}
          </ol>
          <ol>
            3. Request us to refrain from doing certain things with your data or
            restrict the extent of our collection or processing of your data.{" "}
          </ol>
          <ol>
            4. Request partial or complete deletion of your personal
            information.{" "}
          </ol>
          <ol>
            5. Object to our processing of your personal information where we
            are processing your personal information for direct marketing
            purposes.{" "}
          </ol>
          <ol>
            6. Object to decisions being taken by automated means which produce
            legal effects concerning you or similarly significantly affect you.{" "}
          </ol>
          <ol>
            7. Request the transfer of your personal information to another
            party.
          </ol>
          If you wish to exercise any of the rights set out above, you may make
          a request by contacting the organization at:{" "}
          <BoldText>
            <a href="mailto: info@vumele.com"> info@vumele.com</a>
          </BoldText>
        </Message>

        <SubTitle textAlign="left" top="30px">
          14. Exercising your right to request update or correction of
          Information
        </SubTitle>
        <Message top="3px">
          The rights you have to request updates or corrections or deletions to
          the information we collect depend on your relationship with us. You
          can contact us in order to (1) update or correct your personally
          identifiable information you receive from us, (2) change your
          preferences with respect to communications and other information you
          receive from us, or (3) delete the personally identifiable information
          maintained about you on our systems (subject to the following
          paragraph), by cancelling your account. Such updates, corrections,
          changes and deletions will have no effect on other information that we
          maintain, or information that we have provided to third parties in
          accordance with this Privacy Policy prior to such update, correction,
          change or deletion. To protect your privacy and security, we may take
          reasonable steps (such as requesting a unique password) to verify your
          identity before granting you profile access or making corrections. You
          are responsible for maintaining the secrecy of your unique password
          and account information at all times. You should be aware that it is
          not technologically possible to remove each and every record of the
          information you have provided to us from our system. The need to back
          up our systems to protect information from inadvertent loss means that
          a copy of your information may exist in a non-erasable form that will
          be difficult or impossible for us to locate. Promptly after receiving
          your request, all personal information stored in database we actively
          use, and other readily searchable media will be updated, corrected,
          changed or deleted, as appropriate and where legally permissible, as
          soon as and to the extent reasonably and technically possible.
        </Message>

        <SubTitle textAlign="left" top="30px">
          15. CCTV Cameras and Visitors’ Policy
        </SubTitle>
        <Message top="3px">
          We may use CCTV Cameras in some of our physical facilities and we may
          process your personal data using a CCTV system when you visit our
          physical facilities. Our CCTV systems monitor and record visual
          images, including your personal appearance, in our facilities. We
          process these recordings to ensure the continued integrity and
          security of our property and to detect incidents of security threats,
          theft, or hazard which would require our attention and which we would
          otherwise not be aware of. Specifically, we use CCTV systems to:
          <Message left="10px" top="5px">
            a. prevent and detect crimes;
            <br />
            b. identify, apprehend, and prosecute offenders;
            <br />
            c. dealing with any queries, complaints, or enquiries;
            <br />
            d. ensure the security of our and your property and that of our
            clients and contractors;
            <br />
            e. ensure that our policies and procedures are being adhered to;
            <br />
            f. to assist in any investigations or any disciplinary or grievance
            issue;
            <br />
            g. monitor the security of our premises;
            <br />
            h. monitor adherence to health and safety provisions and policies.
            <br />
            <br />
          </Message>
          We process your data through CCTV systems under the tripartite legal
          basis of public interest, vital interest, and consent, in certain
          circumstances. Images captured by CCTV may be monitored and recorded
          and kept for up to 90 days after the recording was made. After this
          time, recording stored on the hard drive of our CCTV system will
          usually be overwritten. Information collected by our CCTV systems
          would only be viewed by authorized personnel and shall not be made
          available to third parties, except legal authorities or on compulsion
          by law. We will endeavour to inform you that your data is being taken
          through CCTV systems at our premises. We may also collect your data at
          our physical facilities through our visitors’ logbook and other
          records. Typically, we obtain basic personal information from you
          which includes but is not limited to name, company name, phone number,
          reason of visit, date, and time of visit. We collect such data to be
          able to prevent and detect crimes, identify visitors in the case of
          investigations, to ensure contact tracing for the prevention of
          transmittable diseases and in line with health standards, to ensure
          the physical security of the people and items, security of
          confidential information located in our premises or accessible from
          our premises and to prevent loss, frauds, thefts, injuries, terrorism,
          and other events of such kind in our premises. We only take your
          information through the visitors’ logbook with your permission. On
          filling the visitor’s logbook, you may be required to consent to the
          routine collection of physical data by CCTV cameras located within our
          premises.
        </Message>

        <SubTitle textAlign="left" top="30px">
          16. Advertising and Marketing Activities
        </SubTitle>
        <Message top="3px">
          Advertising keeps us informed of the websites and services you use,
          free of charge. Our ads are safe, unobtrusive, and as relevant as
          possible.
          <Message left="10px" top="5px">
            <SubTitle textAlign="left" top="3px">
              a. Cookies for Advertising
            </SubTitle>
            Cookies help to make advertising more effective. Without cookies, it
            is difficult for an advertiser to know how many ads were shown and
            how many clicks they received.
            <SubTitle textAlign="left" top="3px">
              b. Remarketing Services
            </SubTitle>
            We use remarketing services. In digital marketing, remarketing (or
            retargeting) is the practice of serving ads across the internet to
            people who have already visited a website. It allows for “following”
            people around the internet by serving ads on the websites and
            platforms they use most.
          </Message>
        </Message>

        <SubTitle textAlign="left" top="30px">
          17. Tracking Technologies
        </SubTitle>
        <Message top="3px">
          Our site and devices utilise the following:
          <Message left="10px" top="5px">
            <SubTitle textAlign="left" top="3px">
              c. Google Maps APIs
            </SubTitle>
            Google Maps API is a robust tool that can be used to create a custom
            map, a searchable map live data synching with location, plan routes,
            or create a mashup just to name a few. Google Maps API may collect
            information from You and from Your Device for security purpose.
            Google Maps API collects information that is held in accordance with
            its Privacy Policy.
            <SubTitle textAlign="left" top="3px">
              d. Cookies
            </SubTitle>
            We use Cookies to enhance the performance and functionality of our
            services but are non-essential to their use. However, without these
            cookies, certain functionality like videos may become unavailable or
            you would be required to enter your login details every time you
            visit our service as we would not be able to remember that you had
            logged in previously.
          </Message>
        </Message>

        <SubTitle textAlign="left" top="30px">
          18. Contact Details
        </SubTitle>
        <Message top="3px">
          If you have any questions about this privacy policy or our privacy
          practices, please contact us using the details provided below:
          <br />
          <br />
          Full name of legal entity: O2O Network Limited SA (Vumele) <br />
          <br />
          Email address:{" "}
          <BoldText>
            <a href="mailto:compliance@vumele.com">compliance@vumele.com</a>
          </BoldText>
        </Message>

        <SubTitle textAlign="left" top="30px">
          19. Changes to the privacy policy and your duty to inform us of
          changes
        </SubTitle>
        <Message top="3px">
          We keep our privacy policy under regular review. If we decide to
          change our privacy policy, we will post those changes on this page,
          together with the modification date. This version was last updated on
          30 May 2022. It is important that the personal data we hold about you
          is accurate and current. Please keep us informed if your personal data
          changes during your relationship with us.
        </Message>
      </ScreenContainer>
    </DesktopBackgroundLayout>
  </Fragment>
);
