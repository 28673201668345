import React, { Fragment } from "react";

import Swipeout from "rc-swipeout";
import styled, { css } from "styled-components";

import "rc-swipeout/assets/index.css";
import { List, ListItem } from "../../../../../../../containers/ListContainer";
import { colors } from "../../../../../../../styles";
import { formatCurrency } from "../../../../../../../utils/currency/parseBalance";
import { SetSaleQuantity } from "../../../../components/set-sale-quantity";
import { ReactComponent as DeleteSVG } from "../../assets/deleteBlack.svg";
import { ReactComponent as RefreshSVG } from "../../assets/refresh.svg";

const SaleInformationBlock = styled.div`
  position: relative;
  margin: 0 16px;
  padding: 10px 1em;
`;

const Text = styled.div`
  color: ${({ color }) => color || "#212c3d"};
  font-size: ${({ size }) => size || "14px"};
  margin-top: ${({ top }) => top || ""};
  margin-bottom: ${({ bottom }) => bottom || ""};
  margin-left: ${({ left }) => left || ""};
  margin-right: ${({ right }) => right || ""};
  font-weight: ${({ weight }) => weight || ""};
  ${({ noHeadingWrap }) =>
    noHeadingWrap
      ? null
      : css`
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        `}
`;

const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: ${({ justify }) => justify || "space-between"};
  margin: 5px 0;
`;

const Input = styled.input`
  font-size: 12px;
  font-weight: 700;
  color: #071827;
  line-height: 18px;
  width: 102px;
  height: 31px;
  padding: 7px;
  background-color: #f2f5fa;
  border-radius: 4px;
  border: none;
  outline: none;
  &:focus {
    background-color: #fff;
  }
`;

const Relative = styled.div`
position relative
`;
const RefreshIcon = styled(RefreshSVG)`
  position: absolute;
  right: 5px;
  top: 10px;
  cursor: pointer;
`;

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const ListProducts = ({
  selectedProducts,
  currentShop,
  setOpenConfirmDeletionPopup,
  setProductIdToDelete,
  setSelectedProducts,
}) => {
  return (
    <SaleInformationBlock>
      <Fragment>
        <List top={"5px !important"} fullScreen>
          {selectedProducts.map((product, index) => {
            const itemsInInventory = currentShop.inventory.find(
              (item) => item.inventoryProductId === product.inventoryProductId
            );
            return (
              <Swipeout
                key={product.inventoryProductId}
                right={[
                  {
                    text: "Remove",
                    onPress: () => {
                      setOpenConfirmDeletionPopup((prev) => !prev);
                      setProductIdToDelete(product.id);
                    },
                    style: {
                      backgroundColor: "transparent",
                      color: "white",
                    },
                    className: "custom-class-2",
                  },
                ]}
              >
                <ListItem
                  margin="4px 0 0 0"
                  height="111px"
                  top={"16px"}
                  bottom={"16px"}
                  direction={"column"}
                  hover={colors.white}
                >
                  <FlexContainer>
                    <Text color="#071827" size="12px" weight="500">
                      {product.name}
                    </Text>
                    <DeleteSVG
                      onClick={() => {
                        setProductIdToDelete(product.id);
                        setOpenConfirmDeletionPopup((prev) => !prev);
                      }}
                    />
                  </FlexContainer>
                  <Text
                    bottom="12px"
                    top="2px"
                    color="#718596"
                    size="10px"
                    weight="500"
                  >
                    {formatCurrency(product.itemPrice)} PER UNIT
                  </Text>
                  <FlexContainer>
                    <SetSaleQuantity
                      selectedProducts={selectedProducts}
                      itemsInInventory={
                        itemsInInventory && itemsInInventory.quantity
                      }
                      setProducts={setSelectedProducts}
                      inventoryProductId={product.inventoryProductId}
                    />
                    <div>
                      <FlexContainer justify="flex-start">
                        <Text
                          color="#718596"
                          size="12px"
                          weight="bold"
                          right="6px"
                        >
                          R
                        </Text>

                        <Relative>
                          <Input
                            name="amount"
                            type="text"
                            inputMode={"numeric"}
                            value={numberWithCommas(
                              product.newPrice >= 0
                                ? product.newPrice
                                : product.itemPrice
                            )}
                            onChange={(e) => {
                              const newProducts = [...selectedProducts].map(
                                (item) => {
                                  if (
                                    item.inventoryProductId ===
                                    product.inventoryProductId
                                  ) {
                                    item.newPrice = Number(
                                      e.target.value.replace(/\D/g, "")
                                    );
                                  }

                                  return item;
                                }
                              );
                              setSelectedProducts([...newProducts]);
                            }}
                          />
                          {product.newPrice !== undefined &&
                            product.newPrice !== product.itemPrice && (
                              <RefreshIcon
                                onClick={() => {
                                  const newProducts = [...selectedProducts].map(
                                    (item) => {
                                      if (
                                        item.inventoryProductId ===
                                        product.inventoryProductId
                                      ) {
                                        item.newPrice = item.itemPrice;
                                      }
                                      return item;
                                    }
                                  );
                                  setSelectedProducts([...newProducts]);
                                }}
                              />
                            )}
                        </Relative>
                      </FlexContainer>
                    </div>
                  </FlexContainer>
                </ListItem>
              </Swipeout>
            );
          })}
        </List>
      </Fragment>
    </SaleInformationBlock>
  );
};

export default ListProducts;
