import React, { Fragment, useState, useEffect } from "react";

import { Formik, Form } from "formik";
import { func, bool } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";

import {
  TopHeader,
  RippleButton,
  InputWithLabel,
} from "../../../../../../components";
import { Close } from "../../../../../../containers/HeaderContainer";
import { InputBlock } from "../../../../../../containers/InputContainer";
import { ScreenContainer } from "../../../../../../containers/ScreenContainer";
import { flags } from "../../../../../../data/countries";
import { merchbuyActions } from "../../../../../../redux/ducks/applications/merchbuy/actions";
import { ReactComponent as NewInfoIcon } from "../../../assets/new-info-icon.svg";
import VerticalLine from "../../../assets/vline.svg";
import { Space } from "../../../styles";
import { MerchantFound } from "../delivery-address-merchant-found";
import { AddNewDeliveryAddressDetails } from "../delivery-details-add-new";

import InfoBadge from "./../../../../../../components/badges/info-badge/index";
import { PhoneNumberValidationSchema } from "./PhoneNumberValidationSchema";

const Padding = styled.div`
  padding: 0 1em;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #071827;
`;

const Subtitle = styled.div`
  font-size: 14px;
  line-height: 21px;
  color: #718596;
  padding-top: 6px;
  padding-bottom: 24px;
  text-align: ${({ align }) => align || "left"};
`;

const ButtonWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 375px;
  width: inherit;
  padding: 15px 15px 40px;
`;

const CountryPhoneBlock = styled.div`
  position: relative;
`;

const CurrentSelectedCountry = styled.div`
  position: absolute;
  left: 16px;
  top: 12px;
  z-index: 2;
  cursor: pointer;

  &::after {
    content: url(${VerticalLine});
    position: absolute;
    width: 24px;
    height: 24px;
    text-align: center;
    cursor: pointer;
    top: 3px;
  }
`;

const CountryFlag = styled.img`
  width: 24px;
  height: 24px;
`;

export const SearchForSabiMerchant = ({
  open,
  cancel,
  setSabiUserEdit,
  setSabiUserUse,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(
    (state) => state.applications.merchbuy.isLoading
  );
  const contacts = useSelector(
    (state) => state.applications.myShop.customersAndReferrals
  );
  const [sabiUser, setSabiUser] = useState(null);
  const [foundSabiUser, setFoundSabiUser] = useState(false);
  const [newUser, setNewUser] = useState(false);
  const [searchedPhoneNumber, setSearchPhoneNumber] = useState("");
  const [contactExist, setContactExist] = useState(false);
  const [value, setValue] = useState({});

  useEffect(() => {
    if (value.msisdn) {
      const found = (contacts || []).find(
        (x) => x.phoneNumber === value.msisdn
      );
      setContactExist(found);
    }
  }, [contacts, value]);

  const onSubmit = async (values) => {
    setSearchPhoneNumber(values.msisdn);
    const user = await dispatch(
      merchbuyActions.getSabiUserDetailByPhoneNumber(values.msisdn)
    );

    if (user) {
      const { name } = user?.role || {};
      if (name === "ROLE_AGENT") {
        return toast.error(
          "Phone number belongs to an Agent, you can only checkout for a merchant"
        );
      }

      const { firstName, lastName, deliverInformation } = user?.data || {};
      const {
        state,
        lga,
        city,
        address,
        phoneNumber,
        deliveryInstruction,
        userId,
      } = deliverInformation || {};

      const info = {
        firstName,
        lastName,
        middleName: "",
        lga,
        state,
        city,
        customerId: userId,
        cordinates: {
          latitude: "",
          longitude: "",
        },
        msisdn: phoneNumber,
        phoneNumber,
        streetAddress: address,
        deliveryInstruction,
        shopNumber: "",
        userId,
        contactType: "CHECKOUT_CUSTOMER",
      };

      setSabiUser(info);
      setFoundSabiUser(true);
    } else {
      setNewUser(true);
    }
  };

  const handleSubmit = (values, setErrors) => {
    setTimeout(() => {
      onSubmit(values);
    }, 200);
  };

  const initialFormValues = {
    msisdn: "",
    country: "SA",
  };

  return (
    open && (
      <Fragment>
        {!foundSabiUser && !newUser && (
          <TopHeader title={"New Address"} noArrow withSpacesHeader>
            <Close left={"16px"} onClick={cancel} />
          </TopHeader>
        )}

        {foundSabiUser && (
          <MerchantFound
            open={foundSabiUser}
            cancel={() => {
              setFoundSabiUser(false);
            }}
            sabiUser={sabiUser}
            setSabiUserEdit={setSabiUserEdit}
            setSabiUserUse={setSabiUserUse}
          />
        )}

        {newUser && (
          <AddNewDeliveryAddressDetails
            open={newUser}
            cancel={() => {
              setNewUser(false);
            }}
            searchPhoneNumber={searchedPhoneNumber}
            setSabiUserUse={(info) => {
              setNewUser(false);
              setSabiUserUse(info);
            }}
          />
        )}

        {!foundSabiUser && !newUser && (
          <ScreenContainer top="0" padding="0">
            <Fragment>
              <Space height="90px" />
              <Padding>
                <Title>New Customer</Title>
                <Subtitle>Please enter customer phone number</Subtitle>
              </Padding>

              <Formik
                initialValues={initialFormValues}
                validationSchema={PhoneNumberValidationSchema}
                onSubmit={(values, { setErrors }) =>
                  handleSubmit(values, setErrors)
                }
              >
                {({
                  errors,
                  touched,
                  values,
                  handleChange,
                  setFieldValue,
                  initialValues,
                  setErrors,
                }) => {
                  setValue(values);
                  return (
                    <Form>
                      <InputBlock>
                        <Padding>
                          <CountryPhoneBlock>
                            <CurrentSelectedCountry>
                              <CountryFlag
                                src={
                                  flags.filter(
                                    (flag) =>
                                      flag.customAbbreviation === values.country
                                  )[0].value
                                }
                                alt={
                                  flags.filter(
                                    (flag) =>
                                      flag.customAbbreviation === values.country
                                  )[0].label
                                }
                              />
                            </CurrentSelectedCountry>
                            <InputWithLabel
                              label={"Phone Number"}
                              type={"text"}
                              value={values.msisdn}
                              placeholder={"Phone Number"}
                              bottom="8px"
                              countryselection
                              name="msisdn"
                              valid={`${touched.msisdn && !errors.msisdn}`}
                              errors={
                                touched &&
                                touched.msisdn &&
                                errors &&
                                errors.msisdn
                              }
                              setFieldValue={setFieldValue}
                              initialValues={initialValues}
                            />
                          </CountryPhoneBlock>
                        </Padding>
                        <Subtitle align="center">
                          An OTP will be sent to this phone number
                        </Subtitle>

                        {contactExist && (
                          <InfoBadge
                            icon={<NewInfoIcon />}
                            title="You have already added this merchant"
                            message="You can search using Merchant’s phone number in your address book."
                            backgroundColor={"#FFF1F0"}
                            margin="0 16px 24px"
                            weight="400"
                            marginTop="4px"
                            color="#A31300"
                            bottom="4px"
                            size="10px"
                            height="16px"
                          />
                        )}

                        <ButtonWrapper>
                          <RippleButton
                            disabled={isLoading || contactExist}
                            type="submit"
                          >
                            Continue
                          </RippleButton>
                        </ButtonWrapper>
                      </InputBlock>
                    </Form>
                  );
                }}
              </Formik>
            </Fragment>
          </ScreenContainer>
        )}
      </Fragment>
    )
  );
};

SearchForSabiMerchant.propTypes = {
  open: bool,
  cancel: func,
};
