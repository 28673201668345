import { push } from "connected-react-router";
import { toast } from "react-toastify";

import { currentAPI, axios } from "../../../../../../config";
import { persistToken } from "../../../../../../utils/auth/persistToken";
import { insertCountryCode } from "../../../../../../utils/inputs/formatPhoneNumber";
//import { fileToFormData } from "../../../../../../utils/files/fileToFormData";
import {
  AUTH_PASSWORD_SETUP,
  SETTINGS_MERCHANT_UPGRADE_COMPLETE,
} from "../../../../../../utils/mix-panel/constants";
import { mixPanel } from "../../../../../../utils/mix-panel/mixPanel";
import { getShops } from "../../../../applications/my-shop/actions/shop";
import {
  getUserAdditionalInfo,
  sendUserInfoOnSignUp,
  resetMerchappPassword,
  storeUserData,
  storeReferringUserData,
} from "../../../../user/actions";
import {
  ACTIVATE_USER_ACCOUNT,
  SAVE_USER_NAME,
  SET_USER_LOGIN_STATUS,
  SAVE_USER_REGION,
  SAVE_USER_REFERRAL_CODE,
  SAVE_USER_ID,
} from "../../../../user/constants";
import {
  CREATING_AGENT_ACCOUNT,
  CREATING_AGENT_ACCOUNT_SUCCESS,
  CREATING_AGENT_ACCOUNT_ERROR,
  LOADING,
} from "../constants";

export const saveAgentReferralCode = (code) => (dispatch) => {
  dispatch({ type: SAVE_USER_REFERRAL_CODE, payload: code });
};

export const saveAgentName = (payload) => (dispatch) => {
  dispatch({ type: SAVE_USER_NAME, payload });
  dispatch(push("/user/create_agent_identity"));
};

export const saveAgentRegion = (region) => (dispatch) => {
  dispatch({ type: SAVE_USER_REGION, payload: region });
  dispatch(push("/user/create_agent_identity"));
};

// When a merchant wants to upgrade to an agent
export const saveMerchantRegion = (region) => (dispatch) => {
  dispatch({ type: SAVE_USER_REGION, payload: region });
  dispatch(push("/actions/um_agent_identity_check"));
};

export const sendAgentRole = (id) => async () => {
  try {
    const response = await axios.put(
      `${currentAPI}/api/users/public/setUserAgentRole/${id}`
    );
    if (response.status === 200) {
      return true;
    }
  } catch (error) {
    console.error(error);
  }
};

export const setMerchantRole = (id) => async () => {
  try {
    const response = await axios.put(
      `${currentAPI}/api/users/public/setUserMerchantRole/${id}`
    );
    if (response.status === 200) {
      return true;
    }
  } catch (error) {
    console.error(error);
  }
};

export const createAgentAccount = (password) => async (dispatch, getState) => {
  dispatch({ type: CREATING_AGENT_ACCOUNT });

  const phoneNumber = getState().user.msisdn;
  const userId = getState().user.userId;
  const isOnMerchApp = getState().user.isOnMerchApp;
  // const initialPath = getState().auth.check.initialPath;
  const role = getState().user.role;
  const startTime = new Date();

  try {
    const responseSignUp = await axios.post(
      `${currentAPI}/api/authorize/${userId}`,
      {
        username: phoneNumber,
        password,
      }
    );

    if (responseSignUp.status === 200) {
      const responseGenerateToken = await axios.post(
        `${currentAPI}/api/token/generate-token`,
        {
          username: phoneNumber,
          password,
        }
      );

      if (responseGenerateToken.status === 200) {
        const tokenData = responseGenerateToken.data;
        persistToken(tokenData);

        isOnMerchApp &&
          tokenData &&
          (await dispatch(
            resetMerchappPassword(phoneNumber, password, tokenData.token)
          ));

        // !isOnMerchApp && tokenData && await dispatch(
        //     createAgentAccountInMerchApp(
        //         password,
        //         phoneNumber,
        //         tokenData.token
        //     )
        // );

        userId && (await dispatch(getUserAdditionalInfo()));
        userId && (await dispatch(sendAgentRole(userId)));
        // isOnMerchApp && await dispatch(getShopFromMerchapp(phoneNumber));

        mixPanel.track(AUTH_PASSWORD_SETUP, {
          Role: role === "ROLE_USER" ? "Merchant" : "Agent",
          Time: Math.round((new Date() - startTime) / 1000),
        });

        dispatch({ type: CREATING_AGENT_ACCOUNT_SUCCESS });
        dispatch({ type: ACTIVATE_USER_ACCOUNT });
        dispatch({ type: SET_USER_LOGIN_STATUS });
        dispatch(push("/my-shop"));
      }
    }
  } catch (error) {
    dispatch({
      type: CREATING_AGENT_ACCOUNT_ERROR,
      payload: error.message,
    });

    toast.error(error.response.data.error);
  }
};

export const createAgentAccountV2 =
  (userData) => async (dispatch, getState) => {
    dispatch({ type: CREATING_AGENT_ACCOUNT });

    const phoneNumber = getState().user.msisdn;
    const initialPath = getState().auth.check.initialPath;
    const startTime = new Date();

    try {
      const payload = {
        country: "South Africa",
        countryCode: "27",
        role: "ROLE_AGENT",
        phoneNumber: phoneNumber,
        msisdn: insertCountryCode(phoneNumber, "+27"),
        referralCode: userData.referralNumber || null,
        accountNumber: userData.accountNumber,
        accountOwnerName: userData.accountOwnerName,
        bankCode: userData.bankCode,
        bankName: userData.bankName,
        currency: userData.currency,
        email: userData.email,
        firstName: userData.firstName,
        lastName: userData.lastName,
        lga: userData.lga,
        password: userData.password,
        state: userData.state,
        agentDocumentsRequest: {
          documentList: [
            {
              documentName: userData.avatar,
              type: userData.passportPhotograph,
            },
            {
              documentName: userData.selectedDocumentUrl,
              type: userData.selectedDocument,
            },
          ],
        },
      };

      const signupResponse = await axios.post(
        `${currentAPI}/api/v2/completeSignup/new`,
        payload
      );

      if (signupResponse.status === 200) {
        const {
          id,
          agentUser,
          tokenData,
          userData: responseData,
        } = signupResponse.data || {};
        persistToken(tokenData);

        id && (await dispatch({ type: SAVE_USER_ID, payload: id }));
        tokenData && (await dispatch(storeUserData(responseData)));
        agentUser && (await dispatch(storeReferringUserData(agentUser.data)));
        tokenData && (await dispatch(getUserAdditionalInfo()));
        tokenData && (await dispatch(getShops()));

        mixPanel.track(AUTH_PASSWORD_SETUP, {
          Role: "Agent",
          Time: Math.round((new Date() - startTime) / 1000),
        });

        dispatch({ type: CREATING_AGENT_ACCOUNT_SUCCESS });
        dispatch({ type: ACTIVATE_USER_ACCOUNT });
        dispatch({ type: SET_USER_LOGIN_STATUS });
        dispatch(push(initialPath ? initialPath : "/"));
        return true;
      }
    } catch (error) {
      dispatch({
        type: CREATING_AGENT_ACCOUNT_ERROR,
        payload: error.message,
      });
      if (error.response && error.response.status === 400) {
        const { data } = (error && error.response) || {};
        data.message && toast.error(error.response.data.message);
      }
      return false;
    }
  };

export const createAgentAccountInMerchApp =
  (password, username, token) => async () => {
    try {
      await axios.post(
        `${currentAPI}/api/merchantAppIntegration/onboardAgent`,
        {
          password,
          username,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

export const sendUserDocumentsOnRegistration =
  (userId, passportUrl, passportLabel, documentUrl, documentLabel) =>
  async (dispatch, getState) => {
    dispatch(request());

    try {
      const updateDocList = () =>
        axios.put(`${currentAPI}/api/users/public/documents/${userId}`, {
          documentList: [
            {
              documentName: documentUrl,
              type: documentLabel,
            },
            {
              documentName: passportUrl,
              type: passportLabel,
            },
          ],
        });

      await dispatch(
        sendUserInfoOnSignUp(userId, { documentType: documentLabel })
      );

      if (documentUrl && passportUrl) {
        const response = await updateDocList();
        dispatch(stoploading());
        if (response.status === 200) {
          return true;
        }
      }
    } catch (error) {
      dispatch(stoploading());
      if (error && error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
      return false;
    }
  };

export const sendUserDocumentsOnUpgrade = () => async (dispatch, getState) => {
  dispatch(request());

  try {
    //const msisdn = getState().user.msisdn;
    const userId = getState().user.userId;
    const document = getState().user.document;
    //const documentData = getState().user.documentData;
    const passport = getState().user.passport;
    //const passportData = getState().user.passportData;
    const docType = getState().user.document.label;
    //const formData = await fileToFormData(document.url, documentData);
    //const formData2 = await fileToFormData(passport.url, passportData);

    // const requestOne = axios.post(
    //     `${currentAPI}/api/storage/uploadAgentDocuments/${encodeURIComponent(msisdn)}/${document.label}`,
    //     formData,
    //     {
    //         headers: {
    //             "Content-Type": "multipart/form-data"
    //         }
    //     }
    // );

    // const requestTwo = axios.post(
    //     `${currentAPI}/api/storage/uploadAgentDocuments/${encodeURIComponent(msisdn)}/${passport.label}`,
    //     formData2,
    //     {
    //         headers: {
    //             "Content-Type": "multipart/form-data"
    //         }
    //     }
    // );
    const requestThree = (/*link1, link2*/) =>
      axios.put(`${currentAPI}/api/users/public/documents/${userId}`, {
        documentList: [
          {
            documentName: document.url,
            type: document.label,
          },
          {
            documentName: passport.url,
            type: passport.label,
          },
        ],
      });

    // const sendUserDocumentResponse = await axios.all([
    //     requestOne,
    //     requestTwo
    // ]);

    await dispatch(
      sendUserInfoOnSignUp(userId, {
        documentType: docType,
      })
    );

    if (document.url !== "" && passport.url !== "") {
      //if (sendUserDocumentResponse[0].status === 200 && sendUserDocumentResponse[1].status === 200) {
      //await requestThree(sendUserDocumentResponse[0].data, sendUserDocumentResponse[1].data);
      await requestThree();
      dispatch(stoploading());
      dispatch(push("/actions/um_agent_bank_account"));
    }
  } catch (error) {
    dispatch(stoploading());
    if (error && error.response) {
      toast.error(error.response.data.message);
    } else {
      toast.error(error.message);
    }
  }
};

export const upgradeMerchantToAgent = () => async (dispatch, getState) => {
  dispatch({ type: CREATING_AGENT_ACCOUNT });

  const firstName = getState().user.firstName;
  const lastName = getState().user.lastName;
  const userId = getState().user.userId;
  const email = getState().user.email;
  const documentType = getState().user.document.label;
  //passport
  const referralCode = getState().user.referralCode;
  const country = getState().user.country;
  const regionData = getState().user.regionData;
  //bank details

  try {
    await dispatch(sendAgentRole(userId));
    await dispatch(
      sendUserInfoOnSignUp(userId, {
        firstName,
        lastName,
        email,
        documentType,
        //passport
        country,
        lga: regionData.lga,
        state: regionData.state,
        referralCode: referralCode || "",
      })
    );

    userId && (await dispatch(getUserAdditionalInfo()));
    mixPanel.track(SETTINGS_MERCHANT_UPGRADE_COMPLETE, {
      "User ID": userId,
      Time: new Date().toLocaleDateString(),
    });
    dispatch({ type: CREATING_AGENT_ACCOUNT_SUCCESS });
    dispatch({ type: ACTIVATE_USER_ACCOUNT });
    dispatch({ type: SET_USER_LOGIN_STATUS });
    dispatch(push("/my-shop"));
  } catch (error) {
    dispatch({
      type: CREATING_AGENT_ACCOUNT_ERROR,
      payload: error.message,
    });

    toast.error(error.response.data.error);
  }
};

function stoploading() {
  return {
    type: LOADING,
    payload: false,
  };
}

function request() {
  return {
    type: LOADING,
    payload: true,
  };
}
