import * as Yup from "yup";

import { containsLettersRegExp } from "../../../../../../utils/regexp/containsLettersRegExp";

export const EditProductValidationSchema = Yup.object().shape({
  productName: Yup.string().nullable().required("Required"),
  retailUnitPrice: Yup.string()
    .nullable()
    .required("Required")
    .matches(containsLettersRegExp, "Retail price cannot contain letters"),
  costPrice: Yup.string()
    .nullable()
    .matches(containsLettersRegExp, "Price cannot contain letters"),
});
