import React, { Fragment, useState, useEffect } from "react";

import Countdown from "react-countdown";
import { connect, useSelector } from "react-redux";

import { TopHeader } from "../../../components";
import History from "../../../utils/History";
import DesktopBackgroundLayout from "../../../views/DesktopBackgroundLayout";
import { ReactComponent as InfoIcon } from "../../assets/icons/info-icon.svg";
import { ReactComponent as MultipleChoiceIcon } from "../../assets/icons/multiple-choice.svg";
import { ReactComponent as QuestionMark } from "../../assets/icons/question-mark.svg";
import { ReactComponent as RewardsIcon } from "../../assets/icons/rewards.svg";
import { ReactComponent as TimeFrameIcon } from "../../assets/icons/time-frame.svg";
import { Button } from "../../components";
import PreviousWinners from "../../containers/previousWinner";
import { utils } from "../../utils";
import Loader from "../loader";
import TermsDialog from "../terms";

import {
  FragmentWrapper,
  PageHeader,
  PageHeaderText,
  RightSide,
  SubHeader,
  SmallText,
  GameExpiryContainer,
  GameExpiryText,
  GameInformationText,
  GameInformation,
  HeaderAvatar,
  LeftSide,
  InfoLeftSide,
  InfoRightSide,
  InfoContainer,
  CountdownText,
  HeaderText,
} from "./styles";

const Home = ({ avatar, username }) => {
  const [openTerms, setOpenTerms] = useState(false);
  const realms = useSelector((state) => state.user.realmNames);
  const currentTimeGreaterThanStartTime =
    utils.iscurrentTimeGreaterThanStartTime();
  const currentTimeGreaterThanEndTime = utils.iscurrentTimeGreaterThanEndTime();
  const todayInMilliSeconds = Date.now();
  const todayFullDate = new Date();
  const tomorrowFullDate = new Date().setDate(todayFullDate.getDate() + 1);
  const tomorrowFormattedDate = utils.formatDate(tomorrowFullDate);
  const todayFormattedDate = utils.formatDate(todayFullDate);
  const milliSecondsTillStartTime =
    todayInMilliSeconds - new Date(`${todayFormattedDate} 05:00:00 PM`);
  const milliSecondsTillEndTime =
    todayInMilliSeconds - new Date(`${todayFormattedDate} 09:00:00 PM`);
  const milliSecondsTillStartTimeTomorrow =
    todayInMilliSeconds - new Date(`${tomorrowFormattedDate} 05:00:00 PM`);

  useEffect(() => {
    if (realms && realms.includes("TRIVIA")) History.push("/more");
  }, [realms]);

  return (
    <Fragment>
      <DesktopBackgroundLayout>
        <Loader open={false} />
        <TermsDialog open={openTerms} cancel={() => setOpenTerms(false)} />
        <TopHeader backLink="/more" title="Games" />
        <PageHeader>
          <LeftSide>
            <HeaderAvatar src={avatar ? avatar : null} />
            <PageHeaderText>Welcome, {username}</PageHeaderText>
          </LeftSide>
          <RightSide>
            <QuestionMark onClick={() => setOpenTerms(true)} />
          </RightSide>
        </PageHeader>
        <SubHeader>Ready to Play? Here’s the 411</SubHeader>
        <FragmentWrapper>
          <InfoContainer>
            <InfoLeftSide>
              <MultipleChoiceIcon />
            </InfoLeftSide>
            <InfoRightSide>
              <HeaderText>20 Questions</HeaderText>
              <SmallText>
                For each question, there is one correct answer. Choose one. If
                it turns green, you got it right.
              </SmallText>
            </InfoRightSide>
          </InfoContainer>
          <InfoContainer>
            <InfoLeftSide>
              <TimeFrameIcon />
            </InfoLeftSide>
            <InfoRightSide>
              <HeaderText>10 Seconds</HeaderText>
              <SmallText>
                You have 10 seconds to answer each question. If you fail to
                answer within that time, you are taken to the next question.
              </SmallText>
            </InfoRightSide>
          </InfoContainer>
          <InfoContainer>
            <InfoLeftSide>
              <RewardsIcon />
            </InfoLeftSide>
            <InfoRightSide>
              <HeaderText>10 Winners</HeaderText>
              <SmallText>
                10 individuals win either N1,000, N500, or N200 everyday. At
                10pm, winners will receive this money in their Spaces Wallets
                which can be used to pay bills and shop on MerchBuy. <br />{" "}
                (ACTIVATE YOUR WALLET BEFORE YOU BEGIN!)
              </SmallText>
            </InfoRightSide>
          </InfoContainer>

          <GameExpiryContainer>
            <GameExpiryText
              color={currentTimeGreaterThanStartTime ? "#fc2d00" : "#56636d"}
            >
              {currentTimeGreaterThanStartTime
                ? "Game expires in"
                : "Game Starts In"}
            </GameExpiryText>
            <Countdown
              date={
                todayInMilliSeconds -
                (currentTimeGreaterThanStartTime
                  ? milliSecondsTillEndTime
                  : currentTimeGreaterThanEndTime
                  ? milliSecondsTillStartTimeTomorrow
                  : milliSecondsTillStartTime)
              }
              onComplete={() => window.location.reload()}
              renderer={(props) => (
                <CountdownText>
                  {`${utils.padNumWithZero(props.hours)}:${utils.padNumWithZero(
                    props.minutes
                  )}:${utils.padNumWithZero(props.seconds)}`}
                </CountdownText>
              )}
            />
            {currentTimeGreaterThanStartTime && (
              <Button
                onClick={() => History.push("/games/play")}
                // disabled={!currentTimeGreaterThanStartTime}
              >
                Play Game
              </Button>
            )}
          </GameExpiryContainer>

          <GameInformation>
            <InfoLeftSide>
              <InfoIcon />
            </InfoLeftSide>
            <InfoRightSide>
              <GameInformationText>
                Quizzes are live everyday between <strong>5pm and 9pm.</strong>{" "}
                Enable notifications to never miss the chance to win.
              </GameInformationText>
            </InfoRightSide>
          </GameInformation>
        </FragmentWrapper>
        <PreviousWinners />
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

Home.propTypes = {};

const mapStateToProps = ({ user }) => ({
  avatar: user.avatar,
});

export default connect(mapStateToProps)(Home);
