export const sortCustomers = (type, customers, setAllCustomers) => {
  if (type === "Most Recent") {
    const newC = customers.sort(
      (a, b) =>
        new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
    );
    setAllCustomers([...newC]);
  } else if (type === "Oldest") {
    const newC = customers.sort(
      (a, b) =>
        new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime()
    );
    setAllCustomers([...newC]);
  } else if (type === "A-Z") {
    setAllCustomers(customers.sort());
  } else if (type === "Z-A") {
    setAllCustomers(customers.sort().reverse());
  }
};
