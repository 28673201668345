import React, { Fragment, useState, useCallback, useEffect } from "react";

import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import {
  SelectBox,
  TextInputWithLabel,
  TopHeader,
  TextareaWithLabel,
  RippleButton,
  Loader,
  FileInput3,
} from "../../../../components";
import { InputBlock } from "../../../../containers/InputContainer";
import { Error } from "../../../../containers/MessageContainer";
import { ScreenContainer } from "../../../../containers/ScreenContainer";
import { logComplaint } from "../../../../redux/ducks/applications/complaints/actions";
import {
  getComplaints,
  getComplaintsCategories,
  getFaqCategories,
} from "../../../../redux/ducks/help/actions";
import DesktopBackgroundLayout from "../../../DesktopBackgroundLayout";

import ComplaintSuccess from "./logcomplaintsuccess";
import { LogComplaintValidationSchema } from "./LogComplaintValidationSchema";
import { colors } from "../../../../styles";

const LogComplaintContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`;
const Text = styled.h3`
  margin-bottom: ${({ bottom }) => bottom || "4px"};
  font-weight: ${({ weight }) => weight || "700"};
  font-size: ${({ size }) => size || "16px"};
  color: ${({ color }) => color || colors.boldDark};
`;

const CountParagraph = styled.p`
  color: ${colors.lightDark};
  font-weight: 400;
  font-size: 10px;
  text-align: right;
  padding-right: 20px;
`;

const Paragraph = styled.p`
  color: ${colors.lightDark};
  font-weight: 400;
  font-size: 10px;
  text-align: left;
`;

const HelpLogComplaint = () => {
  const dispatch = useDispatch();
  const faqCategories = useSelector((state) => state.help.faqCategories);
  const faqFilter =
    faqCategories.filter(
      (data) => data.status === "SHOW_IN_HELPCENTER" && data.name !== "xxxx"
    ) || [];
  const faqFilterWithoutWallet = faqFilter.filter(
    (item) => item.name !== "Wallet"
  );
  const [showSuccess, setShowSuccess] = useState(false);
  const [altLoading, setAltLoading] = useState(false);
  const [formData, setFormData] = useState();
  const [fileName, setFileName] = useState("");
  const limit = 20;
  const departmentId = process.env.REACT_APP_UX_SUPPORT_DEPARTMENT_ID; //country specific
  const [{ content, wordCount }, setContent] = useState({
    content: "",
    wordCount: 0,
  });
  const randomNumber = () => Math.floor(Math.random() * 123456);

  const setFormattedContent = useCallback(
    (text) => {
      let words = text.split(" ").filter(Boolean);
      if (words.length > limit) {
        setContent({
          content: words.slice(0, limit).join(" "),
          wordCount: limit,
        });
      } else {
        setContent({ content: text, wordCount: words.length });
      }
    },
    [limit, setContent]
  );

  const handleSubmit = (values) => {
    const payload = {
      departmentId,
      category: values.categoryName,
      description: content,
      issueReferenceId: values.reference || randomNumber(),
      issueTitle: values.categoryName,
      id: departmentId,
      priority: "normal",
    };
    setAltLoading(true);
    dispatch(logComplaint(payload, formData, fileName)).then((res) => {
      if (res) {
        dispatch(getComplaints());
        setAltLoading(false);
        setShowSuccess(true);
      } else {
        setAltLoading(false);
      }
    });
  };

  useEffect(() => {
    setFormattedContent(content);
  }, [content, setFormattedContent]);

  useEffect(() => {
    dispatch(getComplaintsCategories());
    dispatch(getFaqCategories());
  }, [dispatch]);

  if (altLoading) return <Loader />;

  return (
    <Fragment>
      {showSuccess && (
        <ComplaintSuccess
          open={showSuccess}
          cancel={() => setShowSuccess(!showSuccess)}
        />
      )}

      <DesktopBackgroundLayout>
        {!showSuccess && (
          <TopHeader title={"Log a Complaint/Enquiry"} backLink={"/help"} />
        )}
        <ScreenContainer color="#F9FAFC" minHeight="100vh">
          <LogComplaintContainer>
            <Text>Complaint Information</Text>
            <Text color="#718596" size="14px" weight="400" bottom="8px">
              Kindly provide the following details
            </Text>
            <Formik
              initialValues={{
                categoryName: "",
                reference: "",
                description: content,
                subject: "",
              }}
              validationSchema={LogComplaintValidationSchema}
              onSubmit={(values, { setErrors }) => {
                handleSubmit(values);
              }}
            >
              {({
                errors,
                touched,
                values,
                setFieldValue,
                handleChange,
                initialValues,
              }) => (
                <Form>
                  <InputBlock>
                    <SelectBox
                      name={"categoryName"}
                      placeholder={"Complaint Category"}
                      value={values.categoryName}
                      options={faqFilterWithoutWallet}
                      handleChange={handleChange}
                      valid={`${!touched.categoryName && !errors.categoryName}`}
                      errors={
                        touched &&
                        touched.categoryName &&
                        errors &&
                        errors.categoryName
                      }
                      noSearch={true}
                      bottom="15px"
                      fontSize="16px"
                      opacity="0.5"
                      mbottom={"0"}
                      padding={"10px"}
                      ListHeight={"100%"}
                      height={faqFilter.length > 3 ? "70%" : "50%"}
                      position={"fixed"}
                      contentTop={"20px"}
                    />
                    {errors && errors.categoryName && errors.categoryName && (
                      <Error top="0px">{errors.categoryName}</Error>
                    )}

                    <TextInputWithLabel
                      label={"Reference Number"}
                      type={"text"}
                      value={values.reference}
                      placeholder={"Reference Number"}
                      name="reference"
                      minHeight="56px"
                      valid={`${!touched.reference && !errors.reference}`}
                      errors={
                        touched &&
                        touched.reference &&
                        errors &&
                        errors.reference
                      }
                    />
                    <Paragraph>
                      Eg. Transaction reference or Order number{" "}
                    </Paragraph>

                    <FileInput3
                      mimeType={"image/*"}
                      name="complaintImage"
                      compressFile
                      useFileName
                      useFormData
                      value={formData}
                      height="56px"
                      label={"Complaint Image"}
                      handleChange={(value, name) => {
                        setFormData(value);
                        setFileName(name);
                      }}
                      placeholder="Click to Upload Document here (optional)"
                    />

                    <TextareaWithLabel
                      label={"Description"}
                      value={content}
                      onChange={(event) =>
                        setFormattedContent(event.target.value)
                      }
                      type={"text"}
                      height={"168px"}
                      mtop="16px"
                      width="100%"
                      size={"16px"}
                      inputheight={"150px"}
                      placeholder={"Description"}
                      name={"description"}
                      valid={`${!touched.description && !errors.description}`}
                      errors={
                        touched &&
                        touched.description &&
                        errors &&
                        errors.description
                      }
                      setFieldValue={setFieldValue}
                      initialValues={initialValues}
                    />
                    <CountParagraph>
                      {wordCount}/{limit}
                    </CountParagraph>
                  </InputBlock>
                  <RippleButton
                    disabled={!values.categoryName || !content}
                    top={"40%"}
                    size={"16px"}
                    height={"56px"}
                    type={"submit"}
                  >
                    Save
                  </RippleButton>
                </Form>
              )}
            </Formik>
          </LogComplaintContainer>
        </ScreenContainer>
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default HelpLogComplaint;
