import { USER_LOGOUT } from "../../user/constants";

import {
  LOADING,
  UNPAID_REMITTANCE_ORDER,
  REMITTANCE_METRICS,
  FECTHING_PAYMENT_HISTORY_SUCCESS,
  FETCHING_BY_ORDER_ID,
  FETCHING_DETAILS_BY_ORDER_ID,
} from "./constants";

const initialState = {
  isLoading: false,
  remmittanceHistory: [],
  remmittanceHistoryByOrderId: [],
  amountPendingApproval: "",
  outstandingAmount: "",
  unpaidOrders: [],
  orderDetails: [],
};

export default function remittanceReducer(state = initialState, action) {
  switch (action.type) {
    case LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }

    case FECTHING_PAYMENT_HISTORY_SUCCESS: {
      return {
        ...state,
        remmittanceHistory: action.payload,
      };
    }

    case FETCHING_DETAILS_BY_ORDER_ID: {
      return {
        ...state,
        orderDetails: action.payload,
      };
    }

    case FETCHING_BY_ORDER_ID: {
      return {
        ...state,
        remmittanceHistoryByOrderId: action.payload,
      };
    }

    case UNPAID_REMITTANCE_ORDER: {
      return {
        ...state,
        unpaidOrders: action.payload,
      };
    }

    case REMITTANCE_METRICS: {
      return {
        ...state,
        amountPendingApproval: action.payload.amountPendingApproval,
        outstandingAmount: action.payload.outstandingAmount,
      };
    }

    case USER_LOGOUT: {
      return {
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
}
