import React, { Fragment, useState } from "react";

import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as ArrowIcon } from "../../../../assets/arrow.svg";
import ChevronDownIcon from "../../../../assets/chevron_down.svg";
import {
  InputWithLabel,
  TopHeader,
  RippleButton,
  SelectCountryOverlay,
  Loader,
} from "../../../../components";
import { MenuOptionLogo } from "../../../../containers/MenuContainer";
import { Message, Title } from "../../../../containers/MessageContainer";
import { ScreenContainer } from "../../../../containers/ScreenContainer";
import { flags, countriesData } from "../../../../data/countries";
import { shsActions } from "../../../../redux/ducks/applications/shs/actions";
import { colors } from "../../../../styles";
import { listToAlphabetMap } from "../../../../utils/sorting/alphabeticSort";
import DesktopBackgroundLayout from "../../../DesktopBackgroundLayout";

import deviceSvg from "./assets/device.svg";
import { PhoneNumberValidationSchema } from "./PhoneNumberValidationSchema";

const CountryPhoneBlock = styled.div`
  position: relative;
`;

const CurrentSelectedCountry = styled.div`
  position: absolute;
  left: 16px;
  top: 12px;
  z-index: 2;
  cursor: pointer;

  &::after {
    content: url(${ChevronDownIcon});
    position: absolute;
    width: 24px;
    height: 24px;
    text-align: center;
    cursor: pointer;
    top: 3px;
  }
`;

const CountryFlag = styled.img`
  width: 24px;
  height: 24px;
`;

const MenuOption = styled.li`
  display: flex;
  flex-direction: row;
  padding: 8px 0 8px 0;
  align-items: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  padding-top: 16px;
  padding-bottom: 16px;
`;

const OptionName = styled.h4`
  font-weight: 400;
  color: #0b0c0e;
  font-size: 14px;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 4px;
`;

const OptionSubText = styled.h4`
  font-weight: 400;
  color: #6f798b;
  font-size: 12px;
  margin: auto 16px;
`;

const OptionWithSubText = styled.div`
  display: flex;
  flex-direction: column;
`;

const ArrowForward = styled(ArrowIcon)`
  position: absolute;
  right: 16px;
  top: 16px;
`;

const ShsTransfer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector((state) => state.applications.shs.isLoading);
  const [openCountrySelect, setOpenCountrySelect] = useState(false);
  const [customerInfo, setCustomerInfo] = useState({});
  const [devicesFound, setDevicesFound] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const countriesInfo = listToAlphabetMap(
    countriesData
      .filter((country) => country.name === "Nigeria")
      .map((country) => country.name)
  );

  if (isLoading) return <Loader />;
  return (
    <Fragment>
      <DesktopBackgroundLayout>
        <TopHeader title={"SHS transfer"} />
        <ScreenContainer>
          <Title top={"40px"} textAlign="left">
            Recipient's Phone number
          </Title>
          <Message bottom={"24px"} top={"6px"}>
            Enter recipient's registered phone number
          </Message>
          <Formik
            initialValues={{
              phoneNumber: phoneNumber || "",
              country: "SA",
            }}
            validationSchema={PhoneNumberValidationSchema}
            onSubmit={async (values, { resetForm }) => {
              const phone = `0${values.phoneNumber}`;

              setPhoneNumber(phone);
              const res = await dispatch(shsActions.getLinkedDevices(phone));
              res && setCustomerInfo(res);
              res && setDevicesFound(res?.linkedDevices || []);
            }}
          >
            {({ touched, values, errors, initialValues, setFieldValue }) => (
              <Form>
                <CountryPhoneBlock>
                  <CurrentSelectedCountry>
                    <CountryFlag
                      onClick={() => setOpenCountrySelect(!openCountrySelect)}
                      src={
                        flags.filter(
                          (flag) => flag.customAbbreviation === values.country
                        )[0].value
                      }
                      alt={
                        flags.filter(
                          (flag) => flag.customAbbreviation === values.country
                        )[0].label
                      }
                    />
                  </CurrentSelectedCountry>
                  <SelectCountryOverlay
                    open={openCountrySelect}
                    setOpen={setOpenCountrySelect}
                    countriesInfo={countriesInfo}
                    currentCountry={values.country}
                    setCountry={setFieldValue}
                  />
                  <InputWithLabel
                    type="number"
                    inputMode={"tel"}
                    name="phoneNumber"
                    label="Phone number"
                    placeholder="Phone number"
                    onKeyUp={(e) => {
                      e.target.value = e.target.value.replace(/\s/g, "");
                      setDevicesFound([]);
                    }}
                    autoComplete={"tel"}
                    countryselection={true}
                    errors={
                      touched &&
                      touched.phoneNumber &&
                      errors &&
                      errors.phoneNumber
                    }
                    valid={`${touched.phoneNumber && !errors.phoneNumber}`}
                    setFieldValue={setFieldValue}
                    initialValues={initialValues}
                  />
                </CountryPhoneBlock>

                {!isLoading && (!devicesFound || devicesFound.length === 0) && (
                  <RippleButton
                    type="submit"
                    disabled={
                      values.phoneNumber.length < 10 || errors.phoneNumber
                    }
                    top={"8px"}
                    backgroundColor={colors.deepBlue}
                  >
                    Search Device
                  </RippleButton>
                )}
              </Form>
            )}
          </Formik>
          {devicesFound && devicesFound.length > 0 && (
            <div>
              <Title top={"40px"} textAlign="left">
                {devicesFound.length} Device(s) Found
              </Title>
              <Message bottom={"24px"} top={"6px"}>
                Select the device you want to recharge
              </Message>
              {devicesFound.map((device, index) => (
                <MenuOption
                  key={index}
                  onClick={() => {
                    history.push({
                      pathname: "/actions/shs_recharge",
                      state: {
                        data: customerInfo,
                        selected: device.serialNumber,
                      },
                    });
                  }}
                >
                  <MenuOptionLogo icon={deviceSvg} />
                  <OptionWithSubText>
                    <OptionName>{device.serialNumber}</OptionName>
                    <OptionSubText>{`${customerInfo.customerFirstName} ${customerInfo.customerLastName}`}</OptionSubText>
                  </OptionWithSubText>
                  <ArrowForward />
                </MenuOption>
              ))}
            </div>
          )}
        </ScreenContainer>
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default ShsTransfer;
