import * as Yup from "yup";

export const DeliveryAddressValidationSchema = Yup.object().shape({
  state: Yup.string().nullable().required("Please, select one of the options"),
  lga: Yup.string().nullable().required("Please, select one of the options"),
  city: Yup.string().nullable().required("Please, select one of the options"),
  shopAddress: Yup.string()
    .nullable()
    .required("Please, enter the street address"),
});
