import React, { Fragment, useState } from "react";

import moment from "moment";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { RippleButton, SharePopup } from "../../../../../components";
import { currentHost } from "../../../../../config/API";
import { colors } from "../../../../../styles";
import { formatPrice } from "../../../../../utils/currency/formatPriceWithComma";
import { formatCurrency } from "../../../../../utils/currency/parseBalance";
import { downloadSalesReceipt } from "../../../../../utils/files/htmlToFormData";
import { SHARE_SALES_RECEIPT } from "../../../../../utils/mix-panel/constants";
import { mixPanel } from "../../../../../utils/mix-panel/mixPanel";
import DesktopBackgroundLayout from "../../../../DesktopBackgroundLayout";
import { ReactComponent as CongratulationsLogo } from "../assets/Congratulations.svg";
import { ReactComponent as FileLogo } from "../assets/file.svg";
import { ReactComponent as SabiLogo } from "../assets/sabi.svg";
import { ReactComponent as StoreLogo } from "../assets/store.svg";

const ReceiptHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 16px 22px 16px;
  align-items: center;
  justify-content: center;
`;

const PageTitleWrapper = styled.span`
  display: flex;
  align-items: center;
`;

const PageReceiptWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 24px;
  border-radius: 2px;
  background: #6f89f5;
  margin-top: 14px;
`;

const PageTitle = styled.span`
  color: ${colors.white};
  padding-left: 9.2px;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
`;

const PageReceipt = styled.span`
  font-size: 10px;
  line-height: 16px;
  color: ${colors.white};
  padding-left: 8.33px;
  letter-spacing: 0.01em;
`;

const Container = styled.div`
  min-height: 419px;
  margin: 0 16px;
  background: ${colors.white};
  border-radius: 4px 4px 0 0;
  padding: 30px 0 87px 0;
`;

const Wrapper = styled.div`
  min-height: 419px;
  width: 100%;
  background: linear-gradient(106.74deg, #5271f2 0%, #3d60f3 97.91%);
`;

const CongratulationsWrapper = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.white};
`;
const SabiWrapper = styled.div`
  height: 56px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.gray4};
`;

const SabiText = styled.div`
  font-weight: bold;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: ${colors.lightDark};
`;

const SabiBorder = styled.div`
  width: 22px;
  height: 0px;
  border: 1px solid #cbd6e0;
  transform: rotate(90deg);
  margin: 0 13px;
`;

const RowWrapper = styled.div`
  display: flex;
  justify-content: ${({ justify }) => justify || "space-between"};
  align-items: ${({ noCenter }) => !noCenter && "center"};
  margin-top: ${({ top }) => top || null};
  margin-bottom: ${({ bottom }) => bottom || null};
  padding: ${({ noPadding }) => !noPadding && "0 16px"};
`;

const Text = styled.div`
  color: ${({ color }) => color || "#071827"};
  font-size: ${({ size }) => size || "14px"};
  margin-top: ${({ top }) => top || ""};
  margin-bottom: ${({ bottom }) => bottom || ""};
  margin-left: ${({ left }) => left || ""};
  margin-right: ${({ right }) => right || ""};
  font-weight: ${({ weight }) => weight || ""};
  line-height: ${({ lineHeight }) => lineHeight || null};
  width: ${({ width }) => width};
  text-align: ${({ align }) => align};
  ${({ elipsis }) =>
    elipsis &&
    `white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100px;
  `}
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${colors.gray4};
  margin-top: ${({ top }) => top};
  margin-bottom: ${({ bottom }) => bottom};
`;

const BalanceWrapper = styled.div`
  width: 222px;
  height: 45px;
  background: #ffc3bd;
  border-radius: 8px 0 0 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px 0 25px;
`;

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

const SaleReceipt = () => {
  const location = useLocation();

  const sale = location.state.sale;
  const shopId = location.state.shopId;
  const id = sale.salesInfo ? sale.salesInfo.id : sale.id;
  const customerName =
    sale.salesInfo && sale.salesInfo.customer
      ? sale.salesInfo.customer.name
      : sale.customer && sale.customer.name;
  const totalAmount = sale.salesInfo
    ? sale.salesInfo.totalAmount
    : sale.totalAmount;
  const discount = sale.salesInfo ? sale.salesInfo.discount : sale.discount;
  const saleItemDetails = sale.salesInfo
    ? sale.salesInfo.salesItemDetails
    : sale.salesItemDetails;
  const salesDate = sale.salesInfo
    ? sale.salesInfo.localSalesDate
    : sale.localSalesDate;

  const shop = useSelector((state) => state.applications.myShop.shops).find(
    (shop) => shop.id === shopId
  );
  const shopName = shop && shop.shopName;

  const [openShareLinks, setOpenShareLinks] = useState(false);
  const url = `${currentHost}/receipt?id=${id}`;

  const shareRecieptAction = () => {
    setOpenShareLinks(!openShareLinks);
    mixPanel.track(SHARE_SALES_RECEIPT, {
      "Sales id": id,
      Time: new Date().toLocaleString(),
    });
  };

  return (
    <Fragment>
      <DesktopBackgroundLayout fullHeight bgColor={colors.gray4}>
        <div id="receipt">
          <Wrapper>
            <ReceiptHeader>
              <PageTitleWrapper>
                <StoreLogo />
                <PageTitle>{shopName && shopName}</PageTitle>
              </PageTitleWrapper>
              <PageReceiptWrapper>
                <FileLogo />
                <PageReceipt>Sales Receipt</PageReceipt>
              </PageReceiptWrapper>
            </ReceiptHeader>
            <Container>
              <RowWrapper noCenter>
                <Text size="12px" lineHeight="18px">
                  N{id}
                </Text>
                <div>
                  {customerName && (
                    <RowWrapper noPadding>
                      <Text
                        right="29px"
                        weight="500"
                        color={colors.lightDark}
                        size="12px"
                        width="70px"
                        lineHeight="18px"
                        align="right"
                      >
                        Customer
                      </Text>
                      <Text size="12px" lineHeight="18px">
                        {customerName}
                      </Text>
                    </RowWrapper>
                  )}
                  <RowWrapper noPadding top={customerName && "4px"}>
                    <Text
                      right="29px"
                      weight="500"
                      color={colors.lightDark}
                      size="12px"
                      lineHeight="18px"
                      width="70px"
                      align="right"
                    >
                      Date
                    </Text>
                    <Text size="12px" lineHeight="18px">
                      {moment(salesDate).format("MMM DD, YYYY")}
                    </Text>
                  </RowWrapper>
                </div>
              </RowWrapper>
              <Line top="32px" bottom="12px" />
              <RowWrapper>
                <Text
                  weight="500"
                  color={colors.lightDark}
                  size="10px"
                  lineHeight="16px"
                >
                  Products
                </Text>
                <div>
                  <RowWrapper noPadding>
                    <Text
                      right="74px"
                      weight="500"
                      color={colors.lightDark}
                      size="10px"
                      lineHeight="18px"
                      align="right"
                    >
                      Quantity
                    </Text>
                    <Text
                      weight="500"
                      color={colors.lightDark}
                      size="10px"
                      lineHeight="18px"
                      align="right"
                    >
                      Amount
                    </Text>
                  </RowWrapper>
                </div>
              </RowWrapper>
              <Line top="11px" bottom="10px" />
              {saleItemDetails?.length === 0 ? (
                <RowWrapper>
                  <Text color={colors.lightDark} size="12px" lineHeight="18px">
                    No products added
                  </Text>
                </RowWrapper>
              ) : (
                saleItemDetails.map((item, index) => (
                  <RowWrapper top={index > 1 && "12px"} key={index}>
                    <Text elipsis size="12px" lineHeight="18px">
                      {capitalizeFirstLetter(item.name)}
                    </Text>
                    <div>
                      <RowWrapper noPadding>
                        <Text
                          right="87px"
                          size="12px"
                          lineHeight="18px"
                          align="center"
                        >
                          {item.quantity}
                        </Text>
                        <Text size="12px" lineHeight="18px" align="center">
                          {formatCurrency(item.itemPrice)}
                        </Text>
                      </RowWrapper>
                    </div>
                  </RowWrapper>
                ))
              )}
              <Line top="12px" bottom="16px" />
              <RowWrapper justify="flex-end">
                <div>
                  {discount > 0 && (
                    <RowWrapper noPadding bottom="12px">
                      <Text
                        right="48px"
                        weight="500"
                        size="12px"
                        lineHeight="18px"
                        width="120px"
                        align="right"
                      >
                        Sub Total
                      </Text>
                      <Text weight="bold" size="12px" lineHeight="18px">
                        {formatPrice(totalAmount + discount)}
                      </Text>
                    </RowWrapper>
                  )}
                  {discount > 0 && (
                    <RowWrapper noPadding bottom="12px">
                      <Text
                        right="48px"
                        weight="500"
                        size="12px"
                        lineHeight="18px"
                        width="120px"
                        align="right"
                      >
                        Discount
                      </Text>
                      <Text weight="bold" size="12px" lineHeight="18px">
                        ({formatCurrency(discount)})
                      </Text>
                    </RowWrapper>
                  )}
                  <RowWrapper noPadding bottom="12px">
                    <Text
                      right="48px"
                      weight="500"
                      size="12px"
                      lineHeight="18px"
                      width="120px"
                      align="right"
                    >
                      Total
                    </Text>
                    <Text weight="bold" size="12px" lineHeight="18px">
                      {formatPrice(totalAmount)}
                    </Text>
                  </RowWrapper>
                  <RowWrapper noPadding bottom="12px">
                    <Text
                      right="48px"
                      weight="500"
                      size="12px"
                      lineHeight="18px"
                      width="120px"
                      align="right"
                    >
                      Paid
                    </Text>
                    <Text
                      color={colors.textGreen}
                      weight="bold"
                      size="12px"
                      lineHeight="18px"
                    >
                      {formatPrice(sale?.amountCollected)}
                    </Text>
                  </RowWrapper>
                </div>
              </RowWrapper>
              {sale?.amountOutstanding > 0 && (
                <RowWrapper noPadding justify="flex-end">
                  <BalanceWrapper>
                    <Text weight="500" size="12px" lineHeight="18px">
                      Balance Due
                    </Text>
                    <Text
                      color="#A31300"
                      weight="bold"
                      size="12px"
                      lineHeight="18px"
                    >
                      {formatPrice(sale?.amountOutstanding)}
                    </Text>
                  </BalanceWrapper>
                </RowWrapper>
              )}
            </Container>
            <CongratulationsWrapper>
              <CongratulationsLogo />
            </CongratulationsWrapper>
          </Wrapper>
          <SabiWrapper>
            <SabiText>POWERED BY</SabiText>
            <SabiBorder />
            <SabiLogo />
          </SabiWrapper>
        </div>
        <RowWrapper justify="center">
          <RippleButton
            type={"button"}
            width="120px"
            size="12px"
            right="20px"
            height="35px"
            disabled={true}
            onClick={shareRecieptAction}
          >
            Share Receipt
          </RippleButton>

          <RippleButton
            type={"button"}
            width="120px"
            size="12px"
            height="35px"
            backgroundColor="#e9f6ff"
            color="#22a8ff"
            border="1px solid #22a8ff"
            onClick={async () => {
              await downloadSalesReceipt("receipt");
            }}
          >
            Download Receipt
          </RippleButton>
        </RowWrapper>
        {openShareLinks && (
          <SharePopup
            url={url}
            marketingMessage={"Here is your receipt"}
            shareMessage={"Share receipt"}
            open={openShareLinks}
            setOpen={setOpenShareLinks}
          />
        )}
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default SaleReceipt;
