import styled, { css } from "styled-components";

import NotificationIcon from "./assets/bell.svg";
import NotificationActiveIcon from "./assets/bell_active.svg";

export const NotificationBell = styled.div`
  padding: 5.33px 6.33px;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  background-color: white;
  margin-right: 8px;
  width: 24px;
  height: 24px;
  ${({ notifications }) =>
    notifications.some((notification) => notification.state === "UNREAD")
      ? css`
          background-image: url(${NotificationActiveIcon});
        `
      : css`
          background-image: url(${NotificationIcon});
        `}
`;
