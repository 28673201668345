import { Form } from "formik";
import styled, { css } from "styled-components";

import { colors } from "../../../styles";

import { ReactComponent as AddIcon } from "./assets/add.svg";
import { ReactComponent as AddOrderIcon } from "./assets/add_order.svg";
import { ReactComponent as CirclePending } from "./assets/circle_pending.svg";
import { ReactComponent as CirclePlus } from "./assets/circle_plus.svg";
import { ReactComponent as CircleRejected } from "./assets/circle_rejected.svg";
import { ReactComponent as CircleSuccess } from "./assets/circle_success.svg";
// import { ReactComponent as HomeIcon } from './assets/home.svg';
import { ReactComponent as PaymentIcon } from "./assets/payment.svg";
import { ReactComponent as SideHomeIcon } from "./assets/side-home-icon.svg";

export const CirclePlusIcon = styled(CirclePlus)`
  margin-right: 10px;
`;

export const StyledAddOrderIcon = styled(AddOrderIcon)`
  display: flex;
  margin-top: 8px;
`;

export const CirclePendingIcon = styled(CirclePending)`
  margin-right: 10px;
`;

export const CircleRejectedIcon = styled(CircleRejected)`
  margin-right: 10px;
`;

export const CircleSuccessIcon = styled(CircleSuccess)`
  margin-right: 4px;
`;

export const StyledRemitIcon = styled(AddIcon)`
  margin-right: 10px;
`;

export const StyledHomeIcon = styled(SideHomeIcon)`
  margin-right: 16px;
`;
export const ContainerDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ bottom }) => bottom || null};
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${({ padding }) => padding || null};
`;

export const OrderNumber = styled.span`
  color: ${colors.boldDark};
  font-weight: 400;
  line-height: 18px;
  font-size: 12px;
  margin: ${({ margin }) => margin || "0px"};
`;

export const PaymentStyledIcon = styled(PaymentIcon)`
  margin-right: 4px;
  margin-bottom: -5px;
`;

export const PaymentAmount = styled.span`
  color: ${colors.darkRed};
  font-weight: 500;
  line-height: 21px;
  font-size: 14px;
`;

export const Text = styled.span`
  display: ${({ display }) => display || "flex"};
  color: ${({ color }) => color || colors.themeSubColor1};
  font-weight: ${({ weight }) => weight || "400"};
  line-height: ${({ lineHeight }) => lineHeight || "18px"};
  font-size: ${({ size }) => size || "12px"};
  margin-top: ${({ top }) => top || null};
  margin-right: ${({ right }) => right || null};
  margin-left: ${({ left }) => left || null};
  align-items: center;
  opacity: ${({ opacity }) => opacity || 1};
  text-align: ${({ align }) => align};
`;

export const Divider = styled.div`
  border-bottom: 1px solid ${colors.gray4};
  margin-top: ${({ top }) => top || "8px"};
  margin-bottom: ${({ bottom }) => bottom || "8px"};
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  padding: ${({ padding }) => padding || null};
  margin: ${({ margin }) => margin || null};
  background-color: ${colors.white};
  flex-direction: column;
`;

export const ListHolder = styled.div`
  margin-bottom: 32px;
`;

export const FilterSection = styled.div`
  display: flex;
  padding: ${({ top }) => top || "24px"} 16px 16px;
`;

export const FilterChip = styled.p`
  display: inline-block;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  padding: 6px 12px;
  border-radius: ${({ radius }) => radius || "14px"};
  height: ${({ height }) => height || null};
  margin-right: 26px;
  margin-left: -15px;
  background: ${colors.white};
  color: ${({ active }) => (active ? colors.boldDark : colors.lightDark)};
  border: ${({ active }) =>
    active ? `2px solid ${colors.deepBlue}` : `1px solid ${colors.lightGray}`};
`;

export const RemittanceBlock = styled.div`
  display: flex;
  padding: ${({ padding }) => padding || "3px 14px"};
  margin: 0 -1em;
  align-items: center;
  margin-bottom: 2px;
  align-items: center;
  background-color: ${colors.white};
`;

export const OrderText = styled.span`
  font-size: 12px;
  font-weight: 400;
  display: flex;
  margin-top: ${({ top }) => top || "10px"};
  color: #353645;
`;

export const OrderBlock = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction || "row"};
  padding: 3px 14px;
  margin: 0 -1em;
  align-items: center;
  margin-bottom: 4px;
  align-items: center;
  background-color: ${colors.white};
`;

export const OrderInfo = styled.div`
  flex: 1;
  font-size: 14px;
  margin: 0 -1em;
  padding: 2px 0 7px 10px;
  border-bottom: 3px solid #edf2f7;
`;

export const BalanceText = styled.div`
  color: #718596;
  font-weight: 400;
  padding: 1px 0;
  font-size: 12px;
  display: flex;
  margin-top: ${({ top }) => top || "7px"};
  margin-bottom: ${({ bottom }) => bottom || null};
  margin-left: ${({ left }) => left || null};
`;

export const RemittanceImage = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid ${colors.aliceBlue};
  background-color: ${colors.aliceBlue};
  object-fit: contain;
  margin-right: 16px;
`;

export const RemittanceInfo = styled.div`
  flex: 1;
  font-size: 14px;
  padding: 2px 0 7px 10px;
`;

export const RemitDate = styled.div`
  color: #071827;
  font-weight: 500;
  padding: 1px 0;
  font-size: 12px;
  display: flex;
  margin-top: ${({ top }) => top || "7px"};
  margin-bottom: ${({ bottom }) => bottom || null};
  margin-left: -10px;
`;

export const RemitOrders = styled.div`
  color: ${colors.lightDark};
  font-size: 12px;
  font-weight: 400;
  margin-top: 4px;
  margin-left: -10px;
`;

export const StatusAndDot = styled.span`
  display: flex;
  color: ${colors.lightDark};
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  align-items: center;
`;

export const Amount = styled.span`
  font-size: 12px;
  font-weight: 500;
`;

export const MetricBoard = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.white};
  box-shadow: 0px 15px 84px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  width: 50%;
  margin-left: ${({ left }) => left || null};
  margin-right: ${({ right }) => right || null};
  padding: 16px;
  min-height: 78px;
`;

export const OrderDetailCards = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #edf2f7;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 12px;
  margin-top: 8px;
`;

export const Card = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: ${({ padding }) => padding || null};
`;

export const HeaderSpace = styled.div`
  margin: 16px -1em 0;
  border-bottom: 1px solid ${colors.headerBorder};
`;

export const PageText = styled.span`
  display: flex;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  margin-top: ${({ top }) => top || "24px"};
  color: ${({ color }) => color || colors.popupText};
`;

export const PageSubText = styled.span`
    display: flex;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin-top: ${({ top }) => top || "4px"};
    margin-bottom ${({ bottom }) => bottom || "16px"};
    color: ${colors.lightDark};
`;

export const FlexFormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 90%;
  padding-bottom: 16px;
  justify-content: space-between;
`;

export const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const NoRemittanceWrapper = styled.div`
  diplay: flex;
  flex-direction: column;
  margin: 24px 0 43px;
  padding: 48px 24px;
  align-items: center;
  background: ${colors.white};
  box-shadow: 0px 15px 84px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
`;

export const NoRemittanceLogo = styled.div`
  width: 100%;
  height: 122px;
  background-image: url(${({ icon }) => icon});
  background-repeat: no-repeat;
  background-position: center;
`;

export const ColoredCircle = styled.span`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin: 0px 4px;
  align-items: center;
  justify-content: center;
  background-color: ${colors.gray5};
  ${({ status }) =>
    (status === "CONFIRMED" ||
      status === "SUCCESS" ||
      status === "DELIVERED") &&
    css`
      background-color: ${colors.remittance.success};
    `};

  ${({ status }) =>
    (status === "PENDING" || status === "PACKED") &&
    css`
      background-color: ${colors.remittance.pending};
    `};

  ${({ status }) =>
    status === "REJECTED" &&
    css`
      background-color: ${colors.remittance.rejected};
    `};

  ${({ status }) =>
    status === "SHIPPED" &&
    css`
      background-color: ${colors.remittance.shipped};
    `};

  ${({ status }) =>
    status === "NEW" &&
    css`
      background-color: ${colors.remittance.shipped};
    `};
`;

export const LightText = styled(Text)`
  display: flex;
  line-height: 21px;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  ${({ status }) =>
    status === "CONFIRMED" &&
    css`
      color: ${colors.remittance.successful};
    `};

  ${({ status }) =>
    status === "PENDING" &&
    css`
      color: ${colors.remittance.pending};
    `};

  ${({ status }) =>
    status === "REJECTED" &&
    css`
      color: ${colors.remittance.rejected};
    `};
`;

export const Backdrop = styled.div`
  height: 100%;
  width: 100%;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${colors.white};
  position: fixed;
  bottom: 0;
  padding: 32px 16px;
  border-radius: 16px 16px 0 0;
  @media (min-width: 576px) {
    padding-bottom: 64px;
  }
`;

export const EmptyStateText = styled.h2`
  font-size: ${({ size }) => size || "12px"};
  font-weight: ${({ weight }) => weight || "700"};
  color: ${({ color }) => color || colors.lightDark};
  text-align: ${({ align }) => align || "center"};
  line-height: ${({ lineHeight }) => lineHeight || "21px"};
  padding: 2px;
  margin-left: ${({ left }) => left || null};
  margin-top: ${({ top }) => top || null};
`;

export const RemittanceAmountStatus = styled.div`
  display: flex;
  padding: 9px 16px 12px;
  background: ${colors.bgAmount};
  margin: 0 -1em;
`;

export const AmountText = styled.div`
  display: flex;
  color: ${({ color }) => color || colors.themeSubColor1};
  font-weight: ${({ weight }) => weight || "400"};
  line-height: ${({ lineHeight }) => lineHeight || "18px"};
  font-size: ${({ size }) => size || "12px"};
  margin-top: ${({ top }) => top || null};
  margin-right: ${({ right }) => right || null};
  margin-left: ${({ left }) => left || null};
  align-items: center;
  opacity: ${({ opacity }) => opacity || 1};
`;
