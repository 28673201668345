import React from "react";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { RippleButton } from "../../../../../components";
import { DebtReminderModal } from "../../../../../components/popup";
import { saveLastActionUrl } from "../../../../../redux/ducks/account/wallet/actions";
import { colors } from "../../../../../styles";
import { ReactComponent as WalletIcon } from "../assets/wallet.svg";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 27px 24px 32px 24px;
`;
const PaddingTop = styled.div`
  padding-top: ${({ top }) => top};
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: ${colors.bgBlue};
  text-align: left;
`;

const SubTitle = styled.div`
  font-size: 14px;
  line-height: 21px;
  color: ${colors.lightDark};
  text-align: left;
`;

const WalletPopup = ({ open, debtorId }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(saveLastActionUrl(`/actions/debtors/${debtorId}`));
    history.push("/user/account_bank_data");
  };
  return (
    <DebtReminderModal noHeader={true} height="299px" open={open}>
      <Wrapper>
        <PaddingTop top="0">
          <WalletIcon />
        </PaddingTop>
        <PaddingTop top="10px">
          <Title>Activate your wallet to collect debtor’s payment</Title>
        </PaddingTop>
        <PaddingTop top="16px">
          <SubTitle>
            You need to set up your wallet so you can collect your payment from
            your debtors
          </SubTitle>
        </PaddingTop>
        <RippleButton onClick={handleClick}>Setup Wallet</RippleButton>
      </Wrapper>
    </DebtReminderModal>
  );
};

export default WalletPopup;
