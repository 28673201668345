import React, { Fragment, useState, useEffect } from "react";

import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import {
  TopHeader,
  PasswordGroup,
  SuccessPage,
  Loader,
} from "../../../../../components";
import { Message } from "../../../../../containers/MessageContainer";
import { verifyPin } from "../../../../../redux/ducks/account/wallet/actions";
import { colors } from "../../../../../styles";
import { formatPrice } from "../../../../../utils/currency/formatPriceWithComma";
import { getInputValues } from "../../../../../utils/inputs/getInputValues";
import { ReactComponent as FromSvg } from "../assets/from.svg";
import { ReactComponent as SpacesLogo } from "../assets/small_spaces_logo.svg";
import { ReactComponent as ToSvg } from "../assets/to.svg";
import { ReactComponent as LineSvg } from "../assets/vline.svg";

const ParentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.bgBlue};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const LinearLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
  width: 100%;
  height: 100%;
`;

const TopCard = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  background: ${colors.white};
  padding: 32px 16px 24px 16px;
  border-radius: 8px 8px 0 0;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.05);
  z-index: 1;
`;

const BottomCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  background: ${colors.white};
  padding: 16px;
  border-radius: 0 0 8px 8px;
  border-top-style: dashed;
  border-width: 1px;
  border-color: ${colors.cardBorder};
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.05);
  z-index: 1;
`;

const BottomParentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${colors.white};
  width: 100%;
  height: 100%;
`;

const VerticalDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${({ right }) => right || null};
`;

const HorizontalDiv = styled.div`
  display: flex;
`;

const MLineSvg = styled(LineSvg)`
  margin: 0 0 2px 3px;
`;

const FormSection = styled(Form)`
  display: flex;
  height: 100%;
  width: 100%;
  padding: 0px 20px;
  flex-direction: column;
  justify-content: space-between;
`;

export const CheckoutWithWalletPin = ({
  amount,
  setShowPin,
  setShouldPlaceOrder,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.account.wallet.isLoading);
  const walletBalance = useSelector(
    (state) => state.account.wallet.balance || 0
  );

  const [pin, setPin] = useState("");
  const [value, setValue] = useState("");
  const [wrongPin, setWrongPin] = useState(false);

  useEffect(() => {
    if (pin && pin.length === 4) {
      dispatch(verifyPin(pin)).then((verifiedPin) => {
        if (verifiedPin) {
          setShowPin(false);
          setWrongPin(false);
          setShouldPlaceOrder(verifiedPin);
        } else {
          setWrongPin(true);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pin]);

  if (isLoading) return <Loader text="Processing" />;
  return (
    <Fragment>
      <TopHeader
        title={"Pay"}
        backgroundColor={colors.bgBlue}
        color={colors.white}
        opaqueBackButton
        noBorderBottom
        backAction={() => setShowPin(false)}
        backLink="#"
      />
      <ParentWrapper>
        <LinearLayout>
          <TopCard>
            <HorizontalDiv>
              <VerticalDiv right="22px">
                <FromSvg />
                <MLineSvg />
                <ToSvg />
              </VerticalDiv>
              <VerticalDiv>
                <VerticalDiv>
                  <Message top="-5px" color="#718596" size="10px" weight="400">
                    FROM
                  </Message>
                  <Message top="0px" color="#1A2630" size="14px" weight="500">
                    My Wallet
                  </Message>
                  <Message
                    top="0px"
                    color="#071827"
                    size="12px"
                    weight="400"
                  >{`Bal: ${formatPrice(walletBalance || 0)}`}</Message>
                </VerticalDiv>
                <VerticalDiv>
                  <Message
                    top="22px"
                    bottom="6px"
                    color="#516C82"
                    size="10px"
                    weight="400"
                  >
                    TO
                  </Message>
                  <HorizontalDiv>
                    <SpacesLogo />
                    <VerticalDiv>
                      <Message
                        left="8px"
                        top="3px"
                        color="#1A2630"
                        size="14px"
                        weight="500"
                        lineHeight="21px"
                      >
                        Merchbuy Purchase
                      </Message>
                    </VerticalDiv>
                  </HorizontalDiv>
                </VerticalDiv>
              </VerticalDiv>
            </HorizontalDiv>
          </TopCard>
          <BottomParentWrapper>
            <BottomCard>
              <Message top="0px" color="#718596" size="12px" weight="400">
                Amount
              </Message>
              <Message
                top="0px"
                color="#071827"
                size="16px"
                weight="700"
              >{`${formatPrice(amount || 0)}`}</Message>
            </BottomCard>
            <VerticalDiv>
              <Message
                left="20px"
                lineHeight="16px"
                size="10px"
                weight="400"
                color="#071827"
                top="24px"
                bottom="4px"
              >
                Enter Transaction PIN to continue
              </Message>
              <Formik
                initialValues={{
                  transactionPin: "",
                }}
              >
                {({ touched, valid, errors, setFieldValue }) => (
                  <FormSection>
                    <PasswordGroup
                      count={4}
                      startIndex={1}
                      type={"password"}
                      valid={valid}
                      errors={errors}
                      name={"transactionPin"}
                      touched={touched}
                      align={"center"}
                      enteredValue={value || ""}
                      handleChange={(event) => {
                        setValue(event.target.value);

                        const transPin = getInputValues("transactionPin");
                        setPin(transPin);
                        setFieldValue("transactionPin", transPin);
                      }}
                    />
                  </FormSection>
                )}
              </Formik>
            </VerticalDiv>
          </BottomParentWrapper>
        </LinearLayout>
      </ParentWrapper>
      {wrongPin && (
        <SuccessPage
          title="Transaction failed!"
          subtitle="The pin you provided is not correct. Kindly check and try again"
          showDetailButton
          successfull={false}
          doneText="Try Again"
          detailText="Back To Summary"
          onDoneClick={() => setWrongPin(false)}
          onDetailClick={() => {
            setShowPin(false);
            setWrongPin(false);
          }}
        />
      )}
    </Fragment>
  );
};
