import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";

import { ReactComponent as CancelSvg } from "../../../../assets/cancel.svg";
import { ReactComponent as SearchSvg } from "../../../../assets/search_icon.svg";

const SearchWrapper = styled.div`
  display: flex;
  height: 40px;
  margin-top: ${({ top }) => top || null};
  margin-bottom: ${({ bottom }) => bottom || null};
`;

const SearchWrapperForInputWithCancel = styled(SearchWrapper)`
  width: 100%;
`;

const Input = styled.input`
  border: none;
  outline-color: transparent;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  background-color: #edf2f7;
  padding: 15px;
  border-radius: 4px;
  width: 100%;
  padding-left: 40px;
`;

const SearchIcon = styled(SearchSvg)`
  position: absolute;
  left: 27px;
  margin-right: 28px;
  margin-top: 13px;
`;

const InputWithCancelSearchIcon = styled(SearchIcon)`
  left: 0;
  margin-left: 30px;
  margin-top: 13px;
`;

const CancelIcon = styled(CancelSvg)`
  position: absolute;
  right: ${({ right }) => right || "-40px"};
  margin-right: 83px;
  margin-top: 13px;
`;

const MainSearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${({ margin }) => margin || null};
`;

const SearchCancelText = styled.p`
  font-size: 12px;
  color: #000000;
  cursor: default;
  margin-left: 16px;
`;

export const SearchInput = ({
  placeholder,
  onClick,
  top,
  bottom,
  value,
  onChange,
}) => {
  return (
    <SearchWrapper onClick={onClick} top={top} bottom={bottom}>
      <SearchIcon />
      <Input value={value} onChange={onChange} placeholder={placeholder} />
    </SearchWrapper>
  );
};

export const SearchInputWithCancel = ({
  value,
  placeholder,
  onChange,
  onKeyDown,
  onCancel,
  margin,
  showCancelOnInteract,
  onCancelInputValue,
}) => {
  const inputRef = useRef(null);
  const [showCancel, setShowCancel] = useState(false);

  useEffect(() => {
    if (inputRef) inputRef.current.focus();
  }, [inputRef]);

  useEffect(() => {
    if (showCancelOnInteract) {
      if (value && value.length > 0) setShowCancel(true);
    } else if (!onCancel) {
      if (value && value.length > 0) setShowCancel(true);
      else setShowCancel(false);
    } else {
      setShowCancel(true);
    }
  }, [value, onCancel, showCancelOnInteract]);

  return (
    <MainSearchWrapper margin={margin}>
      <SearchWrapperForInputWithCancel>
        <Input
          placeholder={placeholder}
          onChange={onChange}
          ref={inputRef}
          defaultValue={value}
          onKeyDown={onKeyDown}
          onClick={() => setShowCancel(true)}
        />
        <InputWithCancelSearchIcon />
        {showCancel && (
          <CancelIcon
            right={`${showCancel ? "0" : "-40px"}`}
            onClick={() => {
              inputRef.current.value = "";
              onCancelInputValue && onCancelInputValue();
            }}
          />
        )}
      </SearchWrapperForInputWithCancel>
      {showCancel && (
        <SearchCancelText
          onClick={() => {
            if (!onCancel) setShowCancel(false);
            else onCancel();
          }}
        >
          Cancel
        </SearchCancelText>
      )}
    </MainSearchWrapper>
  );
};
