import { countriesMap } from "../../data/countries";

export const formatPrice = (value = 0, country = "SA") => {
  // if (value === undefined || value === null || value === "") throw new Error("Price value is empty");
  return new Intl.NumberFormat(`en-${country}`, {
    style: "currency",
    currency: countriesMap.get(country).currency.code,
    currencyDisplay: "narrowSymbol",
  })
    .format(value || 0)
    .replace(/^(\D+)/, "$1 ");
};

export const breakUpFormatPrice = (value, country = "SA") => {
  const price = formatPrice(value, country);
  const sign = price.substring(0, 1);
  const main = price.substring(2, price.length - 2);
  const sub = price.substring(price.length - 2);
  return [sign, main, sub];
};
