import React, { Fragment } from "react";

import styled from "styled-components";

import NotificationBell from "../../../assets/notification_bell.svg";
import { Title, SubTitle } from "../../../containers/MessageContainer";
import {
  FlexCenteredBlock,
  ScreenContainer,
} from "../../../containers/ScreenContainer";
import DesktopBackgroundLayout from "../../../views/DesktopBackgroundLayout";
import { RippleButton, PageLogo } from "../../index";

const Space = styled.div`
  height: ${({ height }) => height || "10px"};
`;

const DesktopPopupWrap = styled.div`
  position: relative;
  top: 0;
  background: #fff;

  @media (min-width: 576px) {
    position: relative;
    top: -66px;
    background: #fff;
  }
`;

const Modal = styled.div`
  position: fixed;
  z-index: 5000000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: white;
`;

export const NotificationPopup = ({ open, cancel, confirm }) => {
  return (
    open && (
      <Modal>
        <Fragment>
          <DesktopBackgroundLayout>
            <DesktopPopupWrap>
              <ScreenContainer top={"64px"}>
                <FlexCenteredBlock>
                  <PageLogo
                    Icon={NotificationBell}
                    width={"184px"}
                    height={"184px"}
                    iconHeight={"auto"}
                    iconWidth={"auto"}
                    background={"#f2f5fa"}
                  />
                  <Space height={"32px"} />

                  <Title>Notifications</Title>
                  <SubTitle color={"#56636d"} textAlign={"center"}>
                    Allow Vumele to notify you immediately something important
                    happens.
                  </SubTitle>

                  <RippleButton top={"45px"} onClick={confirm}>
                    Allow notifications
                  </RippleButton>
                  <RippleButton
                    top={"20px"}
                    style={{
                      backgroundColor: "#579fd733",
                      color: "#579fd7",
                    }}
                    onClick={cancel}
                  >
                    I'll do this later
                  </RippleButton>
                </FlexCenteredBlock>
              </ScreenContainer>
            </DesktopPopupWrap>
          </DesktopBackgroundLayout>
        </Fragment>
      </Modal>
    )
  );
};
