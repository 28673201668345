import KenyaFlag from "../../../assets/flags/south-africa.svg";

export const SouthAfricaInfo = {
  name: "SouthAfrica",
  code: "+27",
  flag: KenyaFlag,
  currency: {
    sign: "R",
    code: "ZAR",
  },
};
