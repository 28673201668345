export const satisfiesPartPayment = (
  values,
  paymentInFull,
  selectedCustomer
) => {
  const result =
    !paymentInFull &&
    values.saleAmount > 0 &&
    values.paymentAmount > 0 &&
    selectedCustomer
      ? false
      : true;

  return result;
};

export const satisfiesFullPayment = (values, paymentInFull) => {
  const result = paymentInFull && values.saleAmount > 0 ? false : true;

  return result;
};

export const satisfiesFullPaymentWithCustomer = (
  values,
  paymentInFull,
  selectedCustomer
) => {
  const result = paymentInFull && values.saleAmount > 0 ? false : true;

  return result;
};

const satisfiesCriteria = (
  values,
  paymentInFull,
  selectedCustomer,
  selectedProducts
) => {
  const product =
    selectedProducts !== undefined ? selectedProducts.length > 0 : true;

  const result = paymentInFull
    ? product
      ? false
      : true
    : values.paymentAmount > 0 && product && selectedCustomer
    ? false
    : true;

  return result;
};

export const criterias = (salesOptions, values) => {
  if (
    salesOptions.addItemSold &&
    salesOptions.addDiscount &&
    !salesOptions.addCustomer &&
    !salesOptions.addNotes
  )
    return satisfiesCriteria(
      values,
      values.paymentInFull,
      values.selectedCustomer,
      values.selectedProducts
    );
  if (
    salesOptions.addItemSold &&
    salesOptions.addCustomer &&
    salesOptions.addNotes &&
    !salesOptions.addDiscount
  )
    return satisfiesCriteria(
      values,
      values.paymentInFull,
      values.selectedCustomer,
      values.selectedProducts
    );
  if (
    salesOptions.addItemSold &&
    !salesOptions.addCustomer &&
    !salesOptions.addNotes &&
    !salesOptions.addDiscount
  )
    return satisfiesCriteria(
      values,
      values.paymentInFull,
      values.selectedCustomer,
      values.selectedProducts
    );
  if (
    salesOptions.addNotes &&
    !salesOptions.addCustomer &&
    !salesOptions.addItemSold &&
    !salesOptions.addDiscount
  )
    return satisfiesCriteria(
      values,
      values.paymentInFull,
      values.selectedCustomer,
      undefined
    );
  if (
    salesOptions.addNotes &&
    salesOptions.addCustomer &&
    salesOptions.addItemSold &&
    salesOptions.addDiscount
  )
    return satisfiesCriteria(
      values,
      values.paymentInFull,
      values.selectedCustomer,
      values.selectedProducts
    );
  if (
    salesOptions.addNotes &&
    salesOptions.addCustomer &&
    !salesOptions.addItemSold &&
    !salesOptions.addDiscount
  )
    return satisfiesCriteria(
      values,
      values.paymentInFull,
      values.selectedCustomer,
      undefined
    );
  if (
    salesOptions.addNotes &&
    salesOptions.addCustomer &&
    !salesOptions.addItemSold &&
    salesOptions.addDiscount
  )
    return satisfiesCriteria(
      values,
      values.paymentInFull,
      values.selectedCustomer,
      undefined
    );
  if (
    !salesOptions.addNotes &&
    salesOptions.addCustomer &&
    !salesOptions.addItemSold &&
    salesOptions.addDiscount
  )
    return satisfiesCriteria(
      values,
      values.paymentInFull,
      values.selectedCustomer,
      undefined
    );
  if (
    salesOptions.addNotes &&
    !salesOptions.addCustomer &&
    !salesOptions.addItemSold &&
    salesOptions.addDiscount
  )
    return satisfiesCriteria(
      values,
      values.paymentInFull,
      values.selectedCustomer,
      undefined
    );
  if (
    !salesOptions.addNotes &&
    salesOptions.addCustomer &&
    salesOptions.addItemSold &&
    !salesOptions.addDiscount
  )
    return satisfiesCriteria(
      values,
      values.paymentInFull,
      values.selectedCustomer,
      values.selectedProducts
    );
  if (
    !salesOptions.addNotes &&
    salesOptions.addCustomer &&
    salesOptions.addItemSold &&
    salesOptions.addDiscount
  )
    return satisfiesCriteria(
      values,
      values.paymentInFull,
      values.selectedCustomer,
      values.selectedProducts
    );
  if (
    salesOptions.addNotes &&
    !salesOptions.addCustomer &&
    salesOptions.addItemSold &&
    !salesOptions.addDiscount
  )
    return satisfiesCriteria(
      values,
      values.paymentInFull,
      values.selectedCustomer,
      values.selectedProducts
    );

  if (!values.paymentInFull)
    return satisfiesPartPayment(
      values,
      values.paymentInFull,
      values.selectedCustomer
    );

  if (values.paymentInFull && salesOptions.addCustomer)
    return satisfiesFullPaymentWithCustomer(
      values,
      values.paymentInFull,
      values.selectedCustomer
    );

  if (values.paymentInFull)
    return satisfiesFullPayment(values, values.paymentInFull);
};
