import React, { Fragment, useState, useEffect } from "react";

import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as ArrowIcon } from "../../../../../assets/arrow.svg";
import {
  TopHeader,
  RippleButton,
  SelectBox,
  InputWithOnchange,
  Loader,
} from "../../../../../components";
import { MenuOptionLogo } from "../../../../../containers/MenuContainer";
import { Message, Title } from "../../../../../containers/MessageContainer";
import { ScreenContainer } from "../../../../../containers/ScreenContainer";
import { shsActions } from "../../../../../redux/ducks/applications/shs/actions";
import { colors } from "../../../../../styles";
import { formatPrice } from "../../../../../utils/currency/formatPriceWithComma";
import { formatCurrency } from "../../../../../utils/currency/parseBalance";
import DesktopBackgroundLayout from "../../../../DesktopBackgroundLayout";
import walletSvg from "../assets/wallet.svg";

import { ValidationSchema } from "./ValidationSchema";

const MenuOption = styled.li`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  align-items: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  border-top: 1px solid ${colors.border.top};
  border-bottom: 1px solid ${colors.border.top};
`;

const OptionName = styled.h4`
  font-weight: 400;
  color: #0b0c0e;
  font-size: 14px;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 4px;
`;

const OptionSubText = styled.h4`
  font-weight: 400;
  color: #05944f;
  font-size: 12px;
  margin: auto 16px;
`;

const OptionWithSubText = styled.div`
  display: flex;
  flex-direction: column;
`;

const ArrowForward = styled(ArrowIcon)`
  position: absolute;
  right: 16px;
  top: 16px;
`;

const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const FlexContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80%;
`;

const ShsRecharge = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const state = useLocation().state;
  const isLoading = useSelector((state) => state.applications.shs.isLoading);
  const walletBalance = useSelector(
    (state) => state.account.wallet.balance || 0
  );

  const [customerInfo] = useState(state?.data || {});
  const [devicesFound] = useState(
    state?.data?.linkedDevices &&
      state?.data?.linkedDevices.map((value) => ({
        value: value.serialNumber,
        label: value.serialNumber,
      }))
  );
  const [selected] = useState(state?.selected);
  const [amount, setAmount] = useState(0);
  const [numberOfHours, setNumberOfHours] = useState(0);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (numberOfHours) {
        dispatch(shsActions.getShsRate(parseInt(numberOfHours))).then((res) =>
          setAmount(res)
        );
      } else {
        setAmount("");
      }
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [dispatch, numberOfHours]);

  if (isLoading) return <Loader />;
  return (
    <Fragment>
      <DesktopBackgroundLayout>
        <TopHeader title={"Recharge"} />
        <ScreenContainer height="100%">
          <Title top={"24px"} textAlign="left">
            Enter transaction details
          </Title>
          <Message bottom={"24px"} top={"6px"}>
            Select how much you want to buy
          </Message>
          <Formik
            initialValues={{
              serialNumber: selected || "",
              numberOfHours: numberOfHours || "",
              amount: amount || "",
              shsCustomerPhoneNumber: `0${customerInfo.customerPhoneNumber}`,
            }}
            enableReinitialize
            validationSchema={ValidationSchema}
            onSubmit={async (values, { resetForm }) => {
              history.push({
                pathname: "/actions/shs_recharge_pin",
                state: {
                  payload: values,
                  customerInfo: customerInfo,
                },
              });
            }}
          >
            {({
              touched,
              values,
              errors,
              handleChange,
              initialValues,
              setFieldValue,
            }) => (
              <FlexContainer>
                <InputBlock>
                  <SelectBox
                    name={"serialNumber"}
                    placeholder={"Device"}
                    value={values.serialNumber}
                    options={devicesFound}
                    marginBottom={"-16px"}
                    handleChange={handleChange}
                    valid={`${!touched.serialNumber && !errors.serialNumber}`}
                    error={
                      touched &&
                      touched.serialNumber &&
                      errors &&
                      errors.serialNumber
                    }
                  />

                  <Message
                    left="16px"
                    weight={400}
                    size={"12px"}
                    color={"#718596"}
                    top={"8px"}
                  >
                    {`${customerInfo.customerFirstName} ${customerInfo.customerLastName}`}
                  </Message>

                  <InputWithOnchange
                    label={"How many Hours"}
                    type={"text"}
                    inputMode={"numeric"}
                    placeholder={"How many Hours"}
                    autoComplete={"off"}
                    name="numberOfHours"
                    bottom="24px"
                    top="10px"
                    height={"56px"}
                    background={"#F2F5FA"}
                    value={values.numberOfHours}
                    valid={`${!touched.numberOfHours && !errors.numberOfHours}`}
                    errors={
                      touched &&
                      touched.numberOfHours &&
                      errors &&
                      errors.numberOfHours
                    }
                    onChange={(e) => {
                      const {
                        target: { value: inputValue = 0 },
                      } = e;
                      setFieldValue("numberOfHours", inputValue);
                      setNumberOfHours(inputValue);
                    }}
                    initialValues={initialValues}
                  />

                  <InputWithOnchange
                    label=""
                    type="text"
                    inputMode="numeric"
                    placeholder=""
                    autoComplete="off"
                    readOnly
                    name="amount"
                    bottom="24px"
                    height="56px"
                    background="#F2F5FA"
                    value={formatCurrency(values.amount, "SA")}
                    valid={`${!touched.amount && !errors.amount}`}
                    errors={
                      touched && touched.amount && errors && errors.amount
                    }
                    onChange={(e) => {}}
                    initialValues={initialValues}
                  />

                  <Message weight={400} color={"#718596"} top={"29px"}>
                    Payment Method
                  </Message>
                  <MenuOption>
                    <MenuOptionLogo icon={walletSvg} />
                    <OptionWithSubText>
                      <OptionName>Spaces Wallet</OptionName>
                      <OptionSubText>{`Balance -  ${formatPrice(
                        walletBalance
                      )}`}</OptionSubText>
                    </OptionWithSubText>
                    <ArrowForward />
                  </MenuOption>
                </InputBlock>

                {!isLoading && (
                  <RippleButton
                    type="submit"
                    top={"24px"}
                    backgroundColor={colors.deepBlue}
                  >
                    Continue
                  </RippleButton>
                )}
              </FlexContainer>
            )}
          </Formik>
        </ScreenContainer>
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default ShsRecharge;
