export const LOADING = "LOADING";
export const FETCH_ALL_TRANSACTIONS_SUCCESS = "FETCH_ALL_TRANSACTIONS_SUCCESS";
export const FETCH__ALL_TRANSACTIONS_FAILURE =
  "FETCH__ALL_TRANSACTIONS_FAILURE";
export const FETCH_TRANSACTION_DETAILS_SUCCESS =
  "FETCH_TRANSACTION_DETAILS_SUCCESS";
export const FETCH_TRANSACTION_DETAILS_FAILURE =
  "FETCH_TRANSACTION_DETAILS_FAILURE";
export const LOADING_TRANSACTIONS = "LOADING_TRANSACTIONS";
export const LOADING_TRANSACTION = "LOADING_TRANSACTION";
