import React, { useState, useEffect } from "react";

import styled from "styled-components";

import { colors } from "../../../../../styles";

const ExpireText = styled.div`
  color: ${colors.gray5};
  font-size: 10px;
  line-height: 16px;
`;

const ExpireTextTimer = styled.span`
  color: ${colors.red};
`;

const Countdown = ({ date }) => {
  const [timeLeft, setTimeLeft] = useState("");

  const calculateTimeLeft = () => {
    // minus DateTime gotten as props with current DateTime
    // and check if there is any time Left
    // the + before Date() tells javascript to treat it as plain number
    let difference = +new Date(date) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  });

  const { days, hours, minutes, seconds } = timeLeft;
  const daysToMinutes = Math.floor(days * 24 + (hours + minutes / 60) * 60);
  return (
    <ExpireText>
      Expires in{" "}
      <ExpireTextTimer>
        {seconds && daysToMinutes ? `${daysToMinutes} : ${seconds}` : "00 : 00"}
      </ExpireTextTimer>
    </ExpireText>
  );
};
export default Countdown;
