import * as Yup from "yup";

import { phoneRegExp } from "../../../../../../utils/regexp/phoneRegExp";

export const PhoneNumberValidationSchema = Yup.object().shape({
  msisdn: Yup.string()
    .min(10, "Must be minimum 10 characters")
    .max(11, "Must be maximum 11 characters")
    .matches(phoneRegExp, "Phone Number is not valid")
    .nullable()
    .required("Please, enter a phone number"),
});
