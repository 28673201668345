import * as Yup from "yup";

import { containsLettersRegExp } from "../../../../../../utils/regexp/containsLettersRegExp";

export const WalletToBankValidationSchema = Yup.object().shape({
  amount: Yup.string()
    .matches(containsLettersRegExp, "Amount cannot contain letters")
    .required("Required"),
  accountNumber: Yup.string()
    .required("Account number is Required")
    .test(
      "len",
      "Invalid Account Number",
      (val) => val && /^\d+$/.test(val) && val.length === 10
    ),
  narration: Yup.string(),
});
