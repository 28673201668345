import * as Yup from "yup";

export const TransactionPinFormValidationSchema = Yup.object().shape({
  newTransactionPin: Yup.string()
    .matches(/^\d+$/, "Only numbers are allowed")
    .nullable()
    .required("Required"),
  confirmNewTransactionPin: Yup.string()
    .oneOf([Yup.ref("newTransactionPin"), null], "PIN must be the same")
    .nullable()
    .required("Required"),
});
