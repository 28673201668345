import React, { Fragment, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { SuccessPage } from "../../../../../components";
import { getDebtor } from "../../../../../redux/ducks/applications/my-shop/actions/debtors";
import DesktopBackgroundLayout from "../../../../DesktopBackgroundLayout";

const PaymentReminderSuccess = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const debtorInfo = useSelector(
    (state) => state.applications.myShop.debtor?.debtorInfo || {}
  );
  useEffect(() => {
    dispatch(getDebtor(debtorInfo.customerId));
  }, [debtorInfo.customerId, dispatch]);

  function handleClick() {
    history.goBack();
  }
  return (
    <Fragment>
      <DesktopBackgroundLayout>
        <SuccessPage
          title={"Reminder set successfully"}
          subtitle={`you've successfully created a reminder notification for ${
            debtorInfo?.name?.split(" ")[0]
          }`}
          position={"absolute"}
          mposition={"fixed"}
          doneText={"Done"}
          onDoneClick={handleClick}
          weight={"700"}
          size={"12px"}
        />
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default PaymentReminderSuccess;
