import React, { Fragment, useState } from "react";

import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import {
  Loader,
  RippleButton,
  TopHeader,
  PageLogo,
  PasswordGroup,
} from "../../../../../components";
import {
  Message,
  PageTitleWrapper,
  PageTitle,
  PageSubTitle,
} from "../../../../../containers/MessageContainer";
import {
  ScreenContainer,
  PageProgressLine,
  FormWrapper,
} from "../../../../../containers/ScreenContainer";
import { createMerchantAccountV2 } from "../../../../../redux/ducks/auth/signup/merchant/actions";
import { colors } from "../../../../../styles";
import { getInputValues } from "../../../../../utils/inputs/getInputValues";
import DesktopBackgroundLayout from "../../../../DesktopBackgroundLayout";

import PinCode from "./assets/pincode.svg";
import { PinCreationValidationSchema } from "./PinCreationValidationSchema";

const PageProgressWrap = styled.div`
  position: absolute;
  display: flex;
  right: 15px;
  color: ${colors.themeTextColor0};
  z-index: 100;
`;

const PageProgressStep = styled.span`
  font-size: 14px;
  color: ${colors.themeTextColor1};
`;

const MerchantCreatePin = () => {
  const dispatch = useDispatch();

  const [value, setValue] = useState("");
  const [confirmValue, setConfirmValue] = useState(undefined);
  const [submitAction, setSubmitAction] = useState(false);

  const isLoading = useSelector(
    (state) => state.auth.signup.merchant.isLoading
  );

  const handlePinCreationSubmit = (values) => {
    let userData = localStorage.getItem("userData");
    userData = JSON.parse(userData);
    setSubmitAction(true);
    setTimeout(() => {
      dispatch(
        createMerchantAccountV2({
          ...userData,
          msisdn: userData.phoneNumber,
          password: values.password,
        })
      ).then((res) => {
        setSubmitAction(false);
        res && localStorage.setItem("userData", JSON.stringify({}));
      });
    }, 1000);
  };

  return isLoading ? (
    <Loader />
  ) : (
    <Fragment>
      <DesktopBackgroundLayout>
        <TopHeader title={"Seller Account"}>
          <PageProgressWrap>
            <PageProgressStep>3</PageProgressStep>/3
          </PageProgressWrap>
          <PageProgressLine lineWidth={"100%"} />
        </TopHeader>
        <ScreenContainer paddingBottom={"41px"}>
          <PageLogo
            top={"75px"}
            margin={"auto"}
            borderRadius={"0"}
            Icon={PinCode}
            iconWidth="72px"
            iconHeight="72px"
          />
          <PageTitleWrapper mtop={"10px"} mbottom={"8px"}>
            <PageTitle size={"16px"} top={"16px"} bottom={"6px"}>
              Almost Done
            </PageTitle>
            <PageSubTitle margin={"0"}>
              Create a 6-digit PIN to secure your account
            </PageSubTitle>
          </PageTitleWrapper>
          <Formik
            initialValues={{
              password: "",
              confirmPassword: "",
            }}
            validationSchema={PinCreationValidationSchema}
            onSubmit={handlePinCreationSubmit}
          >
            {({ valid, touched, errors, setFieldValue, values }) => (
              <Form>
                <FormWrapper>
                  <Message
                    top={"24px"}
                    bottom={"0px"}
                    color={"#718596"}
                    size={"12px"}
                  >
                    Enter PIN
                  </Message>
                  <PasswordGroup
                    count={6}
                    startIndex={1}
                    type={"password"}
                    valid={valid}
                    errors={errors}
                    name={"password"}
                    touched={touched}
                    align={"center"}
                    disabled={isLoading}
                    enteredValue={value}
                    handleChange={(event) => {
                      setValue(event.target.value);
                      setFieldValue("password", getInputValues("password"));
                    }}
                  />
                  <Message
                    top={"20px"}
                    bottom={"0px"}
                    color={"#718596"}
                    size={"12px"}
                  >
                    Re-enter PIN
                  </Message>
                  <PasswordGroup
                    count={6}
                    startIndex={7}
                    type={"password"}
                    valid={valid}
                    errors={errors}
                    name={"confirmPassword"}
                    touched={touched}
                    align={"center"}
                    enteredValue={confirmValue}
                    handleChange={(event) => {
                      setConfirmValue(event.target.value);
                      setFieldValue(
                        "confirmPassword",
                        getInputValues("confirmPassword")
                      );
                    }}
                  />
                  <RippleButton
                    type="submit"
                    top={"auto"}
                    disabled={
                      values.password.length < 6 ||
                      values.confirmPassword.length < 6 ||
                      values.password !== values.confirmPassword ||
                      submitAction
                    }
                  >
                    Continue
                  </RippleButton>
                </FormWrapper>
              </Form>
            )}
          </Formik>
        </ScreenContainer>
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default MerchantCreatePin;
