import React, { Fragment, useState, useEffect } from "react";

import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";

import {
  TopHeader,
  Loader,
  RippleButton,
  UploadPicture,
} from "../../../../../components";
import { NewTextInputWithLabel } from "../../../../../components/forms/input/new-text-input";
import {
  ImageCropper,
  toDataURL,
} from "../../../../../components/popup/image-cropper";
import { Container } from "../../../../../containers/ScreenContainer";
import { editShop } from "../../../../../redux/ducks/applications/my-shop/actions/shop";
import { verifyShopName } from "../../../../../redux/ducks/auth/signup/merchant/actions";
import { colors } from "../../../../../styles";
import DesktopBackgroundLayout from "../../../../DesktopBackgroundLayout";
import SupermarketIcon from "../../assets/new-supermarket.svg";

import { ReactComponent as CheckMark } from "./assets/checkmark.svg";
import { EditShopValidationSchema } from "./EditShopValidationSchema";
import { Space } from "./styles";

const Text = styled.p`
  margin: -14px 0 0 32px;
  color: ${({ color }) => color || colors.greenText2};
  font-size: 10px;
  font-weight: 400;
`;

const StyledMark = styled(CheckMark)`
  z-index: 90;
  position: absolute;
  top: 20px;
  right: 30px;
`;
const RelativeContainer = styled(Container)`
  position: relative;
`;

const EditShopDetails = () => {
  const dispatch = useDispatch();
  const selectedShop = useSelector(
    (state) => state.applications.myShop.shops
  )[0];
  const loading = useSelector((state) => state.applications.myShop.isLoading);
  const isLoading = useSelector(
    (state) => state.auth.signup.merchant.isLoading
  );
  const [showCropper, setShowCropper] = useState(false);
  const [picture, setPicture] = useState();
  const [isBusinessNameAvailable, setIsBusinessNameAvailable] = useState(null);
  const [businessName, setBusinessName] = useState(selectedShop?.shopName);
  const [, setErrors] = useState({});
  const onCropperCancel = () => {
    setShowCropper(false);
    setPicture("");
  };

  const onCropperSave = async (url) => {
    if (url) {
      const image = await toDataURL(url);
      setShowCropper(false);
      setPicture(image);
    } else {
      toast.error("Image is still processing, wait for a sec...");
    }
  };

  const pAction = (formName, image) => {
    setShowCropper(true);
    setPicture(image);
  };

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (businessName && businessName !== selectedShop.shopName) {
        dispatch(verifyShopName(businessName, setErrors, "shopName")).then(
          (status) => {
            if (status === true) {
              setIsBusinessNameAvailable(true);
            } else {
              setIsBusinessNameAvailable(false);
            }
          }
        );
      }
    }, 1000);
    return () => clearTimeout(timeOut);
  }, [businessName, dispatch, selectedShop]);

  if (loading || isLoading) {
    return <Loader />;
  } else
    return (
      <Fragment>
        <DesktopBackgroundLayout>
          {showCropper && (
            <ImageCropper
              avatar={picture}
              onCancel={onCropperCancel}
              onSave={onCropperSave}
            />
          )}
          <TopHeader
            size="14px"
            weight="400"
            color={colors.boldDark}
            noBorderBottom
            title="Edit your store details"
          />
          <Space val={"60px"} />
          <Formik
            initialValues={{
              shopName: businessName,
              base64BranchImageString: selectedShop?.avatar,
            }}
            reinitialize={true}
            validationSchema={EditShopValidationSchema}
            onSubmit={(values, { setErrors }) => {
              values.base64BranchImageString = picture;
              const params = {
                id: selectedShop?.id,
                branchId: selectedShop?.branchId,
                businessId: selectedShop?.businessId,
                name: values.shopName,
                base64BranchImageString:
                  values.base64BranchImageString === selectedShop?.avatar
                    ? ""
                    : values.base64BranchImageString,
                actionSrc: "from edit shop",
              };
              dispatch(editShop(params));
            }}
          >
            {({
              handleChange,
              errors,
              values,
              touched,
              setFieldValue,
              initialValues,
            }) => {
              return (
                <>
                  <UploadPicture
                    text={"Tap to change image"}
                    pictureAction={pAction}
                    defaultPicture={SupermarketIcon}
                    width={"49.1px"}
                    height={"41.48px"}
                    picture={picture || values.base64BranchImageString}
                    formName={"base64BranchImageString"}
                    logoRadius="8px"
                    logoBackground="#E3E9FC"
                    formMargin="15px 0px"
                  />
                  <Form style={{ paddingBottom: "65px" }}>
                    <Container>
                      <RelativeContainer>
                        <NewTextInputWithLabel
                          label="Business Name"
                          placeholder="Business Name"
                          value={values && values.shopName}
                          name="shopName"
                          type="text"
                          onKeyUp={(e) => setBusinessName(e.target.value)}
                        />
                        {businessName &&
                          selectedShop.shopName !== businessName &&
                          isBusinessNameAvailable && <StyledMark />}
                      </RelativeContainer>
                      {businessName &&
                        selectedShop.shopName === businessName && (
                          <Text>This is your current business name</Text>
                        )}
                      {businessName &&
                        selectedShop.shopName !== businessName &&
                        isBusinessNameAvailable && (
                          <Text>Business name is available</Text>
                        )}
                      {businessName &&
                        selectedShop.shopName !== businessName &&
                        !isBusinessNameAvailable && (
                          <Text color={colors.textRed}>
                            Business name is not available
                          </Text>
                        )}
                    </Container>

                    <Container>
                      {!showCropper && (
                        <RippleButton
                          type="submit"
                          top={"180px"}
                          disabled={
                            (selectedShop?.shopName !== businessName &&
                              !isBusinessNameAvailable) ||
                            isLoading ||
                            loading ||
                            !businessName
                          }
                        >
                          Save
                        </RippleButton>
                      )}
                    </Container>
                  </Form>
                </>
              );
            }}
          </Formik>
        </DesktopBackgroundLayout>
      </Fragment>
    );
};

export default EditShopDetails;
