import React from "react";

import styled from "styled-components";

import { orderStatus } from "../../../../../enums";
import { colors } from "../../../../../styles/variables";
import { ReactComponent as ProgressIcon } from "../assets/progress-check.svg";

const CheckoutProgressContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${colors.white};
  padding: 1em 1em 8px 1em;
`;

const CheckoutIconContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 105px;
  height: 40px;
  background: ${({ active }) => (active ? colors.deepBlue : colors.white)};
  border-radius: 40px;
`;

const Step2Wrapper = styled.div`
  background: ${colors.deepBlue};
  border-radius: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Step2OutterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const CheckoutProgressText = styled.div`
  color: ${({ active }) => (active ? colors.white : colors.black)};
  font-size: 10px;
  font-weight: 500;
`;

const CheckoutProgressStep = styled.div`
  background-color: ${({ active }) =>
    active ? colors.bgBlue : colors.lightGray};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  margin: 0 9px;
  border: ${({ active }) =>
    active ? `0.5px solid ${colors.white}` : `0.5px solid ${colors.lightGray}`};
  font-size: 10px;
`;

const Text = styled.div`
  color: ${({ active }) => (active ? colors.white : colors.black)};
  padding-bottom: 2px;
`;

const stages = {
  delivery: 1,
  payment: 2,
  summary: 3,
};
const stagesTitle = {
  delivery: "Delivery",
  payment: "Payment",
  summary: "Summary",
};

export const CheckoutProgress = ({ step }) => {
  return (
    <CheckoutProgressContainer>
      {step === "delivery" &&
        Object.values(orderStatus)
          .slice(0, 3)
          .map((status, index) => (
            <CheckoutIconContainer
              key={index}
              active={step === status ? true : false}
            >
              {step !== status ? (
                <CheckoutProgressStep>
                  <Text>{stages[status]}</Text>
                </CheckoutProgressStep>
              ) : (
                <CheckoutProgressStep active={true}>
                  <Text active={true}>{stages[status]}</Text>
                </CheckoutProgressStep>
              )}
              <CheckoutProgressText active={step === status ? true : false}>
                {stagesTitle[status]}
              </CheckoutProgressText>
            </CheckoutIconContainer>
          ))}
      {step === "payment" && (
        <Step2OutterWrapper>
          <Step2Wrapper>
            {Object.values(orderStatus)
              .slice(0, 2)
              .map((status, index) => (
                <CheckoutIconContainer key={index} active={true}>
                  <CheckoutProgressStep active={true}>
                    {status === "delivery" ? (
                      <ProgressIcon />
                    ) : (
                      <Text active={true}>2</Text>
                    )}
                  </CheckoutProgressStep>
                  <CheckoutProgressText active={true}>
                    {stagesTitle[status]}
                  </CheckoutProgressText>
                </CheckoutIconContainer>
              ))}
          </Step2Wrapper>
          <CheckoutIconContainer active={false}>
            <CheckoutProgressStep>
              <Text>3</Text>
            </CheckoutProgressStep>

            <CheckoutProgressText active={false}>Summary</CheckoutProgressText>
          </CheckoutIconContainer>
        </Step2OutterWrapper>
      )}
      {step === "summary" && (
        <Step2OutterWrapper>
          <Step2Wrapper>
            {Object.values(orderStatus)
              .slice(0, 3)
              .map((status, index) => (
                <CheckoutIconContainer key={index} active={true}>
                  <CheckoutProgressStep active={true}>
                    {status === "delivery" || status === "payment" ? (
                      <ProgressIcon />
                    ) : (
                      <Text active={true}>3</Text>
                    )}
                  </CheckoutProgressStep>
                  <CheckoutProgressText active={true}>
                    {stagesTitle[status]}
                  </CheckoutProgressText>
                </CheckoutIconContainer>
              ))}
          </Step2Wrapper>
        </Step2OutterWrapper>
      )}
    </CheckoutProgressContainer>
  );
};
