import { toast } from "react-toastify";

import { axios, currentAPI } from "../../../../config";
import {
  LOADING_FAQ,
  LOADING_FAQ_BY_CATEGORY,
  LOADING_COMPLAINTS_CATEGORIES,
  LOADING_ALL_COMPLAINTS,
  LOADING_SUPPORT_CONTACT,
  LOADING_COMMENTS,
  LOADING_COMPLAINT,
  LOADING,
} from "../constants";

export const getFaqCategories = () => async (dispatch) => {
  if (!navigator.onLine) return;

  const rootCategoryId = "668238000006425027"; //country specific

  dispatch({ type: LOADING, payload: true });
  try {
    const getFaqCategoriesResponse = await axios.get(
      `${currentAPI}/api/complaints/allRootCategory/categoryTree/${rootCategoryId}`
    );
    if (getFaqCategoriesResponse.status === 200) {
      dispatch({
        type: LOADING_FAQ,
        payload: getFaqCategoriesResponse.data.data.children,
      });
    }
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    //
  }
};

export const getFaqByCategories = (categoryId) => async (dispatch) => {
  if (!navigator.onLine) return;
  dispatch({ type: LOADING, payload: true });
  try {
    const getFaqByCategoriesResponse = await axios.get(
      `${currentAPI}/api/support/faq/category/${categoryId}`
    );
    if (getFaqByCategoriesResponse.status === 200) {
      dispatch({
        type: LOADING_FAQ_BY_CATEGORY,
        payload: getFaqByCategoriesResponse.data,
      });
    }
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });

    //
  }
};

export const getComplaints = () => async (dispatch) => {
  if (!navigator.onLine) return;

  dispatch({ type: LOADING, payload: true });
  try {
    const getComplaintsResponse = await axios.get(
      `${currentAPI}/api/support/complaint`
    );
    if (getComplaintsResponse.status === 200) {
      dispatch({
        type: LOADING_ALL_COMPLAINTS,
        payload: getComplaintsResponse.data.data,
      });
    }
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: LOADING, payload: false }); //
  }
};

export const getComplaintById = (complaintId) => async (dispatch) => {
  if (!navigator.onLine) return;

  try {
    const getComplaintsResponse = await axios.get(
      `${currentAPI}/api/support/complaint/${complaintId} `
    );
    if (getComplaintsResponse.status === 200) {
      dispatch({
        type: LOADING_COMPLAINT,
        payload: getComplaintsResponse.data,
      });
      return getComplaintsResponse.data;
    }
  } catch (error) {
    //
  }
};

export const getComplaintsCategories = () => async (dispatch) => {
  if (!navigator.onLine) return;

  try {
    const getComplaintsCategoriesResponse = await axios.get(
      `${currentAPI}/api/support/complaint/category`
    );
    if (getComplaintsCategoriesResponse.status === 200) {
      dispatch({
        type: LOADING_COMPLAINTS_CATEGORIES,
        payload: getComplaintsCategoriesResponse.data,
      });
    }
  } catch (error) {
    //
  }
};

export const getSupportContact = () => async (dispatch) => {
  if (!navigator.onLine) return;

  dispatch({ type: LOADING, payload: true });
  try {
    const getSupportContactResponse = await axios.get(
      `${currentAPI}/api/support/number`
    );
    if (getSupportContactResponse.status === 200) {
      dispatch({
        type: LOADING_SUPPORT_CONTACT,
        payload: getSupportContactResponse.data,
      });
    }
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: LOADING, payload: false }); //
  }
};

export const logComplaint = (payload) => async (dispatch) => {
  if (!navigator.onLine) return;

  try {
    const logComplaintsResponse = await axios.post(
      `${currentAPI}/api/support/complaint`,
      JSON.stringify(payload)
    );
    if (logComplaintsResponse.status === 200) {
      dispatch(getComplaints());
      return true;
    }
  } catch (error) {
    toast.error(error.response.data.message);
    return false;
    //
  }
};

export const AddComment = (payload, complaintId) => async (dispatch) => {
  if (!navigator.onLine) return;

  try {
    const AddCommentResponse = await axios.post(
      `${currentAPI}/api/support/complaint/${complaintId}/comment `,
      JSON.stringify(payload)
    );
    if (AddCommentResponse.status === 200) {
      dispatch(getComments(complaintId));
      return true;
    }
  } catch (error) {
    return false;
    //
  }
};

export const getComments = (complaintId) => async (dispatch) => {
  if (!navigator.onLine) return;
  dispatch({ type: LOADING, payload: true });
  try {
    const getCommentsResponse = await axios.get(
      `${currentAPI}/api/support/complaint/${complaintId}/comment`
    );
    if (getCommentsResponse.status === 200) {
      dispatch({
        type: LOADING_COMMENTS,
        payload: getCommentsResponse.data.data.filter((x) => x.isPublic),
      });
    }
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    //
  }
};
