import React, { Fragment, useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as AllOrderSVG } from "../../../../assets/all_products.svg";
import { ReactComponent as Descending } from "../../../../assets/highest_sort_2.svg";
import { ReactComponent as Ascending } from "../../../../assets/lowest_sort_2.svg";
import { ReactComponent as MoqSVG } from "../../../../assets/moq.svg";
import { ReactComponent as MostRecent } from "../../../../assets/most_recent.svg";
import { ReactComponent as Oldest } from "../../../../assets/oldest.svg";
import { ReactComponent as PriceSVG } from "../../../../assets/price.svg";
import {
  Loader,
  OptionsPopupDialog,
  MoqPopup,
  PriceRangePopup,
} from "../../../../components";
import { SearchInputWithCancel } from "../../../../components/forms/input/search-input/SearchInput";
import { SecondaryText } from "../../../../containers/MessageContainer";
import {
  ScreenContainer,
  SectionWrapper,
} from "../../../../containers/ScreenContainer";
import { merchbuyActions } from "../../../../redux/ducks/applications/merchbuy/actions";
import {
  COMPLETE_SEARCH,
  INITIATE_SEARCH,
} from "../../../../utils/mix-panel/constants";
import { mixPanel } from "../../../../utils/mix-panel/mixPanel";
import DesktopBackgroundLayout from "../../../DesktopBackgroundLayout";
import { ReactComponent as ReceiptSearch } from "../assets/receipt_search.svg";
import { ReactComponent as SearchHint } from "../assets/search_hint.svg";
import { ListProducts, ListShops } from "../components";
import { Container } from "../styles";

const NameLabel = styled(SecondaryText)`
  font-size: 14px;
  font-weight: 500;
  color: #29394f;
  margin: 24px 0 16px 0;
`;

const RecentSearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Chip = styled.span`
  display: flex;
  flex-direction: row;
  height: 28px;
  min-width: 60px;
  width: fit-content;
  color: #071827;
  font-size: 14px;
  font-weight: 400;
  justify-content: center;
  margin: 0 8px 16px 0;
  text-align: center;
  border-radius: 24px;
  background-color: #edf2f7;
  padding: 0px 16px;
  align-items: center;
`;

const FlexCenteredBlock = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: ${({ top }) => top || 0};
`;

const NoSearchFound = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ top }) => top || "16px"};
  padding-top: 4px;
`;

const TableText = styled.span`
  font-size: 14px;
  font-weight: 700;
  display: block;
  color: #212c3d;
`;

const Reason = styled.span`
  display: ${({ display }) => display || "block"};
  align-items: ${({ align }) => align || null};
  justify-content: ${({ justify }) => justify || null};
  font-weight: 400;
  font-size: 12px;
  margin: ${({ margin }) => margin || null};
  color: ${({ color }) => color || "#56636d"};
  line-height: 1.5;
`;

const Dot = styled.div`
  height: ${({ height }) => height || "8px"};
  width: ${({ width }) => width || "8px"};
  border-radius: 50%;
  display: inline-block;
  background-color: ${({ color }) => color || "#c4c4c4"};
  margin-right: 5px;
`;

const AddFlexBox = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const RecentSearchText = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #718596;
  margin: ${({ margin }) => margin || null};
`;

const CenterText = styled.div`
  color: #718596;
  margin: 0 0 20px 0;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
`;

const TabContainer = styled.div`
  display: flex;
  background-color: #edf2f7;
  align-items: center;
  width: 185px;
  height: 32px;
  justify-content: space-between;
  border-radius: 8px;
  margin: auto;
  margin-top: ${({ top }) => top || null};
  margin-bottom: ${({ bottom }) => bottom || "12px"}; ;
`;

const ActiveTab = styled.div`
  background-color: #ffffff;
  width: 89px;
  height: 24px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px;
`;

const NonActiveTab = styled.div`
  padding: 7px 27px 7px 30px;
`;

const TabText = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  color: #212c3d;
`;

const Hold = styled.div`
  background-color: #fff;
  margin-top: 0px;
  padding: 5px 0 0px;
  @media (max-width: 576px) {
    width: calc(100% - 32px);
    margin-top: 0px;
  }
`;

const MerchbuySearch = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const msisdn = useSelector((state) => state.user.msisdn);
  const isLoading = useSelector(
    (state) => state.applications.merchbuy.isLoading
  );
  const productSearchTerm = useSelector(
    (state) => state.applications.merchbuy.productsSearch
  );
  const { searchTerm: searchTermFromLocation } = history.location.state || {};

  const [searchTerm, setSearchTerm] = useState(searchTermFromLocation || "");
  const [searchedProducts, setSearchedProducts] = useState([]);
  const [searchedShops, setSearchedShops] = useState([]);
  const [searchType, setSearchType] = useState("PRODUCT");
  const [onFinalSearch, setOnFinalSearch] = useState(false);
  const [onResultReady, setOnResultReady] = useState(false);
  const [products, setProducts] = useState([]);
  const [shops, setShops] = useState([]);

  /* Filtering and sorting */
  const [searchValue, setSearchValue] = useState("");
  const [sortOptionsOpen, setSortOptionsOpen] = useState(false);
  const [openFilterOptions, setOpenFilterOptions] = useState(false);
  const [openPriceRangePopup, setOpenPriceRangePopup] = useState(false);
  const [openMoqPopup, setOpenMoqPopup] = useState(false);
  const [sortType, setSortType] = useState("ASC");
  const [filterType, setFilterType] = useState("ALL");
  const [moqValue, setMoqValue] = useState("");
  const [priceRange, setPriceRange] = useState({
    minPrice: "",
    maxPrice: "",
  });

  useEffect(() => {
    mixPanel.track(INITIATE_SEARCH, {
      "User ID": user.userId,
      Time: new Date().toLocaleString(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    !onFinalSearch &&
      !searchTerm &&
      dispatch(merchbuyActions.getFrequentSearchTerms()).then((data) => {
        const { product, shop } = data || {};
        setSearchedProducts(product || []);
        setSearchedShops(shop || []);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchTerm]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      !onFinalSearch &&
        searchTerm &&
        dispatch(
          merchbuyActions.getFrequentSearchTermsOnChange(searchTerm)
        ).then((data) => {
          const { product, shop } = data || {};
          setSearchedProducts(product || []);
          setSearchedShops(shop || []);
        });
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchTerm]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const payload = {
        categoryType: searchType,
        mobileNumber: user.msisdn,
        terms: searchValue,
        sortCriteria: sortType,
        moq: moqValue,
        startPrice: priceRange.minPrice,
        endPrice: priceRange.maxPrice,
        searchFilter: filterType,
        page: 1,
        size: 100,
      };
      onFinalSearch &&
        searchValue &&
        dispatch(merchbuyActions.postSearchTerm(payload)).then((response) => {
          const { content } = response || {};
          if (searchType === "PRODUCT") {
            content && setProducts(content);
          } else {
            content && setShops(content);
          }
          setOnResultReady(true);
        });
    }, 500);
    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    searchValue,
    searchType,
    sortType,
    filterType,
    moqValue,
    priceRange,
  ]);

  // product search term
  useEffect(() => {
    if (productSearchTerm) {
      setSearchTerm(productSearchTerm);
      const payload = {
        categoryType: searchType,
        mobileNumber: msisdn,
        terms: productSearchTerm,
        sortCriteria: sortType,
        moq: moqValue,
        startPrice: priceRange.minPrice,
        endPrice: priceRange.maxPrice,
        searchFilter: filterType,
        page: 1,
        size: 100,
      };
      dispatch(merchbuyActions.postSearchTerm(payload)).then((response) => {
        const { content } = response || {};
        if (searchType === "PRODUCT") {
          content && setProducts(content);
        } else {
          content && setShops(content);
        }
        setOnResultReady(true);
      });
    }
  }, [
    dispatch,
    productSearchTerm,
    msisdn,
    searchType,
    sortType,
    filterType,
    moqValue,
    priceRange,
  ]);

  const onConfirmSearch = (text) => {
    !onFinalSearch && setOnFinalSearch(!onFinalSearch);
    setSearchTerm(text);
    setSearchValue(text);
    mixPanel.track(COMPLETE_SEARCH, {
      "User ID": user.userId,
      Time: new Date().toLocaleString(),
      searchValue: text,
    });
  };

  const onSearchTermChange = (text) => {
    onFinalSearch && setOnFinalSearch(!onFinalSearch);
    onResultReady && setOnResultReady(!onResultReady);
    setSearchTerm(text);
  };

  const setMoq = (data) => {
    if (data.moq) {
      setFilterType("MOQ");
      setMoqValue(data.moq);
      setOpenMoqPopup(!openMoqPopup);
    }
  };

  const setFilterPrice = (data) => {
    if (data.minPrice && data.maxPrice) {
      setFilterType("PRICE");
      setPriceRange(data);
      setOpenPriceRangePopup(!openPriceRangePopup);
    }
  };
  const handleClearButton = () => {
    dispatch(merchbuyActions.updateProductSearch(""));
    history.push("/actions/merchbuy");
  };

  if (isLoading) return <Loader />;

  return (
    <DesktopBackgroundLayout>
      <ScreenContainer
        color="#f9fafc"
        top={"0px"}
        padding={"0"}
        paddingBottom={"0"}
      >
        <Hold>
          <SectionWrapper
            bgcolor={"#ffffff"}
            padding={"1em 1em 0.5em"}
            margin={"0 0 4px"}
          >
            <SearchInputWithCancel
              value={searchTerm}
              placeholder={"Search for a product"}
              onChange={(e) => onSearchTermChange(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") onConfirmSearch(e.target.value);
              }}
              onCancel={handleClearButton}
            />
          </SectionWrapper>
        </Hold>
        {searchType === "PRODUCT" ? (
          <Fragment>
            {!onResultReady ? (
              <Fragment>
                <SectionWrapper
                  height={"100vh"}
                  bgcolor={"#ffffff"}
                  padding={"1em 1em 0.5em"}
                >
                  <RecentSearchText margin={"0 0 14px"}>
                    Recent Search
                  </RecentSearchText>
                  <RecentSearchWrapper>
                    {searchedProducts &&
                      searchedProducts.map((data, index) => (
                        <Chip
                          key={index}
                          onClick={() => {
                            onConfirmSearch(data.terms);
                          }}
                        >
                          {data.terms}
                        </Chip>
                      ))}
                  </RecentSearchWrapper>
                </SectionWrapper>
              </Fragment>
            ) : (
              <FlexCenteredBlock>
                <TabContainer top={"10px"} bottom={"0px"}>
                  {searchType === "PRODUCT" ? (
                    <ActiveTab>
                      <TabText>Products</TabText>
                    </ActiveTab>
                  ) : (
                    <NonActiveTab
                      onClick={() => {
                        searchType !== "PRODUCT" && setSearchType("PRODUCT");
                      }}
                    >
                      <TabText>Products</TabText>
                    </NonActiveTab>
                  )}
                  {searchType === "SHOP" ? (
                    <ActiveTab>
                      <TabText>Shops</TabText>
                    </ActiveTab>
                  ) : (
                    <NonActiveTab
                      onClick={() => {
                        searchType !== "SHOP" && setSearchType("SHOP");
                      }}
                    >
                      <TabText>Shops</TabText>
                    </NonActiveTab>
                  )}
                </TabContainer>
                {products && products.length > 0 ? (
                  <Container width={"100%"} padding={"1em"}>
                    <CenterText>
                      Showing {products && products.length} result(s)
                    </CenterText>
                    <ListProducts
                      data={products}
                      size={{ width: "160px", height: "96px" }}
                      searchTerm={searchTerm}
                    />
                  </Container>
                ) : (
                  <Container width={"100%"} padding={"1em"}>
                    <SectionWrapper
                      bgcolor={"#ffffff"}
                      padding={"2.5em 1em 2.5em"}
                      radius={"8px"}
                      width={"100%"}
                    >
                      <NoSearchFound top={"0"}>
                        <ReceiptSearch
                          style={{
                            width: "128px",
                            height: "135px",
                            alignSelf: "center",
                          }}
                        />
                        <TableText
                          style={{
                            marginTop: "10px",
                            marginBottom: "16px",
                            textAlign: "center",
                          }}
                        >
                          We couldn't find any matches
                        </TableText>
                        <SearchHint
                          style={{
                            width: "24px",
                            height: "24px",
                            alignSelf: "center",
                          }}
                        />
                        <Reason
                          display={"flex"}
                          align={"center"}
                          justify={"center"}
                          color={"#718596"}
                          margin={"14px 0 7px"}
                        >
                          <Dot color={"#718596"} width={"4px"} height={"4px"} />
                          Make sure the spelling is correct
                        </Reason>
                        <Reason
                          display={"flex"}
                          align={"center"}
                          justify={"center"}
                          color={"#718596"}
                        >
                          <Dot color={"#718596"} width={"4px"} height={"4px"} />
                          Use less keywords
                        </Reason>
                      </NoSearchFound>
                    </SectionWrapper>
                  </Container>
                )}
              </FlexCenteredBlock>
            )}
          </Fragment>
        ) : (
          <Fragment>
            {!onResultReady ? (
              <SectionWrapper
                height={"100vh"}
                bgcolor={"#ffffff"}
                padding={"1em 1em 0.5em"}
              >
                <NameLabel>Recent Searches</NameLabel>
                <RecentSearchWrapper>
                  {searchedShops &&
                    searchedShops.map((data, index) => (
                      <Chip
                        key={index}
                        onClick={() => {
                          onConfirmSearch(data.terms);
                        }}
                      >
                        {data.terms}
                      </Chip>
                    ))}
                </RecentSearchWrapper>
              </SectionWrapper>
            ) : (
              <FlexCenteredBlock>
                <TabContainer top={"10px"} bottom={"0px"}>
                  {searchType === "PRODUCT" ? (
                    <ActiveTab>
                      <TabText>Products</TabText>
                    </ActiveTab>
                  ) : (
                    <NonActiveTab
                      onClick={() => {
                        searchType !== "PRODUCT" && setSearchType("PRODUCT");
                      }}
                    >
                      <TabText>Products</TabText>
                    </NonActiveTab>
                  )}
                  {searchType === "SHOP" ? (
                    <ActiveTab>
                      <TabText>Shops</TabText>
                    </ActiveTab>
                  ) : (
                    <NonActiveTab
                      onClick={() => {
                        searchType !== "SHOP" && setSearchType("SHOP");
                      }}
                    >
                      <TabText>Shops</TabText>
                    </NonActiveTab>
                  )}
                </TabContainer>
                {shops && shops.length > 0 ? (
                  <Container>
                    <CenterText>
                      Showing {shops && shops.length} result(s){" "}
                    </CenterText>
                    <AddFlexBox>
                      <ListShops
                        data={shops}
                        size={{ width: "32px", height: "32px" }}
                        showProductCategory={false}
                      />
                    </AddFlexBox>
                  </Container>
                ) : (
                  <Container width={"100%"} padding={"1em"}>
                    <SectionWrapper
                      bgcolor={"#ffffff"}
                      padding={"2.5em 1em 2.5em"}
                      radius={"8px"}
                      width={"100%"}
                    >
                      <NoSearchFound top={"0"}>
                        <ReceiptSearch
                          style={{
                            width: "128px",
                            height: "134px",
                            alignSelf: "center",
                          }}
                        />
                        <TableText
                          style={{
                            marginTop: "10px",
                            marginBottom: "16px",
                            textAlign: "center",
                          }}
                        >
                          We couldn't find any matches
                        </TableText>
                        <SearchHint
                          style={{
                            width: "24px",
                            height: "24px",
                            alignSelf: "center",
                          }}
                        />
                        <Reason
                          display={"flex"}
                          align={"center"}
                          justify={"center"}
                          color={"#718596"}
                          margin={"14px 0 7px"}
                        >
                          <Dot color={"#718596"} width={"4px"} height={"4px"} />
                          Make sure the spelling is correct
                        </Reason>
                        <Reason
                          display={"flex"}
                          align={"center"}
                          justify={"center"}
                          color={"#718596"}
                        >
                          <Dot color={"#718596"} width={"4px"} height={"4px"} />
                          Use less keywords
                        </Reason>
                      </NoSearchFound>
                    </SectionWrapper>
                  </Container>
                )}
              </FlexCenteredBlock>
            )}
          </Fragment>
        )}
      </ScreenContainer>
      <OptionsPopupDialog
        open={sortOptionsOpen}
        title={"Sort"}
        cancel={() => {
          setSortOptionsOpen(!sortOptionsOpen);
        }}
        items={[
          {
            Icon: MostRecent,
            title: "Most Recent",
            selected: sortType === "ASC",
            click: () => {
              setSortOptionsOpen(!sortOptionsOpen);
              sortType !== "ASC" && setSortType("ASC");
            },
          },
          {
            Icon: Oldest,
            title: "Oldest",
            selected: sortType === "DESC",
            click: () => {
              setSortOptionsOpen(!sortOptionsOpen);
              sortType !== "DESC" && setSortType("DESC");
            },
          },
          {
            Icon: Descending,
            title: "Price (High - Low)",
            selected: sortType === "PRICE_H_L",
            click: () => {
              setSortOptionsOpen(!sortOptionsOpen);
              sortType !== "PRICE_H_L" && setSortType("PRICE_H_L");
            },
          },
          {
            Icon: Ascending,
            title: "Price (Low - High)",
            selected: sortType === "PRICE_L_H",
            click: () => {
              setSortOptionsOpen(!sortOptionsOpen);
              sortType !== "PRICE_L_H" && setSortType("PRICE_L_H");
            },
          },
        ]}
      />
      <OptionsPopupDialog
        open={openFilterOptions}
        title={"Filter"}
        cancel={() => {
          setOpenFilterOptions(!openFilterOptions);
        }}
        items={[
          {
            Icon: AllOrderSVG,
            title: "All Products",
            selected: filterType === "ALL",
            click: () => {
              setOpenFilterOptions(!openFilterOptions);
              filterType !== "ALL" && setFilterType("ALL");
            },
          },
          {
            Icon: PriceSVG,
            more: true,
            title: "Price",
            click: () => {
              setOpenFilterOptions(!openFilterOptions);
              setOpenPriceRangePopup(!openPriceRangePopup);
            },
          },
          {
            Icon: MoqSVG,
            more: true,
            title: "Minimum Order Quantity",
            click: () => {
              setOpenFilterOptions(!openFilterOptions);
              setOpenMoqPopup(!openMoqPopup);
            },
          },
        ]}
      />
      <MoqPopup
        open={openMoqPopup}
        setMoq={setMoq}
        cancel={() => {
          setOpenMoqPopup(!openMoqPopup);
        }}
      />
      <PriceRangePopup
        open={openPriceRangePopup}
        cancel={() => {
          setOpenPriceRangePopup(!openPriceRangePopup);
        }}
        setFilterPrice={setFilterPrice}
      />
    </DesktopBackgroundLayout>
  );
};

export default MerchbuySearch;
