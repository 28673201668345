import React, { Fragment } from "react";

import styled from "styled-components";

import { SubTitle, Title } from "../../containers/MessageContainer";
import {
  FlexCenteredBlock,
  ScreenContainer,
} from "../../containers/ScreenContainer";
import { colors } from "../../styles";
import DesktopBackgroundLayout from "../../views/DesktopBackgroundLayout";
import { RippleButton } from "../button";

import background from "./assets/background-new.svg";
import CheckMark from "./assets/checkmark.svg";
import { ReactComponent as Confetti } from "./assets/confetti.svg";
import union from "./assets/union310.png";

const Gif = styled.img`
  width: 65.03px;
  height: 65px;
  position: absolute;
  top: -45px;
`;

const MenuContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 60px 0 0 0;
  border-radius: 8px;
  background: url(${union}) bottom;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
`;

const CongratsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 128px;
  height: 24px;
  border-radius: 34px;
  background-color: rgba(255, 255, 255, 0.2);
  margin-bottom: 8px;
`;

const NewSuccessPage = ({
  children,
  showCongratIcon = false,
  headerText,
  homeLink,
  newBtnText,
  newBtnLink,
  titleWidth,
}) => {
  return (
    <Fragment>
      <DesktopBackgroundLayout>
        <ScreenContainer
          style={{
            minHeight: "100vh",
            background: ` no-repeat center/cover url(${background}), rgba(34, 126, 255, .9)`,
          }}
          top="0"
        >
          <FlexCenteredBlock top={"50px"} mtop="50px">
            {showCongratIcon && (
              <CongratsWrapper>
                <Confetti />
                <SubTitle
                  style={{ marginLeft: "4px" }}
                  font="10px"
                  weight="400"
                  lineHeight="16px"
                  color={colors.white}
                >
                  Congratulations
                </SubTitle>
              </CongratsWrapper>
            )}
            <Title
              size="16px"
              color={colors.white}
              weight="700"
              lineHeight="24px"
              bottom="16px"
              maxWidth={titleWidth}
            >
              {headerText}
            </Title>
            <MenuContent>
              <Gif src={CheckMark} alt="loading..." />
              {children}
            </MenuContent>
            {newBtnText && (
              <RippleButton
                top={"70px"}
                height="56px"
                size="16px"
                style={{
                  backgroundColor: `${colors.white}`,
                  color: `${colors.deepBlue}`,
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
                onClick={() => newBtnLink()}
              >
                {newBtnText}
              </RippleButton>
            )}
            <RippleButton
              top={"16px"}
              height="56px"
              size="16px"
              border={`1px solid ${colors.border.lightBlue}`}
              color={colors.aliceBlue}
              backgroundColor="transparent"
              onClick={() => homeLink()}
            >
              Go Home
            </RippleButton>
          </FlexCenteredBlock>
        </ScreenContainer>
      </DesktopBackgroundLayout>
    </Fragment>
  );
};

export default NewSuccessPage;
