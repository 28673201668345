import React, { Fragment, useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import { TopHeader } from "../../../components";
import { ScreenContainer } from "../../../containers/ScreenContainer";
import {
  getSupportContact,
  getFaqCategories,
} from "../../../redux/ducks/help/actions";
import DesktopBackgroundLayout from "../../DesktopBackgroundLayout";

import { ReactComponent as CallIcon } from "./assets/call.svg";
import { ReactComponent as HistoryIcon } from "./assets/complainthistory.svg";
import { ReactComponent as LogIcon } from "./assets/logcomplaint.svg";
import { ReactComponent as QuestionIcon } from "./assets/question.svg";
import { ReactComponent as SideArrowIcon } from "./assets/sidearrow.svg";
import { ReactComponent as WhatsappIcon } from "./assets/whatsapp.svg";
import { ReactComponent as StarIcon } from "./assets/star.svg";
import HelpRating from "./rate-spaces/HelpRating";

const Frame = styled.div`
  margin: 24px 0 0 0;
  text-align: center;
`;

const Text = styled.h3`
  color: #071827;
  font-size: 14px;
  font-weight: 400;
`;

const IconCover = styled.div`
  margin-right: 17px;
  display: flex;
  align-items: center;
`;

const InnerBox = styled.div`
  display: flex;
  align-items: center;
`;

const OptionBox = styled.div`
  display: flex;
  border: 1px solid #edf2f7;
  margin-bottom: ${({ bottom }) => bottom || "8px"};
  background: #ffffff;
  border-radius: 4px;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent || null};
  height: 56px;
  padding: 0 18px;
`;

const CallLink = styled.a``;

const Help = () => {
  const dispatch = useDispatch();
  const contact = useSelector((state) => state.help?.contactNumber);
  const surveyWindow = useSelector((state) => state.user.surveyWindow);
  const lastSurveyResponse = useSelector(
    (state) => state.user.lastSurveyResponse
  );
  const [openSurveyPage, setOpenSurveyPage] = useState(false);

  const arrowOptions = [
    {
      Icon: <LogIcon />,
      title: "Log a Complaint/Enquiry",
      SideIcon: <SideArrowIcon />,
      Link: "/help/log_complaint",
    },
    {
      Icon: <HistoryIcon />,
      title: "Complaint History",
      SideIcon: <SideArrowIcon />,
      Link: "/help/complaint_history",
    },
    {
      Icon: <QuestionIcon />,
      title: "Frequently Asked Questions",
      SideIcon: <SideArrowIcon />,
      Link: "/help/FAQ",
    },
  ];

  const noArrowOptions = [
    {
      Icon: <CallIcon />,
      title: "Call Our Support Line",
      Link: contact && `tel:${contact.contactNumber}`,
    },
    {
      Icon: <WhatsappIcon />,
      title: "Chat With Our Support Line",
      Link: contact && `https://wa.me/+234${contact.whatsappNumber}`,
    },
  ];

  const endDate =
    surveyWindow &&
    moment(new Date(surveyWindow?.startDate)).add(
      surveyWindow && surveyWindow?.length,
      "days"
    );

  const isBetween = moment(
    new Date(lastSurveyResponse && lastSurveyResponse?.createdAt)
  ).isBetween(surveyWindow?.startDate, endDate, "day", "[]");
  const isTodayBetween = moment(new Date()).isBetween(
    moment(surveyWindow?.startDate),
    moment(endDate),
    "day",
    "[]"
  );
  const isMatch = lastSurveyResponse && isTodayBetween && !isBetween;
  const displayCriteria =
    isMatch || !Object.entries(lastSurveyResponse || {})?.length;

  useEffect(() => {
    dispatch(getSupportContact());
    dispatch(getFaqCategories());
  }, [dispatch]);

  return (
    <Fragment>
      <DesktopBackgroundLayout>
        <TopHeader title={"Help and Support"} backLink={"/more"} />
        <ScreenContainer color="#F9FAFC" minHeight="100vh">
          <Frame>
            {arrowOptions.map((data, index) => (
              <Link to={data.Link} key={index}>
                <OptionBox justifyContent="space-between">
                  <InnerBox>
                    <IconCover>{data.Icon}</IconCover>
                    <Text>{data.title}</Text>
                  </InnerBox>
                  {data.SideIcon}
                </OptionBox>
              </Link>
            ))}
            {surveyWindow?.startDate && displayCriteria && (
              <OptionBox
                justifyContent="space-between"
                onClick={() => setOpenSurveyPage(!openSurveyPage)}
              >
                <InnerBox>
                  <IconCover>
                    <StarIcon />
                  </IconCover>
                  <Text>Rate Sabi</Text>
                </InnerBox>
                <SideArrowIcon />
              </OptionBox>
            )}
            {noArrowOptions.map((data, index) => (
              <CallLink key={index} href={data.Link}>
                <OptionBox>
                  <IconCover>{data.Icon}</IconCover>
                  <Text>{data.title}</Text>
                </OptionBox>
              </CallLink>
            ))}
          </Frame>
        </ScreenContainer>
      </DesktopBackgroundLayout>
      {openSurveyPage && (
        <HelpRating
          open={openSurveyPage}
          setOpen={() => setOpenSurveyPage(!openSurveyPage)}
        />
      )}
    </Fragment>
  );
};

export default Help;
