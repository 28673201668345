import { push } from "connected-react-router";
// import { verifyUser } from "../../../user/actions/index";
import { toast } from "react-toastify";

import { axios, currentAPI } from "../../../../../config";
import { countriesMap } from "../../../../../data/countries";
import {
  checkUserExistInLocalStorage,
  clearStorage,
} from "../../../../../utils/auth/clearStorage";
import {
  insertCountryCode,
  insertZero,
} from "../../../../../utils/inputs/formatPhoneNumber";
import { AUTH_ENTER_PHONE_NUMBER } from "../../../../../utils/mix-panel/constants";
import { mixPanel } from "../../../../../utils/mix-panel/mixPanel";
import { setExpired } from "../../../timer/actions";
import {
  SAVE_USER_TELEPHONE,
  SAVE_USER_ID,
  SAVE_USER_COUNTRY,
  SAVE_USER_NAME,
  SAVE_USER_AVATAR,
} from "../../../user/constants";
import { getUserPublicData } from "../../login/actions";
import {
  SENDING_TELEPHONE,
  SENDING_TELEPHONE_SUCCESS,
  SENDING_TELEPHONE_ERROR,
} from "../constants";

export const sendTelephone = (phoneNumber, country) => async (dispatch) => {
  dispatch({ type: SENDING_TELEPHONE });

  const countryCode = countriesMap.get(country).code;
  const msisdn = insertCountryCode(phoneNumber, countryCode);

  try {
    const response = await axios.post(
      `${currentAPI}/api/registerUser/validate/phoneNumber`,
      {
        countryCode,
        msisdn: msisdn,
        phoneNumber: insertZero(phoneNumber),
      }
    );
    const userData = await dispatch(getUserPublicData(response.data.userId));

    if (response.status === 200) {
      if (response.data.status === "USER_EXIST") {
        if (userData && userData.registrationFinished) {
          dispatch({ type: SENDING_TELEPHONE_SUCCESS });
          userData.avatar &&
            dispatch({ type: SAVE_USER_AVATAR, payload: userData.avatar });
          userData.firstName &&
            dispatch({ type: SAVE_USER_NAME, payload: userData });
          dispatch({ type: SAVE_USER_ID, payload: response.data.userId });
          dispatch(push("/login"));
        } else {
          const resetAuthorization = await axios.post(
            `${currentAPI}/api/authorize/reset/${encodeURIComponent(msisdn)}`
          );

          if (resetAuthorization.status === 200) {
            // await axios.get(`${currentAPI}/api/otp/send/mobile?msisdn=${encodeURIComponent(msisdn)}`);
            dispatch({ type: SAVE_USER_NAME, payload: userData });
            dispatch({ type: SAVE_USER_ID, payload: response.data.userId });
            dispatch({ type: SENDING_TELEPHONE_SUCCESS });
            dispatch(push({ pathname: "/check", userData }));
          }
        }
      } else {
        mixPanel.track(AUTH_ENTER_PHONE_NUMBER, {
          "Phone Number": phoneNumber,
        });
        dispatch({ type: SENDING_TELEPHONE_SUCCESS });
        dispatch({ type: SAVE_USER_ID, payload: response.data.userId });
        dispatch(push("/check"));
      }
    } else {
      clearStorage();
      toast.error("An error occured, please try again!");
    }
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.status === 400 &&
      error.response.status !== "USER_EXIST"
    ) {
      dispatch({ type: SENDING_TELEPHONE_SUCCESS });
      dispatch(setExpired(true));
      dispatch(push("/check"));
    } else {
      dispatch({
        type: SENDING_TELEPHONE_ERROR,
        payload: error.message || "An error occured, please try again!",
      });

      toast.error(error.message || "An error occured, please try again!");
    }
  } finally {
    dispatch({ type: SENDING_TELEPHONE_SUCCESS });
    dispatch({ type: SAVE_USER_TELEPHONE, payload: phoneNumber });
    dispatch({ type: SAVE_USER_COUNTRY, payload: country });
  }
};

export const validateTelephone = (phoneNumber, country) => async (dispatch) => {
  dispatch({ type: SENDING_TELEPHONE });

  const countryCode = countriesMap.get(country).code;
  const msisdn = insertCountryCode(phoneNumber, countryCode);

  try {
    const response = await axios.post(
      `${currentAPI}/api/users/validate/user/mobile/phoneNumber`,
      {
        msisdn: msisdn,
      }
    );

    if (response.status === 200 && response?.data?.status === "USER_EXIST") {
      const { user } = response.data || {};
      const userData = await dispatch(getUserPublicData(user.id));

      if (userData && userData.registrationFinished) {
        userData.avatar &&
          dispatch({ type: SAVE_USER_AVATAR, payload: userData.avatar });
        userData.firstName &&
          dispatch({ type: SAVE_USER_NAME, payload: userData });
        dispatch({ type: SAVE_USER_ID, payload: user.id });

        const forgotPassword = checkUserExistInLocalStorage(user.id);
        if (forgotPassword) {
          dispatch(push("/user/password-otp-options"));
        } else {
          dispatch(push("/login"));
        }
      } else {
        localStorage.setItem(
          "userData",
          JSON.stringify({
            firstName: userData.firstName || "",
            lastName: userData.lastName || "",
            shopName: userData?.businessProfileData?.businessName || "",
            referralCode: userData?.businessProfileData?.referralCode || "",
          })
        );

        dispatch(push({ pathname: "/otp_options", userData }));
      }
    } else {
      mixPanel.track(AUTH_ENTER_PHONE_NUMBER, {
        "Phone Number": phoneNumber,
      });
      localStorage.setItem("userData", JSON.stringify({}));
      dispatch(push("/otp_options"));
    }
  } catch (error) {
    const { status } = error?.response || {};
    if (status === 400 && status !== "USER_EXIST") {
      localStorage.setItem("userData", JSON.stringify({}));
      dispatch(setExpired(true));
      dispatch(push("/otp_options"));
    } else {
      dispatch({
        type: SENDING_TELEPHONE_ERROR,
        payload: error.message || "An error occured, please try again!",
      });

      toast.error(error.message || "An error occured, please try again!");
    }
  } finally {
    dispatch({ type: SENDING_TELEPHONE_SUCCESS });
    dispatch({ type: SAVE_USER_TELEPHONE, payload: insertZero(phoneNumber) });
    dispatch({ type: SAVE_USER_COUNTRY, payload: country });
  }
};

export const onTokenExpire = () => async (dispatch, getState) => {
  const phoneNumber = getState().user.msisdn;
  const country = getState().user.country;
  sendTelephone(phoneNumber, country);
};
