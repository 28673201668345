import React from "react";

import styled from "styled-components";

import { colors } from "../../../../../styles";
import { formatPrice } from "../../../../../utils/currency/formatPriceWithComma";

const Text = styled.div`
  font-weight: ${({ weight }) => weight || "normal"};
  font-size: ${({ font }) => font || "12px"};
  line-height: 18px;
  color: ${({ color }) => color || colors.lightDark};
`;

const Wrapper = styled.div`
  background: ${colors.white};
  height: 108px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  padding: 1.2em;
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PaymentInfo = ({ priceInfo }) => (
  <Wrapper>
    <InnerWrapper>
      <Text>Items Total</Text>
      <Text font="14px" color={colors.boldDark}>
        {priceInfo && formatPrice(priceInfo.subTotal)}
      </Text>
    </InnerWrapper>
    <InnerWrapper>
      <Text>Delivery Fee</Text>
      <Text font="14px" color={colors.boldDark}>
        {priceInfo && formatPrice(priceInfo.deliveryFee)}
      </Text>
    </InnerWrapper>
    <InnerWrapper>
      <Text>VAT (0%)</Text>
      <Text font="14px" color={colors.boldDark}>
        {priceInfo && formatPrice(priceInfo.vat)}
      </Text>
    </InnerWrapper>
    <InnerWrapper>
      <Text color={colors.boldDark}>TOTAL</Text>
      <Text weight="700" font="14px" color={colors.themeTextColor1}>
        {priceInfo && formatPrice(priceInfo.totalPrice)}
      </Text>
    </InnerWrapper>
  </Wrapper>
);
