const dashboardActions = {
  AGENCYBANKING: "#eae7ff",
  FINANCIAL: "#E9F2FF",
  SHOP: "#dff2ec",
  CONTACTS: "#fff6e5",
  LEARNING: "#ffe2de",
  SERVICES: "#e5f5ff",
  SHS: "#e5f5ff",
};

const myShop = {
  profit: {
    profit: "#4e9700",
    loss: "#ff0000",
  },
  totalSales: {
    bg: "#4e970040",
    text: "#4e9700",
  },
  totalOwed: {
    bg: "#d47c0c40",
    text: "#d47c0c",
  },
  inventory: {
    bg: "#5473e740",
    text: "#5473e7",
  },
  product: {
    inStock: {
      bg: "#4e970040",
      text: "#4e9700",
    },
    outOfStock: {
      bg: "#e0202040",
      text: "#e02020",
    },
  },
  orders: {
    bg: "#579fd740",
    text: "#579fd7",
  },
  reports: {
    bg: "#ff565640",
    text: "#ff5656",
  },
  customers: {
    bg: "#0091ff40",
    text: "#0091ff",
  },
  warning: "#fff6e6",
  pending: {
    bg: "#f0a82140",
    text: "#f0a821",
  },
  packed: {
    bg: "#f2f5fa",
    text: "#4e9700",
  },
  delivered: {
    bg: "#4e970040",
    text: "#4e9700",
  },
  rejected: {
    bg: "#e0202040",
    text: "#e02020",
  },
};

const transactions = {
  pending: "#ffc106",
  paid: "#4b8e03",
  withdrawal: "#e02020",
  successful: "#00B361",
  failed: "#E02020",
  credit: "#05944f",
  debit: "#e01a00",
  lightPending: "#FFC042",
};

const performanceChart = {
  MERCHANTS: "#0095ff",
  AGENTS: "#41d6ba",
  ORDERS: "#fa6400",
};

const remittance = {
  pending: "#FFC042",
  success: "#05944f",
  rejected: "#E01A00",
  successful: "#00B361",
  shipped: "#0064F0",
};

const colors = {
  aquamarine: "#a7d9ff33",
  tomatoFaded: "#e0202040",
  badge: {
    lightOrange: {
      text: "#d47c0c",
      bg: "#d47c0c40",
    },
    lightBlue: {
      bg: "#419bf910",
    },
    success: "#023b20",
  },
  background: {
    default: "#eee",
    header: "#fff",
    component: "#f7f7f7",
    progressBar: "#D2D6DB",
    overlay: "105 105 105",
    slidingOverlay: "255 255 255",
    ripple: "#e4eeff",
    logo: "#559fd71a",
    circle: "#4e86c6",
    lightCircle: "#EDE6FE",
    walletBadge: "#22a8ff",
    primaryPad: "#E9F2FF",
    secondaryPad: "#FDEDE7",
    failedStatus: "#ffc3bd",
  },
  pinkLight3: "#FFE0EF",
  themeTextColor0: "#A0B1C0",
  themeTextColor1: "#212c3d",
  themeTextColor2: "#e8e8e8",
  themeTextColor3: "#0B0C0E",
  themeTextColor4: "#919394",
  themeTextColor5: "#253246",
  themeTextColor6: "#6c7983",
  themeTextColor7: "#579fd7",
  themeTextColor8: "#afb7bd",
  themeTextColor9: "#fafafa",
  themeTextColor10: "#003277",
  themeTextColor11: "#5C6F7F",
  themeTextColor12: "#227EFF",
  themeTextColor13: "#001E4C",
  themeTxtColor10: "#071827",
  themeTxtColor11: "#e01a00",
  themeTxtColor12: "#00b361",
  themeSubColor1: "#718596",
  themeSubColor2: "#ffad0a",
  themeColor1: "#027CD2",
  themeColor2: "#212c3d",
  themeColor3: "#f2f5fa",
  themeColor4: "#0000001f",
  themeColor5: "#56636d1a",
  themeColor6: "#838f9a",
  skyBlue: "#A7D9FF",
  darkGrey: "#8f959d",
  lightGrey: "#a3acb3",
  faintGrey: "#A0B1C0",
  subGrey: "#718596",
  lightPink: "#FDEDE7",
  lightGrey1: "#c8d1da",
  border: {
    default: "#f2f5fa",
    active: "#212c3d",
    input: "#a3acb3",
    error: "#e02020",
    top: "#f2f5fa",
    bottom: "#f2f5fa",
    primary: "#227EFF",
    lightBlue: "#CBD6E0",
  },
  setting: {
    userTypeBg: "#E9F2FF",
    userTypeTextColor: "#0350BB",
    agentTier1Bg: "#4caf5033",
    agentTier1TextColor: "#4caf50",
    agentTier2Bg: "#11237d33",
    agentTier2TextColor: "#11237d",
  },
  link: {
    inactive: "rgb(86, 99, 109)",
    active: "#579FD7",
  },
  popup: {
    header: "#333238",
    body: "#6c7984",
    cancelButton: "#FFFFFF",
    confirm: "#4e9700",
    reject: "#e02020",
  },
  switch: {
    on: "#22a8ff",
    off: "#bde5ff",
    noob: "#ffffff",
  },
  linkHoverColor: "#00FFFF",
  lightBoldBlue: "#1260b8",
  blueish: "rgba(87, 159, 215, .2)",
  gray1: "#cccccc",
  gray2: "#dddddd",
  gray3: "#eeeeee",
  gray4: "#EDF2F7",
  smoothGreyText: "#6c7984",
  gray5: "#aaa",
  gray6: "#808080",
  yellow: "#FFFF00",
  yellowBg: "#ffebaf",
  green: "#5cbb23",
  greenText: "#6dd400",
  greenText2: "#024525",
  greenBg: "#dcffb7",
  orange: "#fa6400",
  orangeGradient: "#ffac74",
  red: "#e02020",
  redBg: "#ff9a9a",
  lightGray1: "#edf2f2",
  lightRed: "#ffdfdf",
  lightYellow: "#fff6e6",
  blue: "#22a8ff",
  deepBlue: "#227EFF",
  blue2: "#62a9dc",
  silver: "#f2f2f2",
  white: "#fff",
  black: "#333",
  aliceBlue: "#E9F2FF",
  bgBlue: "#0350BB",
  boldDark: "#071827",
  lightDark: "#718596",
  lightGray: "#E2E9F0",
  lightestGray: "#F9FAFC",
  textLightDark: "#6F798B",
  redAlert: "#FF705E",
  lightGreen: "#D3FDE9",
  textGreen: "#05944F",
  lightRedBg: "#FFDFDB",
  textRed: "#E01A00",
  lightYellowBg: "#FFF7E6",
  textYellow: "#D78F00",
  notificationBg: "#fbfcfd",
  lightGray2: "#2E4457",
  darkRed: "#A31300",
  darkGreen1: "#024525",
  lightGray3: "#BBC0C9",
  textActivate: "#cd4213",
  cardBorder: "#b6c1de",
  headerBorder: "#ececf2",
  popupText: "#353645",
  warning: "#805500",
  bgAmount: "#fff1f0",
  sabiBlue: "#3C48FC",
  pink: "#7e42f5",
  pink2: "#dbcafc",

  dashboardActions: { ...dashboardActions },
  transactions: { ...transactions },
  performanceChart: { ...performanceChart },
  myShop: { ...myShop },
  remittance: { ...remittance },
};

const fonts = {
  main: `'DM Sans', sans-serif;`,
};

export { colors, fonts };
