import React from "react";

import { Link } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as ShareIcon } from "../../assets/upload-share.svg";
import { colors } from "../../styles";
import { ExtendComponent } from "../../styles/hoc/extendComponent";

export const Button = styled.button`
  display: inline-block;
  background-color: ${({ backgroundColor }) =>
    backgroundColor || colors.deepBlue};
  border-radius: ${({ radius }) => radius || "4px"};
  font-size: ${({ size }) => size || "14px"};
  font-weight: ${({ weight }) => weight || "500"};
  height: ${({ height }) => height || "45px"};
  width: ${({ width }) => width || "100%"};
  border: ${({ border }) => border || "none"};
  margin: ${({ margin }) => margin || null};
  z-index: ${({ zIndex }) => zIndex || null};
  margin-top: ${({ top }) => top || "32px"};
  margin-bottom: ${({ bottom }) => bottom || null};
  margin-right: ${({ right }) => right || null};
  margin-left: ${({ left }) => left || null};
  color: ${({ color }) => color || colors.white};
  display: ${({ display }) => display || null};
  justify-content: ${({ justifyContent }) => justifyContent || null};
  cursor: pointer;
  position: ${({ position }) => position || "relative"};
  top: ${({ ptop }) => ptop || null};
  overflow: hidden;
  padding: ${({ padding }) => padding || "0px"};
  outline: none;
  &:focus::after {
    display: block;
  }

  &:disabled {
    opacity: 0.5;
  }

  ${({ mediaFontSize }) =>
    mediaFontSize &&
    `
        @media only screen and (max-width: 330px) {
            font-size: 10px;
        }
    `}
`;

export const InlineButton = styled.button`
  display: inline-block;
  margin: 0;
  padding: 0;
  border: none;
  background: transparent;
  font-size: 12px;
  font-weight: ${({ weight }) => weight || "lighter"};
  line-height: ${({ height }) => height || null};
  color: ${colors.deepBlue};
  position: relative;
  overflow: hidden;
  cursor: pointer;
  &:focus::after {
    display: block;
  }
`;

const TextLabel = styled.label`
  &:active::after {
    display: block;
    margin: 0;
    top: calc(50% - 6px);
    left: calc(50% - 3px);
    max-width: 6px;
    max-height: 6px;
  }
`;

export const RippleEffect = `
	&::after {
		display: none;
		content: "";
		position: absolute;
		border-radius: 50%;
		background-color: ${colors.background.ripple};
		width: 100px;
		height: 100px;
		margin-top: -50px;
		margin-left: -50px;
		top: 50%;
		left: 50%;
		animation: ripple 1.5s;
		opacity: 0;
	}

	@keyframes ripple {
		from {
			opacity: 1;
			transform: scale(0);
		}

		to {
			opacity: 0;
			transform: scale(10);
		}
	}
`;

export const RippleInlineButton = ExtendComponent(InlineButton, ``);
export const RippleButton = ExtendComponent(
  Button,
  `
        position: relative;
		// z-index: 5;
        width: ${({ width }) => width || "100%"};
		-webkit-tap-highlight-color: transparent;
        &:after{
            content: "";
                position: absolute;
                left: 50%;
                top:50%;
                margin: -15px 0 0 -30px;
                border-radius: 45%;
                z-index: -1;
        };
        &:active:after{
                height: 30px;
                width: 60px;
                display: block;
                transform: scale(30);
                transition: 1s;
        };
    `
);

export const RippleLabel = ExtendComponent(TextLabel, ``);
export const RippleLink = ExtendComponent(
  Link,
  `
	-webkit-tap-highlight-color: transparent;
     margin-bottom: ${({ bottom }) => bottom || null};
`
);

export const FloatingButtonWrapper = styled.div`
  position: sticky;
  bottom: ${({ bottom }) => bottom || "0"};
  z-index: ${({ zIndex }) => zIndex || "10000"};
  padding: 20px 20px;
  background: white;
`;

export const FloatingButton = styled(RippleButton)`
  margin: 0 16px;
  width: calc(100% - 32px);
`;

export const LightRippleButton = styled(RippleButton)`
  background-color: ${colors.blueish};
  height: ${({ height }) => height || null};
  width: ${({ width }) => width || null};
  margin: ${({ margin }) => margin || null};
  color: ${({ color }) => color || colors.blue};
  &:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -15px 0 0 -30px;
    background: #cfd0d1;
    border-radius: 45%;
    z-index: -1;
  }
`;

export const LightButton = styled(RippleButton)`
  position: ${({ position }) => position || null};
  right: ${({ right }) => right || null};
  background-color: ${({ background }) => background || "#e9f6ff"};
  height: ${({ height }) => height || null};
  z-index: ${({ zIndex }) => zIndex || null};
  width: ${({ width }) => width || null};
  margin: ${({ margin }) => margin || null};
  color: ${({ color }) => color || "#22a8ff"};
  font-size: ${({ size }) => size || null};
  font-weight: ${({ weight }) => weight || null};
  border-radius: ${({ radius }) => radius || "4px"};
  border: solid 1px ${({ borderColor }) => borderColor || colors.deepBlue};
  cursor: ${({ cursor }) => cursor || null};
`;

const IconBlock = styled.div`
  margin-right: 15px;
`;

const ButtonWithIcon = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonWithShareIcon = ({
  title = "Share",
  width,
  icon,
  backgroundColor,
  radius,
  color,
  onClick,
  top,
  size,
}) => {
  return (
    <ButtonWithIcon
      size={size}
      width={width}
      radius={radius}
      backgroundColor={backgroundColor}
      color={color}
      onClick={onClick}
      top={top}
    >
      <IconBlock>{icon ? icon : <ShareIcon />}</IconBlock>
      {title}
    </ButtonWithIcon>
  );
};
