import React, { useEffect, useState } from "react";

import { bool, string, func } from "prop-types";
import styled from "styled-components";

import { Overlay } from "../../../containers/OverlayContainer";
import { RippleButton } from "../../button";
import { PopUp, PopUpContent } from "../common";

import AmazingIcon from "./assets/amazing.png";
import BgIcon from "./assets/bg.png";
import CancelIcon from "./assets/cancel.png";
import HappyIcon from "./assets/happy.png";
import NoIcon from "./assets/no.png";
import NotReallyIcon from "./assets/not_really.png";
import TerribleIcon from "./assets/terrible.png";

const RateBox = styled.div`
  padding: 16px;
`;

export const RatingTextArea = styled.textarea`
  width: 100%;
  padding: 16px;
  border: 1px solid #cbd6e0;
  border-radius: 4px;
  height: ${({ height }) => height || "119px"};
  font-size: 14px;
  font-weight: 500;
  &:focus {
    outline: none;
  }
`;

const Header = styled.div`
  height: 150px;
  background: #0350bb;
  background-image: url(${BgIcon});
`;
const Bottom = styled.div`
  height: 190px;
  background: #ffffff;
`;

const ImageWrapper = styled.div`
  position: relative;
  height: 40px;
  width: 100%;
  margin: 15px;
  display: flex;
  justify-content: flex-end;
  @media (min-width: 576px) {
    max-width: ${({ fullMaxWidth }) => fullMaxWidth || "375px"};
    width: ${({ fullWidth }) => fullWidth || null};
    padding-right: 16px;
  }
`;

const IconWrapper = styled.div`
  position: relative;
  height: 56px;
  width: 56;
  margin: 15px;
  display: flex;
  justify-content: flex-end;
`;

const Icon = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 40px;
  object-fit: cover;
`;

const Emoji = styled.img`
  width: 35px;
  height: 35px;
  border-radius: 40px;
  object-fit: cover;
`;

const ProgressWrapper = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
`;
const Progress = styled.div`
  width: 72px;
  height: 4px;
  background: #edf2f7;
  border-radius: 24px;
  margin: 3px;
`;

const UnProgress = styled.div`
  width: 72px;
  height: 4px;
  background: #64a4ff;
  border-radius: 24px;
  margin: 3px;
`;

const TextWrapper = styled.div`
  font-weight: bold;
  font-size: 20px;
  padding-left: 27px;
  padding-right: 27px;
  line-height: 30px;
  height: 60px;
  text-align: left;
  color: #ffffff;
`;

const CommentWrapper = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #718596;
  text-align: center;
`;

const ActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ActionInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ActionTextWrapper = styled.div`
  font-size: 12px;
  line-height: 18px;
  align-items: center;
  color: #071827;
`;

const followUpQuestion1 = "Will you recommend Sabi to your friends and family?";
const followUpQuestion2 =
  "Please tell us how satisfied you were with this service?";
const followUpQuestion3 = "How easy was it to get help from us?";

const questions = [
  "Will you recommend Sabi to your friends and family?",
  "Please tell us how satisfied you were with this service?",
  "How easy was it to get help from us?",
];

const stepInfo = [
  {
    selectedStep: [
      { icon: NoIcon, text: "No", score: 30 },
      { icon: NotReallyIcon, text: "Not Really", score: 60 },
      { icon: HappyIcon, text: "Yes", score: 100 },
    ],
  },
  {
    selectedStep: [
      { icon: TerribleIcon, text: "Terrible", score: 0 },
      { icon: NoIcon, text: "Bad", score: 30 },
      { icon: NotReallyIcon, text: "Neutral", score: 50 },
      { icon: HappyIcon, text: "Good", score: 70 },
      { icon: AmazingIcon, text: "Amazing", score: 100 },
    ],
  },
  {
    selectedStep: [
      { icon: TerribleIcon, text: "Terrible", score: 0 },
      { icon: NoIcon, text: "Bad", score: 30 },
      { icon: NotReallyIcon, text: "Neutral", score: 50 },
      { icon: HappyIcon, text: "Good", score: 70 },
      { icon: AmazingIcon, text: "Amazing", score: 100 },
    ],
  },
];

export const NpsPopup = ({ open, updateNps, close, surveyType }) => {
  const [step, setStep] = useState(0);
  const [feedbackDtos, setFeedbackDtos] = useState([]);
  const [showTextBox, setShowTextBox] = useState(false);
  const [content, setContent] = useState("");
  const [secondContent, setSecondContent] = useState("");
  const [thirdContent, setThirdContent] = useState("");

  const stepOneCriteria = step === 0 && !content;
  const stepTwoCriteria = step === 1 && !secondContent;
  const stepThreeCriteria = step === 2 && !thirdContent;

  const { selectedStep } = stepInfo[step] || {};

  const handleStep = (followUpResponse, promoterScore) => {
    setFeedbackDtos([
      ...feedbackDtos,
      {
        followUpQuestion:
          step === 0
            ? followUpQuestion1
            : step === 1
            ? followUpQuestion2
            : followUpQuestion3,
        followUpResponse,
        promoterScore,
      },
    ]);
    if (promoterScore <= 30) {
      setShowTextBox(true);
      return;
    }

    step < 2 && setStep(step + 1);
  };
  const values = {
    externalRef: "",
    responded: true,
    surveyId: surveyType && surveyType.id,
    value1: feedbackDtos[0]?.promoterScore,
    value2: content,
    value3: feedbackDtos[1]?.promoterScore,
    value4: secondContent,
    value5: feedbackDtos[2]?.promoterScore,
    value6: thirdContent,
  };

  useEffect(() => {
    if (!showTextBox && feedbackDtos.length === 3) {
      updateNps(values);
      setTimeout(() => {
        setFeedbackDtos([]);
        setStep(0);
        setContent("");
        setSecondContent("");
        setThirdContent("");
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackDtos, showTextBox]);

  return (
    <>
      {open && (
        <Overlay
          onClick={() => null}
          zIndex={"999999"}
          bgc={"rgba(0, 0, 0, 45%)"}
        />
      )}
      <PopUp flex="column" bottom="20%" open={open} zIndex={"1000000"}>
        <ImageWrapper margin={false}>
          <Icon
            src={CancelIcon}
            alt="cancel"
            onClick={() => {
              close();
            }}
          />
        </ImageWrapper>
        <PopUpContent>
          <Header>
            <ProgressWrapper>
              <Progress />
              {step === 0 ? <UnProgress /> : <Progress />}
              {step === 0 ? (
                <UnProgress />
              ) : step === 1 ? (
                <UnProgress />
              ) : (
                <Progress />
              )}
            </ProgressWrapper>

            <TextWrapper>{questions[step]}</TextWrapper>
          </Header>
          <Bottom>
            {showTextBox ? (
              <RateBox>
                <RatingTextArea
                  onChange={(event) => {
                    if (step === 0) setContent(event.target.value);
                    if (step === 1) setSecondContent(event.target.value);
                    if (step === 2) setThirdContent(event.target.value);
                  }}
                  type={"text"}
                  placeholder={"Tell us how we can improve"}
                  name={"description"}
                  height={"90px"}
                />
                <RippleButton
                  top={"10px"}
                  onClick={() => {
                    setShowTextBox(false);
                    step < 2 && setStep(step + 1);
                  }}
                  disabled={
                    stepOneCriteria || stepTwoCriteria || stepThreeCriteria
                  }
                >
                  Next
                </RippleButton>
              </RateBox>
            ) : (
              <>
                <CommentWrapper>
                  Select a face that best describes your response
                </CommentWrapper>
                <ActionWrapper>
                  {selectedStep.map((data, index) => (
                    <ActionInnerWrapper key={index}>
                      <IconWrapper>
                        <Emoji
                          onClick={() => handleStep(data.text, data.score)}
                          src={data.icon}
                          alt={data.text}
                        />
                      </IconWrapper>
                      <ActionTextWrapper>{data.text}</ActionTextWrapper>
                    </ActionInnerWrapper>
                  ))}
                </ActionWrapper>
              </>
            )}
          </Bottom>
        </PopUpContent>
      </PopUp>
    </>
  );
};

NpsPopup.propTypes = {
  open: bool,
  cancel: func,
  status: string,
};
