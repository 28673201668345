import React, { useEffect, useState } from "react";

import { bool, func } from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import { ListItem, ListHeading } from "../../../containers/ListContainer";
import { Overlay } from "../../../containers/OverlayContainer";
import { merchbuyActions } from "../../../redux/ducks/applications/merchbuy/actions";
import {
  fetchDeliveryDetails,
  getCustomersAndReferrals,
} from "../../../redux/ducks/applications/my-shop/actions/customers";
import { colors } from "../../../styles";
import { mapAvailableStates } from "../../../utils/inputs/mapAvailableStates";
import { PopUp, PopUpContent, PopUpHeader, ConfirmButton } from "../common";

const Header = styled(PopUpHeader)`
  font-weight: 700;
`;

const StyledListItemContainer = styled.div`
  overflow-y: scroll;
  max-height: 200px;
`;

const ModifiedListItem = styled(ListItem)`
  align-items: center;
  padding-top: 0px;
  background: ${({ selected }) =>
    selected ? colors.themeColor3 : colors.white};
`;

const ModifiedConfirmButton = styled(ConfirmButton)`
  background-color: ${colors.popup.cancelButton};
  color: ${colors.deepBlue};
`;

export const DeliveryLocationPopup = ({ open, getLocation }) => {
  const dispatch = useDispatch();
  const listOfAvailableStates = useSelector(
    (state) => state.applications.merchbuy.availableStates
  );
  const [state, setState] = useState([]);
  const [selected, setSelected] = useState({});

  useEffect(() => {
    dispatch(merchbuyActions.getAVailableStates());
    dispatch(merchbuyActions.getAllCities());
    dispatch(getCustomersAndReferrals());
    dispatch(fetchDeliveryDetails());
  }, [dispatch]);

  useEffect(() => {
    setState(mapAvailableStates(listOfAvailableStates));
  }, [listOfAvailableStates]);

  const onOkayClick = () => {
    getLocation(selected);
    localStorage.setItem("deliveryLocation", JSON.stringify(selected));
  };

  return (
    <PopUp open={open}>
      <Overlay nonSliding={true}></Overlay>
      <PopUpContent height={"350px"}>
        <Header align={"left"}>Delivery Location</Header>
        <StyledListItemContainer>
          {state.map((entry, index) => (
            <ModifiedListItem
              key={index}
              height={"48px"}
              pressedUpList
              selected={entry.label === selected.state}
              onClick={() => setSelected({ state: entry.label })}
            >
              <ListHeading>{entry.label}</ListHeading>
            </ModifiedListItem>
          ))}
        </StyledListItemContainer>
        <ModifiedConfirmButton
          type="button"
          disabled={!selected || Object.keys(selected).length === 0}
          onClick={onOkayClick}
        >
          Okay
        </ModifiedConfirmButton>
      </PopUpContent>
    </PopUp>
  );
};

DeliveryLocationPopup.propTypes = {
  open: bool,
  getLocation: func,
};
