import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import styled from "styled-components";

import { Loader } from "../../../../components";
import { ScreenContainer } from "../../../../containers/ScreenContainer";
import useConfirmPayement from "../../../../hooks/useConfirmPayement";
import {
  confirmPaymentLink,
  getPublicPaymentLinkDetails,
} from "../../../../redux/ducks/account/wallet/actions";
import { formatPrice } from "../../../../utils/currency/formatPriceWithComma";
import { formatCreationDate } from "../../../../utils/date/formatCreationDate";
import { getParameterByName } from "../../../../utils/getUrlParamsByName";
import DesktopBackgroundLayout from "../../../DesktopBackgroundLayout";
import { Text } from "../../merchbuy/styles";
import { ReactComponent as CallIcon } from "../assets/call.svg";
import { ReactComponent as MessageIcon } from "../assets/message.svg";
import { ReactComponent as PaymentLinkIcon } from "../assets/payment_link_sabi.svg";
import { ReactComponent as StoreIcon } from "../assets/store.svg";
import { ReactComponent as SuccessIcon } from "../assets/success.svg";
import PaymentLinkDetails from "../view-payments/payment-details";

import PaymentFooter from "./payment-footer";

const PaymentLinkWrapper = styled.div`
  min-height: 104px;
  padding: 18px;
  border: 1px solid #e2e9f0;
  background-color: #f6f8fd;
  border-radius: 4px;
`;

const LinkIconWrapper = styled.div`
  position: relative;
  justify-content: center;
  display: flex;
  top: 15px;
`;

const PaymentLinkTextDetails = styled.p`
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: #071827;
`;

const PaymentSuccessContainer = styled.div`
  height: 34px;
  background-color: #d3fde9;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin: 0 15px;
  gap: 15px;
`;

const RullerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-top: 32px;
`;

const Ruller = styled.div`
  border: 1px solid #e2e9f0;
  min-width: 70%;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  gap: ${({ gap }) => gap || null};
`;

const PayWithPaymentLink = () => {
  const dispatch = useDispatch();
  const response = useConfirmPayement(confirmPaymentLink);

  const [paymentDetails, setPaymentDetails] = useState(null);
  const [showReceipt, setShowReceipt] = useState(false);
  const [loading, setLoading] = useState(true);

  const reference = getParameterByName("reference");
  const linkingReference = getParameterByName("linkingreference");

  const getDetails = async () => {
    if (reference) {
      const res = await dispatch(getPublicPaymentLinkDetails(reference));
      if (res) {
        setPaymentDetails(res);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (reference && linkingReference) {
      if (response) {
        setPaymentDetails(response);
        setLoading(false);
      }
    } else if (reference) {
      getDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkingReference, reference, response]);

  const onDownloadClick = () => {
    setShowReceipt(true);
    setTimeout(() => {
      window.print();
    }, 3000);
  };

  if (loading) {
    return <Loader />;
  } else if (showReceipt) {
    const transactionInfo = {
      status:
        paymentDetails.status === "CLOSED"
          ? "SUCCESSFUL"
          : paymentDetails.status,
      transactionClass: "PAYMENT_LINK",
      transactionStatus:
        paymentDetails.status === "CLOSED"
          ? "SUCCESSFUL"
          : paymentDetails.status,
      requestedOn: paymentDetails.createdAt,
      requestedBy: `${paymentDetails?.ownerFirstName} ${paymentDetails?.ownerLastName}`,
      paidOn: paymentDetails.createdAt,
      transactionAmount: paymentDetails.amount,
      reference: paymentDetails.paymentReference,
      narration: paymentDetails.description,
      transactionType:
        paymentDetails.status === "CLOSED"
          ? "PAYMENT_LINK_CLOSED"
          : "PAYMENT_LINK_OPEN",
    };

    return (
      <PaymentLinkDetails
        link="/actions/payment-links/pay"
        backAction={() => setShowReceipt(false)}
        showComplaintBtn={false}
        transactionInfo={transactionInfo}
      >
        <PaymentFooter
          onDownloadClick={onDownloadClick}
          paymentDetails={paymentDetails}
          btnBackground="#ffffff"
        />
      </PaymentLinkDetails>
    );
  }

  return (
    <DesktopBackgroundLayout>
      <ScreenContainer color="#F9FAFC" top="0">
        <LinkIconWrapper>
          <PaymentLinkIcon />
        </LinkIconWrapper>
        <PaymentLinkWrapper>
          <PaymentLinkTextDetails>{`A payment of ${formatPrice(
            paymentDetails?.amount
          )} has been requested from you.`}</PaymentLinkTextDetails>
          {paymentDetails?.status !== "OPENED" && (
            <PaymentSuccessContainer>
              <SuccessIcon />
              <Text color="#012816" font="12px" fontWeight="500">
                You paid already
              </Text>
            </PaymentSuccessContainer>
          )}
        </PaymentLinkWrapper>
        <RullerContainer>
          <Text fontWeight="400" font="10px" color="#5C6F7F">
            YOUR DETAILS
          </Text>
          <Ruller></Ruller>
        </RullerContainer>
        <Text
          margin="0 0 4px 0"
          color="#071827"
          font="14px"
          fontWeight="500"
        >{`${paymentDetails?.firstName} ${paymentDetails?.lastName}`}</Text>
        <Text margin="0 0 32px 0" color="#5C6F7F" font="12px" fontWeight="400">
          {paymentDetails?.phoneNumber}
        </Text>
        <RullerContainer>
          <Text fontWeight="400" font="10px" color="#5C6F7F">
            REQUEST DETAILS
          </Text>
          <Ruller></Ruller>
        </RullerContainer>
        <FlexContainer>
          <FlexContainer gap="8px">
            <StoreIcon />
            <div>
              <Text
                color="#071827"
                font="14px"
                fontWeight="500"
              >{`${paymentDetails?.ownerFirstName} ${paymentDetails?.ownerLastName}`}</Text>
              <Text
                margin="4px 0 0 0"
                color="#5C6F7F"
                font="12px"
                fontWeight="400"
              >
                {paymentDetails?.ownerPhoneNumber}
              </Text>
            </div>
          </FlexContainer>
          <FlexContainer gap="4px">
            <CallIcon />
            <MessageIcon />
          </FlexContainer>
        </FlexContainer>
        <FlexContainer>
          <Text color="#5C6F7F" font="12px" fontWeight="400">
            Amount
          </Text>
          <Text color="#071827" font="12px" fontWeight="400">
            {formatPrice(paymentDetails?.amount)}
          </Text>
        </FlexContainer>
        <FlexContainer>
          <Text color="#5C6F7F" font="12px" fontWeight="400">
            Requested on
          </Text>
          <Text color="#071827" font="12px" fontWeight="400">
            {formatCreationDate(paymentDetails?.createdAt)}
          </Text>
        </FlexContainer>
        <FlexContainer>
          <Text color="#5C6F7F" font="12px" fontWeight="400">
            Narration
          </Text>
          <Text color="#071827" font="12px" fontWeight="400">
            {paymentDetails?.description}
          </Text>
          <PaymentFooter
            onDownloadClick={onDownloadClick}
            setShowReceipt={() => setShowReceipt(true)}
            paymentDetails={paymentDetails}
          />
        </FlexContainer>
      </ScreenContainer>
    </DesktopBackgroundLayout>
  );
};

export default PayWithPaymentLink;
