import React, { Fragment, useState, useEffect } from "react";

import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as CustomerSVG } from "../../../../assets/customer.svg";
import { ReactComponent as DeleteSVG } from "../../../../assets/delete1.svg";
import { ReactComponent as Divider } from "../../../../assets/line.svg";
import { ReactComponent as Line } from "../../../../assets/short-line.svg";
import { RippleButton, TopHeader } from "../../../../components";
import { NewAmountInputWithLabel } from "../../../../components/forms/input/new-amount-input";
import { NewTextInputWithLabel } from "../../../../components/forms/input/new-text-input";
import NewSuccessPage from "../../../../components/success-page/new-success-page";
import { ScreenContainer } from "../../../../containers/ScreenContainer";
import { generatePaymentLink } from "../../../../redux/ducks/account/wallet/actions";
import { colors } from "../../../../styles";
import { formatPrice } from "../../../../utils/currency/formatPriceWithComma";
import { formatCreationDate } from "../../../../utils/date/formatCreationDate";
import { CREATE_PAYMENT_LINK } from "../../../../utils/mix-panel/constants";
import { mixPanel } from "../../../../utils/mix-panel/mixPanel";
import DesktopBackgroundLayout from "../../../DesktopBackgroundLayout";
import { Text } from "../../merchbuy/styles";
import { CustomersPopUp } from "../../my-shop/sales/add-sale/components/customers";
import { ReactComponent as InfoSvg } from "../assets/Info.svg";

import SharePaymentLink from "./share-payment-link";

const DetailsContainer = styled.div`
  display: flex;
  margin-top: 16px;
`;

const TextDetails = styled.p`
  color: ${colors.themeTextColor11};
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 0 12px;
  width: 85%;
`;

const CustomerWrapper = styled.div`
  background-color: ${colors.themeColor3};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  margin-bottom: 10px;
  padding: 15px;
  border: 1px solid ${colors.lightGray};
  border-radius: 4px;
`;

const AddCustomerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const CustomerText = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: ${colors.themeTextColor11};
`;

const AddCustomerText = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: ${colors.deepBlue};
`;

const Ruler = styled.div`
  margin: 16px 5px 24px;
  border: 1px solid ${colors.lightGray};
`;

const InputText = styled.p`
  margin-top: ${({ top }) => top || "0px"};
  font-weight: 400;
  font-size: ${({ size }) => size || "12px"};
  color: ${colors.themeTextColor11};
  text-align: ${({ align }) => align || "left"};
`;

const NameWrapper = styled.div`
  height: 24px;
  width: 24px;
  border-radius: 24px;
  background: ${colors.lightYellowBg};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InitialAndCustomerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const InitialsText = styled.p`
  margin: 0;
  font-size: 10px;
  font-weight: 500;
  color: ${colors.textYellow};
`;

const PricesContainer = styled.div`
  background-color: ${colors.lightGray1};
  width: calc(100% - 32px);
  margin: 40px 23px 23px;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 15px 0px;
`;

const CustomerDetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 10px;
`;

const CustomerInnerWrapper = styled.div`
  margin: 0 15px;
`;

const ShortLineContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledButton = styled(RippleButton)`
  position: fixed;
  bottom: 40px;
  left: 0;
  right: 0;
  margin: auto;
  width: 343px;

  @media (max-width: 576px) {
    width: calc(100% - 32px);
  }
`;

const CreatePaymentLink = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showCustomersPopUp, setShowCustomersPopUp] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(undefined);
  const [showSuccess, setShowSuccess] = useState(false);
  const [paymentData, setPaymentData] = useState(null);

  const checkIsDisabled = (values) => {
    if (!selectedCustomer || !values.Amount || !values.Narration) {
      return true;
    } else if (values.Narration.length > 20) {
      return true;
    } else if (values.Amount > 20000) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (selectedCustomer?.customerName && !selectedCustomer?.firstName) {
      const [firstName, lastName = firstName] =
        selectedCustomer?.customerName.split(" ");
      setSelectedCustomer({
        ...selectedCustomer,
        firstName,
        lastName,
        customerName: null,
      });
    }
  }, [selectedCustomer]);

  const onSubmit = async (values) => {
    mixPanel.track(CREATE_PAYMENT_LINK, {
      Amount: values.Amount,
    });
    const payload = {
      amount: values.Amount,
      description: values.Narration,
      firstName: selectedCustomer?.firstName,
      lastName: selectedCustomer?.lastName,
      modeOfNotification: "WHATSAPP",
      phoneNumber: selectedCustomer?.customerPhoneNumber,
    };
    const res = await dispatch(generatePaymentLink(payload));
    if (res) {
      setShowSuccess(true);
      setPaymentData({ ...payload, ...res, date: new Date() });
    }
  };

  if (showSuccess)
    return (
      <NewSuccessPage
        headerText="Payment Link Created Successfully"
        homeLink={() => history.push("/actions/payment-links")}
        titleWidth={"169px"}
      >
        <PricesContainer>
          <Text
            font="10px"
            margin="1px 0 0 0"
            color={colors.themeTextColor11}
            fontWeight="700"
          >
            AMOUNT
          </Text>
          <Text
            margin="4px 0 13px 0"
            color={colors.bgBlue}
            font="20px"
            fontWeight="700"
          >
            {formatPrice(paymentData?.amount)}
          </Text>
          <Divider />
          <CustomerDetailsWrapper>
            <CustomerInnerWrapper>
              <Text
                margin="0 0 3px 0"
                color={colors.themeTextColor11}
                font="10px"
                fontWeight="700"
              >
                CUSTOMER
              </Text>
              <Text
                margin="7px 0"
                color={colors.boldDark}
                font="12px"
                fontWeight="500"
              >
                {`${paymentData?.firstName} ${paymentData?.lastName}`}
              </Text>
              <Text
                color={colors.themeTextColor11}
                font="12px"
                fontWeight="400"
              >
                {paymentData?.phoneNumber}
              </Text>
            </CustomerInnerWrapper>
            <CustomerInnerWrapper>
              <Text
                align="right"
                margin="0 0 3px 0"
                color={colors.themeTextColor11}
                font="10px"
                fontWeight="700"
              >
                DATE
              </Text>
              <Text
                color={colors.boldDark}
                margin="7px 0"
                font="12px"
                fontWeight="500"
              >
                {formatCreationDate(paymentData?.date)}
              </Text>
            </CustomerInnerWrapper>
          </CustomerDetailsWrapper>
        </PricesContainer>
        <ShortLineContainer>
          <Line />
          <Text
            margin="0 9px"
            color={colors.themeTextColor11}
            font="10px"
            fontWeight="700"
          >
            Link Created
          </Text>
          <Line />
        </ShortLineContainer>
        <SharePaymentLink
          status="PENDING"
          paymentLink={paymentData?.paymentLink}
          width={"calc(100% - 32px)"}
          margin={"0 23px"}
        />
      </NewSuccessPage>
    );
  return (
    <DesktopBackgroundLayout>
      <TopHeader title="Create Payment Link"></TopHeader>
      <ScreenContainer>
        <DetailsContainer>
          <InfoSvg />
          <TextDetails>
            Send your unique payment link below to your customers and get paid
            directly to your spaces wallet.
          </TextDetails>
        </DetailsContainer>
        <Ruler />
        <Fragment>
          <Formik
            onSubmit={(values) => onSubmit(values)}
            initialValues={{ Amount: "", Narration: "" }}
          >
            {({ values }) => (
              <Form>
                <NewAmountInputWithLabel
                  minHeight="initial"
                  label="Amount"
                  name="Amount"
                  placeholder="Amount"
                />
                <InputText>Max: R 20,000</InputText>
                <CustomerWrapper
                  padding="0"
                  onClick={() => setShowCustomersPopUp(true)}
                >
                  <InitialAndCustomerWrapper>
                    {selectedCustomer && (
                      <NameWrapper>
                        <InitialsText>
                          {selectedCustomer?.firstName
                            ? selectedCustomer?.firstName[0] +
                              selectedCustomer?.lastName[0]?.toUpperCase()
                            : null}
                        </InitialsText>
                      </NameWrapper>
                    )}
                    <CustomerText>
                      {selectedCustomer?.firstName
                        ? `${selectedCustomer?.firstName} ${selectedCustomer?.lastName}`
                        : "Customer"}
                    </CustomerText>
                  </InitialAndCustomerWrapper>
                  {selectedCustomer ? (
                    <DeleteSVG />
                  ) : (
                    <AddCustomerWrapper>
                      <CustomerSVG />
                      <AddCustomerText>Add Custormer</AddCustomerText>
                    </AddCustomerWrapper>
                  )}
                </CustomerWrapper>
                {selectedCustomer && (
                  <InputText align="left">{`Phone ${selectedCustomer?.customerPhoneNumber}`}</InputText>
                )}
                <NewTextInputWithLabel
                  label="Narration"
                  type={"text"}
                  name="Narration"
                  placeholder="Narration"
                />
                <InputText align={"right"} size={"10px"} top={"-16px"}>
                  {values.Narration.length}/20
                </InputText>
                <div>
                  <StyledButton
                    type="submit"
                    disabled={checkIsDisabled(values)}
                  >
                    Create Payment Link
                  </StyledButton>
                </div>
              </Form>
            )}
          </Formik>
        </Fragment>
      </ScreenContainer>
      {showCustomersPopUp && (
        <CustomersPopUp
          open={showCustomersPopUp}
          selectedCustomer={selectedCustomer}
          setSelectedCustomer={setSelectedCustomer}
          cancel={() => setShowCustomersPopUp((prev) => !prev)}
          showFirstAndLastName={true}
          height={"70vh"}
        />
      )}
    </DesktopBackgroundLayout>
  );
};

export default CreatePaymentLink;
