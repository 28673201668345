import React from "react";

import { string, func, bool } from "prop-types";

import { ButtonWithShareIcon } from "../../../components";
import { Overlay, ActionBlock } from "../../../containers/OverlayContainer";
import {
  CancelButton,
  PopUp,
  PopUpContent,
  PopUpHeader,
  InfoMessage,
  ConfirmButton,
} from "../common";

export const InfoPopupDialog = ({
  open,
  title,
  message,
  children,
  withConfirmation,
  confirm,
  confirmText,
  headerPadding,
  messagePadding,
  cancel,
  color,
  weight,
  size,
  noBorderBottom,
  contentPadding,
  textLeft,
  shareButton,
  buttonIcon,
  maxHeight,
  overflowY,
}) => {
  return (
    <PopUp open={open}>
      {open && <Overlay onClick={cancel} />}
      <PopUpContent
        padding={contentPadding}
        maxHeight={maxHeight}
        overflowY={overflowY}
      >
        <PopUpHeader
          align={textLeft}
          noBorderBottom={noBorderBottom}
          color={color}
          weight={weight}
          size={size}
          padding={headerPadding}
        >
          {title}
        </PopUpHeader>

        {message && (
          <InfoMessage align={textLeft} padding={messagePadding} bottom>
            {message}
          </InfoMessage>
        )}
        {children}
        {withConfirmation ? (
          <ActionBlock direction={"row"} top={"32px"} bottom="10px">
            <CancelButton
              top="0px"
              type="button"
              onClick={cancel}
              color="#227EFF"
              radius="4px"
              size="16px"
              height="48px"
              bgColor="#fff"
              border={true}
            >
              Cancel
            </CancelButton>

            {shareButton ? (
              <ButtonWithShareIcon
                top="0px"
                radius="4px"
                icon={buttonIcon}
                color="#fff"
                backgroundColor="#227EFF"
                onClick={confirm}
                size="16px"
              />
            ) : (
              <ConfirmButton type="submit" onClick={confirm}>
                {confirmText ? confirmText : "Confirm"}
              </ConfirmButton>
            )}
          </ActionBlock>
        ) : (
          <CancelButton
            type="button"
            onClick={cancel}
            color="#227EFF"
            radius="4px"
            size="16px"
            height="56px"
            top="16px"
            bgColor="#fff"
            boxShadow={true}
          >
            Cancel
          </CancelButton>
        )}
      </PopUpContent>
    </PopUp>
  );
};

InfoPopupDialog.propTypes = {
  open: bool,
  withConfirmation: bool,
  confirmText: string,
  title: string,
  message: string,
  padding: string,
  messagePadding: string,
  cancel: func,
};
